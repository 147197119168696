<div class="card stickyCard">
    <div class="card-header-white header-underline">
        <strong>
            <h6 class="white-header-text">{{'ADD_NEW_DEVICE' |translate}}</h6>
        </strong>
        <span class="float-right cursorPointer" (click)="closeAddForm()">
            <span class="fa-light fa-xmark" style="margin-right: 15px; position: relative; top: -12px"></span>
        </span>
    </div>
    <div class="card-body-white">

        <div class="alert alert-danger" *ngIf="addDevFormError">
            {{addDevFormError}}
        </div>

        <div class="alert alert-info" *ngIf='addDevFormInfo'>
            {{addDevFormInfo}}
        </div>

        <div class="col-lg-6">
            <form [formGroup]="addDevForm" (ngSubmit)="addDevice()">

                <div class="form-group pb-3">
                    <label><strong>{{'SERIAL_NUMBER' |translate}} <span
                                matTooltip="{{'TOOLTIP_SERIAL_NUMBER' |translate}}"
                                class="fa fa-fw fa-circle-info"></span></strong></label>
                    <div class="input-group">
                        <input class="form-control" type="text" placeholder="{{'SERIAL_NUMBER' |translate}}"
                            formControlName="id" name="devAddId">
                        <div class="input-group-append" matTooltip="{{'ID_VALIDATION_INFO' |translate}}"
                            [hidden]="addDevForm.controls['id'].valid || addDevForm.controls['id'].pristine"
                            *ngIf="!devValidated">
                            <div class="input-group-text alert-danger"><span class="fa-light fa-fw fa-info"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group" *ngIf="!devValidated">
                    <button type="submit" [disabled]="!addDevForm.valid"
                        class="btn btn-outline-primary gray-bg mr-1"><span
                            class="fa-light fa-check"></span> {{'VERIFY' |translate}}</button>
                </div>

                <div *ngIf="devValidated">
                    <div class="form-group" *ngIf="!newDevice" style="margin-top: -20px; padding-bottom: 28px;">
                        <strong>{{'DEVICE_TYPE' |translate}}:</strong> {{getDevType()}}
                    </div>

                    <div class="form-group" *ngIf="newDevice">
                        <div class="from-group"><label><strong>{{'DEVICE_TYPE' |translate}}</strong></label></div>

                        <mat-form-field appearance="outline">
                            <mat-select formControlName="devType">
                                <mat-option value="" disabled>{{'DEVICE_TYPE' |translate}}</mat-option>
                                <mat-option *ngFor="let devType of deviceTypeList" [value]="devType.value">
                                    {{devType.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="form-group" style="margin-top: -28px;">
                        <div class="from-group"><label><strong>{{'LOCATION' |translate}}</strong></label></div>
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="locationId">
                                <mat-option value="" disabled>{{'LOCATION' |translate}}</mat-option>
                                <mat-option *ngFor="let loc of locations" [value]="loc.id">
                                    {{loc.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="form-group" style="margin-top: -15px;">
                        <label><strong>{{'NAME' |translate}}</strong></label>
                        <input type="text" class="form-control" placeholder="{{'NAME' |translate}}"
                            formControlName="name" name="name">
                    </div>

                    <div class="form-group" *ngIf="responseDev.gsmNumber">
                        <strong>{{'GSM_NUMBER' |translate}}:</strong> {{responseDev.gsmNumber}}
                    </div>

                    <div class="form-group">
                        <button [disabled]="(addDevForm.invalid || addDevForm.pristine || deviceIsBeingAdded)"
                            #formSubmitButton type="submit"
                            class="btn btn-primary mr-1">
                            <div class="d-flex align-items-center">
                                <mat-spinner *ngIf="deviceIsBeingAdded" diameter="20"></mat-spinner>
                                <span class="fa-light fa-floppy-disk" *ngIf="!deviceIsBeingAdded"></span>
                                <span class="ml-2">{{'SAVE' |translate}}</span>
                            </div>
                        </button>

                        <button type="reset" class="btn btn-outline-danger" (click)="resetAddForm()">
                            <span class="fa-light fa-xmark"></span>
                            {{'CANCEL' |translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
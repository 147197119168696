import { ChartOptions } from "chart.js";

/**
 * DEFAULT BAR CHART
 */
export var BarDefOptions = {
  type: "bar",
  data: {
    datasets: [
      {
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
      },
    ],
  },
  options: {
    title: {
      display: true,
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: [
        {
          position: "bottom",
          scaleLabel: {
            display: true,
          },
        },
      ],
      y: [
        {
          beginAtZero: true,
        },
      ],
    },
    layout: {
      padding: {
        left: 30,
        right: 30,
        top: 30,
        bottom: 30,
      },
    },
  },
};

/**
 * DEFAULT HORIZONTAL BAR CHART
 */
export var HorizontalBarDefOptions = {
  type: "horizontalBar",
  data: {
    datasets: [
      {
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
      },
    ],
  },
  options: {
    title: {
      display: true,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: [
        {
          position: "top",
          scaleLabel: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      y: [
        {
          barThickness: 20,
        },
      ],
    },
    tooltips: {
      intersect: false,
    },
    layout: {
      padding: {
        left: 30,
        right: 30,
        top: 30,
        bottom: 30,
      },
    },
    legend: {
      display: true,
      position: "bottom",
    },
  },
};

export var HorizontalBarEmaOptions = {
  type: "horizontalBar",
  data: {
    datasets: [
      {
        backgroundColor: "rgba(255, 94, 94, 0.2)",
        borderColor: "rgba(255, 94, 94, 1)",
        borderWidth: 2,
      },
      {
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
      },
      {
        backgroundColor: "rgba(255, 208, 81, 0.2)",
        borderColor: "rgba(255, 208, 81, 1)",
        borderWidth: 2,
      },
    ],
  },
  options: {
    title: {
      display: true,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: [
        {
          stacked: true,
          position: "top",
          scaleLabel: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      y: [
        {
          stacked: true,
          barThickness: 20,
        },
      ],
    },
    tooltips: {
      intersect: false,
    },
    layout: {
      padding: {
        left: 30,
        right: 30,
        top: 30,
        bottom: 30,
      },
    },
    legend: {
      display: true,
      position: "top",
    },
  },
};

/**
 * DEFAULT PIE CHART
 */
export var PieDefOptions = {
  type: "pie",
  data: {
    datasets: [
      {
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 94, 94, 0.2)",
          "rgba(255, 208, 81, 0.2)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 94, 94, 1)",
          "rgba(255, 208, 81, 1)",
        ],
        borderWidth: 2,
      },
    ],
  },
  options: {
    title: {
      display: true,
    },
    layout: {
      padding: {
        left: 30,
        right: 30,
        top: 30,
        bottom: 30,
      },
    },
    legend: {
      display: true,
      position: "top",
    },
  },
};

/**
 * TIME LINE CHART
 */

export var TimeLineOptions = {
  plugins: [
    {
      beforeDraw: function (c) {
        var reset_zoom = document.getElementById("chartZoomReset"); //reset button
        var ticks = c.scales["x-axis-0"].ticks.length;
        let refNumber = 0;
        switch (c.options.scales.xAxes[0].time.unit) {
          case "minute":
            refNumber = 49;
            break;
          case "hour":
            refNumber = 12;
            break;
          case "day":
            refNumber = 7;
            break;
          default:
            refNumber = 49;
            break;
        }
        if (ticks < refNumber) reset_zoom.hidden = false;
        else reset_zoom.hidden = true;
      },
    },
  ],
  type: "line",
  data: {
    datasets: [
      {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        fill: true,
        lineTension: 0,
        pointRadius: 0,
        borderWidth: 1.5,
      },
    ],
  },
  options: {
    scales: {
      x: [
        {
          type: "time",
          distribution: "linear",
        },
      ],
      y: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 200,
          },
        },
      ],
    },
    title: {
      display: true,
    },
    legend: {
      display: false,
    },
    zoom: {
      enabled: true,
      drag: true,
      mode: "x",
    },
    maintainAspectRatio: false,
  },
};

import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {
  Component,
  OnInit,
  Input,
  HostListener,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Chart, ChartDataset } from "chart.js";
import { IDeviceTypeAlertsByWeekday } from "app/models/statistics.model";
import { Subscription, fromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import { HelperService } from "app/services/helper.service";
import { ALERTS_PER_DAY_CHART_CONFIGS } from "app/shared/chart-configs.shared";

@Component({
  selector: "alerts-per-day",
  templateUrl: "./alerts-per-day.component.html",
  styleUrls: ["./alerts-per-day.component.css"],
  standalone: true,
  imports: [TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsPerDayComponent implements OnInit, OnDestroy {
  constructor(
    private helper: HelperService,
    private translate: TranslateService
  ) {}
  @Input() alertsByDays: IDeviceTypeAlertsByWeekday[];

  chart: Chart;
  datasets: ChartDataset<"bar">[] = [];
  dayLabels: string[];

  currentColorIndex: number = 0;
  maxColorIndex: number = COLORS.length - 1;

  mouseRightClickSubscription: Subscription;

  pickColor(): string {
    const pickedColor = COLORS[this.currentColorIndex];
    this.currentColorIndex === this.maxColorIndex
      ? (this.currentColorIndex = 0)
      : this.currentColorIndex++;
    return pickedColor;
  }

  // Prevent opening browser's mouse right click dropdown menu when clicked
  @HostListener("contextmenu", ["$event"])
  onContextMenu(event: MouseEvent) {
    event.preventDefault();
  }

  // Reset charts pan view
  resetZoom() {
    this.chart.resetZoom();
  }

  drawChart(): void {
    // Parse chart data
    for (const type of this.alertsByDays) {
      const dataset: ChartDataset<"bar"> = {
        label: this.helper.getDeviceType(type.deviceType),
        data: type.alertsPerDay.map((count) => (count === 0 ? null : count)),
        barThickness: 23,
        backgroundColor: this.pickColor(),
      };
      this.datasets.push(dataset);
    }
    // Set the data and hour labels
    ALERTS_PER_DAY_CHART_CONFIGS.data.datasets = this.datasets;
    ALERTS_PER_DAY_CHART_CONFIGS.data.labels = this.dayLabels;

    // Create chart
    this.chart = new Chart("alertsPerDayChart", ALERTS_PER_DAY_CHART_CONFIGS);
  }

  ngOnInit() {
    // Get translations the chart
    this.translate
      .get([
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ])
      .subscribe((t) => {
        this.dayLabels = [
          t.MONDAY,
          t.TUESDAY,
          t.WEDNESDAY,
          t.THURSDAY,
          t.FRIDAY,
          t.SATURDAY,
          t.SUNDAY,
        ];
        this.drawChart();
      });

    // Init listening mouse right click event
    /* NOT in use, but preserve the code in case zoom is enabled again
    const mouseRightClick$ = fromEvent(document, "mousedown").pipe(
      filter((event: MouseEvent) => event.button === 2)
    );

    this.mouseRightClickSubscription = mouseRightClick$.subscribe(() => {
      this.resetZoom();
    });
    */
  }

  ngOnDestroy(): void {
    //this.mouseRightClickSubscription.unsubscribe();
  }
}

const COLORS = [
  "rgb(112, 212, 252)",
  "rgb(0, 136, 227)",
  "rgb(75, 194, 186)",
  "rgb(255, 179, 153)",
  "rgb(255, 219, 160)",
  "rgb(143, 122, 219)",
  "rgb(152, 238, 206)",
  "rgb(255, 249, 179)",
  "rgb(196, 175, 148)",
  "rgb(234, 54, 127)",
  "rgb(204, 255, 252)",
  "rgb(255, 132, 83)",
  "rgb(198, 235, 203)",
  "rgb(255, 246, 133)",
  "rgb(255, 147, 54)",
  "rgb(230, 109, 126)",
  "rgb(129, 126, 227)",
  "rgb(94, 69, 75)",
  "rgb(255, 222, 248)",
  "rgb(27, 160, 148)",
];

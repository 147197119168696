import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MomentPipe, SignalQualityClassPipe } from 'app/pipes/pipes';

@Component({
  selector: 'device-signal-levels',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MomentPipe,
    SignalQualityClassPipe
  ],
  templateUrl: './device-signal-levels.component.html',
  styleUrls: ['./device-signal-levels.component.css']
})
export class DeviceSignalLevelsComponent {

  @Input() signalLevels;
  @Input() networkSurveyResults;
  @Input() deviceType;
  signalCollapseState: boolean
}

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ApiService } from "app/services/api.service";
import { Router } from "@angular/router";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { StaffModel, IdentityUser } from "app/models/staff.model";
import { ValidateUsernameAsync } from "app/services/validators.service";
import { AlarmRouteModel, Route } from "../../../models/alarmroutes.model";
import { ReceiverTeam } from "../../../models/receiver-team";
import { HelperService } from "../../../services/helper.service";
import { UnlocDoorTypes } from "app/models/unloc.model";
import { Subject } from "rxjs";
import { throttleTime } from "rxjs/operators";
import { MomentPipe } from "../../../pipes/pipes";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CommonModule } from "@angular/common";
import { v4 as uuidv4 } from "uuid";
import { passwordValidator } from "app/shared/password-validator.shared";

@Component({
  selector: "staff-info",
  templateUrl: "./staff-info.component.html",
  styleUrls: ["./staff-info.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatTooltipModule,
    TranslateModule,
    MomentPipe,
  ],
})
export class StaffInfoComponent implements OnChanges, OnDestroy, OnInit {
  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private usernameValidator: ValidateUsernameAsync,
    private helper: HelperService
  ) { }

  @Input("staff") staff: StaffModel;
  @Input("locations") locations: any;
  @Output() closeStaffCardEvent = new EventEmitter<any>();
  @Output() staffUpdateEvent = new EventEmitter<StaffModel>();
  @Output() staffInsertEvent = new EventEmitter<StaffModel>();
  @Output() staffDeleteEvent = new EventEmitter<string>();

  initialDataLoaded: boolean;
  alarmRouteLocations: AlarmRouteModel[] = [];
  availableAlarmRouteLocations: AlarmRouteModel[] = [];
  receiverTeamLocations: ReceiverTeamLocation[];
  availableReceiverTeamLocations: ReceiverTeamLocation[];
  user: any;
  editMode: boolean;
  staffEditForm: UntypedFormGroup;
  updateSuccess: boolean;
  updateError: boolean;
  passwordMisMatch: boolean;
  deleteConfirmation: boolean;
  enhancedSecurityFeature: boolean;
  identityUsers: IdentityUser[];
  staffLocationList: string[];

  alarmRouteDropDownOpen: boolean = false;
  receiverTeamDropDownOpen: boolean = false;
  lockTypes = UnlocDoorTypes;
  //Select lock types by default which do not require special permissions
  selectedLockTypes: any;
  showUnlockSection = true;

  formSubmitSubject = new Subject();

  createStaffEditForm(): void {
    const enhancedPasswordValidatorRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&_-])[A-Za-z\\d@$!%*?&_-]{8,}$"
    );
    const gsmNumberRegex = new RegExp(
      "^\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{1,14}$"
    );

    this.staffEditForm = this.fb.group({
      firstName: this.staff.firstName,
      familyName: this.staff.familyName,
      username: [this.staff.username, { updateOn: "blur" }],
      location: [this.staff.location, Validators.required],
      accessLocations: [this.staffLocationList],
      alarmRoutes: [
        {
          value: this.staff.alarmRoutes,
          disabled: !!!this.staff.location && this.locations.length !== 1,
        },
      ],
      receiverTeams: [
        {
          value: this.staff.teams,
          disabled: !!!this.staff.location && this.locations.length !== 1,
        },
      ],
      gsmNumber: [
        this.staff.gsmNumber,
        { validators: Validators.pattern(gsmNumberRegex), updateOn: "blur" },
      ],
      email: [this.staff.email, Validators.email],
      language: [this.staff.language, Validators.required],
      usingLevel: [this.staff.usingLevel, Validators.required],
      lockType: [this.selectedLockTypes],
      password: [
        "",
        {
          validators: this.enhancedSecurityFeature
            ? Validators.pattern(enhancedPasswordValidatorRegex)
            : passwordValidator(),
          updateOn: "blur",
        },
      ],
      confirmPassword: [
        "",
        {
          validators: this.enhancedSecurityFeature
            ? Validators.pattern(enhancedPasswordValidatorRegex)
            : passwordValidator(),
          updateOn: "blur",
        },
      ],
      locked: this.staff.locked,
      identityUser: this.staff.identityUsername,
    });

    if (this.staff.identityUsername) {
      //do not set validators if staff was integrated
    } else {
      this.staffEditForm.controls["username"].setValidators([
        Validators.required,
        Validators.pattern(new RegExp("^\\S+$")),
      ]);
      this.staffEditForm.controls["username"].setAsyncValidators(
        this.usernameValidator.validate.bind(this.usernameValidator)
      );
      this.staffEditForm.controls["username"].updateValueAndValidity();
    }

    if (this.locations.length === 1) {
      this.staffEditForm.get("location").setValue(this.locations[0]);
      this.staffEditForm.get("location").disable();
    }

    this.staffEditForm.get("location").valueChanges.subscribe((_) => {
      this.staffEditForm.get("alarmRoutes").enable();
      this.staffEditForm.get("alarmRoutes").reset([]);
      this.staffEditForm.get("receiverTeams").enable();
      this.staffEditForm.get("receiverTeams").reset([]);
      this.parseRoutes();
      this.parseReceiverTeams();
    });
  }

  unlockStaff() {
    this.staffEditForm.controls.locked.patchValue(false);
    this.submitStaffEdit();
  }

  compareByRouteId(o1: any, o2: any): boolean {
    return o1 && o2 && o1.id === o2.id;
  }

  compareByGroupId(o1: any, o2: any): boolean {
    return o1 && o2 && o1.groupId === o2.groupId;
  }

  closeStaffCard(): void {
    this.closeStaffCardEvent.emit(this.staff);
  }

  isFeatureEnabled(feature: string): boolean {
    let features: [string] = JSON.parse(localStorage.getItem("features"));
    if (features) {
      return features.includes(feature);
    }
    return false;
  }

  getLanguageString(lang: string) {
    switch (lang) {
      case "FI":
        return this.translate.instant("FINNISH");
      case "EN":
        return this.translate.instant("ENGLISH");
      case "ES":
        return this.translate.instant("SPANISH");
      case "SE":
        return this.translate.instant("SWEDISH");
      case "DE":
        return this.translate.instant("GERMAN");
      case "FR":
        return this.translate.instant("FRENCH");
      default:
        return this.translate.instant("UNKNOWN_LANGUAGE");
    }
  }

  getUsingLevelString(usingLevel: number): string {
    switch (usingLevel) {
      case 101:
        return this.translate.instant("STAFF_CUSTOMER_ADMIN");
      case 100:
        return this.translate.instant("STAFF_MAIN_USER");
      case 50:
        return this.translate.instant("STAFF_USER");
      case 10:
        return this.translate.instant("EveronMobileApp");
      case 0:
        return this.translate.instant("STAFF_READONLY");
      default:
        "";
    }
  }

  featureEnabled(feature: string): boolean {
    let features = JSON.parse(localStorage.getItem("features"));
    if (features) {
      let index = features.indexOf(feature);
      if (index !== -1) {
        return true;
      }
    }
    return false;
  }

  staffEditMode(): void {
    //If staff does not have ID user is creating new staff so close the whole card
    if (!this.staff.id) {
      this.closeStaffCard();
      return;
    }
    //Hide all information and error messages
    this.updateError = false;
    this.updateSuccess = false;

    //If edit mode is not enabled create new form and enable edit mode.
    if (!this.editMode) {
      this.createStaffEditForm();
    }
    this.toggleEditMode(!this.editMode);
  }

  async createStaffInformation(data: any): Promise<any> {
    return await new Promise<any>((resolve, reject) =>
      this.api.addNewStaff(data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => reject()
      )
    );
  }

  async updateStaffInformation(data: any, newUuid: string): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      try {
        // Include current alarm routes and receiver teams to remove user from them, in case user is relocated from top level to a location
        data.receiverTeamsToRemove = this.mapReceiverTeamsIdsToRemove(data);
        data.alarmRoutesToRemove = this.mapAlarmRoutesIdsToRemove(data);

        // Remove fields in order to avoid errors in backend
        delete data.alarmRoutes;
        delete data.receiverTeams;

        this.api.saveStaffInfo(data, newUuid).subscribe(
          (res) => {
            resolve(res);
          },
          (err) => reject()
        );
      } catch (error) {
        console.error(error);
        reject();
      }
    });
  }

  async updateStaffAlarmRoutes(
    personnelId: string,
    alarmRoutes: Route[],
    newUuid: string = ""
  ): Promise<any> {
    let alarmRouteIds: string[] = [];
    if (alarmRoutes) {
      alarmRouteIds = alarmRoutes.map((alarmRoute) => alarmRoute.id);
    }

    await new Promise<any>((resolve, reject) =>
      this.api
        .updatePersonnelAlarmRoutes(personnelId, alarmRouteIds, newUuid)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => reject()
        )
    );
  }

  async updateStaffReceiverTeams(
    personnelId: string,
    receiverTeams: ReceiverTeam[],
    newUuid: string = ""
  ): Promise<any> {
    let receiverTeamIds: { groups: string[] } = { groups: [] };
    if (receiverTeams) {
      receiverTeams.forEach((team) =>
        receiverTeamIds.groups.push(team.groupId)
      );
    }

    await new Promise<any>((resolve, reject) =>
      this.api
        .updatePersonnelReceiverTeams(personnelId, receiverTeamIds, newUuid)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => reject()
        )
    );
  }

  async updateAuthenticatedUser(newUuid: string): Promise<any> {
    let data = {
      username: this.staffEditForm.value.identityUser,
      personnelId: this.staff.id,
    };
    await new Promise<any>((resolve) =>
      this.api.updateAuthenticatedUser(data, newUuid).subscribe((res) => {
        resolve(res);
      })
    );
  }

  async updateLocationAccess(newUuid: string): Promise<any> {
    let data = {
      personnelId: this.staff.id,
      locations: this.staffEditForm.value.accessLocations,
    };
    await new Promise<any>((resolve) =>
      this.api.saveStaffLocationList(data, newUuid).subscribe((res) => {
        resolve(res);
      })
    );
  }

  async updateLockOpeningRights(newUuid: string = ""): Promise<any> {
    const selectedLockTypes = this.staffEditForm.controls.lockType.value;
    const allLockTypes = UnlocDoorTypes;
    let data: any = [];
    // Check what lock types are selected and what are disabled
    allLockTypes.forEach((l) => {
      let lockType = {
        lockedDoorTypeId: parseInt(l.value),
        requirePermission: l.requirePermission,
        selected: false,
      };
      if (selectedLockTypes.includes(l.value)) {
        lockType.selected = true;
      }
      data.push(lockType);
    });

    await new Promise<any>((resolve) =>
      this.api
        .updateStaffLockOpeningRights(this.staff.id, data, newUuid)
        .subscribe((res) => {
          resolve(res);
        })
    );
  }

  async updateStaff(data: any): Promise<void> {
    const newUuid = uuidv4();

    if (this.staffEditForm.controls.identityUser.dirty) {
      this.updateAuthenticatedUser(newUuid);
    }
    if (this.staffEditForm.controls.accessLocations.dirty) {
      this.updateLocationAccess(newUuid);
    }
    if (this.staffEditForm.controls.alarmRoutes.dirty) {
      await this.updateStaffAlarmRoutes(data.id, data.alarmRoutes, newUuid);
    }
    if (this.staffEditForm.controls.receiverTeams.dirty) {
      await this.updateStaffReceiverTeams(data.id, data.receiverTeams, newUuid);
    }

    if (this.staffEditForm.controls.lockType.dirty) {
      await this.updateLockOpeningRights(newUuid);
    }

    await this.updateStaffInformation(data, newUuid);
  }

  async saveNewStaff(data: any): Promise<any> {
    const newStaffResponse = await this.createStaffInformation(data);
    await this.updateStaffAlarmRoutes(newStaffResponse.id, data.alarmRoutes);
    await this.updateStaffReceiverTeams(
      newStaffResponse.id,
      data.receiverTeams
    );
    return newStaffResponse;
  }

  submitStaffEdit(): void {
    this.updateError = false;
    this.updateSuccess = false;
    this.showUnlockSection = false;

    //Make deep copy of form data
    const data = JSON.parse(JSON.stringify(this.staffEditForm.value));
    //this.staffEditForm.value.username = "";

    data.id = this.staff.id;
    data.locationId = this.staffEditForm.getRawValue().location.id;

    if (data.password != data.confirmPassword) {
      this.passwordMisMatch = true;
      return;
    }
    //If data already has ID user is editing staff details. Use right API for that and otherwise submit new staff
    if (data.id) {
      this.passwordMisMatch = false;
      this.updateStaff(data).then(
        (_) => {
          //Get updated information from server if save was successfull
          this.api.getStaffInfo(data.id).subscribe((res) => {
            this.staff = res;
            this.updateSuccess = true;
            this.staffUpdateEvent.emit(this.staff);
            //Find correct location with location ID
            this.staff.location = this.locations.find((obj) => {
              return obj.id === this.staff.locationId;
            });
          });
          this.toggleEditMode(false);
        },
        (err) => {
          this.updateError = true;
          //Set username back into form in case of error in submit
          this.staffEditForm.value.username = data.username;
        }
      );
    } else {
      this.saveNewStaff(data).then(
        (response) => {
          //Update lock opening rights after save so we have staff ID
          if (this.staffEditForm.controls.lockType.dirty) {
            this.staff.id = response.id;
            this.updateLockOpeningRights();
          }

          //Get updated information from server if save was successfull
          this.api.getStaffInfo(response.id).subscribe((res) => {
            this.toggleEditMode(false);
            this.staffInsertEvent.emit(res);
          });
        },
        (err) => {
          this.updateError = true;
        }
      );
    }
  }

  //Check if typed username is the same as the old one and mark field as pristine so it passes validation
  accountNameBlurMethod(): void {
    if (this.staffEditForm.value.username === this.staff.username) {
      this.staffEditForm.controls.username.markAsPristine();
      this.staffEditForm.controls.username.updateValueAndValidity();
    }
  }

  deleteStaff(): void {
    this.updateError = false;
    this.updateSuccess = false;
    this.api.deleteStaff(this.staff.id).subscribe(
      (res) => {
        this.staffDeleteEvent.emit(this.staff.id);
      },
      (err) => {
        this.updateError = true;
      }
    );
  }

  parseRoutes(): void {
    if (this.staffEditForm && this.staffEditForm.getRawValue().location) {
      const formLocationId = this.staffEditForm.getRawValue().location.id;
      if (formLocationId) {
        if (formLocationId.startsWith("C0")) {
          if (this.alarmRouteLocations) {
            this.availableAlarmRouteLocations = JSON.parse(
              JSON.stringify(this.alarmRouteLocations)
            );
          }
        } else {
          this.availableAlarmRouteLocations = [
            this.alarmRouteLocations.find(
              (location) => location.locationId === formLocationId
            ),
          ];
        }
      }
    }
  }

  parseReceiverTeams(): void {
    if (this.staffEditForm && this.staffEditForm.getRawValue().location) {
      if (this.receiverTeamLocations) {
        const formLocationId = this.staffEditForm.getRawValue().location.id;
        if (formLocationId) {
          if (formLocationId.startsWith("C0")) {
            this.availableReceiverTeamLocations = JSON.parse(
              JSON.stringify(this.receiverTeamLocations)
            );
          } else {
            this.availableReceiverTeamLocations = [
              this.receiverTeamLocations.find(
                (location) => location.locationId === formLocationId
              ),
            ];
          }
        }
      }
    }
  }

  async getAlarmRoutes(): Promise<void> {
    await new Promise<void>((resolve) =>
      this.api.getAlarmRoutes().subscribe((res) => {
        if (res) {
          this.alarmRouteLocations = res;
        }
        resolve();
      })
    );
  }

  async getReceiverTeams(): Promise<void> {
    const convertReceiverTeamsIntoLocations = (res: ReceiverTeam[]) => {
      if (!res) {
        return;
      }
      const receiverGroupRawLocations = this.helper.groupBy(
        res,
        (receiverTeam) => receiverTeam.locationId
      );

      this.receiverTeamLocations = receiverGroupRawLocations.map((teams) => {
        if (!teams[0].locationName) {
          return {
            locationName: teams[0].customerName,
            locationId: teams[0].locationId,
            receiverTeams: teams,
          };
        }
        return {
          locationName: teams[0].locationName,
          locationId: teams[0].locationId,
          receiverTeams: teams,
        };
      });
    };

    await new Promise<void>((resolve) =>
      this.api.getReceiverTeams().subscribe((res) => {
        convertReceiverTeamsIntoLocations(res);
        resolve();
      })
    );
  }

  async getIdentities(): Promise<void> {
    await new Promise<void>((resolve) =>
      this.api.getIdentityList().subscribe((res) => {
        this.identityUsers = res;
        resolve();
      })
    );
  }

  async getLockOpeningRights(): Promise<void> {
    await new Promise<void>((resolve) =>
      this.api.getStaffLockOpeningRights(this.staff.id).subscribe((res) => {
        this.selectedLockTypes = res.map((x) => {
          if (x.permitted) {
            return x.lockedDoorTypeId.toString();
          }
        });

        // then adding new staff check out checkboxes where permission is not required
        this.staff.id
          ? null
          : (this.selectedLockTypes = UnlocDoorTypes.map((x) =>
            x.requirePermission === false ? x.value : undefined
          ));

        resolve();
      })
    );
  }

  async getStaffLocationList(): Promise<void> {
    await new Promise<void>((resolve) => {
      // Skip fetching the location access list for the new user
      if (!this.staff.id) resolve();

      this.api.getStaffLocationList(this.staff.id).subscribe((res) => {
        if (res) {
          this.staffLocationList = res;
        }
        resolve();
      })
    });
  }

  async fetchInitialData(): Promise<void> {
    await this.getReceiverTeams();
    await this.getAlarmRoutes();
    if (
      this.isFeatureEnabled("StrongAuthentication") &&
      this.user?.roleLevel >= 100
    ) {
      await this.getIdentities();
    }
    if (this.isFeatureEnabled("DigitalKey")) {
      await this.getLockOpeningRights();
    }
    if (this.user?.roleLevel >= 100) {
      await this.getStaffLocationList();
    }
  }

  openAlarmRoutePage(routeId: string): void {
    this.router.navigate(["route/" + routeId]);
  }

  openReceiverTeamsPage(teamId: string): void {
    this.router.navigate(["teams/" + teamId]);
  }

  //Toggle editMode, If edit mode is true fetch AlarmRoutes and ReceiverTeams
  toggleEditMode(editMode: boolean) {
    this.editMode = editMode;
    if (editMode) {
      this.initialDataLoaded = false;
      this.fetchInitialData().then((_) => {
        this.createStaffEditForm();
        this.parseRoutes();
        this.parseReceiverTeams();
        this.initialDataLoaded = true;
      });
    }
  }

  mapAlarmRoutesIdsToRemove(data: any): string[] {
    return this.staff?.alarmRoutes
      ?.map((route) => route.id)
      .filter(
        (routeId) =>
          !data.alarmRoutes.map((route: Route) => route.id).includes(routeId)
      ); // Do not include routes to the remove list that are about to be reassigned
  }

  mapReceiverTeamsIdsToRemove(data: any): string[] {
    return this.staff?.teams
      ?.map((team) => team.groupId)
      .filter(
        (teamId) =>
          !data.receiverTeams
            .map((team: ReceiverTeam) => team.groupId)
            .includes(teamId)
      );
  }

  ngOnInit(): void {
    this.createStaffEditForm();
    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject.pipe(throttleTime(3000)).subscribe(() => {
      this.submitStaffEdit();
    });
  }

  ngOnChanges() {
    this.enhancedSecurityFeature = this.isFeatureEnabled("EnhancedSecurity");
    this.toggleEditMode(false);
    this.updateError = false;
    this.updateSuccess = false;

    // Find correct location from location list with location ID
    this.staff.location = this.locations.find((obj) => {
      return obj.id === this.staff.locationId;
    });

    this.user = JSON.parse(localStorage.getItem("user"));

    // If staff does not have ID user is creating new staff. Enable editing mode and initialize the edit form
    if (!this.staff.id) {
      this.toggleEditMode(true);
    }
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

interface ReceiverTeamLocation {
  locationName: string;
  locationId: string;
  receiverTeams: ReceiverTeam[];
}

<div id="viewContainer">
    <div id="time-filters">
        <mat-form-field appearance="outline">
            <mat-label>{{ "REPORT_START_DATE" | translate }}</mat-label>
            <input
                placeholder="{{ 'REPORT_START_DATE' | translate }}"
                matInput
                [max]="currentDate"
                [min]="dateLimit"
                [matDatepicker]="filterStartDatePicker"
                [(ngModel)]="startDate"
                (dateChange)="onStartDateChange($event)"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="filterStartDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #filterStartDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style="margin-left: 7px" appearance="outline">
            <mat-label>{{ "REPORT_END_DATE" | translate }}</mat-label>
            <input
                placeholder="{{ 'REPORT_END_DATE' | translate }}"
                matInput
                [min]="startDate"
                [max]="currentDate"
                [matDatepicker]="filterEndDayPicker"
                [(ngModel)]="endDate"
                (dateChange)="onEndDateChange($event)"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="filterEndDayPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #filterEndDayPicker></mat-datepicker>
        </mat-form-field>

        <button
            (click)="loadAlerts()"
            id="searchBtn"
            type="submit"
            class="btn btn-primary"
        >
            <span class="fal fa-search"></span>
            {{ "SEARCH" | translate }}
        </button>
    </div>

    <hr />

    <div *ngIf="pageViewInitialized">
        <div *ngIf="!dataLoaded">
            <img
                class="fadeLoader centerLoader logoSmall"
                src="assets/img/loading_logo.svg"
            />
        </div>

        <div *ngIf="dataLoaded && deviceAlerts.length === 0">
            <p>{{ "SEARCH_NOT_FOUND" | translate }}</p>
        </div>

        <div
            [hidden]="!dataLoaded || deviceAlerts.length === 0"
            id="deviceAlertContainer"
        >
            <div class="card">
                <div class="card-header-blue">
                    <h6 class="blue-text" id="headerText">
                        {{ "ALERTS_PER_DEVICE" | translate }}
                    </h6>

                    <div id="dropdownContainer">
                        <button
                            class="custom-dropdown-btn"
                            (click)="showDropdownMenu = true"
                            #dropdownBtn
                        >
                            <i class="fa-solid fa-ellipsis"></i>
                        </button>

                        <div
                            class="custom-dropdown-menu"
                            *ngIf="showDropdownMenu"
                            #dropdownMenu
                        >
                            <div
                                class="custom-dropdown-option"
                                id="downloadCSV"
                                (click)="exportDeviceAlertsAsCSV()"
                            >
                                {{ "DOWNLOAD_CSV" | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="rounded-bottom-corners">
                    <div class="form-inline">
                        <p id="showDevicesWithText">
                            {{ "SHOW_DEVICES_WITH" | translate }}
                        </p>

                        <mat-form-field class="mr-2">
                            <mat-select
                                matNativeControl
                                [formControl]="selectFormControl"
                                (selectionChange)="applyFilter()"
                            >
                                <mat-option value="more" selected>{{
                                    "MORE_THAN" | translate
                                }}</mat-option>
                                <mat-option value="less">{{
                                    "LESS_THAN" | translate
                                }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="mr-2">
                            <input
                                matInput
                                placeholder="10"
                                [formControl]="alertCountFilter"
                                (keyup)="applyFilter()"
                            />
                        </mat-form-field>
                        <p>{{ "ALERTS" | translate }}</p>
                    </div>

                    <table
                        mat-table
                        [dataSource]="tableData"
                        class="rounded-bottom-corners"
                        matSortActive="alarms"
                        matSortDirection="desc"
                        matSort
                    >
                        <!--device name column-->
                        <ng-container matColumnDef="deviceName">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                {{ "DEVICE_NAME" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let e">
                                {{ e.deviceName }}
                            </td>
                        </ng-container>
                        <!--device type column-->
                        <ng-container matColumnDef="deviceType">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                {{ "DEVICE_TYPE" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let e">
                                {{ e.deviceType }}
                            </td>
                        </ng-container>
                        <!--device ID column-->
                        <ng-container matColumnDef="deviceId">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                {{ "SERIAL_NUMBER" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let e">
                                {{ e.deviceId }}
                            </td>
                        </ng-container>
                        <!--alarm count column-->
                        <ng-container
                            matColumnDef="alarms"
                            class="mat-column-alarms"
                        >
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                {{ "ALERTS" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let e">{{ e.alarms }}</td>
                        </ng-container>
                        <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumns"
                        ></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: displayedColumns"
                        ></tr>
                    </table>
                </div>
            </div>

            <div class="card" id="alarmTypeCard">
                <mat-grid-list
                    class="mat-grid-card-header-blue"
                    cols="2"
                    rowHeight="40px"
                >
                    <mat-grid-tile id="alerts">
                        <h6 class="blue-text">
                            {{
                                "ALERTS_WITH_FIRST_LETTER_UPPERCASE" | translate
                            }}
                        </h6>
                    </mat-grid-tile>

                    <mat-grid-tile id="total-amount">
                        <h6 class="blue-text">
                            {{ "TOTAL_AMOUNT" | translate }}:
                            {{ alarmTypes.total }}
                        </h6>
                    </mat-grid-tile>
                </mat-grid-list>

                <mat-grid-list cols="2" rowHeight="130px">
                    <mat-grid-tile>
                        <mat-card-content>
                            <p class="alert-type">
                                {{ "BASESTATION_BUTTON" | translate }}
                            </p>
                            <p></p>
                            <h2 class="alarm-count-value">
                                {{ alarmTypes.basestationButton }}
                            </h2>
                        </mat-card-content>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-card-content>
                            <p class="alert-type">
                                {{ "ALARM_BUTTON" | translate }}
                            </p>
                            <h2 class="alarm-count-value">
                                {{ alarmTypes.alarmButton }}
                            </h2>
                        </mat-card-content>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-card-content>
                            <p class="alert-type">
                                {{ "FALL_ALARM" | translate }}
                            </p>
                            <h2 class="alarm-count-value">
                                {{ alarmTypes.fallAlarm }}
                            </h2>
                        </mat-card-content>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-card-content>
                            <p class="alert-type">
                                {{ "MOVEMENT" | translate }}
                            </p>
                            <h2 class="alarm-count-value">
                                {{ alarmTypes.movement }}
                            </h2>
                        </mat-card-content>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-card-content>
                            <p class="alert-type">
                                {{ "DOOR_ALARM" | translate }}
                            </p>
                            <h2 class="alarm-count-value">
                                {{ alarmTypes.doorAlarm }}
                            </h2>
                        </mat-card-content>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-card-content>
                            <p class="alert-type">
                                {{ "BATTERY_STATE" | translate }}
                            </p>
                            <h2 class="alarm-count-value">
                                {{ alarmTypes.batteryState }}
                            </h2>
                        </mat-card-content>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
        <br />
    </div>
</div>


import { HttpClient, HttpBackend } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatTooltipDefaultOptions } from '@angular/material/tooltip';

export function HttpLoaderFactory(http: HttpBackend) {
  let client: HttpClient = new HttpClient(http);
  return new TranslateHttpLoader(client);
}

export const customTooltipDefaults: MatTooltipDefaultOptions = {
  position: "above",
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0,
};




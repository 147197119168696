<div class="card stickyCard">
    <div class="card-header card-header-dark">
        <strong>
            {{'ADD_NEW_DEVICE' |translate}}
        </strong>
        <span class="float-right cursorPointer" (click)="closeAddForm()"><span class="fa-light fa-xmark"></span></span>
    </div>
    <div class="card-body card-body-dark">
        <div class="card-body-container">
            <div class="alert alert-danger" *ngIf="addDevFormError">
                {{addDevFormError}}
            </div>
            <div class="col-lg-6">
                <form [formGroup]="addDevForm" (ngSubmit)="formSubmitSubject.next()">

                    <div class="form-group">
                        <label>
                            <strong>
                                {{'SERIAL_NUMBER' |translate}} <span matTooltip="{{'TOOLTIP_SERIAL_NUMBER' |translate}}"
                                                                     class="fa fa-fw fa-circle-info"></span>
                            </strong>
                        </label>
                        <div class="input-group">
                            <input class="form-control" type="text" placeholder="{{'SERIAL_NUMBER' |translate}}"
                                   formControlName="id" name="devAddId">
                            <div class="input-group-append" matTooltip="{{'ID_VALIDATION_INFO' |translate}}"
                                 [hidden]="addDevForm.controls['id'].valid || addDevForm.controls['id'].pristine"
                                 *ngIf="devValidate(addDevForm.controls['id'].valid)">
                                <div class="input-group-text alert-danger">
                                    <span class="fa-light fa-fw fa-info"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>

                        <div class="form-group">
                            <label><strong>{{'NAME' |translate}}</strong></label>
                            <input type="text" class="form-control" placeholder="{{'NAME' |translate}}"
                                   formControlName="name" name="name">
                        </div>

                        <div class="form-group">
                            <div class="from-group"><label><strong>{{'DEVICE_TYPE' |translate}}</strong></label></div>
                            <mat-form-field>
                                <mat-select formControlName="deviceType" required placeholder="{{'DEVICE_TYPE' |translate}}">

                                    <mat-option *ngFor="let devType of devTypesForSelect" value={{devType.deviceType}}>{{getDevType(devType.deviceType)}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <button [disabled]="!addDevForm.controls['deviceType'].valid" type="submit"
                                    class="btn btn-outline-primary mr-1">
                                <span class="fa-light fa-floppy-disk"></span>
                                {{'SAVE' |translate}}
                            </button>
                            <!--
                            <button type="reset" class="btn btn-outline-danger" (click)="resetAddForm()">
                                <span class="fa-light fa-xmark"></span> {{'CANCEL' |translate}}
                            </button>
                            -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

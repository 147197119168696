<div *ngIf="availableStaffForLocation">
    <p>{{'LOCATION_ACCESS_RIGHT_INFORMATION' |translate}}</p>
    <form [formGroup]="locationStaffEditForm" (ngSubmit)="formSubmitSubject.next()">
        <div class="form-group">
            <mat-selection-list formControlName="selectedStaffForLocation" dense="true">
                <mat-list-option *ngFor="let staff of availableStaffForLocation" [value]="staff.id">
                    {{staff.fullName}} ({{staff.id}})
                </mat-list-option>
            </mat-selection-list>
        </div>
        <div class="form-group">
            <div *ngIf="updateSuccess" class="alert alert-success">
                {{'LOCATION_UPDATE_SUCCESS' |translate}}
            </div>
            <div *ngIf="updateError" class="alert alert-danger">
                {{'ERROR' |translate}}
            </div>
            <button [disabled]="locationStaffEditForm.pristine" type="submit" class="btn btn-outline-primary gray-bg mr-1"><span
                    class="fa-light fa-floppy-disk"></span> {{'SAVE' |translate}}</button>
        </div>
    </form>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Route, ServiceTime, ServiceTimeValidity } from '../../../../models/alarmroutes.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgFor, NgIf } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';

@Component({
    selector: 'alarm-route-schedules',
    templateUrl: './alarm-route-schedules.component.html',
    styleUrls: ['./alarm-route-schedules.component.css'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatRadioModule, NgFor, NgIf, MatCheckboxModule, MatFormFieldModule, MatInputModule, TranslateModule]
})
export class AlarmRouteSchedulesComponent implements OnInit {

  currentServiceTimes: ServiceTime[] = [];
  currentServiceTimeValidity: ServiceTimeValidity;
  showServiceTimeOptions: boolean;


  daysMap: Map<string, string> = new Map([
    ['mon', 'DAYOFWEEK_1'],
    ['tue', 'DAYOFWEEK_2'],
    ['wed', 'DAYOFWEEK_3'],
    ['thu', 'DAYOFWEEK_4'],
    ['fri', 'DAYOFWEEK_5'],
    ['sat', 'DAYOFWEEK_6'],
    ['sun', 'DAYOFWEEK_7'],
  ]);

  serviceTimeValidityOptions: ServiceTimeValidityOption[] = [
    {value: ServiceTimeValidity.Always, viewValue: 'ALWAYS'},
    {value: ServiceTimeValidity.Daily, viewValue: 'DAILY'},
    {value: ServiceTimeValidity.Never, viewValue: 'NEVER'}
  ];


  constructor(private fb: UntypedFormBuilder) {
  }

  schedulesForm: UntypedFormGroup;

  @Input('tempRoute') tempRoute: Route;


  TIME_REGEX = /^([0-1][0-9]|[2][0-3]).([0-5][0-9])$/;


  buildForms(): void {
    this.schedulesForm = this.fb.group({
      serviceTimeValidity: [this.currentServiceTimeValidity || ServiceTimeValidity.Never],
    });

    Array.from(this.daysMap.keys()).forEach(day => {
      const currentServiceTime: ServiceTime = this.currentServiceTimes.find(serviceTime => serviceTime.day === day);
      const serviceTimeEnabled: boolean = !currentServiceTime.startTime.startsWith('00:00') || !currentServiceTime.endTime.startsWith('00:00');

      this.schedulesForm.addControl(day + 'enabled', this.fb.control(serviceTimeEnabled));
      this.schedulesForm.addControl(day + 'startTime', this.fb.control(currentServiceTime.startTime.substring(0, Math.max(currentServiceTime.startTime.length - 3, 5)), Validators.pattern(this.TIME_REGEX)));
      this.schedulesForm.addControl(day + 'endTime', this.fb.control(currentServiceTime.endTime.substring(0, Math.max(currentServiceTime.endTime.length - 3, 5)), Validators.pattern(this.TIME_REGEX)));


      if (!serviceTimeEnabled) {
        this.schedulesForm.get(day + 'startTime').disable();
        this.schedulesForm.get(day + 'endTime').disable();
      }
    });


  }


  toggleShowServiceTimeOptions(): void {
    this.showServiceTimeOptions = this.schedulesForm.get('serviceTimeValidity').value === ServiceTimeValidity.Daily;
  }


  toggleServiceTimeSection(day: string): void {
    const serviceTimeEnabled: boolean = this.schedulesForm.get(day + 'enabled').value;

    if (serviceTimeEnabled) {
      this.schedulesForm.get(day + 'startTime').enable();
      this.schedulesForm.get(day + 'startTime').setValue('00:00');
      this.schedulesForm.get(day + 'endTime').enable();
      this.schedulesForm.get(day + 'endTime').setValue('23:59');
    } else {
      this.schedulesForm.get(day + 'startTime').disable();
      this.schedulesForm.get(day + 'startTime').setValue('00:00');
      this.schedulesForm.get(day + 'endTime').disable();
      this.schedulesForm.get(day + 'endTime').setValue('00:00');
    }

  }

  onFormChange(): void {

    this.schedulesForm.valueChanges.subscribe(information => {
      this.saveScheduleInformationToTempRoute(information);

    });

  }


  saveScheduleInformationToTempRoute(information: any): void {

    this.tempRoute.serviceTimeValidity = information.serviceTimeValidity;

    this.tempRoute.serviceTimes = Array.from(this.daysMap.keys()).map(day => {
      let startTime: string = information[day + 'startTime'] || '00:00';
      let endTime: string = information[day + 'endTime'] || '00:00';

      const validStartTime = this.TIME_REGEX.test(startTime);
      const validEndTime = this.TIME_REGEX.test(endTime);


      if (information[day + 'enabled']) {
        if (!validStartTime) {
          startTime = '00:00'
        }

        if (!validEndTime) {
          endTime = '00:00'
        }
      }

      const cleanTime = (time: string) => {
        return time.replace(/[^0-9]/, ':');
      };


      return {day: day, startTime: cleanTime(startTime), endTime: cleanTime(endTime)}
    });
  }


  ngOnInit() {
    this.currentServiceTimes = JSON.parse(JSON.stringify(this.tempRoute.serviceTimes));
    this.currentServiceTimeValidity = JSON.parse(JSON.stringify(this.tempRoute.serviceTimeValidity));
    this.buildForms();
    this.toggleShowServiceTimeOptions();
    this.onFormChange();
  }


}


export interface ServiceTimeValidityOption {
  value: ServiceTimeValidity;
  viewValue: string;
}


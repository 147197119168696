import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location, NgIf } from '@angular/common';
import * as moment from 'moment-timezone';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../../../services/api.service';
import { NgxBootstrapMultiselectModule, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { FilterOptions } from 'app/services/helper.service';
import * as Plotly from 'plotly.js';
import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, NgxBootstrapMultiselectModule, TranslateModule]
})
export class ActivityComponent implements OnInit, OnDestroy {

    /**
     * SERVICES
     */
    constructor(
        private translate: TranslateService,
        private api: ApiService,
        private location: Location,
        private route: ActivatedRoute,
    ) { }
    dataLoading: boolean;
    sensorsLoaded: boolean;

    deviceFilter: any = {
        settings: {
            enableSearch: true,
            showUncheckAll: false,
            dynamicTitleMaxItems: 1,
            checkedStyle: 'fontawesome',
            containerClasses: "fullWidth",
            buttonClasses: "btn btn-outline-dark fullWidth",
            selectionLimit: 1,
            autoUnselect: true,
            closeOnSelect: true
        },
        data: new FilterOptions
    };
    advancedFilterBaseTexts: IMultiSelectTexts;
    graphTexts: any = {};
    selectedSensor: any = {};

    /**
     * FUNCTIONS
     */
    //Function is initialized on page load
    ngOnInit(): void {
        //Load devices with filtered values from the server
        this.api.getActivitySensors()
            .subscribe(res => {
                if (res) {
                    //Generate new list from the result so name and id is displayed if name is set
                    let filterData = res.map(obj => {
                        let a: any = {};
                        a.id = obj.sensorId;
                        if (obj.sensorName) {
                            a.name = obj.sensorName + " (" + obj.sensorId + ")"
                        } else {
                            a.name = obj.sensorId
                        }
                        return a;
                    })
                    this.sensorsLoaded = true;
                    this.deviceFilter.data.filterList = filterData;
                    let id: string;
                    //If user comes with direct URL get the device ID from the URL
                    this.route.params.subscribe(params => {
                        id = params['id'];
                    })
                    if (id) {
                        if (!this.deviceFilter.data.selectedList) {
                            this.deviceFilter.data.selectedList = [];
                        }
                        this.deviceFilter.data.selectedList[0] = id;
                        this.sensorChanged(this.deviceFilter.data.selectedList);
                        this.loadActivity();
                    }
                }
            }, err => {
                this.sensorsLoaded = true;
                this.deviceFilter.data.filterList = null;
            })

        //Load translations for different UI elements
        this.translate.get(['DATE_FROM', 'DATE_TO', 'FILTER_DEVICE', 'FILTER_UNCHECK_ALL', 'SEARCH', 'ACTIVITY', 'SAMPLE_DISTRIBUTIONS', 'DAY_DISTRIBUTIONS', 'NR_DAYS', 'NR_SAMPLES', 'DAY_24H', 'DAYTIME', 'NIGHTTIME']).subscribe(t => {
            this.advancedFilterBaseTexts = {
                searchPlaceholder: t.SEARCH,
                uncheckAll: t.FILTER_UNCHECK_ALL
            }
            this.deviceFilter.data.texts = Object.assign({}, this.advancedFilterBaseTexts, { defaultTitle: t.FILTER_DEVICE })
            this.graphTexts.activityTitle = t.ACTIVITY;
            this.graphTexts.boxedTitle = t.DAY_DISTRIBUTIONS;
            this.graphTexts.violinTitle = t.SAMPLE_DISTRIBUTIONS;
            this.graphTexts.days = t.NR_DAYS;
            this.graphTexts.samples = t.NR_SAMPLES;
            this.graphTexts.day24 = t.DAY_24H;
            this.graphTexts.daytime = t.DAYTIME;
            this.graphTexts.nighttime = t.NIGHTTIME;
        })
    }

    ngOnDestroy(): void {
        this.deviceFilter.data.selectedList = null;
    }

    sensorChanged(event: any): void {
        this.selectedSensor = this.deviceFilter.data.filterList.find(obj => {
            if (obj.id == event[0]) {
                return obj;
            }
        })
    }

    loadActivity(): void {
        let deviceId = this.selectedSensor.id;
        this.location.go("reports/activity/" + deviceId)
        Plotly.purge('scatter');
        Plotly.purge('violin');
        Plotly.purge('boxed');
        this.dataLoading = true;
        this.api.loadActivityData(deviceId, "scatter")
            .subscribe(res => {
                if (res) {
                    let allSamples = this.groupByDate(res);
                    this.drawScatterGraph(allSamples, res.length);
                }
            })
        this.api.loadActivityData(deviceId, "boxed")
            .subscribe(res => {
                if (res) {
                    var keys = [];
                    var values = [];
                    var dayValues = [];
                    var nightValues = [];
                    if (res.distribution) for (var minute in res.distribution) {
                        for (var value in res.distribution[minute]) {
                            var nrOfSamples = res.distribution[minute][value];
                            for (var i = 0; i < nrOfSamples; i++) {
                                values.push(value);
                                //Convert the minutes into time string by creating new moment object with milliseconds
                                keys.push(moment.utc(1000 * 60 * parseInt(minute)).format("HH:mm"));
                                if (parseInt(minute) < 5 * 60 || parseInt(minute) >= 23 * 60) {
                                    nightValues.push(value);
                                }
                                if (parseInt(minute) >= 9 * 60 && parseInt(minute) < 19 * 60) {
                                    dayValues.push(value);
                                }
                            }
                        }
                    }
                    this.drawBoxedGraph(keys, values);
                    this.api.loadActivityData(deviceId, "violin")
                        .subscribe(res2d => {
                            this.dataLoading = false;
                            if (res2d) {
                                var values2d = [];
                                var dayValues2d = [];
                                var nightValues2d = [];
                                if (res2d.distribution) for (var minute in res2d.distribution) {
                                    for (var value in res2d.distribution[minute]) {
                                        var nrOfSamples = res2d.distribution[minute][value];
                                        for (var i = 0; i < nrOfSamples; i++) {
                                            values2d.push(value);
                                            if (parseInt(minute) < 5 * 60 || parseInt(minute) >= 23 * 60) {
                                                nightValues2d.push(value);
                                            }
                                            if (parseInt(minute) >= 9 * 60 && parseInt(minute) < 19 * 60) {
                                                dayValues2d.push(value);
                                            }
                                        }
                                    }
                                }
                                this.drawViolinGraph(values, values2d, dayValues, dayValues2d, nightValues, nightValues2d);
                            }
                        })
                }
            })

    }

    groupByDate(data): any {
        let allSamples = [];
        let index: number = -1;
        for (let i = 0; i < data.length; i++) {
            let curDate = moment(data[i].timeStamp).format("YYYY-MM-DD").toString();
            let prevDate = i > 0 ? moment(data[i - 1].timeStamp).format("YYYY-MM-DD").toString() : null;
            let sample = {
                x: data[i].timeStamp,
                y: data[i].value
            };
            if (curDate !== prevDate) {
                index++;
                allSamples[index] = [];
                allSamples[index].push(sample);
            } else {
                allSamples[index].push(sample);
            }
        }
        return allSamples;
    }

    drawScatterGraph(allSamples: any, sampleCount): void {
        let data = [];
        //Add empty calibration dataset with all minutes in x-axis so graph is drawed for whole day even if data is missing from real traces
        let calibration: any = {
            name: '',
            type: 'scatter',
            showscale: false,
            opacity: 0,
            hoverinfo: 'x',
            x: [],
            y: []
        }
        //Add each minute in HH:mm format into the x-axis and 0 for y-axis
        let calibrationDate = moment();
        for (let i = 0; i < 1440; i++) {
            calibration.x.push(calibrationDate.startOf('day').add(i, 'minutes').format("HH:mm").toString());
            calibration.y.push(0)
        }
        //Push the calibration trace to the beginning of all traces
        data.push(calibration);
        for (let i = 0; i < allSamples.length; i++) {
            var color = i === allSamples.length - 1 ? 'blue' : (i === allSamples.length - 2) ? 'gray' : 'rgb(220, 220, 220)';
            var width = (i === allSamples.length - 1) ? 3 : 1;
            let sData: any = {
                name: '',
                type: 'scatter',
                line: {
                    color: color,
                    width: width
                },
                showscale: false,
                x: [],
                y: []
            };
            sData.x = allSamples[i].map(s => {
                return moment(s.x).format("HH:mm").toString();
            });
            sData.y = allSamples[i].map(s => {
                return s.y;
            });
            data.push(sData)
        }
        let layout: any = {
            title: `<b> ${this.graphTexts.activityTitle} - ${this.selectedSensor.name} </b><br /> 7 ${this.graphTexts.days} (${sampleCount} ${this.graphTexts.samples})`,
            showlegend: false,
            autosize: true,
            height: 600,
            xaxis: {
                tickmode: "array",
                tickvals: [0, 3 * 60, 6 * 60, 9 * 60, 12 * 60, 15 * 60, 18 * 60, 21 * 60, 24 * 60],
                ticktext: ['0:00', '3:00', '6:00', '9:00', '12:00', '15:00', '18:00', '21:00', '24:00']
            }
        };
        Plotly.newPlot('scatter', data, layout, { responsive: true });
    }

    drawViolinGraph(values: any, values2d: any, dayValues: any, dayValues2d: any, nightValues: any, nightValues2d: any): void {
        let data: any = [
            {
                type: 'violin',
                y: values,
                points: 'none',
                box: {
                    visible: true
                },
                boxpoints: false,
                line: {
                    color: 'black'
                },
                fillcolor: '#8dd3c7',
                opacity: 0.6,
                meanline: {
                    visible: true
                },
                side: 'negative',

                x0: this.graphTexts.day24,
                showlegend: false
            }, {
                type: 'violin',
                y: values2d,
                points: 'none',
                box: {
                    visible: true
                },
                boxpoints: false,
                line: {
                    color: 'blue'
                },
                fillcolor: '#8dd3c7',
                opacity: 0.6,
                meanline: {
                    visible: true
                },
                side: 'positive',

                x0: this.graphTexts.day24,
                showlegend: false
            },
            {
                type: 'violin',
                y: dayValues,
                points: 'none',
                box: {
                    visible: true
                },
                boxpoints: false,
                line: {
                    color: 'black'
                },
                fillcolor: '#8dd3c7',
                opacity: 0.6,
                meanline: {
                    visible: true
                },
                side: 'negative',

                x0: `${this.graphTexts.daytime} (9 - 19)`,
                showlegend: false
            }, {
                type: 'violin',
                y: dayValues2d,
                points: 'none',
                box: {
                    visible: true
                },
                boxpoints: false,
                line: {
                    color: 'blue'
                },
                fillcolor: '#8dd3c7',
                opacity: 0.6,
                meanline: {
                    visible: true
                },
                side: 'positive',

                x0: `${this.graphTexts.daytime} (9 - 19)`,
                showlegend: false
            },
            {
                type: 'violin',
                y: nightValues,
                points: 'none',
                box: {
                    visible: true
                },
                boxpoints: false,
                line: {
                    color: 'black'
                },
                fillcolor: '#8dd3c7',
                opacity: 0.6,
                meanline: {
                    visible: true
                },
                side: 'negative',

                x0: `${this.graphTexts.nighttime} (23 - 5)`,
                showlegend: false
            }, {
                type: 'violin',
                y: nightValues2d,
                points: 'none',
                box: {
                    visible: true
                },
                boxpoints: false,
                line: {
                    color: 'blue'
                },
                fillcolor: '#8dd3c7',
                opacity: 0.6,
                meanline: {
                    visible: true
                },
                side: 'positive',
                x0: `${this.graphTexts.nighttime} (23 - 5)`,
                showlegend: false
            }];

        let layout: any = {
            title: `<b>${this.graphTexts.violinTitle} -  ${this.selectedSensor.name} </b><br />14 ${this.graphTexts.days} (${values.length} ${this.graphTexts.samples}) vs. <span style='color:#0000FF'>2 ${this.graphTexts.days} (${values2d.length} ${this.graphTexts.samples})</span>`,
            yaxis: {
                zeroline: false
            }
        }

        Plotly.purge('violin');
        Plotly.newPlot('violin', data, layout, { responsive: true });

    }
    drawBoxedGraph(keys: any, values): void {
        let data: any = [{
            type: 'box',
            boxpoints: 'suspectedoutliers',

            x: keys,
            y: values,
            box: {
                visible: false
            },
            line: {
                color: 'green',
            },
            meanline: {
                visible: true
            },
            transforms: [{
                type: 'groupby',
                groups: keys
            }],
            showlegend: false,
        }]

        let layout: any = {
            title: `<b>${this.graphTexts.boxedTitle} - ${this.selectedSensor.name}</b><br />14 ${this.graphTexts.days} (${values.length} ${this.graphTexts.samples})`,
            yaxis: {
                zeroline: false
            },
            xaxis: {
                dtick: 6
            }
        }
        Plotly.purge('boxed');
        Plotly.newPlot('boxed', data, layout, { responsive: true });
    }
}

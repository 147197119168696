<ng-container *ngIf="logoutBtnForMobileSidenav; else logoutMenuForDesktopSideNav">
    <!--Logout-->
    <button *ngIf="user" class="button-rounded innerShadow" mat-raised-button [ngStyle]="loginButtonStyle"
        [matMenuTriggerFor]="userLogout">{{initials}}
        <i *ngIf="!initials" class="fa-duotone fa-user"></i>
        <!-- <small *ngIf="loggedAsCustomer()">{{loggedCustomer}}</small> -->
    </button>
    <mat-menu #userLogout="matMenu">
        <div class="menuWithPaddings">
            <div mat-menu-item [disableRipple]='true'
                [ngStyle]="{cursor: 'default', 'tabindex': -1, 'line-height': '15px'}"
                (click)="$event.stopPropagation()"
                class="bgColorNoneOnHover mb-2">
                <span>{{user?.firstName}} {{user?.familyName}}</span><br>
                <span>{{user?.server}}</span><br>
                <span [ngStyle]="{color: 'grey'}">{{enterpriseName}}</span><br>
                <span [ngStyle]="{color: 'grey'}">{{customerId}}</span>
            </div>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="showPasswordChangeDialog()" class="bgColorOnHoverLink">
                {{'CHANGE_PASSWORD' |translate}}
            </button>
            <button mat-menu-item *ngIf="language === 'fi' || language === 'sv'" class="bgColorOnHoverLink">
                <a class="logoutLink" target="_blank" [href]="'assets/doc/everon_portal_manual_'+language+'.pdf'">
                    {{'PORTAL_MANUAL' |translate}}
                </a>
            </button>
            <button mat-menu-item *ngIf="language === 'fi'|| language === 'sv' || language === 'en'"
                class="bgColorOnHoverLink">
                <a class="logoutLink" target="_blank" [href]="'assets/doc/ema_manual_'+language+'.pdf'">
                    {{'EMA_MANUAL' |translate}}
                </a>
            </button>

            <button mat-raised-button (click)="backToLandingpage()" *ngIf="fromLandingPage()" class="logoutButton mb-2">
                <span class="fa-light fa-fw fa-arrow-left" aria-hidden="true"></span>
                {{('BACK_TO_LANDINGPAGE' |translate)}}
            </button>
            <button mat-raised-button class="logoutButton" (click)="logout()">
                {{loggedAsCustomer() ? ('CHANGE_CUSTOMER' |translate) : ('SIGN_OUT' |translate)}}
            </button>
        </div>

    </mat-menu>

    <!--Landing page logout-->
    <button *ngIf="landingPage" [matMenuTriggerFor]="landingPageLogout" class="button-rounded" mat-raised-button
        [ngStyle]="loginButtonStyle">
        <i class="fa-light fa-user" aria-hidden="true"></i>
    </button>
    <mat-menu #landingPageLogout="matMenu">
        <span>{{landingPage?.user.firstname}} {{landingPage?.user.surname}}</span>
        <button mat-menu-item (click)="logout()">
            <span class="fa-light fa-fw fa-arrow-right-from-bracket" aria-hidden="true"></span> {{'SIGN_OUT'
            |translate}}
        </button>
    </mat-menu>
</ng-container>

<ng-template #logoutMenuForDesktopSideNav>
    <!-- ordinary logout -->
    <mat-list role="list" *ngIf="showNav() && !onMobileScreen && user">
        <div class="normalCursor pt-3">
            <mat-list-item *ngIf="!showTitles" role="listitem"
                class="mb-2 doNotTrimSpanButton"
                matTooltip="{{user?.firstName}} {{user?.familyName}}"
                matTooltipPosition="after"
                matTooltipClass="customTooltipStyle"
                matTooltipShowDelay="50"
                matTooltipHideDelay="150">
                <button mat-fab class="normalCursor whiteBackGrndDarkBlueFont innerShadow nonClickable">
                    {{ initials }}
                    <i *ngIf="!initials" class="fa-duotone fa-fw fa-user-vneck"></i>
                </button>
            </mat-list-item>

            <ng-container *ngIf="showTitles">
                <mat-list-item role="listitem" class="text-light smallerHeight bolderBiggerName">
                    <i class="fa-duotone fa-fw fa-id-badge"></i>
                    <span>{{user?.firstName}} {{user?.familyName}}</span>
                </mat-list-item>
                <mat-list-item role="listitem" class="text-light smallerHeight">
                    <i class="fa-duotone"></i>
                    <span>{{user?.server}}</span>
                </mat-list-item>
                <mat-list-item role="listitem" class="text-light smallerHeight">
                    <i class="fa-duotone"></i>
                    <span
                        matTooltip="{{enterpriseName}}"
                        matTooltipPosition="after"
                        [matTooltipDisabled]="enterpriseName?.length < 15"
                        matTooltipClass="customTooltipStyle"
                        matTooltipShowDelay="50"
                        matTooltipHideDelay="150">{{shortenedEnterpriseName}}
                    </span>
                </mat-list-item>
                <mat-list-item role="listitem" class="text-light smallerHeight mb-3">
                    <i class="fa-duotone"></i>
                    <span>{{customerId}}</span>
                </mat-list-item>
            </ng-container>

            <mat-list-item role="listitem"
                class="text-light pointer backgroundColorOnHover"
                (click)="showPasswordChangeDialog(); $event.stopPropagation()"
                matTooltip="{{'CHANGE_PASSWORD' |translate}}"
                matTooltipPosition="after"
                [matTooltipDisabled]="showTitles"
                matTooltipClass="customTooltipStyle"
                matTooltipShowDelay="50"
                matTooltipHideDelay="150">
                <div [ngClass]="{'centerItem' : !showTitles}">
                    <a>
                        <i class="fa-duotone fa-fw fa-lock"></i>
                        <span *ngIf="showTitles">{{'CHANGE_PASSWORD' |translate}}</span>
                    </a>
                </div>
            </mat-list-item>
        </div>

        <div (click)="$event.stopPropagation()">
            <mat-list-item role="listitem" class="text-light pointer backgroundColorOnHover"
                *ngIf="fromLandingPage()"
                matTooltip="{{('BACK_TO_LANDINGPAGE' |translate)}}"
                matTooltipPosition="after"
                [matTooltipDisabled]="showTitles"
                matTooltipClass="customTooltipStyle"
                matTooltipShowDelay="50"
                matTooltipHideDelay="150">
                <div [ngClass]="{'centerItem' : !showTitles}">
                    <a (click)="backToLandingpage()">
                        <i class="fa-duotone fa-fw fa-backward"></i>
                        <span *ngIf="showTitles">{{('BACK_TO_LANDINGPAGE' |translate)}}</span>
                    </a>
                </div>
            </mat-list-item>
            <mat-list-item role="listitem" class="text-light pointer backgroundColorOnHover" (click)="logout()"
                matTooltip="{{ loggedAsCustomer() ? ('CHANGE_CUSTOMER' |translate) : ('SIGN_OUT'|translate)}}"
                matTooltipPosition="after"
                [matTooltipDisabled]="showTitles"
                matTooltipClass="customTooltipStyle"
                matTooltipShowDelay="50"
                matTooltipHideDelay="150">
                <div [ngClass]="{'centerItem' : !showTitles}">
                    <a>
                        <i class="fa-duotone fa-fw fa-right-from-bracket"></i>
                        <span *ngIf="showTitles">{{ loggedAsCustomer() ? ('CHANGE_CUSTOMER' |translate) :
                            ('SIGN_OUT' |translate)}}</span>
                    </a>
                </div>
            </mat-list-item>
        </div>
    </mat-list>

    <!-- landing page logout -->
    <mat-list role="list" *ngIf="showNav() && !onMobileScreen && !user">
        <div class="normalCursor border-top border-mute border-2 pt-3" (click)="$event.stopPropagation()">
            <mat-list-item role="listitem" class="mb-2 text-light doNotTrimSpanButton">
                {{landingPage?.user.firstname}} {{landingPage?.user.surname}}
                <button mat-fab *ngIf="showTitles" style="background-color:#fff">
                    <i class="fa-duotone fa-fw fa-user-vneck" style="color: #07184D"></i>
                </button>
            </mat-list-item>

            <mat-list-item role="listitem" class="text-light pointer backgroundColorOnHover" (click)="logout()">
                <a>
                    <i class="fa-duotone fa-fw fa-right-from-bracket"></i>
                    <span *ngIf="showTitles">{{'SIGN_OUT' |translate}}</span>
                </a>
            </mat-list-item>

            <mat-list-item role="listitem" class="text-light pointer backgroundColorOnHover">
                <mat-select [(value)]="selectedLanguage" style="width: 120px;"
                    (selectionChange)="onSelectionChange($event)">
                    <mat-select-trigger>
                        <img *ngIf="selectedLanguage" [src]="selectedLanguage.imgSrc" class="languageFlag">
                    </mat-select-trigger>
                    <mat-option *ngFor="let option of languageOptions"
                        [value]="option">
                        <img [src]="option.imgSrc" [alt]="option.alt" class="languageFlag lightgreyBorder">
                    </mat-option>
                </mat-select>
            </mat-list-item>
        </div>
    </mat-list>
</ng-template>
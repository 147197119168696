import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnOnCamera, OnOnCameraGroup, OnOnCameraGroupState, OnOnProfile } from 'app/models/onon.model';
import { OnOnService } from 'app/services/onon.service';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment-timezone';
import { ApiService } from 'app/services/api.service';
import { TranslateModule } from '@ngx-translate/core';
import { OnOnMonitorCardComponent } from './onon-monitor-card/onon-monitor-card.component';
import { NgFor, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';


@Component({
    selector: 'onon-monitor',
    templateUrl: './onon-monitor.component.html',
    styleUrls: ['./onon-monitor.component.css'],
    standalone: true,
    imports: [RouterLink, NgFor, NgIf, OnOnMonitorCardComponent, TranslateModule]
})
export class OnOnMonitorComponent implements OnInit, OnDestroy {
  constructor(
    private ononApi: OnOnService,
    private api: ApiService,
    private cookies: CookieService
  ) { }
  cameraGroups: OnOnCameraGroup[];
  cameraGroupNames: any[];
  profiles: OnOnProfile[];
  cameraGroupProfiles: any[];
  cameras: OnOnCamera[];
  cameraGroupProfileSets: any[];

  parseCameraGroups(): void {
    //Insert cameras and states under groups
    this.cameraGroups.forEach(group => {
      group.cameras = this.cameras.filter(c => c.cameraGroupId === group.id);
      //Find correct group name
      let groupName: any = this.cameraGroupNames.find(x => x.id === group.lyraId.substr(group.lyraId.length - 8));
      //Use group name if it was found and otherwise use camera ID
      group.name = groupName ? groupName.name : group.lyraId.substr(group.lyraId.length - 8);
      let profileSet: any = this.cameraGroupProfileSets.find(p => p.cameraGroupId === group.id);
      if (profileSet) {
        group.schedule = profileSet.schedule;
      }
      if (group.schedule && group.schedule.length > 0) {
        group.schedule[0].name = this.profiles.find(x => x.id === group.schedule[0].profileId).name;
      }

    });
  }

  updateCameraGroupState(socketState: any): void {
    let group: OnOnCameraGroup = this.cameraGroups.find(x => x.id === socketState.sourceId)
    //Update states if camera does not yet have state or nurse is not in room
    if (!group.state || !group.nurseInRoom) {
      //Initialize new state if it was not yet added into camera
      if (!group.state) {
        let state: OnOnCameraGroupState = new OnOnCameraGroupState();
        state.state = socketState.supervisionState;
        state.time = socketState.supervisionStateTime;
        group.state = state;
      } else {
        group.state.state = socketState.supervisionState;
        group.state.time = socketState.supervisionStateTime;
      }
    }
  }

  updateCameraGroupAlerts(alertStates: any): void {
    this.cameraGroups.forEach(g => {
      if (alertStates.tenantId === g.tenantId) {
        let alerts = alertStates.activeAlerts.filter(x => x.cameraGroupId === g.id)
        g.alerts = alerts;
        //Check if any alert has been started by nurse. Update state and time if so
        if (alerts.some(x => x.taskStartedBy)) {
          g.nurseInRoom = true
          if (g.state) g.state.time = moment().toString();
        } else {
          //Change state to unknown when nurse completes the alert
          if (g.nurseInRoom) {
            g.state.state = "unknown";
          }
          g.nurseInRoom = false;
        }

      }
    });
  }

  updateCameraGroupActiveProfile(data: any): void {
    let group: OnOnCameraGroup = this.cameraGroups.find(x => x.id === data.sourceId);
    group.activeProfile = this.profiles.find(x => x.id === data.activeProfileId);
  }

  ngOnInit(): void {
    //Make login into OnOn API
    this.ononApi.login().subscribe(res => {
      //Set OnOn API token into cookies
      this.cookies.set("onon-session-token", "Bearer " + res.token, undefined, "/", undefined, false, "Lax");
      //Initialize all data after login
      this.ononApi.initializeOnOnData().subscribe(results => {
        //Get results from array
        this.cameras = results[0];
        this.cameraGroups = results[1];
        this.profiles = results[2];
        this.cameraGroupProfileSets = results[3];
        this.api.getDeviceNameList(this.cameraGroups.map(x => x.lyraId.substr(x.lyraId.length - 8))).subscribe(res => {
          this.cameraGroupNames = res;
          //Parse initial data
          this.parseCameraGroups();
        })
        //Initialize WebSocket connection into OnOn server and subscribe for messages from the socket
        this.ononApi.initSocket();
        this.ononApi.socketOnMessage('cameragroup-supervision-state').subscribe(res => {
          this.updateCameraGroupState(res);
        })
        this.ononApi.socketOnMessage('alerts').subscribe(res => {
          this.updateCameraGroupAlerts(res);
        })
        this.ononApi.socketOnMessage('cameragroup-active-profile').subscribe(res => {
          this.updateCameraGroupActiveProfile(res);
        })
      })
    }, err => {
    })
  }

  ngOnDestroy(): void {
    //Close the socket once user navigates out from the page
    this.ononApi.closeSocket();
  }
}
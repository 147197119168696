<div [(ngModel)]="showBaseDevicesOnly" ngDefaultControl class="row">

    <div *ngIf="countData > 0" class="statusBlock" [ngClass]="statusClass">

        <div (click)="displayOnlineStatusDevices()" class="cursorPointer">
            <span class="statusCount" [ngClass]="statusClass">{{countData}}</span>
            <span class="statusHeader"
                [ngClass]="!onlineStatus.state ? '' : 'boldNormalSizeFont'">{{statusTitle}}</span>

            <span [ngClass]="!onlineStatus.state ? 'fa-chevron-down' : 'fa-chevron-up'" class="fa-solid fa-xl"
                style="float: right; color: #707070; position: relative; top: 15px;">
            </span>

            <div *ngIf="showProductStatus" class="onlineStatusDevices">
                <app-device-database></app-device-database>
            </div>
        </div>

        <div *ngIf="onlineStatus?.state" [@animationCollapseLeave]>
            <div *ngIf="!onlineStatus?.deviceList">
                <img style="margin-top:5px;" class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg">
            </div>
            <div *ngIf="onlineStatus?.deviceList" style="margin-left: 0px;">
                <div class="d-flex justify-content-between align-items-center ml-1">
                    <span style="color: #222222;"> {{description}}</span>
                    <span class="btn btn-outline-primary gray-bg cursorPointer text-nowrap" (click)="exportAsCSV()">
                        <span class="fa-light fa-download"></span>
                        {{'DOWNLOAD_CSV' |translate}}
                    </span>
                </div>

                <div class="onlineStatusDevices" [@animationCollapse]>
                    <div>
                        <table matSort class="table table-hover" (matSortChange)="sortData($event)">
                            <thead>
                                <tr>
                                    <th mat-sort-header="id">ID</th>
                                    <th mat-sort-header="name">{{'DEVICE_NAME' |translate}}</th>
                                    <th mat-sort-header="location">{{'LOCATION' |translate}}</th>
                                    <th mat-sort-header="deviceType">{{'DEVICE_TYPE' |translate}}</th>
                                    <th mat-sort-header="softwareVersion">{{'DEVICE_SOFTWARE_VERSION'
                                        |translate}}</th>
                                    <th mat-sort-header="lastcon">{{'DEVICE_LAST_CONTACT' |translate}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dev of onlineStatus?.deviceList" (click)="openDevice(dev)"
                                    class="cursorPointer">
                                    <td>{{dev.id}}</td>
                                    <td>{{dev.name}}</td>
                                    <td>{{dev.locationName}}</td>
                                    <td>{{getDeviceType(dev.deviceType)}}</td>
                                    <td>{{dev.softwareVersion != 0 ? dev.softwareVersion : '-'}}</td>
                                    <td>{{dev.lastConnection |moment:'l LTS'}}</td>
                                    <td>
                                        <div *ngIf="dev.lowBatteryWarning">
                                            <span class="fa-solid fa-fw fa-lg"
                                                [ngClass]="service.getBatteryClass(dev.lowBatteryLevel)"></span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="countData === 0" class="statusBlockNoClick" [ngClass]="statusClass">
        <span class="statusCount" [ngClass]="statusClass">{{countData}}</span>
        <span class="statusHeader" [ngClass]="!onlineStatus.state ? '' : 'boldNormalSizeFont'">{{statusTitle}}</span>
    </div>
</div>

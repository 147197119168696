<div class="card">
    <div class="card-header-white header-underline">
        <h6 class="white-header-text">
            {{ "ADD_NEW_COMPANY_GROUP" | translate }}
        </h6>
        <span class="float-right cursorPointer" (click)="showForm.next(false)">
            <span class="fa-light fa-xmark white-header-xmark-icon"> </span>
        </span>
    </div>

    <form
        class="form"
        #registerCompanyGroup="ngForm"
        class="ml-3 pr-3"
        style="height: 650px; overflow: auto"
    >
        <div class="form-group m-3">
            <label for="groupName"
                ><b>{{ "COMPANY_GROUP_NAME" | translate }}</b></label
            >
            <input
                type="text"
                [(ngModel)]="companyGroupForm.name"
                name="groupName"
                id="groupName"
                class="form-control form-control-sm"
                [ngClass]="{
                    'is-invalid': !groupName.value,
                    'is-valid': groupName.value
                }"
                #groupName="ngModel"
                required
            />
        </div>

        <div class="form-group m-3">
            <label for="address"
                ><b>{{ "ADDRESS" | translate }}</b></label
            >
            <input
                type="text"
                [(ngModel)]="companyGroupForm.address"
                name="address"
                class="form-control form-control-sm"
                [ngClass]="{
                    'is-invalid': !address.value,
                    'is-valid': address.value
                }"
                id="address"
                #address="ngModel"
                required
            />
        </div>

        <div class="m-3">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="postOffice">
                            <b>{{ "POSTOFFICE" | translate }}</b>
                        </label>
                        <input
                            type="text"
                            [(ngModel)]="companyGroupForm.postOffice"
                            name="postOffice"
                            class="form-control form-control-sm"
                            [ngClass]="{
                                'is-invalid': !postOffice.value,
                                'is-valid': postOffice.value
                            }"
                            id="postOffice"
                            #postOffice="ngModel"
                            required
                        />
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="postCode">
                            <b>{{ "POSTCODE" | translate }}</b>
                        </label>
                        <input
                            type="text"
                            [(ngModel)]="companyGroupForm.postCode"
                            name="postCode"
                            class="form-control form-control-sm"
                            [ngClass]="{
                                'is-invalid': !postCode.value,
                                'is-valid': postCode.value
                            }"
                            id="postCode"
                            #postCode="ngModel"
                            required
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="m-3 pb-2">
            <label for="country">
                <b>{{ "COUNTRY" | translate }}</b>
            </label>
            <select
                [(ngModel)]="companyGroupForm.country"
                name="country"
                class="form-control form-control-sm"
                [ngClass]="{
                    'is-invalid invalidBgImgPosition': !country.value,
                    'is-valid': country.value
                }"
                id="country"
                #country="ngModel"
                required
            >
                <option value="">-</option>
                <option
                    *ngFor="let tz of timezones"
                    [value]="tz.country.alpha3Code"
                >
                    {{ tz.country.name }}
                </option>
            </select>

            <div class="mt-2">
                <p *ngIf="companyGroupForm.country" class="ml-1">
                    {{ "TIMEZONE" | translate }}:
                    <strong> {{ setTimezone() }} </strong>
                </p>
            </div>
        </div>

        <div class="m-3">
            <label for="language">
                <b>{{ "LANGUAGE" | translate }}</b>
            </label>
            <select
                [(ngModel)]="companyGroupForm.langId"
                name="language"
                class="form-control form-control-sm"
                id="language"
            >
                <option value="FIN">
                    {{ "FINNISH" | translate }}
                </option>
                <option value="ENG" selected>
                    {{ "ENGLISH" | translate }}
                </option>
                <option value="SWE">
                    {{ "SWEDISH" | translate }}
                </option>
                <option value="DEU">
                    {{ "GERMAN" | translate }}
                </option>
                <option value="FRA">
                    {{ "FRENCH" | translate }}
                </option>
                <option value="EST">
                    {{ "ESTONIAN" | translate }}
                </option>
            </select>
        </div>

        <hr />

        <div class="form-group m-3 pb-1">
            <label for="organizationNumber">
                <b>{{ "ORGANIZATION_NUMBER" | translate }}</b>
            </label>
            <input
                type="text"
                [(ngModel)]="companyGroupForm.organizationNumber"
                name="'organizationNumber"
                class="form-control form-control-sm"
                [ngClass]="{
                    'is-invalid': !organizationNumber.value,
                    'is-valid': organizationNumber.value
                }"
                id="organizationNumber"
                #organizationNumber="ngModel"
                required
            />
            <!--For example Y-tunnus in finland or EIN in USA-->
            <small class="ml-1" style="color: #1241cd">
                {{ "FOR_EXAMPLE_YTUNNUS_IN_FINLAND_OR_EIN_IN_USA" | translate }}
            </small>
        </div>

        <div class="form-group m-3 pb-2">
            <label for="contactPhone">
                <b>{{ "CONTACT_PHONE" | translate }}</b>
            </label>
            <input
                type="text"
                [(ngModel)]="companyGroupForm.contactPhone"
                name="contactPhone"
                class="form-control form-control-sm"
                [ngClass]="{
                    'is-invalid': !contactPhone.value,
                    'is-valid': contactPhone.value
                }"
                id="contactPhone"
                #contactPhone="ngModel"
                required
            />
        </div>

        <div class="m-3">
            <label for="server">
                <b>{{ "SERVER" | translate }}</b>
            </label>
            <select
                [(ngModel)]="companyGroupForm.server"
                (ngModelChange)="onServerSelected()"
                name="server"
                class="form-control form-control-sm"
                [ngClass]="{
                    'is-invalid invalidBgImgPosition': !server.value,
                    'is-valid': server.value
                }"
                id="server"
                #server="ngModel"
                required
            >
                <ng-container>
                    <option [value]="undefined" selected>-</option>
                    <option *ngFor="let server of serverList" [value]="server">
                        {{ server }}
                    </option>
                </ng-container>
            </select>
        </div>

        <div
            *ngIf="companySelect.companyOptions.length > 0"
            class="row"
            style="min-height: 150px"
        >
            <div class="col-sm-3">
                <div class="mt-1 ml-3">
                    <ngx-bootstrap-multiselect
                        class="ssDropUp ssDropupIcon"
                        [name]="'company' + i"
                        [options]="companySelect.companyOptions"
                        [texts]="companySelect.texts"
                        [settings]="companySelect.settings"
                        [(ngModel)]="companyGroupForm.companies"
                    >
                    </ngx-bootstrap-multiselect>
                </div>
            </div>
            <div class="col-sm-9">
                <div class="pl-5">
                    <table>
                        <thead>
                            <tr class="disableHover">
                                <th>
                                    <strong>{{
                                        "COMPANIES_TO_ADD" | translate
                                    }}</strong>
                                    ({{ "OPTIONAL" | translate }})
                                </th>
                                <th>
                                    <i
                                        *ngIf="
                                            companyGroupForm.companies.length >
                                            0
                                        "
                                        class="fa-solid fa-xmark cursorPointerHover mt-2"
                                        ngbTooltip="{{
                                            'REMOVE_ALL' | translate
                                        }}"
                                        [container]="'body'"
                                        placement="top"
                                        (click)="removeAllAddedCompanies()"
                                    ></i>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                *ngFor="
                                    let company of companyGroupForm.companies;
                                    let i = index
                                "
                                class="disableHover"
                            >
                                <td>
                                    {{ company.company }}
                                </td>
                                <td>
                                    <i
                                        class="fad fa-trash-alt cursorPointerHover"
                                        ngbTooltip="{{
                                            'REMOVE_COMPANY' | translate
                                        }}"
                                        [container]="'body'"
                                        placement="top"
                                        (click)="removeCompany(i)"
                                    ></i>
                                </td>
                            </tr>
                            <tr *ngIf="companyGroupForm.companies.length === 0">
                                <p class="ml-3 mt-2">
                                    {{ "NO_COMPANIES" | translate }}
                                </p>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="m-3">
            <div *ngIf="failureMessage" class="alert alert-danger" role="alert">
                {{ failureMessage | translate }}
            </div>

            <hr />
            <button
                type="submit"
                class="btn btn-primary mr-2"
                [disabled]="!registerCompanyGroup.form.valid"
                appThrottleClick
                (throttledClick)="saveCompanyGroup()"
            >
                <span class="fa-light fa-floppy-disk"></span>
                {{ "SAVE" | translate }}
            </button>

            <button
                class="btn btn-outline-primary gray-bg"
                (click)="showForm.next(false)"
            >
                <span class="fa-light fa-times"></span>
                {{ "CANCEL" | translate }}
            </button>
        </div>
    </form>
</div>

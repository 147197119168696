import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment-timezone';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../../../services/api.service';
import { NgxBootstrapMultiselectModule, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { Chart } from 'chart.js'
import * as jsPDF from 'jspdf'
import * as GraphOptions from './graphs.options'
import { ReportFilter, FilterOptions, GlobalVariables } from 'app/services/helper.service';
import { ReportRequestData } from '../../../models/reportrequestdata.model';
import { DateAdapter, MatOptionModule } from '@angular/material/core';
import { DeviceReportComponent } from '../device-report/device-report.component';
import { EMAACStatisticsComponent } from '../emaac-statistics/emaac-statistics.component';
import { ReportSummaryComponent } from '../report-summary/report-summary.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'graphs',
    templateUrl: './graphs.component.html',
    styleUrls: ['./graphs.component.css'],
    standalone: true,
    imports: [NgIf, MatFormFieldModule, MatSelectModule, FormsModule, MatOptionModule, NgFor, MatInputModule, MatDatepickerModule, NgxBootstrapMultiselectModule, ReportSummaryComponent, EMAACStatisticsComponent, DeviceReportComponent, TranslateModule]
})
export class GraphsComponent implements OnInit, OnDestroy {

  /**
   * SERVICES
   */
  constructor(
    private translate: TranslateService,
    private api: ApiService,
    private globals: GlobalVariables,
    private dateAdapter: DateAdapter<any>
  ) { }

  /**
   * LOCAL VARIABLES
   */
  chart: Chart;
  graphData: Array<any>;
  graphTypes: GraphType[] = [];
  filterGraphType: any = "";
  dataLoaded: boolean;
  advancedFilterState: boolean;
  advancedFiltersLoaded: boolean = false;
  advancedFilterBaseTexts: IMultiSelectTexts;

  currentDate: Date = new Date();
  dateLimit: Date = moment().subtract(4, 'months').toDate();

  //filter options
  startDate: Date = moment().subtract(1, 'd').toDate();
  endDate: Date = new Date();

  advancedFilter: ReportFilter = {
    SumAvg: "sum",
    settings: {
      enableSearch: true,
      showUncheckAll: true,
      dynamicTitleMaxItems: 0,
      checkedStyle: 'fontawesome',
      buttonClasses: "btn btn-outline-dark fullWidth"
    },
    device: new FilterOptions,
    location: new FilterOptions,
    alertRoute: new FilterOptions,
    basestation: new FilterOptions,
    personnel: new FilterOptions
  };


  startDateEventHandler($event: any) {
    this.startDate = $event;
  }

  endDateEventHandler($event: any) {
    this.endDate = $event;
  }

  /**
   * FUNCTIONS
   */
  //Function is initialized on page load
  ngOnInit(): void {
    this.dateAdapter.setLocale(localStorage.getItem('language'))

    this.initGraphTypes();
    if (!this.globals.reportFilters) {
      this.api.getReportFilteringOptions()
        .subscribe(res => {
          this.advancedFilter.device.filterList = res.devices.sort((a, b) => {
            return a.name > b.name;
          })
          this.advancedFilter.alertRoute.filterList = res.alertRoutes.sort((a, b) => {
            return a.name > b.name;
          })
          this.advancedFilter.location.filterList = res.locations.sort((a, b) => {
            return a.name > b.name;
          })
          this.advancedFilter.basestation.filterList = res.basestations.sort((a, b) => {
            return a.name > b.name;
          })
          this.advancedFilter.personnel.filterList = res.personnel.sort((a, b) => {
            return a.name > b.name;
          })
          this.advancedFiltersLoaded = true;
          this.globals.reportFilters = this.advancedFilter;
        });
    } else {
      this.advancedFilter = this.globals.reportFilters;
      this.advancedFiltersLoaded = true;
    }
    this.translate.get(['DATE_FROM', 'DATE_TO', 'FILTER_CHECK_ALL', 'FILTER_UNCHECK_ALL', 'FILTER_CHECKED', 'FILTER_ALL_SELECTED', 'SEARCH']).subscribe(t => {

      this.advancedFilterBaseTexts = {
        checkAll: t.FILTER_CHECK_ALL,
        uncheckAll: t.FILTER_UNCHECK_ALL,
        checked: t.FILTER_CHECKED,
        checkedPlural: t.FILTER_CHECKED,
        searchPlaceholder: t.SEARCH,
        allSelected: t.FILTER_ALL_SELECTED
      }
    })
    this.translate.get(['FILTER_DEVICES', 'FILTER_ALARM_ROUTINGS', 'FILTER_LOCATIONS', 'FILTER_BASESTATIONS', 'FILTER_STAFF_MEMBERS']).subscribe(t => {
      this.advancedFilter.device.texts = Object.assign({}, this.advancedFilterBaseTexts, { defaultTitle: t.FILTER_DEVICES })
      this.advancedFilter.alertRoute.texts = Object.assign({}, this.advancedFilterBaseTexts, { defaultTitle: t.FILTER_ALARM_ROUTINGS })
      this.advancedFilter.location.texts = Object.assign({}, this.advancedFilterBaseTexts, { defaultTitle: t.FILTER_LOCATIONS })
      this.advancedFilter.basestation.texts = Object.assign({}, this.advancedFilterBaseTexts, { defaultTitle: t.FILTER_BASESTATIONS })
      this.advancedFilter.personnel.texts = Object.assign({}, this.advancedFilterBaseTexts, { defaultTitle: t.FILTER_STAFF_MEMBERS })
    })
  }

  ngOnDestroy(): void {
    this.advancedFilter.device.selectedList = null;
    this.advancedFilter.alertRoute.selectedList = null;
    this.advancedFilter.location.selectedList = null;
    this.advancedFilter.basestation.selectedList = null;
    this.advancedFilter.personnel.selectedList = null;
  }

  initGraphTypes(): void {
    this.translate.get(['GRAPH_EMA', 'GRAPH_COUNT_BY_HOUR', 'GRAPH_COUNT_BY_DAY_OF_WEEK', 'GRAPH_FULL_DURATION', 'GRAPH_PATIENT_WAIT_TIME', 'GRAPH_CARE_TIME_USAGE', 'GRAPH_PERSONNEL_EFFECTIVITY', 'ALERTS_SUMMARY', 'EMAAC_STATISTICS', 'DEVICES']).subscribe(t => {
      this.graphTypes.push(
        { type: "bar", name: t.GRAPH_COUNT_BY_HOUR, statsType: "CountByHour", dataType: "trend", group: 1 },
        { type: "bar", name: t.GRAPH_COUNT_BY_DAY_OF_WEEK, statsType: "CountByDayOfWeek", dataType: "trend", group: 1 },
        { type: "pie", name: t.GRAPH_FULL_DURATION, statsType: "FullDuration", groupBy: "Customer", lowerCountLimit: 60, upperCountLimit: 600, dataType: "stats", group: 1 },
        //{ type: "barEMA", name: t.GRAPH_EMA, dataType: "ema", groupBy: "Device", group: 1},
        //{ type: "horizontalBar", name: t.GRAPH_PATIENT_WAIT_TIME, statsType: "PatientWaitTime", groupBy: "Device", dataType: "stats", group: 1 },
        { type: "pie", name: t.GRAPH_CARE_TIME_USAGE, statsType: "CareTimeUsage", groupBy: "Customer", lowerCountLimit: 60, upperCountLimit: 600, dataType: "stats", group: 1 },
        //{ type: "horizontalBar", name: t.GRAPH_PERSONNEL_EFFECTIVITY, statsType: "PersonnelEffectivity", groupBy: "Device", dataType: "stats", group: 1 },
        { type: "alertSummaryData", name: t.ALERTS_SUMMARY, group: 2 },
        { type: "deviceData", name: t.DEVICES, group: 2 },
      )

      if (this.isFeatureEnabled('EMAAC')) {
        this.graphTypes.push({ type: "emaacStatisticsData", name: t.EMAAC_STATISTICS, group: 2 });
      }
    })
  }

  drawGraph(): void {
    let chartConf;
    this.translate.get(['PRE_SYSTEM_TIME', 'RESPONSE_TIME', 'CARE_TIME_USAGE', 'LESS_THAN_MINUTE', 'MINUTE_TO_TEN_MINUTES', 'OVER_TEN_MINUTES', 'SECONDS']).subscribe(t => {
      switch (this.filterGraphType.type) {
        case "horizontalBar":
          chartConf = GraphOptions.HorizontalBarDefOptions;
          chartConf.options.title.text = this.filterGraphType.name;
          chartConf.options.scales.xAxes[0].scaleLabel.labelString = t.SECONDS;
          chartConf.data.labels = this.graphData.map(doc => doc.groupName + " (" + doc.groupId + ")");
          chartConf.data.datasets[0].label = this.filterGraphType.name + " (" + this.advancedFilter.SumAvg + ")";
          chartConf.data.datasets[0].data = this.graphData.map(doc => {
            if (this.advancedFilter.SumAvg === 'sum') {
              return doc.durationSum;
            } else {
              return doc.durationAvg;
            }
          });
          break;
        case "pie":
          chartConf = GraphOptions.PieDefOptions;
          chartConf.options.title.text = this.filterGraphType.name;
          chartConf.data.labels = [t.LESS_THAN_MINUTE, t.MINUTE_TO_TEN_MINUTES, t.OVER_TEN_MINUTES];
          let pieData = [this.graphData[0].limitCounts["0"], this.graphData[0].limitCounts["1"], this.graphData[0].limitCounts["2"]]
          chartConf.data.datasets[0].data = pieData;
          break;
        case "bar":
          chartConf = GraphOptions.BarDefOptions;
          chartConf.options.title.text = this.filterGraphType.name;
          if (this.filterGraphType.statsType === "CountByDayOfWeek") {
            chartConf.data.labels = this.translateDayOfWeek(this.graphData);
          } else {
            chartConf.data.labels = this.graphData.map(doc => doc.label);
          }
          chartConf.data.datasets[0].label = this.filterGraphType.name;
          chartConf.data.datasets[0].data = this.graphData.map(doc => doc.count);
          break;
        case "barEMA":
          chartConf = GraphOptions.HorizontalBarEmaOptions;
          chartConf.options.title.text = this.filterGraphType.name;
          chartConf.options.scales.xAxes[0].scaleLabel.labelString = t.SECONDS;
          chartConf.data.labels = this.graphData.map(doc => doc.groupName + " (" + doc.groupId + ")");
          chartConf.data.datasets[0].label = t.PRE_SYSTEM_TIME;
          chartConf.data.datasets[0].data = this.graphData.map(doc => {
            if (this.advancedFilter.SumAvg === 'sum') {
              return doc.preSystemTimeSum
            } else {
              return doc.preSystemTimeAvg
            }
          });
          chartConf.data.datasets[1].label = t.RESPONSE_TIME;
          chartConf.data.datasets[1].data = this.graphData.map(doc => {
            if (this.advancedFilter.SumAvg === 'sum') {
              return doc.responseTimeSum
            } else {
              return doc.responseTimeAvg
            }
          });
          chartConf.data.datasets[2].label = t.CARE_TIME_USAGE;
          chartConf.data.datasets[2].data = this.graphData.map(doc => {
            if (this.advancedFilter.SumAvg === 'sum') {
              return doc.careTimeUsageSum
            } else {
              return doc.careTimeUsageAvg
            }
          });
          break;
        default:
          break;
      }
    })

    this.chart = new Chart("mainChart", chartConf);
    if (this.filterGraphType.type === "horizontalBar" || this.filterGraphType.type === "barEMA") {
      //Calculate canvas height based on data rows
      let height = (this.graphData.length * 25) + 250;
      this.chart.canvas.parentElement.style.height = height + "px";
    }
  }

  translateDayOfWeek(data: any): string[] {
    let translatedNames: string[] = [];
    data.forEach(el => {
      let str = "DAYOFWEEK_" + el.label
      this.translate.get(str).subscribe((t: string) => {
        translatedNames.push(t);
      })
    });
    return translatedNames;
  }

  downloadPDF(): void {
    let pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'mm'
    });
    pdf.text(this.filterGraphType.name, 10, 10)
    pdf.addImage(this.chart.toBase64Image(), 'PNG', 15, 40);
    pdf.save(this.filterGraphType.value + '.pdf')
  }

  loadGraph(): void {
    if (this.chart) this.chart.destroy();
    this.dataLoaded = false;

    let data = new ReportRequestData();
    data.fromTimestamp = moment(this.startDate).startOf('day');
    data.toTimestamp = moment(this.endDate).endOf('day');
    //Add filters to request if they exists
    if (this.filterGraphType.statsType) {
      data.statsType = this.filterGraphType.statsType;
    }
    if (this.filterGraphType.groupBy) {
      data.groupBy = this.filterGraphType.groupBy;
    }
    if (this.advancedFilter.device.selectedList) {
      data.deviceFilter = this.advancedFilter.device.selectedList;
    }
    if (this.advancedFilter.location.selectedList) {
      data.locationFilter = this.advancedFilter.location.selectedList;
    }
    if (this.advancedFilter.alertRoute.selectedList) {
      data.alertRouteFilter = this.advancedFilter.alertRoute.selectedList;
    }
    if (this.advancedFilter.basestation.selectedList) {
      data.basestationFilter = this.advancedFilter.basestation.selectedList;
    }
    if (this.advancedFilter.personnel.selectedList) {
      data.personnelFilter = this.advancedFilter.personnel.selectedList;
    }
    if (typeof this.filterGraphType.lowerCountLimit !== 'undefined' && typeof this.filterGraphType.upperCountLimit !== 'undefined') {
      data.countLimits = {
        lowerLimit: this.filterGraphType.lowerCountLimit,
        upperLimit: this.filterGraphType.upperCountLimit
      }
    }
    this.api.getReportData(data, this.filterGraphType.dataType)
      .subscribe(res => {
        this.dataLoaded = true;
        if (res != null) {
          this.graphData = res;
          this.graphData.sort((a, b) => {
            if (this.advancedFilter.SumAvg === 'sum') {
              return parseFloat(b.durationSum) - parseFloat(a.durationSum);
            } else {
              return parseFloat(b.durationAvg) - parseFloat(a.durationAvg);
            }
          })
          this.drawGraph();
        } else {
          this.graphData = null;
        }
      })
  }

  isFeatureEnabled(feature: string): boolean {
    let features: [string] = JSON.parse(localStorage.getItem('features'));
    if (features) {
      return features.includes(feature);
    }
    return false;
  }


}

class GraphType {
  statsType?: string;
  name: string;
  type: string;
  groupBy?: string;
  dataType?: string;
  lowerCountLimit?: number;
  upperCountLimit?: number;
  //Group 1 stat types (graphs) share a common time filters
  //Group 2 stat elements has its own time filters
  group: number;
}

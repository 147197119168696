<div id="container">
    <div class="item" [ngSwitch]="log.area">
        <app-activity-staff-details *ngSwitchCase="'Staff'" (closeDialog)="close()" [log]="log"></app-activity-staff-details>

        <ng-container *ngSwitchCase="'Digital Supervision'" [ngSwitch]="log.details.targetClass">
            <app-activity-camera-visit-details *ngSwitchCase="'Scheduled visit'" (closeDialog)="close()" [log]="log"></app-activity-camera-visit-details>
            <!-- Use camera group details as default element, since some of the older log might not be visible due to missing targetClass element -->
            <app-activity-camera-group-details *ngSwitchDefault (closeDialog)="close()" [log]="log"></app-activity-camera-group-details>
        </ng-container>
    </div>
    <div class="item">
        <i class="fa-solid fa-xmark fa-lg cursorPointer" style="color: #707070" (click)="close()"></i>
    </div>
</div>

import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../../../services/api.service';
import { HelperService, GlobalVariables, ReportFilter, FilterOptions } from 'app/services/helper.service';
import { DateAdapter, MatOptionModule } from '@angular/material/core';
import { EMAACStatisticsRequest, ReportRequestData } from '../../../models/reportrequestdata.model'
import * as moment from 'moment-timezone';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MomentPipe } from '../../../pipes/pipes';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

interface IEmaacStatistics {
  averageResponseTime: string,
  medianResponseTime: string,
  fastestResponseTime: string,
  longestResponseTime: string,
  alertResponseTimes: IAlertResponseTime[]
}

interface IAlertResponseTime {
  timestamp: string,
  deviceName: string,
  deviceType: string,
  deviceId: string,
  responseTime: number
  formattedResponseTime?: string
}

@Component({
    selector: 'app-emaac-statistics',
    templateUrl: './emaac-statistics.component.html',
    styleUrls: ['./emaac-statistics.component.css'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, FormsModule, MatSelectModule, ReactiveFormsModule, NgFor, MatOptionModule, NgIf, MatTableModule, MatSortModule, TranslateModule, MomentPipe]
})
export class EMAACStatisticsComponent implements OnInit {

  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private globals: GlobalVariables,
    private helper: HelperService
  ) { }

  @ViewChild(MatSort) sort: MatSort;

  //Variables
  meanRadioValue: boolean = true;
  medianRadioValue: boolean = false;
  dataLoading: boolean = false;
  noDataFound: boolean = false;
  pageViewInitialized: boolean = false;
  displayedColumns: string[] = ['timestamp', 'deviceName', 'deviceType', 'deviceId', 'responseTime'];


  tableData: any;
  averageResponseTime: string;
  medianResponseTime: string;
  fastestResponseTime: string;
  longestResponseTime: string;
  averageOrMeanResTime: string;

  //filter variables
  alertRoutes: FilterOptions;
  selectedAlertRoutes = new UntypedFormControl('');

  currentDate: Date = new Date();
  dateLimit: Date = moment().subtract(4, 'months').toDate();

  @Input() startDate: Date;
  @Input() endDate: Date;

  @Output() startDateChanged: EventEmitter<Date> = new EventEmitter();
  @Output() endDateChanged: EventEmitter<Date> = new EventEmitter();

  onStartDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.startDate = event.value;
    this.startDateChanged.emit(this.startDate);
  }

  onEndDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.endDateChanged.emit(event.value);
  }

  loadStatistics(): void {
    this.pageViewInitialized = true;
    this.dataLoading = true;
    this.noDataFound = true;

    const request = this.setRequestFilters();

    this.api.getEmaacStatistics(request)
      .subscribe((res: IEmaacStatistics) => {
        if (res != null) {

          this.averageResponseTime = this.convertSeconds(parseFloat(res.averageResponseTime));
          this.medianResponseTime = this.convertSeconds(parseFloat(res.medianResponseTime));
          this.fastestResponseTime = this.convertSeconds(parseInt(res.fastestResponseTime));
          this.longestResponseTime = this.convertSeconds(parseInt(res.longestResponseTime));

          this.averageOrMeanResTime = this.meanRadioValue ? this.averageResponseTime : this.medianResponseTime;

          res.alertResponseTimes.forEach(alertResponseTime => {
            alertResponseTime.timestamp = moment(alertResponseTime.timestamp).format('YYYY-MM-DD HH:mm:ss');
            alertResponseTime.deviceType = this.helper.getDeviceType(alertResponseTime.deviceType);
            alertResponseTime.formattedResponseTime = this.convertSeconds(alertResponseTime.responseTime);
          })

          this.tableData = new MatTableDataSource(res.alertResponseTimes);

          this.tableData.sortingDataAccessor = (row :IAlertResponseTime, column) => {
            switch (column) {
              case 'responseTime': {
                return row.responseTime;
              }
              default: {
                return row[column];
              }
            }
          };

          this.tableData.sort = this.sort;

          this.dataLoading = false;
          this.noDataFound = false;
        }
        else {
          this.dataLoading = false;
        }
      })
  }

  setRequestFilters(): EMAACStatisticsRequest {
    let request = new EMAACStatisticsRequest();

    request.fromTimestamp = moment(this.startDate).utc(true).startOf('day');
    request.toTimestamp = moment(this.endDate).utc(true).endOf('day');

    request.alertRouteFilter = this.selectedAlertRoutes.value;

    return request;
  }

  onRadioClick(name: string): void {
    switch (name) {
      case 'mean':
        this.meanRadioValue = true;
        this.medianRadioValue = false;
        this.averageOrMeanResTime = this.averageResponseTime;
        break;
      case 'median':
        this.medianRadioValue = true;
        this.meanRadioValue = false;
        this.averageOrMeanResTime = this.medianResponseTime;
        break;
    }
  }

  convertSeconds(timeInSeconds: number): string {
    if (timeInSeconds > 3599) {
      return `${Math.floor(timeInSeconds / 3600)} h ${Math.floor(timeInSeconds % 3600 / 60)} min ${timeInSeconds % 60} s`;
    }
    else if (timeInSeconds > 59) {
      return `${Math.floor(timeInSeconds % 3600 / 60)} min ${timeInSeconds % 60} s`;
    }
    else {
      return `${timeInSeconds % 60} s`;
    };
  }

  ngOnInit(): void {
    this.api.getReportFilteringOptions()
      .subscribe(res => {
        this.alertRoutes = res.alertRoutes.sort((a, b) => {
          return a.name > b.name;
        })
      })
  }
}

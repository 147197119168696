<h3>{{'ALARM_ROUTE_MANAGEMENT' |translate}}</h3>


<div class="row">
    <div class="col-12">

        <ng-container *ngIf="!showEditForm">
            <div [ngClass]="{'d-none d-xl-block': showInformationForm}" class="mb-2">
                <i class="fa-solid fa-diamond ml-2 defaultRouteIcon"></i>
                <small> = {{'DEFAULT_ROUTE' |translate}}</small>
                <i class="fa-solid fa-circle ml-2 enabledRouteIcon"></i>
                <small> = {{'ROUTE_ENABLED' |translate}}</small>
                <i class="fa-solid fa-square ml-2 disabledRouteIcon"></i>
                <small> = {{'ROUTE_DISABLED' |translate}}</small>
            </div>

            <div class="btn-group mb-3 mt-3" role="group" aria-label="">
                <button *ngIf="user.roleLevel >= 100" class="btn btn-primary"
                    (click)="createNewRoute(); closeInformationForm()"><span class="fa-light fa-fw fa-plus"></span>
                    {{'CREATE_NEW_ROUTE' |translate}}</button>

                <button class="btn btn-outline-primary"
                    [ngClass]="{'ml-2 float-right' : user.roleLevel>= 100, 'd-none d-xl-block' : showInformationForm}"
                    (click)="showDefaultRoutes = !showDefaultRoutes"><span
                        [ngClass]="showDefaultRoutes ? 'fa-regular fa-check-square': 'fa-light fa-square'"></span>
                    {{'SHOW_DEFAULT_ROUTES' |translate}}</button>
            </div>
        </ng-container>


    </div>


</div>


<div class="row p-2">

    <div [ngClass]="showInformationForm ? ['col-lg-7', 'd-none', 'd-xl-block']: 'col-lg-7 col-md-12'"
        *ngIf="!showEditForm">

        <ng-container *ngFor="let location of alarmRouteLocations; let i = index">
            <div class="card" [ngClass]="{'ml-4': !isCustomerLocation(location) && showCustomerLocation()}">
                <div (click)="toggleCollapseState(location)"
                    class="card-header-white rounded-bottom-corners cursorPointerHover">
                    <h5 class="white-header-text">
                        <span *ngIf="isCustomerLocation(location)" class="fa-fw mr-2"
                            [ngClass]="location.collapsed ? 'fa-light fa-user' : 'fa-solid fa-user'"></span>
                        <span *ngIf="!isCustomerLocation(location)" class="fa-fw mr-2"
                            [ngClass]="location.collapsed ? 'fa-light fa-house' : 'fa-solid fa-house'"></span>
                        {{location.locationName}}
                    </h5>
                </div>
                <div *ngIf="location.collapsed " class="card-body-white">
                    <table class="table table-borderless table-hover table-responsive" matSort
                        (matSortChange)="sortData($event, i)">
                        <thead>
                            <tr class="border-bottom">
                                <th style="width:1%;"></th>
                                <th mat-sort-header="name">{{'NAME' |translate}}</th>
                                <th mat-sort-header="serviceTimeValidity">{{'SERVICE_TIME_VALIDITY' |translate}}
                                </th>
                                <th mat-sort-header="deliveryType">{{'DELIVERY_TYPE' |translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="location.collapsed">
                                <ng-container *ngIf="location.routes.length > 0">
                                    <tr class="cursorPointerHover" *ngFor="let route of location.routes;"
                                        (click)="newRoute=false; showRouteInformation(route.id); ">

                                        <ng-container *ngIf="route.defaultRoute && showDefaultRoutes">
                                            <td><i class="fa-solid fa-diamond defaultRouteIcon"></i></td>
                                            <td>{{route.name}} ({{'DEFAULT_ROUTE' | translate}})</td>
                                            <td>
                                                {{parseServiceTime(route.serviceTimeValidity,
                                                route.commonServiceTime) | translate}}
                                            </td>
                                            <td>
                                                <span matTooltip="{{route.deliveryType | translate}}"
                                                    [ngClass]="deliveryTypeIconMap.get(route.deliveryType)"></span>
                                            </td>

                                        </ng-container>

                                        <ng-container *ngIf="!route.defaultRoute">
                                            <td>
                                                <i *ngIf="!route.enabled"
                                                    class="fa-solid fa-square disabledRouteIcon"></i>
                                                <i *ngIf="route.enabled"
                                                    class="fa-solid fa-circle enabledRouteIcon"></i>
                                            </td>
                                            <td>{{route.name}}</td>
                                            <td>
                                                {{parseServiceTime(route.serviceTimeValidity,
                                                route.commonServiceTime) | translate}}
                                            </td>
                                            <td>
                                                <span matTooltip="{{route.deliveryType | translate}}"
                                                    [ngClass]="deliveryTypeIconMap.get(route.deliveryType)"></span>
                                            </td>

                                        </ng-container>

                                    </tr>
                                </ng-container>

                                <ng-container *ngIf="location.routes.length <= 0">
                                    <tr>
                                        <td></td>
                                        <td colspan="3">
                                            <p class="text-center">{{'NO_ALARM_ROUTES' | translate}}</p>
                                        </td>
                                    </tr>
                                </ng-container>

                            </ng-container>

                        </tbody>

                    </table>
                </div>
            </div>
        </ng-container>

        <div *ngIf="!alarmRouteLocations">
            <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg">
        </div>
    </div>

    <div class="col-lg-12" *ngIf="showEditForm && selectedRoute">
        <alarm-route-edit [route]="selectedRoute" [callCenters]="callCenters"
            [alarmRouteLocations]="alarmRouteLocations" (closeEditForm)="showEditForm=false;" [newRoute]="newRoute"
            [startTab]="activeTabIndex" (routeCreated)="routeCreated()"
            (routeEdited)="routeEdited($event)"></alarm-route-edit>
    </div>

    <div class="col-xl-5 col-lg-12" *ngIf="showInformationForm && !showEditForm">
        <alarm-route-info [route]="selectedRoute" [callCenters]="callCenters" (routeDeleted)="routeDeleted($event)"
            (closeInformationForm)="closeInformationForm();" (activeTabIndexEmitter)="setActiveTabIndex($event)"
            (showEditForm)="showEditForm=true"></alarm-route-info>
    </div>
</div>

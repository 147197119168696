import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { ApiService } from "app/services/api.service";
import { Company } from "app/models/company.models";
import { ITimezone, TIMEZONES } from "../../timezones";
import { TranslateModule } from "@ngx-translate/core";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "company-info",
    templateUrl: "./company-info.component.html",
    styleUrls: ["./company-info.component.css"],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, FormsModule, ReactiveFormsModule, TranslateModule]
})
export class CompanyInfoComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private modalService: NgbModal
  ) {}

  editMode: boolean = false;
  deleteConfirmation: boolean = false;

  @Input() showInfoForm: BehaviorSubject<boolean>;
  @Input() company: Company;

  @Input() successMsg: BehaviorSubject<string>;
  @Input() failedMsg: BehaviorSubject<string>;

  @Output() companyUpdated: EventEmitter<boolean> = new EventEmitter();

  companyDataStorage: Company;
  summary: ICompanySummary = null;
  editCompanyForm: UntypedFormGroup;

  timezones: ITimezone[] = TIMEZONES;
  errorDeleteMsg: "ERROR" | "" = "";

  createForm(data: Company): void {
    this.editCompanyForm = this.fb.group({
      companyId: [data.companyId],
      companyName: [data.companyName],
      address: [data.address],
      postCode: [data.postCode],
      postOffice: [data.postOffice],
      country: [data.country],
      timeZone: [data.timeZone],
      contactPhone: [data.contactPhone],
      deliveryMethod: [data.deliveryMethod],
      server: [data.server],
    });
  }

  onSubmit(): void {
    this.api
      .updateCompany(this.editCompanyForm.value)
      .subscribe((res: boolean) => {
        if (res) {
          this.successMsg.next("COMPANY_UPDATE_SUCCESS");
          this.companyUpdated.emit(true);
        } else {
          this.successMsg.next("COMPANY_UPDATE_FAILED");
        }
        this.showInfoForm.next(false);
      });
  }

  deleteCompanyRequest(): void {
    this.api
      .deleteCompany(this.company.companyId, this.company.server)
      .subscribe(
        (res) => {
          this.showInfoForm.next(false);
          this.companyUpdated.emit(true);
        },
        (err) => {
          console.log(err);
          this.deleteConfirmation = false;
        }
      );
  }

  getTimeZoneName(countryCode: string): string {
    return countryCode
      ? this.timezones.find((t) => t.country.code === countryCode).name ?? "-"
      : "-";
  }

  getCountryName(countryCode: string): string {
    return countryCode
      ? this.timezones.find((c) => c.country.code === countryCode).country
          .name ?? "-"
      : "-";
  }

  // Set time zone to form and return name of the timezone
  setTimeZone(countryCode: string): string {
    const timezone = this.timezones.find((t) => t.country.code === countryCode);
    if (timezone) {
      this.editCompanyForm.get("timeZone").setValue(timezone.timezone);
      return timezone.name;
    }
    return "-";
  }

  openDialog(dialogElement: any) {
    this.errorDeleteMsg = "";
    if (this.summary) {
      this.deleteConfirmation = true;
      this.modalService.open(dialogElement, { centered: true }).result.then(
        () => {
          this.deleteConfirmation = false;
        },
        () => {
          this.deleteConfirmation = false;
        }
      );
    } else {
      this.api
        .getCompanySummary(this.company.companyId, this.company.server)
        .subscribe(
          (res: ICompanySummary) => {
            this.summary = res;
            this.deleteConfirmation = true;
            this.modalService
              .open(dialogElement, { centered: true })
              .result.then(
                () => {
                  this.deleteConfirmation = false;
                },
                () => {
                  this.deleteConfirmation = false;
                }
              );
          },
          (error) => {
            this.deleteConfirmation = false;
            this.errorDeleteMsg = "ERROR";
            console.log(error);
          }
        );
    }
  }

  ngOnInit(): void {
    this.createForm(this.company);
    this.companyDataStorage = this.company;
  }

  get selectedCountry(): string {
    return this.editCompanyForm.controls.country.value;
  }
}

interface ICompanySummary {
  deviceCount: number;
  staffCount: number;
  locationCount: number;
  alertRouteCount: number;
}

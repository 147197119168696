import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { FeatureBadgeComponent } from "app/components/feature-badge/feature-badge.component";
import { ActivityLogService } from "app/services/activity-log.service";
import { TranslateModule } from "@ngx-translate/core";
import { HighlightLinkSearchPipe, HighlightSearch, MomentPipe, PropsFalsyPipe } from "app/pipes/pipes";
import { Router, RouterModule } from "@angular/router";
import { AnchorWrapPipe, ParseTargetClassPipe, ResolveActivityBadgeStatusPipe } from "./activity-log-table-pipes";
import { ActivityTargetClass, IActivityLog } from "app/models/activity-log.model";
import { Observable } from "rxjs";
import { ScrollNearEndDirective } from "app/directives/scroll-near-end.directive";
import { MatDialog } from "@angular/material/dialog";
import { ActivityDetailsDialogComponent } from "../activity-details-dialog/activity-details-dialog.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: "app-activity-log-table",
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    FeatureBadgeComponent,
    TranslateModule,
    MomentPipe,
    RouterModule,
    AnchorWrapPipe,
    HighlightSearch,
    HighlightLinkSearchPipe,
    ScrollNearEndDirective,
    PropsFalsyPipe,
    ParseTargetClassPipe,
    ResolveActivityBadgeStatusPipe,
  ],
  templateUrl: "./activity-log-table.component.html",
  styleUrls: ["./activity-log-table.component.css"],
})
export class ActivityLogTableComponent implements OnInit {
  constructor(
    public activityLogService: ActivityLogService,
    public router: Router,
    private dialog: MatDialog
  ) { }

  displayedColumns: string[] = [
    "timestamp",
    "area",
    "user",
    "action",
    "details",
  ];
  activityLogs$: Observable<IActivityLog[]>;

  get scrollDisabled(): boolean {
    return this.activityLogService.infiniteScrollDisabled;
  }

  get searchText(): string {
    return this.activityLogService.filters.search;
  }

  ngOnInit(): void {
    this.activityLogs$ = this.activityLogService.activityLogs$;
  }

  onRowClicked(log: IActivityLog, event: MouseEvent): void {
    const target = event.target as HTMLElement;

    // Don't open the dialog if the user clicked on a link or the log is not a scheduled visit and the action is not modify
    if (
      target.tagName.toLocaleLowerCase() === "a" ||
      log.details.targetClass !== ActivityTargetClass.ScheduledVisit &&
      log.action.toLocaleLowerCase() !== "modify"
    ) {
      return;
    }

    this.dialog.open(ActivityDetailsDialogComponent, {
      data: log,
      width: "45%",
      position: { top: "5%" },
      disableClose: true,
      autoFocus: false,
    });
  }

  onNearBottomScroll(): void {
    // Don't fetch more logs if we have reached the end of the logs or the previous request is still loading
    if (this.scrollDisabled || this.activityLogService.activityLogsLoading) {
      return;
    }
    this.activityLogService.getNextLogBatch();
  }

  onCellClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains("link")) {
      const url = target.getAttribute("data-url");
      if (url) {
        this.router.navigateByUrl(url);
      }
    }
  }
}

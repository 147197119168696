import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.css'],
    standalone: true,
    imports: [MatToolbarModule, NgIf, MatButtonModule, MatIconModule, MatMenuModule, TranslateModule]
})
export class ToolbarComponent implements OnInit {
  @Input() user;
  @Input() language;
  @Input() loggedCustomer;
  @Input() landingPage;
  @Input() onMobileScreen;
  @Output() openSideNav = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onSideNavOpen() {
    this.openSideNav.emit();
  }
}

import { FormsModule } from "@angular/forms";
import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatRadioModule } from '@angular/material/radio';
import { ApiService } from "app/services/api.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ThrottleButtonClickDirective } from "app/directives/throttle-button-click.directive";
import { DeviceStatus, ReplaceDeviceForm, DeviceReplacementStatus } from "app/models/device.model";

interface IReplaceOptions {
  label: string;
  value: DeviceStatus;
}

@Component({
  selector: 'app-replace-device-dialog',
  templateUrl: './replace-device-dialog.component.html',
  styleUrls: ['./replace-device-dialog.component.css'],
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    ThrottleButtonClickDirective,
    CommonModule,
    MatDialogModule,
    MatRadioModule
  ]
})
export class ReplaceDeviceDialogComponent {
  constructor(
    private api: ApiService,
    private dialogRef: MatDialogRef<ReplaceDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public form: ReplaceDeviceForm
  ) {
    // Set the default value of the radio button to SERVICE
    this.form.deviceStatus = "SERVICE";
  }

  errorMsg: string;
  replacing: boolean = false;

  options: IReplaceOptions[] = [
    { label: "SEND_DEVICE_TO_SERVICE", value: "SERVICE" },
    { label: "DEVICE_IS_LOST", value: "LOST" },
    { label: "MOVE_DEVICE_TO_INVENTORY", value: "CUSTOMER_INVENTORY" },
    { label: "OTHER", value: "CUSTOMER_INVENTORY" },
  ]

  replaceDevice(): void {
    this.errorMsg = "";
    this.replacing = true;

    this.api.replaceDevice(this.form).subscribe(
      () => {
        this.replacing = false;
        this.dialogRef.close({ replaced: true });
      },
      (e) => {
        switch (e.error.status as DeviceReplacementStatus) {
          case DeviceReplacementStatus.ReplaceDeviceNotValid:
            this.errorMsg = "INVALID_DEVICE";
            break;
          case DeviceReplacementStatus.ReplaceDeviceAlreadyInUse:
            this.errorMsg = "DEVICE_IN_USE";
            break;
          case DeviceReplacementStatus.GenericError:
            this.errorMsg = "ERROR";
            break;
          case DeviceReplacementStatus.DeviceNotReplaceableWithGivenType:
            this.errorMsg = "DEVICE_NOT_REPLACEABLE_WITH_GIVEN_TYPE";
            break;
          case DeviceReplacementStatus.DeviceReplacementNotSupported:
            this.errorMsg = "REPLACEMENT_NOT_SUPPORTED";
            break;
          case DeviceReplacementStatus.ReplacedDeviceNotOffline:
            this.errorMsg = "REPLACED_DEVICE_NOT_OFFLINE";
            break;
          case DeviceReplacementStatus.ReplacedDeviceSoftwareTooOld:
            this.errorMsg = "REPLACED_DEVICE_SOFTWARE_TOO_OLD";
            break;
          case DeviceReplacementStatus.ReplacedDeviceNotFound:
            this.errorMsg = "REPLACED_DEVICE_NOT_FOUND_IN_INVENTORY";
            break;
          default:
            this.errorMsg = "ERROR";
            break;
        }
        this.replacing = false;
      })
  }

  cancel(): void {
    this.dialogRef.close({
      replaced: false,
      cancelReplace: this.replacing ? true : false // If the replacement process is ongoing, flag it to be canceled
    });
  }
}

<mat-card class="mx-2 my-2"
    (mouseover)="alert ? alert.showChevron=true : null"
    (mouseout)="alert ? alert.showChevron=false : null"
    [class.alertOutOfTime]="alert?.styleOutOfTime"
    [class.alertBadlyOutOfTime]="alert?.styleBadlyOutOfTime"
    (click)="alert ? alert.showChevron ? crService.takeAction(alert) : null : null">
    <mat-card-content
        [ngClass]="{'alertCardHeaderWithDrag': alert?.allowTransition, 'alertCardTransitional': alert?.transitional}"
        cdkDragHandle>
        <div *ngIf="alert" class="d-flex flex-wrap justify-content-between">
            <div class="d-flex flex-column">
                <div class="d-flex flex-row align-items-center">
                    <i class="fa-solid faIconSize" [ngClass]="crService.getAlertFaIcon(alert)"></i>
                    <span class="ml-2 customerName" *ngIf="alert.customerName">
                        {{alert.customerName}}
                    </span>
                    <span *ngIf="alert.increment > 0"
                        class="ml-3 badge badge-pill badge-danger">
                        {{'+' + alert.increment}}
                    </span>
                </div>
                <div class="mt-2">
                    <p class="alertHeader ml-2"><strong>{{alert.header}} ({{alert.productId}})</strong></p>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div class="mr-sm-4 my-2">
                    <p class="time"><strong>{{ alert.showTime | date: 'mm:ss'}}</strong></p>
                </div>

                <div class="constantWidth">
                    <i *ngIf="alert.showChevron" class="fa-duotone fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
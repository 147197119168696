import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AnnaPerennaMonitorProfile } from 'app/models/annaperenna.model';
import { AnnaPerennaMonitorCardComponent } from './ap-monitor-card/ap-monitor-card.component';
import { NgFor, NgIf } from '@angular/common';


@Component({
    selector: 'ap-monitor',
    templateUrl: './ap-monitor.component.html',
    styleUrls: ['./ap-monitor.component.css'],
    standalone: true,
    imports: [NgFor, NgIf, AnnaPerennaMonitorCardComponent, TranslateModule]
})
export class AnnaPerennaMonitorComponent implements OnInit, OnDestroy {
  constructor(
    private api: ApiService
  ) { }
  monitors: any;
  profiles: AnnaPerennaMonitorProfile[];
  monitorTimeout: any;
  editMode: boolean;
  monitorRequest: any;
  monitorsNotFound: boolean = false;


  monitorInEdit(event: boolean): void {
    this.editMode = event;
  }

  monitorRemovedEmit(id: number): void {
    this.monitorRequest = JSON.parse(localStorage.getItem("monitorRequest"));
    //Atleast one monitor has to be visible always
    if (this.monitorRequest.length > 1) {
      //Remove monitor from request list
      let monitorR = this.monitorRequest.find(obj => {
        if (obj.monitorId === id) {
          return obj;
        }
      })
      this.monitorRequest.splice(this.monitorRequest.indexOf(monitorR), 1);
      localStorage.setItem("monitorRequest", JSON.stringify(this.monitorRequest))

      //Remove monitor from visible monitors
      let monitor = this.monitors.find(obj => {
        if (obj.monitorId === id) {
          return obj;
        }
      })
      this.monitors.splice(this.monitors.indexOf(monitor), 1);
    }
  }



  getMonitorStates(): void {
    //Get monitor data from local storage
    this.monitorRequest = JSON.parse(localStorage.getItem("monitorRequest"));
    this.api.getMonitorStates(this.monitorRequest).subscribe(res => {
      if (res) {
        if (!this.editMode) {
          this.monitors = res;
        }
        this.monitorTimeout = setTimeout(() => {
          this.getMonitorStates();
        }, 5 * 1000);
      }
    }, err => {

    })
  }

  resetMonitors(): void {
    //Clear all previous timeouts for data polling
    clearTimeout(this.monitorTimeout);
    //Clear monitor data from local storage
    localStorage.removeItem("monitorRequest");
    //Reload all monitors from API
    this.loadAllMonitors();
  }

  loadAllMonitors(): void {
    this.api.getMonitors().subscribe(monitors => {
      if (monitors) {
        localStorage.setItem("monitorRequest", JSON.stringify(monitors))
        this.getMonitorStates();
      } else {
        this.monitorsNotFound = true;
      }
    }, err => {

    })
  }

  ngOnInit(): void {
    this.api.getMonitorProfiles().subscribe(res => {
      if (res) {
        this.profiles = res;
      } else {
        this.profiles = [];
      }
      
    })
    //Load all monitors if local stroage is empty. Otherwise refresh monitor data using monitors in local storage
    if (!localStorage.getItem("monitorRequest")) {
        this.loadAllMonitors();
    } else {
      this.getMonitorStates();
    }
  }

  ngOnDestroy(): void {
    //Disable state polling when user navigates out of the page
    clearTimeout(this.monitorTimeout);
  }
}
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Device } from '../../../models/device.model';
import { CallCenterDeliveryType, RecipientType, Route, ServiceTime } from '../../../models/alarmroutes.model';
import { CallCenter } from '../../../models/callcenter.model';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { HelperService } from '../../../services/helper.service';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'alarm-route-info',
    templateUrl: './alarm-route-info.component.html',
    styleUrls: ['./alarm-route-info.component.css'],
    standalone: true,
    imports: [NgIf, NgClass, NgFor, TranslateModule]
})
export class AlarmRouteInfoComponent implements OnInit, OnChanges {

  constructor(private translate: TranslateService, private helper: HelperService, private api: ApiService, private router: Router,) {

  }

  @Input('route') route: Route;
  @Input('callCenters') callCenters: CallCenter[];
  @Output() routeDeleted = new EventEmitter<string>();
  @Output() closeInformationForm = new EventEmitter<void>();
  @Output() showEditForm = new EventEmitter<void>();
  @Output() activeTabIndexEmitter = new EventEmitter<number>();


  callCenterDeliveryTypeTranslationsMap: Map<CallCenterDeliveryType, string> = new Map([
    [CallCenterDeliveryType.UnableToDeliverAlarm, 'UNABLE_TO_DELIVER_ALARM'],
    [CallCenterDeliveryType.CallCenterFirst, 'CALL_CENTER_FIRST'],
    [CallCenterDeliveryType.CallCenterOnly, 'CALL_CENTER_ONLY'],
    [CallCenterDeliveryType.WhenUnableToDeliverEMACallCenterOnly, 'UNABLE_TO_DELIVER_EMA_CALL_CENTER_ONLY'],
    [CallCenterDeliveryType.WhenUnableToDeliverEMAAlarm, 'UNABLE_TO_DELIVER_EMA_ALARM'],
  ]);


  daysTranslationMap: Map<string, string> = new Map([
    ['mon', 'DAYOFWEEK_1'],
    ['tue', 'DAYOFWEEK_2'],
    ['wed', 'DAYOFWEEK_3'],
    ['thu', 'DAYOFWEEK_4'],
    ['fri', 'DAYOFWEEK_5'],
    ['sat', 'DAYOFWEEK_6'],
    ['sun', 'DAYOFWEEK_7'],
  ]);

  user: any;
  activeTabIndex: number = 0;
  activations: any;
  deleteConfirmation: boolean;
  showAdvancedSettings: boolean = false;


  emitActiveTabIndex(): void {
    this.activeTabIndexEmitter.emit(this.activeTabIndex);
  }


  getCallCenterInformation(id: string): CallCenter {
    return this.callCenters.find(callCenter => callCenter.id === id);
  }


  getDeviceType(type: string): string {
    return this.helper.getDeviceType(type);
  }


  recipientType(type: string): string {
    return type === RecipientType.User ? 'fal fa-user fa-fw ' : 'fal fa-users fa-fw ';
  }

  getDeviceIconClass(deviceType: string): string {
    return this.api.getDeviceIconClass(deviceType);
  }

  serviceTimeClass(serviceTime: ServiceTime) {
    return serviceTime.startTime === serviceTime.endTime ? 'fas fa-xs fa-circle text-danger' :
      'fas fa-xs fa-circle text-success'
  }


  enabledIndicator(enabled: boolean) {
    return enabled ?
      'fas fa-xs fa-circle text-success' : 'fas fa-xs fa-circle text-danger';
  }


  deleteRoute(): void {
    this.api.deleteAlarmRoute(this.route.id).subscribe(res => {
      this.routeDeleted.emit(this.route.id);
      this.closeInfoForm();
    }, err => {
      // this.translate.get(['ERROR']).subscribe(t => {
      //   this.editTeamFormError = t.ERROR;
      // })
    })
  }

  //Get localized activation name based on group, node and device type
  getActivationName(group: number, node: number, type: string, customAct: string, connTimeLimit: number): string {
    return this.helper.getActivationName(group, node, type, customAct, connTimeLimit);
  }

  getCallCenterDeliveryType(callCenterFirst: boolean, keepAlarmOpenAfterCallCenter: boolean, emaBeforeCallCenter: boolean): CallCenterDeliveryType {

    if (!callCenterFirst && !keepAlarmOpenAfterCallCenter && !emaBeforeCallCenter) {
      return CallCenterDeliveryType.UnableToDeliverAlarm;
    }

    if (callCenterFirst && keepAlarmOpenAfterCallCenter && !emaBeforeCallCenter) {
      return CallCenterDeliveryType.CallCenterFirst;
    }

    if (callCenterFirst && !keepAlarmOpenAfterCallCenter && !emaBeforeCallCenter) {
      return CallCenterDeliveryType.CallCenterOnly;
    }

    if (callCenterFirst && !keepAlarmOpenAfterCallCenter && emaBeforeCallCenter) {
      return CallCenterDeliveryType.WhenUnableToDeliverEMACallCenterOnly;
    }

    if (callCenterFirst && keepAlarmOpenAfterCallCenter && emaBeforeCallCenter) {
      return CallCenterDeliveryType.WhenUnableToDeliverEMAAlarm;
    }

  }


  parseServiceTime(serviceTime: ServiceTime): string {
    serviceTime.startTime = this.trimTime(serviceTime.startTime);
    serviceTime.endTime = this.trimTime(serviceTime.endTime);
    if (serviceTime.startTime.startsWith('00:00') && serviceTime.endTime.startsWith('00:00')) {
      return 'NEVER';
    }

    if (serviceTime.startTime.startsWith('00:00') && serviceTime.endTime.startsWith('23:59')) {
      return 'ALWAYS';
    }

    return `${serviceTime.startTime} - ${serviceTime.endTime}`;
  }

  emaFeatureEnabled(): boolean {
    let features: [string] = JSON.parse(localStorage.getItem('features'));
    if (features) {
      return features.includes('EveronMobileApp');
    }
    return false;
  }


  openDevicePage(deviceId: string): void {
    this.router.navigate(['device/' + deviceId]);
  }

  openPersonnelPage(staffId: string): void {
    this.router.navigate(['staff/' + staffId]);
  }

  openTeamPage(teamId: string): void {
    this.router.navigate(['teams/' + teamId]);
  }

  // 00:00:00 => 00:00
  trimTime(time: string): string {
    return time.substring(0, Math.max(time.length - 3, 5));
  }


  showEditingForm(): void {
    this.showEditForm.emit();
  }

  closeInfoForm(): void {
    this.closeInformationForm.emit();
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.activeTabIndex = 0;
    this.emitActiveTabIndex();
  }

}

<button routerLink="/camera-supervision" mat-raised-button class="btn">
    <mat-icon>chevron_left</mat-icon>
    {{ 'BACK_TO_CAMERA_SUPERVISION' | translate }}
</button>
<div class="col-md-6 offset-md-3">
    <h4>{{ 'CAMERA_SUPERVISION_SETTINGS' | translate }}</h4>
    <p>{{ 'CAMERA_SUPERVISION_SETTINGS_DESCRIPTION' | translate }}</p>
    <!-- Camera selection container -->
    <div class="card-body">
        <div class="content" style="background-color: #FFFFFF;">
            <div class="flex space-between">
                <h5>{{ 'SELECT_CAMERAS' | translate }}</h5>
            </div>
            <div class="flex space-between display-block">
                <div class="col-md-4">
                    <!-- <div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'SEARCH_CAMERAS' | translate }}</mat-label>
                            <mat-hint>{{ 'SEARCH_FOR_ID_OR_NAME' | translate }}</mat-hint>
                            <input matInput type="text">
                            <button matSuffix mat-icon-button>
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-form-field>
                    </div> -->
                    <div class="mt-3">
                        {{'SELECT_CAMERA_BY_CLICK' |translate}}
                        <div *ngFor="let group of visibleCameraGroups" class="pt-2">
                            <span class="cursorPointer" (click)="selectCameraGroup(group)"
                                [ngClass]="{'text-success': group.selected}">
                                <span class="fa-light fa-fw fa-video"></span> {{group.displayName}} <span
                                    *ngIf="group.selected" class="text-success fa-light fa-fw fa-check"></span></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-8" *ngIf="selectedCameraGroups.length > 0">
                    <form [formGroup]="newVisitForm" (ngSubmit)="formSubmitSubject.next()">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>{{'WEEKDAY' |translate}}*</mat-label>
                                <mat-select formControlName="visitWeekDay" multiple>
                                    <mat-option *ngFor="let weekday of weekdays" [value]="weekday.id">
                                        {{weekday.name |translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width">
                                <mat-label>{{'DATE_FROM' |translate}}*</mat-label>
                                <input matInput type="time" value="08:00" formControlName="visitStartTime">
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width">
                                <mat-label>{{'VISIT_DURATION' |translate}}*</mat-label>
                                <input matInput type="number" value="120" formControlName="visitDuration">
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width">
                                <mat-label>{{'EXTENSION_TIME' |translate}}*</mat-label>
                                <input matInput type="number" value="15" formControlName="visitExtensionTime">
                            </mat-form-field>
                            <mat-icon matTooltip="{{ 'EXTENSION_TIME_TOOLTIP' | translate }}">info</mat-icon>
                        </div>
                        <div class="form-group">
                            <button mat-stroked-button class="btn" type="button" (click)="cancelVisitCreation()">
                                <mat-icon>cancel</mat-icon>
                                {{'CANCEL' | translate }}
                            </button>
                            <button [disabled]="!newVisitForm.valid" type="submit" mat-raised-button color="primary"
                                class="btn margin-left">
                                <mat-icon>save</mat-icon>
                                {{ 'SAVE' | translate }}
                            </button>
                        </div>
                        <div class="form-group">
                            <div *ngIf="savingInProgress" class="alert alert-info mt-2"><span
                                    class="fa-light fa-spinner fa-spin"></span> {{'SAVING_DATA' |translate}}</div>
                            <div *ngIf="savingDone" class="alert alert-success mt-2"><span
                                    class="fa-light fa-check"></span> {{'UPDATE_SUCCESS' |translate}}</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

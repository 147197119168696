import { Subject, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ApiService } from "app/services/api.service";
import { SortOrder } from "app/services/helper.service";
import { FlaggedResidentSorter } from "app/models/resident.model";
import { MomentPipe } from "../../../pipes/pipes";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CommonModule } from "@angular/common";
import { i18nPaginator } from "../new-staff/i18nPaginator";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
  PageEvent,
} from "@angular/material/paginator";

/**
 * Flag types by table column
 */
const flagTypesByField = {
  name: ["FName", "LName"],
  address: [
    "Address",
    "NRAddress",
    "NRCoAddress",
    "NRZipCode",
    "NRCity",
    "AltAddress",
    "AltCoAddress",
    "AltCity",
    "AltZipCode",
    "ProtectedAddress",
  ],
  company: ["Municipality"],
};

@Component({
  selector: "flagged-residents",
  templateUrl: "./flagged-residents.component.html",
  styleUrls: ["./flagged-residents.component.css"],
  standalone: true,
  providers: [{ provide: MatPaginatorIntl, useClass: i18nPaginator }],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatCheckboxModule,
    FormsModule,
    TranslateModule,
    MomentPipe,
  ],
})
export class FlaggedResidentsComponent implements OnInit {
  constructor(private api: ApiService) {
    this.output = this.sortFlaggedResidentsSubject$.pipe(
      switchMap(() => {
        return this.api.getCompanyFlaggedResidents(
          this.pageSize,
          this.selectedPageIndex,
          this.sortBy,
          this.sortKey
        );
      })
    );
    this.output.subscribe(
      (res: { totalNumberOfResidents: number; pageData: any[] }) => {
        this.initFlaggedResidents(res.pageData);
      }
    );
  }
  private sortFlaggedResidentsSubject = new Subject<any>();
  sortFlaggedResidentsSubject$ =
    this.sortFlaggedResidentsSubject.asObservable();
  output: Observable<any>;

  flaggedResidents: any;

  //selectedCount: number = 0;

  markButtonDisabled: boolean = true;

  pageInited: boolean = false;

  allSelected: boolean = false;

  changesSuccess: boolean = false;

  changesFailed: boolean = false;

  deleteSuccessLine: number = -1;
  deleteFailedLine: number = -1;

  totalNumberOfResidents = 0;
  selectedPageIndex: number = 0;

  sortBy: SortOrder = SortOrder.Default;
  sortKey: FlaggedResidentSorter = FlaggedResidentSorter.Name;

  pageSize = 50;

  isInactive(resident: any) {
    if (resident.flagType == "inactive") {
      return true;
    }
    return false;
  }

  removeMessages() {
    this.changesSuccess = false;
    this.changesFailed = false;
  }

  async markAsDone() {
    const data = [];
    for (let resident of this.flaggedResidents) {
      if (resident.checked === true) {
        data.push({
          residentId: resident.residentId,
          companyId: resident.companyId,
        });
      }
    }

    setTimeout(() => {
      this.removeMessages();
    }, 3000);

    await this.api.confirmResidentChanges(data).subscribe(
      (res) => {
        this.changesSuccess = true;
      },
      (err) => {
        this.changesFailed = true;
        console.log(err);
      }
    );
  }

  select(selected: boolean): void {
    if (!selected && this.allSelected) {
      this.allSelected = false;
    }

    const selectedCount = this.flaggedResidents.filter(
      (obj: any) => obj["checked"]
    ).length;

    this.markButtonDisabled = selectedCount > 0 ? false : true;
  }

  selectAll() {
    this.markButtonDisabled = this.allSelected ? false : true;

    const check = this.allSelected;
    for (let i = 0; i < this.flaggedResidents.length; i++) {
      this.flaggedResidents[i]["checked"] = check;
    }
  }

  removeSuccessMessage(i: number) {
    this.flaggedResidents.splice(i, 1);
    this.deleteSuccessLine = -1;
  }

  removeFailedMessage() {
    this.deleteFailedLine = -1;
  }

  deleteResident(resident: any, i: any): void {
    var data = {
      residentId: resident.residentId,
      companyId: resident.companyId,
    };

    this.api.deleteResident(data).subscribe(
      (res) => {
        this.deleteSuccessLine = i;
        this.flaggedResidents.splice(i, 1, "");
        setTimeout(() => {
          this.removeSuccessMessage(i);
        }, 3000);
        console.log(res);
      },
      (err) => {
        this.deleteFailedLine = i;
        setTimeout(() => {
          this.removeFailedMessage();
        }, 3000);
        console.log(err);
      }
    );
  }

  sort(sorter: FlaggedResidentSorter): void {
    if (sorter !== this.sortKey) this.sortBy = SortOrder.Default;
    this.sortKey = sorter;
    switch (this.sortBy) {
      case SortOrder.Default:
        this.sortBy = SortOrder.Asc;
        break;
      case SortOrder.Asc:
        this.sortBy = SortOrder.Desc;
        break;
      case SortOrder.Desc:
        this.sortBy = SortOrder.Default;
    }
    this.sortFlaggedResidentsSubject.next();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  initFlaggedResidents(data: any): void {
    this.flaggedResidents = data;
    let changes = [];

    for (let i = 0; i < this.flaggedResidents.length; i++) {
      changes[i] = this.flaggedResidents[i].flagType.split(";");

      let inactiveFound = false;

      for (let change of changes[i]) {
        // Red box to the specific field
        this.flaggedResidents[i][change + "Changed"] = "changed";

        if (change == "Inactive") {
          inactiveFound = true;
          break;
        }
        if (flagTypesByField.address.includes(change)) {
          this.flaggedResidents[i]["addressChanged"] = "changed";
        }
        if (flagTypesByField.name.includes(change)) {
          this.flaggedResidents[i]["residentNameChanged"] = "changed";
        }
        if (flagTypesByField.company.includes(change)) {
          this.flaggedResidents[i]["companyChanged"] = "changed";
        }
        if (change == "PhoneHome") {
          this.flaggedResidents[i]["phoneHomeChanged"] = "changed";
        }
        if (change == "PhoneCell") {
          this.flaggedResidents[i]["phoneCellChanged"] = "changed";
        }
      }

      if (this.flaggedResidents[i].flagType == "Inactive" || inactiveFound) {
        this.flaggedResidents[i].inactive = "flagInactive";
        this.flaggedResidents[i].icon = "fas fa-trash mr1 exp";
        this.flaggedResidents[i].flagStatus = "REMOVED";
      } else {
        this.flaggedResidents[i].icon = "fas fa-user-edit exp";
        this.flaggedResidents[i].flagStatus = "CHANGED";
      }
    }
  }

  ngOnInit(): void {
    this.api
      .getCompanyFlaggedResidents(
        this.pageSize,
        this.selectedPageIndex,
        this.sortBy,
        this.sortKey
      )
      .subscribe(
        (res: { totalNumberOfResidents: number; pageData: any[] }) => {
          this.totalNumberOfResidents = res.totalNumberOfResidents;
          this.initFlaggedResidents(res.pageData);
          this.pageInited = true;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  handlePageEvent(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;

    this.api
      .getCompanyFlaggedResidents(
        this.pageSize,
        pageEvent.pageIndex,
        this.sortBy,
        this.sortKey
      )
      .subscribe(
        (res: { totalNumberOfResidents: number; pageData: any[] }) => {
          this.totalNumberOfResidents = res.totalNumberOfResidents;
          this.initFlaggedResidents(res.pageData);
          this.pageInited = true;
        },
        (err) => {
          console.log(err);
        }
      );
  }
}

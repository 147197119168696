<ng-container *ngIf="fileNotFound">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="error-template">
                    <h2>
                        {{'FILE_NOT_FOUND' | translate}}</h2>
                    <h3>
                        {{'REPORT_NOTICE' | translate}}
                    </h3>
                </div>
            </div>
        </div>
    </div>

</ng-container>

<div class="card">
    <h4 id="header" class="mainDarkBlueText normalFontWeight">
        {{ "ALERT_REASONS" | translate }}
    </h4>

    <div id="chartContaner">
        <canvas
            [hidden]="
                !alertsByAlarmReasons || alertsByAlarmReasons.length === 0
            "
            id="alertsByAlarmReasonsChart"
        ></canvas>

        <ng-container
            *ngIf="!alertsByAlarmReasons || alertsByAlarmReasons.length === 0"
        >
            <p class="mt-2 ml-2">
                {{ "NO_RESULT" | translate }}
            </p>
        </ng-container>
    </div>
</div>

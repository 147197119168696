import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICompanyGroup, DeviceBindingTimeUnit, IUpdateCompanyGroupBindingTimeForm } from 'app/models/company.models';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from 'app/services/api.service';

const BINDING_TIME_SUCCESS_MSG = "BINDING_TIME_UPDATE_SUCCESS";
const ERROR_MSG = "ERROR";

@Component({
  selector: 'app-company-group-binding-time',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './company-group-edit-binding-time.component.html',
  styleUrls: ['./company-group-edit-binding-time.component.css']
})
export class CompanyGroupEditBindingTimeComponent implements OnInit {

  constructor(private api: ApiService) { }

  @Input() companyGroup: ICompanyGroup;

  deviceBindingTimeControl: FormControl<number>;
  deviceBindingTimeUnitControl: FormControl<DeviceBindingTimeUnit>;
  deviceBindingTimeVisibleControl: FormControl<boolean>;

  updating: boolean = false;
  successMsg: string = "";
  errorMsg: string = "";

  submit(): void {
    this.resetMessages();
    this.startUpdating();

    this.api.updateCompanyGroupBindingTime(this.createBindingTimeForm())
      .subscribe(
        () => this.handleUpdateSuccess(),
        (error) => this.handleUpdateError(error)
      );
  }

  createBindingTimeForm(): IUpdateCompanyGroupBindingTimeForm {
    return {
      companyGroupId: this.companyGroup.companyGroupId,
      server: this.companyGroup.server,
      deviceBindingTime: this.deviceBindingTimeControl.value,
      deviceBindingTimeUnit: this.deviceBindingTimeUnitControl.value,
      deviceBindingTimeVisible: this.deviceBindingTimeVisibleControl.value
    };
  }

  handleUpdateSuccess(): void {
    this.successMsg = BINDING_TIME_SUCCESS_MSG;
    this.stopLoading();
  }

  handleUpdateError(error: any): void {
    console.log(error);
    this.errorMsg = ERROR_MSG;
    this.stopLoading();
  }

  startUpdating(): void {
    this.updating = true;
    this.resetMessages();
  }

  stopLoading(): void {
    this.updating = false;
  }

  resetMessages(): void {
    this.successMsg = "";
    this.errorMsg = "";
  }

  ngOnInit(): void {
    this.deviceBindingTimeUnitControl = new FormControl<DeviceBindingTimeUnit>(this.companyGroup.deviceBindingTimeUnit);
    this.deviceBindingTimeVisibleControl = new FormControl<boolean>(this.companyGroup.deviceBindingTimeVisible);
    this.deviceBindingTimeControl = new FormControl<number>(this.companyGroup.deviceBindingTime, [
      Validators.required,
      Validators.min(0),
      Validators.max(9999)
    ]);
  }
}

import { Component, Input, OnChanges, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { LocationModel } from 'app/models/location.model';
import { ApiService } from 'app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StaffModel } from 'app/models/staff.model';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { MatListModule } from '@angular/material/list';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'location-access-rights',
    templateUrl: './location-access-rights.component.html',
    styleUrls: ['./location-access-rights.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatListModule, NgFor, TranslateModule]
})
export class LocationAccessRightsComponent implements OnChanges, OnDestroy, OnInit {

  //Init global variables and services
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef
  ) {
  };

  @Input('location') location: LocationModel;

  locationStaffEditForm: UntypedFormGroup;

  availableStaffForLocation: StaffModel[];
  configuredStaffForLocation: string[]
  updateSuccess: boolean;
  updateError: boolean;

  formSubmitSubject = new Subject();

  createLocationStaffEditForm(): void {
    this.locationStaffEditForm = this.fb.group({
      selectedStaffForLocation: new UntypedFormControl(this.configuredStaffForLocation)
    })
  }

  submitStaffsForLocation(): void {
    this.updateError = false;
    this.updateSuccess = false;
    let data = {
      locationId: this.location.id,
      ConfiguredPersonnels: this.locationStaffEditForm.value.selectedStaffForLocation
    }
    this.api.updateLocationStaffList(data).subscribe(res => {
      this.updateSuccess = true;
    }, err => {
      this.updateError = true;
    })
  }

  ngOnInit(): void {
     // Initialize form submit subject with 3 second throttle time to prevent multiple submits
     this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe(() => {
        this.submitStaffsForLocation();
      });
  }

  ngOnChanges(): void {
    //Load staff list for location
    this.api.getLocationStaffList(this.location.id).subscribe(res => {
      this.availableStaffForLocation = res.availablePersonnels;
      this.configuredStaffForLocation = res.configuredPersonnels;
      this.createLocationStaffEditForm();
    })
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

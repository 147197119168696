import { Component, Input, OnInit } from '@angular/core';
import { CrAlert } from 'app/services/crsync.service';
import { MomentPipe } from '../../../../pipes/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor, SlicePipe } from '@angular/common';

@Component({
    selector: 'app-cr-notes-list',
    templateUrl: './cr-notes-list.component.html',
    styles: [`
    .alertCardClickTarget {
      color: #1D83E9;
      cursor: pointer;
      }
      `],
    standalone: true,
    imports: [NgIf, NgFor, SlicePipe, TranslateModule, MomentPipe]
})
export class CrNotesListComponent implements OnInit {

  constructor() { }

  @Input('alert') alert: CrAlert;

  noteShowCount = 1;

  ngOnInit(): void {
  }

}

import { IActivityLog } from 'app/models/activity-log.model';
import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivityStaffDetailsComponent } from '../activity-staff-details/activity-staff-details.component';
import { ActivityCameraGroupDetailsComponent } from '../activity-camera-group-details/activity-camera-group-details.component';
import { ActivityCameraVisitDetailsComponent } from '../activity-camera-visit-details/activity-camera-visit-details.component';

@Component({
  selector: 'app-activity-details-dialog',
  standalone: true,
  imports: [CommonModule, ActivityStaffDetailsComponent, ActivityCameraGroupDetailsComponent, ActivityCameraVisitDetailsComponent],
  templateUrl: './activity-details-dialog.component.html',
  styleUrls: ['./activity-details-dialog.component.css']
})
export class ActivityDetailsDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ActivityDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public log: IActivityLog
  ) { }

  close(): void {
    this.dialogRef.close();
  }
}

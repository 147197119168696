<span>
    {{ ("CAMERA_GROUP" | translate) + " " }}

    <a [routerLink]="['/camera-configuration', log?.details?.targetId]" (click)="closeDialog.emit()">
        {{ log?.details?.targetName }}
    </a>

    {{ " " + ("WAS_MODIFIED_BY" | translate) + " " }}

    <strong *ngIf="!log?.staffId && log?.staffName === 'Everon Admin';else elseBlock">
        Everon Admin
    </strong>

    <ng-template #elseBlock>
        <a [routerLink]="['/staff', log?.staffId]" (click)="closeDialog.emit()">{{ log?.staffName | identityFormat : log?.staffId }}</a>
    </ng-template>
</span>
<p class="mt-1">{{ log?.timestamp | moment : "l LTS" }}</p>

<hr />

<span class="subtitle">
    {{ "MODIFICATIONS" | translate | uppercase }}
</span>

<div class="container">
    <!-- Group name -->
    <ng-container *ngIf="visibleFields | includes : 'groupName'">
        <span class="item-title">{{ "GROUP_NAME" | translate }}:</span>

        <div class="item-pair-value">
            <span class="negative-highlight line-through">
                {{ cameraGroupChanges?.groupName?.oldValue || "NONE" | translate }}
            </span>
            <i class="fa-light fa-arrow-right-long fa-lg"></i>
            <span class="positive-highlight">
                {{ cameraGroupChanges?.groupName?.newValue || "NONE" | translate }}
            </span>
        </div>
    </ng-container>

    <!-- Address -->
    <ng-container *ngIf="visibleFields | includes : 'address'">
        <span class="item-title">{{ "ADDRESS" | translate }}:</span>

        <div class="item-pair-value">
            <span class="negative-highlight line-through">
                {{ cameraGroupChanges?.address?.oldValue || "NONE" | translate }}
            </span>
            <i class="fa-light fa-arrow-right-long fa-lg"></i>
            <span class="positive-highlight">
                {{ cameraGroupChanges?.address?.newValue || "NONE" | translate }}
            </span>
        </div>
    </ng-container>

    <!-- Postal code -->
    <ng-container *ngIf="visibleFields | includes : 'postalCode'">
        <span class="item-title">{{ "POSTCODE" | translate }}:</span>

        <div class="item-pair-value">
            <span class="negative-highlight line-through">
                {{ cameraGroupChanges?.postalCode?.oldValue || "NONE" | translate }}
            </span>
            <i class="fa-light fa-arrow-right-long fa-lg"></i>
            <span class="positive-highlight">
                {{ cameraGroupChanges?.postalCode?.newValue || "NONE" | translate }}
            </span>
        </div>
    </ng-container>

    <!-- City -->
    <ng-container *ngIf="visibleFields | includes : 'city'">
        <span class="item-title">{{ "POSTOFFICE" | translate }}:</span>

        <div class="item-pair-value">
            <span class="negative-highlight line-through">
                {{ cameraGroupChanges?.city?.oldValue || "NONE" | translate }}
            </span>
            <i class="fa-light fa-arrow-right-long fa-lg"></i>
            <span class="positive-highlight">
                {{ cameraGroupChanges?.city?.newValue || "NONE" | translate }}
            </span>
        </div>
    </ng-container>

    <!-- Access mode -->
    <ng-container *ngIf="visibleFields | includes : 'accessModeTranslationCode'">
        <span class="item-title">{{ "ACCESS_MODE" | translate }}:</span>

        <div class="item-pair-value">
            <span class="negative-highlight line-through">
                {{ cameraGroupChanges?.accessModeTranslationCode?.oldValue || "NONE" | translate }}
            </span>
            <i class="fa-light fa-arrow-right-long fa-lg"></i>
            <span class="positive-highlight">
                {{ cameraGroupChanges?.accessModeTranslationCode?.newValue || "NONE" | translate }}
            </span>
        </div>
    </ng-container>

    <!-- Location -->
    <ng-container *ngIf="visibleFields | includes : 'location'">
        <span class="item-title">{{ "LOCATION" | translate }}:</span>

        <div class="item-pair-value">
            <span class="negative-highlight line-through">
                {{ cameraGroupChanges?.location?.oldValue || "NONE" | translate }}
            </span>
            <i class="fa-light fa-arrow-right-long fa-lg"></i>
            <span class="positive-highlight">
                {{ cameraGroupChanges?.location?.newValue || "NONE" | translate }}
            </span>
        </div>
    </ng-container>

    <!-- Cameras -->
    <ng-container *ngIf="visibleFields | some : 'addedCameras' : 'modifiedCameras' : 'removedCameras'">
        <p id="cameras-title" class="item-group-title">
            {{ "CAMERAS" | translate }}:
        </p>
        <div class="item-group-box">
            <!-- Added cameras -->
            <ng-container *ngIf="visibleFields | includes : 'addedCameras'">
                <span class="item-value d-flex align-items-center">
                    {{ "ADDED" | translate }}:
                    <span class="positive-highlight ml-4">
                        <span *ngFor="let camera of cameraGroupChanges?.addedCameras; let isLast = last">
                            <strong>{{ camera.name + " (" + camera.id + ")" }}</strong>:
                            {{ (camera | formatCameraDetails) }}
                            <div *ngIf="!isLast" class="mb-2"></div>
                        </span>
                    </span>
                </span>
            </ng-container>

            <!-- Modified cameras -->
            <ng-container *ngIf="visibleFields | includes : 'modifiedCameras'">
                <span class="item-value d-flex align-items-center">
                    {{ "MODIFIED" | translate }}:
                    <span class="warning-highlight ml-4">
                        <span *ngFor="let camera of cameraGroupChanges?.modifiedCameras; let isLast = last">
                            <strong>{{ camera.name + " (" + camera.id + ")" }}</strong>:
                            {{ (camera | formatCameraDetails) }}
                            <div *ngIf="!isLast" class="mb-2"></div>
                        </span>
                    </span>
                </span>
            </ng-container>

            <!-- Removed cameras -->
            <ng-container *ngIf="visibleFields | includes : 'removedCameras'">
                <span class="item-value d-flex align-items-center">
                    {{ "REMOVED" | translate }}:
                    <span class="negative-highlight line-through ml-4">
                        {{ cameraGroupChanges?.removedCameras | join : ', ' || "NONE" | translate }}
                    </span>
                </span>
            </ng-container>
        </div>
    </ng-container>

    <!-- Receiver groups -->
    <ng-container *ngIf="visibleFields | some : 'addedReceiverGroups' : 'removedReceiverGroups'">
        <p id="receiver-groups-title" class="item-group-title">
            {{ "RECEIVER_TEAMS" | translate }}:
        </p>
        <div class="item-group-box">
            <!-- Added receiver groups -->
            <ng-container *ngIf="visibleFields | includes : 'addedReceiverGroups'">
                <span class="item-value d-flex align-items-center">
                    {{ "ADDED" | translate }}:
                    <span class="positive-highlight ml-4">
                        {{ cameraGroupChanges?.addedReceiverGroups | join : ', ' || "NONE" | translate }}
                    </span>
                </span>
            </ng-container>

            <!-- Removed alert devices -->
            <ng-container *ngIf="visibleFields | includes : 'removedReceiverGroups'">
                <span class="item-value d-flex align-items-center">
                    {{ "REMOVED" | translate }}:
                    <span class="negative-highlight line-through ml-4">
                        {{ cameraGroupChanges?.removedReceiverGroups | join : ', ' || "NONE" | translate }}
                    </span>
                </span>
            </ng-container>
        </div>
    </ng-container>

    <!-- Alert devices/connected devices -->
    <ng-container *ngIf="visibleFields | some : 'addedAlertDevices' : 'removedAlertDevices'">
        <p id="connected-devices-title" class="item-group-title">
            {{ "CONNECTED_DEVICES" | translate }}:
        </p>
        <div class="item-group-box">
            <!-- Added alert devices -->
            <ng-container *ngIf="visibleFields | includes : 'addedAlertDevices'">
                <span class="item-value d-flex align-items-center">
                    {{ "ADDED" | translate }}:
                    <span class="positive-highlight ml-4">
                        {{ cameraGroupChanges?.addedAlertDevices | join : ', ' || "NONE" | translate }}
                    </span>
                </span>
            </ng-container>

            <!-- Removed alert devices -->
            <ng-container *ngIf="visibleFields | includes : 'removedAlertDevices'">
                <span class="item-value d-flex align-items-center">
                    {{ "REMOVED" | translate }}:
                    <span class="negative-highlight line-through ml-4">
                        {{ cameraGroupChanges?.removedAlertDevices | join : ', ' || "NONE" | translate }}
                    </span>
                </span>
            </ng-container>
        </div>
    </ng-container>

    <!-- Visit time -->
    <ng-container *ngIf="visibleFields | includes : 'visitTimeMinutes'">
        <span class="item-title">{{ "VISIT_TIME" | translate }}:</span>

        <div class="item-pair-value">
            <span class="negative-highlight line-through">
                {{
                    cameraGroupChanges?.visitTimeMinutes?.oldValue
                    ? cameraGroupChanges.visitTimeMinutes.oldValue + " " + ("MINUTES" | translate)
                    : "NONE" | translate
                }}
            </span>
            <i class="fa-light fa-arrow-right-long fa-lg"></i>
            <span class="positive-highlight">
                {{
                    cameraGroupChanges?.visitTimeMinutes?.newValue
                    ? cameraGroupChanges.visitTimeMinutes.newValue + " " + ("MINUTES" | translate)
                    : "NONE" | translate
                }}
            </span>
        </div>
    </ng-container>

    <!-- Visit grace time -->
    <ng-container *ngIf="visibleFields | includes : 'graceTimeMinutes'">
        <span class="item-title">{{ "GRACE_TIME" | translate }}:</span>

        <div class="item-pair-value">
            <span class="negative-highlight line-through">
                {{
                    cameraGroupChanges?.graceTimeMinutes?.oldValue
                    ? cameraGroupChanges.graceTimeMinutes.oldValue + " " + ("MINUTES" | translate)
                    : "NONE" | translate
                }}
            </span>
            <i class="fa-light fa-arrow-right-long fa-lg"></i>
            <span class="positive-highlight">
                {{
                    cameraGroupChanges?.graceTimeMinutes?.newValue
                    ? cameraGroupChanges.graceTimeMinutes.newValue + " " + ("MINUTES" | translate)
                    : "NONE" | translate
                }}
            </span>
        </div>
    </ng-container>
</div>

<div class="mt-4" *ngIf="allFields.length > visibleFieldCount">
    <span id="shown-details-info">{{ ("SHOWN_ACTIVITY_DETAILS_INFO" | translate : visibleFieldCount) | uppercase }}.</span>
</div>

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { StaffModel } from '../../../../models/staff.model';
import { ReceiverTeam } from '../../../../models/receiver-team';
import { Recipient, RecipientType, Route } from '../../../../models/alarmroutes.model';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragPreview, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { AddRecipientAnimation } from '../../../../animations';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLineModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { NgIf, NgFor, NgClass, KeyValuePipe } from '@angular/common';

@Component({
    selector: 'alarm-route-recipients',
    templateUrl: './alarm-route-recipients.component.html',
    styleUrls: ['./alarm-route-recipients.component.css'],
    animations: [AddRecipientAnimation],
    standalone: true,
    imports: [NgIf, MatListModule, NgFor, MatLineModule, MatIconModule, NgClass, MatDividerModule, CdkDropList, CdkDrag, CdkDragPreview, CdkDragPlaceholder, KeyValuePipe, TranslateModule]
})
export class AlarmRouteRecipientsComponent implements OnInit, OnChanges {

  constructor() {
  }

  @Input('tempRoute') tempRoute: Route;
  @Input('fallbackRecipients') fallbackRecipients: boolean;
  @Input('allStaff') allStaff: StaffModel[] = [];
  @Input('allReceiverTeams') allReceiverTeams: ReceiverTeam[] = [];
  @Input('customerInformation') customerInformation: any;

  selectedRecipientsList: RecipientOption[] = [];

  addedAnimation: boolean = true;

  availableRecipientGroups: Map<RecipientGroupType, RecipientGroup> = new Map([

    [RecipientGroupType.CompanyReceiverTeams, {
      translationSuffix: 'RECEIVER_TEAMS',
      locationName: '',
      availableRecipients: []
    }],

    [RecipientGroupType.LocationReceiverTeams, {
      translationSuffix: 'RECEIVER_TEAMS',
      locationName: '',
      availableRecipients: []
    }],

    [RecipientGroupType.CompanyStaff, {
      translationSuffix: 'RECIPIENTS',
      locationName: '',
      availableRecipients: []
    }],

    [RecipientGroupType.LocationStaff, {
      translationSuffix: 'RECIPIENTS',
      locationName: '',
      availableRecipients: []
    }]


  ]);


  /**
   * RECIPIENT MANAGEMENT
   */



  getAvailableCompanyStaff(): StaffModel[] {
    return this.allStaff.filter(staff => staff.locationId.startsWith('C0'));

  }

  getAvailableLocationStaff(): StaffModel[] {
    return this.allStaff.filter(staff => staff.locationId === this.tempRoute.locationId);

  }


  getAvailableCompanyReceiverTeams(): ReceiverTeam[] {
    if(!this.allReceiverTeams) return [];
    return this.allReceiverTeams.filter(team => team.customerId.startsWith('C0') && !team.locationId);


  }

  getAvailableLocationReceiverTeams(): ReceiverTeam[] {
    if(!this.allReceiverTeams) return [];
    return this.allReceiverTeams.filter(team => team.locationId === this.tempRoute.locationId);
  }

  resetAvailableRecipients(): void {
    this.availableRecipientGroups.forEach(group => group.availableRecipients = []);
  }

  parseAvailableRecipients(): void {
    this.resetAvailableRecipients();

    if (this.tempRoute.locationId.startsWith('C0')) {

      this.availableRecipientGroups.get(RecipientGroupType.CompanyStaff).locationName = this.customerInformation.name;
      this.availableRecipientGroups.get(RecipientGroupType.CompanyStaff).availableRecipients =
        this.getAvailableCompanyStaff().map(staff => {
          return {name: staff.fullName, gsmNumber: staff.gsmNumber, id: staff.id, locationId: staff.locationId, type: RecipientType.User}
        });

      this.availableRecipientGroups.get(RecipientGroupType.CompanyReceiverTeams).locationName = this.customerInformation.name;
      this.availableRecipientGroups.get(RecipientGroupType.CompanyReceiverTeams).availableRecipients =
        this.getAvailableCompanyReceiverTeams().map(team => {
          return {name: team.groupName, gsmNumber: '', id: team.groupId, locationId: team.locationId, type: RecipientType.Group}
        });


    } else {

      this.availableRecipientGroups.get(RecipientGroupType.CompanyStaff).locationName = this.customerInformation.name;
      this.availableRecipientGroups.get(RecipientGroupType.CompanyStaff).availableRecipients =
        this.getAvailableCompanyStaff().map(staff => {

          return {name: staff.fullName, gsmNumber: staff.gsmNumber, id: staff.id, locationId: staff.locationId, type: RecipientType.User}
        });

      this.availableRecipientGroups.get(RecipientGroupType.CompanyReceiverTeams).locationName = this.customerInformation.name;
      this.availableRecipientGroups.get(RecipientGroupType.CompanyReceiverTeams).availableRecipients =
        this.getAvailableCompanyReceiverTeams().map(team => {
          return {name: team.groupName, gsmNumber: '', id: team.groupId, locationId: team.locationId, type: RecipientType.Group}
        });

      this.availableRecipientGroups.get(RecipientGroupType.LocationStaff).locationName = this.tempRoute.locationName;
      this.availableRecipientGroups.get(RecipientGroupType.LocationStaff).availableRecipients =
        this.getAvailableLocationStaff().map(staff => {
          return {name: staff.fullName, gsmNumber: staff.gsmNumber, id: staff.id, locationId: staff.locationId, type: RecipientType.User}
        });


      this.availableRecipientGroups.get(RecipientGroupType.LocationReceiverTeams).locationName = this.tempRoute.locationName;
      this.availableRecipientGroups.get(RecipientGroupType.LocationReceiverTeams).availableRecipients =
        this.getAvailableLocationReceiverTeams().map(team => {
          return {name: team.groupName, gsmNumber: '', id: team.groupId, locationId: team.locationId, type: RecipientType.Group}
        });

    }


  }


  addRecipient(recipient: RecipientOption): void {
    this.addedAnimation = true;
    recipient.selected = true;
    this.selectedRecipientsList.push(recipient);
    this.saveRecipientsToTempRoute();
  }


  removeRecipient(removeIndex: number): void {
    this.addedAnimation = false;

    const removeRecipient = this.selectedRecipientsList[removeIndex];

    if (removeRecipient) {
      this.unToggleSelectedFromRecipient(removeRecipient.id);
      this.selectedRecipientsList.splice(removeIndex, 1);
      this.saveRecipientsToTempRoute();
    }
  }


  addRecipientsByTypeAndLocationId(locationId: string, recipientType: RecipientType, recipientGroupType: RecipientGroupType): void {

    const recipients = this.availableRecipientGroups.get(recipientGroupType).availableRecipients.filter(recipient =>
      recipient.type === recipientType &&
      recipient.locationId === locationId);

    if (recipients) {
      recipients.forEach(recipient => this.addRecipient(recipient));
    }

  }


  dragAndDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.selectedRecipientsList,
      event.previousIndex,
      event.currentIndex
    );
    this.saveRecipientsToTempRoute();
  }


  recipientType(type: string): string {
    return type === RecipientType.User ? 'fal fa-user fa-fw ' : 'fal fa-users fa-fw ';
  }


  recipientExistsInAvailableRecipients(recipientId: string): boolean {
    const listOfRecipients = Array.from(this.availableRecipientGroups.values());
    return listOfRecipients.some(group =>
      group.availableRecipients.some(recipient => recipient.id === recipientId)
    );
  }

  saveRecipientsToTempRoute(): void {
    if (this.fallbackRecipients) {
      this.tempRoute.fallBackRecipients = this.selectedRecipientsList;
    } else {
      this.tempRoute.recipients = this.selectedRecipientsList;
    }
  }

  unToggleSelectedFromRecipient(recipientId: string): void {

    // Check if there is only one recipient with same id and set .selected as false
    const containsOne: boolean = this.selectedRecipientsList.filter(recipient => recipient.id === recipientId).length === 1;

    if (containsOne) {
      const listOfRecipients = Array.from(this.availableRecipientGroups.values());
      listOfRecipients.some(group =>
        group.availableRecipients.some(recipient => {
          if (recipient.id === recipientId) {
            recipient.selected = false;
            return true;
          }
        })
      );
    }

  }

  toggleSelectedForInitialRecipients(): void {
    const listOfRecipients = Array.from(this.availableRecipientGroups.values());

    this.selectedRecipientsList.forEach(selectedRecipient => {
      listOfRecipients.some(group =>
        group.availableRecipients.some(recipient => {
          if (recipient.id === selectedRecipient.id) {
            recipient.selected = true;
            return true;
          }
        })
      )
    });
  }


  /**
   * END OF RECIPIENT MANAGEMENT
   */


  scrollToBottom(id: string): void {
    setTimeout(() => {
      const element = document.getElementById(id);
      element.scrollTop = element.scrollHeight;
    }, 25);

  }


  ngOnInit() {
    if (this.fallbackRecipients) {
      this.selectedRecipientsList.push(...this.tempRoute.fallBackRecipients);
    } else {
      this.selectedRecipientsList.push(...this.tempRoute.recipients);
    }

    this.parseAvailableRecipients();
    this.toggleSelectedForInitialRecipients();

  }


  //Re-parse recipients on location change
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tempRoute.previousValue) {
      const tempRecipientsList = this.selectedRecipientsList;
      this.parseAvailableRecipients();
      this.selectedRecipientsList = tempRecipientsList.filter(recipient =>
        this.recipientExistsInAvailableRecipients(recipient.id));

      this.toggleSelectedForInitialRecipients();

      this.saveRecipientsToTempRoute();
    }
  }

}

interface RecipientOption extends Recipient {
  locationId?: string;
  selected?: boolean;
}

export interface RecipientGroup {
  translationSuffix: string;
  availableRecipients: RecipientOption[];
  locationName: string;
}


export enum RecipientGroupType {
  CompanyReceiverTeams,
  LocationReceiverTeams,
  CompanyStaff,
  LocationStaff,

}

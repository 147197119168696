import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ReportPipesModule } from "app/components/report/report-pipes";
import { LockEvent } from 'app/models/report.model';
import { MomentPipe, TransformToLangCodePipe } from 'app/pipes/pipes';

@Component({
  selector: 'app-report-lock-event-table',
  templateUrl: './report-lock-event-table.component.html',
  styleUrls: ['../../report.component.css'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    InfiniteScrollModule,
    ReportPipesModule,
    MomentPipe,
    TransformToLangCodePipe
  ]
})
export class ReportLockEventTableComponent {

  constructor() { }

  @Input() lockEvents: LockEvent[];
  @Input() infiniteScrollDisabled: boolean;

  @Output() loadNextEvents = new EventEmitter<void>();

  expandRow(lockEvent: LockEvent): void {
    lockEvent.expanded = !lockEvent.expanded ?? true;
  }
}

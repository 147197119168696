<p *ngIf="!pageInited">{{ "LOADING" | translate }}...</p>
<div class="residentsGrid" *ngIf="pageInited">
  <div class="d-flex align-items-baseline paginatorBoxTop">
    <div class="m-2" data-testid="totalItems_Top">
      {{ "ITEMS" | translate }}: {{totalNumberOfResidents}}
    </div>

    <mat-paginator #paginatorTop
      class="ml-2 mb-1"
      [showFirstLastButtons]="true"
      [length]="totalNumberOfResidents"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      [pageSizeOptions]="[25, 50, 75, 100]"
      (page)="paginatorBottom.pageIndex = $event.pageIndex; handlePageEvent($event)"
      aria-label="Select page">
    </mat-paginator>
  </div>

  <button
    class="btn btn-primary shadow-sm residentButton mr-3"
    (click)="move()"
    [disabled]="moveDisabled"
  >
    {{ "MOVE" | translate }}
  </button>

  <form (ngSubmit)="search(0)" class="residentSearch mr-3">
    <div class="input-group">
      <input
        [(ngModel)]="searchValue"
        [ngModelOptions]="{standalone: true}"
        class="form-control border border-primary"
        type="text"
        (keydown.enter)="search(0)"
        placeholder="{{ 'SEARCH' | translate }}... "
        data-testid="input_field"
      />

      <div class="input-group-append">
        <button class="btn btn-primary" type="submit" data-testid="submit_btn">
          {{ "SEARCH" | translate }}
        </button>
      </div>
    </div>
  </form>

  <div class="p-0 residentTable">
    <!--
    <div class="card-header">
      <h3>{{'NEW_RESIDENTS' |translate}}</h3>
      <p>{{'NEW_RESIDENTS_INFO' |translate}}</p>
    </div>
    -->
    <div class="alert alert-success mt-3" *ngIf="moveSuccess" data-testid="move_success">
      {{ "UPDATE_SUCCESS" | translate }}
    </div>

    <div class="alert alert-danger mt-3" *ngIf="moveFailed" data-testid="move_error">
      {{ "ERROR" | translate }}
    </div>

    <table *ngIf="newResidents.residents.length > 0; else elseblock" class="tuneTableToScrollIt mb-3" data-testid="table">
      <thead class="stickyHead">
        <tr>
          <th style="width: 1%">
            <mat-checkbox (click)="selectAll()" [(ngModel)]="allSelected"></mat-checkbox>
          </th>
          <th (click)="sort(0)">
            <span>{{ "NAME" | translate }}</span>
            &nbsp;
            <span>
              <i *ngIf="sortKey !== 0 || (sortKey === 0 && sortBy === 0)" class="fa-solid fa-sort" style="color: #a9a9a9"></i>
              <i *ngIf="sortKey === 0 && sortBy === -1" class="fa-solid fa-sort-up"></i>
              <i *ngIf="sortKey === 0 && sortBy === 1" class="fa-solid fa-sort-down"></i>
            </span>
          </th>
          <th (click)="sort(1)">
            <span>
              {{ "ADDRESS" | translate }}
            </span>
            &nbsp;
            <span>
              <i *ngIf="sortKey !== 1 || (sortKey === 1 && sortBy === 0)" class="fa-solid fa-sort" style="color: #a9a9a9"></i>
              <i *ngIf="sortKey === 1 && sortBy === -1" class="fa-solid fa-sort-up"></i>
              <i *ngIf="sortKey === 1 && sortBy === 1" class="fa-solid fa-sort-down"></i>
            </span>
          </th>
          <th style="min-width: 200px" (click)="sort(2)">
            <span>
              {{ "NEW_RESIDENT_DISPLAYNAME" | translate }}
            </span>
            &nbsp;
            <span>
              <i *ngIf="sortKey !== 2 || (sortKey === 2 && sortBy === 0)" class="fa-solid fa-sort" style="color: #a9a9a9"></i>
              <i *ngIf="sortKey === 2 && sortBy === -1" class="fa-solid fa-sort-up"></i>
              <i *ngIf="sortKey === 2 && sortBy === 1" class="fa-solid fa-sort-down"></i>
            </span>
          </th>
          <th (click)="sort(3)">
            <span>
              {{ "DATEADDED" | translate }}
            </span>
            &nbsp;
            <span>
              <i *ngIf="sortKey !== 3 || (sortKey === 3 && sortBy === 0)" class="fa-solid fa-sort" style="color: #a9a9a9"></i>
              <i *ngIf="sortKey === 3 && sortBy === -1" class="fa-solid fa-sort-up"></i>
              <i *ngIf="sortKey === 3 && sortBy === 1" class="fa-solid fa-sort-down"></i>
            </span>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr data-testid="table_row" *ngFor="let resident of newResidents.residents;let i = index">
          <td>
            <mat-checkbox
              (change)="select(resident.checked)"
              [(ngModel)]="resident.checked"
              [id]="i"
              [checked]="resident.checked">
            </mat-checkbox>
          </td>
          <td>{{ resident.name }}</td>
          <td>{{ resident.address }}</td>
          <td>
            <input
              type="text"
              class="p-1 border"
              [(ngModel)]="resident.displayName"
              [defaultValue]="resident.displayName"
            />

            <button class="btn ml-1" (click)="changeDisplay(resident, i)">
              <span><i class="fas fa-save"></i></span>
            </button>

            <div *ngIf="i == displayNameLine" class="alert alert-success mt-3">
              {{ "UPDATE_SUCCESS" | translate }}
            </div>

            <div *ngIf="i == displayNameLineFailed" class="alert alert-danger mt-3">
              {{ "ERROR" | translate }}
            </div>
          </td>
          <td>{{ resident.dateAdded | moment : "L LTS" }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <ng-template #elseblock>
      <div class="row ml-5" data-testid="no_residents_found">
        {{ "NO_RESIDENTS_FOUND" | translate }}
      </div>
    </ng-template>
  </div>

  <div class="ml-3 mr-1 residentMoveCard">
    <div class="card-header d-flex align-items-center justify-content-between padding_15_px">
      <div>{{ "MOVE_TO" | translate }}</div>
    </div>

    <div class="card-body">
      <mat-radio-group
        [(ngModel)]="selectedCompany"
        (change)="activateMove()"
        [disabled]="companyDisabled"
      >
        <mat-radio-button
          *ngFor="let customer of newResidents.customers;let i = index"
          value="{{ i + 1 }}"
          class="p-3 customers"
        >
          <div>
            <strong>{{ customer.company }}</strong>
            <br />
            {{ customer.companyId }}
            <br />
            {{ customer.address }}
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="d-flex align-items-baseline paginatorBoxBottom">
    <div class="m-2" data-testid="totalItems_Bottom">
      {{ "ITEMS" | translate }}: {{totalNumberOfResidents}}
    </div>
    <mat-paginator #paginatorBottom
      class="ml-2 mb-1"
      [showFirstLastButtons]="true"
      [length]="totalNumberOfResidents"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      [pageSizeOptions]="[25, 50, 75, 100]"
      (page)="paginatorTop.pageIndex = $event.pageIndex; handlePageEvent($event)"
      aria-label="Select page">
    </mat-paginator>
  </div>
</div>

import { CompanyGroupLocation } from "app/models/location.model";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "app/services/api.service";
import { NewResidentSorter } from "app/models/resident.model";
import { SortOrder } from "app/services/helper.service";
import { Subject, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { MomentPipe } from "../../../pipes/pipes";
import { TranslateModule } from "@ngx-translate/core";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgIf, NgFor } from "@angular/common";
import { MatPaginatorIntl, MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { i18nPaginator } from "../new-staff/i18nPaginator";
import { IResidentList } from "./new-residents-interfaces";

@Component({
    selector: "new-residents",
    templateUrl: "./new-residents.component.html",
    styleUrls: ["./new-residents.component.css"],
    standalone: true,
    providers: [{provide: MatPaginatorIntl, useClass: i18nPaginator}],
    imports: [NgIf,  MatPaginatorModule, MatCheckboxModule, FormsModule, NgFor, MatRadioModule, TranslateModule, MomentPipe]
})
export class NewResidentsComponent implements OnInit {
  constructor(private api: ApiService) {
    this.output = this.sortNewResidentsSubject$.pipe(
      switchMap(() => {
        return this.api.getResidentList(
          this.pageSize,
          this.currentPageIndex,
          this.sortKey,
          this.sortBy,
          this.searchValue
        );
      })
    );
    this.output.subscribe((res: IResidentList) => {
      this.initResidents(res);
    });
  }

  private sortNewResidentsSubject = new Subject<any>();
  sortNewResidentsSubject$ = this.sortNewResidentsSubject.asObservable();
  output: Observable<any>;

  newResidents: any = {
    residents: [],
    customers: [],
  };

  pageInited: boolean = false;

  displayName: string = "";

  allSelected: boolean = false;

  companyDisabled: boolean = true;

  moveDisabled: boolean = true;

  moveActivated: boolean = false;

  selectedCount: number = 0;

  moveSuccess: boolean = false;

  moveFailed: boolean = false;

  displayNameFailed: boolean = false;

  displayNameLine: number = -1;

  displayNameLineFailed: number = -1;

  totalNumberOfResidents = 0;

  currentPageIndex: number = 0;

  sortKey: NewResidentSorter = NewResidentSorter.Name;

  sortBy: SortOrder = SortOrder.Default;

  searchValue = "";

  search(page: number) {
    let residentsLoaded = false;
    this.currentPageIndex = page;
    this.api
      .getResidentList(this.pageSize, page, this.sortKey, this.sortBy, this.searchValue)
      .subscribe(
        (res: IResidentList) => {
          this.initResidents(res);
          residentsLoaded = true;
          this.pageInited =
            residentsLoaded ? true : false;
        },
        (error) => {
          console.log(error);
        }
      );

    return false;
  }

  changeDisplay(resident, i) {
    const data = {
      residentId: resident.residentId,
      displayName: resident.displayName,
    };

    setTimeout(() => {
      this.removeMessages();
    }, 3000);

    this.api.setResidentDisplayName(data).subscribe(
      (res) => {
        this.displayNameLine = i;
      },
      (err) => {
        this.displayNameFailed = i;
      }
    );
  }
  selectedCompany: number = 0;

  removeMessages() {
    this.moveSuccess = false;
    this.moveFailed = false;
    this.displayNameLine = -1;
    this.displayNameLineFailed = -1;
  }

  async move() {
    var data = [];
    var resident;
    for (resident of this.newResidents.residents) {
      if (resident.checked === true) {
        data.push({
          residentId: resident.residentId,
          companyId:
            this.newResidents.customers[this.selectedCompany - 1].companyId,
        });
      }
    }

    setTimeout(() => {
      this.removeMessages();
    }, 3000);

    await this.api.setResidentCompanyLocation(data).subscribe(
      (res) => {
        this.moveSuccess = true;
      },
      (err) => {
        this.moveFailed = true;
        console.log(err);
      }
    );

    await this.api
      .getResidentList(this.pageSize, this.currentPageIndex, this.sortKey, this.sortBy, this.searchValue)
      .subscribe(
        (res: IResidentList) => {
          this.initResidents(res);
        },
        (err) => {
          this.moveFailed = true;
          console.log(err);
        }
      );
  }

  select(selected) {
    this.selectedCount += selected * 2 - 1;
    if (this.selectedCount) {
      this.companyDisabled = false;
      if (this.moveActivated) this.moveDisabled = false;
    } else {
      this.companyDisabled = true;
      if (this.moveActivated) this.moveDisabled = true;
    }
  }

  /*
  selectAll() {
    var resident,
      i = 0;

    if (!this.allSelected) {
      this.companyDisabled = false;
      if (this.moveActivated) this.moveDisabled = false;
      this.selectedCount = this.newResidents.residents.length;
    } else {
      this.companyDisabled = true;
      if (this.moveActivated) this.moveDisabled = true;
      this.selectedCount = 0;
    }

    for (resident of this.newResidents.residents) {
      if (typeof resident.checked === "undefined") {
        this.newResidents.residents[i]["checked"] = false;
      }
      this.newResidents.residents[i++]["checked"] = !this.allSelected;
    }
  }
  */

  selectAll(): void {
    if (this.allSelected) {
      this.companyDisabled = false;
      if (this.moveActivated) this.moveDisabled = false;
      this.selectedCount = this.newResidents.residents.length;
    } else {
      this.companyDisabled = true;
      if (this.moveActivated) this.moveDisabled = true;
      this.selectedCount = 0;
    }

    const check = this.allSelected;
    for (let i = 0; i < this.newResidents.residents.length; i++) {
      this.newResidents.residents[i]["checked"] = check;
    }
  }

  sort(sorter: NewResidentSorter): void {
    if (sorter !== this.sortKey) this.sortBy = SortOrder.Default;
    this.sortKey = sorter;
    switch (this.sortBy) {
      case SortOrder.Default:
        this.sortBy = SortOrder.Asc;
        break;
      case SortOrder.Asc:
        this.sortBy = SortOrder.Desc;
        break;
      case SortOrder.Desc:
        this.sortBy = SortOrder.Default;
    }
    this.sortNewResidentsSubject.next();
  }

  activateMove() {
    this.moveActivated = true;
    this.moveDisabled = false;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  initResidents(res) {
    this.newResidents.residents = res.pageData;
    this.totalNumberOfResidents = res.totalNumberOfResidents; // this is not the length of pageData!
  }

  ngOnInit(): void {
    let residentsLoaded = false;
    let companyGroupsLoaded = false;
    this.api
      .getResidentList(this.pageSize, this.currentPageIndex, this.sortKey, this.sortBy, this.searchValue)
      .subscribe(
        (res: IResidentList) => {
          this.initResidents(res);
          residentsLoaded = true;
          this.pageInited =
            residentsLoaded && companyGroupsLoaded ? true : false;
        },
        (error) => {
          console.log(error);
        }
      );
    this.api.getCompanyGroups().subscribe(
      (res) => {
        this.newResidents.customers = res;
        companyGroupsLoaded = true;
        this.pageInited = residentsLoaded && companyGroupsLoaded ? true : false;
      },
      (error) => {
        console.log(error);
      }
    );
  }



  pageSize = 50;

  handlePageEvent(pageEvent: PageEvent){
    this.pageSize = pageEvent.pageSize
    let residentsLoaded = false;
    this.currentPageIndex = pageEvent.pageIndex

    this.api
      .getResidentList(this.pageSize, pageEvent.pageIndex, this.sortKey, this.sortBy, this.searchValue)
      .subscribe(
        (res: IResidentList) => {
          this.initResidents(res);
          residentsLoaded = true;
          this.pageInited = residentsLoaded ? true : false;
        },
        (error) => {
          console.log(error);
        }
      );
  }
}

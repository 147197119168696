<div id="bindingTimeContainer">
  <div *ngIf="successMsg" class="alert alert-success alert-trim" role="alert">
    {{ successMsg | translate }}
  </div>

  <div *ngIf="errorMsg" class="alert alert-danger alert-trim" role="alert">
    {{ errorMsg | translate }}
  </div>

  <p>{{ "BINDING_TIME" | translate }}</p>
  <form class="d-flex pt-2" name="bindingTimeForm">
    <button
      id="submitBindingTimeBtn"
      type="submit"
      [ngClass]="deviceBindingTimeControl.valid ? 'validBg' : 'invalidBg cursorDisabled'"
      [disabled]="deviceBindingTimeControl.invalid"
      (click)="submit()"
    >

      <span
        *ngIf="!updating"
        [ngClass]="deviceBindingTimeControl.valid ? 'fa-duotone fa-check' : 'fa-solid fa-x'"
        class="fa-lg"
        style="color: #FCFCFC"
      ></span>

      <span
        *ngIf="updating"
        class="spinner-border spinner-border-sm"
        style="color: #FCFCFC"
      ></span>
    </button>

    <input
      type="number"
      id="timeInput"
      name="timeInput"
      class="form-control"
      [formControl]="deviceBindingTimeControl"
    >

    <select
      class="form-control"
      id="timeFormatSelect"
      name="timeFormat"
      [formControl]="deviceBindingTimeUnitControl"
    >
      <option value="Days">{{ "DAYS" | translate }}</option>
      <option value="Months">{{ "MONTHS" | translate }}</option>
      <option value="Years">{{ "YEARS" | translate }}</option>
    </select>

    <select
      class="form-control"
      id="visibilitySelect"
      name="bindingTimeVisible"
      [formControl]="deviceBindingTimeVisibleControl"
    >
      <option [value]="false">{{ "DISABLED" | translate }}</option>
      <option [value]="true">{{ "ENABLED" | translate }}</option>
    </select>
  </form>

  <small *ngIf="deviceBindingTimeControl.invalid" class="text-danger invalidTimeText">
    {{ "INVALID_VALUE" | translate}}
  </small>
</div>

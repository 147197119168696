<div
    class="custom"
    id="tab-group"
    *ngIf="user?.roleLevel === 95"
>
<!-- [(selectedIndex)]="selectedIndex" -->
<h3 class="mt-3">{{"RESIDENTS" | translate}}</h3>
    <mat-tab-group
        class="custom-tabs mt-3"
        mat-align-tabs="start"
        animationDuration="0ms"
    >
        <!--(selectedTabChange)="setData(selectedIndex)"-->
        <mat-tab label="{{ 'NEW' | translate }}">
            <new-residents></new-residents>
        </mat-tab>
        <mat-tab label="{{ 'CHANGES' | translate }}" >
            <flagged-residents></flagged-residents>
        </mat-tab>
        <mat-tab label="{{ 'ALL' | translate }}">
            <all-residents></all-residents>
        </mat-tab>
    </mat-tab-group>
</div>

import { MultiselectDropdownComponent } from "./../multiselect-dropdown/multiselect-dropdown.component";
import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { ActivityLogTableComponent } from "./activity-log-table/activity-log-table.component";
import { CapitalizePipe } from "app/pipes/pipes";
import {
  ActivityLogFilters,
  ActivityLogFilterOptions,
} from "app/models/activity-log.model";
import { ActivityLogService } from "app/services/activity-log.service";

@Component({
  selector: "app-activity-log",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ActivityLogTableComponent,
    CapitalizePipe,
    MultiselectDropdownComponent,
    TranslateModule,
    FormsModule,
  ],
  providers: [ActivityLogService],
  templateUrl: "./activity-log.component.html",
  styleUrls: ["./activity-log.component.css"],
})
export class ActivityLogComponent implements OnInit {
  constructor(public activityLogService: ActivityLogService) { }

  options = new ActivityLogFilterOptions();

  updateFilters(): void {
    this.activityLogService.updateFilters(this.activityLogService.filters);
  }

  clearFilters(): void {
    this.activityLogService.updateFilters(new ActivityLogFilters());
  }

  ngOnInit(): void {
    this.activityLogService.getUserFilters(this.options);
  }
}

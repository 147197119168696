import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { ApiService } from "app/services/api.service";
import { ActivatedRoute } from "@angular/router";
import { Location, NgIf, NgFor } from "@angular/common";
import { ICompanyList, Company } from "app/models/company.models";
import { TranslateModule } from "@ngx-translate/core";
import { CompanyInfoComponent } from "./company-info/company-info.component";
import { AddCompanyComponent } from "./company-add/company-add.component";
import { MatListModule } from "@angular/material/list";
import { MatInputModule } from "@angular/material/input";

@Component({
    selector: "company-management",
    templateUrl: "./company-management.component.html",
    styleUrls: ["./company-management.component.css"],
    standalone: true,
    imports: [NgIf, MatInputModule, FormsModule, ReactiveFormsModule, MatListModule, NgFor, AddCompanyComponent, CompanyInfoComponent, TranslateModule]
})
export class CompanyManagementComponent implements OnInit, OnDestroy {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  companies: ICompanyList[];

  selectedCompany: Company;
  selectedCompanyId: string;

  listedCompanies: ICompanyList[] = [];
  companySearchFilter = new UntypedFormControl("");

  //Variables for controlling child components initialization
  showAddCompanyForm = new BehaviorSubject(false);
  showCompanyInfo = new BehaviorSubject(false);

  successMsg = new BehaviorSubject("");
  failedMsg = new BehaviorSubject("");

  clearMessages(): void {
    this.successMsg.next("");
    this.failedMsg.next("");
  }

  companySelectEvent(companyId: string, server: string): void {
    this.showCompanyInfo.next(false);
    this.api.getCompany(companyId, server).subscribe((res) => {
      this.selectedCompany = res;
      this.selectedCompanyId = companyId;
      this.showCompanyInfo.next(true);
    });
  }

  filterCompanies() {
    if (this.companySearchFilter.value === "")
      this.listedCompanies = this.companies;
    else {
      let temp = [];
      for (const company of this.companies) {
        if (
          this.companySearchFilter.value.toLocaleLowerCase() ===
          company.companyName
            .substring(0, this.companySearchFilter.value.length)
            .toLowerCase()
        )
          temp.push(company);
      }
      this.listedCompanies = temp;
    }
  }

  refreshCompanies($event: boolean): void {
    if($event) {
      this.api.getCompanyList().subscribe((res: ICompanyList[]) => {
        this.companies = res.sort(this.compareCompanyNames);
        this.listedCompanies = this.companies;
      })
    }
  }

  compareCompanyNames(a: ICompanyList, b: ICompanyList): number {
    if (a.companyName < b.companyName) return -1;
    if (a.companyName > b.companyName) return 1;
    return 0;
  }

  ngOnInit(): void {
    this.api.getCompanyList().subscribe((res: ICompanyList[]) => {
      this.companies = res.sort(this.compareCompanyNames);
      this.listedCompanies = this.companies;

      this.route.params.subscribe(
        (params) => (this.selectedCompanyId = params["id"])
      );
    });

    this.showCompanyInfo.subscribe((show) => {
      if (show) {
        this.location.go("system/company-management/" + this.selectedCompanyId);
      } else {
        this.location.go("system/company-management");
      }
    });
  }

  ngOnDestroy(): void {
    this.showAddCompanyForm.unsubscribe();
    this.showCompanyInfo.unsubscribe();
    this.successMsg.unsubscribe();
    this.failedMsg.unsubscribe();
  }
}


import { Component, Input } from '@angular/core';
import { ICompanyGroup } from 'app/models/company.models';
import { BehaviorSubject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CompanyGroupEditCompaniesComponent } from './tabs/company-group-edit-companies/company-group-edit-companies.component';
import { CompanyGroupEditInformationComponent } from './tabs/company-group-edit-info/company-group-edit-info.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { CompanyGroupEditBindingTimeComponent } from './tabs/company-group-edit-binding-time/company-group-edit-binding-time.component';

@Component({
    selector: 'company-group-edit',
    templateUrl: './company-group-edit.component.html',
    styleUrls: ['./company-group-edit.component.css'],
    standalone: true,
    imports: [CommonModule, MatTabsModule, CompanyGroupEditInformationComponent, CompanyGroupEditCompaniesComponent, CompanyGroupEditBindingTimeComponent, TranslateModule]
})
export class CompanyGroupEditComponent {

  @Input() companyGroup: ICompanyGroup;
  @Input() showForm: BehaviorSubject<boolean>;

  tabIndex: number = 0;
}

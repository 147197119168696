<div class="row" *ngIf="device">
    <div class="col-lg-12">
        <div class="row">
            <div class="col-lg-6">
                <!-- View device information-->
                <div *ngIf="!editMode">
                    <div>
                        <strong>{{'SERIAL_NUMBER' |translate}}:</strong> {{device.id}}
                    </div>
                    <div *ngIf="device.name">
                        <strong>{{'NAME' |translate}}:</strong> {{device.name}}
                    </div>
                    <div>
                        <strong>{{'ENABLED' |translate}}:</strong> {{device.keyEnabled.toString() |translate}}
                    </div>
                    <div *ngIf="user.roleLevel >= 100" class="mt-3">
                        <button class="btn btn-outline-primary gray-bg" (click)="editMode = !editMode">
                            <span class="fal fa-fw fa-edit"></span>
                            {{'MODIFY' |translate}}
                        </button>
                    </div>
                </div>
                <!-- Edit device information -->
                <div *ngIf="editMode">
                    <form [formGroup]="keyEditForm" (ngSubmit)="formSubmitSubject.next()">
                        <div class="form-group">
                            <label><strong>{{'NAME' |translate}}</strong></label>
                            <input class="form-control" type="text" placeholder="{{'NAME' |translate}}" formControlName="name">
                        </div>
                        <div class="form-group">
                            <label>{{'ENABLED' |translate}}</label>
                            <section>
                                <mat-slide-toggle formControlName="enabled">
                                </mat-slide-toggle>
                            </section>
                        </div>
                        <div class="form-group">
                            <button [disabled]="(keyEditForm.invalid || keyEditForm.pristine)" type="submit" class="btn btn-outline-primary gray-bg mr-1"><span class="fal fa-save"></span> {{'SAVE' |translate}}</button>
                            <button type="reset" class="btn btn-outline-danger" (click)="resetKeyEditForm()"><span class="fal fa-times"></span> {{'CANCEL' |translate}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="!device">
    <div class="col-lg-12">
        <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg">
    </div>
</div>

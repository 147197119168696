import { IAlertsByAlarmReason } from "app/models/statistics.model";
import { IAlarmReasonTranslations } from "app/models/alarm-reasons.model";
import { Chart, ChartDataset } from "chart.js";
import { Subscription, fromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import { ALERTS_BY_ALARM_REASON_CHART_CONFIGS } from "app/shared/chart-configs.shared";
import { TranslateModule } from "@ngx-translate/core";
import { NgIf } from "@angular/common";
import {
  Component,
  OnInit,
  Input,
  HostListener,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";

@Component({
  selector: "alerts-by-alarm-reason",
  templateUrl: "./alerts-by-alarm-reason.component.html",
  styleUrls: ["./alerts-by-alarm-reason.component.css"],
  standalone: true,
  imports: [TranslateModule, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsByAlarmReasonComponent implements OnInit, OnDestroy {
  constructor() { }

  @Input() alertsByAlarmReasons: IAlertsByAlarmReason[];
  @Input() translations: IAlarmReasonTranslations;

  chart: Chart;

  currentColorIndex: number = 0;
  maxColorIndex: number = COLORS.length - 1;

  mouseRightClickSubscription: Subscription;

  pickColor(): string {
    const pickedColor = COLORS[this.currentColorIndex];
    this.currentColorIndex === this.maxColorIndex
      ? (this.currentColorIndex = 0)
      : this.currentColorIndex++;
    return pickedColor;
  }

  // Prevent opening browser's mouse right click dropdown menu when clicked
  @HostListener("contextmenu", ["$event"])
  onContextMenu(event: MouseEvent) {
    event.preventDefault();
  }

  resetZoom() {
    this.chart.resetZoom();
  }

  getCategoryName(dictEntryKey: string): string {
    return this.translations?.alarmCategory?.find(
      (category) => category.key === dictEntryKey
    )?.translation;
  }

  getAlarmReasonName(dictEntryKey: string): string {
    return this.translations?.alarmReason?.find(
      (reason) => reason.key === dictEntryKey
    )?.translation;
  }

  drawAlertsByAlarmReasonsChart(): void {
    const datasets: ChartDataset<"bar">[] = [];
    const categoryLabels: string[] = [];
    const maxCategoryAlertCount = Math.max(
      ...this.alertsByAlarmReasons.map((x) => x.totalAlerts)
    );

    const totalAlerts = this.alertsByAlarmReasons.reduce(
      (sum, alarmCategory) => sum + alarmCategory.totalAlerts,
      0
    );

    let i = 0;
    for (const category of this.alertsByAlarmReasons) {
      // Get category label name
      categoryLabels.push(this.getCategoryName(category.alarmReasonCategory));

      // Parse chart data
      for (const alarmReason of category.alarmReasonAlerts) {
        let dataset: ChartDataset<"bar"> = {
          label: this.getAlarmReasonName(alarmReason.alarmReason),
          backgroundColor: this.pickColor(),
          barThickness: 20,
          data: [],
        };

        dataset.data[i] = alarmReason.alertCount;
        datasets.push(dataset);
      }
      i++;
    }
    // Set data and alter configuration defaults
    ALERTS_BY_ALARM_REASON_CHART_CONFIGS.data.datasets = datasets;
    ALERTS_BY_ALARM_REASON_CHART_CONFIGS.data.labels = categoryLabels;
    ALERTS_BY_ALARM_REASON_CHART_CONFIGS.options.scales.x.max =
      maxCategoryAlertCount + 5; // Extend the x-axis scale by 5 alerts to avoid hiding the percent data label at the top of the stacked bar

    // Format the category labels
    ALERTS_BY_ALARM_REASON_CHART_CONFIGS.options.scales.y.ticks = {
      color: "#707070",
      font: {
        size: 12,
        weight: "600",
      },
      crossAlign: "far",
      callback: (_, index) => {
        const size = 35;
        const label = categoryLabels[index];

        return label?.length ?? 0 > size
          ? label.substring(0, size - 3) + "..."
          : label;
      },
    };

    // Set custom datalabel for showing percentage of total alerts that
    ALERTS_BY_ALARM_REASON_CHART_CONFIGS.options.plugins.datalabels = {
      color: "#707070",
      anchor: "end",
      align: "end",
      // Formatter loops through all values in the chart
      formatter: (_, context) => {
        const categoryAlerts = [];
        const datasets = context.chart.data.datasets;

        let lastCategoryDatasetIndex = 0;
        for (let i = 0; i < datasets.length; i++) {
          if (datasets[i].data[context.dataIndex] !== undefined) {
            categoryAlerts.push(datasets[i].data[context.dataIndex]); // Get alert counts of each stacked bar in the alarm category
            lastCategoryDatasetIndex = i; // Keep track of the last dataset index of the top stacked bar that belongs to the category
          }
        }

        // Calculate total alerts in the category
        const totalCategoryAlerts = categoryAlerts.reduce(
          (total, datapoint) => total + datapoint,
          0
        );

        // Set percentage datalabel to the top bar in the stack
        if (context.datasetIndex === lastCategoryDatasetIndex) {
          return ((totalCategoryAlerts / totalAlerts) * 100).toFixed(0) + "%";
        }
        return "";
      },
    };

    // Create chart
    this.chart = new Chart(
      "alertsByAlarmReasonsChart",
      ALERTS_BY_ALARM_REASON_CHART_CONFIGS
    );

    this.canvasHeight = this.alertsByAlarmReasons.length;
  }

  set canvasHeight(categoryCount: number) {
    const baseHeight = 200;
    const heightIncrement = 25 * categoryCount;

    const pixels = baseHeight + heightIncrement + "px";
    this.chart.canvas.parentElement.style.height = pixels;
  }

  ngOnInit(): void {
    this.drawAlertsByAlarmReasonsChart();

    // Init listening mouse right click event
    const mouseRightClick$ = fromEvent(document, "mousedown").pipe(
      filter((event: MouseEvent) => event.button === 2)
    );

    this.mouseRightClickSubscription = mouseRightClick$.subscribe(() => {
      this.resetZoom();
    });
  }

  ngOnDestroy(): void {
    this.mouseRightClickSubscription?.unsubscribe();
  }
}

const COLORS = [
  "rgb(112, 212, 252)",
  "rgb(0, 136, 227)",
  "rgb(75, 194, 186)",
  "rgb(255, 179, 153)",
  "rgb(255, 219, 160)",
  "rgb(143, 122, 219)",
  "rgb(152, 238, 206)",
  "rgb(255, 249, 179)",
  "rgb(196, 175, 148)",
  "rgb(234, 54, 127)",
  "rgb(204, 255, 252)",
  "rgb(255, 132, 83)",
  "rgb(198, 235, 203)",
  "rgb(255, 246, 133)",
  "rgb(255, 147, 54)",
  "rgb(230, 109, 126)",
  "rgb(129, 126, 227)",
  "rgb(94, 69, 75)",
  "rgb(255, 222, 248)",
  "rgb(27, 160, 148)",
];

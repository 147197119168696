<div *ngFor="let event of historyEvents | slice : 0 : collapseItemCount" class="log-item">
  <p class="event-details" style="padding-bottom: 4px">
    <strong>{{ event.userName | translate }}</strong>
    {{ (event.statusKey + "_ACTION") | translate }}
    <strong>{{ (event.statusKey + "_TARGET") | translate }}</strong>
    <span class="ml-3">{{ event.timestamp | moment : "l LTS" }}</span>
  </p>

  <div class="event-values d-flex">
    <app-device-history-status-selector
      [statusKey]="event.statusKey"
      [value]="event.oldValue"
    ></app-device-history-status-selector>

    <span class="fa-sharp fa-thin fa-arrow-right align-self-center mx-2 mt-1"></span>

    <app-device-history-status-selector
      [statusKey]="event.statusKey"
      [value]="event.newValue"
    ></app-device-history-status-selector>
  </div>
</div>

<div [@expandCollapseDetails]="expandState">
  <div *ngFor="let event of historyEvents | slice : collapseItemCount" class="log-item">
      <p class="event-details" style="padding-bottom: 4px">
        <strong>{{ event.userName | translate }}</strong>
        {{ (event.statusKey + "_ACTION") | translate }}
        <strong>{{ (event.statusKey + "_TARGET") | translate }}</strong>
        <span class="ml-3">{{ event.timestamp | moment : "l LTS" }}</span>
      </p>

      <div class="event-values d-flex">
        <app-device-history-status-selector
          [statusKey]="event.statusKey"
          [value]="event.oldValue"
        ></app-device-history-status-selector>

        <span class="fa-sharp fa-thin fa-arrow-right align-self-center mx-2 mt-1"></span>

        <app-device-history-status-selector
          [statusKey]="event.statusKey"
          [value]="event.newValue"
        ></app-device-history-status-selector>
      </div>
    </div>
</div>



<button class="expand-btn mt-2" *ngIf="historyEvents.length > collapseItemCount" (click)="toggleExpand()">
  {{ expandState === "collapsed" ? ("SHOW_MORE" | translate) : ("SHOW_LESS" | translate) }}
</button>

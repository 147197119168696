<p *ngIf="!pageInited">{{ "LOADING" | translate }}...</p>
<div class="container-fluid p-0" [hidden]="!pageInited">
  <div class="d-flex align-items-baseline">
    <div class="m-2">
      {{ "ITEMS" | translate }}: {{ allResPageData.resultCount }}
    </div>

    <mat-paginator
     #paginatorTop
     class="ml-2 mb-1"
     [showFirstLastButtons]="true"
     [length]="allResPageData.resultCount"
     [pageSize]="allResPageData.pageSize"
     [pageSizeOptions]="[25, 50, 75, 100]"
     (page)="paginatorBottom.pageIndex = $event.pageIndex; paginatorBottom.pageSize = $event.pageSize; handlePageEvent($event)"
     aria-label="Select page">
    </mat-paginator>

    <form (ngSubmit)="search(0)">
      <div class="input-group">
        <input
          #residentSearchInput
          type="text"
          class="form-control border border-primary"
          (keydown.enter)="search(0)"
          placeholder="{{ 'SEARCH' | translate }}... "
        />

        <div class="input-group-append">
          <button class="btn btn-primary" type="submit">
            {{ "SEARCH" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <mat-accordion multi="true">
    <mat-expansion-panel class="residents-header">
      <mat-expansion-panel-header #panelH (click)="panelH._toggle()">
        <mat-panel-title>
          <div class="row residents resheader-content p-0">
            <div class="col" (click)="sortTable('Name')">
              <div class="h">
                {{ "NAME" | translate }}
              </div>
              &nbsp;
              <span>
                <i
                 *ngIf="selectedSortHeader !== 'Name' || (selectedSortHeader === 'Name' && sortBy === 0)"
                 class="fa-solid fa-sort"
                 style="color: #a9a9a9">
                </i>

                <i *ngIf="selectedSortHeader === 'Name' && sortBy === -1" class="fa-solid fa-sort-up"></i>
                <i *ngIf="selectedSortHeader === 'Name' && sortBy === 1"class="fa-solid fa-sort-down"></i>
              </span>
            </div>

            <div class="col" (click)="sortTable('Address')">
              <div class="h">
                {{ "ADDRESS" | translate }}
              </div>
              &nbsp;
              <span>
                <i
                 *ngIf="selectedSortHeader !== 'Address' || (selectedSortHeader === 'Address' && sortBy === 0)"
                 class="fa-solid fa-sort"
                 style="color: #a9a9a9">
                </i>
                <i *ngIf="selectedSortHeader === 'Address' && sortBy === -1" class="fa-solid fa-sort-up"></i>
                <i *ngIf="selectedSortHeader === 'Address' && sortBy === 1" class="fa-solid fa-sort-down"></i>
              </span>
            </div>

            <div class="col" (click)="sortTable('Company')">
              <div class="h">
                {{ "COMPANY" | translate }}
              </div>
              &nbsp;
              <span>
                <i
                 *ngIf="selectedSortHeader !== 'Company' || (selectedSortHeader === 'Company' && sortBy === 0)"
                 class="fa-solid fa-sort"
                 style="color: #a9a9a9">
                </i>
                <i *ngIf="selectedSortHeader === 'Company' && sortBy === -1" class="fa-solid fa-sort-up"></i>
                <i *ngIf="selectedSortHeader === 'Company' && sortBy === 1" class="fa-solid fa-sort-down"></i>
              </span>
            </div>

            <div class="col-sm-2 rescmpid" (click)="sortTable('SSnumber')">
              <div class="h">
                {{ "SSNUMBER" | translate }}
              </div>
              &nbsp;
              <span>
                <i
                 *ngIf="selectedSortHeader !== 'SSnumber' || (selectedSortHeader === 'SSnumber' && sortBy === 0)"
                 class="fa-solid fa-sort"
                 style="color: #a9a9a9">
                </i>
                <i *ngIf="selectedSortHeader === 'SSnumber' && sortBy === -1" class="fa-solid fa-sort-up"></i>
                <i *ngIf="selectedSortHeader === 'SSnumber' && sortBy === 1" class="fa-solid fa-sort-down"></i>
              </span>
            </div>

            <div class="col-sm-2 rescmpid" (click)="sortTable('PhoneHome')">
              <div class="h">
                {{ "PHONE_HOME" | translate }}
              </div>
              &nbsp;
              <span>
                <i
                 *ngIf="selectedSortHeader !== 'PhoneHome' || (selectedSortHeader === 'PhoneHome' && sortBy === 0)"
                 class="fa-solid fa-sort"
                 style="color: #a9a9a9">
                </i>
                <i *ngIf="selectedSortHeader === 'PhoneHome' && sortBy === -1" class="fa-solid fa-sort-up"></i>
                <i *ngIf="selectedSortHeader === 'PhoneHome' && sortBy === 1" class="fa-solid fa-sort-down"></i>
              </span>
            </div>

            <div class="col-sm-2 rescmpid" (click)="sortTable('PhoneCell')">
              <div class="h">
                {{ "PHONE_CELL" | translate }}
              </div>
              &nbsp;
              <span>
                <i
                 *ngIf="selectedSortHeader !== 'PhoneCell' || (selectedSortHeader === 'PhoneCell' && sortBy === 0)"
                 class="fa-solid fa-sort"
                 style="color: #a9a9a9">
                </i>
                <i *ngIf="selectedSortHeader === 'PhoneCell' && sortBy === -1" class="fa-solid fa-sort-up"></i>
                <i *ngIf="selectedSortHeader === 'PhoneCell' && sortBy === 1" class="fa-solid fa-sort-down"></i>
              </span>
            </div>

            <div class="col" (click)="sortTable('DeviceCount')">
              <div class="h">
                {{ "DEVICES" | translate }}
              </div>
              &nbsp;
              <span>
                <i
                 *ngIf="selectedSortHeader !== 'DeviceCount' || (selectedSortHeader === 'DeviceCount' && sortBy === 0)"
                 class="fa-solid fa-sort"
                 style="color: #a9a9a9">
                </i>
                <i *ngIf="selectedSortHeader === 'DeviceCount' && sortBy === -1" class="fa-solid fa-sort-up"></i>
                <i *ngIf="selectedSortHeader === 'DeviceCount' && sortBy === 1" class="fa-solid fa-sort-down"></i>
              </span>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <ng-container *ngFor="let resident of filteredResidents; let i = index">
      <mat-expansion-panel
        [expanded]="resident.panelOpen"
        (opened)="onPanelOpen(i)"
        #panel
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="row residents">
              <div class="col resname" innerHTML="{{ resident.residentName | highlight : residentFilter }}"></div>
              <div class="col resaddr" innerHTML="{{ resident.address | highlight : residentFilter }}"></div>
              <div class="col rescmp" innerHTML="{{ resident.company | highlight : residentFilter }}"></div>
              <div class="col rescmp" innerHTML="{{ resident.sSnumber | highlight : residentFilter }}"></div>
              <div class="col-sm-2" innerHTML="{{ resident.phoneHome | highlight : residentFilter }}"></div>
              <div class="col-sm-2" innerHTML="{{ resident.phoneCell | highlight : residentFilter }}"></div>
              <div class="col resdev" innerHTML="{{ resident.residentDevices.toString() | highlight : residentFilter }}"></div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngIf="panel.expanded">
          <div *ngIf="resident.agrigatedDevices?.length; else elseBlock">
            <div class="row res-dev-header">
              <div class="col">
                {{ "DEVICE_TYPE" | translate }}
              </div>
              <div class="col">
                {{ "DEVICE_NAME" | translate }}
              </div>
              <div class="col">
                {{ "DEVICE_STATUS" | translate }}
              </div>
              <div class="col">{{ "DEVICE_ID" | translate }}</div>
            </div>

            <div class="row" *ngFor="let device of resident.agrigatedDevices">
              <div class="col" innerHTML="{{ device.devType | residentDevice : deviceTranslations | highlight : residentFilter }}"></div>
              <div class="col" innerHTML="{{ device.devName | highlight : residentFilter }}"></div>
              <div class="col" innerHTML="{{ device.devStatus | highlight : residentFilter }}"></div>
              <div class="col" innerHTML="{{ device.deviceId | highlight : residentFilter }}"></div>
            </div>
          </div>
          <ng-template #elseBlock>
            {{ "RESIDENT_NO_DEVICES" | translate}}
          </ng-template>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>

    <ng-container *ngIf="!filteredResidents.length && allResidentsLoaded">
      {{ "NO_RESULTS" | translate }}
    </ng-container>
  </mat-accordion>

  <div class="d-flex align-items-baseline paginatorBox">
    <div class="m-2">{{ "ITEMS" | translate }}: {{ allResPageData.resultCount }}</div>
    <mat-paginator
     #paginatorBottom
     class="ml-2 mb-1"
     [showFirstLastButtons]="true"
     [length]="allResPageData.resultCount"
     [pageSize]="allResPageData.pageSize"
     [pageSizeOptions]="[25, 50, 75, 100]"
     (page)="paginatorTop.pageIndex = $event.pageIndex; paginatorTop.pageSize = $event.pageSize; handlePageEvent($event)"
     aria-label="Select page">
    </mat-paginator>
  </div>
</div>

<div class="mainDarkBlueCard">
    <div class="cardContent">
        <h4 class="alertsHeader">
            {{ "ALERTS_WITH_FIRST_LETTER_UPPERCASE" | translate }}
        </h4>

        <h3 class="pb-3">
            <b>{{ locationAlerts.totalAlerts }}</b>
        </h3>

        <div class="row" id="tableHeaders">
            <div class="col-sm-8">
                <div class="d-flex">
                    <p class="mr-2">{{ "LOCATION" | translate }}</p>
                    <div class="sort cursorPointer" (click)="sort('location')">
                        <i
                            class="fa-solid fa-caret-up caretUp"
                            [ngClass]="{
                                sorted: sorter === 'location' && sortOrder === 1
                            }"
                        ></i>
                        <i
                            class="fa-solid fa-caret-down caretDown"
                            [ngClass]="{
                                sorted:
                                    sorter === 'location' && sortOrder === -1
                            }"
                        ></i>
                    </div>
                </div>
            </div>

            <div class="col-sm">
                <div class="d-flex">
                    <p class="mr-2">
                        {{ "ALERTS_WITH_FIRST_LETTER_UPPERCASE" | translate }}
                    </p>
                    <div class="sort cursorPointer" (click)="sort('alerts')">
                        <i
                            class="fa-solid fa-caret-up caretUp"
                            [ngClass]="{
                                sorted: sorter === 'alerts' && sortOrder === 1
                            }"
                        ></i>
                        <i
                            class="fa-solid fa-caret-down caretDown"
                            [ngClass]="{
                                sorted: sorter === 'alerts' && sortOrder === -1
                            }"
                        ></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="overline pt-2">
            <div
                class="row"
                [ngClass]="{ rowSpacing: !first, lastRow: last }"
                *ngFor="
                    let location of collapsedList;
                    let first = first;
                    let last = last
                "
            >
                <p class="col-sm-8">{{ location.locationName }}</p>

                <div class="col-sm">
                    <div
                        class="alarmContainer"
                        [ngStyle]="alarmContainerStyles"
                    >
                        <p class="alarmCount">{{ location.alertCount }}</p>
                        <p class="ml-1">{{ "ALERTS" | translate }}</p>
                    </div>
                </div>
            </div>

            <div [@expandCollapseDetails]="expandState">
                <div
                    class="row"
                    [ngClass]="{ rowSpacing: !first }"
                    *ngFor="let location of expandedList; let first = first"
                >
                    <p class="col-sm-8">{{ location.locationName }}</p>
                    <div class="col-sm">
                        <div
                            class="alarmContainer"
                            [ngStyle]="alarmContainerStyles"
                        >
                            <p class="alarmCount">{{ location.alertCount }}</p>
                            <p class="ml-1">{{ "ALERTS" | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overline" id="footer">
        <div>
            <button
                id="showMoreLessBtn"
                (click)="toggleExpand()"
                *ngIf="
                    collapsedList.length <
                    locationAlerts.alertsByLocations.length
                "
            >
                {{
                    expandState === "collapsed"
                        ? ("SHOW_MORE" | translate)
                        : ("SHOW_LESS" | translate)
                }}
            </button>
        </div>

        <div>
            <p class="p-0">
                {{ "SHOWING" | translate }}
                {{
                    (expandState === "expanded"
                        ? locationAlerts.alertsByLocations.length
                        : collapsedList.length) +
                        " " +
                        ("OF" | translate)
                }}
                {{ locationAlerts.alertsByLocations.length }}
            </p>
        </div>
    </div>
</div>

<div *ngIf="initialized" class="card stickyCard">
    <div class="card-header-white header-underline">
        <ng-container class="white-header-text" *ngIf="tempRoute.defaultRoute">
            <h6 class="white-header-text">{{tempRoute.locationName}} ({{'DEFAULT_ROUTE' |translate}})</h6>
        </ng-container>
        <ng-container *ngIf="!tempRoute.defaultRoute && !newRoute">
            <h6 class="white-header-text"> {{tempRoute.name}} ({{tempRoute.id}})</h6>
        </ng-container>
        <ng-container *ngIf="newRoute">
            <h6 class="white-header-text">{{'CREATE_NEW_ROUTE' |translate}}</h6>
        </ng-container>
        <span class="float-right cursorPointer" (click)="closeForm()">
            <span class="fa-light fa-xmark white-header-xmark-icon"></span>
        </span>
    </div>

    <div class="card-body-white">
        <div class="col-12">
            <mat-horizontal-stepper #stepper class="stepperContainer" [linear]="!informationStep.completed && newRoute"
                [selectedIndex]="startTab">
                <ng-template matStepperIcon="edit">
                    <mat-icon>done</mat-icon>
                </ng-template>


                <mat-step [stepControl]="informationForm" #informationStep>
                    <ng-template matStepLabel>{{'BASIC_INFORMATION' | translate}}</ng-template>
                    <alarm-route-basic-information [tempRoute]="tempRoute" [alarmRouteLocations]="alarmRouteLocations"
                        [callCenters]="callCenters"
                        (onLocationChange)="onLocationChange()"></alarm-route-basic-information>
                </mat-step>

                <mat-step *ngIf="!tempRoute.defaultRoute">
                    <ng-template matStepLabel>{{'DEVICES' |translate}}</ng-template>
                    <alarm-route-devices [tempRoute]="tempRoute" [deviceList]="rawDeviceList"></alarm-route-devices>
                </mat-step>


                <mat-step>
                    <ng-template matStepLabel>{{'RECIPIENTS' |translate}}</ng-template>
                    <alarm-route-recipients [tempRoute]="tempRoute" [fallbackRecipients]="false" [allStaff]="allStaff"
                        [allReceiverTeams]="allReceiverTeams"
                        [customerInformation]="customerInformation"></alarm-route-recipients>
                </mat-step>


                <mat-step *ngIf="!tempRoute.defaultRoute">
                    <ng-template matStepLabel>{{'SCHEDULE' |translate}}</ng-template>
                    <alarm-route-schedules [tempRoute]="tempRoute"></alarm-route-schedules>

                </mat-step>

                <mat-step *ngIf="tempRoute.fallBackType !== 'NONE' && !tempRoute.defaultRoute">
                    <ng-template matStepLabel>{{'FALLBACK_RECIPIENTS' |translate}}</ng-template>
                    <alarm-route-fallback-recipients [tempRoute]="tempRoute" [allStaff]="allStaff"
                        [allReceiverTeams]="allReceiverTeams" [customerInformation]="customerInformation">
                    </alarm-route-fallback-recipients>

                </mat-step>

                <mat-step *ngIf="tempRoute.alarmIndicationGroupEnabled">
                    <ng-template matStepLabel>{{'ALARM_INDICATION_GROUP' |translate}}</ng-template>
                    <app-alarm-route-alarm-indication-groups [tempRoute]="tempRoute" [deviceList]="rawDeviceList">
                    </app-alarm-route-alarm-indication-groups>

                </mat-step>


            </mat-horizontal-stepper>

        </div>

        <div class="d-none d-sm-block button-container">
            <button [disabled]="!tempRoute.name" class="btn btn-outline-primary gray-bg mr-2"
                (click)="validateRoute(false);"><span class="fa-light fa-floppy-disk"></span> {{'SAVE'
                |translate}}</button>
            <button class="btn btn-outline-danger" (click)="this.closeForm()"><span class="fal fa-times"></span>
                {{'CANCEL' |translate}}</button>
            <button [disabled]="!tempRoute.name || stepper?.selectedIndex + 1 === stepper?.steps?.length"
                class="btn btn-outline-primary gray-bg float-right" (click)="stepper.next()">{{'NEXT' |translate}}</button>
            <button [disabled]="stepper?.selectedIndex === 0" class="btn btn-outline-primary gray-bg float-right mr-2"
                (click)="stepper.previous()">{{'PREVIOUS' |translate}}</button>

        </div>

        <div class="d-block d-sm-none button-container">
            <button [disabled]="!tempRoute.name" class="btn btn-outline-primary gray-bg mr-2"
                (click)="validateRoute(false);"><span class="fa-light fa-floppy-disk"></span></button>
            <button class="btn btn-outline-danger" (click)="this.closeForm()"><span
                    class="fa-light fa-xmark"></span></button>
            <button [disabled]="!tempRoute.name || stepper?.selectedIndex + 1 === stepper?.steps?.length"
                class="btn btn-outline-primary gray-bg float-right" (click)="stepper.next()"><span
                    class="fa-light fa-arrow-right"></span></button>
            <button [disabled]="stepper?.selectedIndex === 0" class="btn btn-outline-primary gray-bg float-right mr-2"
                (click)="stepper.previous()"><span class="fa-light fa-arrow-left"></span></button>

        </div>
    </div>
</div>


<div class="row" *ngIf="!initialized">
    <div class="col-lg-12">
        <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg">
    </div>
</div>

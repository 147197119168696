<h2 mat-dialog-title>{{'VALIDATION' | translate}} {{'ERRORS' | translate}}</h2>
<mat-dialog-content class="mat-typography">

    <ng-container *ngIf="validationModel?.errors?.length !== 0">

        <mat-list dense="true" *ngFor="let group of validationErrorGroups | keyvalue">
            <div class="mb-2 mr-2 validation-error-title">
                <span class="fa-light fa-triangle-exclamation text-danger mr-1 pb-1"
                    style="vertical-align: middle"></span>
                <h3 class="text-truncate d-inline">{{validationErrorsTranslationMap.get(group.key)| translate}}</h3>
            </div>

            <div class="border-bottom mb-2" *ngIf="group.key === validationError.ActivationsForDeviceMissing">
                <h4 class="mb-2 mr-2 text-danger text-truncate">{{'DEVICES'| translate}}:</h4>
                <mat-list-item class="text-truncate" *ngFor="let error of group.value">
                    <span [ngClass]="getDeviceIconClass(error.deviceType)" class="mr-1"
                        style="vertical-align: middle"></span>

                    <span *ngIf="error.deviceName">{{error.deviceName}} </span>
                    <span *ngIf="!error.deviceName">{{error.deviceId}} </span>
                    <span>&nbsp;({{getDeviceType(error.deviceType)}})</span>
                </mat-list-item>
            </div>

            <div class="border-bottom mb-2" *ngIf="group.key === validationError.ConlictingRoutersFound">
                <h4 class="mb-2 mr-2 text-danger text-truncate">{{'CONFLICTS'| translate}}:</h4>
                <mat-list-item class="text-truncate" *ngFor="let conflict of group.value">
                    <span [ngClass]="getDeviceIconClass(conflict.deviceType)" class="mr-1"
                        style="vertical-align: middle"></span>
                    <span *ngIf="conflict.deviceName">{{conflict.deviceName}} </span>
                    <span *ngIf="!conflict.deviceName">{{conflict.deviceId}} </span>
                    <span>&nbsp;({{getDeviceType(conflict.deviceType)}})</span>
                    <span>&nbsp;{{'IN_ROUTING' | translate}}</span>
                    <span (click)="openRoutePage(conflict.routerId)"
                        class="router-name cursorPointer">&nbsp;{{conflict.routerName}}</span>
                    <span>&nbsp;({{daysMap.get(conflict.day) | translate}} {{trimTime(conflict.startTime.toString())}} -
                        {{trimTime(conflict.endTime.toString())}})</span>
                </mat-list-item>
            </div>
        </mat-list>
    </ng-container>


</mat-dialog-content>

<mat-dialog-actions class="mt-5">
    <!--    Send "true" as indicator that route needs to be saved as incomplete-->
    <span class="btn btn-outline-danger mr-2 cursorPointerHover" (click)="this.dialogRef.close(true)"><span
            class="fa-light fa-floppy-disk"></span> {{'SAVE_AS_INCOMPLETE' |translate}}</span>
    <span class="btn btn-outline-primary cursorPointerHover" (click)="this.dialogRef.close()"><span
            class="fa-light fa-xmark"></span> {{'CANCEL' |translate}}</span>


</mat-dialog-actions>
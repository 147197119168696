import { throttleTime } from 'rxjs/operators';
import { Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReceiverTeam } from 'app/models/receiver-team';
import { ApiService } from 'app/services/api.service';
import { Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'team-lock-access',
    templateUrl: './team-lock-access.component.html',
    styleUrls: ['./team-lock-access.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatListModule, NgFor, TranslateModule]
})
export class TeamLockAccessComponent implements OnChanges, OnInit, OnDestroy {

  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService
  ) { }

  @Input('team') team: ReceiverTeam;
  @Input('locations') allLocations: any;

  locationLockAccessForm: UntypedFormGroup;
  originalLocations: string[];
  originalLocationsLoaded: boolean;
  saveSuccess: boolean;

  formSubmitSubject = new Subject();

  createLocationLockAccessForm(): void {
    this.locationLockAccessForm = this.fb.group({
      selectedLocationAccess: new UntypedFormControl(this.originalLocations)
    })
  }

  submitLocationLockAccess(): void {
    this.saveSuccess = false;
    const data = this.locationLockAccessForm.value.selectedLocationAccess;
    this.api.updateLocationLockAccess(this.team.groupId, data).subscribe(res => {
      this.saveSuccess = true;
    })
  }

  ngOnInit() {
    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe(() => {
        this.submitLocationLockAccess();
      });
  }

  ngOnChanges(): void {
    this.api.getLocationLockAccess(this.team.groupId).subscribe(res => {
      this.originalLocationsLoaded = true;
      this.originalLocations = res;
      this.createLocationLockAccessForm();
    })
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

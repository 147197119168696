import { Component, OnInit } from "@angular/core";
import { ReceiverTeam, User } from "../../models/receiver-team";
import { ApiService } from "../../services/api.service";
import { LocationModel } from "../../models/location.model";
import { StaffModel } from "app/models/staff.model";
import { ActivatedRoute, Router } from "@angular/router";
import { Location, NgIf, NgClass, NgFor } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { LocationInfoComponent } from "../location/location-info/location-info.component";
import { TeamAddComponent } from "./team-add/team-add.component";
import { TeamEditComponent } from "./team-edit/team-edit.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { map } from "rxjs/operators";

@Component({
  selector: "app-receiver-teams",
  templateUrl: "./receiver-teams.component.html",
  styleUrls: ["./receiver-teams.component.css"],
  standalone: true,
  imports: [NgIf, NgClass, NgFor, MatTooltipModule, TeamEditComponent, TeamAddComponent, LocationInfoComponent, TranslateModule]
})
export class ReceiverTeamsComponent implements OnInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router
  ) { }

  user: any;
  receiverTeams: Team[];
  locationTeams: LocationTeam[];
  customerTeams: CustomerTeam[];
  showTeamAddForm: boolean = false;
  showTeamEditForm: boolean = false;
  showLocationEditForm: boolean = false;
  noTeamsAvailable: boolean;
  selectedTeam: ReceiverTeam;
  selectedLocation: LocationModel;
  allStaff: StaffModel[] = [];
  allLocations: any = [];

  getUsingLevelClass(usingLevel: number): string {
    switch (usingLevel) {
      case 101:
        return "staffSuperUser";
      case 100:
        return "staffMainUser";
      case 50:
        return "staffUser";
      case 40:
        return "staffAPMU";
      default:
        return "";
    }
  }

  getInactiveState(user: User): boolean {
    if (this.emaFeatureEnabled()) {
      if (user.emaOnline && !user.active) {
        return true;
      }
    } else {
      if (!user.active) {
        return true;
      }
    }

    return false;
  }

  teamsModified(): void {
    const teamsCollapsedStates = this.receiverTeams.map((team) => {
      return { groupId: team.groupId, collapsed: team.collapsed };
    });
    this.fetchInitialData().then((_) => {
      this.parseGroups();
      teamsCollapsedStates.forEach((teamState) => {
        this.receiverTeams.some((team) => {
          if (team.groupId === teamState.groupId) {
            team.collapsed = teamState.collapsed;
            return true;
          }
        });
      });
    });
  }

  locationEdited(): void {
    this.getReceiverTeams().then((_) => this.parseGroups());
  }

  teamDeleted(groupId: string): void {
    const team: Team = this.receiverTeams.find((team) => {
      return team.groupId === groupId;
    });

    const teamIndex: number = this.receiverTeams.indexOf(team);

    this.receiverTeams.splice(teamIndex, 1);
    this.parseGroups();
  }

  changeUserGroupActiveStatus(team: Team): void {
    let data = {
      groupId: team.groupId,
      activeStatus: !team.currentUserActiveInThisGroup,
    };
    this.api.changeUserActiveStatus(data).subscribe((res) => {
      team.currentUserActiveInThisGroup = data.activeStatus;
      let user: User = team.users.find((x) => {
        return x.id === this.user.userId;
      });
      user.active = data.activeStatus;
    });
  }

  addUserToGroup(groupId: string): void {
    this.api.addUserToReceiverTeam(groupId).subscribe(
      (res) => {
        const user: User = res;
        this.receiverTeams.some((team) => {
          if (team) {
            if (team.groupId === groupId) {
              team.currentUserLoggedToThisGroup = true;
              team.currentUserActiveInThisGroup = true;
              if (!team.users) {
                team.users = [];
              }
              team.users.push(user);
              return true;
            }
          }
          return false;
        });
      },
      (err) => { }
    );
  }

  removeUserFromGroup(groupId: string): void {
    this.api.deleteUserFromReceiverTeam(groupId).subscribe(
      (res) => {
        this.receiverTeams.some((team) => {
          if (team) {
            if (team.groupId === groupId) {
              team.currentUserLoggedToThisGroup = false;
              team.currentUserActiveInThisGroup = false;
              const userIndex: number = team.users.findIndex((user) => {
                return user.id === this.user.customerId;
              });
              if (team.users.length === 1) {
                team.users = [];
              } else {
                team.users.splice(userIndex, 1);
              }
              return true;
            }
          }
          return false;
        });
      },
      (err) => { }
    );
  }

  emaFeatureEnabled(): boolean {
    let features: [string] = JSON.parse(localStorage.getItem("features"));
    if (features) {
      return features.includes("EveronMobileApp");
    }
    return false;
  }

  // Parses receiverGroups into individual groups based on locationId or customerId
  parseGroups(): void {
    const groupsMap: Map<string, LocationTeam> = new Map();

    // Loop through all receiverGroup elements and create groupsMap with locationId as key and team as value
    if (this.receiverTeams) {
      for (const team of this.receiverTeams) {
        if (groupsMap.has(team.locationId)) {
          groupsMap.get(team.locationId).teams.push(team);
        } else {
          const location = new LocationTeam(
            false,
            team.locationId,
            team.locationName,
            [team]
          );
          groupsMap.set(team.locationId, location);
        }
      }
    }


    // Convert groupsMap to array and store it into locationGroups
    this.locationTeams = Array.from(groupsMap.values());

    // Check if locationGroups contains customer groups and put them into separate array.

    // Check if locationId is undefined, if it is then it's customer group
    const customerLocationIndex = this.locationTeams.findIndex(
      (location) => location.locationId === undefined
    );

    if (customerLocationIndex !== -1) {
      const customerGroupsMap: Map<string, CustomerTeam> = new Map();
      this.locationTeams[customerLocationIndex].teams.forEach((team) => {
        if (customerGroupsMap.has(team.customerId)) {
          customerGroupsMap.get(team.customerId).teams.push(team);
        } else {
          customerGroupsMap.set(
            team.customerId,
            new CustomerTeam(false, team.customerId, team.customerName, [team])
          );
        }
      });

      this.customerTeams = Array.from(customerGroupsMap.values());
      this.locationTeams.splice(customerLocationIndex, 1);

      this.locationTeams.sort((a, b) => {
        return a.locationName.localeCompare(b.locationName);
      });
    }
  }

  async getLocationInformation(id: string): Promise<void> {
    await new Promise<void>((resolve) =>
      this.api.getLocation(id).subscribe((res) => {
        this.selectedLocation = res;
        resolve(res);
      })
    );
  }

  isSideFormVisible(): boolean {
    return (
      this.showTeamEditForm || this.showTeamAddForm || this.showLocationEditForm
    );
  }

  featureEnabled(feature: string): boolean {
    let features = JSON.parse(localStorage.getItem("features"));
    if (features) {
      let index = features.indexOf(feature);
      if (index !== -1) {
        return true;
      }
    }
    return false;
  }

  showTeamInformation(team: Team): void {
    this.selectedTeam = team;
    this.showTeamEditForm = true;
    this.location.go("/teams/" + team.groupId);
  }

  closeTeamInformationForm(): void {
    this.showTeamEditForm = false;
    this.location.go("teams");
  }

  async getStaffList(): Promise<void> {
    await new Promise<void>((resolve) =>
      this.api.getStaffList().subscribe((res) => {
        this.allStaff = res.sort((a, b) =>
          a.fullName.localeCompare(b.fullName)
        );
        resolve();
      })
    );
  }

  async getReceiverTeams(): Promise<void> {
    await new Promise<void>((resolve) =>
      this.api.getReceiverTeams().subscribe((res) => {
        this.receiverTeams = res;
        if (this.receiverTeams) {
          this.receiverTeams.forEach((team) => (team.collapsed = false));
        } else {
          this.noTeamsAvailable = true;
        }
        resolve(res);
      })
    );
  }

  async getLocations(): Promise<void> {
    await new Promise<void>((resolve) =>
      this.api.getLocations().pipe(
        map((locations) => {
          // Clear the array before adding locations to prevent duplicates
          this.allLocations = [];

          locations?.forEach(location => {
            this.allLocations.push({
              name: location.name,
              id: location.id
            })
          });

          return locations;
        })
      ).subscribe((res) => resolve(res))
    );
  }

  async fetchInitialData(): Promise<void> {
    await Promise.allSettled([this.getStaffList(), this.getReceiverTeams(), this.getLocations()]);
  }

  openPersonnelPage(staffId: string): void {
    this.router.navigate(["staff/" + staffId]);
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));

    this.fetchInitialData().then((_) => {
      this.parseGroups();

      // Check if user comes with URL containing the team ID
      let teamId;
      // Get ID from the URL
      this.route.params.subscribe((params) => {
        teamId = params["id"];
      });

      // If ID was found from the URL, check if route actually exists, retrieve route information and show information form.
      if (teamId) {
        const team = this.receiverTeams.find((team) => team.groupId === teamId);

        if (team) {
          this.showTeamInformation(team);
        }
      }
    });
  }
}

class Team implements ReceiverTeam {
  collapsed?: boolean;
  currentUserLoggedToThisGroup: boolean;
  currentUserActiveInThisGroup: boolean;
  groupId: string;
  groupName: string;
  locationId: string;
  locationName: string;
  users: User[];
  customerId: string;
  customerName: string;
  locked: boolean;
  hidden: boolean;
}

class LocationTeam {
  collapsed?: boolean;
  locationId: string;
  locationName: string;
  teams: Team[];

  constructor(
    collapsed: boolean,
    locationId: string,
    locationName: string,
    teams: Team[]
  ) {
    this.collapsed = collapsed;
    this.locationId = locationId;
    this.locationName = locationName;
    this.teams = teams;
  }
}

class CustomerTeam {
  collapsed?: boolean;
  customerId: string;
  customerName: string;
  teams: Team[];

  constructor(
    collapsed: boolean,
    customerId: string,
    customerName: string,
    teams: Team[]
  ) {
    this.collapsed = collapsed;
    this.customerId = customerId;
    this.customerName = customerName;
    this.teams = teams;
  }
}

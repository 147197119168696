import { Component, OnInit, OnDestroy, ViewChild, Inject } from "@angular/core";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { TextFormatComponent } from "../text-format/text-format.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatAccordion, MatExpansionModule } from "@angular/material/expansion";
import { takeUntil } from "rxjs/operators";
import { AlarmService } from "app/services/alarm.service";
import { ApiService } from "../../services/api.service";
import { Observable, Subject } from "rxjs";
import { IAlarmReasonTranslations } from "app/models/alarm-reasons.model";
import { ReportPipesModule } from "./report-pipes";
import * as moment from "moment-timezone";
import { IGetReportFilterOptionsResponse, ReportDataType, ReportType } from "app/models/report.model";
import { ReportAlertTableComponent } from "./report-tables/report-alert-table/report-alert-table.component";
import { ReportCameraEventTableComponent } from "./report-tables/report-camera-event-table/report-camera-event-table.component";
import { ReportLockEventTableComponent } from "./report-tables/report-lock-event-table/report-lock-event-table.component";
import { NgxBootstrapMultiselectModule, IMultiSelectSettings, IMultiSelectTexts, IMultiSelectOption } from "ngx-bootstrap-multiselect";
import { MomentPipe, TransformToLangCodePipe, TranslateAlarmCategoryPipe, TranslateAlarmReasonPipe } from "../../pipes/pipes";
import { GlobalVariables, ReportFilter, FilterOptions, HelperService, UiLang } from "../../services/helper.service";
import { CategoryMultiselectButtonComponent } from '../category-multiselect-button/category-multiselect-button.component';
import { DownloadPanelComponent } from './download-panel/download-panel.component';
import { ReportService } from 'app/services/report.service';

type DeviceTypeSelectOption = { id: string; name: string };

@Component({
  selector: "report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    NgxBootstrapMultiselectModule,
    InfiniteScrollModule,
    TextFormatComponent,
    TranslateModule,
    MomentPipe,
    TranslateAlarmReasonPipe,
    TranslateAlarmCategoryPipe,
    TransformToLangCodePipe,
    ReportPipesModule,
    MatNativeDateModule,
    ReportAlertTableComponent,
    ReportCameraEventTableComponent,
    ReportLockEventTableComponent,
    CategoryMultiselectButtonComponent,
    DownloadPanelComponent
  ]
})
export class ReportComponent implements OnInit, OnDestroy {
  // Declare dependency incjections
  constructor(
    private translate: TranslateService,
    private api: ApiService,
    private globals: GlobalVariables,
    private helper: HelperService,
    private dateAdapter: DateAdapter<any>,
    private alarmService: AlarmService,
    private reportService: ReportService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
  ) { }

  private destroy$ = new Subject<void>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  // Declare variables
  customDeviceSearch: boolean = false;
  customDeviceQuery: string = null;
  customBasestationSearch: boolean = false;
  customBasestationQuery: string = null;
  customDeviceOption: any = {
    id: "CUSTOM_DEVICE_ID",
    name: "Custom Device ID",
  };
  customBasestationOption: any = { id: "CUSTOM_BASESTATION_ID", name: "BASE" };
  activationArray: any;
  devicesJson: any;
  filterState: boolean;
  alarmReasonTranslations: IAlarmReasonTranslations;
  cameraVisitsEnabled: boolean = false;
  lockEventsEnabled: boolean = false;

  user: any;
  events: ReportDataType = [];

  selectedTab: ReportType = "Alert";

  currentDate: Date = new Date();
  filterStartTime: string = "00:00";
  filterStartDay: Date = moment().subtract(1, "d").toDate();
  filterEndTime: string = "23:59";
  filterEndDay: Date = new Date();

  infiniteScrollDisabled: boolean = false;
  infiniteScrollPage = 1;

  eventsLoaded: boolean = false;

  PDFReportRequestSuccess: boolean;
  PDFReportRequestFailed: boolean;

  reportFile = "";

  _showRaportInputBox = false;
  replacedCTL: string;

  advancedFilter: ReportFilter = {
    SumAvg: "sum",
    settings: {
      enableSearch: true,
      showUncheckAll: true,
      dynamicTitleMaxItems: 0,
      checkedStyle: "fontawesome",
      containerClasses: "fullWidth",
      buttonClasses: "btn btn-dropdown-sm", //'btn btn-outline-dark fullWidth'
    },
    singleSelectSettings: {
      enableSearch: true,
      showUncheckAll: true,
      dynamicTitleMaxItems: 1,
      checkedStyle: "fontawesome",
      containerClasses: "fullWidth",
      buttonClasses: "btn btn-dropdown-sm", //'btn btn-outline-dark fullWidth',
      autoUnselect: true,
      selectionLimit: 1,
    },
    device: new FilterOptions(),
    location: new FilterOptions(),
    alertRoute: new FilterOptions(),
    basestation: new FilterOptions(),
    personnel: new FilterOptions(),
    deviceTypes: new FilterOptions(),
    activations: new FilterOptions(),
    eventTypes: new FilterOptions(),
    alarmReasons: new FilterOptions(),
  };
  selectedDeviceTypes: DeviceTypeSelectOption[] = [];
  weekDayFilter: number[] = [];

  // Settings used in device select list
  deviceSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: "fontawesome",
    showUncheckAll: true,
  };
  deviceSelectTexts: IMultiSelectTexts = {};

  weekDayFilterList: any = [
    { title: "MONDAY_SHORT", isChecked: false, value: 1 },
    { title: "TUESDAY_SHORT", isChecked: false, value: 2 },
    { title: "WEDNESDAY_SHORT", isChecked: false, value: 3 },
    { title: "THURSDAY_SHORT", isChecked: false, value: 4 },
    { title: "FRIDAY_SHORT", isChecked: false, value: 5 },
    { title: "SATURDAY_SHORT", isChecked: false, value: 6 },
    { title: "SUNDAY_SHORT", isChecked: false, value: 0 },
  ];

  get showRaportInputBox() {
    return this._showRaportInputBox;
  }

  set showRaportInputBox(value: boolean) {
    this._showRaportInputBox = value;
  }

  addEventTimestamps(
    row,
    csvRow,
    eventTypeId,
    spaces_old,
    spaceCount,
    options,
    csvData
  ) {
    // Create empty column

    csvRow[row[eventTypeId + "Events"][0].eventType] = "";
    var spaces = 6;

    csvData.push(["", "", "", "", "", eventTypeId + " events"]);
    for (var i = 0; i < row[eventTypeId + "Events"].length; i++) {
      var addedSpaces = [];
      if (
        typeof row[eventTypeId + "Events"][i].eventType !== "undefined"
      ) {
        if (
          typeof spaceCount[row[eventTypeId + "Events"][i].eventType] ===
          "undefined"
        ) {
          spaceCount[row[eventTypeId + "Events"][i].eventType] = spaces++;
        }
      } else if (eventTypeId == "comment") {
        if (
          typeof spaceCount[row[eventTypeId + "Events"][i].eventType] ===
          "undefined"
        ) {
          spaceCount[row[eventTypeId + "Events"][i].eventType] = spaces++;
        }
        //        row[eventTypeId + "Events"][i].CommentTime;
      }

      for (
        var a = 0;
        a < spaceCount[row[eventTypeId + "Events"][i].eventType];
        a++
      ) {
        addedSpaces.push("");
      }

      if (eventTypeId == "comment") {
        addedSpaces.push(row[eventTypeId + "Events"][i].CommentTime);
        addedSpaces.push("");
      } else {
        addedSpaces.push(row[eventTypeId + "Events"][i].time);
      }

      // add header
      if (spaces > spaces_old) {
        if (
          typeof row[eventTypeId + "Events"][i].eventType !== "undefined"
        ) {
          options.headers.push(row[eventTypeId + "Events"][i].eventType);
        } else {
          options.headers.push("Comment time");
        }
        spaces_old++;
      }

      csvData.push(addedSpaces);
    }

    return {
      options: options,
      csvData: csvData,
      spaces_old: spaces_old,
    };
  }

  updateWeekDayFilter(event: any): void {
    // Add value into weekDaysFilter array if checkbox was checked. Otherwise remove it from the array
    if (event.checked) {
      this.weekDayFilter.push(parseInt(event.source.value.value));
    } else {
      this.weekDayFilter.splice(
        this.weekDayFilter.indexOf(parseInt(event.source.value.value)),
        1
      );
    }
    event.source.value.isChecked = event.checked;
  }

  limitExceed = false;
  alertLimitValue = 0;

  resetPDFLoadingError() {
    this.limitExceed = false;
    this.alertLimitValue = 0;
    this.PDFReportRequestFailed = false;
  }

  onShowRaport(event: any) {
    this._showRaportInputBox = !this._showRaportInputBox;
    event.stopPropagation();
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  // Fired when show filter link is pressed.
  showFilterMenu(): void {
    // Switch the state of the filter menu (displayed or hidden)
    this.filterState = !this.filterState;
    // Translate some texts in device selection list
    this.translate
      .get(["FILTER_UNCHECK", "ALERT_FILTER_DEVICES", "SEARCH"])
      .pipe(takeUntil(this.destroy$))
      .subscribe((t) => {
        this.deviceSelectTexts.uncheckAll = t.FILTER_UNCHECK;
        this.deviceSelectTexts.defaultTitle = t.ALERT_FILTER_DEVICES;
        this.deviceSelectTexts.searchPlaceholder = t.SEARCH;
      });
    // If filter menu is opened download devices for the list
    if (this.filterState) {
      // If device list is already loaded during this session use it from the session storage
      this.devicesJson = JSON.parse(sessionStorage.getItem("devices"));
    }
  }

  search(): void {
    if (this.eventsLoaded) {
      this.events = [];
      this.infiniteScrollPage = 1;
      this.infiniteScrollDisabled = false;

      this.loadEvents(this.selectedTab);
    }
  }

  changeTab(tab: ReportType): void {
    // Don't reload the data if the same tab is selected
    if (tab === this.selectedTab) {
      return;
    }

    this.events = [];
    this.infiniteScrollPage = 1;
    this.infiniteScrollDisabled = false;

    this.selectedTab = tab;

    this.loadEvents(this.selectedTab);
  }

  // Parse device request response received from the server
  parseDeviceResponse(response: any): void {
    let allDevices = [];
    response.devices.forEach((dev) => {
      //Filtering out devices with prefix 18
      if (!dev.DevID.startsWith("18") && !dev.DevID.startsWith("08")) {
        //If device has name combine name and ID. Otherwise use only ID
        let devName = dev.DevName ? dev.DevName + " - " + dev.DevID : dev.DevID;
        if (dev.EnvID === "00000000") {
          dev.EnvID = dev.DevID;
        }
        let tmpDev = {
          name: devName,
          id: dev.DevID,
          custId: dev.CustID,
          envId: dev.EnvID,
        };
        //Push edited device into array which is used in view
        allDevices.push(tmpDev);
      }
      //Set device list to local variable which is used to generate the table
      this.devicesJson = allDevices;
    });
  }

  sortEventsByStartTimeDesc(a: any, b: any): number {
    // Check whether timestamp is stored to visitStarted, createdTime or receiveTime property key and get the value as string
    const stampA: string = a.visitStarted || a.createdTime || a.receiveTime;
    const stampB: string = b.visitStarted || b.createdTime || b.receiveTime;

    if (new Date(stampA) > new Date(stampB)) return -1;
    if (new Date(stampA) < new Date(stampB)) return 1;
    return 0;
  }

  initAlarmReasons(lang: any): void {
    this.alarmService
      .getAlarmReasonTranslations(lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (translations) => {
          this.alarmReasonTranslations = translations;

          this.advancedFilter.alarmReasons.selectedList = [];
          this.advancedFilter.alarmReasons.filterList = this.alarmService.parseAlarmReasonFilterOptions(translations);
        }
      );
  }

  initReportFilters() {
    // if report filters are saved at statistics view in globals.reportFilters object, there are missing property "deviceTypes" which is causing an error
    if (!this.globals.reportFilters || !this.globals.reportFilters.deviceTypes) {
      this.getAdvancedFilters();
    } else {
      this.advancedFilter = this.globals.reportFilters;
    }
    this.setAdvancedFilterTranslations();
  }

  getAdvancedFilters(): void {
    this.api.getReportFilteringOptions()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: IGetReportFilterOptionsResponse) => {
        this.advancedFilter.device.setFilterListByDescOrder(res.devices);
        this.advancedFilter.alertRoute.setFilterListByDescOrder(res.alertRoutes);
        this.advancedFilter.location.setFilterListByDescOrder(res.locations);
        this.advancedFilter.basestation.setFilterListByDescOrder(res.basestations);
        this.advancedFilter.personnel.setFilterListByDescOrder(res.personnel);

        // Add custom device and basestation options to the beginning of the list
        this.advancedFilter.device.filterList.unshift(this.customDeviceOption);
        this.advancedFilter.basestation.filterList.unshift(this.customBasestationOption);

        this.advancedFilter.deviceTypes.filterList = this.parseDeviceTypeFilters(res.deviceTypes);
        this.advancedFilter.activations.filterList = [];

        // Save the filter options to global variable for reinitializating the filters faster when the user returns to the report view
        this.globals.reportFilters = this.advancedFilter;
      });
  }

  setAdvancedFilterTranslations(): void {
    this.translate
      .get([
        "DATE_FROM",
        "DATE_TO",
        "FILTER_CHECK_ALL",
        "FILTER_UNCHECK_ALL",
        "FILTER_CHECKED",
        "FILTER_ALL_SELECTED",
        "SEARCH",
        "DEVICE",
        "ALARM_ROUTING",
        "LOCATION",
        "BASESTATION",
        "STAFF",
        "CUSTOM_DEVICE_ID",
        "CUSTOM_BASESTATION_ID",
        "DEVICE_TYPE",
        "ACTIVATION",
        "EVENT_TYPE",
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((t) => {
        const sharedTexts = {
          checkAll: t.FILTER_CHECK_ALL,
          uncheckAll: t.FILTER_UNCHECK_ALL,
          checked: t.FILTER_CHECKED,
          checkedPlural: t.FILTER_CHECKED,
          searchPlaceholder: t.SEARCH,
          allSelected: t.FILTER_ALL_SELECTED,
        };

        this.advancedFilter.device.texts = Object.assign({}, sharedTexts, {
          defaultTitle: t.DEVICE,
        });
        this.advancedFilter.alertRoute.texts = Object.assign({}, sharedTexts, {
          defaultTitle: t.ALARM_ROUTING,
        });
        this.advancedFilter.location.texts = Object.assign({}, sharedTexts, {
          defaultTitle: t.LOCATION,
        });
        this.advancedFilter.basestation.texts = Object.assign({}, sharedTexts, {
          defaultTitle: t.BASESTATION,
        });
        this.advancedFilter.personnel.texts = Object.assign({}, sharedTexts, {
          defaultTitle: t.STAFF,
        });
        this.advancedFilter.deviceTypes.texts = Object.assign({}, sharedTexts, {
          defaultTitle: t.DEVICE_TYPE,
        });
        this.advancedFilter.activations.texts = Object.assign({}, sharedTexts, {
          defaultTitle: t.ACTIVATION,
        });
        this.advancedFilter.eventTypes.texts = Object.assign({}, sharedTexts, {
          defaultTitle: t.EVENT_TYPE,
        });
        this.customDeviceOption.name = t.CUSTOM_DEVICE_ID;
        this.customBasestationOption.name = t.CUSTOM_BASESTATION_ID;
      });
  }

  parseDeviceTypeFilters(data: string[]): any[] {
    let parsedList: any[] = [];
    data.forEach((e) => {
      const typeName = this.api.getDeviceType(e, true);
      const option: DeviceTypeSelectOption = { id: e, name: typeName };
      parsedList.push({ id: option, name: typeName });
    });
    return parsedList;
  }

  onDeviceTypeFilterChange(deviceTypes: { id: string; name: string }[]): void {
    const selectedDeviceTypes = deviceTypes.map((t) => t.id);
    const filterList: IMultiSelectOption[] = [];
    const activations = this.helper.getActivationsForDeviceTypes(selectedDeviceTypes);

    this.advancedFilter.activations.selectedList = [];
    this.advancedFilter.activations.filterList = [];
    this.advancedFilter.deviceTypes.selectedList = selectedDeviceTypes;

    for (const type of deviceTypes) {
      const alertTypeCategory: IMultiSelectOption = {
        id: type.id,
        name: type.name || "",
        isLabel: true,
      };

      const alertTypes: IMultiSelectOption[] = activations
        .filter((a) => a.deviceType === type.id)
        .map((a) => {
          return {
            // Replace "No contact in %CTL%" which comes from the database in the UI language
            // with "NO_CONNECTION_WITHIN_TIME" translated in portal according the UI language
            name: a.name.includes("%CTL%") ? this.replacedCTL : a.name,
            id: `${a.group};${a.node};${a.deviceType}`,
            parentId: a.deviceType,
          };
        });

      filterList.push(alertTypeCategory, ...alertTypes);
    }

    this.advancedFilter.activations.filterList = filterList;
  }

  getAlertsDefSettings(page: number, pageSize: number): Observable<any> {
    let filters: any = {
      paging: {
        alertsPerPage: pageSize,
        pageNumber: page,
      },
      fieldFilter: [
        "taskId",
        "createTime",
        "receiveTime",
        "deviceName",
        "deviceId",
        "activationGroup",
        "activationNode",
        "deviceType",
        "resolverName",
        "deliveryType",
        "customActivationName",
        "completeTime",
        "environmentName",
        "environmentId",
        "emaEvents",
        "callEvents",
        "commentEvents",
        "cancelled",
        "smsEvents",
        "emailEvents",
        "connTimeLimit",
        "alertRouterId",
        "alertRouterName",
        "confirmed",
        "alarmReasons",
      ],
    };
    filters = this.reportService.setReportFilters(
      filters,
      this.advancedFilter,
      this.customDeviceOption,
      this.customDeviceQuery,
      this.customBasestationOption,
      this.customBasestationQuery,
      this.weekDayFilter,
      this.filterStartDay,
      this.filterEndDay,
      this.filterStartTime,
      this.filterEndTime)// this.setReportFilters(filters);
    return this.api.getAlertHistory(filters);
  }

  isLockingEventFiltersValid(): boolean {
    const invalidFilterSelectCount: number = [
      (this.advancedFilter.alertRoute.selectedList ?? []).length,
      (this.advancedFilter.basestation.selectedList ?? []).length,
      (this.advancedFilter.personnel.selectedList ?? []).length,
      (this.advancedFilter.activations.selectedList ?? []).length,
    ].reduce((a, b) => a + b, 0)

    const validDevTypeFilters
      = this.isLockingEventDeviceTypeFiltersValid(this.advancedFilter.deviceTypes.selectedList);

    if (invalidFilterSelectCount > 0 || !validDevTypeFilters) {
      return false;
    }
    return true;
  }

  isLockingEventDeviceTypeFiltersValid(selectedDevTypes: string[] | null): boolean {
    const validDevTypes = ["32-13"];
    let valid = true;
    if (selectedDevTypes?.length > 0) {
      valid = selectedDevTypes.some((type) => validDevTypes.includes(type));
    }
    return valid
  }

  submitAlertNote(row: any): void {
    let data = {
      alertId: row.taskId,
      commentText: row.noteText,
    };
    this.api.addNote(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          row.noteText = "";
          if (!row.commentEvents) row.commentEvents = [];
          let note = {
            UserName: this.user.displayName,
            UserId: this.user.userId,
            CommentTime: moment(),
            Comment: data.commentText,
          };
          row.commentEvents.push(note);
        },
        (err) => { }
      );
  }

  async loadNextEvents(): Promise<void> {
    // Don't load new events via infinite scrolling if previous load is unfinished
    if (this.eventsLoaded) {
      this.infiniteScrollPage++;
      const events = await this.loadEvents(this.selectedTab);

      if (!events || events.length === 0) {
        this.infiniteScrollDisabled = true;
      }
    }
  }

  async loadEvents(tab: ReportType): Promise<ReportDataType> {
    let data: ReportDataType;
    const pageSize = 50;

    this.eventsLoaded = false;

    switch (tab) {
      case "Alert":
        data = await this.getAlertsDefSettings(this.infiniteScrollPage, pageSize).toPromise();
        break;
      case "CameraVisit":
        data = await this.reportService.getCameraVisit(
          this.infiniteScrollPage,
          pageSize,
          this.advancedFilter,
          this.customDeviceOption,
          this.customDeviceQuery,
          this.customBasestationOption,
          this.customBasestationQuery,
          this.weekDayFilter,
          this.filterStartDay,
          this.filterEndDay,
          this.filterStartTime,
          this.filterEndTime,
          this.cameraVisitsEnabled).toPromise();
        break;
      case "LockEvent":
        data = await this.reportService.getLockingEvents(
          this.infiniteScrollPage,
          pageSize,
          this.advancedFilter,
          this.customDeviceOption,
          this.customDeviceQuery,
          this.customBasestationOption,
          this.customBasestationQuery,
          this.weekDayFilter,
          this.filterStartDay,
          this.filterEndDay,
          this.filterStartTime,
          this.filterEndTime).toPromise();
        break;
    };

    // To prevent mixing data from different event types, check whether the function's local tab corresponds to the selected tab
    if (data && tab === this.selectedTab) {
      this.events = [...this.events, ...data];
      this.events.sort(this.sortEventsByStartTimeDesc);
    }

    this.eventsLoaded = true;

    return data;
  }

  onDeviceListChange($event: any) {
    if (this.advancedFilter.device.selectedList) {
      this.customDeviceSearch = this.advancedFilter.device.selectedList.some(
        (deviceId) => deviceId === this.customDeviceOption.id
      );
    }
  }

  onBasestationListChange($event: any) {
    if (this.advancedFilter.basestation.selectedList) {
      this.customBasestationSearch =
        this.advancedFilter.basestation.selectedList.some(
          (baseStationId) => baseStationId === this.customBasestationOption.id
        );
    }
  }

  clearAlarmReasonFilter(): void {
    this.advancedFilter.alarmReasons.selectedList = [];

    if (!this.advancedFilter.alarmReasons.filterList) {
      return
    }

    for (const filter of this.advancedFilter.alarmReasons.filterList) {
      filter.allChecked = false;
      filter.collapseState = "collapsed";
      if (filter.options) {
        filter.options.forEach((option: any) => option.checked = false);
      }

    }
  }

  clearWeekdayFilter(): void {
    this.weekDayFilter = [];
    // Reset checked value in weekDayFilterList
    for (let i = 0; i < this.weekDayFilterList.length; i++) {
      this.weekDayFilterList[i].isChecked = false;
    }
  }

  clearFilters(): void {
    this.filterStartTime = "00:00";
    this.filterEndTime = "23:59";
    this.customDeviceQuery = null;
    this.customDeviceSearch = false;
    this.customBasestationQuery = null;
    this.customBasestationSearch = false;
    this.filterStartDay = moment().subtract(1, "d").toDate();
    this.filterEndDay = new Date();
    this.advancedFilter.device.selectedList = [];
    this.advancedFilter.location.selectedList = null;
    this.advancedFilter.alertRoute.selectedList = null;
    this.advancedFilter.basestation.selectedList = [];
    this.advancedFilter.personnel.selectedList = null;
    this.advancedFilter.deviceTypes.selectedList = null;
    this.advancedFilter.activations.selectedList = null;
    this.advancedFilter.alarmReasons.selectedList = [];
    this.clearAlarmReasonFilter();
    this.clearWeekdayFilter();
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.user.language = localStorage.getItem("language");
    this.helper.setDateAdapterLocale(this.user.language as UiLang, this.dateAdapter);
    this.cameraVisitsEnabled = this.helper.isFeatureEnabled("CameraSupervision");
    this.lockEventsEnabled = this.helper.isFeatureEnabled("DigitalKey");

    this.translate
      .get("NO_CONNECTION_WITHIN_TIME")
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => (this.replacedCTL = data));


    this.initAlarmReasons(this.user.language);
    this.initReportFilters();
    this.loadEvents(this.selectedTab);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.clearFilters();
  }
}

<strong>{{'TWO_FACTOR_AUTHENTICATION' |translate}}</strong>
<div class="mt-3">
    <!--Container if 2FA is not yet enabled for customer-->
    <div *ngIf="customer && !customer.twoFactorAuthEnabled">
        <p>
            {{'TWO_FACTOR_DISABLED_INFORMATION' |translate}}
        </p>
        <p>
            <button class="btn btn-primary" (click)="enableTwoFactorAuthentication()">{{'ENABLE_TWO_FACTOR_AUTHENTICATION' |translate}}</button>
        </p>
    </div>
    <!--Container if 2FA is enabled for customer-->
    <div *ngIf="customer && customer.twoFactorAuthEnabled">
        <div>
            {{'TWO_FACTOR_ENABLED_INFORMATION' |translate}}
        </div>
        <div class="mt-2">
            <button class="btn btn-primary" (click)="showTwoFactorAuthDetails()">{{!twoFactorAuthDetails ? ('SHOW_TWO_FACTOR_AUTHENTICATION_DETAILS' |translate) : ('HIDE_TWO_FACTOR_AUTHENTICATION_DETAILS' |translate)}}</button>
            <div *ngIf="twoFactorAuthDetails" class="mt-4">
                <p>
                    1. {{'TWO_FACTOR_INFO_STEP1' |translate}}
                </p>
                <p>
                    2. {{'TWO_FACTOR_INFO_STEP2' |translate}}
                </p>
                <p>
                    <img [src]="twoFactorAuthDetails.qrCode" /><br>{{'MANUAL_CODE' |translate}}: {{twoFactorAuthDetails.manualCode}}
                </p>
                <hr>
                <div *ngIf="user.roleLevel === 200">
                    <div *ngIf="!twoFactorDisableConfirm">
                        <button class="btn btn-danger" (click)="twoFactorDisableConfirm = true">{{'DISABLE_TWO_FACTOR_AUTHENTICATION' |translate}}</button>
                    </div>
                    <div *ngIf="twoFactorDisableConfirm">
                        <p>
                            <strong>{{'DISABLE_TWO_FACTOR_AUTHENTICATION_CONFIRM_INFO' |translate}}</strong>
                        </p>
                        <p>
                            {{'DISABLE_TWO_FACTOR_AUTHENTICATION_CONFIRM' |translate}}
                        </p>
                        <button class="btn btn-danger" (click)="disableTwoFactorAuthentication()">{{'DELETE' |translate}}</button>
                        <button class="btn btn-outline-primary ml-3" (click)="twoFactorDisableConfirm = false">{{'CANCEL' |translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
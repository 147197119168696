import { Chart, ChartDataset } from "chart.js";
import { IAverageAlertDurations } from "app/models/statistics.model";
import { AVERAGE_ALERT_DURATIONS_CHART_CONFIGS } from "app/shared/chart-configs.shared";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { SecondsToReadableFormatPipe } from "app/pipes/pipes";
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from "@angular/core";

@Component({
  selector: "average-alert-durations",
  templateUrl: "./average-alert-durations.component.html",
  styleUrls: ["./average-alert-durations.component.css"],
  imports: [TranslateModule, SecondsToReadableFormatPipe],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AverageAlertDurationsComponent implements OnInit {
  constructor(private translate: TranslateService) {}

  @Input() durations: IAverageAlertDurations;

  chart: Chart;
  avgTotalDuration: number = 0;

  labels: {
    technicalDurationLabel: string;
    queueTimeLabel: string;
    reservedTimeLabel: string;
    careTimeLabel: string;
  };

  drawAverageAlertDurationChart(): void {
    // Parse chart data
    const datasets: ChartDataset<"bar">[] = [
      {
        label: this.labels.technicalDurationLabel,
        data: [this.durations.avgTechDurationSeconds],
        backgroundColor: Colors.technicalDurationColor,
        barThickness: 35,
      },
      {
        label: this.labels.queueTimeLabel,
        data: [this.durations.avgQueueTimeSeconds],
        backgroundColor: Colors.queueTimeColor,
        barThickness: 35,
      },
      {
        label: this.labels.reservedTimeLabel,
        data: [this.durations.avgReservedTimeSeconds],
        backgroundColor: Colors.reservedTimeColor,
        barThickness: 35,
      },
      {
        label: this.labels.careTimeLabel,
        data: [this.durations.avgCareTimeSeconds],
        backgroundColor: Colors.careTimeColor,
        barThickness: 35,
      },
    ];
    // Set chart data and init chart labels
    AVERAGE_ALERT_DURATIONS_CHART_CONFIGS.data.datasets = datasets;
    AVERAGE_ALERT_DURATIONS_CHART_CONFIGS.data.labels = [""];

    // Set maximum value for the x-axis
    AVERAGE_ALERT_DURATIONS_CHART_CONFIGS.options.scales.x.max =
      this.avgTotalDuration;

    // Draw chart
    this.chart = new Chart(
      "averageAlertDurationChart",
      AVERAGE_ALERT_DURATIONS_CHART_CONFIGS
    );
  }

  calculateAverageTotalDuration(durations: IAverageAlertDurations): number {
    return Object.values(durations).reduce(
      (previous, current) => previous + current,
      0
    );
  }

  ngOnInit(): void {
    this.avgTotalDuration = this.calculateAverageTotalDuration(this.durations);
    // Get translations and draw the chart
    this.translate
      .get([
        "SECONDS",
        "TECHNICAL_DURATION",
        "QUEUE_TIME",
        "RESERVED_TIME",
        "CARE_TIME",
      ])
      .subscribe((t) => {
        this.labels = {
          technicalDurationLabel: t.TECHNICAL_DURATION,
          queueTimeLabel: t.QUEUE_TIME,
          reservedTimeLabel: t.RESERVED_TIME,
          careTimeLabel: t.CARE_TIME,
        };
        this.drawAverageAlertDurationChart();
      });
  }
}

const Colors = {
  technicalDurationColor: "rgb(255, 118, 74)",
  queueTimeColor: "rgb(255, 219, 160)",
  reservedTimeColor: "rgb(112, 212, 252)",
  careTimeColor: "rgb(75, 194, 186)",
};

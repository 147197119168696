<div id="container">
    <div id="content">
        <div id="headerContainer">
            <h4 class="mainDarkBlueText normalFontWeight">
                {{ "ALERTS_PER_DAY" | translate }}
            </h4>
        </div>

        <div id="chartContainer">
            <canvas id="alertsPerDayChart" height="290px"></canvas>
        </div>
    </div>
</div>

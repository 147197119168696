import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceCompanyGroup } from 'app/models/device.model';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { ApiService } from 'app/services/api.service';

export interface IUpdateDeviceBindTimeForm {
  deviceId: string;
  bindingTimeStart: Date;
  bindingTimeEnd: Date;
}

const ERROR_MSG = "ERROR";
const SUCCESS_MSG = "DEVICE_BIND_TIME_UPDATE_SUCCESS";

@Component({
  selector: 'app-device-bind-time-manager',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './device-bind-time-manager.component.html',
  styleUrls: ['./device-bind-time-manager.component.css']
})
export class DeviceBindTimeManagerComponent implements OnInit {

  constructor(private helper: HelperService, private api: ApiService) { }

  @Output() closed = new EventEmitter<string>();
  @Input() device: DeviceCompanyGroup;

  bindingTimeControl: FormControl<number>;
  remainingDays: number;

  updating: boolean = false;
  errorMsg: string = "";

  submit(): void {
    const form = this.createUpdateForm()
    this.startUpdating();

    this.api.updateDeviceBindTime(form)
      .subscribe(
        () => this.handleUpdateSuccess(form),
        (error) => this.handleUpdateError(error)
      );
  }

  cancel(): void {
    this.closed.emit("");
  }

  createUpdateForm(): IUpdateDeviceBindTimeForm {
    const currentStartDate = new Date(this.device.bindingTimeStart);
    const newStartTime = this.getNewStartDate(currentStartDate);

    return {
      deviceId: this.device.devId,
      bindingTimeStart: newStartTime,
      bindingTimeEnd: this.getNewEndDate(new Date())
    }
  }

  getNewStartDate(startDate: Date): Date {
    const validStartDate = this.helper.isValidDate(startDate);

    return validStartDate && this.remainingDays > 0
      ? startDate
      : new Date();
  }

  getNewEndDate(date: Date): Date {
    return this.helper.addDays(date, this.bindingTimeControl.value);
  }

  createBindingTimeControl(days: number): FormControl<number> {
    return new FormControl<number>(days, [
      Validators.required,
      Validators.min(0),
      Validators.max(9999)
    ]);
  }

  startUpdating(): void {
    this.updating = true;
  }

  stopUpdating(): void {
    this.updating = false;
  }

  handleUpdateSuccess(form: IUpdateDeviceBindTimeForm): void {
    this.stopUpdating();
    this.setUpdatedBindTimesToDevice(form);
    this.closed.emit(SUCCESS_MSG);
  }

  handleUpdateError(error: any): void {
    this.stopUpdating();
    this.errorMsg = ERROR_MSG;
    console.error(error);
  }

  setUpdatedBindTimesToDevice(form: IUpdateDeviceBindTimeForm): void {
    this.device.bindingTimeStart = form.bindingTimeStart;
    this.device.bindingTimeEnd = form.bindingTimeEnd;
  }

  ngOnInit(): void {
    this.remainingDays = this.helper.getDaysBetweenDates(new Date(), new Date(this.device.bindingTimeEnd));
    this.bindingTimeControl = this.createBindingTimeControl(this.remainingDays);
  }
}

import { Component, EventEmitter, Input, OnInit, Output, OnChanges, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReceiverTeam } from '../../../models/receiver-team';
import { LocationModel } from '../../../models/location.model';
import { StaffModel } from '../../../models/staff.model';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatOption, MatOptionModule } from '@angular/material/core';
import { ThrottleButtonClickDirective } from '../../../directives/throttle-button-click.directive';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'team-add',
    templateUrl: './team-add.component.html',
    styleUrls: ['./team-add.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatSlideToggleModule, MatTooltipModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor, MatCheckboxModule, MatListModule, ThrottleButtonClickDirective, TranslateModule]
})
export class TeamAddComponent implements OnChanges {
  @Input('staff') allStaff: StaffModel[];
  @Output() closeAddFormEvent = new EventEmitter<void>();
  @Output() teamAdded = new EventEmitter<ReceiverTeam>();

  constructor(private api: ApiService, private translate: TranslateService, private fb: UntypedFormBuilder,) {
  }


  // Used to delete Mobile Device location from location list
  private mobileDevicesLocationServerName: string = 'Mobile devices';

  team: ReceiverTeam;
  teamAddForm: UntypedFormGroup;
  locationList: LocationModel[];
  addTeamFormError: string;
  availableStaffForCompany: StaffModel[];
  availableStaffForLocation: StaffModel[];
  allSelected = false;

  createTeamAddForm(): void {
    this.teamAddForm = this.fb.group({
      groupName: ['', {
        validators: [Validators.required],
      }],
      locationName: [''],
      selectedTeamMembers: "",
      teamLocked: false
    })
  }


  getLocations(): void {
    this.api.getLocations().subscribe(res => {
      const locations: LocationModel[] = res;
      //Delete mobile device location from locations
      const mobileDeviceLocationIndex: number = locations.findIndex((location) => location.name === this.mobileDevicesLocationServerName);
      if (mobileDeviceLocationIndex !== -1) {
        locations.splice(mobileDeviceLocationIndex, 1);
      }
      this.locationList = locations;
    });
  }


  closeAddForm(): void {
    this.closeAddFormEvent.emit();
  }

  addTeam(): void {
    let locationId: string = '';
    const selectedLocation: LocationModel = this.locationList.find((locationName) =>
      locationName === this.teamAddForm.value.locationName);

    if (selectedLocation) {
      locationId = selectedLocation.id;
    }

    const groupData = {
      'groupName': this.teamAddForm.value.groupName,
      'locationId': locationId,
      'locked': this.teamAddForm.value.teamLocked,
      'staffs': this.teamAddForm.value.selectedTeamMembers
    };

    this.api.addReceiverTeam(groupData).subscribe(res => {
      this.team = res;
      this.teamAdded.emit(this.team);
      this.closeAddForm();
    }, err => {
      this.translate.get(['ERROR']).subscribe(t => {
        this.addTeamFormError = t.ERROR;
      })
    })

  }
  parseStaffList(staff: StaffModel[]): void {
    if (this.teamAddForm && this.teamAddForm.value.locationName) {
      this.availableStaffForLocation = staff.filter(staff => staff.locationId === this.teamAddForm.value.locationName.id);
    } else {
      this.availableStaffForLocation = [];
    }
    this.availableStaffForCompany = staff.filter(staff => staff.locationId.startsWith('C0'))
  }

  ngOnChanges() {
    this.parseStaffList(this.allStaff)
    this.getLocations();
    this.createTeamAddForm();
  }

  toggleAllSelection() {
    if (!this.allSelected) {
      this.teamAddForm.controls.selectedTeamMembers
        .patchValue([
          ...this.availableStaffForCompany.map(staff => staff.id),
          ...this.availableStaffForLocation.map(staff => staff.id)
        ]);
      this.allSelected = true;
    } else {
      this.teamAddForm.controls.selectedTeamMembers.patchValue([]);
      this.allSelected = false;
    }
  }

}

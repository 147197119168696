import { Component, OnChanges, Input, OnInit, OnDestroy } from "@angular/core";
import { ApiService } from "app/services/api.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { LocationModel, LocationEmaSettings } from "app/models/location.model";
import { Observable, Subject, concat } from "rxjs";
import { throttleTime } from "rxjs/operators";
import { MatListModule } from "@angular/material/list";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { CommonModule } from "@angular/common";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";

// States for saving categories. 0 = not saved, 1 = saved successfully, 2 = saving returned errors
enum SaveState {
  NotSaved = 0,
  Saved = 1,
  Error = 2,
}

type FormType =
  "submitAlertTimeoutsForm" |
  "submitAlertNoteForm" |
  "submitAlarmOptionsForm" |
  "submitAlertUiForm" |
  "submitLoginKeyForm";

@Component({
  selector: "location-ema-settings",
  templateUrl: "./location-ema-settings.component.html",
  styleUrls: ["./location-ema-settings.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatListModule,
    TranslateModule,
    MatCheckboxModule
  ],
})
export class LocationEmaSettingsComponent
  implements OnChanges, OnInit, OnDestroy {
  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder
  ) { }

  @Input("location") location: LocationModel;

  defaultConfigChecked: boolean = false;
  saving: boolean = false;

  locationSettings: LocationEmaSettings;
  alertTimeoutsForm: UntypedFormGroup;
  alertUiForm: UntypedFormGroup;
  alarmOptionsForm: UntypedFormGroup;
  loginKeyForm: UntypedFormGroup;
  alertNoteForm: UntypedFormGroup;

  saveState = {
    alertTimers: SaveState.NotSaved,
    alertPriorities: SaveState.NotSaved,
    alertNotes: SaveState.NotSaved,
    alarmOptions: SaveState.NotSaved,
    loginKeys: SaveState.NotSaved,
    alertUi: SaveState.NotSaved,
    defaultConfigForms: SaveState.NotSaved
  };

  soundOptions: string[];
  colourOptions: string[];
  alertTypeOptions: any;

  formSubmitSubject = new Subject();

  set setDefaultConfigChecked(locationId: string) {
    // Check the default configurations if the given location is company
    this.defaultConfigChecked = locationId.startsWith("C0") ? true : false;

    if (this.defaultConfigChecked) {
      this.alertTimeoutsForm.disable();
      this.alertUiForm.disable();
      this.alarmOptionsForm.disable();
      this.loginKeyForm.disable();
    } else {
      this.alertTimeoutsForm.enable();
      this.alertUiForm.enable();
      this.alarmOptionsForm.enable();
      this.loginKeyForm.enable();
    }
  }

  initForms(): void {
    this.alertTimeoutsForm = this.fb.group({
      reserved: [
        this.locationSettings.alertTimers.reserved.value,
        Validators.required,
      ],
      started: [
        this.locationSettings.alertTimers.started.value,
        Validators.required,
      ],
      completed: [
        this.locationSettings.alertTimers.completed.value,
        Validators.required,
      ],
    });

    this.alertUiForm = this.fb.group({
      quickComplete: this.locationSettings.alertUi.quickComplete.value,
      alertCardHeader: this.locationSettings.alertUi.alertCardHeader.value,
    });

    this.alarmOptionsForm = this.fb.group({
      offlineDelay: this.locationSettings.alarmOptions.offlineDelay.value,
      startedTaskMuting: this.locationSettings.alarmOptions.startedTaskMuting.value,
      minimumVolume: this.locationSettings.alarmOptions.minimumVolume.value,
    });

    this.loginKeyForm = this.fb.group({
      loginKeys: this.locationSettings.loginKeys.value,
    });

    this.alertNoteForm = this.fb.group({
      note: ["", Validators.required],
    });
  }

  submitAlertTimeoutsForm(): void {
    this.saveState.alertTimers = 0;
    const form = this.getAlertTimeoutsSubmitForm(this.location.id);

    this.api.saveEmaSettings(form).subscribe(
      (res) => {
        this.saveState.alertTimers = 1;
      },
      (err) => {
        this.saveState.alertTimers = 2;
      }
    );
  }

  getAlertTimeoutsSubmitForm(locationId: string): any {
    const form = {
      id: locationId,
      category: "alert-timers",
      settings: JSON.parse(JSON.stringify(this.locationSettings.alertTimers)),
    };
    form.settings.reserved.value = this.alertTimeoutsForm.value.reserved;
    form.settings.started.value = this.alertTimeoutsForm.value.started;
    form.settings.completed.value = this.alertTimeoutsForm.value.completed;
    return form;
  }

  submitAlertUiForm(): void {
    this.saveState.alertUi = 0;
    const form = this.getAlertUiSubmitForm(this.location.id);

    this.api.saveEmaSettings(form).subscribe(
      (res) => {
        this.saveState.alertUi = 1;
      },
      (err) => {
        this.saveState.alertUi = 2;
      }
    );
  }

  getAlertUiSubmitForm(locationId: string): any {
    const form: any = {
      id: locationId,
      category: "alert-ui",
      settings: JSON.parse(JSON.stringify(this.locationSettings.alertUi)),
    };
    form.settings.quickComplete.value = this.alertUiForm.value.quickComplete;
    form.settings.alertCardHeader.value = this.alertUiForm.value.alertCardHeader;
    return form;
  }

  submitAlarmOptionsForm(): void {
    this.saveState.alarmOptions = 0;
    const form = this.getAlarmOptionsSubmitForm(this.location.id);

    this.api.saveEmaSettings(form).subscribe(
      (res) => {
        this.saveState.alarmOptions = 1;
      },
      (err) => {
        this.saveState.alarmOptions = 2;
      }
    );
  }

  getAlarmOptionsSubmitForm(locationId: string): any {
    const form: any = {
      id: locationId,
      category: "alarm-options",
      settings: JSON.parse(JSON.stringify(this.locationSettings.alarmOptions)),
    };
    form.settings.offlineDelay.value = this.alarmOptionsForm.value.offlineDelay;
    form.settings.startedTaskMuting.value = this.alarmOptionsForm.value.startedTaskMuting;
    form.settings.minimumVolume.value = this.alarmOptionsForm.value.minimumVolume;
    return form;
  }

  submitLoginKeyForm(): void {
    this.saveState.loginKeys = 0;
    const form = this.getLoginKeySubmitForm(this.location.id);

    this.api.saveEmaSettings(form).subscribe(
      (res) => {
        this.saveState.loginKeys = 1;
      },
      (err) => {
        this.saveState.loginKeys = 2;
      }
    );
  }

  getLoginKeySubmitForm(locationId: string): any {
    let form = {
      id: locationId,
      category: "login-keys",
      settings: JSON.parse(JSON.stringify(this.locationSettings.loginKeys)),
    };
    form.settings.value = this.loginKeyForm.value.loginKeys;
    return form;
  }

  submitAlertNoteForm(): void {
    let notes: any = JSON.parse(
      JSON.stringify(this.locationSettings.alertNotes)
    );
    notes.notes.push(this.alertNoteForm.value.note);
    this.saveNotes(notes);
  }

  removeNote(i: number): void {
    let notes: any = JSON.parse(
      JSON.stringify(this.locationSettings.alertNotes)
    );
    notes.notes.splice(i, 1);
    this.saveNotes(notes);
  }

  saveNotes(notes: any): void {
    this.saveState.alertNotes = 0;
    let data: any = {
      id: this.location.id,
      category: "alert-notes",
      settings: notes,
    };
    this.api.saveEmaSettings(data).subscribe(
      (res) => {
        this.saveState.alertNotes = 1;
        this.locationSettings.alertNotes.notes = data.settings.notes;
      },
      (err) => {
        this.saveState.alertNotes = 2;
      }
    );
  }

  getOfflineDelayOption(option: any): any {
    return typeof option === "string"
      ? this.translate.instant(option)
      : option + " " + this.translate.instant("MINUTES");
  }

  parseAlertTypes(): void {
    // Parse response from Alert Server
    for (
      let i = 0;
      i < this.locationSettings.alertPriorities.priorities.length;
      i++
    ) {
      let priority: any = this.locationSettings.alertPriorities.priorities[i];
      // Make deep copy of all available alert types so it's unique in every priority
      priority.availableAlertTypes = JSON.parse(
        JSON.stringify(this.alertTypeOptions)
      );

      // Copy the original config types so those can be reverted later
      priority.originalConfigTypes = JSON.parse(
        JSON.stringify(priority.configAlertTypes)
      );

      // Set fixed alert types as selected and disabled
      for (let j = 0; j < priority.fixedAlertTypes.length; j++) {
        let alertType: any = priority.availableAlertTypes.find((obj) => {
          return obj.value === priority.fixedAlertTypes[j];
        });
        if (alertType) {
          alertType.disabled = true;
          alertType.selected = true;
        }
      }

      // Set already configured alert types as selected and disabled
      for (let j = 0; j < priority.configAlertTypes.length; j++) {
        let alertType: any = priority.availableAlertTypes.find((obj) => {
          return obj.value === priority.configAlertTypes[j];
        });
        if (alertType) {
          alertType.disabled = true;
          alertType.selected = true;
        }
      }
    }

    // Remove fixed alert types from list of available options from other priorities
    for (
      let i = 0;
      i < this.locationSettings.alertPriorities.priorities.length;
      i++
    ) {
      let priority: any = this.locationSettings.alertPriorities.priorities[i];
      for (
        let j = 0;
        j < this.locationSettings.alertPriorities.priorities.length;
        j++
      ) {
        let anotherPriority =
          this.locationSettings.alertPriorities.priorities[j];
        if (anotherPriority.priority != priority.priority) {
          anotherPriority.availableAlertTypes =
            anotherPriority.availableAlertTypes.filter((x) => {
              return priority.fixedAlertTypes.indexOf(x.value) < 0;
            });
        }
      }
    }
  }

  alertTypeSelectionChange(event: any, priority: any): void {
    // Get selected option
    const option = event.options[0];

    // When new type is selected unselect it from other priorities
    if (option.selected) {
      priority.configAlertTypes.push(option.value);
      // Mark the selected type as selected in available types array
      let alertType: any = priority.availableAlertTypes.find((obj) => {
        return obj.value === option.value;
      });
      if (alertType) {
        alertType.selected = true;
      }

      // Mark the selected type as unselected in all others
      for (
        let i = 0;
        i < this.locationSettings.alertPriorities.priorities.length;
        i++
      ) {
        const p = this.locationSettings.alertPriorities.priorities[i];
        // Check that priority is not the same as selected
        if (p.priority !== priority.priority) {
          // Remove selected type from other priorities config list
          let typeIndex = p.configAlertTypes.indexOf(option.value);
          if (typeIndex !== -1) {
            p.configAlertTypes.splice(typeIndex, 1);
          }
          // Find the selected type and set selected and disabled values to false
          let alertType: any = p.availableAlertTypes.find((obj) => {
            return obj.value === option.value;
          });
          if (alertType) {
            alertType.selected = false;
            alertType.disabled = false;
          }
        }
      }
    } else {
      // When type is unselected revert it back to the original priority and set it to selected and disabled
      let typeIndex = priority.configAlertTypes.indexOf(option.value);
      if (typeIndex !== -1) {
        priority.configAlertTypes.splice(typeIndex, 1);
      }
      // Mark the selected type as unselected in available types array
      let alertType: any = priority.availableAlertTypes.find((obj: any) => {
        return obj.value === option.value;
      });
      if (alertType) {
        alertType.selected = false;
      }
      // Find the priority where the type was originally configured
      for (
        let i = 0;
        i < this.locationSettings.alertPriorities.priorities.length;
        i++
      ) {
        const p = this.locationSettings.alertPriorities.priorities[i];
        if (p.originalConfigTypes.includes(option.value)) {
          p.configAlertTypes.push(option.value);
          let alertType: any = p.availableAlertTypes.find((obj: any) => {
            return obj.value === option.value;
          });
          if (alertType) {
            alertType.selected = true;
            alertType.disabled = true;
          }
        }
      }
    }
  }

  submitAlertPriorities(): void {
    this.saveState.alertPriorities = 0;
    const form = this.getAlertPrioritySubmitForm(this.location.id);

    this.api.saveEmaSettings(form).subscribe(
      (res) => {
        this.saveState.alertPriorities = 1;
        this.parseAlertTypes();
      },
      (err) => {
        this.saveState.alertPriorities = 2;
      }
    );
  }

  getAlertPrioritySubmitForm(locationId: string): any {
    const form = {
      id: locationId,
      category: "alert-priorities",
      settings: this.locationSettings.alertPriorities,
    }
    // Delete UI only fields
    for (let i = 0; i < form.settings.priorities.length; i++) {
      const priority = form.settings.priorities[i];
      delete priority.availableAlertTypes;
      delete priority.originalConfigTypes;
    }

    return form;
  }

  playPrioritySound(name: string): void {
    let audioEl = <HTMLAudioElement>document.getElementById("priorityAudio");
    audioEl.src = `/assets/sounds/${name}.mp3`;
    audioEl.load();
    audioEl.play();
  }

  changeDefaultSettings(event: any): void {
    let settingsId = this.location.id;

    if (event.checked) {
      settingsId = JSON.parse(localStorage.getItem("customerInfo")).customerId;
    }

    this.api.getEmaSettings(settingsId).subscribe(
      (res) => {
        this.locationSettings = res;
        this.initForms();
        this.parseAlertTypes();
        this.setDefaultConfigChecked = settingsId; // Check if the fetched settings are default settings for the company
      },
      (err) => { }
    );
  }

  /**
   * Submit all forms for the default settings. NOTE: not in use at the moment.
   */
  submitDefaultConfigForms(): void {
    const currentLocationId = this.location.id;
    const locationIds = [currentLocationId];
    const submits$: Observable<any>[] = [];

    // TODO: Add other IDs from locations that uses the same default settings
    this.saving = true;

    for (const id of locationIds) {
      submits$.push(this.api.saveEmaSettings(this.getAlertTimeoutsSubmitForm(id)));
      submits$.push(this.api.saveEmaSettings(this.getAlertPrioritySubmitForm(id)));
      submits$.push(this.api.saveEmaSettings(this.getAlarmOptionsSubmitForm(id)));
      submits$.push(this.api.saveEmaSettings(this.getAlertUiSubmitForm(id)));
      submits$.push(this.api.saveEmaSettings(this.getLoginKeySubmitForm(id)));
    }

    // Wait each submit to finish before starting the next one, otherwise all saving can fail
    concat(...submits$)
      .subscribe({
        complete: () => {
          this.saveState.defaultConfigForms = 1;
          this.saving = false;
        },
        error: (error) => {
          this.saveState.defaultConfigForms = 2;
          this.saving = false;
          console.error(error);
        },
      });
  }

  ngOnInit(): void {
    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe((formType: FormType) => {
        switch (formType) {
          case "submitAlertTimeoutsForm":
            this.submitAlertTimeoutsForm();
            break;
          case "submitAlertNoteForm":
            this.submitAlertNoteForm();
            break;
          case "submitAlarmOptionsForm":
            this.submitAlarmOptionsForm();
            break;
          case "submitAlertUiForm":
            this.submitAlertUiForm();
            break;
          case "submitLoginKeyForm":
            this.submitLoginKeyForm();
            break;
        }
      });
  }

  ngOnChanges() {
    this.soundOptions = [
      "SOUND1",
      "SOUND2",
      "SOUND3",
      "SOUND4",
      "SOUND5",
      "SOUND6",
    ];
    this.colourOptions = ["ORANGE", "GREEN", "RED", "BLUE", "PURPLE"];
    this.alertTypeOptions = [
      {
        value: "TYPE_FIRE_ALARM",
        selected: false,
        disabled: false,
      },
      {
        value: "TYPE_DISTRESS_ALERT",
        selected: false,
        disabled: false,
      },
      {
        value: "TYPE_EXIT_ALERT",
        selected: false,
        disabled: false,
      },
      {
        value: "TYPE_BUTTON_ALERT",
        selected: false,
        disabled: false,
      },
      {
        value: "TYPE_MOVEMENT_ALERT",
        selected: false,
        disabled: false,
      },
      {
        value: "TYPE_ASSISTANCE_ALERT",
        selected: false,
        disabled: false,
      },
      {
        value: "TYPE_DOOR_ALERT",
        selected: false,
        disabled: false,
      },
      {
        value: "TYPE_NONE",
        selected: false,
        disabled: false,
      },
    ];
    this.api.getEmaSettings(this.location.id).subscribe(
      (res) => {
        this.locationSettings = res;
        this.initForms();
        this.parseAlertTypes();
      },
      (err) => { }
    );
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

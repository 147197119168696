import { ApiService } from "app/services/api.service";
import {
  Component,
  Input,
  OnChanges,
} from "@angular/core";
import { ICompanyGroup } from "app/models/company.models";
import {
  TIMEZONES,
  ITimezone,
} from "app/components/company-management/timezones";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    selector: "company-group-edit-info",
    templateUrl: "./company-group-edit-info.component.html",
    styleUrls: ["./company-group-edit-info.component.css"],
    standalone: true,
    imports: [FormsModule, NgClass, NgFor, NgIf, TranslateModule]
})
export class CompanyGroupEditInformationComponent implements OnChanges {
  constructor(private api: ApiService) {}

  @Input() companyGroup: ICompanyGroup;
  editCompanyGroupForm: ICompanyGroup;

  successMessage: string;
  failureMessage: string;

  timezones: ITimezone[] = TIMEZONES;

  updateCompanyGroup() {
    this.api
      .updateCompanyGroup(this.editCompanyGroupForm)
      .subscribe((updatedCompanyGroup: ICompanyGroup) => {
        if (updatedCompanyGroup) {
          this.companyGroup = updatedCompanyGroup;
          this.successMessage = "UPDATE_COMPANY_GROUP_INFO_SUCCESS";
        } else {
          this.failureMessage = "UPDATE_COMPANY_GROUP_INFO_FAILED";
        }
      }),
      (error: any) => {
        console.log(error);
        this.failureMessage = "UPDATE_COMPANY_GROUP_INFO_FAILED";
      };
  }

  // Set timezone and return the name of the timezone
  setTimezone(): string {
    const data = this.timezones.find(
      (x) => x.country.alpha3Code === this.editCompanyGroupForm.country
    );
    if (data) {
      this.editCompanyGroupForm.timezone = data.timezone;
      return data.name;
    }
    return "";
  }

  initForm(): void {
    // Create deep copy from company group object
    this.editCompanyGroupForm = JSON.parse(JSON.stringify(this.companyGroup));
  }

  ngOnChanges(): void {
    this.initForm();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from '../../../../services/device.service';
import { MomentPipe } from 'app/pipes/pipes';

@Component({
  selector: 'display-device-info',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MomentPipe,
    ],
  templateUrl: './display-device-info.component.html',
  styleUrls: ['./display-device-info.component.css']
})
export class DisplayDeviceInfo implements OnInit {
  constructor(
    private service: DeviceService
  ) { }

  @Input() device;
  @Input() userRoleLevel: number;
  @Input() residentsLength: number;
  @Output() enterEditMode = new EventEmitter<any>();

  iotCommsEnabled: boolean;

  ngOnInit(){
    this.iotCommsEnabled = this.service.featureEnabled("IotComms")
  }

  onEditMode(){
    this.enterEditMode.emit();
  }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { LogoutOptionsComponent } from './logout-options/logout-options.component';
import { GlobalNavComponent } from './nav/nav.component';
import { LogoComponent } from './logo/logo.component';
import { NgStyle, NgIf } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
    selector: 'app',
    templateUrl: './global.component.html',
    styleUrls: ['./global.component.css'],
    standalone: true,
    imports: [MatSidenavModule, NgStyle, LogoComponent, GlobalNavComponent, LogoutOptionsComponent, ToolbarComponent, NgIf, RouterOutlet, TranslateModule]
})
export class AppComponent implements OnInit {
  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog
  ) { }

  user: any;
  landingPageUser:any;
  loggedCustomer: any;
  language: string;
  userPasswordWillExpire: boolean = false;
  highlightNotification: any;
  copyYear = moment().format('YYYY');
  appVersion = environment.version;
  url: string = this.router.url;
  screenWidth: number;
  sideNavOpened: boolean;
  onMobileScreen: boolean;
  enterpriseName: string;
  showTitles = true;
  initials: string;
  landingPage: any;
  features: string;

  // indicator for setting different layout depending on desktop/mobile screen size
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 599) {
      this.onMobileScreen = false;
      this.sideNavOpened = true;
    } else {
      this.onMobileScreen = true;
      this.sideNavOpened = false;
    }
  }

  toggleTitles(titlesShown: boolean) {
    this.showTitles = titlesShown;
  }

  routeChanged(): void {
    this.language = localStorage.getItem("language");
    this.url = this.router.url;
    this.user = JSON.parse(localStorage.getItem("user"));
    this.landingPageUser = JSON.parse(localStorage.getItem("landing-page"));
    if (this.user) {
      moment.tz.setDefault(this.user.timezone)

      if (!localStorage.getItem("features")) {
        this.api.getFeatures(true)
          .subscribe((response: any) => {
            localStorage.setItem("features", JSON.stringify(response));
            this.features = JSON.stringify(response)
          })
      }
      else {
        this.features = localStorage.getItem("features");
      }

      if (!localStorage.getItem("customerInfo")) {
        this.api.getCustomerInformation()
          .subscribe((response: any) => {
            localStorage.setItem("customerInfo", JSON.stringify(response));
          })
      }

      // Calculate if user password will expire within 14 days
      if (this.user.passwordExpirationDays !== 0) {
        if (this.user.passwordChanged && this.user.passwordChanged !== '0001-01-01T00:00:00Z') {
          let passwordWillExpire = moment(this.user.passwordChanged).add(this.user.passwordExpirationDays, 'days');
          if (passwordWillExpire.diff(moment(), 'days') < 14) {
            this.userPasswordWillExpire = true;
          }
        }
      }
    }

    if (localStorage.getItem("language")) {
      if (this.user && !localStorage.getItem("act")) {
        this.api.getActivationTranslations(localStorage.getItem("language"))
          .subscribe((response: any) => {
            localStorage.setItem("act", JSON.stringify(response));
          });
      }
    }
  }

  getUserPasswordExpirationDay(): string {
    return moment(this.user.passwordChanged).add(this.user.passwordExpirationDays, 'days').format('l');
  }

  ngOnInit(): void {
    this.onResize();
    let language = localStorage.getItem("language");
    if (language) {
      this.translate.use(language);
      //Change englisg language to use UK locale in timestamps
      if (language === 'en') {
        moment.locale('en-GB');
      } else {
        moment.locale(language);
      }

      if (this.user && !localStorage.getItem("act")) {
        this.api.getActivationTranslations(language)
          .subscribe((response: any) => {
            localStorage.setItem("act", JSON.stringify(response));
          });
      }
    }
  }
}

<div class="float-right pt-3">
  <!-- Hide the dropdown button, if the binding time is not visible since there's no available currently -->
  <i
    *ngIf="bindingTimeVisible"
    #showDropdownBtn
    class="fa-regular fa-ellipsis fa-lg dialogControlBtn cursorPointer mr-4"
    (click)="showMenuItems = true"
  ></i>
  <i class="fa-regular fa-xmark fa-xl dialogControlBtn cursorPointer mr-3" (click)="close()"></i>

  <div class="customDropdown" *ngIf="showMenuItems" #dropdown>
    <div class="dropdownOption" (click)="toggle('deviceInfo')">
      {{ "DEVICE_INFORMATION" | translate}}
    </div>

    <div class="dropdownOption" (click)="toggle('bindTimeManager')">
      {{ "EDIT_BINDING_TIME" | translate}}
    </div>
  </div>
</div>

<div *ngIf="successMsg" class="alert alert-success" [@fadeOut] role="alert">
  {{ successMsg | translate }}
</div>

<ng-container [ngSwitch]="displayedContent">
  <app-device-information
    *ngSwitchCase="'deviceInfo'"
    [device]="device">
  </app-device-information>

  <app-device-bind-time-manager
    *ngSwitchCase="'bindTimeManager'"
    [device]="device"
    (closed)="openDefaultTab($event)"
  ></app-device-bind-time-manager>
</ng-container>

<div class="row">
    <!--Container for zonedata-->
    <div class="col-lg-12" *ngIf="zoneData?.snapshotBase64">
        <div class="zoneList">
            <div>
                <h6>{{'ACTIVE_ZONES' |translate}}</h6>
                <div *ngFor="let zone of zoneData?.activeZones" class="alert cursorPointer zoneBlock"
                    [ngClass]="getClassForZone(zone)" (click)="editZone(zone)">
                    {{zone.name}} <span class="float-right"><span class="fa-light fa-fw fa-ban disableZone"
                            *ngIf="!zone.modified" (click)="disableZone(zone)"></span><span *ngIf="zone.modified"
                            class="fa-light fa-lg fa-fw fa-floppy-disk" (click)="saveZone(zone)"></span>
                        <span *ngIf="zone.modified" class="fa-light fa-lg fa-fw fa-xmark ml-3"
                            (click)="cancelZoneEdit(zone)"></span>
                    </span>
                </div>
            </div>
            <div *ngIf="zoneData?.disabledZones.length > 0">
                <h6>{{'DISABLED_ZONES' |translate}}</h6>
                <div *ngFor="let zone of zoneData?.disabledZones" class="alert zoneBlock"
                    [ngClass]="getClassForZone(zone)">
                    {{zone.name}} <span class="float-right"><span
                            class="fa-light fa-fw fa-check activateZone cursorPointer" *ngIf="!zone.modified"
                            (click)="activateZone(zone)"></span><span *ngIf="zone.modified"
                            class="fa-light fa-lg fa-fw fa-floppy-disk cursorPointer"
                            (click)="saveZone(zone)"></span><span *ngIf="zone.modified"
                            class="fa-light fa-lg fa-fw fa-xmark ml-3 cursorPointer"
                            (click)="cancelZoneEdit(zone)"></span></span>
                </div>
            </div>
        </div>
        <div class="zoneContainer" [style.height.px]="zoneData?.height" [style.width.px]="zoneData?.width" #dragBounds>
            <img *ngIf="zoneData" [src]="'data:image/png;base64,' + zoneData.snapshotBase64" />
            <svg [style.height.px]="zoneData?.height" [style.width.px]="zoneData?.width" class="zonesSvg">
                <polygon *ngFor="let zone of zoneData?.activeZones" [attr.points]="zone.coords"
                    [ngClass]="getClassForZone(zone)" (click)="editZone(zone)" />
            </svg>
            <div class="zonePoint" ngDraggable [bounds]="dragBounds" [inBounds]="true" [preventDefaultEvent]="true"
                (movingOffset)="onMoving($event, i)" *ngFor="let xy of selectedZone?.xyCoords; let i = index"
                [ngStyle]="{'left.px': xy.left, 'top.px': xy.top}"></div>
        </div>
    </div>
    <!--Error if snapshot could not be loaded-->
    <div class="col-lg-4" *ngIf="!zoneData?.snapshotBase64 && zoneData?.zones.length > 0">
        {{'SNAPSHOT_NOT_LOADED' |translate}}
    </div>
    <!--Loading icon while zone information and snapshot is being loaded-->
    <div class="col-lg-12" *ngIf="!zoneData">
        <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg">
    </div>
</div>

<mat-card class="my-2">
    <div [ngClass]="(isCheckedAlarms$ | async) ? 'chosenAlarms align-items-start':'commentBoxes'"
        class="d-flex cursorPointer" (click)="openCheckboxList()">
        <i class="d-block mx-2 fa-light fa-ballot-check"></i>

        <ng-template #emptyHeader>
            <span class="flex-grow-1 text-left">{{'MARK_ALARM_REASON'|translate}}</span>
        </ng-template>

        <div *ngIf="isCheckedAlarms$ | async; else emptyHeader">
            <app-display-alarm-reasons
                [alert]="alert"
                [dataComesFromSocket]="false">
            </app-display-alarm-reasons>
        </div>

        <button mat-flat-button class="mx-2 placeToTheRight" *ngIf="!checkboxListIsOpen">
            <i [ngClass]="
                (isCheckedAlarms$ | async)?
                    'fa-light fa-pen-to-square penIcon':
                    'fa-light fa-plus'">
            </i>
        </button>
    </div>


    <alarm-reason-checklist class="mt-4 mb-3 mx-3"
        [hidden]="checkboxListIsOpen != true"
        [alert]="alert"
        (onClosingForm)="checkboxListIsOpen = false"
        [checkboxListIsOpen]="checkboxListIsOpen">
    </alarm-reason-checklist>

</mat-card>
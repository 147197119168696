<div class="container">
    <div class="row mt-3 pb-5">
        <div class="col ml-1">
            <p class="mb-1">
                <b>{{ "NAME" | translate }}</b
                >:&nbsp;{{ companyGroup.name }}
            </p>
            <p class="mb-1">
                <b>ID</b>:&nbsp;{{ companyGroup.companyGroupId }}
            </p>
            <p>
                <b>{{ "POPULATE_STAFF" }}</b
                >:&nbsp;{{
                    companyGroup.populateStaffEnabled
                        ? "ENABLED"
                        : ("DISABLED" | translate)
                }}
            </p>
        </div>
        <div class="col">
            <ngx-bootstrap-multiselect
                *ngIf="companySelect.options.length > 0"
                class="float-right ssDropdownToLeft"
                [options]="companySelect.options"
                [texts]="companySelect.texts"
                [settings]="companySelect.settings"
                [(ngModel)]="addedCompanies"
            >
            </ngx-bootstrap-multiselect>
        </div>
    </div>
</div>

<div class="mx-3">
    <div
        *ngIf="successMessage"
        class="alert alert-success"
        role="alert"
        [@fadeOut]
    >
        <!--Success! The company/companies have been added to the group.-->
        {{ successMessage | translate }}
    </div>
    <div
        *ngIf="failureMessage"
        class="alert alert-danger"
        role="alert"
        [@fadeOut]
    >
        <!--Failed to company/companies to the group-->
        {{ failureMessage | translate }}
    </div>
</div>

<div class="m-2" style="height: 430px">
    <table *ngIf="addedCompanies.length > 0">
        <thead>
            <tr class="disableHover">
                <th>
                    <b>{{ "COMPANY" | translate }}</b>
                </th>
                <th>
                    <i
                        *ngIf="!companyGroup.populateStaffEnabled"
                        class="fa-solid fa-user-xmark float-right mr-3"
                        ngbTooltip="{{ 'POPULATE_STAFF_DISABLED' | translate }}"
                        placement="top"
                    ></i>
                </th>
                <th>
                    <b>{{ "ADDRESS" | translate }}</b>
                </th>
                <th><b>ID</b></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="let company of addedCompanies; let i = index"
                class="disableHover"
            >
                <td>
                    {{ company.company }}
                </td>
                <td style="width: 110px">
                    <div
                        *ngIf="companyGroup.populateStaffEnabled"
                        class="row float-right"
                    >
                        <div [hidden]="!company.populateStaff" class="mr-2">
                            <label
                                class="form-check-label"
                                [for]="'addToAllReceiverGroups' + i"
                            >
                                <i
                                    class="fa-solid fa-users mr-27px"
                                    ngbTooltip="{{
                                        'ADD_POPULATED_STAFF_TO_ALL_RECEIVER_GROUPS'
                                            | translate
                                    }}"
                                    placement="top"
                                ></i>
                            </label>
                            <input
                                type="checkbox"
                                class="form-check-input"
                                [id]="'addToAllReceiverGroups' + i"
                                [(ngModel)]="company.addToAllReceiverGroups"
                            />
                        </div>

                        <div class="mr-4">
                            <label
                                class="form-check-label"
                                [for]="'populateStaff' + i"
                            >
                                <i
                                    class="fa-solid fa-clipboard-user fa-lg mr-27px"
                                    ngbTooltip="{{
                                        'POPULATE_STAFF' | translate
                                    }}"
                                    placement="top"
                                ></i>
                            </label>
                            <input
                                type="checkbox"
                                [(ngModel)]="company.populateStaff"
                                (ngModelChange)="onPopulateStaffChange(company)"
                                class="form-check-input cursorPointerHover"
                                [id]="'populateStaff' + i"
                            />
                        </div>
                    </div>
                </td>

                <td>{{ company.address }}</td>
                <td>{{ company.companyId }}</td>
                <td style="width: 65px">
                    <i
                        class="fad fa-trash-alt cursorPointerHover ml-3"
                        ngbTooltip="{{ 'REMOVE_COMPANY' | translate }}"
                        [container]="'body'"
                        placement="top"
                        (click)="removeNewCompany(i)"
                    ></i>
                </td>
            </tr>

            <tr *ngIf="addedCompanies.length > 0" class="disableHover">
                <td colspan="5" class="noBottomBorder">
                    <div class="float-right py-2">
                        <button
                            class="btn btn-primary mx-2"
                            appThrottleClick
                            (throttledClick)="saveNewCompanies()"
                        >
                            <span class="fa-light fa-fw fa-floppy-disk"></span>
                            {{ "SAVE" | translate }}
                        </button>
                        <button
                            class="btn secondaryBtnWhiteBg"
                            (click)="removeAllNewCompanies()"
                        >
                            <span class="fa-light fa-trash-alt"></span>
                            {{ "REMOVE_ALL" | translate }}
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <table *ngIf="companyGroup.companies.length > 0" class="mt-3">
        <thead>
            <tr class="disableHover">
                <th>
                    <b>{{ "COMPANY" | translate }}</b>
                </th>
                <th>
                    <b>{{ "ADDRESS" | translate }}</b>
                </th>
                <th><b>ID</b></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let company of companyGroup.companies; let i = index">
                <td>{{ company.company }}</td>
                <td>{{ company.address }}</td>
                <td>{{ company.companyId }}</td>
                <td style="width: 65px">
                    <div *ngIf="!company.confirmDelete">
                        <i
                            class="hoverVisible fad fa-trash-alt cursorPointerHover ml-3"
                            ngbTooltip="{{
                                'REMOVE_COMPANY_FROM_GROUP' | translate
                            }}"
                            [container]="'body'"
                            placement="top"
                            (click)="company.confirmDelete = true"
                        ></i>
                    </div>

                    <div *ngIf="company.confirmDelete">
                        <i
                            class="fa-solid fa-check cursorPointerHover fa-lg"
                            style="color: #089c3b; margin-right: 17px"
                            ngbTooltip="{{ 'CONFIRM_REMOVE' | translate }}"
                            [container]="'body'"
                            placement="top"
                            (click)="
                                removeCurrentCompany(
                                    company.server,
                                    company.companyId,
                                    i
                                )
                            "
                        ></i>
                        <i
                            class="fa-solid fa-xmark cursorPointerHover fa-lg"
                            style="color: #e8413b"
                            ngbTooltip="{{ 'CANCEL' | translate }}"
                            [container]="'body'"
                            placement="top"
                            (click)="company.confirmDelete = false"
                        ></i>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <p
        *ngIf="
            companyGroup.companies.length === 0 && addedCompanies.length === 0
        "
        class="ml-3"
    >
        {{ "NO_COMPANIES" | translate }}
    </p>
</div>

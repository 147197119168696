<form *ngIf="listOfAlerts" [formGroup]="listOfAlerts">
    <div formArrayName="alarmReasonsByCategories">
        <mat-accordion multi="true">

            <!-- each category is an expandable panel with a name in the header-->
            <div *ngFor="let category of getCategoryArray().controls; let i=index">
                <mat-expansion-panel [formGroupName]="i" [expanded]="categoryIsChecked(category)" class="mb-1" #panel
                    data-testid="panel">

                    <mat-expansion-panel-header
                        [ngStyle]="{'font-weight': panel.expanded ? 'bolder': ''}"
                        data-testid="categoryName">
                        {{ allNames[i].categoryName }}
                    </mat-expansion-panel-header>

                    <!-- each alarm reason inside the category is a checkbox with a name -->
                    <div formArrayName="alarms" class="ml-3">
                        <div *ngFor="let alarm of getAlarmReasonsArray(i).controls; let j=index;">
                            <div [formGroupName]="j">
                                <mat-checkbox
                                    [checked]="alarm.get('isChecked').value"
                                    data-testid="checkbox"
                                    formControlName="isChecked">{{ allNames[i].alarms[j].name}}
                                </mat-checkbox>
                                <br>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>

        </mat-accordion>
    </div>
</form>
<div
    class="d-flex flex-column my-3">
    <div class="d-flex flex-row justify-content-between">
        <button (click)="cancelWorkingWithForm()"
            class="btn btn-outline-primary ml-4"
            data-testid="cancel_btn">
            {{ 'CANCEL' | translate }}
        </button>
        <button (click)="onSubmit()"
            class="btn btn-primary mr-4"
            data-testid="confirm_btn"
            [disabled]="!listOfAlerts.valid">
            {{ 'CONFIRM' | translate }}
        </button>
    </div>
    <div class="d-flex flex-row justify-content-end">
        <span *ngIf="!listOfAlerts.valid"
            class="validationNotice">{{'CHOOSE_ONE_ALARM_REASON' | translate}}
        </span>
    </div>
</div>
<!-- <p>{{ listOfAlerts.value | json }}</p> -->
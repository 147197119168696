import { filter } from 'rxjs/operators';
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { IDeviceAlert } from "app/models/statistics.model";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { TranslateModule } from "@ngx-translate/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { DeviceTypePipe } from "app/pipes/pipes";
import { Angular2Csv } from "angular2-csv";
import * as moment from "moment-timezone";
import { StatisticsService } from "app/services/statistics.service";
import { Component, OnInit, ViewChild, Input, AfterViewInit, ChangeDetectionStrategy, ElementRef, HostListener } from "@angular/core";
import { HelperService } from "app/services/helper.service";

const CSV_HEADERS = ["ID", "Name", "Location", "Address", "Postal code", "City", "More information", "Type", "Alerts", "Start date", "End date"];
const CSV_OPTIONS = { fieldSeparator: ";", quoteStrings: '"', decimalseparator: ".", showTitle: false, headers: CSV_HEADERS }

@Component({
  selector: "alerts-per-care-recipient",
  templateUrl: "./alerts-per-care-recipient.component.html",
  styleUrls: ["./alerts-per-care-recipient.component.css"],
  standalone: true,
  imports: [TranslateModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, MatTableModule, MatInputModule, MatSortModule, DeviceTypePipe, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsPerCareRecipientComponent implements OnInit, AfterViewInit {
  constructor(
    private statistics: StatisticsService,
    private helper: HelperService
  ) { }

  @Input() deviceAlerts: IDeviceAlert[];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("dropdownBtn") dropdownBtn: ElementRef;
  @ViewChild("dropdownMenu") dropdownMenu: ElementRef;

  displayedColumns = ["deviceName", "deviceType", "deviceId", "alertCount"];
  tableData: MatTableDataSource<IDeviceAlert>;

  alertCountFilter = new FormControl("");
  directionFilter = new FormControl("more");

  showDropdownMenu: boolean = false;
  user: any;

  /**
   * Device alert custom dropdown hide handler
   * @param event Click event
   */
  @HostListener("document:click", ["$event"])
  handleHideDropdown(event: Event): void {
    if (this.dropdownBtn && this.showDropdownMenu) {
      // Check if clicked element is dropdown option, dropdown button or icon inside the button
      const isOption = this.dropdownMenu.nativeElement.contains(event.target);
      const isDropdownBtn = this.dropdownBtn.nativeElement === event.target;
      const isBtnIcon = this.dropdownBtn.nativeElement.contains(event.target);

      // Hide the dropdown if the clicked element isn't any of the checked elements
      if (!isDropdownBtn && !isBtnIcon && !isOption) {
        this.showDropdownMenu = false;
      }
    }
  }

  exportCSV(): void {
    const alerts = this.filterAlerts(this.deviceAlerts);
    const startDate = moment(this.statistics.filters.startDate).format("YYYY-MM-DD");
    const endDate = moment(this.statistics.filters.endDate).format("YYYY-MM-DD");

    const filename = "deviceAlerts_" + startDate + "_" + endDate;
    const csvData = this.parseCsv(alerts);

    // Generate the CSV file, send it to the browser
    new Angular2Csv(csvData, filename, CSV_OPTIONS);

    // Close the dropdown menu after exporting the CSV
    this.showDropdownMenu = false;
  }

  parseCsv(alerts: IDeviceAlert[]): any[] {
    const csvData = [];

    const startDate = moment(this.statistics.filters.startDate).format("YYYY-MM-DD");
    const endDate = moment(this.statistics.filters.endDate).format("YYYY-MM-DD");

    for (const alert of alerts) {
      const row = {
        deviceId: `="${alert.deviceId}"`,
        deviceName: alert.deviceName,
        location: alert.locationName,
        address: alert.address,
        postalCode: alert.postalCode,
        city: alert.city,
        moreInfo: alert.moreInfo,
        deviceType: this.helper.getDeviceType(alert.deviceType),
        alerts: alert.alertCount,
        startDate: startDate,
        endDate: endDate,
      };
      csvData.push(row);
    }
    return csvData;
  }

  filterAlerts(deviceAlerts: IDeviceAlert[]): IDeviceAlert[] {
    let filteredAlerts = deviceAlerts;
    let selectedAlertCount: number;

    // Apply filter if alert count value is a number
    if (this.alertCountFilter.value !== "" && !isNaN(+this.alertCountFilter.value)) {
      selectedAlertCount = Number(this.alertCountFilter.value);

      switch (this.directionFilter.value) {
        case "more":
          filteredAlerts = this.deviceAlerts.filter((deviceAlert) => {
            return deviceAlert.alertCount > selectedAlertCount;
          });
          break;
        case "less":
          filteredAlerts = this.deviceAlerts.filter((deviceAlert) => {
            return deviceAlert.alertCount < selectedAlertCount;
          });
          break;
      }
    }
    return filteredAlerts;
  }

  onFilterChange(): void {
    const filteredDeviceAlerts = this.filterAlerts(this.deviceAlerts);

    this.tableData = new MatTableDataSource(filteredDeviceAlerts);
    this.tableData.sort = this.sort;
  }

  ngAfterViewInit(): void {
    this.tableData.sort = this.sort;
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.tableData = new MatTableDataSource(this.deviceAlerts);
  }
}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IActivityLog, IUserAccountChangeRecord, UserAccountChangeRecordField, VISIBLE_DETAILS_FIELDS_COUNT } from 'app/models/activity-log.model';
import { RouterModule } from '@angular/router';
import { IdentityFormatPipe, LockRightsPipe, MomentPipe, UserRolePipe, PropsFalsyPipe, AllPropsFalsyPipe, JoinPipe, IncludesPipe, SomePipe } from 'app/pipes/pipes';
import { BoxLayoutStylePipe } from './activity-staff-details-pipes';


@Component({
  selector: 'app-activity-staff-details',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MomentPipe,
    UserRolePipe,
    BoxLayoutStylePipe,
    LockRightsPipe,
    IdentityFormatPipe,
    AllPropsFalsyPipe,
    PropsFalsyPipe,
    JoinPipe,
    IncludesPipe,
    SomePipe
  ],
  templateUrl: './activity-staff-details.component.html',
  styleUrls: ['../activity-details-dialog/activity-details-content-styles.css']
})
export class ActivityStaffDetailsComponent implements OnChanges {
  @Output() closeDialog = new EventEmitter<void>();
  @Input() log: IActivityLog;

  readonly visibleFieldCount = VISIBLE_DETAILS_FIELDS_COUNT;

  accountChanges: IUserAccountChangeRecord;
  visibleFields: UserAccountChangeRecordField[] = [];
  allFields: UserAccountChangeRecordField[] = [];

  ngOnChanges(): void {
    this.accountChanges = this.log?.details?.accountChanges; // Copy the account changes to a local variable to just make the template little more cleaner.
    const result = this.getVisibleFields(this.accountChanges);
    this.visibleFields = [...result.visibleFields]; // Change the visible fields to a new array to trigger change detection.
    this.allFields = [...result.allFields];
  }

  private getVisibleFields(accountChanges: IUserAccountChangeRecord): { visibleFields: UserAccountChangeRecordField[], allFields: UserAccountChangeRecordField[] } {
    // Return empty array if the object is falsy.
    if (!accountChanges) {
      return { visibleFields: [], allFields: [] };
    }

    const visibleFields: UserAccountChangeRecordField[] = [];
    const allFields = Object.keys(accountChanges).filter(obj => obj) as UserAccountChangeRecordField[]; // Get the all fields that are not falsy.

    // Role level and lock rights have higher priority, so they are always visible if they exists.
    if (allFields.includes("roleLevel")) {
      visibleFields.push("roleLevel");
    }

    if (allFields.includes("lockRights")) {
      visibleFields.push("lockRights");
    }

    // Add the rest of the visible fields up to the limit.
    for (const field of allFields) {
      this.addVisibleField(field, allFields, visibleFields);
    }
    return { visibleFields, allFields };
  }

  private addVisibleField(field: UserAccountChangeRecordField, allFields: UserAccountChangeRecordField[], visibleFields: UserAccountChangeRecordField[]): void {
    // Do nothing if the field is already visible or the limit is reached.
    if (this.isFieldAddable(field, visibleFields) === false) {
      return;
    }

    switch (field) {
      case "addedLocationRights":
      case "removedLocationRights":
        const locationRightsChanges = this.filterFields(allFields, "addedLocationRights", "removedLocationRights");
        this.addIfSpace(visibleFields, locationRightsChanges);
        break;
      case "addedReceiverTeams":
      case "removedReceiverTeams":
        const receiverTeamsChanges = this.filterFields(allFields, "addedReceiverTeams", "removedReceiverTeams");
        this.addIfSpace(visibleFields, receiverTeamsChanges);
        break;
      case "addedAlarmRoutes":
      case "removedAlarmRoutes":
        const alarmRoutesChanges = this.filterFields(allFields, "addedAlarmRoutes", "removedAlarmRoutes");
        this.addIfSpace(visibleFields, alarmRoutesChanges);
        break;
      default:
        visibleFields.push(field);
        break;
    }
  }

  private isFieldAddable(field: UserAccountChangeRecordField, visibleFields: UserAccountChangeRecordField[]): boolean {
    if (visibleFields.length >= this.visibleFieldCount || visibleFields.includes(field)) {
      return false;
    }
    return true;
  }

  private filterFields(allFields: UserAccountChangeRecordField[], field1: UserAccountChangeRecordField, field2: UserAccountChangeRecordField): UserAccountChangeRecordField[] {
    return allFields.filter(f => f === field1 || f === field2);
  }

  private addIfSpace(visibleFields: UserAccountChangeRecordField[], fieldsToAdd: UserAccountChangeRecordField[]): void {
    // Add the fields if there is enough space.
    if (visibleFields.length >= this.visibleFieldCount - fieldsToAdd.length) {
      return;
    }
    fieldsToAdd.forEach(f => visibleFields.push(f));
  }
}

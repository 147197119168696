import { Component, OnDestroy, OnInit } from '@angular/core';

import { AlarmService } from 'app/services/alarm.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, noop, Subscription } from 'rxjs';
import { IAlarmFormFields, IAlarmReason, IAlarmReasonCategory, ICategoryFormFields, IDeleteAlarmReason, IDeleteAlarmReasonCategory, IDragAndDropChangedCategory, IFormValue, ITableAlarmReason, ITableAlarmsAndCategoryNames } from 'app/models/alarm-reasons.model'
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropListGroup, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { map, take } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { ThrottleButtonClickDirective } from '../../directives/throttle-button-click.directive';
import { NgIf, NgFor, NgStyle, NgClass } from '@angular/common';

@Component({
    selector: 'app-alarm-reasons',
    templateUrl: './alarm-reasons.component.html',
    styleUrls: ['./alarm-reasons.component.css'],
    standalone: true,
    imports: [NgIf, ThrottleButtonClickDirective, CdkDropListGroup, NgFor, MatExpansionModule, NgStyle, CdkDropList, CdkDrag, CdkDragHandle, FormsModule, ReactiveFormsModule, NgClass, MatButtonModule, MatTooltipModule, TranslateModule]
})
export class AlarmReasonsComponent implements OnInit, OnDestroy {

  constructor(
    private fb: UntypedFormBuilder,
    private alarmService: AlarmService,
    private _snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  showEditForm = false;

  columnLanguages = ['English', 'Finnish', 'Swedish', 'Estonian']
  editAlarms: UntypedFormGroup;
  alarmsSub = new Subscription();
  formSub = new Subscription();
  extractedAlarms = new Subscription();
  saveAlarmReasonsSub: Subscription;
  savingFailedSub: Subscription;
  alarms: IAlarmReasonCategory[] = [];
  language: string;
  sortingIndex: number;
  arrayOfTableData: ITableAlarmsAndCategoryNames[] = []
  categoryNameTranslated: string;
  alarmNameTranslated: string;
  // ^[a-zA-Z0-9à-üÀ-Ü]+[a-zA-Z0-9à-üÀ-Ü\s/\//().,'`-]*$
  nameScandinavianPattern = "^[a-zA-ZäÄöÖåÅ\\s\\/':\\-,]*$"
  nameEnglishPattern = "^[a-zA-Z\\s\\/':\\-,]*$"

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  translated_SAVING_FAILED: string;

  tableDataSourceSorted$ = combineLatest([
    this.alarmService.alarmReasonsSource$,
    this.alarmService.sortingIndex$
  ]).pipe(
    map(([alarms, sortingIndex]) => {
      this.sortingIndex = sortingIndex
      this.arrayOfTableData = [];

      // modify alarms for showing in the table
      let returnedArray = this.modifyAlarms(alarms, sortingIndex, this.arrayOfTableData)

      this.sortArray(returnedArray, 'categoryNames', sortingIndex)
      return returnedArray
    })
  ).subscribe(
    () => this.createForm()
  );

  sortArray(arrayToSort, arrayOfFields, sortingIndex: number) {
    arrayToSort.sort((a, b) => (a[arrayOfFields][sortingIndex] > b[arrayOfFields][sortingIndex] ? 1 : -1))
  }

  extractCategoryNames(category) {
    let categoryNames: string[] = []
    categoryNames.push(category.alarmReasonCategory_EN);
    categoryNames.push(category.alarmReasonCategory_FI);
    categoryNames.push(category.alarmReasonCategory_SE);
    categoryNames.push(category.alarmReasonCategory_EE);
    return categoryNames
  }

  extractAlarmNames(item) {
    let alarmNames = [];
    alarmNames.push(item.alarmReason_EN);
    alarmNames.push(item.alarmReason_FI);
    alarmNames.push(item.alarmReason_SE);
    alarmNames.push(item.alarmReason_EE);
    return alarmNames
  }

  modifyAlarms(alarms, sortingIndex, arrayOfTableData) {

    alarms.map((category: IAlarmReasonCategory) => {
      let categoryNames = this.extractCategoryNames(category)

      let tableAlarms = []
      category.alarmReasons.forEach(
        (item: IAlarmReason) => {

          let alarmNames = this.extractAlarmNames(item);

          let alarmPosWithNames: ITableAlarmReason = {
            alarmReasonCategoryID: item.alarmReasonCategoryID,
            alarmReasonID: item.alarmReasonID,
            dictionaryEntryKey: item.dictionaryEntryKey,
            names: alarmNames,
            positionIndex: item.positionIndex
          };

          tableAlarms.push(alarmPosWithNames)
        }
      )

      this.sortArray(tableAlarms, 'names', sortingIndex)

      let alarmsAndCategoryNames: ITableAlarmsAndCategoryNames = {
        categoryNames: categoryNames,
        alarms: tableAlarms,
        positionIndex: category.positionIndex,
        categoryID: category.alarmReasonCategoryID
      }

      arrayOfTableData.push(alarmsAndCategoryNames)
    })
    return arrayOfTableData
  }

  ngOnInit(): void {
    this.alarmService.getAlarmReasons()

    this.savingFailedSub = this.translate.get("SAVING_FAILED").subscribe(
      data => this.translated_SAVING_FAILED = data
    )

    this.language = localStorage.getItem('language')
    this.sortingIndex = this.getSortingNameIndex(this.language)
    this.alarmService.setSortingIndex(this.sortingIndex)


    this.alarmsSub = this.alarmService.alarmReasonsSource$.subscribe(
      alarms => {
        this.alarms = alarms
      }
    )

  }

  getSortingNameIndex(language: string): number {
    let sortingNameIndex: number
    switch (language) {
      case 'en':
        sortingNameIndex = 0;
        break;
      case 'fi':
        sortingNameIndex = 1;
        break;
      case 'sv':
        sortingNameIndex = 2;
        break;
      case 'ee':
        sortingNameIndex = 3;
        break;
      default:
        sortingNameIndex = 0;
    }
    return sortingNameIndex;
  }

  resetSortingIndex(index: number) {
    this.alarmService.setSortingIndex(index)
  }

  // make grag and drop possible
  drop(event: CdkDragDrop<ITableAlarmReason[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.previousContainer.data, event.previousIndex, event.currentIndex);
      this.afterDropSameCat(event)
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.afterDropDifCat(event)
    }
  }

  // rewrite dropped alarm reasons category ID and position index
  getChangedAlarms(data: ITableAlarmReason[], catID: number): IAlarmReason[] {
    let changedAlarms: IAlarmReason[];
    changedAlarms = data.map(
      (item, index) => {
        let changedAlarm: IAlarmReason = {
          alarmReasonID: item.alarmReasonID,
          dictionaryEntryKey: item.dictionaryEntryKey,
          positionIndex: (index + 1) * 100,
          alarmReasonCategoryID: catID,
          alarmReason_EN: item.names[0],
          alarmReason_FI: item.names[1],
          alarmReason_SE: item.names[2],
          alarmReason_NB: item.names[2],
          alarmReason_EE: item.names[3],
          isChecked: false
        }
        return changedAlarm
      }
    )
    return changedAlarms
  }

  // update two categories(they corresponds to containers):
  // one the alarm reason was dragged from
  // and another the alarm reason was dropped into
  // AND save changes into service
  afterDropDifCat(event: CdkDragDrop<ITableAlarmReason[]>): void {
    let donorCategoryId: number = +event.previousContainer.id

    let donorCategory: IDragAndDropChangedCategory = {
      categoryId: donorCategoryId,
      alarms: this.getChangedAlarms(event.previousContainer.data, donorCategoryId)
    }

    let recepientCategory: IDragAndDropChangedCategory = {
      categoryId: +event.container.id,
      alarms: this.getChangedAlarms(event.container.data, +event.container.id)
    }

    let updatedAlarms = this.getAlarmsUpdated(donorCategory, recepientCategory)

    this.alarmService.saveAlarmReasons(updatedAlarms)
  }

  // update the category (which corresponds to the container)
  // where alarm reason position was changed
  // AND save changes into service
  afterDropSameCat(event: CdkDragDrop<ITableAlarmReason[]>): void {
    let changedCategory: IDragAndDropChangedCategory = {
      categoryId: +event.previousContainer.id,
      alarms: this.getChangedAlarms(event.previousContainer.data, +event.previousContainer.id)
    }

    let updatedAlarms = this.getAlarmsUpdated(changedCategory)

    this.alarmService.saveAlarmReasons(updatedAlarms)
  }

  // update the whole category list
  getAlarmsUpdated(donorCategory: IDragAndDropChangedCategory, recepientCategory?: IDragAndDropChangedCategory): IAlarmReasonCategory[] {
    let updatedAlarms: IAlarmReasonCategory[] = this.alarms.map(
      category => {
        if (category.alarmReasonCategoryID === donorCategory.categoryId) {
          category.alarmReasons = donorCategory.alarms
        }
        if (recepientCategory) {
          if (category.alarmReasonCategoryID === recepientCategory.categoryId) {
            category.alarmReasons = recepientCategory.alarms
          }
        }
        return category
      }
    )
    return updatedAlarms;
  }

  createForm(): void {
    this.editAlarms = this.fb.group({
      categories: this.fb.array([
        ...this.buildCategoryFormFields()
      ])
    })
  }

  // build category form fields
  // based on data provided from the service
  buildCategoryFormFields(): UntypedFormGroup[] {
    const formGroups: UntypedFormGroup[] = [];
    this.formSub = this.alarmService.alarmReasonsSource$.pipe(
      map(unsortedAlarms => {
        [this.categoryNameTranslated, this.alarmNameTranslated] = this.alarmService.getAlarmsTranslated(this.language);

        let sortedAlarms = this.alarmService.sortAlphabetically(unsortedAlarms, this.categoryNameTranslated, this.alarmNameTranslated)
        return sortedAlarms
      }),
      map(
        alarms => alarms.map(
          category => {
            let categoryFormFields: ICategoryFormFields = {
              // ...category,
              alarmReasonCategoryID: category.alarmReasonCategoryID,
              dictionaryEntryKey: category.dictionaryEntryKey,
              positionIndex: category.positionIndex,
              alarmReasonCategory_EN: [category.alarmReasonCategory_EN, [Validators.required, Validators.pattern(this.nameEnglishPattern)]],
              alarmReasonCategory_FI: [category.alarmReasonCategory_FI, Validators.pattern(this.nameScandinavianPattern)],
              alarmReasonCategory_SE: [category.alarmReasonCategory_SE, Validators.pattern(this.nameScandinavianPattern)],
              alarmReasonCategory_NB: category.alarmReasonCategory_NB,
              alarmReasonCategory_EE: [category.alarmReasonCategory_EE, Validators.pattern(this.nameScandinavianPattern)],
              alarmReasons: this.fb.array([
                ...this.buildAlarmFormFields(category.alarmReasonCategoryID)
              ])
            }
            formGroups.push(this.fb.group(categoryFormFields))
          }
        )
      )
    ).subscribe()
    return formGroups
  }

  // build alarm form fields
  // based on data provided from the service
  buildAlarmFormFields(categoryID: number): UntypedFormGroup[] {
    const formGroups: UntypedFormGroup[] = [];
    this.extractCategoryAlarms(categoryID).forEach(alarm => {
      let alarmFormFields: IAlarmFormFields = {
        alarmReasonCategoryID: alarm.alarmReasonCategoryID,
        alarmReasonID: alarm.alarmReasonID,
        dictionaryEntryKey: alarm.dictionaryEntryKey,
        positionIndex: alarm.positionIndex,
        alarmReason_EN: [alarm.alarmReason_EN, [Validators.required, Validators.pattern(this.nameEnglishPattern)]],
        alarmReason_FI: [alarm.alarmReason_FI, Validators.pattern(this.nameScandinavianPattern)],
        alarmReason_SE: [alarm.alarmReason_SE, Validators.pattern(this.nameScandinavianPattern)],
        alarmReason_NB: alarm.alarmReason_NB,
        alarmReason_EE: [alarm.alarmReason_EE, Validators.pattern(this.nameScandinavianPattern)],
        isChecked: alarm.isChecked
      };
      formGroups.push(
        this.fb.group(alarmFormFields)
      )
    })
    return formGroups;
  }

  // get an array of alarms from the given category
  // from data provided
  extractCategoryAlarms(categoryID: number): IAlarmReason[] {
    let alarmReasons: IAlarmReason[] = [];
    this.extractedAlarms = this.alarmService.alarmReasonsSource$.pipe(
      map(unsortedAlarms => {
        [this.categoryNameTranslated, this.alarmNameTranslated] = this.alarmService.getAlarmsTranslated(this.language);

        let sortedAlarms = this.alarmService.sortAlphabetically(unsortedAlarms, this.categoryNameTranslated, this.alarmNameTranslated)
        return sortedAlarms
      }),
      map(
        alarms => alarms.map(
          category => category.alarmReasonCategoryID === categoryID ? alarmReasons = [...category.alarmReasons] : null
        )
      )
    ).subscribe()
    return alarmReasons
  }

  // --------processing categories
  getCategoryArray(): UntypedFormArray {
    // console.log("categories: ", this.editAlarms.get('categories'))
    return <UntypedFormArray>this.editAlarms.get('categories')
  }

  addCategory(): void {
    const alarmCategories = this.getCategoryArray();
    alarmCategories.push(this.initCategory())
    // console.log('new cat!')
  }

  removeCategory(categoryIndex: number): void {
    const alarmCategories = this.getCategoryArray();
    let categoryToBeDeleted: IDeleteAlarmReasonCategory;
    alarmCategories.controls.forEach((formGroup, index) => {
      if (categoryIndex === index) {
        categoryToBeDeleted = {
          dictionaryEntryKey: (<UntypedFormGroup>formGroup).controls['dictionaryEntryKey'].value,
          alarmReasonCategoryID: (<UntypedFormGroup>formGroup).controls['alarmReasonCategoryID'].value
        }
        // console.log((<FormGroup>formGroup).controls['dictionaryEntryKey'].value)
        // console.log((<FormGroup>formGroup).controls['alarmReasonCategoryID'].value)
      }
    })
    alarmCategories.removeAt(categoryIndex);
    this.alarmService.deleteAlarmReasonCategory(categoryToBeDeleted);
    // this.alarmService.getAlarmReasons();
  }

  // create a new category
  initCategory(): UntypedFormGroup {
    let categoryTemplate: ICategoryFormFields = {
      alarmReasonCategoryID: null,
      dictionaryEntryKey: null,
      positionIndex: 100,
      alarmReasonCategory_EN: ['Category Name In English', [Validators.required, Validators.pattern(this.nameEnglishPattern)]],
      alarmReasonCategory_FI: ['kategorian nimi suomeksi', Validators.pattern(this.nameScandinavianPattern)],
      alarmReasonCategory_SE: ['kategorinamn på svenska', Validators.pattern(this.nameScandinavianPattern)],
      alarmReasonCategory_NB: 'kategorinamn på norska',
      alarmReasonCategory_EE: ['eestikeelne kategooria nimi', Validators.pattern(this.nameScandinavianPattern)],
      alarmReasons: this.fb.array([
        this.initAlarmReason(null)
      ])
    }
    return this.fb.group(categoryTemplate)
  }

  // ------------processing alarm reasons
  getAlarmReasonsArray(categoryIndex: number): UntypedFormArray {
    return <UntypedFormArray>this.getCategoryArray().controls[categoryIndex].get('alarmReasons');
  }

  addAlarm(categoryIndex): void {
    const alarmCategories = this.getCategoryArray();
    let categoryID: number;
    alarmCategories.controls.forEach((cat, index) => {
      if (categoryIndex === index) {
        // console.log("cat", (<FormGroup>cat).controls['alarmReasonCategoryID'].value)
        categoryID = +(<UntypedFormGroup>cat).controls['alarmReasonCategoryID'].value
      }
    })
    const alarms = this.getAlarmReasonsArray(categoryIndex);
    // console.log(alarms)
    // console.log((<FormGroup>alarms?.controls[0])?.controls['alarmReasonCategoryID'].value)
    alarms.push(this.initAlarmReason(categoryID));
  }

  removeAlarm(categoryIndex: number, alarmIndex: number): void {
    const alarms = this.getAlarmReasonsArray(categoryIndex);

    let alarmReasonToBeDeleted: IDeleteAlarmReason;
    alarms.controls.forEach((formGroup, index) => {
      if (alarmIndex === index) {
        alarmReasonToBeDeleted = {
          dictionaryEntryKey: (<UntypedFormGroup>formGroup).controls['dictionaryEntryKey'].value,
          alarmReasonCategoryID: (<UntypedFormGroup>formGroup).controls['alarmReasonCategoryID'].value,
          alarmReasonID: (<UntypedFormGroup>formGroup).controls['alarmReasonID'].value
        }
        // console.log('dictionaryEntryKey: ', (<FormGroup>formGroup).controls['dictionaryEntryKey'].value)
        // console.log('alarmReasonCategoryID: ', (<FormGroup>formGroup).controls['alarmReasonCategoryID'].value)
        // console.log('alarmReasonID: ', (<FormGroup>formGroup).controls['alarmReasonID'].value)
      }
    })
    // console.log(alarmReasonToBeDeleted)
    if (alarmReasonToBeDeleted['dictionaryEntryKey'] != null && alarmReasonToBeDeleted['alarmReasonID'] != null) {
      this.alarmService.deleteAlarmReason(alarmReasonToBeDeleted);
    }
    alarms.removeAt(alarmIndex);
    // this.alarmService.getAlarmReasons();
  }

  // create a new alarm reason
  initAlarmReason(categoryID: number): UntypedFormGroup {
    let alarmTemplate: IAlarmFormFields = {
      alarmReasonCategoryID: categoryID,
      alarmReasonID: null,
      dictionaryEntryKey: null,
      positionIndex: 100,
      alarmReason_EN: ['English', [Validators.required, Validators.pattern(this.nameEnglishPattern)]],
      alarmReason_FI: ['suomi', Validators.pattern(this.nameScandinavianPattern)],
      alarmReason_SE: ['svenska', Validators.pattern(this.nameScandinavianPattern)],
      alarmReason_NB: 'norska',
      alarmReason_EE: ['eesti', Validators.pattern(this.nameScandinavianPattern)],
      isChecked: false
    }
    return this.fb.group(alarmTemplate)
  }

  onSubmit(): void {
    let formValue: IFormValue = this.editAlarms.value;
    let dataInProperFormat = this.formatFormData(formValue);
    this.saveAlarmReasonsSub = this.alarmService.saveAlarmReasons(dataInProperFormat)
      .pipe(
        take(1)
      )
      .subscribe(
        // update UI if saving into database was successful
        success => this.alarmService.getAlarmReasons(),
        // show notification if saving failed
        error => this.openSnackBar(this.translated_SAVING_FAILED, ""),
        noop
      );
    this.showEditForm = false
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['savingFailed']
    });
  }

  onExitForm() {
    this.showEditForm = false
    this.createForm()
  }

  // take categories from the form and possible change position index of the category
  formatFormData(data: IFormValue): IAlarmReasonCategory[] {
    let updatedAlarmReasons: IAlarmReasonCategory[] = [];
    data.categories.forEach((category, index) => {
      let updatedCategory: IAlarmReasonCategory = {
        alarmReasonCategoryID: category.alarmReasonCategoryID,
        dictionaryEntryKey: category.dictionaryEntryKey,
        alarmReasonCategory_EN: category.alarmReasonCategory_EN,
        alarmReasonCategory_FI: category.alarmReasonCategory_FI,
        alarmReasonCategory_SE: category.alarmReasonCategory_SE,
        alarmReasonCategory_NB: category.alarmReasonCategory_NB,
        alarmReasonCategory_EE: category.alarmReasonCategory_EE,
        alarmReasons: category.alarmReasons as unknown as IAlarmReason[],
        // ...category,
        positionIndex: (index + 1) * 100
      }
      updatedAlarmReasons.push(updatedCategory)
    })
    return updatedAlarmReasons
  }

  ngOnDestroy(): void {
    this.alarmsSub.unsubscribe();
    this.formSub?.unsubscribe();
    this.extractedAlarms.unsubscribe();
    this.savingFailedSub.unsubscribe();
  }
}

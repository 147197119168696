<div class="card stickyCard">
    <div class="card-header-white header-underline">
        <h6 class="white-header-text">
            {{ team.groupName }} ({{ team.groupId }})
        </h6>
        <span
            class="float-right cursorPointer white-header-xmark-icon"
            (click)="closeEditForm()"
            ><span class="fa-light fa-xmark"></span
        ></span>
    </div>

    <div class="card-body-white">
        <div [formGroup]="teamEditForm">
            <div
                class="mb-2"
                *ngIf="!team?.locationId && featureEnabled('DigitalKey')"
            >
                <ul class="list-inline">
                    <li class="list-inline-item">
                        <span
                            class="editPageTab"
                            [ngClass]="{
                                'editPageTab-active': activeTab === 1
                            }"
                            (click)="activeTab = 1"
                            >{{ "INFORMATION" | translate }}</span
                        >
                    </li>
                    <li class="list-inline-item">
                        <span
                            class="editPageTab"
                            [ngClass]="{
                                'editPageTab-active': activeTab === 2
                            }"
                            (click)="activeTab = 2"
                            >{{ "LOCK_ACCESS_RIGHTS" | translate }}</span
                        >
                    </li>
                </ul>
            </div>
            <team-lock-access
                *ngIf="activeTab === 2"
                [locations]="allLocations"
                [team]="team"
            ></team-lock-access>
            <div *ngIf="activeTab === 1">
                <div class="form-group">
                    <label
                        ><strong>{{ "NAME" | translate }}</strong></label
                    >
                    <input
                        class="form-control"
                        type="text"
                        placeholder="{{ team.groupName }}"
                        formControlName="groupName"
                    />
                </div>
                <div *ngIf="team?.locationName" class="form-group">
                    <strong>{{ "LOCATION" | translate }}:</strong>
                    {{ team?.locationName }} ({{ team?.locationId }})
                </div>
                <div class="form-group">
                    <mat-slide-toggle formControlName="teamLocked"
                        >{{ "LOCKED" | translate }}
                        <span
                            matTooltip="{{ 'TOOLTIP_TEAM_LOCKED' | translate }}"
                            class="fa fa-fw fa-info-circle"
                        ></span
                    ></mat-slide-toggle>

                    <mat-slide-toggle
                        *ngIf="user.roleLevel >= 200"
                        formControlName="teamHidden"
                        class="ml-3"
                        >{{ "HIDDEN" | translate }}
                        <span
                            matTooltip="{{ 'TOOLTIP_TEAM_HIDDEN' | translate }}"
                            class="fa fa-fw fa-info-circle"
                        ></span
                    ></mat-slide-toggle>
                </div>
                <div class="form-group">
                    <label class="d-flex justify-content-between rightMargin"
                        ><strong>{{ "STAFF_MEMBERS" | translate }}</strong>
                        <mat-checkbox
                            (click)="toggleAllSelection()"
                            [labelPosition]="'before'"
                        >
                            <strong>{{ "SELECT_ALL" | translate }}</strong>
                        </mat-checkbox>
                    </label>
                    <mat-selection-list
                        #teamMembers
                        formControlName="selectedTeamMembers"
                        class="staff-container"
                        dense="true"
                    >
                        <mat-list-option
                            *ngFor="let staff of availableStaffForCompany"
                            [value]="staff.id"
                        >
                            {{ staff.fullName }}
                        </mat-list-option>
                        <span
                            *ngIf="
                                availableStaffForLocation &&
                                availableStaffForLocation.length > 0
                            "
                            matSubHeader
                            >{{ "LOCATION_MEMBERS" | translate }}</span
                        >
                        <mat-list-option
                            *ngFor="let staff of availableStaffForLocation"
                            [value]="staff.id"
                        >
                            {{ staff.fullName }}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
                <div class="form-group" *ngIf="!deleteConfirmation">
                    <button
                        [disabled]="teamEditForm.invalid"
                        type="submit"
                        (click)="editTeam()"
                        class="btn btn-outline-primary gray-bg mr-1"
                    >
                        <span class="fa-light fa-floppy-disk"></span>
                        {{ "SAVE" | translate }}
                    </button>
                    <button
                        type="reset"
                        class="btn btn-outline-danger"
                        (click)="closeEditForm()"
                    >
                        <span class="fa-light fa-xmark"></span>
                        {{ "CANCEL" | translate }}
                    </button>
                    <span
                        *ngIf="user.roleLevel >= 100"
                        class="btn btn-outline-danger float-right cursorPointer"
                        (click)="deleteConfirmation = true"
                        ><span class="fa-light fa-trash-alt"></span>
                        {{ "DELETE" | translate }}</span
                    >

                    <div
                        class="alert alert-danger mt-2"
                        *ngIf="editTeamFormError"
                    >
                        {{ editTeamFormError }}
                    </div>
                </div>

                <div class="form-group" *ngIf="deleteConfirmation">
                    <p>
                        {{
                            "GROUP_DELETE_CONFIRMATION"
                                | translate : { groupname: team.groupName }
                        }}
                    </p>
                    <span
                        class="btn btn-outline-danger cursorPointer"
                        (click)="deleteTeam()"
                        ><span class="fa-light fa-trash-alt"></span>
                        {{ "DELETE" | translate }}</span
                    >
                    <span
                        class="btn btn-outline-primary gray-bg ml-2 cursorPointer"
                        (click)="deleteConfirmation = false"
                        ><span class="fa-light fa-xmark"></span>
                        {{ "CANCEL" | translate }}</span
                    >
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card monitor" *ngIf="cameraGroup.state">
    <div class="card-header">
        <strong>{{cameraGroup.name}}</strong>
        <div class="float-right">
            <span class="fa-light fa-fw fa-lg fa-id-badge cursorPointer" (click)="editProfile()"
                *ngIf="user.roleLevel >= 100 || user.roleLevel === 40"></span>
            <a class="ml-3"
                *ngIf="cameraGroup.id && cameraGroup.cameras && (user.roleLevel >= 100 || user.roleLevel === 40)"
                [routerLink]="'zones/'+cameraGroup.id"><span class="fa-light fa-fw fa-lg fa-vector-square"></span></a>
        </div>
        <div class="mt-1 mb-1">
            <div *ngIf="!profileEdit">
                <span *ngIf="cameraGroup.activeProfile">{{cameraGroup.activeProfile.name}}</span>
            </div>
            <div *ngIf="profileEdit" class="form mt-2">
                <div class="form-group">
                    <mat-form-field class="selector-full-width">
                        <mat-label>{{'DEFAULT_PROFILE' |translate}}</mat-label>
                        <mat-select [(ngModel)]="selectedDefaultProfile">
                            <mat-option *ngFor="let profile of profiles" [value]="profile">
                                {{profile.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="selector-full-width">
                        <mat-label>{{'SCHEDULED_PROFILE' |translate}}</mat-label>
                        <mat-select [(ngModel)]="selectedScheduledProfile">
                            <mat-option [value]=null>{{'DISABLED' |translate}}</mat-option>
                            <mat-option *ngFor="let profile of profiles" [value]="profile">
                                {{profile.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-row" *ngIf="selectedScheduledProfile">
                    <div class="form-group col-xl-6">
                        <label>{{'REPORT_START_TIME' |translate}}</label>
                        <input type="text" class="form-control" [(ngModel)]="selectedScheduledProfileStartTime">
                    </div>
                    <div class="form-group col-xl-6">
                        <label>{{'REPORT_END_TIME' |translate}}</label>
                        <input type="text" class="form-control" [(ngModel)]="selectedScheduledProfileStopTime">
                    </div>
                </div>
                <div class="form-group">
                    <button (click)="saveProfile()" class="btn btn-outline-primary mr-2">{{'SAVE' |translate}}</button>
                    <button (click)="editProfile()" class="btn btn-outline-danger">{{'CANCEL' |translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div *ngIf="cameraGroup.state.state !== 'disabled'">
            <div class="row mb-1">
                <div class="float-left ml-3 mt-3" *ngIf="cameraGroup.alert">
                    <span class="fa-solid fa-3x fa-exclamation text-danger"></span>
                </div>
                <div class="float-left ml-3">
                    <img [src]="getIconPath(cameraGroup.state.state)" class="apStateIcon" alt=""
                        *ngIf="cameraGroup.state.state">
                </div>
                <div class="float-left ml-3">
                    <strong>{{getIconText()}}</strong><br>
                    <span *ngIf="cameraGroup.state.state != 'unknown'"><span
                            *ngIf="this.cameraGroup.state.time && this.cameraGroup.state.time !== '0001-01-01T00:00:00Z'">{{'STARTED'|translate}}:
                            {{cameraGroup.state.time |moment: 'LT'}} <br>({{calculateStateDuration()}})</span></span>
                </div>
            </div>
            <!-- Show notification if cameraGroup has an active alert -->
            <div *ngIf="cameraGroup.alerts" class="mt-2">
                <div class="alert alertActive alert-danger"
                    [ngClass]="{'alert-danger' : !alert.taskStartedBy, 'alert-success': alert.taskStartedBy}"
                    *ngFor="let alert of cameraGroup.alerts">
                    <span class="fa-light fa-fw fa-bell"></span> {{alert.type |translate}}
                </div>
            </div>
        </div>
    </div>
</div>
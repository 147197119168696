<div class="card">
    <h4 class="mainDarkBlueText normalFontWeight" id="header">
        {{ "ALERTS_PER_ALERT_TYPE" | translate }}
    </h4>

    <div class="cardContent">
        <div class="row" id="tableHeaders">
            <div id="alertTypeHeader">
                <div class="d-flex">
                    <p class="mr-2 mainDarkBlueText">
                        {{ "ALERT_TYPE" | translate }}
                    </p>
                    <div class="cursorPointer" (click)="sort('alertType')">
                        <i
                            class="fa-solid fa-caret-up caretUp"
                            *ngIf="
                                (sorter === 'alertType' && sortOrder === 1) ||
                                sortOrder === 0 ||
                                sorter !== 'alertType'
                            "
                        ></i>
                        <i
                            class="fa-solid fa-caret-down caretDown"
                            *ngIf="
                                (sorter === 'alertType' && sortOrder === -1) ||
                                sortOrder === 0 ||
                                sorter !== 'alertType'
                            "
                        ></i>
                    </div>
                </div>
            </div>

            <div id="alertsHeader">
                <div class="d-flex">
                    <p class="mr-2 mainDarkBlueText">
                        {{ "ALERTS_WITH_FIRST_LETTER_UPPERCASE" | translate }}
                    </p>
                    <div class="cursorPointer" (click)="sort('alerts')">
                        <i
                            class="fa-solid fa-caret-up caretUp"
                            *ngIf="
                                (sorter === 'alerts' && sortOrder === 1) ||
                                sortOrder === 0 ||
                                sorter !== 'alerts'
                            "
                        ></i>
                        <i
                            class="fa-solid fa-caret-down caretDown"
                            *ngIf="
                                (sorter === 'alerts' && sortOrder === -1) ||
                                sortOrder === 0 ||
                                sorter !== 'alerts'
                            "
                        ></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="overline p-2" id="overline">
            <div
                *ngFor="
                    let alert of listedAlerts
                        | slice : 0 : collapseStateDeviceTypeCount;
                    let first = first;
                    let last = last
                "
                [ngClass]="{ lastCollapsedListItem: last }"
            >
                <div class="alertRow" [ngClass]="{ alertsRowSpacing: !first }">
                    <div class="deviceTypeColumn">
                        <p class="mainDarkBlueText semiBold">
                            {{ alert.deviceType | deviceType }}
                        </p>
                    </div>
                    <div class="alertCountColumn">
                        <div
                            class="alertsContainer"
                            [ngStyle]="alertsContainerStyles"
                        >
                            <p class="alertsCount mainDarkBlueText semiBold">
                                {{ alert.totalAlerts }}
                            </p>
                            <p class="mainDarkBlueText semiBold ml-1">
                                {{ "ALERTS" | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    class="alertRow alertsRowSpacing"
                    *ngFor="let activation of alert.activations"
                >
                    <div class="alertTypeColumn">
                        <p class="mainDarkBlueText">
                            {{
                                alert.deviceType
                                    | alertType
                                        : activation.group
                                        : activation.node
                            }}
                        </p>
                    </div>
                    <div class="alertCountColumn">
                        <div
                            class="alertsContainer"
                            [ngStyle]="alertsContainerStyles"
                        >
                            <p class="alertsCount mainDarkBlueText">
                                {{ activation.alertCount }}
                            </p>
                            <p class="mainDarkBlueText ml-1">
                                {{ "ALERTS" | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div [@expandCollapseDetails]="expandState">
                <div
                    *ngFor="
                        let alert of listedAlerts
                            | slice : collapseStateDeviceTypeCount;
                        let first = first
                    "
                >
                    <div
                        class="alertRow"
                        [ngClass]="{ alertsRowSpacing: !first }"
                    >
                        <div class="deviceTypeColumn">
                            <p class="mainDarkBlueText semiBold">
                                {{ alert.deviceType | deviceType }}
                            </p>
                        </div>
                        <div class="alertCountColumn">
                            <div
                                class="alertsContainer"
                                [ngStyle]="alertsContainerStyles"
                            >
                                <p
                                    class="alertsCount mainDarkBlueText semiBold"
                                >
                                    {{ alert.totalAlerts }}
                                </p>
                                <p class="mainDarkBlueText semiBold ml-1">
                                    {{ "ALERTS" | translate }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        class="alertRow alertsRowSpacing"
                        *ngFor="let activation of alert.activations"
                    >
                        <div class="alertTypeColumn">
                            <p class="mainDarkBlueText">
                                {{
                                    alert.deviceType
                                        | alertType
                                            : activation.group
                                            : activation.node
                                }}
                            </p>
                        </div>
                        <div class="alertCountColumn">
                            <div
                                class="alertsContainer"
                                [ngStyle]="alertsContainerStyles"
                            >
                                <p class="alertsCount mainDarkBlueText">
                                    {{ activation.alertCount }}
                                </p>
                                <p class="mainDarkBlueText ml-1">
                                    {{ "ALERTS" | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="footer">
        <div>
            <button
                class="mainDarkBlueText"
                id="showMoreLessBtn"
                (click)="toggleExpand()"
                *ngIf="maxRows > displayedRows"
            >
                {{
                    expandState === "collapsed"
                        ? ("SHOW_MORE" | translate)
                        : ("SHOW_LESS" | translate)
                }}
            </button>
        </div>

        <div>
            <p class="mainDarkBlueText">
                {{ "SHOWING" | translate }}
                {{
                    (expandState === "collapsed" ? displayedRows : maxRows) +
                        " " +
                        ("OF" | translate) +
                        " " +
                        maxRows
                }}
            </p>
        </div>
    </div>
</div>

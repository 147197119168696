<table
  infiniteScroll
  [infiniteScrollDistance]="0"
  [infiniteScrollThrottle]="500"
  [infiniteScrollDisabled]="infiniteScrollDisabled"
  (scrolled)="loadNextEvents.emit()"
  class="table table-hover tableLatest"
>
  <thead>
    <tr>
      <th></th>
      <th>{{ "TIMESTAMP" | translate }}</th>
      <th>{{ "DEVICE" | translate }}</th>
      <th>{{ "EVENT" | translate }}</th>
      <th>{{ "USER_OR_RECIPIENT" | translate }}</th>
    </tr>
  </thead>

  <tbody>
    <ng-container *ngFor="let row of lockEvents; let e = event; let i = index">
      <tr class="trCollapse" [ngClass]="{ trEven: e }" (click)="expandRow(row, i)">
        <td><span class="fa-light" [ngClass]="!row.expanded ? 'fa-plus' : 'fa-minus'"></span></td>
          <td>{{ row.createdTime | moment : "l LTS" }}</td>
          <td>{{ row.lockName }}</td>
          <td>{{ row.eventType }}</td>
          <td><i class="fa-solid fa-mobile-screen mobileIcon mr-1"></i> {{ row.userName || "-" }}</td>
        </tr>

        <tr *ngIf="row.expanded" [ngClass]="{ trEven: e }">
          <td></td>
          <td class="lockingEventDetailColumn">
            <p><strong>{{ "SERIAL_NUMBER" | translate }}</strong>: {{ row.deviceId }}</p>
            <p><strong>{{ "LOCK_SERIAL_NUMBER" | translate }}</strong>: {{ row.lockId }}</p>
            <p *ngIf="row.lockedDoorTypeId | lockType">
              <strong>{{ "LOCK_TYPE" | translate }}</strong>: {{ row.lockedDoorTypeId | lockType | translate }}
            </p>
            <p *ngIf="row.lockedDoorTypeId | lockCategory">
              <strong>{{ "CATEGORY" | translate }}</strong>: {{ row.lockedDoorTypeId | lockCategory | translate }}
            </p>
          </td>
          <td></td>
          <td></td>
          <td class="lockingEventDetailColumn">
            <p><strong>{{ "PHONE_NUMBER" | translate }}</strong>: {{ row.mobileNumber }}</p>
          </td>
          <td></td>
        </tr>
    </ng-container>
  </tbody>
</table>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ApiService } from 'app/services/api.service';
import * as moment from 'moment-timezone';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'password-change-dialog',
    templateUrl: './password-change-dialog.component.html',
    styleUrls: ['./password-change-dialog.component.css'],
    standalone: true,
    imports: [FormsModule, NgIf, MatDialogModule, TranslateModule]
})
export class PasswordChangeDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PasswordChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService
  ) { }

  enhancedSecurity: boolean = false;
  passwordChangeError: string;
  expiredOldPassword: string;
  expiredNewPassword: string;
  expiredNewPasswordConfirm: string;

  changePassword(): void {
    this.passwordChangeError = "";
    if (this.expiredNewPassword != this.expiredNewPasswordConfirm) {
      this.passwordChangeError = "PASSWORDS_DONT_MATCH";
      return;
    }
    let data: any = {
      oldPassword: this.expiredOldPassword,
      newPassword: this.expiredNewPasswordConfirm
    }
    this.api.changePassword(data).subscribe(res => {
      //Get current user object from localStorage
      let user = JSON.parse(localStorage.getItem('user'));
      //Change new token and password change date into the object
      user.passwordChanged = moment().utc();
      //Set it back into the localStorage
      localStorage.setItem('user', JSON.stringify(user));
      //Close the dialog
      this.dialogRef.close();
    }, err => {
      this.passwordChangeError = "PASSWORD_NOT_VALID";
    })
  }

  ngOnInit() {
    let features = JSON.parse(localStorage.getItem('features'))
    if (features && features.indexOf('EnhancedSecurity') !== -1) {
      return this.enhancedSecurity = true;
    }
  }
}

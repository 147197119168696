import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResidentInfo } from '../../../models/resident.model';
import { Device } from '../../../models/device.model';
import { ViewChild } from '@angular/core';
import { MomentPipe } from '../../../pipes/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor } from '@angular/common';
import { ThrottleButtonClickDirective } from '../../../directives/throttle-button-click.directive';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';

@Component({
    selector: 'resident-info',
    templateUrl: './resident-info.component.html',
    styleUrls: ['./resident-info.component.css'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgxBootstrapMultiselectModule, ThrottleButtonClickDirective, NgIf, NgFor, TranslateModule, MomentPipe]
})
export class ResidentInfoComponent implements OnInit {

  constructor(
    private api: ApiService,
    private fb: UntypedFormBuilder
  ) { }

  @Input('deviceInfo') devicesInfo: [];
  @Input('deviceCount') deviceCount: number;
  @Input('residentInfo') residentInfo: ResidentInfo;
  @Input('devices') devices: Device[];
  @ViewChild('selectedDevice') selectedDevice;

  deviceSelect: any = {
    settings: {
      enableSearch: true,
      dynamicTitleMaxItems: 1,
      checkedStyle: 'fontawesome',
      containerClasses: "fullWidth",
      buttonClasses: "btn btn-outline-dark fullWidth",
      selectionLimit: 1,
      autoUnselect: true,
      closeOnSelect: true
    },
    deviceList: null,
    texts: {
      defaultTitle: ""
    },
    selectedDevice: null
  }

  residentEditForm: UntypedFormGroup;

  //Mock data
  residentName = "Firstname Middlename Lastname";
  personalID = '450521-1234';
  company = "Company 4";
  residentAddress = "Koppargatan 18";
  residentPostalCode = "603 45 Norrköping";
  telNumber = "+4673 099 99 99";
  doorCode = "1234";
  drivingDirections = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sodales eget ipsum a tincidunt. Maecenas in elit et sem molestie hendrerit auctor at purus. Mauris porta vehicula purus, at faucibus turpis consequat non. Phasellus mauris ex, posuere at risus ut, cursus lacinia orci. Pellentesque convallis porta est, nec tristique mauris aliquam luctus.";
  notes = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sodales eget ipsum a tincidunt. Maecenas in elit et sem molestie hendrerit auctor at purus. Mauris porta vehicula purus, at faucibus turpis consequat non. Phasellus mauris ex, posuere at risus ut, cursus lacinia orci.";
  importantInfo = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sodales eget ipsum a tincidunt. Maecenas in elit et sem molestie hendrerit auctor at purus. Mauris porta vehicula purus, at faucibus turpis consequat non. Phasellus mauris ex, posuere at risus ut, cursus lacinia orci.";

  addDevice() {
    const data = { residentId: this.residentInfo.residentId, deviceId: this.selectedDevice.model[0] }
    this.api.setResidentDevice(data).subscribe(res => { }, err => {
      console.log(err);
    });
  }

  ngOnInit(): void {
    this.residentName = this.residentInfo.fName + " " + this.residentInfo.mName + " " + this.residentInfo.lName;
    this.personalID = this.residentInfo.ssnumber;
    this.company = this.residentInfo.company;
    this.residentAddress = this.residentInfo.address;
    this.residentPostalCode = this.residentInfo.zipCode;
    this.telNumber = this.residentInfo.phoneCell;
    this.doorCode = this.residentInfo.doorCode;
    this.drivingDirections = this.residentInfo.drivingDirections;
    this.notes = this.residentInfo.description;
    this.importantInfo = this.residentInfo.importantInformation;

    this.deviceSelect.deviceList = this.devices.map(obj => {
      let a: any = {};
      a.id = obj.id;
      a.name = obj.name;
      return a;
    });

    this.deviceSelect.deviceList.unshift({ id: 0, name: "" });

    this.residentEditForm = new UntypedFormGroup({
      deviceId: new UntypedFormControl()
    });

  }

}

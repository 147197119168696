import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { trigger, transition, style, animate } from "@angular/animations";
import { ICompanyGroup, ICompanyGroupCompany } from "app/models/company.models";
import { ApiService } from "app/services/api.service";
import { NgxBootstrapMultiselectModule, IMultiSelectOption, IMultiSelectTexts, IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { ThrottleButtonClickDirective } from "../../../../../../directives/throttle-button-click.directive";
import { FormsModule } from "@angular/forms";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "company-group-edit-companies",
    templateUrl: "./company-group-edit-companies.component.html",
    styleUrls: ["./company-group-edit-companies.component.css"],
    animations: [
        trigger("fadeOut", [
            transition(":leave", [animate("0.5s ease-out", style({ opacity: 0 }))]),
        ]),
    ],
    standalone: true,
    imports: [NgIf, NgxBootstrapMultiselectModule, FormsModule, NgFor, ThrottleButtonClickDirective, TranslateModule]
})
export class CompanyGroupEditCompaniesComponent implements OnChanges {
  constructor(private api: ApiService, private translate: TranslateService) {}

  @Input() companyGroup: ICompanyGroup;

  addedCompanies: ICompanyGroupCompany[] = [];
  availableCompanies: ICompanyGroupCompany[];

  successMessage: string;
  failureMessage: string;
  msgFadeOutTime: number = 3000;

  companySelect: ICompanySelect = {
    options: [],
    texts: {},
    settings: {
      enableSearch: true,
      showCheckAll: false,
      showUncheckAll: false,
      fixedTitle: true,
      closeOnSelect: false,
      checkedStyle: "checkboxes",
      buttonClasses: "primaryBtn btnText16px ssPlusIcon",
      selectionLimit: 0,
      autoUnselect: false,
      dynamicTitleMaxItems: 0,
      maxHeight: "500px",
      loadViewDistance: 1,
      stopScrollPropagation: true,
      selectAddedValues: true,
      focusBack: false,
    },
  };

  onPopulateStaffChange(company: ICompanyGroupCompany): void {
    if (!company.populateStaff) company.addToAllReceiverGroups = false;
  }

  // NOTE: function removes multiple option, while in the GUI only one company can be removed (=1 option added) at the moment
  addOptions(companies: ICompanyGroupCompany[]): void {
    let temp = this.companySelect.options;
    let temp2: IMultiSelectOption[] = [];
    // Remove ngx-bootstrap-multiselect temporarily from DOM to add new options
    this.companySelect.options = [];
    companies.forEach((company) => {
      // Uncheck the checkmarks
      company.populateStaff = false;
      company.addToAllReceiverGroups = false;
      temp2.push({ id: company, name: company.company });
    });
    temp = [...temp, ...temp2];
    temp.sort((a, b) => a.name.localeCompare(b.name));
    this.companySelect.options = temp;
  }

  removeOptions(companyIdList: string[]): void {
    let temp = this.companySelect.options;
    temp = temp.filter((x) => !companyIdList.includes(x.id.companyId));
    // Empty array temporarily to update option list
    this.companySelect.options = [];
    this.companySelect.options = temp;
  }

  removeCurrentCompany(server: string, companyId: string, index: number): void {
    this.api.removeCompanyFromGroup(server, companyId).subscribe(
      (success: boolean) => {
        if (success) {
          const removedCompany = this.companyGroup.companies.splice(index, 1);
          this.addOptions(removedCompany);
        } else {
          this.setFailureMessage(
            "FAILED_TO_REMOVE_COMPANY_FROM_GROUP",
            this.msgFadeOutTime
          );
        }
      },
      (error) => {
        this.setFailureMessage(
          "FAILED_TO_REMOVE_COMPANY_FROM_GROUP",
          this.msgFadeOutTime
        );
      }
    );
  }

  saveNewCompanies(): void {
    this.api
      .addCompanyToGroup(
        this.addedCompanies,
        this.companyGroup.companyGroupId,
        this.companyGroup.server
      )
      .subscribe(
        (updatedCompanies: ICompanyGroupCompany[]) => {
          if (updatedCompanies) {
            // Add updated companies to the company group
            this.companyGroup.companies = [
              ...this.companyGroup.companies,
              ...updatedCompanies,
            ];
            // Get company IDs of updated companies
            const IDList = updatedCompanies.map((x) => x.companyId);

            // Remove successfully added companies from added companies list
            this.addedCompanies = this.addedCompanies.filter(
              (c) => !IDList.includes(c.companyId)
            );

            // Remove options when added
            this.removeOptions(IDList);

            this.setSuccessMessage(
              "COMPANY_ADDED_TO_GROUP_SUCCESS",
              this.msgFadeOutTime
            );
          } else {
            this.setFailureMessage(
              "FAILED_TO_ADD_COMPANIES_TO_THE_GROUP",
              this.msgFadeOutTime
            );
          }
        },
        (error) => {
          console.log(error);
          this.setFailureMessage(
            "FAILED_TO_ADD_COMPANIES_TO_THE_GROUP",
            this.msgFadeOutTime
          );
        }
      );
  }

  removeAllNewCompanies(): void {
    // Set checkmarks to false(=uncheck) before emptying array
    this.addedCompanies.forEach((x) => {
      x.populateStaff = false;
      x.addToAllReceiverGroups = false;
    });
    this.addedCompanies = [];
  }

  removeNewCompany(index: number): void {
    // Set checkmarks to false(=uncheck) before removing from array
    this.addedCompanies[index].populateStaff = false;
    this.addedCompanies[index].addToAllReceiverGroups = false;

    this.addedCompanies.splice(index, 1);
  }

  initDropDownOptions(companies: ICompanyGroupCompany[]): void {
    let array = [];
    for (let company of companies) {
      company.populateStaff = false;
      company.addToAllReceiverGroups = false;
      array.push({
        id: company,
        name: company.company,
      });
    }
    // Empty the array to temporarily remove multiselect button from the DOM for setting new options
    this.companySelect.options = [];
    this.companySelect.options = array;
  }

  getCompanyOptions(): void {
    this.api
      .getAvailableCompanies(this.companyGroup.server)
      .subscribe((res: ICompanyGroupCompany[]) => {
        this.availableCompanies = res.sort((a, b) =>
          a.company.localeCompare(b.company)
        );
        this.initDropDownOptions(this.availableCompanies);
      });
  }

  setSuccessMessage(message: string, time: number) {
    this.successMessage = message;
    setTimeout(() => {
      this.successMessage = "";
    }, time);
  }

  setFailureMessage(message: string, time: number) {
    this.failureMessage = message;
    setTimeout(() => {
      this.failureMessage = "";
    }, time);
  }

  ngOnChanges(): void {
    this.getCompanyOptions();

    // Get translation for the company select dropdown button
    this.translate
      .get(["SEARCH", "SEARCH_NOT_FOUND", "ADD_COMPANY"])
      .subscribe((t) => {
        this.companySelect.texts = {
          defaultTitle: t.ADD_COMPANY,
          searchPlaceholder: t.SEARCH,
          searchEmptyResult: t.SEARCH_NOT_FOUND,
        };
      });
  }
}

interface ICompanySelect {
  options: IMultiSelectOption[];
  settings: IMultiSelectSettings;
  texts: IMultiSelectTexts;
}

import { GlobalVariables } from 'app/services/helper.service';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ApiService } from "app/services/api.service";
import { CookieService } from "ngx-cookie-service";
import { DeviceCompanyGroup } from "../../models/device.model";
import { TranslateModule } from "@ngx-translate/core";
import { CompanygroupDevicesComponent } from "./devices/devices.component";
import { NewStaffComponent } from "./new-staff/new-staff.component";
import { AllResidentsComponent } from "./all-residents/all-residents.component";
import { FlaggedResidentsComponent } from "./flagged-residents/flagged-residents.component";
import { NewResidentsComponent } from "./new-residents/new-residents.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatOptionModule } from "@angular/material/core";
import { NgFor, NgIf } from "@angular/common";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
    selector: "landing-page",
    templateUrl: "./landing-page.component.html",
    styleUrls: ["./landing-page.component.css"],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, MatTabsModule, NewResidentsComponent, FlaggedResidentsComponent, AllResidentsComponent, NewStaffComponent, CompanygroupDevicesComponent, TranslateModule, RouterModule]
})
export class LandingPageComponent implements OnInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private globals: GlobalVariables,
    private cookies: CookieService
  ) {}

  customerSelectionData: any;
  selectedCustomer: any;
  user: any;

  selectedIndex: number = 0;

  customers = [];

  resAndDevCount: any = {};

  loginIntoCustomer(): void {
    let data = {
      sessionId: this.customerSelectionData.sessionId,
      userId: this.selectedCustomer.userId,
      serverName: this.selectedCustomer.serverName,
      idProvider: this.customerSelectionData.idProvider,
    };

    this.api.loginToFederationCustomer(data).subscribe((res) => {
      //Set the token received from the server to the cookie.

      localStorage.setItem(
        "landingpage-token",
        this.cookies.get("session-token")
      );

      this.cookies.set(
        "session-token",
        "Bearer " + res.token,
        undefined,
        "/",
        undefined,
        false,
        "Lax"
      );
      // Set logged in user data into local storage for later use.
      localStorage.setItem("user", JSON.stringify(res));
      localStorage.setItem("fromLandingPage", "true");

      localStorage.setItem(
        "landingpage-temp",
        localStorage.getItem("landing-page")
      );

      localStorage.removeItem("landing-page");
      this.globals.reportFilters = null; // Remove stored report filters from memory; otherwise, the login site might retain filters from the another previously visited site

      this.router.navigate(["dashboard"]);
    });
  }

  ngOnInit() {

    this.customerSelectionData =
      this.router.getCurrentNavigation()?.extras?.state;

    if (localStorage.getItem("fromLandingPage") == "true") {
      this.router.navigate(["dashboard"]);
    }
    this.customerSelectionData = JSON.parse(
      localStorage.getItem("landing-page")
    );
    this.customerSelectionData.customers.sort((a, b) => a.customerName.localeCompare(b.customerName));
    this.user = this.customerSelectionData.user;
    //    this.api.logoutAuthenticatedUser();

      this.api.getCompaynGroupResidentAndDeviceStatistics().subscribe(
        (res) => {
          this.resAndDevCount = res;

        },
        (err) => {
          console.log(err);
        }
      )


      this.route.queryParams.subscribe((params) => {
        // Check for which tab we are returning
        this.selectedIndex = typeof params.newstaffpage === "undefined" ? 0 : 3;
        if (!this.selectedIndex) {
          this.selectedIndex = typeof params.devicepage === "undefined" ? 0 : 4;
        }
      });
  }

  tabCheckInt: ReturnType<typeof setTimeout>;

  ngAfterViewInit() {
    this.tabCheckInt = setInterval(this.setTabCounter.bind(this), 1);
  }

  setTabCounter(): void {
    if (Object.keys(this.resAndDevCount).length) {
      // If tab missing without 'if'-check, would cause infinite counter-display creation bug.
      if (
        document.getElementsByClassName("mat-tab-label-content")[0]?.innerHTML
          .length
      ) {
        document.getElementsByClassName("mat-tab-label-content")[0].innerHTML =
          document.getElementsByClassName("mat-tab-label-content")[0]
            .innerHTML +
          '&nbsp;<span class="badge tab-count">' +
          this.resAndDevCount.newResidents +
          "</span>";
      }
      if (
        document.getElementsByClassName("mat-tab-label-content")[1]?.innerHTML
          .length
      ) {
        document.getElementsByClassName("mat-tab-label-content")[1].innerHTML =
          document.getElementsByClassName("mat-tab-label-content")[1]
            .innerHTML +
          '&nbsp;<span class="badge tab-count">' +
          this.resAndDevCount.changes +
          "</span>";
      }
      if (
        document.getElementsByClassName("mat-tab-label-content")[2]?.innerHTML
          .length
      ) {
        document.getElementsByClassName("mat-tab-label-content")[2].innerHTML =
          document.getElementsByClassName("mat-tab-label-content")[2]
            .innerHTML +
          '&nbsp;<span class="badge tab-count">' +
          this.resAndDevCount.allResidents +
          "</span>";
      }
      if (
        document.getElementsByClassName("mat-tab-label-content")[3]?.innerHTML
          .length
      ) {
        document.getElementsByClassName("mat-tab-label-content")[3].innerHTML =
          document.getElementsByClassName("mat-tab-label-content")[3]
            .innerHTML +
          '&nbsp;<span class="badge tab-count">' +
          this.resAndDevCount.staff +
          "</span>";
      }
      if (
        document.getElementsByClassName("mat-tab-label-content")[4]?.innerHTML
          .length
      ) {
        document.getElementsByClassName("mat-tab-label-content")[4].innerHTML =
          document.getElementsByClassName("mat-tab-label-content")[4]
            ?.innerHTML +
          '&nbsp;<span class="badge tab-count">' +
          this.resAndDevCount.devices +
          "</span>";
      }

      clearInterval(this.tabCheckInt);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { IAlarmReason, IAlarmReasonCategory, ICategoryAndReasonTranslated, IEarlierCheckedAlarmsGroup, IReasonsGroupedByCategory } from 'app/models/alarm-reasons.model';
import { AlarmService } from 'app/services/alarm.service';
import { CrAlert } from 'app/services/crsync.service';
import { Subscription } from 'rxjs';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-display-alarm-reasons',
    template: `
    <ul *ngFor="let category of checkedAlarms">
      <div><strong>{{category?.categoryName }}</strong></div>
      <li *ngFor="let name of category.alarmNames" class="ml-2">{{name}}</li>
    </ul>
  `,
    styles: [
        `ul {
      list-style-type: none;
      padding-inline-start: 10px;
      margin-top: 0.9rem;
  }`
    ],
    standalone: true,
    imports: [NgFor]
})
export class DisplayAlarmReasonsComponent implements OnInit {

  constructor(
    private alarmService: AlarmService
    ) { }

  checkedAlarms: IReasonsGroupedByCategory[];
  @Input() alert: CrAlert;
  @Input() dataComesFromSocket: boolean;

  checkedAlarmsSub: Subscription;
  fullListOfAlarmReasonsSub: Subscription;

  language: string;
  alarmReasonCategory_LANG: string;
  alarmReason_LANG: string;

  extractReasonBasedOnAlertID(checkedAlarms: IEarlierCheckedAlarmsGroup[], alertID: string){
    const myOwnArray = checkedAlarms.find(alertCard => alertCard.alertId === alertID)
    return myOwnArray?.reasons
  }

  findObjInArrayByKey(array: IAlarmReasonCategory[], key: ICategoryAndReasonTranslated, categoryNameField: string, reasonNameField: string): ICategoryAndReasonTranslated{

    let categoryFound: IAlarmReasonCategory;
    let reasonsFound: IAlarmReason;

    array.forEach(obj => {
      if (obj.dictionaryEntryKey === key.category) {
        categoryFound = obj
      }
    })

    let rawReasons = categoryFound?.alarmReasons

      rawReasons?.forEach(obj => {
      if (obj.dictionaryEntryKey === key.reason) {
        reasonsFound = obj
      }
    })

    return {
      category: categoryFound?.[categoryNameField],
      reason: reasonsFound?.[reasonNameField]
    }
  }

  getAllReasonsTranslated(
          rawData: IAlarmReasonCategory[],
          toTranslate: CrAlert["alarmReasons"],
          categoryNameField: string,
          reasonNameField: string): ICategoryAndReasonTranslated[]
  {
    let translatedItems: ICategoryAndReasonTranslated[] = []
    toTranslate.forEach(item => {

      let translatedItem: ICategoryAndReasonTranslated = this.findObjInArrayByKey(rawData, item, categoryNameField, reasonNameField)
      translatedItems.push(translatedItem)

    })
    return translatedItems
  }

  getArrayWithoutRepeats(arrayWithRepeats: ICategoryAndReasonTranslated[]): ICategoryAndReasonTranslated[]{

    return arrayWithRepeats.reduce(
      (accumulator, current) => {

        let alreadyAdded = accumulator.find(item => {
          if (item.category === current.category && item.reason === current.reason) {
            return true
          }
        })

        if (alreadyAdded) {
          return accumulator
        }
        return [...accumulator, current]

      }, []
    )
  }

  groupReasonsByCategory(separatedReasons: ICategoryAndReasonTranslated[]): IReasonsGroupedByCategory[]{
    let result: IReasonsGroupedByCategory[] = [];
    separatedReasons.forEach(item => {
      // template for rewriting an existing OR creating an absolutely new object for result array
      let itemToAdd: any = {};
      // do an object with current category name exist in the result array?
      let existingObj = result.find(group => group.categoryName === item.category)

      // if object already exists in the result array
      if(existingObj) {

        // fill the new object with existing data
        itemToAdd = {
          categoryName: existingObj.categoryName,
          alarmNames: [...existingObj.alarmNames]
        };

        // and add one more reason (current data)
        itemToAdd.alarmNames.push(item.reason)

        // delete old object form the result array
        // because instead of it a new one will be added with one more reason
        result = result.filter(alreadyAddedGroup => alreadyAddedGroup.categoryName !== item.category)

        // if object does not exist in the result array
      } else {

        // fill a new object with current data
          itemToAdd.categoryName = item.category
          itemToAdd.alarmNames = [item.reason]
      }

      result.push(itemToAdd)
    })
    return result
  }

  translateAlarmReasonsAndFormatForUI(
              alarmReasonsCompleteData: IAlarmReasonCategory[],
              chosenReasons: CrAlert["alarmReasons"],
              categoryNameField: string,
              reasonNameField: string) : IReasonsGroupedByCategory[]
  {
    let arrayWithRepeats = this.getAllReasonsTranslated(alarmReasonsCompleteData, chosenReasons, categoryNameField, reasonNameField)
    let arrayWithoutRepeats = this.getArrayWithoutRepeats(arrayWithRepeats)
    return this.groupReasonsByCategory(arrayWithoutRepeats)
  }

  ngOnInit(): void {

    this.language = localStorage.getItem('language');
    [this.alarmReasonCategory_LANG, this.alarmReason_LANG] = this.alarmService.getAlarmsTranslated(this.language)

    // for forwarded alert card - fetch data from socket
    if(this.dataComesFromSocket) {

      this.fullListOfAlarmReasonsSub = this.alarmService.alarmReasonsSource$.subscribe(
        rawData => {
          this.checkedAlarms = this.translateAlarmReasonsAndFormatForUI(rawData, this.alert.alarmReasons, this.alarmReasonCategory_LANG, this.alarmReason_LANG)
        }
      )

    } else {
    // for started alert card - fetch data temporarily saved in the alarm service
    // because there is no alarm reasons in socket data yet
      this.checkedAlarmsSub = this.alarmService.allStoredAlarmReasons$.subscribe(
        data => {
          this.checkedAlarms = this.extractReasonBasedOnAlertID(data, this.alert.alertId)
        }
      )
    }
  }

  ngOnDestroy(){
    this.checkedAlarmsSub?.unsubscribe();
    this.fullListOfAlarmReasonsSub?.unsubscribe()
  }

}

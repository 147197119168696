
<button class="btn btn-dropdown-sm" style="color: #212529" #button (click)="openDropdown()">
  {{ selected?.length > 0 ? ("SELECTED" | translate) + " " + selected.length : name }}
  <span class="fa-solid fa-caret-down fa-xs ml-3" style="color: #212529"></span>
</button>

<div id="dropdown-menu" #dropdownMenu *ngIf="showDropdownMenu">
  <div id="search">
    <input
      type="text"
      class="form-control form-control-sm"
      [placeholder]="'SEARCH' | translate"
      (input)="search($event)"
    />
  </div>

  <span id="untick-all" (click)="uncheckAll()"><i class="fas fa-xmark fa-lg mr-1"></i>{{ "UNTICK_ALL" | translate }}</span>

  <div *ngIf="options?.length > 0" class="option-list mt-3">
    <section *ngFor="let group of options">
      <span class="d-flex align-items-center">
        <i
          [@caretRotation]="group.collapseState"
          class="fas fa-caret-down category-caret"
          (click)="toggleCollapse(group)"
        ></i>

        <mat-checkbox
          [(ngModel)]="group.allChecked"
          [indeterminate]="someChecked(group)"
          (ngModelChange)="toggleAll(group)"
        ></mat-checkbox>
        <label [innerHTML]="group.name | highlight : searchText"></label>
      </span>

      <ul [@expandCollapseDetails]="group.collapseState">
        <ng-container *ngFor="let option of group.options">
          <li class="d-flex">
            <mat-checkbox [(ngModel)]="option.checked" (ngModelChange)="toggle(group)"></mat-checkbox>
            <label [innerHTML]="option.name | highlight : searchText"></label>
          </li>
        </ng-container>
      </ul>
    </section>
  </div>
</div>

<div class="row">
    <div class="col-xl-12">
        <h3>{{'OnOn' |translate}}</h3>
    </div>
</div>
<div class="row mb-3">
    <div class="col-xl-12">
        <a class="btn btn-outline-primary" routerLink="profiles"><span class="fa-light fa-fw fa-id-badge"></span>
            {{'PROFILE_MANAGEMENT' |translate}}</a>
    </div>
</div>
<ng-container *ngFor="let cameraGroup of cameraGroups; let i = index">
    <div class="row mb-4" *ngIf="i % 6 === 0">
        <div class="col-xl-2" *ngIf="cameraGroups[i]">
            <onon-monitor-card [cameraGroup]="cameraGroups[i]" [profiles]="profiles"></onon-monitor-card>
        </div>
        <div class="col-xl-2" *ngIf="cameraGroups[i+1]">
            <onon-monitor-card [cameraGroup]="cameraGroups[i+1]" [profiles]="profiles"></onon-monitor-card>
        </div>
        <div class="col-xl-2" *ngIf="cameraGroups[i+2]">
            <onon-monitor-card [cameraGroup]="cameraGroups[i+2]" [profiles]="profiles"></onon-monitor-card>
        </div>
        <div class="col-xl-2" *ngIf="cameraGroups[i+3]">
            <onon-monitor-card [cameraGroup]="cameraGroups[i+3]" [profiles]="profiles"></onon-monitor-card>
        </div>
        <div class="col-xl-2" *ngIf="cameraGroups[i+4]">
            <onon-monitor-card [cameraGroup]="cameraGroups[i+4]" [profiles]="profiles"></onon-monitor-card>
        </div>
        <div class="col-xl-2" *ngIf="cameraGroups[i+5]">
            <onon-monitor-card [cameraGroup]="cameraGroups[i+5]" [profiles]="profiles"></onon-monitor-card>
        </div>
    </div>
</ng-container>
<div class="row">
    <div class="col-lg-12">
        <div *ngIf="!cameraGroups">
            <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg">
        </div>
    </div>
</div>

import { type } from "jquery";
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { Device } from "../../../models/device.model";
import { Subject } from "rxjs";
import { throttleTime } from "rxjs/operators";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CommonModule } from "@angular/common";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: "device-add",
  templateUrl: "./device-add.component.html",
  styleUrls: ["./device-add.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    TranslateModule,
    MatProgressSpinnerModule
  ]
})
export class DeviceAddComponent implements OnInit {
  @Input("locationList") locations: any;
  @Output() closeAddFormEvent = new EventEmitter<void>();
  @Output() deviceAddedEvent = new EventEmitter<string>();

  //Init global variables and services
  constructor(
    private api: ApiService,
    private helper: HelperService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder
  ) { }

  addDevForm: UntypedFormGroup;
  devValidated: boolean = false;
  addDevFormError: string;
  addDevFormInfo: string;
  responseDev: Device;
  fileName = "";
  deviceTypeList: { value: string; name: string }[] = [];

  //Indicates that device is not found in the system and not belonging to any customer
  newDevice: boolean;
  //Variable for resetting deviceTypeList to it's original state
  devTypeListStorage: any;

  deviceIsBeingAdded = false;

  //Initialize the form for adding new devices
  createAddDevForm(): void {
    this.addDevForm = this.fb.group({
      id: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            new RegExp("^(?:31|34|36|06|89)[0-9A-F]{6}$", "i")
          ),
        ]),
      ],
    });
  }

  //Update the form after successfull validity check
  updateAddDevForm(): void {
    //Disable the id field so user can't change it after the validity check
    this.addDevForm.controls["id"].disable();
    //Add new fields to the form
    this.addDevForm.addControl(
      "locationId",
      new UntypedFormControl("", Validators.required)
    );
    this.addDevForm.addControl("name", new UntypedFormControl(""));
    this.addDevForm.addControl("devType", new UntypedFormControl(this.newDevice ? "" : this.responseDev.deviceType, Validators.required)
    );

    //Remove required validator if device is not new
    if (!this.newDevice) {

      this.addDevForm.get("devType").setValidators(null);
      this.addDevForm.get("devType").updateValueAndValidity();
    }

    //Add response device's type to the list if doesn't already exists there
    if (
      !this.deviceTypeList.some((e) => e.value === this.responseDev.deviceType)
    ) {
      this.deviceTypeList.push({
        value: this.responseDev.deviceType,
        name: this.helper.getDeviceType(this.responseDev.deviceType),
      });
    }
    //Filter device type selection to match the given serial number ID for correct device class selection
    this.deviceTypeList = this.deviceTypeList.filter(
      (devType) =>
        devType.value.substring(0, 2) ===
        this.responseDev.deviceType.substring(0, 2)
    );
  }

  getDevType(): string {
    return this.helper.getDeviceType(this.responseDev.deviceType);
  }

  //Reset the form to it's initial state
  resetAddForm(): void {
    this.addDevFormError = "";
    this.addDevFormInfo = "";
    //Make form reset and set id back to null
    this.addDevForm.reset({
      id: "",
    });
    //Enable the id field and remove extra fields which are not needed before validation
    this.addDevForm.controls["id"].enable();
    this.addDevForm.removeControl("locationId");
    this.addDevForm.removeControl("name");
    this.addDevForm.removeControl("devType");
    this.devValidated = false;
    this.deviceTypeList = this.devTypeListStorage;
  }

  //Emit the close event to parent component
  closeAddForm(): void {
    this.closeAddFormEvent.emit();
  }

  // Add new device when form is submitted
  addDevice(): void {
    this.devValidated ? this.deviceIsBeingAdded = true : null; // Show loading spinner only after second submit
    // Make sure that device is validated before submitting it to the server
    if (!this.devValidated) {
      this.checkValidity();
      return;
    }

    let dev: Device = new Device();
    dev.id = this.addDevForm.getRawValue().id;
    dev.name = this.addDevForm.value.name;
    dev.locationId = this.addDevForm.value.locationId;
    dev.deviceType = this.addDevForm.value.devType;
    dev.devClass = this.addDevForm.value.devType.substring(3);

    this.api.addDevice(dev).subscribe(
      (res) => {
        this.deviceAddedEvent.emit(dev.id);
        this.deviceIsBeingAdded = false;
      },
      (err) => {
        this.addDevFormError = this.translate.instant("ERROR");
        this.deviceIsBeingAdded = false;
        console.log(err);
      }
    );
  }

  //Check that device exists in the system and it's not already in use
  //If there's no device available and checked id doesn't belong to customer, response returns default values for adding new device
  checkValidity(): void {
    this.api.checkDeviceValidity(this.addDevForm.value.id).subscribe((res) => {
      if (res) {
        this.responseDev = res.device;
        this.newDevice = res.newDevice;
        this.addDevFormError = "";
        this.devValidated = true;
        this.updateAddDevForm();
        if (this.newDevice) {
          //translation text needed: (english version) Device not found in the system. New device to be added.
          this.translate.get(["DEVICE_VALIDITY_NOT_FOUND"]).subscribe((t) => {
            this.addDevFormInfo = t.DEVICE_VALIDITY_NOT_FOUND;
          });
        }
      } else {
        //translation text needed: (english version) Device is already in use.
        this.translate.get(["DEVICE_VALIDITY_INUSE"]).subscribe((t) => {
          this.addDevFormError = t.DEVICE_VALIDITY_INUSE;
        });
      }
    });
  }

  ngOnInit() {
    this.createAddDevForm();

    //Get device types. The list may be incomplete
    this.api.getReportFilteringOptions().subscribe((res) => {
      res.deviceTypes.forEach((devType) => {
        this.deviceTypeList.push({
          value: devType,
          name: this.helper.getDeviceType(devType),
        });
      });
      this.devTypeListStorage = this.deviceTypeList;
    });

  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { ApiService } from '../../../services/api.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-report-download',
    templateUrl: './report-download.component.html',
    styleUrls: ['./report-download.component.css'],
    standalone: true,
    imports: [NgIf, TranslateModule]
})


export class ReportDownloadComponent implements OnInit {

  constructor(private api: ApiService, private router: Router, private route: ActivatedRoute,) {
  }

  fileNotFound: boolean;
  fileName: string;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (!params.file) {
        return this.router.navigate(['/dashboard']);
      }


      this.fileName = params.file;
      this.api.downloadReport(this.fileName).then(blob => {
        saveAs(blob, `report-${this.fileName}`);

      }).catch(() => {
        this.fileNotFound = true;

      }).then(() => {
        if (!this.fileNotFound) {
          this.router.navigate(['/dashboard']);
        }

      })


    });
  }

}

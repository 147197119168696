<div class="row">
    <div class="col-12">
        <h6 class="text-center routeEditGroupTitle mb-2">{{'DEVICES' | translate}}</h6>
        <p *ngIf="deviceGroups.length == 0" class="text-center mt-2">{{'NO_DEVICES' | translate}}</p>
    </div>
</div>

<div class="row">


    <ng-container *ngIf="deviceGroups.length != 0">
        <div class="col-lg-4 col-md-12 white-bg">

            <div class="card card-borders p-2">
                <h6 class="text-center devicesGroupTitle">{{'AVAILABLE_DEVICES' | translate}}</h6>

                <mat-list>

                    <mat-list-item *ngIf="availableDevicesEmpty()">
                        <div class="d-flex flex-row justify-content-between">

                            <div>
                                <span class="fa-light fa-check-double mr-1" style="vertical-align: middle"></span>
                                <span class="devicesGroupTitle text-truncate">{{'ADD_ALL_DEVICES' | translate}}</span>
                            </div>

                            <mat-icon class="add-device-button" (click)="addAllDevices();">add_circle</mat-icon>

                        </div>
                    </mat-list-item>


                    <ng-container *ngFor="let deviceTypeOption of availableDeviceTypeOptions">

                        <mat-list-item *ngIf="availableDevicesByDeviceType(deviceTypeOption.deviceType)">
                            <div class="d-flex flex-row justify-content-between">

                                <span class="devicesGroupTitle text-truncate">
                                    {{'ALL' | translate}} -
                                    {{getDeviceType(deviceTypeOption.deviceType)}}
                                </span>

                                <mat-icon class="add-device-button" (click)="addDevicesByType(deviceTypeOption.deviceType)">
                                    add_circle
                                </mat-icon>

                            </div>
                        </mat-list-item>
                    </ng-container>


                    <ng-container *ngFor="let group of deviceGroups">
                        <mat-list-item *ngIf="availableDevicesInLocation(group.locationId)">
                            <div class="d-flex flex-row justify-content-between">

                                <div>
                                    <span *ngIf="group.locationName"
                                        class="fa-fw mr-2 fa-solid fa-house devicesGroupTitle"></span>

                                    <span class="devicesGroupTitle text-truncate">{{group.locationName}}</span>
                                </div>


                                <mat-icon class="add-device-button" (click)="addDevicesByLocationId(group.locationId)">
                                    add_circle
                                </mat-icon>

                            </div>

                        </mat-list-item>

                        <ng-container *ngFor="let device of group.deviceOptions">

                            <mat-list-item *ngIf="!device.selected">
                                <div class="d-flex flex-row justify-content-between">

                                    <div class="text-truncate">
                                        <span [ngClass]="getDeviceIconClass(device.deviceType)" class="mr-1"
                                            style="vertical-align: middle"></span>

                                        <ng-container *ngIf="device.deviceName">{{device.deviceName}}</ng-container>
                                        <ng-container *ngIf="!device.deviceName">{{device.deviceId}}</ng-container>
                                        ({{getDeviceType(device.deviceType)}})
                                    </div>


                                    <mat-icon class="add-device-button" (click)="addDevice(device)">
                                        add_circle
                                    </mat-icon>

                                </div>
                            </mat-list-item>

                            <ng-container *ngFor="let pairedDevice of device.pairedDeviceOptions">

                                <mat-list-item *ngIf="!pairedDevice.selected">
                                    <div class="d-flex flex-row justify-content-between">

                                        <div class="text-truncate">
                                            <span [ngClass]="getDeviceIconClass(pairedDevice.deviceType)" class="mr-1"
                                                style="vertical-align: middle"></span>

                                            <ng-container *ngIf="pairedDevice.deviceName">{{pairedDevice.deviceName}}
                                            </ng-container>
                                            <ng-container *ngIf="!pairedDevice.deviceName">{{pairedDevice.deviceId}}
                                            </ng-container>
                                            ({{getDeviceType(pairedDevice.deviceType)}})
                                        </div>


                                        <mat-icon class="add-device-button" (click)="addDevice(pairedDevice)">
                                            add_circle
                                        </mat-icon>

                                    </div>
                                </mat-list-item>
                            </ng-container>
                        </ng-container>
                    </ng-container>


                </mat-list>
            </div>
        </div>


        <!--Selected devices-->

        <div class="col-lg-4 col-md-12 white-bg">
            <div class="card card-borders p-2">
                <h6 class="text-center devicesGroupTitle">{{'SELECTED_DEVICES' | translate}}</h6>
                <mat-list>

                    <mat-list-item *ngIf="selectedDevicesEmpty()">
                        <div class="d-flex flex-row justify-content-between">

                            <div>
                                <span class="fa-light fa-check-double mr-1" style="vertical-align: middle"></span>

                                <span class="devicesGroupTitle text-truncate">{{'REMOVE_ALL_DEVICES' | translate}}</span>
                            </div>

                            <mat-icon class="remove-device-button" (click)="removeAllDevices();">remove_circle</mat-icon>

                        </div>
                    </mat-list-item>


                    <div *ngFor="let deviceTypeOption of availableDeviceTypeOptions"
                        [ngClass]="{'highlight-selection' : deviceTypeOption.highlight}">
                        <mat-list-item *ngIf="selectedDevicesByDeviceType(deviceTypeOption.deviceType)">
                            <div class="d-flex flex-row justify-content-between align-items-center">

                                <span class="text-truncate devicesGroupTitle selection-container"
                                    (click)="highlightDeviceTypeOption(deviceTypeOption);
                                              selectedDevicesForActivations = getDevicesByType(deviceTypeOption.deviceType);
                                              deviceTypeGroupHighlighted = true;">
                                    {{'ALL' | translate}} - {{getDeviceType(deviceTypeOption.deviceType)}}
                                </span>

                                <mat-icon class="remove-device-button"
                                    (click)="removeDevicesByType(deviceTypeOption.deviceType)">
                                    remove_circle
                                </mat-icon>

                            </div>
                        </mat-list-item>
                    </div>


                    <ng-container *ngFor="let group of deviceGroups">
                        <mat-list-item *ngIf="selectedDevicesInLocation(group.locationId)">
                            <div class="d-flex flex-row justify-content-between align-items-center">
                                <div>
                                    <span *ngIf="group.locationName"
                                        class="fa-fw mr-2 fa-solid fa-house devicesGroupTitle"></span>

                                    <span class="devicesGroupTitle text-truncate">{{group.locationName}}</span>
                                </div>

                                <mat-icon class="remove-device-button" (click)="removeDevicesByLocationId(group.locationId)">
                                    remove_circle
                                </mat-icon>
                            </div>
                        </mat-list-item>

                        <ng-container *ngFor="let device of group.deviceOptions">


                            <div [@addDeviceAnimation]="device.selected"
                                [ngClass]="{'highlight-selection' : device.highlight}">


                                <mat-list-item *ngIf="device.selected">
                                    <div class="d-flex flex-row justify-content-between align-items-center">

                                        <div class="text-truncate selection-container d-flex align-items-center"
                                            (click)="highlightDeviceOption(device);
                                                deviceTypeGroupHighlighted = false;
                                                selectedDevicesForActivations = [device]">

                                            <span
                                                [ngClass]="getDeviceIconClass(device.deviceType)"
                                                class="mr-1" style="vertical-align: middle">
                                            </span>

                                            <div [ngClass]="{'text-danger' : device?.activations.length === 0}"
                                                class="d-inline">
                                                <ng-container *ngIf="device.deviceName">{{device.deviceName}}</ng-container>
                                                <ng-container *ngIf="!device.deviceName">{{device.deviceId}}</ng-container>
                                                <span> ({{getDeviceType(device.deviceType)}})</span>
                                            </div>
                                        </div>

                                        <mat-icon class="remove-device-button" (click)="removeDevice(device)">
                                            remove_circle
                                        </mat-icon>

                                    </div>
                                </mat-list-item>
                            </div>

                            <div *ngFor="let pairedDevice of device.pairedDeviceOptions"
                                [@addedAnimation]="device.selected"
                                [ngClass]="{'highlight-selection' : pairedDevice.highlight}" class="mb-1">


                                <mat-list-item *ngIf="pairedDevice.selected">
                                    <div class="d-flex flex-row justify-content-between align-items-center">

                                        <div class="text-truncate selection-container d-flex align-items-center"
                                            (click)="highlightDeviceOption(pairedDevice);
                                                 deviceTypeGroupHighlighted = false;
                                                 selectedDevicesForActivations = [pairedDevice]">

                                            <span [ngClass]="getDeviceIconClass(pairedDevice.deviceType)" class="mr-1"
                                                style="vertical-align: middle"></span>

                                            <div [ngClass]="{'text-danger' : pairedDevice?.activations.length === 0}"
                                                class="d-inline">
                                                <ng-container *ngIf="pairedDevice.deviceName">{{pairedDevice.deviceName}}
                                                </ng-container>
                                                <ng-container *ngIf="!pairedDevice.deviceName">{{pairedDevice.deviceId}}
                                                </ng-container>
                                                <span> ({{getDeviceType(pairedDevice.deviceType)}})</span>
                                            </div>
                                        </div>

                                        <mat-icon class="remove-device-button" (click)="removeDevice(pairedDevice)">
                                            remove_circle
                                        </mat-icon>

                                    </div>
                                </mat-list-item>
                            </div>
                        </ng-container>
                    </ng-container>

                </mat-list>
            </div>
        </div>


        <div class="col-lg-4 col-md-12 white-bg">
            <div class="card card-borders p-2 activations-list">
                <h6 class="text-center devicesGroupTitle">{{'ALARM_TYPE' | translate}}</h6>


                <p *ngIf="!selectedDeviceByDeviceId(selectedDevicesForActivations[0]?.deviceId)" class="text-center">
                    {{'NO_DEVICES_SELECTED' | translate}}</p>
                <p *ngIf="!deviceTypeGroupHighlighted && selectedDeviceByDeviceId(selectedDevicesForActivations[0]?.deviceId)"
                    class="text-center">{{selectedDevicesForActivations[0].deviceName}}</p>
                <p *ngIf="deviceTypeGroupHighlighted  && selectedDeviceByDeviceId(selectedDevicesForActivations[0]?.deviceId)"
                    class="text-center">{{getDeviceType(selectedDevicesForActivations[0].deviceType)}}</p>


                <ng-container *ngIf="selectedDeviceByDeviceId(selectedDevicesForActivations[0]?.deviceId)">

                    <ng-container
                        *ngFor="let selectedDeviceActivation of getDeviceActivationOptions(selectedDevicesForActivations) | keyvalue">
                        <mat-checkbox class="activation-checkbox"
                            #checkbox
                            [checked]="selectedDeviceActivation.value === 1"
                            [indeterminate]="selectedDeviceActivation.value === 2"
                            (change)="!checkbox.checked
                                                    ? removeActivationFromDevices(selectedDeviceActivation.key.bindDeviceIds, selectedDeviceActivation.key)
                                                    : addActivationToDevices(selectedDeviceActivation.key.bindDeviceIds, selectedDeviceActivation.key)">

                            <!-- Replace activation name if the name contains '%CTL%' -->

                            <ng-container *ngIf="selectedDeviceActivation.key.name.includes('%CTL%'); else name">
                                {{'NO_CONNECTION_WITHIN_TIME' | translate}}
                            </ng-container>

                            <ng-template #name> {{selectedDeviceActivation.key.name}}</ng-template>
                        </mat-checkbox>


                    </ng-container>
                </ng-container>
            </div>

        </div>
    </ng-container>
</div>

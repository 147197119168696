<form (ngSubmit)="changePassword()" #passwordExpiredLogin="ngForm">
    <div class="form-group">
        <label><strong>{{'CHANGE_PASSWORD' |translate}}</strong></label>

        <ul *ngIf="enhancedSecurity">
            <li>{{'ENHANCED_PASSWORD_REQUIREMENTS1' |translate}}</li>
            <li>{{'ENHANCED_PASSWORD_REQUIREMENTS2' |translate}}</li>
            <li>{{'ENHANCED_PASSWORD_REQUIREMENTS3' |translate}}</li>
        </ul>

        <p *ngIf="!enhancedSecurity">
            {{'PASSWORD_INFO' |translate}}
        </p>

        <p class="text-danger" *ngIf="passwordChangeError">
            {{passwordChangeError |translate}}
        </p>
    </div>
    <div class="form-group">
        <input class="form-control" type="password" placeholder="{{'OLD_PASSWORD' |translate}}"
            [(ngModel)]="expiredOldPassword" name="expiredOldPasswordElement" #expiredOldPasswordElement="ngModel"
            required>
    </div>
    <div class="form-group">
        <input class="form-control"
            [pattern]="enhancedSecurity ? '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&_-])[A-Za-z\\d@$!%*?&_-]{8,}$' : '^\\S{6,}$'"
            type="password" placeholder="{{'NEW_PASSWORD' |translate}}" [(ngModel)]="expiredNewPassword"
            name="expiredNewPasswordElement"
            #expiredNewPasswordElement="ngModel" required>
    </div>
    <div class="form-group">
        <input class="form-control"
            [pattern]="enhancedSecurity ? '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&_-])[A-Za-z\\d@$!%*?&_-]{8,}$' : '^\\S{6,}$'"
            type="password" placeholder="{{'CONFIRM_NEW_PASSWORD' |translate}}" [(ngModel)]="expiredNewPasswordConfirm"
            name="expiredNewPasswordConfirmElement" #expiredNewPasswordConfirmElement="ngModel" required>
    </div>
    <div class="form-group">
        <button [disabled]="passwordExpiredLogin.invalid" class="btn btn-outline-primary" type="submit"><span
                class="fa-light fa-fw fa-key"></span> {{'CHANGE_PASSWORD' |translate}}</button>
        <button [mat-dialog-close]="true" class="btn btn-outline-danger ml-2"><span
                class="fa-light fa-fw fa-xmark"></span> {{'CANCEL' |translate}}</button>
    </div>
</form>
<div class="pt-3 card-body-dark">
    <div class="card-header">
        <strong>
            {{'REMOVE_DEVICE' |translate}}
        </strong>
        <span class="float-right cursorPointer" (click)="closeAddForm()"><span class="fa-light fa-xmark"></span></span>
    </div>
    <div class="card-body-container">
        <p>device-remove-company works!</p>
    </div>
</div>

<div class="card">
    <div class="card-header-white header-underline">
        <h6 class="white-header-text">
            {{ companyGroup.name }} ({{ companyGroup.companyGroupId }})
        </h6>
        <span class="float-right cursorPointer" (click)="showForm.next(false)">
            <span class="fa-light fa-xmark white-header-xmark-icon"> </span>
        </span>
    </div>

    <div [ngStyle]="{'height': tabIndex === 2 ? 'fit-content' : '650px'}">
        <mat-tab-group [(selectedIndex)]="tabIndex">
            <mat-tab label="{{ 'INFORMATION' | translate }}">
                <company-group-edit-info
                    [companyGroup]="companyGroup"
                ></company-group-edit-info>
            </mat-tab>

            <mat-tab label="{{ 'COMPANY_MANAGEMENT' | translate }}">
                <company-group-edit-companies
                    [companyGroup]="companyGroup"
                ></company-group-edit-companies>
            </mat-tab>

            <mat-tab label="{{ 'SETTINGS' | translate }}">
                <app-company-group-binding-time
                    [companyGroup]="companyGroup"
                ></app-company-group-binding-time>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

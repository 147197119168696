import { throttleTime } from "rxjs/operators";
import { Subject } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "app/services/api.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { AnnaPerennaMonitorProfile } from "app/models/annaperenna.model";
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { AnnaPerennaMonitorZoneComponent } from "../ap-monitor-zone/ap-monitor-zone.component";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "ap-monitor-edit",
    templateUrl: "./ap-monitor-edit.component.html",
    styleUrls: ["./ap-monitor-edit.component.css"],
    standalone: true,
    imports: [RouterLink, NgIf, AnnaPerennaMonitorZoneComponent, MatFormFieldModule, MatSelectModule, FormsModule, MatOptionModule, NgFor, ReactiveFormsModule, MatSlideToggleModule, MatTooltipModule, TranslateModule]
})
export class AnnaPerennaMonitorEditComponent implements OnInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder
  ) {}

  monitor: any;
  monitorId: any;
  profiles: AnnaPerennaMonitorProfile[];
  selectedProfile: AnnaPerennaMonitorProfile;
  profileManagementForm: UntypedFormGroup;
  saveSuccess: boolean = false;
  saveError: boolean = false;
  deleteConfirm: boolean = false;

  formSubmitSubject = new Subject();

  profileChanged(data: any) {
    this.createProfileManagementForm();
  }

  createNewProfile(): void {
    //Create new profile with all settings disabled and set it as selected profile
    this.selectedProfile = new AnnaPerennaMonitorProfile();
    this.selectedProfile.monitoringEnabled = false;
    this.selectedProfile.bedOut = "1440";
    this.selectedProfile.roomOut = "1440";
    this.selectedProfile.maxFloorTime = 1440;
    this.selectedProfile.maxToiletTime = "1440";
    this.selectedProfile.twoPersonsInRoom = 0;
    this.selectedProfile.fallRecognition = 0;
    this.selectedProfile.sendImage = 0;
    this.selectedProfile.exitNightTime = 0;
    this.selectedProfile.bedOutControl = 0;
    this.createProfileManagementForm();
  }

  createProfileManagementForm(): void {
    this.profileManagementForm = this.fb.group({
      profileName: [this.selectedProfile.name, Validators.required],
      profileMonitoringEnabled: this.selectedProfile.monitoringEnabled,
      profileBedOutEnabled:
        this.selectedProfile.bedOut === "1440" ||
        this.selectedProfile.bedOut === "-1"
          ? false
          : true,
      profileRoomOutEnabled:
        this.selectedProfile.roomOut === "1440" ||
        this.selectedProfile.roomOut === "-1"
          ? false
          : true,
      profileRoomOut:
        this.selectedProfile.roomOut === "1440" ||
        this.selectedProfile.roomOut === "-1"
          ? "0"
          : this.selectedProfile.roomOut,
      profileMaxToiletTimeEnabled:
        this.selectedProfile.maxToiletTime === "1440" ||
        this.selectedProfile.maxToiletTime === "-1"
          ? false
          : true,
      profileMaxToiletTime:
        this.selectedProfile.maxToiletTime === "1440" ||
        this.selectedProfile.maxToiletTime === "-1"
          ? "0"
          : this.selectedProfile.maxToiletTime,
      profileMaxFloorTimeEnabled:
        this.selectedProfile.maxFloorTime === 1440 ||
        this.selectedProfile.maxFloorTime === -1
          ? false
          : true,
      profileMaxFloorTime:
        this.selectedProfile.maxFloorTime === 1440 ||
        this.selectedProfile.maxFloorTime === 1440
          ? 0
          : this.selectedProfile.maxFloorTime,
      profileInactiveTimeEnabled:
        this.selectedProfile.inactiveStart && this.selectedProfile.inactiveEnd
          ? true
          : false,
      profileInactiveTimeStart: [
        this.parseTime(this.selectedProfile.inactiveStart),
        Validators.pattern(new RegExp("^([0-9]{2}):([0-9]{2})$")),
      ],
      profileInactiveTimeEnd: [
        this.parseTime(this.selectedProfile.inactiveEnd),
        Validators.pattern(new RegExp("^([0-9]{2}):([0-9]{2})$")),
      ],
      profileSuperActiveTimeEnabled:
        this.selectedProfile.superActiveStart &&
        this.selectedProfile.superActiveEnd
          ? true
          : false,
      profileSuperActiveTimeStart: [
        this.parseTime(this.selectedProfile.superActiveStart),
        Validators.pattern(new RegExp("^([0-9]{2}):([0-9]{2})$")),
      ],
      profileSuperActiveTimeEnd: [
        this.parseTime(this.selectedProfile.superActiveEnd),
        Validators.pattern(new RegExp("^([0-9]{2}):([0-9]{2})$")),
      ],
      profileFallRecognition: this.selectedProfile.fallRecognition,
      profileTwoPersonsInRoom: this.selectedProfile.twoPersonsInRoom,
      profileExitNightTime: this.selectedProfile.exitNightTime,
      profileSendImage: this.selectedProfile.sendImage,
      profileBedOutControlEnabled:
        this.selectedProfile.bedOutControl === 1440 ||
        this.selectedProfile.bedOutControl === -1
          ? false
          : true,
      profileBedOutControl:
        this.selectedProfile.bedOutControl === 1440 ||
        this.selectedProfile.bedOutControl === -1
          ? 0
          : this.selectedProfile.bedOutControl,
    });
  }

  submitProfileManagementForm(): void {
    this.saveError = false;
    this.saveSuccess = false;

    let data: any = {};
    //Set ID only if it's in selected profile. New profiles don't have ID set
    if (this.selectedProfile.id) {
      data.id = this.selectedProfile.id;
    }
    data.name = this.profileManagementForm.value.profileName;
    data.monitoringEnabled =
      this.profileManagementForm.value.profileMonitoringEnabled;
    data.bedOut = this.profileManagementForm.value.profileBedOutEnabled
      ? "0"
      : "1440";
    data.roomOut = this.profileManagementForm.value.profileRoomOutEnabled
      ? this.profileManagementForm.value.profileRoomOut
      : "1440";
    data.maxToiletTime = this.profileManagementForm.value
      .profileMaxToiletTimeEnabled
      ? this.profileManagementForm.value.profileMaxToiletTime
      : "1440";
    data.maxFloorTime = this.profileManagementForm.value
      .profileMaxFloorTimeEnabled
      ? this.profileManagementForm.value.profileMaxFloorTime
      : 1440;
    data.fallRecognition =
      this.profileManagementForm.value.profileFallRecognition;
    data.twoPersonsInRoom =
      this.profileManagementForm.value.profileTwoPersonsInRoom;
    data.exitNightTime = this.profileManagementForm.value.profileExitNightTime;
    data.sendImage = this.profileManagementForm.value.profileSendImage;
    data.superActiveStart = this.profileManagementForm.value
      .profileSuperActiveTimeEnabled
      ? this.profileManagementForm.value.profileSuperActiveTimeStart.replace(
          ":",
          ""
        )
      : "";
    data.superActiveEnd = this.profileManagementForm.value
      .profileSuperActiveTimeEnabled
      ? this.profileManagementForm.value.profileSuperActiveTimeEnd.replace(
          ":",
          ""
        )
      : "";
    data.inactiveStart = this.profileManagementForm.value
      .profileInactiveTimeEnabled
      ? this.profileManagementForm.value.profileInactiveTimeStart.replace(
          ":",
          ""
        )
      : "";
    data.inactiveEnd = this.profileManagementForm.value
      .profileInactiveTimeEnabled
      ? this.profileManagementForm.value.profileInactiveTimeEnd.replace(":", "")
      : "";
    data.bedOutControl = this.profileManagementForm.value
      .profileBedOutControlEnabled
      ? this.profileManagementForm.value.profileBedOutControl
      : 1440;

    //If data has ID use the update API and update local profile list. Otherwise profile is new and use the save API and update local profile list
    if (data.id) {
      const editProfileIndex: number = this.profiles.findIndex(
        (profile) => profile.id === data.id
      );
      this.profiles[editProfileIndex] = data;
      this.selectedProfile = this.profiles[editProfileIndex];
      this.api.updateProfileData(data).subscribe(
        (res) => {
          this.saveSuccess = true;
        },
        (err) => {
          this.saveError = true;
        }
      );
    } else {
      this.api.saveNewProfile(data).subscribe(
        (res) => {
          this.selectedProfile.name = data.name;
          this.selectedProfile.id = res;
          this.profiles.push(this.selectedProfile);
          this.saveSuccess = true;
        },
        (err) => {
          this.saveError = true;
        }
      );
    }
  }

  deleteProfile(): void {
    this.saveError = false;
    this.saveSuccess = false;
    this.api.deleteMonitorProfile(this.selectedProfile.id).subscribe(
      (res) => {
        this.profiles.splice(this.profiles.indexOf(this.selectedProfile), 1);
        this.selectedProfile = null;
      },
      (err) => {}
    );
  }

  //Parse time from ie. 2000 to 20:00
  parseTime(data: any): string {
    if (data) {
      let splitted = data.match(/.{1,2}/g);
      return `${splitted[0]}:${splitted[1]}`;
    }
    return "";
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.monitorId = params["id"];
      this.api.getMonitorInfo(this.monitorId).subscribe((res) => {
        this.monitor = res;
      });
    });

    this.selectedProfile = null;
    this.api.getMonitorProfiles().subscribe((res) => {
      this.profiles = res.filter((obj) => {
        return obj.profileType !== 3;
      });
    });

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject.pipe(throttleTime(3000)).subscribe(() => {
      this.submitProfileManagementForm();
    });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

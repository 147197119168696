import { throttleTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AnimationCollapse } from '../../../animations';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationModel } from 'app/models/location.model';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'location-add',
    templateUrl: './location-add.component.html',
    styleUrls: ['./location-add.component.css'],
    animations: [
        AnimationCollapse
    ],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, TranslateModule]
})
export class LocationAddComponent implements OnChanges, OnInit, OnDestroy {

  //Init global variables and services
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private fb: UntypedFormBuilder
  ) { };

  @Input('location') location: LocationModel;
  @Output() closeLocationCardEvent = new EventEmitter<LocationModel>();
  @Output() locationAddedEvent = new EventEmitter<LocationModel>();

  user: any;
  allFields:boolean;
  locationAddForm: UntypedFormGroup;
  customerInfo: any;

  formSubmitSubject = new Subject();

  createLocationAddForm(): void {
    this.locationAddForm = this.fb.group({
      name: [this.location.name, Validators.required],
      contactPhone: this.customerInfo.contactPhone,
      address: this.customerInfo.address,
      postCode: this.customerInfo.postCode,
      postOffice: this.customerInfo.postOffice
    })
  }

  submitNewLocation(): void {
    let newLocation = new LocationModel();
    newLocation.name = this.locationAddForm.value.name;
    newLocation.contactPhone = this.locationAddForm.value.contactPhone;
    newLocation.address = this.locationAddForm.value.address;
    newLocation.postCode = this.locationAddForm.value.postCode;
    newLocation.postOffice = this.locationAddForm.value.postOffice;
    this.api.addNewLocation(newLocation).subscribe(res => {
      if (res) {
        this.locationAddedEvent.emit(res);
      }
    }, err => {
      this.locationAddedEvent.emit(null)
    })
  }

  closeLocationCard(): void {
    this.closeLocationCardEvent.emit(this.location);
  }

  ngOnInit(): void {
    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject.pipe(throttleTime(3000))
    .subscribe(() => {
      this.submitNewLocation();
    });
  }

  ngOnChanges() {
    this.customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
    this.user = JSON.parse(localStorage.getItem("user"));
    this.createLocationAddForm();
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe;
  }
}

<div class="card">
    <div class="card-header card-header-dark d-flex">
        <span class="mr-2">{{'FORWARDED_ALERTS' |translate}}</span>
        <app-notification-badge [count]="alerts?.length" [warningStatus]="false"
            style="margin-top: 3px;"></app-notification-badge>
    </div>
    <mat-accordion multi class="card-body card-body-dark" cdkDropList id="forwardedAlertList" [cdkDropListData]="alerts"
        (cdkDropListDropped)="crService.drop($event)">
        <mat-expansion-panel *ngFor="let alert of alerts" cdkDrag [cdkDragDisabled]="!alert.allowTransition"
            class="mb-2">
            <mat-expansion-panel-header
                [ngClass]="{'alertCardHeaderWithDrag': alert.allowTransition, 'alertCardTransitional': alert.transitional}"
                cdkDragHandle class="justify-content-between">
                <div class="container my-2">
                    <div class="row">
                        <img src="{{ alert | pickUpAlertIcon }}" class="moderateSize mr-2">

                        <div class="w-25 mr-2"><strong>{{alert.header}} ({{alert.productId}})</strong></div>

                        <div class="alignItemsToCenter">
                            <p class="mr-4"> {{ alert | pickUpActivationName }}</p>
                        </div>
                        <div class="alignItemsToCenter">
                            <p><small><i>{{alert.time | calculateAlertTime}}</i></small></p>
                        </div>
                        <div class="w-25 text-center" (click)="crSync.resetAlertIncrement(alert.userId, alert.alertId)"
                            *ngIf="alert.increment > 0"
                            matTooltip="{{'INCREMENT_INFO' |translate}} {{'CLICK_TO_RESET' |translate}}"
                            [matTooltipPosition]="'above'">
                            <span class="badge badge-pill badge-danger">{{'+' + alert.increment}}</span>
                        </div>
                    </div>
                    <div class="row mt-2 ml-2">
                        <p><strong>{{'STATUS' |translate}}:</strong> {{getAlertStatus(alert)}} <span
                                *ngIf="alert.state !== emaAlertState.Available" class="fa-solid fa-xs fa-circle"
                                [ngClass]="{'text-danger': !getStaffDetails(alert, 2), 'text-success': getStaffDetails(alert, 2)}"></span>
                        </p>
                    </div>
                </div>
            </mat-expansion-panel-header>
            <div class="alertCardBody clearfix">
                <div>
                    <p><span class="fa-light fa-lg fa-fw fa-location-dot"></span>&nbsp;&nbsp;{{alert.address}}</p>
                </div>
                <div class="mt-2" *ngIf="alert.place">
                    <p><span class="fa-light fa-lg fa-fw fa-microchip"></span>&nbsp;&nbsp;{{alert.place}}</p>
                </div>
                <div>
                    <app-display-alarm-reasons
                        [alert]="alert"
                        [dataComesFromSocket]="true">
                    </app-display-alarm-reasons>
                    <app-add-note [alert]="alert"></app-add-note>
                </div>
                <div class="mt-3">
                    <p class="mb-2" *ngIf="alert.state !== emaAlertState.Available && getStaffDetails(alert, 0)"><a
                            href="{{'tel:' + getStaffDetails(alert, 0)}}" class="cursorPointerHover"><span
                                class="fa-light fa-fw fa-phone"></span>&nbsp;&nbsp;{{'CALL_NURSE' |translate}}:
                            {{getStaffDetails(alert, 0)}}</a></p>
                    <p *ngIf="alert.baseMISDN" data-testid="call-patient-link" class="mb-2"><a href="{{'tel:' + alert.baseMISDN}}" class="cursorPointerHover"><span
                                class="fa-light fa-fw fa-phone"></span>&nbsp;&nbsp;{{'CALL_PATIENT' |translate}}:
                            {{alert.baseMISDN}}</a></p>
                </div>
                <hr>
                <div class="mt-3">
                    <p class="mt-2" *ngIf="alert.customerName">
                        <span
                            class="fa-light fa-fw fa-building"></span>&nbsp;&nbsp;{{alert.customerName}},&nbsp;{{alert.locationName}}
                    </p>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="showTeamAlerts">
        <div class="card-header card-header-dark">{{'ALERT_FORWARDED_BY_OTHERS' |translate}} ({{teamsAlerts.length}})
        </div>
        <mat-accordion multi class="card-body card-body-dark d-block" cdkDropList id="forwardedAlertList"
            [cdkDropListData]="alerts"
            (cdkDropListDropped)="crService.drop($event)">
            <mat-expansion-panel *ngFor="let alert of teamsAlerts">
                <mat-expansion-panel-header class="justify-content-between">
                    <img src="{{ alert | pickUpAlertIcon }}" class="moderateSize mr-2">
                    <div class="w-25">
                        <p class="mr-2"><strong>{{alert.header}} ({{alert.productId}})</strong></p>
                    </div>
                    <div>
                        <p class="mr-2"> {{ alert | pickUpActivationName }}</p>
                    </div>
                    <div>
                        <small>{{alert.time | calculateAlertTime}}</small>
                    </div>
                    <div class="cursorPointer w-25 text-center"
                        (click)="crSync.resetAlertIncrement(alert.userId, alert.alertId)"
                        *ngIf="alert.increment > 0"
                        matTooltip="{{'INCREMENT_INFO' |translate}} {{'CLICK_TO_RESET' |translate}}"
                        [matTooltipPosition]="'above'">
                        <span class="badge badge-pill badge-danger">{{'+' + alert.increment}}</span>
                    </div>
                </mat-expansion-panel-header>
                <div class="alertCardBody clearfix">
                    <div>
                        <p><span class="fa-light fa-lg fa-fw fa-location-dot"></span>&nbsp;&nbsp;{{alert.address}}
                        </p>
                    </div>
                    <div class="mt-3">
                        <p><strong>{{'STATUS' |translate}}:</strong> {{getAlertStatus(alert)}} <span
                                *ngIf="alert.state !== emaAlertState.Available" class="fa-solid fa-xs fa-circle"
                                [ngClass]="{'text-danger': !getStaffDetails(alert, 2), 'text-success': getStaffDetails(alert, 2)}"></span>
                        </p>
                    </div>
                    <div>
                        <app-add-note [alert]="alert"></app-add-note>
                    </div>
                    <div class="mt-3">
                        <p class="mb-2"
                            *ngIf="alert.state !== emaAlertState.Available && getStaffDetails(alert, 0)"><a
                                href="{{'tel:' + getStaffDetails(alert, 0)}}" class="cursorPointerHover"><span
                                    class="fa-light fa-fw fa-phone"></span>&nbsp;&nbsp;{{'CALL_NURSE' |translate}}:
                                {{getStaffDetails(alert, 0)}}</a></p>
                        <p *ngIf="alert.baseMISDN" data-testid="call-patient-link" class="mb-2"><a href="{{'tel:' + alert.baseMISDN}}" class="cursorPointerHover"><span
                                    class="fa-light fa-fw fa-phone"></span>&nbsp;&nbsp;{{'CALL_PATIENT'
                                |translate}}: {{alert.baseMISDN}}</a></p>
                    </div>
                    <hr>
                    <div class="mt-3">
                        <p class="mt-2" *ngIf="alert.customerName"><span
                                class="fa-light fa-fw fa-building"></span>&nbsp;&nbsp;{{alert.customerName}},&nbsp;{{alert.locationName}}
                        </p>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CrSyncService } from 'app/services/crsync.service';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-cr-notes-form',
    templateUrl: './cr-notes-form.component.html',
    styles: [],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TranslateModule]
})
export class CrNotesFormComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private crSync: CrSyncService
  ) { }

  @Input() addedCameraVisitNotes: string[];
  @Input() alert;

  noteForm: UntypedFormGroup;
  @Output() onCloseByCancel = new EventEmitter<any>();

  formSubmitSubject = new Subject();

  createNoteForm(): void {
    this.noteForm = this.fb.group({
      text: ["", Validators.required]
    })
  }

  closeForm(): void {
    this.onCloseByCancel.emit();
  }

  submitNote(): void {
    this.attachNoteToAlert(this.noteForm.value.text)
  }

  attachNoteToAlert(text: string){
    if (this.alert) {
      this.crSync.addNote(this.alert.userId, this.alert.alertId, text);
    } else {
      this.addedCameraVisitNotes.push(text)
    }
  }

  ngOnInit() {
    this.createNoteForm();

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject.pipe(throttleTime(3000))
    .subscribe(() => {
      this.submitNote();
    });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }

}

import { trigger, state, style, animate, transition } from '@angular/animations';

export const CaretRotationAnimation = trigger('caretRotation', [
  state('expanded', style({
    transform: 'rotate(0deg)'
  })),
  state('collapsed', style({
    transform: 'rotate(-45deg)'
  })),
  transition('expanded <=> collapsed', animate('300ms ease-in-out')),
]);

<div class="row">
    <div class="col-md-12">
        <h3>{{ "ACTIVITY_TITLE" | translate }}</h3>
        <form
            (ngSubmit)="loadActivity()"
            class="form-inline"
            #activityFilterForm="ngForm"
            *ngIf="deviceFilter.data.filterList"
        >
            <ngx-bootstrap-multiselect
                class="mr-2"
                required
                [options]="deviceFilter.data.filterList"
                [settings]="deviceFilter.settings"
                [texts]="deviceFilter.data.texts"
                [(ngModel)]="deviceFilter.data.selectedList"
                (ngModelChange)="sensorChanged($event)"
                name="advancedFilterDevices"
            ></ngx-bootstrap-multiselect>
            <button
                [disabled]="
                    !activityFilterForm.valid ||
                    deviceFilter.data.selectedList?.length === 0
                "
                type="submit"
                class="btn btn-primary mr-2"
            >
                <span class="fa-light fa-magnifying-glass"></span>
                {{ "SEARCH" | translate }}
            </button>
        </form>
        <div *ngIf="!deviceFilter.data.filterList && sensorsLoaded">
            {{ "NO_DEVICES" | translate }}
        </div>
        <div *ngIf="!sensorsLoaded">
            <img
                style="margin-top: 5px; height: 50px"
                class="fadeLoader logoSmall"
                src="assets/img/loading_logo.svg"
            />
        </div>
    </div>
</div>
<hr />
<div class="row" *ngIf="dataLoading">
    <div class="col-md-11">
        <div style="text-align: center">
            <img
                style="margin-top: 5px"
                class="fadeLoader logoSmall"
                src="assets/img/loading_logo.svg"
            />
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-11">
        <div class="chartContainer" style="background-color: '#F5F7F8'">
            <div id="scatter"></div>
            <div id="violin"></div>
            <div id="boxed"></div>
        </div>
    </div>
</div>

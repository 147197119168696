<div class="card" style="width: 80%; margin-left: auto; margin-right: auto">
    <div class="card-header-white header-underline">
        <h6 class="white-header-text">
            {{ "COMPANY_INFO" | translate }}
        </h6>
        <span
            class="float-right cursorPointer"
            (click)="this.showInfoForm.next(false)"
        >
            <span class="fa-light fa-xmark white-header-xmark-icon"></span>
        </span>
    </div>

    <!--Info card-->
    <div *ngIf="!editMode">
        <div class="card card-borders m-3">
            <table
                class="table table-borderless table-sm informationTable ml-3"
            >
                <tbody>
                    <tr>
                        <td><strong>ID:</strong></td>
                        <td>{{ company.companyId }}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ "COMPANY_NAME" | translate }}:</strong>
                        </td>
                        <td>{{ company.companyName }}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ "ADDRESS" | translate }}:</strong>
                        </td>
                        <td>{{ company.address }}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ "POSTCODE" | translate }}:</strong>
                        </td>
                        <td>{{ company.postCode }}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ "POSTOFFICE" | translate }}:</strong>
                        </td>
                        <td>{{ company.postOffice }}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ "COUNTRY" | translate }}:</strong>
                        </td>
                        <td>{{ getCountryName(company.country) }}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ "TIME_ZONE" | translate }}:</strong>
                        </td>
                        <td>{{ getTimeZoneName(company.country) }}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ "CONTACT_PHONE" | translate }}:</strong>
                        </td>
                        <td>{{ company.contactPhone }}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong
                                >{{ "DELIVERY_METHOD" | translate }}:</strong
                            >
                        </td>
                        <td>{{ company.deliveryMethod }}</td>
                    </tr>
                    <div
                        class="mt-3"
                        *ngIf="
                            company.activeFeatures &&
                            company.activeFeatures.length > 0
                        "
                    >
                        <tr>
                            <td>
                                <strong
                                    >{{
                                        "ENABLED_FEATURES" | translate
                                    }}:</strong
                                >
                            </td>
                        </tr>
                        <hr />
                        <tr>
                            <ul
                                class="companyFeatures"
                                *ngFor="let feature of featureList"
                            >
                                <li *ngIf="features.value.includes(feature)">
                                    <span
                                        class="fa-light fa-fw"
                                        [ngClass]="getFeatureClass(feature)"
                                    ></span>
                                    {{ feature | translate }}
                                </li>
                            </ul>
                        </tr>
                    </div>
                </tbody>
            </table>
        </div>

        <button
            *ngIf="!editMode"
            class="btn btn-outline-dark m-3"
            (click)="editMode = true; tempCompany = company"
        >
            <span class="fa fa-edit"></span>{{ "MODIFY" | translate }}
        </button>
    </div>

    <!--Edit form-->
    <form *ngIf="editMode" [formGroup]="editCompanyForm" role="form">
        <div style="height: 100%; overflow-x: hidden; overflow-y: auto">
            <div class="ml-4 mr-4 pb-3">
                <div class="form-group row">
                    <label for="id" class="col-sm-4 col-form-label">ID:</label>

                    <div class="col-sm-7 mt-3 companyData">
                        <h6>{{ company.companyId }}</h6>
                    </div>
                </div>
                <hr />

                <div class="form-group row">
                    <label for="name" class="col-sm-4 col-form-label"
                        >{{ "COMPANY_NAME" | translate }}:
                    </label>

                    <div class="col-sm-7 mt-3 companyData">
                        <input
                            class="form-control form-control-sm"
                            id="name"
                            type="text"
                            formControlName="companyName"
                            required
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label for="address" class="col-sm-4 col-form-label"
                        >{{ "ADDRESS" | translate }}:
                    </label>

                    <div class="col-sm-7 mt-3 companyData">
                        <input
                            class="form-control form-control-sm"
                            id="address"
                            type="text"
                            formControlName="address"
                            required
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label for="zip" class="col-sm-4 col-form-label"
                        >{{ "POSTCODE" | translate }}:
                    </label>

                    <div class="col-sm-7 mt-3 companyData">
                        <input
                            class="form-control form-control-sm"
                            id="zip"
                            type="text"
                            formControlName="postCode"
                            required
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label for="city" class="col-sm-4 col-form-label"
                        >{{ "POSTOFFICE" | translate }}:
                    </label>

                    <div class="col-sm-7 mt-3 companyData">
                        <input
                            class="form-control form-control-sm"
                            id="city"
                            type="text"
                            formControlName="postOffice"
                            required
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label for="country" class="col-sm-4 col-form-label"
                        >{{ "COUNTRY" | translate }}:
                    </label>

                    <div class="col-sm-7 mt-3 companyData">
                        <select
                            class="form-control form-control-sm"
                            id="country"
                            name="country"
                            formControlName="country"
                        >
                            <option *ngIf="!company.country" [value]="null">
                                -
                            </option>
                            <option
                                *ngFor="let tz of timezones"
                                [value]="tz.country.code"
                            >
                                {{ tz.country.name }}
                            </option>
                        </select>
                        <p *ngIf="selectedCountry" class="mt-3">
                            {{ "TIME_ZONE" | translate }}:
                            <b>{{ setTimeZone(selectedCountry) }}</b>
                        </p>
                    </div>
                </div>
                <hr />

                <div class="form-group row">
                    <label for="phone" class="col-sm-4 col-form-label"
                        >{{ "CONTACT_PHONE" | translate }}:
                    </label>

                    <div class="col-sm-7 mt-3 companyData">
                        <input
                            class="form-control form-control-sm"
                            id="phone"
                            type="text"
                            formControlName="contactPhone"
                            required
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label
                        for="delivery-method"
                        class="col-sm-4 col-form-label"
                    >
                        {{ "DELIVERY_METHOD" | translate }}:
                    </label>

                    <div class="col-sm-7 mt-3 companyData">
                        <select
                            class="form-control form-control-sm"
                            id="delivery-method"
                            name="delivery"
                            formControlName="deliveryMethod"
                        >
                            <option value="EMAIL">
                                {{ "EMAIL" | translate }}
                            </option>
                            <option value="SMS">SMS</option>
                        </select>
                    </div>
                </div>
            </div>
            <br />

            <div class="row" *ngIf="errorDeleteMsg">
                <div class="col">
                    <div
                        class="alert alert-danger float-right mr-3"
                        style="width: fit-content"
                        role="alert"
                    >
                        {{ errorDeleteMsg | translate }}
                    </div>
                </div>
            </div>
            <div>
                <div class="form-group mx-3 mb-3">
                    <button
                        type="submit"
                        class="btn btn-primary mr-2"
                        (click)="onSubmit()"
                    >
                        <span class="fa fa-floppy-disk"></span>
                        {{ "SAVE" | translate }}
                    </button>

                    <button
                        class="btn btn-outline-dark"
                        (click)="editMode = false; createForm(tempCompany)"
                    >
                        <span class="fa fa-times"></span>
                        {{ "CANCEL" | translate }}
                    </button>

                    <button
                        class="warningBtnLessAttention float-right"
                        (click)="openDialog(confirmDeleteDialog)"
                    >
                        <span class="fa-light fa-trash-alt"></span>
                        {{ "DELETE" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<ng-template #confirmDeleteDialog let-modal>
    <div class="modal-header">
        <div>
            <h2 style="color: #e8413b" class="modal-title">
                {{ "WARNING" | translate }}!
            </h2>
            <h5 class="mt-2">
                {{ "DELETE_COMPANY_DATA_PERMANENTLY" | translate }}
            </h5>
        </div>
        <span class="float-right cursorPointer" (click)="modal.dismiss()">
            <span class="fa-light fa-xmark"></span>
        </span>
    </div>
    <div id="body" class="mx-4 mt-4">
        <p>
            {{ "DELETE_COMPANY_WARNING" | translate }}
            <strong>{{ company.companyName }}</strong> (<strong>{{
                company.companyId
            }}</strong
            >)
            <b style="color: #ad020c">{{
                "PERMANENT_DATA_LOSS" | translate
            }}</b>
        </p>

        <p>
            {{ "ALL_COMPANYS" }}
            <strong>{{ "DEVICES_LOWERCASE" | translate }}</strong>
            (<strong>{{ summary.deviceCount }}</strong
            >),
            <strong>{{ "STAFFS_LOWERCASE" | translate }}</strong> (<strong>{{
                summary.staffCount
            }}</strong
            >),
            <strong>{{ "LOCATIONS_LOWERCASE" | translate }}</strong> (<strong>{{
                summary.locationCount
            }}</strong
            >) {{ "AND" | translate }}
            <strong>{{ "ALERT_ROUTES_LOWERCASE" | translate }}</strong>
            (<strong>{{ summary.alertRouteCount }}</strong
            >) {{ "WILL_BE_DELETED" | translate }}
        </p>
    </div>
    <div id="footer" class="mx-3 mb-3">
        <h6 class="mt-3 ml-2">
            <strong style="color: #1d83e9">{{
                "ACTION_CANT_BE_UNDONE" | translate
            }}</strong>
        </h6>
        <hr />
        <ng-container *ngIf="deleteConfirmation">
            <button
                type="button"
                class="btn btn-danger float-left"
                (click)="deleteCompanyRequest(); modal.dismiss()"
            >
                <span class="fa-light fa-trash-alt"></span>
                {{ "DELETE" | translate }}
            </button>
            <button
                type="button"
                class="btn btn-secondary float-right"
                (click)="modal.dismiss()"
            >
                {{ "CANCEL" | translate }}
            </button>
        </ng-container>
    </div>
</ng-template>

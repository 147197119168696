import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from 'app/services/helper.service';
import { CrSyncService, CrAlert, CrAlertState, CrTeamMember } from '../../../services/crsync.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AddNoteComponent } from '../control-room-alert-panel-started/add-note/add-note.component';
import { DisplayAlarmReasonsComponent } from '../display-alarm-reasons/display-alarm-reasons.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { CalculateAlertTime, PickUpActivationName, PickUpAlertIcon } from 'app/pipes/pipes';
import { ControlRoomService } from 'app/services/control-room.service';
import { NotificationBadgeComponent } from 'app/components/notifications/notification-badge/notification-badge.component';

@Component({
    selector: 'control-room-alert-panel-forwarded',
    templateUrl: './control-room-alert-panel-forwarded.component.html',
    styleUrls: ['../control-room.component.css'],
    standalone: true,
    imports: [
      MatExpansionModule,
      CdkDropList,
      NgFor,
      CdkDrag,
      CdkDragHandle,
      NgClass,
      NgIf,
      MatTooltipModule,
      DisplayAlarmReasonsComponent,
      AddNoteComponent,
      TranslateModule,
      PickUpAlertIcon,
      PickUpActivationName,
      CalculateAlertTime,
      NotificationBadgeComponent
    ]
})
export class ControlRoomAlertPanelForwardedComponent implements OnInit {

  constructor(
    public helper: HelperService,
    private crSync: CrSyncService,
    private translate: TranslateService,
    public crService: ControlRoomService
  ) { }

  // @Input('alertsArray') alerts: any;
  // @Input('teamsAlertsArray') teamsAlerts: any;
  get alerts(){
    return this.crService.forwardedAlerts
  }

  get teamsAlerts(){
    return this.crService.teamsForwardedAlerts
  }
  // needed to use the enum CrAlertState in HTML template
  emaAlertState = CrAlertState;
  @Input('showTeamAlerts') showTeamAlerts: boolean;

  userId: any;

  getAlertStatus(alert: CrAlert): string {
    switch (alert.state) {
      case CrAlertState.Available:
        return this.translate.instant("WAITING_TO_BE_RESERVED");
      case CrAlertState.Reserved:
        return `${this.translate.instant("RESERVED_BY")}: ${this.getStaffDetails(alert, 1)}`;
      case CrAlertState.Started:
        return `${this.translate.instant("STARTED_BY")}: ${this.getStaffDetails(alert, 1)}`;
      default:
        break;
    }
  }

  //0 = phone number
  //1 = name
  //2 = online status
  getStaffDetails(alert: CrAlert, type: number): any {
    let staff = this.crSync.getTeamMember(alert.reservedBy);
    if (staff) {
      switch (type) {
        case 0:
          //Return first phone number. TODO SUPPORT FOR MULTIPLE NUMBERS
          if (staff.phoneNumbers.length > 0) {
            return staff.phoneNumbers[0]?.phoneNumber;
          }
          break;
        case 1:
          return staff.name;
        case 2:
          return staff.isOnline;
        default:
          break;
      }
    }
    return "";
  }

  getTeamMemberName(alert: CrAlert): string {
    let member = this.crSync.getTeamMember(alert.startedBy, alert.userId);
    if (member) return member.name || "";
    return "";
  }

  ngOnInit() {
  }

}

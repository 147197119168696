import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { ApiService } from 'app/services/api.service';
import { OnOnCameraGroup, OnOnProfile } from 'app/models/onon.model';
import { OnOnService } from 'app/services/onon.service';
import { MomentPipe } from '../../../pipes/pipes';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, NgClass } from '@angular/common';


@Component({
    selector: 'onon-monitor-card',
    templateUrl: './onon-monitor-card.component.html',
    styleUrls: ['./onon-monitor-card.component.css'],
    standalone: true,
    imports: [NgIf, RouterLink, MatFormFieldModule, MatSelectModule, FormsModule, NgFor, MatOptionModule, NgClass, TranslateModule, MomentPipe]
})
export class OnOnMonitorCardComponent implements OnChanges {
  constructor(
    private translate: TranslateService,
    private ononApi: OnOnService,
    private router: Router
  ) { }

  @Input('cameraGroup') cameraGroup: OnOnCameraGroup;
  @Input('profiles') profiles: OnOnProfile[];

  profileEdit: boolean = false;
  selectedDefaultProfile: any;
  selectedScheduledProfile: any;
  selectedScheduledProfileStartTime: string;
  selectedScheduledProfileStopTime: string;
  user: any;

  getIconPath(type: string): string {
    //Return unknown icon if type is not set
    if (!type) {
      return 'assets/img/ap_icons/unknown.png'
    }
    let iconPath: string = type;

    //Return nurse icon if nurse is in room
    if (this.cameraGroup.nurseInRoom) {
      return 'assets/img/ap_icons/nurse.png'
    }

    if (type.startsWith('safe')) {
      iconPath = 'safe';
    }
    if (type.startsWith('floor')) {
      iconPath = 'floor';
    }
    return `assets/img/ap_icons/${iconPath}.png`
  }

  getIconText(): string {
    if (!this.cameraGroup.state.state) {
      return "";
    }

    if (this.cameraGroup.nurseInRoom) {
      return this.translate.instant("AP_ICON_TEXT_NURSE")
    }

    let type = this.cameraGroup.state.state;
    type = type.toUpperCase();
    let translateString = `AP_ICON_TEXT_${type}`
    return this.translate.instant(translateString)
  }

  editProfile(): void {
    if (this.user.roleLevel == 40 || this.user.roleLevel >= 100) {
      this.selectedDefaultProfile = this.profiles.find(x => x.id === this.cameraGroup.activeProfile?.id);
      if (this.cameraGroup.schedule && this.cameraGroup.schedule[0]) {
        this.selectedScheduledProfile = this.profiles.find(x => x.id === this.cameraGroup.schedule[0].profileId)
        this.selectedScheduledProfileStartTime = this.zeroPad(this.cameraGroup.schedule[0].startTime.hour, 2) + ":" + this.zeroPad(this.cameraGroup.schedule[0].startTime.minute, 2)
        this.selectedScheduledProfileStopTime = this.zeroPad(this.cameraGroup.schedule[0].stopTime.hour, 2) + ":" + this.zeroPad(this.cameraGroup.schedule[0].stopTime.minute, 2)
      }
      this.profileEdit = !this.profileEdit;
    }
  }

  saveProfile(): void {
    let data: any = {
      cameraGroupId: this.cameraGroup.id,
      defaultProfileId: this.selectedDefaultProfile.id
    }

    if (this.selectedScheduledProfile) {
      data.schedule = [
        {
          profileId: this.selectedScheduledProfile.id,
          startTime: this.selectedScheduledProfileStartTime,
          stopTime: this.selectedScheduledProfileStopTime
        }
      ]
    }

    this.ononApi.changeActiveCameraGroupProfile(data).subscribe(res => {
      this.cameraGroup.activeProfile = this.profiles.find(x => x.id === this.selectedDefaultProfile.id)
      this.profileEdit = false;
    }, err => {

    })
  }

  calculateStateDuration(): string {
    //Return empty string if event timestamp is null or zero value
    if (!this.cameraGroup.state.time || this.cameraGroup.state.time === "0001-01-01T00:00:00Z") return "";
    let momentDate = moment(this.cameraGroup.state.time);
    return momentDate.fromNow();
  }

  zeroPad(num, places): string {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  ngOnChanges() {
    this.user = JSON.parse(localStorage.getItem("user"));
  }


}


<div *ngIf="PDFReportRequestSuccess" class="pdfRaportAlert" (click)="PDFReportRequestSuccess = false">
    <div class="alert alert-success pdf-report-alert">
        {{ "REPORT_REQUEST_PDF_SUCCESS" | translate }} <i class="fa-light fa-xmark"></i>
    </div>

    <div class="alert alert-warning pdf-report-alert">
        {{ "REPORT_REQUEST_PDF_NOTE" | translate }} <i class="fa-light fa-xmark"></i>
    </div>
</div>

<div
    *ngIf="PDFReportRequestFailed"
    (click)="resetPDFLoadingError()"
    class="pdfRaportAlert">
    <div class="alert alert-danger">
        <span *ngIf="!limitExceed">{{ "REPORT_REQUEST_PDF_FAILED" | translate }}</span>
        <span *ngIf="limitExceed">{{ "REPORT_REQUEST_PDF_ALERT_LIMIT_EXCEED" | translate }} {{alertLimitValue}}.</span>
        <i class="fa-light fa-xmark"></i>
    </div>
</div>

<div class="ml-3">
    <h3>{{ "NAV_REPORT" | translate }}</h3>
</div>

<!--Tabs-->
<div class="row mt-3 ml-1 pb-2">
    <div class="col">
        <span
            class="cursorPointer"
            [ngClass]="selectedTab === 'Alert' ? 'darkUnderline' : 'defaultUnderline'"
            (click)="changeTab('Alert')">
            <span class="tabLabel" [ngClass]="{ highlightedLabel: selectedTab === 'Alert'}">
                {{ "ALERTS_TAB" | translate }}
            </span>
        </span>

        <ng-container *ngIf="cameraVisitsEnabled">
            <span class="cursorDefault defaultUnderline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <span
                class="cursorPointer"
                [ngClass]="selectedTab === 'CameraVisit' ? 'darkUnderline' : 'defaultUnderline'"
                (click)="changeTab('CameraVisit')">
                <span class="tabLabel" [ngClass]="{ highlightedLabel: selectedTab === 'CameraVisit'}">
                    {{ "CAMERA_EVENTS" | translate }}
                </span>
            </span>
        </ng-container>

        <ng-container *ngIf="lockEventsEnabled">
            <span class="cursorDefault defaultUnderline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <span
                class="cursorPointer"
                [ngClass]="selectedTab === 'LockEvent' ? 'darkUnderline' : 'defaultUnderline'"
                (click)="changeTab('LockEvent')">
                <span class="tabLabel" [ngClass]="{ highlightedLabel: selectedTab === 'LockEvent'}">
                    {{ "LOCK_EVENTS" | translate }}
                </span>
            </span>
        </ng-container>

    </div>
</div>

<h6 class="ml-3 mt-3"><strong>{{ "DATE_AND_TIME" | translate }}</strong></h6>

<div class="mb-2 mt-3 pl-3 filterContent" role="toolbar">
    <mat-form-field class="mr-2" style="margin-bottom: -10px" appearance="outline">
        <mat-label>{{ "REPORT_START_DATE" | translate }}</mat-label>
        <input
            matInput
            [max]="currentDate"
            [matDatepicker]="filterStartDatePicker"
            [(ngModel)]="filterStartDay" />
        <mat-datepicker-toggle matSuffix [for]="filterStartDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #filterStartDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="mr-2" appearance="outline">
        <mat-label>{{ "REPORT_END_DATE" | translate }}</mat-label>
        <input
            placeholder="{{ 'REPORT_END_DATE' | translate }}"
            matInput
            [min]="filterStartDay"
            [max]="currentDate"
            [matDatepicker]="filterEndDayPicker"
            [(ngModel)]="filterEndDay" />
        <mat-datepicker-toggle matSuffix [for]="filterEndDayPicker"></mat-datepicker-toggle>
        <mat-datepicker #filterEndDayPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="mr-2" appearance="outline">
        <mat-label>{{ "REPORT_START_TIME" | translate }}</mat-label>
        <input
            pattern="^([0-9]{2}):([0-9]{2})$"
            [(ngModel)]="filterStartTime"
            matInput
            type="text"
            placeholder="{{ 'REPORT_START_TIME' | translate }}" />
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>{{ "REPORT_END_TIME" | translate }}</mat-label>
        <input
            pattern="^([0-9]{2}):([0-9]{2})$"
            [(ngModel)]="filterEndTime"
            matInput
            type="text"
            placeholder="{{ 'REPORT_END_TIME' | translate }}" />
    </mat-form-field>

    <ng-container *ngFor="let checkbox of weekDayFilterList">
        <mat-checkbox
            class="checkbox"
            [value]="checkbox"
            [checked]="checkbox.isChecked"
            (change)="updateWeekDayFilter($event)">
            {{ checkbox.title | translate }}
        </mat-checkbox>
    </ng-container>
</div>

<b class="pl-3">{{ "FILTER" | translate }}</b>

<div class="form-inline ml-2 mb-2 pl-3" role="toolbar" aria-label="">
    <div class="row">
        <div class="form-group m-2" *ngIf="advancedFilter.location.filterList">
            <ngx-bootstrap-multiselect
                [options]="advancedFilter.location.filterList"
                [settings]="advancedFilter.settings"
                [texts]="advancedFilter.location.texts"
                [(ngModel)]="advancedFilter.location.selectedList"
                name="advancedFilterLocations">
            </ngx-bootstrap-multiselect>
        </div>

        <div class="form-group m-2" *ngIf="advancedFilter.device.filterList">
            <ngx-bootstrap-multiselect
                (dropdownClosed)="onDeviceListChange($event)"
                (ngModelChange)="onDeviceListChange($event)"
                [options]="advancedFilter.device.filterList"
                [settings]="advancedFilter.settings"
                [texts]="advancedFilter.device.texts"
                [(ngModel)]="advancedFilter.device.selectedList"
                name="advancedFilterDevices">
            </ngx-bootstrap-multiselect>

            <input
                *ngIf="customDeviceSearch"
                [(ngModel)]="customDeviceQuery"
                class="form-control border border-primary ml-2"
                type="text"
                placeholder="{{ 'CUSTOM_DEVICE_ID' | translate }}" />
        </div>

        <div class="form-group m-2" *ngIf="advancedFilter.deviceTypes.filterList">
            <ngx-bootstrap-multiselect
                (ngModelChange)="onDeviceTypeFilterChange($event)"
                [options]="advancedFilter.deviceTypes.filterList"
                [settings]="advancedFilter.settings"
                [texts]="advancedFilter.deviceTypes.texts"
                [(ngModel)]="selectedDeviceTypes"
                name="advancedFilterDeviceTypes">
            </ngx-bootstrap-multiselect>
        </div>

        <div class="form-group m-2"
            *ngIf="advancedFilter.activations.filterList"
            [hidden]=" !advancedFilter.deviceTypes.selectedList || advancedFilter.deviceTypes.selectedList?.length === 0"
        >
            <ngx-bootstrap-multiselect
                [options]="advancedFilter.activations.filterList"
                [settings]="advancedFilter.settings"
                [texts]="advancedFilter.activations.texts"
                [(ngModel)]="advancedFilter.activations.selectedList"
                name="advancedFilterActivations">
            </ngx-bootstrap-multiselect>
        </div>

        <div class="form-group m-2" *ngIf="advancedFilter.basestation.filterList">
            <ngx-bootstrap-multiselect
                (dropdownClosed)="onBasestationListChange($event)"
                (ngModelChange)="onBasestationListChange($event)"
                [options]="advancedFilter.basestation.filterList"
                [settings]="advancedFilter.settings"
                [texts]="advancedFilter.basestation.texts"
                [(ngModel)]="advancedFilter.basestation.selectedList"
                name="advancedFilterBasestations">
            </ngx-bootstrap-multiselect>

            <input
                *ngIf="customBasestationSearch"
                [(ngModel)]="customBasestationQuery"
                class="form-control border border-primary ml-2"
                type="text"
                placeholder="{{ 'CUSTOM_BASESTATION_ID' | translate }}" />
        </div>

        <div class="form-group m-2" *ngIf="advancedFilter.alertRoute.filterList">
            <ngx-bootstrap-multiselect
                [options]="advancedFilter.alertRoute.filterList"
                [settings]="advancedFilter.settings"
                [texts]="advancedFilter.alertRoute.texts"
                [(ngModel)]="advancedFilter.alertRoute.selectedList"
                name="advancedFilterAlertRoutes">
            </ngx-bootstrap-multiselect>
        </div>

        <div class="form-group m-2" *ngIf="advancedFilter.personnel.filterList">
            <ngx-bootstrap-multiselect
                [options]="advancedFilter.personnel.filterList"
                [settings]="advancedFilter.settings"
                [texts]="advancedFilter.personnel.texts"
                [(ngModel)]="advancedFilter.personnel.selectedList"
                name="advancedFilterPersonnel">
            </ngx-bootstrap-multiselect>
        </div>

        <!--
        <div class="form-group m-2" *ngIf="advancedFilter.eventTypes.filterList">
            <ngx-bootstrap-multiselect
                [options]="advancedFilter.eventTypes.filterList"
                [settings]="advancedFilter.settings"
                [texts]="advancedFilter.eventTypes.texts"
                [(ngModel)]="advancedFilter.eventTypes.selectedList"
                name="advancedFilterPersonnel">
            </ngx-bootstrap-multiselect>
        </div>
        -->

        <div *ngIf="advancedFilter.alarmReasons.filterList" class="form-group m-2">
            <app-category-multiselect-button
                [name]="'ALARM_REASON' | translate"
                [(options)]="advancedFilter.alarmReasons.filterList"
                [(selected)]="advancedFilter.alarmReasons.selectedList">
            </app-category-multiselect-button>
        </div>

        <div class="form-inline m-2" role="toolbar" aria-label="">
            <button class="btn btn-outline-primary btn-sm" (click)="clearFilters(); search()">
                <span class="fa-light fa-xmark"></span>
                {{ "CLEAR_FILTERS" | translate }}
            </button>
        </div>
    </div>
</div>

<div class="row mt-2 pb-5">
    <button class="btn btn-primary" style="margin-left: 31.5px;" (click)="search()">
        <span class="fa-light fa-search"></span> {{ "SEARCH" | translate }}
    </button>
</div>

<div class="row" style="background: #fff">
    <div class="col-md-12">

        <div class="row pb-3">
            <div class="col d-flex">
                <h4 class="align-self-end m-0 ml-3" style="color: #001b4b">
                    {{ "LATEST_EVENTS" | translate }}
                </h4>
            </div>

            <app-download-panel
                [PDFReportRequestSuccess]="PDFReportRequestSuccess"
                (PDFReportRequestSuccessEvent)="PDFReportRequestSuccess=$event"

                [PDFReportRequestFailed]="PDFReportRequestFailed"
                (PDFReportRequestFailedEvent)="PDFReportRequestFailed=$event"

                [limitExceed]="limitExceed"
                (limitExceedEvent)="limitExceed=$event"

                [user]="user"
                [filterStartDay]="filterStartDay"
                [filterEndDay]="filterEndDay"
                [filterStartTime]="filterStartTime"
                [filterEndTime]="filterEndTime"
                [advancedFilter]="advancedFilter"
                [weekDayFilter]="weekDayFilter"
                [customDeviceOption]="customDeviceOption"
                [customDeviceQuery]="customDeviceQuery"
                [customBasestationOption]="customBasestationOption"
                [customBasestationQuery]="customBasestationQuery"
                [cameraVisitsEnabled]="cameraVisitsEnabled"
                [selectedTab]="selectedTab">
            </app-download-panel>

            <!-- <div class="load-r-content col d-flex justify-content-end">
        <mat-accordion multi>
          <mat-expansion-panel
            matTooltip="{{ 'REPORT_REQUEST_TOOLTIP' | translate }}"
            matTooltipPosition="above"
            class="mb-2"
            [(expanded)]="PDFRequestPanelExpanded"
            (click)="togglePDFChecker()"
          >
            <mat-expansion-panel-header>
              <div>
                <span class="fa-light fa-download"></span>
                {{ "DOWNLOAD_ALERTS" | translate }}&nbsp;&nbsp;
              </div>
            </mat-expansion-panel-header>

            <div class="form-inline">
              <button class="btn btn-outline-primary btn-block" (click)="downloadCsvOrJson('csv')" [disabled]="loadingCSV">
                <span *ngIf="loadingCSV; else CSV">
                  {{'LOADING_CSV'| translate}}...
                  <img class="fadeLoader centerLoader" style="width: 30px" src="assets/img/loading_logo_small.png">
                </span>

                <ng-template #CSV>CSV</ng-template>
              </button>

              <button
                class="btn btn-outline-primary btn-block"
                [disabled]="loadingPDF && !pdfError"
                (click)="triggerPdfGeneration()"
              >
                <span *ngIf="loadingPDF; else PDF">
                  <span *ngIf="pdfError; else PDFOK">{{'PDF_RETRY'| translate}}</span>
                  <ng-template #PDFOK>
                    {{'LOADING_PDF'| translate}}...
                    <img class="fadeLoader centerLoader" style="width: 30px" src="assets/img/loading_logo_small.png">
                  </ng-template>
                </span>

                <ng-template #PDF>PDF</ng-template>
              </button>

              <button
                *ngIf="selectedTab === 'Alert'"
                class="btn btn-outline-primary btn-block"
                (click)="downloadCsvOrJson('json')"
                [disabled]="loadingJSON"
              >
                <span *ngIf="loadingJSON; else JSON">
                  {{'LOADING_JSON'| translate}}...
                  <img class="fadeLoader centerLoader" style="width: 30px" src="assets/img/loading_logo_small.png">
                </span>

                <ng-template #JSON>JSON</ng-template>
              </button>
        </div>
        </mat-expansion-panel>
        </mat-accordion>
    </div> -->
        </div>

        <div [ngSwitch]="selectedTab">
            <app-report-alert-table
                *ngSwitchCase="'Alert'"
                [alerts]="events"
                [alarmReasonTranslations]="alarmReasonTranslations"
                [infiniteScrollDisabled]="infiniteScrollDisabled"
                (submitAlertNote)="submitAlertNote($event)"
                (loadNextAlerts)="loadNextEvents()">
            </app-report-alert-table>

            <app-report-camera-event-table
                *ngSwitchCase="'CameraVisit'"
                [cameraEvents]="events"
                [infiniteScrollDisabled]="infiniteScrollDisabled"
                (loadNextEvents)="loadNextEvents()">
            </app-report-camera-event-table>

            <app-report-lock-event-table
                *ngSwitchCase="'LockEvent'"
                [lockEvents]="events"
                [infiniteScrollDisabled]="infiniteScrollDisabled"
                (loadNextEvents)="loadNextEvents()">
            </app-report-lock-event-table>
        </div>

        <div *ngIf="infiniteScrollDisabled && eventsLoaded" class="d-flex justify-content-center pb-3">
            <strong>{{ "NO_MORE_RESULTS" | translate }}</strong>
        </div>
        <div *ngIf="(!events || events.length === 0) && eventsLoaded">
            {{ "NO_EVENTS" | translate }}
        </div>
        <div *ngIf="!eventsLoaded">
            <img
                class="fadeLoader centerLoader logoSmall"
                src="assets/img/loading_logo.svg" />
        </div>
    </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ExpandCollapseAnimation } from 'app/animations';
import { DeviceStatusResolverPipe } from '../device-status-resolver.pipe';
import { DeviceCompanyGroup } from 'app/models/device.model';
import { FeatureBadgeComponent } from 'app/components/feature-badge/feature-badge.component';
import { ApiService } from 'app/services/api.service';
import { BatteryIconPipe } from 'app/pipes/pipes';
import { IDeviceHistory } from 'app/models/device-history.model';
import { DeviceHistoryLogsComponent } from './device-history-logs/device-history-logs.component';
import { CommonModule } from '@angular/common';

type CollapseState = "expanded" | "collapsed";
const ERROR_MSG = "ERROR";

@Component({
  selector: 'app-device-information',
  standalone: true,
  imports: [CommonModule, TranslateModule, FeatureBadgeComponent, DeviceHistoryLogsComponent, DeviceStatusResolverPipe, BatteryIconPipe],
  templateUrl: './device-information.component.html',
  styleUrls: ['./device-information.component.css'],
  animations: [ExpandCollapseAnimation]
})
export class DeviceInformationComponent implements OnInit {

  constructor(private api: ApiService) {}

  @Input() device: DeviceCompanyGroup;

  batteryStatus: number; // Device's battery state
  deviceHistory: IDeviceHistory;

  deviceInfoCollapseState: CollapseState = "expanded";
  careRecipientCollapseState: CollapseState = "expanded";
  deviceHistoryCollapseState: CollapseState = "expanded"

  deviceHistoryLoading: boolean = false;
  errorMsg: string;

  toggleDeviceInfo(): void {
    this.deviceInfoCollapseState =
      this.deviceInfoCollapseState === "expanded"
        ? "collapsed"
        : "expanded";
  }

  toggleCareRecipient(): void {
    this.careRecipientCollapseState =
      this.careRecipientCollapseState === "expanded"
        ? "collapsed"
        : "expanded";
  }

  toggleDeviceHistory(): void {
    this.deviceHistoryCollapseState =
      this.deviceHistoryCollapseState === "expanded"
        ? "collapsed"
        : "expanded";
  }

  getBatteryStatus(deviceId: string): void {
    this.api.getDeviceBatteryStatus(deviceId).subscribe((batteryStatus) => {
      this.batteryStatus = batteryStatus;
    });
  }

  getDeviceHistory(deviceId: string): void {
    this.startLoading();

    this.api.getDeviceHistory(deviceId)
      .subscribe(
        (res) => this.handleResponse(res),
        (error) => this.handleError(error)
      );
  }

  handleResponse(response: IDeviceHistory): void {
    this.deviceHistory = response;
    this.stopLoading();
  }

  handleError(error: any): void {
    console.warn(error);
    this.errorMsg = ERROR_MSG;
    this.stopLoading();
  }

  startLoading(): void {
    this.deviceHistoryLoading = true;
  }

  stopLoading(): void {
    this.deviceHistoryLoading = false;
  }

  ngOnInit(): void {
    this.getBatteryStatus(this.device.devId);
    this.getDeviceHistory(this.device.devId);
  }

}

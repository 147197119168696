<h3 class="ml-2">{{ "NAV_STAFF_MANAGEMENT" | translate }}</h3>

<div class="row">
  <div class="col-12">
    <div class="row mb-2 ml-1 align-items-baseline">
      <span class="fa-solid fa-user fa-fw staffSuperUser ml-2"></span>
      <small> = {{ "STAFF_CUSTOMER_ADMIN" | translate }}</small>

      <span class="fa-light fa-user fa-fw staffMainUser ml-2"></span>
      <small> = {{ "STAFF_MAIN_USER" | translate }}</small>

      <span class="fa-light fa-user fa-fw staffUser ml-2"></span>
      <small> = {{ "STAFF_USER" | translate }}</small>

      <span class="fa-light fa-user fa-fw ml-2"></span>
      <small> = {{ "STAFF_READONLY" | translate }}</small>

      <span *ngIf="featureEnabled('EveronMobileApp')" class="fa-light fa-user fa-fw ml-2 staffEMA"></span>
      <small *ngIf="featureEnabled('EveronMobileApp')"> = {{ "EveronMobileApp" | translate }}</small>

      <span *ngIf="featureEnabled('AnnaPerenna') || featureEnabled('OnOn')" class="fa-light fa-user fa-fw ml-2 staffONONMU"></span>
      <small *ngIf="featureEnabled('AnnaPerenna') || featureEnabled('OnOn')"> = {{ "STAFF_ONON_MAIN_USER" | translate }}</small>
    </div>

    <div class="row mt-3 d-flex justify-content-between">
      <div class="ml-4" *ngIf="user.roleLevel >= 50">
        <button class="btn btn-primary" (click)="addNewStaff()">
          <span class="fa-light fw fa-plus"></span>
          {{ "ADD_NEW_STAFF" | translate }}
        </button>

        <button *ngIf="!filterON" class="btn btn-light ml-2" (click)="showLocationsWithoutStaff = !showLocationsWithoutStaff">
          {{ (showLocationsWithoutStaff ? "HIDE_LOCATIONS_WITHOUT_STAFF" : "SHOW_LOCATIONS_WITHOUT_STAFF") | translate }}
        </button>
      </div>

      <div class="d-flex flex-row justify-content-end">
        <mat-form-field class="staffFilterInput" appearance="outline">
          <input
            matInput
            placeholder="{{ 'SEARCH_BY_NAME_ID_EMAIL_GSM' | translate }}... "
            #staffSearchInput
            (keydown.enter)="onEnterKeydown($event)"
          />

          <button
            *ngIf="filterON"
            class="staffClearFilterBtn mr-2"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearFilter()"
            matTooltip="{{ 'CLEAR_FILTER' | translate }}"
            matTooltipPosition="above"
            matTooltipClass="clearFilterTooltipStyle"
            matTooltipShowDelay="50"
            matTooltipHideDelay="150"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <button class="btn btn-primary ml-2 mr-4" style="width: 200px" #staffSearchButton>
          <span class="fa-light fa-magnifying-glass"></span>
          {{ "SEARCH" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3 p-2">
  <div [ngClass]="{ 'd-none d-xl-block col-xl-6': selectedStaff || selectedLocation, 'col-lg-12': true }">
    <mat-card *ngIf="!staffExists(staffLocationList) && filterON">
      {{ "NO_MATCHES" | translate }}
    </mat-card>

    <ng-container *ngFor="let location of staffLocationList; let i = index">
      <div
        *ngIf="showLocationsWithoutStaff || location.staff" class="card"
        [ngClass]="{ 'ml-4': !isCustomerLocation(location) && showCustomerLocation() }"
      >
        <div class="card-header cursorPointerHover" (click)="location.collapseState = !location.collapseState">
          <h5>
            <span *ngIf="isCustomerLocation(location)" class="fa-fw mr-2" [ngClass]="location.collapseState ? 'fa-light fa-user' : 'fa-solid fa-user'"></span>
            <span *ngIf="!isCustomerLocation(location)" class="fa-fw mr-2" [ngClass]="location.collapseState ? 'fa-light fa-house' : 'fa-solid fa-house'"></span>

            {{ location.name }}

            <span *ngIf="location.networkFailureState" class="ml-3 text-danger locationNetworkFailureText">
              <span class="fa-light fa-fw fa-wifi-slash"></span>
              {{ "NETWORK_FAILURE" | translate }}
            </span>

            <span
              matTooltip="{{ 'TOOLTIP_EDIT_LOCATION' | translate }}"
              [ngClass]="user.roleLevel >= 50 ? 'fa-light fa-pen-to-square' : 'fa-light fa-info'"
              class="float-right mr-2 cursorPointerHover"
              (click)="editLocation(location); $event.stopPropagation()"
            ></span>
          </h5>

          <small>{{ "NR_OF_STAFF" | translate }}: {{ location.staff ? location.staff.length : 0 }}</small>
        </div>

        <div [hidden]="!location.collapseState" class="card-body-white">
          <table class="table table-borderless table-hover table-responsive" matSort (matSortChange)="sortData($event, i)">
            <thead>
              <tr class="border-bottom">
                <th mat-sort-header="usingLevel" style="width: 1%"></th>
                <th mat-sort-header="id">ID</th>
                <th mat-sort-header="username">
                  {{ "USERNAME" | translate }}
                </th>
                <th mat-sort-header="fullName">
                  {{ "NAME" | translate }}
                </th>
                <th mat-sort-header="emaOnline" *ngIf="featureEnabled('EveronMobileApp')">
                  {{ "EMA" | translate }}
                  <span matTooltip="{{ 'TOOLTIP_EMA' | translate }}" class="fa fa-fw fa-info-circle"></span>
                </th>
                <th mat-sort-header="gsmNumber">
                  {{ "GSM_NUMBER" | translate }}
                </th>
                <th mat-sort-header="email">
                  {{ "EMAIL" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                matTooltip="{{ 'TOOLTIP_EDIT_STAFF' | translate }}"
                class="cursorPointer"
                [ngClass]="{ lockedStaff: staff.locked }"
                *ngFor="let staff of location.staff"
                (click)="editStaff(staff)"
              >
                <td><span class="fa-fw fa-lg" [ngClass]="getStaffIcon(staff)"></span></td>
                <td [innerHTML]="staff.id | highlight : filterCriteria"></td>
                <td [innerHTML]="staff.username | highlight : filterCriteria"></td>
                <td [innerHTML]="staff.fullName | highlight : filterCriteria"></td>

                <td *ngIf="featureEnabled('EveronMobileApp')">
                  <i *ngIf="!staff.emaOnline" class="fa-regular fa-circle mr-2 emaOffline"></i>
                  <i *ngIf="staff.emaOnline" class="fa-solid fa-circle-check mr-2 emaOnline"></i>
                </td>

                <td [innerHTML]="staff.gsmNumber | highlight : filterCriteria"></td>
                <td [innerHTML]="staff.email | highlight : filterCriteria"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>

    <div *ngIf="!staffList">
      <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg"/>
    </div>
  </div>

  <div class="col-xl-6 col-lg-12">
    <div *ngIf="loadingStaffInformation">
      <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg"/>
    </div>

    <staff-info
      *ngIf="selectedStaff && !loadingStaffInformation"
      [staff]="selectedStaff"
      [locations]="locationList"
      (closeStaffCardEvent)="editStaff($event)"
      (staffUpdateEvent)="staffUpdated($event)"
      (staffInsertEvent)="staffInserted($event)"
      (staffDeleteEvent)="staffDeleted($event)"
    ></staff-info>

    <location-info
      *ngIf="selectedLocation && !selectedStaff"
      [location]="selectedLocation"
      (locationDeletedEvent)="locationDeleted($event)"
      (locationUpdateEvent)="locationUpdated($event)"
      (closeLocationCardEvent)="closeLocation($event)"
    ></location-info>
  </div>
</div>

import { throttleTime } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { OnOnProfile, OnOnBedAlertModes } from 'app/models/onon.model';
import { ApiService } from 'app/services/api.service';
import { OnOnService } from 'app/services/onon.service';
import { Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgFor, NgIf } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'onon-profile-management',
    templateUrl: './onon-profile-management.component.html',
    styleUrls: ['./onon-profile-management.component.css'],
    standalone: true,
    imports: [RouterLink, MatFormFieldModule, MatSelectModule, FormsModule, MatOptionModule, NgFor, NgIf, ReactiveFormsModule, MatSlideToggleModule, MatTooltipModule, TranslateModule]
})
export class OnOnProfileManagementComponent implements OnInit, OnDestroy {


  constructor(
    private ononApi: OnOnService,
    private fb: UntypedFormBuilder
  ) {
  }

  monitor: any;
  monitorId: any;
  profiles: OnOnProfile[];
  selectedProfile: OnOnProfile;
  profileManagementForm: UntypedFormGroup;
  saveSuccess: boolean = false;
  saveError: boolean = false;
  deleteConfirm: boolean = false;
  deleteError: boolean = false;

  formSubmitSubject = new Subject();

  profileChanged(data: any) {
    this.createProfileManagementForm();
  }

  createNewProfile(): void {
    //Create new profile with all settings disabled and set it as selected profile
    this.selectedProfile = new OnOnProfile();
    this.createProfileManagementForm();
  }

  createProfileManagementForm(): void {
    this.profileManagementForm = this.fb.group({
      profileName: [this.selectedProfile.name, Validators.required],
      profileMonitoringEnabled: this.selectedProfile.enabled,
      profileBedOutMode: this.selectedProfile.bedAlerts.mode,
      profileBedOutDelay: this.selectedProfile.bedAlerts.delayMinutes,
      profileRoomOutEnabled: this.selectedProfile.exitAlerts.enabled,
      profileRoomOut: this.selectedProfile.exitAlerts.delayMinutes,
      profileMaxToiletTimeEnabled: this.selectedProfile.toiletAlerts.enabled,
      profileMaxToiletTime: this.selectedProfile.toiletAlerts.delayMinutes,
      profileMaxFloorTimeEnabled: this.selectedProfile.fallAlerts.enabled,
      profileMaxFloorTime: this.selectedProfile.fallAlerts.delayMinutes,
      profileTwoPersonsInRoom: this.selectedProfile.manyPersonsAlerts.enabled,
      profileSendImage: this.selectedProfile.sendImages,
      profileImageType: this.selectedProfile.sentImagesType
    })
  }

  submitProfileManagementForm(): void {
    this.saveError = false;
    this.saveSuccess = false;
    let changedProfile: OnOnProfile = JSON.parse(JSON.stringify(this.selectedProfile));
    changedProfile.name = this.profileManagementForm.value.profileName;
    changedProfile.enabled = this.profileManagementForm.value.profileMonitoringEnabled;
    changedProfile.bedAlerts.mode = this.profileManagementForm.value.profileBedOutMode;
    changedProfile.bedAlerts.delayMinutes = this.profileManagementForm.value.profileBedOutMode === OnOnBedAlertModes.AlertWithDelay ? parseFloat(this.profileManagementForm.value.profileBedOutDelay) : 0;
    changedProfile.exitAlerts.enabled = this.profileManagementForm.value.profileRoomOutEnabled;
    changedProfile.exitAlerts.delayMinutes = this.profileManagementForm.value.profileRoomOutEnabled ? parseFloat(this.profileManagementForm.value.profileRoomOut) : 0;
    changedProfile.toiletAlerts.enabled = this.profileManagementForm.value.profileMaxToiletTimeEnabled;
    changedProfile.toiletAlerts.delayMinutes = this.profileManagementForm.value.profileMaxToiletTimeEnabled ? parseFloat(this.profileManagementForm.value.profileMaxToiletTime) : 0;
    changedProfile.fallAlerts.enabled = this.profileManagementForm.value.profileMaxFloorTimeEnabled;
    changedProfile.fallAlerts.delayMinutes = this.profileManagementForm.value.profileMaxFloorTimeEnabled ? parseFloat(this.profileManagementForm.value.profileMaxFloorTime) : 0;
    changedProfile.manyPersonsAlerts.enabled = this.profileManagementForm.value.profileTwoPersonsInRoom;
    changedProfile.sendImages = this.profileManagementForm.value.profileSendImage;
    changedProfile.sentImagesType = this.profileManagementForm.value.profileImageType;

    //If changed profile has ID then modify existing. Otherwise create new profile
    if (changedProfile.id) {
      this.ononApi.updateProfile(changedProfile).subscribe(res => {
        this.saveSuccess = true;
        //Replace saved profile in original array and set it as selected
        let i = this.profiles.indexOf(this.profiles.find(x => x.id === changedProfile.id));
        this.profiles[i] = changedProfile;
        this.selectedProfile = this.profiles[i];
      }, err => {
        this.saveError = true;
      })
    } else {
      this.ononApi.insertNewProfile(changedProfile).subscribe(res => {
        this.profiles.push(res);
        this.selectedProfile = res;
       this.saveSuccess = true;
      }, err => {
        this.saveError = true;
      })
    }
  }

  deleteProfile(): void {
    this.saveError = false;
    this.saveSuccess = false;
    this.deleteError = false;
    this.ononApi.deleteProfile(this.selectedProfile.id).subscribe(res => {
        this.profiles.splice(this.profiles.indexOf(this.selectedProfile), 1);
        this.selectedProfile = null;
        this.deleteConfirm = false;
    }, err => {
        this.deleteError = true;
        this.deleteConfirm = false;
    })
  }


  ngOnInit() {
    this.selectedProfile = null;
    this.ononApi.getCustomerProfiles().subscribe(res => {
      this.profiles = res;
    })

     // Initialize form submit subject with 3 second throttle time to prevent multiple submits
     this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe(() => {
        this.submitProfileManagementForm();
      });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

import { AccessMode } from "../../models/camera.model";
import { Pipe, PipeTransform } from "@angular/core";
import { ICameraGroup } from "app/models/camera.model";
import { MultiSelectOption } from "app/shared/ss-multiselect-dropdown-settings.shared";
import { IGroupedOption, IOption } from "../multiselect-dropdown/multiselect-dropdown.component";

@Pipe({
  name: "cameraGroupFullAddress",
  pure: true,
  standalone: true,
})
export class CameraGroupFullAddressPipe implements PipeTransform {
  transform(cameraGroup: ICameraGroup): string {
    const address = cameraGroup.address;
    const city = cameraGroup.postOffice;
    const postCode = cameraGroup.postCode;

    const parts = [address, postCode, city].filter(Boolean);
    return parts.join(", ");
  }
}

@Pipe({
  name: "locationName",
  pure: true,
  standalone: true,
})
export class LocationNamePipe implements PipeTransform {
  transform(
    serverIdLocationId: string,
    locations: { id: string; name: string }[]
  ): string {
    const location = locations.find(
      (option) => option.id === serverIdLocationId.substring(8)
    );
    return location?.name || "";
  }
}

@Pipe({
  name: "deviceName",
  pure: true,
  standalone: true,
})
export class DeviceNamePipe implements PipeTransform {
  transform(deviceId: string, devices: IOption[]): string {
    const device = devices.find((option) => option.value === deviceId);
    return device?.name || "";
  }
}

@Pipe({
  name: "receiverTeamName",
  pure: true,
  standalone: true,
})
export class ReceiverTeamNamePipe implements PipeTransform {
  transform(
    groupId: string,
    receiverTeams: IGroupedOption[]
  ): string {
    const receiverTeam: IOption = receiverTeams
      .reduce((result, obj) => {
        return result.concat(obj.options);
      }, [])
      .find((team: IOption) => team.value === groupId);

    return receiverTeam?.name || "";
  }
}

@Pipe({
  name: "accessModeLangCode",
  pure: true,
  standalone: true,
})
export class AccessModeToLangugeCodePipe implements PipeTransform {
  transform(accessMode: AccessMode): string {
    return accessMode === AccessMode.Always
      ? "ACCESS_MODE_ALWAYS"
      : accessMode === AccessMode.VisitOnAlert
        ? "VISIT_ON_ALERT"
        : "SCHEDULED_VISIT";
  }
}

<div class="card">
    <div id="cardHeader">
        <h4 class="mainDarkBlueText font-weight-normal">
            {{ "ALERTS_PER_CARE_RECIPIENT" | translate }}
        </h4>

        <div id="dropdownContainer">
            <button
                class="custom-dropdown-btn"
                (click)="showDropdownMenu = true"
                #dropdownBtn
            >
                <i class="fa-solid fa-ellipsis"></i>
            </button>

            <div
                class="custom-dropdown-menu"
                *ngIf="showDropdownMenu"
                #dropdownMenu
            >
                <div
                    class="custom-dropdown-option"
                    id="downloadCSV"
                    (click)="exportCSV()"
                >
                    {{ "DOWNLOAD_CSV" | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex align-items-center" id="filters">
        <p class="mr-2">
            {{ "SHOW_DEVICES_WITH" | translate }}
        </p>

        <mat-form-field class="mr-2">
            <mat-select
                matNativeControl
                [formControl]="directionFilter"
                (selectionChange)="onFilterChange()"
            >
                <mat-option
                    class="hideMatOptionCheckbox"
                    value="more"
                    selected
                    >{{ "MORE_THAN" | translate }}</mat-option
                >

                <mat-option class="hideMatOptionCheckbox" value="less">{{
                    "LESS_THAN" | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="mr-2">
            <input
                matInput
                placeholder="{{ 'ENTER_NUMBER' | translate }}"
                [formControl]="alertCountFilter"
                (keyup)="onFilterChange()"
            />
        </mat-form-field>

        <p>{{ "ALERTS" | translate }}</p>
    </div>

    <div id="tableContainer">
        <table
            mat-table
            [dataSource]="tableData"
            class="rounded-bottom-corners"
            matSortActive="alertCount"
            matSortDirection="desc"
            matSort
        >
            <!--device name column-->
            <ng-container matColumnDef="deviceName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "DEVICE_NAME" | translate }}
                </th>
                <td mat-cell *matCellDef="let e">{{ e.deviceName }}</td>
            </ng-container>

            <!--device type column-->
            <ng-container matColumnDef="deviceType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "DEVICE_TYPE" | translate }}
                </th>
                <td mat-cell *matCellDef="let e">
                    {{ e.deviceType | deviceType }}
                </td>
            </ng-container>

            <!--device ID column-->
            <ng-container matColumnDef="deviceId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "SERIAL_NUMBER" | translate }}
                </th>
                <td mat-cell *matCellDef="let e">{{ e.deviceId }}</td>
            </ng-container>

            <!--alert count column-->
            <ng-container matColumnDef="alertCount" class="mat-column-alarms">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "ALERTS_WITH_FIRST_LETTER_UPPERCASE" | translate }}
                </th>
                <td mat-cell *matCellDef="let e">{{ e.alertCount }}</td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>

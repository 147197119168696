<div class="container">
    <h6 class="routeEditGroupTitle mb-2 text-center">{{'SERVICE_TIME_VALIDITY' | translate}}</h6>
    <div class="card card-borders p-2">
        <div class="row justify-content-center">

            <div class="col-auto">

                <div [formGroup]="schedulesForm">
                    <div class="text-center">
                        <mat-radio-group formControlName="serviceTimeValidity"
                                         (change)="toggleShowServiceTimeOptions()">
                            <mat-radio-button *ngFor="let serviceTimeValidityOption of serviceTimeValidityOptions"
                                              [value]="serviceTimeValidityOption.value"
                                              class="mr-2 mb-4">
                                {{serviceTimeValidityOption.viewValue | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>


                    <table *ngIf="showServiceTimeOptions"
                           class="table table-borderless table-responsive table-sm centerTableContent">
                        <thead>
                        <tr>
                            <th>{{'DAY' |translate}}</th>

                            <th class="d-flex justify-content-center">
                                <span class="mr-2">{{'REPORT_START_TIME' |translate}} </span>
                                <span class="ml-2">{{'REPORT_END_TIME' |translate}}</span>
                            </th>

                        </tr>
                        </thead>

                        <tbody>

                        <ng-container *ngFor="let serviceTimeOption of currentServiceTimes">
                            <tr>
                                <td>
                                    <mat-checkbox [formControlName]="serviceTimeOption.day + 'enabled'"
                                                  (change)="toggleServiceTimeSection(serviceTimeOption.day);">
                                        {{daysMap.get(serviceTimeOption.day) | translate}}:
                                    </mat-checkbox>
                                </td>

                                <td>
                                    <mat-form-field class="serviceTimeInput mr-2">
                                        <input matInput
                                               [formControlName]="serviceTimeOption.day + 'startTime'"
                                               maxlength="5"
                                               type="text">
                                    </mat-form-field>
                                    -
                                    <mat-form-field class="serviceTimeInput ml-2">
                                        <input matInput
                                               [formControlName]="serviceTimeOption.day + 'endTime'"
                                               maxlength="5"
                                               type="text">
                                    </mat-form-field>
                                </td>
                            </tr>
                        </ng-container>
                        </tbody>


                    </table>
                </div>
            </div>


        </div>
    </div>
</div>



<div class="load-r-content col d-flex justify-content-end">
    <mat-accordion multi>
        <mat-expansion-panel
            matTooltip="{{ 'REPORT_REQUEST_TOOLTIP' | translate }}"
            matTooltipPosition="above"
            class="mb-2"
            [(expanded)]="PDFRequestPanelExpanded"
            (click)="togglePDFChecker()">
            <mat-expansion-panel-header>
                <div>
                    <span class="fa-light fa-download"></span>
                    {{ "DOWNLOAD_ALERTS" | translate }}&nbsp;&nbsp;
                </div>
            </mat-expansion-panel-header>

            <div class="form-inline">
                <button class="btn btn-outline-primary btn-block" (click)="downloadCsvOrJson('csv')"
                    [disabled]="loadingCSV">
                    <span *ngIf="loadingCSV; else CSV">
                        {{'LOADING_CSV'| translate}}...
                        <img class="fadeLoader centerLoader logoSmall" style="width: 30px"
                            src="assets/img/loading_logo.svg">
                    </span>

                    <ng-template #CSV>CSV</ng-template>
                </button>

                <button
                    class="btn btn-outline-primary btn-block"
                    [disabled]="loadingPDF && !pdfError"
                    (click)="triggerPdfGeneration()">
                    <span *ngIf="loadingPDF; else PDF">
                        <span *ngIf="pdfError; else PDFOK">{{'PDF_RETRY'| translate}}</span>
                        <ng-template #PDFOK>
                            {{'LOADING_PDF'| translate}}...
                            <img class="fadeLoader centerLoader logoSmall" style="width: 30px"
                                src="assets/img/loading_logo.svg">
                        </ng-template>
                    </span>

                    <ng-template #PDF>PDF</ng-template>
                </button>

                <button
                    *ngIf="selectedTab === 'Alert'"
                    class="btn btn-outline-primary btn-block"
                    (click)="downloadCsvOrJson('json')"
                    [disabled]="loadingJSON">
                    <span *ngIf="loadingJSON; else JSON">
                        {{'LOADING_JSON'| translate}}...
                        <img class="fadeLoader centerLoader logoSmall" style="width: 30px"
                            src="assets/img/loading_logo.svg">
                    </span>

                    <ng-template #JSON>JSON</ng-template>
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

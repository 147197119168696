import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { ReceiverTeam } from "../../../models/receiver-team";
import { ApiService } from "../../../services/api.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatListOption, MatListModule } from "@angular/material/list";
import { LocationModel } from "../../../models/location.model";
import { StaffModel } from "app/models/staff.model";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TeamLockAccessComponent } from "../team-lock-access/team-lock-access.component";
import { NgIf, NgClass, NgFor } from "@angular/common";

@Component({
    selector: "team-edit",
    templateUrl: "./team-edit.component.html",
    styleUrls: ["./team-edit.component.css"],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgClass, TeamLockAccessComponent, MatSlideToggleModule, MatTooltipModule, MatCheckboxModule, MatListModule, NgFor, TranslateModule]
})
export class TeamEditComponent implements OnChanges {
  @Input("team") team: ReceiverTeam;
  @Input("staff") allStaff: StaffModel[];
  @Input("locations") allLocations: any;
  @Output() closeEditFormEvent = new EventEmitter<void>();
  @Output() teamDeleted = new EventEmitter<string>();
  @Output() teamEdited = new EventEmitter<any>();

  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef
  ) {}

  user: any;
  teamEditForm: UntypedFormGroup;
  editTeamFormError: string;
  deleteConfirmation: boolean;
  availableStaffForCompany: StaffModel[];
  availableStaffForLocation: StaffModel[];
  activeTab: number = 1;
  allSelected = false;

  createTeamEditForm(): void {
    this.teamEditForm = this.fb.group({
      groupName: [
        this.team.groupName,
        {
          validators: [Validators.required],
        },
      ],
      selectedTeamMembers: this.team.users
        ? new UntypedFormControl(
            this.team.users.map((x) => {
              return x.id;
            })
          )
        : "",
      teamLocked: this.team.locked,
      teamHidden: this.team.hidden,
    });
    //Run change detection after selection of staffs were changed
    this.cdRef.detectChanges();
  }

  editTeam(): void {
    const groupData = {
      groupId: this.team.groupId,
      groupName: this.teamEditForm.value.groupName,
      locked: this.teamEditForm.value.teamLocked,
      hidden: this.teamEditForm.value.teamHidden,
      staffs: this.teamEditForm.value.selectedTeamMembers,
    };

    console.log(groupData);

    this.api.editReceiverTeam(groupData).subscribe(
      (res) => {
        groupData.staffs = res;
        this.teamEdited.emit(groupData);
        this.closeEditForm();
      },
      (err) => {
        this.translate.get(["ERROR"]).subscribe((t) => {
          this.editTeamFormError = t.ERROR;
        });
      }
    );
  }

  featureEnabled(feature: string): boolean {
    let features: [String] = JSON.parse(localStorage.getItem("features"));
    if (features) {
      return features.includes(feature);
    }
    return false;
  }

  deleteTeam(): void {
    this.api.deleteReceiverTeam(this.team.groupId).subscribe(
      (res) => {
        this.teamDeleted.emit(this.team.groupId);
        this.closeEditForm();
      },
      (err) => {
        this.translate.get(["ERROR"]).subscribe((t) => {
          this.editTeamFormError = t.ERROR;
        });
      }
    );
  }

  closeEditForm(): void {
    this.closeEditFormEvent.emit();
  }

  parseStaffList(staff: StaffModel[]): void {
    this.availableStaffForCompany = [];
    this.availableStaffForLocation = [];
    if (this.team.locationId) {
      this.availableStaffForLocation = staff.filter(
        (staff) => staff.locationId === this.team.locationId
      );
    }
    this.availableStaffForCompany = staff.filter((staff) =>
      staff.locationId.startsWith("C0")
    );
  }

  ngOnChanges() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.parseStaffList(this.allStaff);
    this.createTeamEditForm();
  }

  toggleAllSelection() {
    if (!this.allSelected) {
      this.teamEditForm.controls.selectedTeamMembers.patchValue([
        ...this.availableStaffForCompany.map((staff) => staff.id),
        ...this.availableStaffForLocation.map((staff) => staff.id),
      ]);
      this.allSelected = true;
    } else {
      this.teamEditForm.controls.selectedTeamMembers.patchValue([]);
      this.allSelected = false;
    }
  }
}

<div class="card stickyCard">
    <div class="card-header-white header-underline">
        <h6 class="white-header-text">
            {{'IMPORT_LOCK_CSV' |translate}}
        </h6>
        <span class="float-right cursorPointer" (click)="closeImportForm()" style="margin-right: 15px; position: relative; top: -12px">
            <span class="fal fa-times"></span>
        </span>
    </div>
    <div class="card-body-white">
        <div>
            <p>
                {{'DOWNLOAD_CSV_TEMPLATE_INFORMATION' |translate}} <br>
                <a href="assets/doc/LockDataTemplate.xlsx">LockDataTemplate.xlsx</a>
            </p>
        </div>
        <div>
            <form [formGroup]="uploadLockCsvFile" (ngSubmit)="formSubmitSubject.next()">
                <div class="form-group">
                    <div>
                        <label>{{'FILE' |translate}}</label>
                        <input type="file" class="form-control-file" (change)="fileChange($event)"
                            formControlName="file" name="file" accept=".csv">
                    </div>
                </div>
                <div class="form-group">
                    <div class="alert alert-success" *ngIf="saveSuccess">
                        {{'CSV_IMPORT_SUCCESS' |translate}}
                    </div>
                    <div class="alert alert-danger" *ngIf="saveError">
                        {{'ERROR' |translate}}
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" [disabled]="!uploadLockCsvFile.valid"
                        class="btn btn-outline-primary gray-bg mr-2"><span class="fal fa-upload"></span> {{'UPLOAD'
                        |translate}}</button>
                    <button type="button" class="btn btn-outline-danger" (click)="closeImportForm();" name="reset"><span
                            class="fal fa-times"></span> {{'CANCEL' |translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>

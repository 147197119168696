import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ResidentInfo } from '../../models/resident.model';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { ResidentInfoComponent } from './resident-info/resident-info.component';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'residents',
    templateUrl: './residents.component.html',
    styleUrls: ['./residents.component.css'],
    standalone: true,
    imports: [NgFor, NgClass, NgIf, ResidentInfoComponent, TranslateModule]
})
export class ResidentsComponent implements OnInit {

  constructor(
    private api: ApiService
  ) { }

  _residentFilter: string;
  @ViewChild('residentSearchInput', { static: true }) residentSearchInput: ElementRef;

  residents: ResidentInfo[];

  devices = [];

  devicesOfResidents = [];

  devicesOfResident = [];

  deviceInfo = [];

  residentInfo: ResidentInfo;

  showDeviceInfo: boolean = false;

  selectedDev: number = -1;

  deviceCount: number = 0;

    searchResident: any = {
    showEmpty: false,
    type: [],
    selectSettings: {
      enableSearch: true,
      dynamicTitleMaxItems: 1,
      checkedStyle: 'fontawesome',
      containerClasses: 'fullWidth',
      buttonClasses: 'btn btn-outline-dark fullWidth',
      selectionLimit: 1,
      autoUnselect: true,
      closeOnSelect: true,
      showUncheckAll: true
    },
    selectTexts: {
      defaultTitle: '',
      searchPlaceholder: '',
      uncheckAll: ''
    }
  }

  get residentFilter(): string {
    return this._residentFilter;
  }

  set residentFilter(value: string) {
    this._residentFilter = value;


  }

  getResidentDevices(i) {
    this.devicesOfResident = this.devicesOfResidents[this.residents[i].residentId];
    this.deviceInfo = [];
    for (let device of this.devicesOfResident) {
      if (typeof device.devId !== 'undefined') {
        this.setResidentInfo(device.devId);
      }
    }

    this.showDeviceInfo = true;
    this.selectedDev = i;

    this.residentInfo = this.residents[i];

  }

  setResidentInfo(deviceId) {
    this.api.getDeviceInfo(deviceId).subscribe(res => {
      this.deviceInfo.push({
        devId: res.id,
        devName: res.name,
        devType: res.deviceType,
        lastConnection: res.lastConnection,
        status: res.onlineStatus
      });
      this.deviceCount++;
    });
  }

  async ngOnInit() {

    // Get company active residents
    await this.api.getCompanyResidents(2).subscribe(res => {
      this.residents = res;
    });
    
    let data = {
      fullInfo: false
    };
   
    await this.api.getDevices(data).subscribe(res => {
      this.devices = res;
    });
    
    await this.api.getCompanyResidentDevices().subscribe(res => {
      let residentDevices = res;
      for (let resident of this.residents) {
        this.devicesOfResidents[resident.residentId] = [];
        for (let residentDevice of residentDevices) {
          if (residentDevice.residentId == resident.residentId && typeof residentDevice.devId !== 'undefined') {
            this.devicesOfResidents[resident.residentId].push(residentDevice);
          }
        }
      }

    });


    // setup debounce for deviceFilter so we won't freeze  browser on high device amounts
    fromEvent(this.residentSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => event.target.value)
      // Time in milliseconds between key events
      , debounceTime(50)
      // If previous query is different from current
      , distinctUntilChanged()
      // subscription for response
    ).subscribe((query: string) => this.residentFilter = query.toLowerCase());

    
  }

  addNewResident() {
    // TODO
  }

}

import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { HelperService } from '../../../../services/helper.service';
import { Device } from '../../../../models/device.model';
import { CookieService } from 'ngx-cookie-service';
import { throttleTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'device-add-inventory',
    templateUrl: './device-add-inventory.component.html',
    styleUrls: ['./device-add-inventory.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatTooltipModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, TranslateModule]
})
export class DeviceAddInventoryComponent implements OnInit, OnDestroy {

  @Input('locationList') locations: any;
  @Output() closeAddFormEvent = new EventEmitter<void>();
  @Output() deviceAddedEvent = new EventEmitter<string>();
  @Output() deviceAddedError = new EventEmitter<void>();

  //Init global variables and services
  constructor(
    private api: ApiService,
    private helper: HelperService,
    private router: Router,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private cookies: CookieService
  ) {
  };

  @Input()
  customerSelectionData: any;

  addDevForm: UntypedFormGroup;
  devValidated: boolean = false;
  addDevFormError: string;
  responseDev: Device;
  fileName = '';
  devTypes = [];
  devClasses = {};

  selectedDevType = '';

  devTypesForSelect = [];

  formSubmitSubject = new Subject();

  //Initialize the form for adding new devices
  createAddDevForm(): void {
    this.addDevForm = this.fb.group({
      id: ['', Validators.compose([Validators.required, Validators.pattern(new RegExp('^[0-9A-F]{8}$', 'i'))])],
      deviceType: [Validators.compose([Validators.required])]
    })
    this.addDevForm.addControl('name', new UntypedFormControl({ value: '', disabled: true }));
    this.addDevForm.addControl('deviceType', new UntypedFormControl({ value: 'devTypeNotSelected', disabled: true }));
  }

  //Update the form after successfull validity check
  updateAddDevForm(): void {
    this.addDevForm.controls['name'].disable();
    this.addDevForm.controls['deviceType'].disable();
    this.addDevForm.controls['deviceType'].setValue('');
  }

  devValidate(validate) {
    if (validate) {
      this.addDevForm.controls['name'].enable();
      this.addDevForm.controls['deviceType'].enable();

      this.devTypesForSelect = this.devTypes.filter((devType) => devType.deviceType.startsWith(this.addDevForm.controls['id'].value.substring(0, 2)));
      if (!this.devTypesForSelect.length) {
        this.updateAddDevForm();
      }
    } else {
      this.updateAddDevForm();
    }
    return validate;
  }

  getDevType(type): string {
    return this.helper.getDeviceType(type)
  }

  //Emit the close event to parent component
  closeAddForm(): void {
    this.closeAddFormEvent.emit();
  }

  getDevClass(devType) {
    return this.devClasses[devType];
  }

  //Add new device when form is submitted
  addDevice(): void {

    let dev: Device = new Device();

    dev.id = this.addDevForm.getRawValue().id;
    dev.name = this.addDevForm.value.name;

    // Device class is parsed from deviceType
    dev.devClass = this.addDevForm.value.deviceType.split('-')[1];
    dev.devPrefix = this.addDevForm.value.deviceType.split('-')[0];

    this.api.addDeviceToInventory(dev).subscribe(res => {
      this.deviceAddedEvent.emit(dev.id);
      this.addDevForm.reset();
    }, err => {
      this.deviceAddedError.emit();
    });


  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngOnInit() {
    this.createAddDevForm();

    this.devTypes = JSON.parse(localStorage.getItem('devType')).sort((a, b) => {
      return this.compare(a["deviceTypeName"], b["deviceTypeName"], true);
    });

    for (let devType of this.devTypes) {
      this.devClasses[devType.deviceType] = devType.deviceType.split('-')[1];
    }

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe(() => {
        this.addDevice();
      });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }

}

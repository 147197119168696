import { IAlertsByAlertType } from "app/models/statistics.model";
import { ExpandCollapseAnimation } from "app/animations";
import { HelperService, SortOrder } from "app/services/helper.service";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule, SlicePipe } from "@angular/common";
import { AlertTypePipe, DeviceTypePipe } from "app/pipes/pipes";
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from "@angular/core";

@Component({
  selector: "alerts-per-alert-type",
  templateUrl: "./alerts-per-alert-type.component.html",
  styleUrls: ["./alerts-per-alert-type.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SlicePipe,
    DeviceTypePipe,
    AlertTypePipe,
  ],
  animations: [ExpandCollapseAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsPerAlertTypeComponent implements OnInit {
  constructor(private helper: HelperService) {}

  @Input() alertTypeAlerts: IAlertsByAlertType[];
  listedAlerts: IAlertsByAlertType[];

  expandState: "collapsed" | "expanded" = "collapsed";
  collapseStateDeviceTypeCount: number = 3;

  displayedRows: number = 0;
  maxRows: number;
  sorter: string = "";
  sortOrder: SortOrder = SortOrder.Default;

  // Style object for the alerts container
  alertsContainerStyles: { "grid-template-columns": string };

  // Set width of the alert count columns based on number digits of the biggest alert count
  setAlertColumnWidths(maxAlerts: number): void {
    let alertCountGridColumnWidth = "auto";
    // 1-digit
    if (maxAlerts < 10) {
      alertCountGridColumnWidth = "12px";
    }
    // 2-digits
    else if (maxAlerts < 100) {
      alertCountGridColumnWidth = "20px";
    }
    // 3-digits
    else if (maxAlerts < 1000) {
      alertCountGridColumnWidth = "28px";
    }
    // 4-digits
    else if (maxAlerts < 10000) {
      alertCountGridColumnWidth = "36px";
    }
    // 5-digits
    else if (maxAlerts < 100000) {
      alertCountGridColumnWidth = "44px";
    }
    // 6-digits
    else if (maxAlerts < 1000000) {
      alertCountGridColumnWidth = "52px";
    }
    // Set styles
    this.alertsContainerStyles = {
      "grid-template-columns": `${alertCountGridColumnWidth} auto`,
    };
  }

  sort(sorter: "alertType" | "alerts"): void {
    if (this.sorter !== sorter) {
      this.sortOrder = SortOrder.Default;
    }
    this.sorter = sorter;

    switch (this.sortOrder) {
      case SortOrder.Default:
        if (sorter === "alertType") {
          this.listedAlerts = this.alertTypeAlerts.sort((a, b) =>
            this.compareAlertTypeNamesDesc(a, b, this.helper)
          );
        }
        if (sorter === "alerts") {
          this.listedAlerts = this.alertTypeAlerts.sort(
            this.compareAlertsCountsDesc
          );
        }
        this.sortOrder = SortOrder.Desc;
        break;
      case SortOrder.Desc:
        if (sorter === "alertType") {
          this.listedAlerts = this.alertTypeAlerts.sort((a, b) =>
            this.compareAlertTypeNamesAsc(a, b, this.helper)
          );
        }
        if (sorter === "alerts") {
          this.listedAlerts = this.alertTypeAlerts.sort(
            this.compareAlertsCountsAsc
          );
        }
        this.sortOrder = SortOrder.Asc;
        break;
      case SortOrder.Asc:
        this.listedAlerts = this.alertTypeAlerts;
        this.sortOrder = SortOrder.Default;
        break;
    }
  }

  compareAlertTypeNamesAsc(
    a: IAlertsByAlertType,
    b: IAlertsByAlertType,
    helper: HelperService
  ): number {
    const deviceTypeNameA = helper.getDeviceType(a.deviceType);
    const deviceTypeNameB = helper.getDeviceType(b.deviceType);

    return deviceTypeNameB.localeCompare(deviceTypeNameA);
  }

  compareAlertTypeNamesDesc(
    a: IAlertsByAlertType,
    b: IAlertsByAlertType,
    helper: HelperService
  ): number {
    const deviceTypeNameA = helper.getDeviceType(a.deviceType);
    const deviceTypeNameB = helper.getDeviceType(b.deviceType);

    return deviceTypeNameA.localeCompare(deviceTypeNameB);
  }

  compareAlertsCountsAsc(a: IAlertsByAlertType, b: IAlertsByAlertType): number {
    return a.totalAlerts - b.totalAlerts;
  }

  compareAlertsCountsDesc(
    a: IAlertsByAlertType,
    b: IAlertsByAlertType
  ): number {
    return b.totalAlerts - a.totalAlerts;
  }

  toggleExpand(): void {
    this.expandState =
      this.expandState === "collapsed" ? "expanded" : "collapsed";
  }

  ngOnInit(): void {
    let count = 0;

    // Count total rows
    for (let i = 0; i < this.alertTypeAlerts.length; i++) {
      this.alertTypeAlerts[i].activations.forEach(() => count++);
      count++;

      if (i < this.collapseStateDeviceTypeCount) {
        this.displayedRows = count;
      }
    }
    this.maxRows = count;

    // Get biggest alert count
    const maxAlertCount = Math.max(
      ...this.alertTypeAlerts.map((alert) => alert.totalAlerts)
    );
    this.setAlertColumnWidths(maxAlertCount);

    this.listedAlerts = this.alertTypeAlerts;
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Device } from '../../../../models/device.model';
import { ApiService } from '../../../../services/api.service';
import { Activation, AlarmRouteDevice, Route } from '../../../../models/alarmroutes.model';
import { AddDeviceAnimation } from '../../../../animations';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLineModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { NgIf, NgFor, NgClass, KeyValuePipe } from '@angular/common';


/*
* deviceGroups are populated at ngOnInit
* deviceGroups are mirrored to Available devices and Selected devices.
* When user adds an device from Available devices to Selected devices the devices selected variable will be changed to true
*
* If device is selected then it's not showed as Available device.
*
* Reason why we're hiding elements instead of parsing them again, when any of the deviceGroups devices change is performance.
*
*
* Every time when user makes a change to Devices (Selects, removes, activates an option), saveDevicesToRoute() is triggered,
* which takes all the selected devices (selected = true)  from deviceGroups  and  saves them to route.
*
* */

@Component({
    selector: 'alarm-route-devices',
    templateUrl: './alarm-route-devices.component.html',
    styleUrls: ['./alarm-route-devices.component.css'],
    animations: [AddDeviceAnimation],
    standalone: true,
    imports: [NgIf, MatListModule, MatLineModule, MatIconModule, NgFor, NgClass, MatCheckboxModule, KeyValuePipe, TranslateModule]
})
export class AlarmRouteDevicesComponent implements OnInit, OnChanges {


  constructor(private api: ApiService) {
  }


  @Input('tempRoute') tempRoute: Route;
  @Input('deviceList') rawDeviceList: Device[];


  deviceGroups: DeviceGroup[] = [];
  selectedDevicesForActivations: DeviceOption[] = [];
  deviceTypeGroupHighlighted: boolean = false;

  availableDeviceTypeOptions: DeviceTypeOption[] = [];
  deviceTypeAvailableActivations: ActivationOption[] = [];


  allActivations: ActivationOption[] = [];


  /*
  * Creates a device groups from device list.
  * Ex.
  *
  * LocationA
  *  - Device1
  *  - Device2
  * LocationB
  *  - Device3
  *  - Device4
  *
  * */
  getDeviceGroups(rawDeviceList: Device[]): DeviceGroup[] {

    if(!rawDeviceList){
      return [];
    }

    let deviceList: DeviceOption[] = [];
    let deviceGroup: DeviceGroup[] = [];


    // Filter key devices
    rawDeviceList = rawDeviceList.filter(device => !device.deviceType.startsWith('F0-1'));

    if (this.tempRoute.locationId.startsWith('C22')) {
      rawDeviceList = rawDeviceList.filter(device => device.locationId === this.tempRoute.locationId);
    }


    //First add all non paired devices to list
    rawDeviceList.forEach(device => {
      const baseId = device.id;
      if (!baseId || baseId === '00000000') {


        if (device.deviceActivations) {
          device.deviceActivations.map(activation => {
            return {group: activation.activationGroup, node: activation.activationNode};
          });
        }

        deviceList.push({
          activations: [],
          connectionTimeLimit: device.connectionTimeLimit,
          deviceId: device.id,
          deviceName: device.name,
          deviceType: device.deviceType,
          locationId: device.locationId,
          locationName: device.locationName
        });
      }
    });


    rawDeviceList.forEach(device => {
      const baseId = device.id;

      if (baseId && baseId !== '00000000') {

        //Loop through list and find basestation with same id where device is paired
        for (let i = 0; i < deviceList.length; i++) {
          if (deviceList[i].deviceId === baseId) {
            //Initialize pairedDeviceOptions list if it's undefined
            if (!deviceList[i].pairedDeviceOptions) {
              deviceList[i].pairedDeviceOptions = [];
            }


            deviceList[i].pairedDeviceOptions.push({
              activations: [],
              connectionTimeLimit: device.connectionTimeLimit,
              deviceId: device.id,
              deviceName: device.name,
              deviceType: device.deviceType,
              locationId: device.locationId,
              locationName: device.locationName
            });
            return;
          }
        }


        deviceList.push(({
          activations: [],
          connectionTimeLimit: device.connectionTimeLimit,
          deviceId: device.id,
          deviceName: device.name,
          deviceType: device.deviceType,
          locationId: device.locationId,
          locationName: device.locationName
        }));
      }

    });


    deviceList.forEach(dev => {
      dev.selected = false;

      let deviceGroupIndex: number = deviceGroup.findIndex(deviceGroup => deviceGroup.locationId === dev.locationId);

      if (deviceGroupIndex === -1) {
        deviceGroup.push({locationId: dev.locationId, locationName: dev.locationName, deviceOptions: [dev]})
      } else {
        deviceGroup[deviceGroupIndex].deviceOptions.push(dev);
      }

    });


    deviceGroup = deviceGroup.sort((a, b) => a.locationName.localeCompare(b.locationName));

    return deviceGroup;

  }


  /*
  * Gets devices from deviceGroups
  * */
  getDevicesFromGroup(): DeviceOption[] {

    let devices: DeviceOption[] = [];

    this.deviceGroups.forEach(group => {
      group.deviceOptions.forEach(device => {
        devices.push(device);

        if (device.pairedDeviceOptions) {
          device.pairedDeviceOptions.forEach(pairedDevice => {
            devices.push(pairedDevice);
          });
        }
      });
    });

    return devices;

  }

  /*
  * Gets all different device types available.
  * */
  getAvailableDeviceTypes(): DeviceTypeOption[] {

    const devices = this.getDevicesFromGroup();
    let availableDeviceTypes: DeviceTypeOption[] = [];

    devices.forEach(device => {
      if (!availableDeviceTypes.some(deviceTypeOption => deviceTypeOption.deviceType === device.deviceType)) {
        availableDeviceTypes.push({deviceType: device.deviceType})
      }
    });

    return availableDeviceTypes;

  }


  getDeviceType(type: string): string {
    return this.api.getDeviceType(type);
  }

  getDeviceIconClass(deviceType: string): string {
    return this.api.getDeviceIconClass(deviceType);
  }


  /*
* Gets all devices by type
*  */
  getDevicesByType(deviceType: string): DeviceOption[] {
    const devices = this.getDevicesFromGroup();
    return devices.filter(device => device.selected && device.deviceType === deviceType);
  }


  // Adds Devices
  addDevice(device: DeviceOption): void {
    device.selected = true;
    this.saveDevicesToTempRoute();
  }


  addAllDevices(): void {
    const devices = this.getDevicesFromGroup();
    devices.forEach(device => {
      this.addDevice(device);
    });
  }


  addDevicesByLocationId(locationId: string): void {
    const devices = this.getDevicesFromGroup().filter(device => device.locationId === locationId);
    devices.forEach(device => this.addDevice(device));
  }

  addDevicesByType(deviceType: string): void {
    const devices = this.getDevicesFromGroup().filter(device => device.deviceType === deviceType);
    devices.forEach(device => this.addDevice(device));
  }

  // Removes Devices
  removeDevice(device: DeviceOption): void {
    device.selected = false;
    this.saveDevicesToTempRoute();
  }


  removeAllDevices(): void {
    const devices = this.getDevicesFromGroup();
    devices.forEach(device => {
      this.removeDevice(device);
    });
  }

  removeDevicesByLocationId(locationId: string): void {
    const devices = this.getDevicesFromGroup().filter(device => device.locationId === locationId);
    devices.forEach(device => this.removeDevice(device));
  }

  removeDevicesByType(deviceType: string): void {
    const devices = this.getDevicesFromGroup().filter(device => device.deviceType === deviceType);
    devices.forEach(device => this.removeDevice(device));
  }


  /*
* Check if there are no available devices (device.selected = false) in deviceGroups
*  */
  availableDevicesEmpty(): boolean {
    const devices = this.getDevicesFromGroup().filter(device => !device.selected);
    return devices && devices.length !== 0;
  }

  /*
* Check if there are no selected devices (device.selected = true) in deviceGroups
*  */
  selectedDevicesEmpty(): boolean {
    const devices = this.getDevicesFromGroup().filter(device => device.selected);
    return devices && devices.length !== 0;
  }

  /*
* Check if there is deviceId (device.selected = false) in deviceGroups
*  */
  availableDeviceByDeviceId(deviceId: string): boolean {
    const device = this.getDevicesFromGroup().find(device => !device.selected && device.deviceId === deviceId);
    return !!device;
  }

  /*
* Check if there is deviceId (device.selected = true) in deviceGroups
*  */
  selectedDeviceByDeviceId(deviceId: string): boolean {
    const device = this.getDevicesFromGroup().find(device => device.selected && device.deviceId === deviceId);
    return !!device
  }

  /*
* Check if there is deviceType (device.selected = false) in deviceGroups
*  */
  availableDevicesByDeviceType(deviceType: string): boolean {
    const devices = this.getDevicesFromGroup().filter(device => !device.selected && device.deviceType === deviceType);
    return devices && devices.length !== 0;
  }

  /*
* Check if there is deviceType (device.selected = true) in deviceGroups
*  */
  selectedDevicesByDeviceType(deviceType: string): boolean {
    const devices = this.getDevicesFromGroup().filter(device => device.selected && device.deviceType === deviceType);
    return devices && devices.length !== 0;
  }

  /*
* Check if there are any devices ny locationId (device.selected = false) in deviceGroups
*  */
  availableDevicesInLocation(locationId: string): boolean {
    const devices = this.getDevicesFromGroup().filter(device => !device.selected && device.locationId === locationId);
    return devices && devices.length !== 0;
  }

  /*
* Check if there are any devices by locationId (device.selected = true) in deviceGroups
*  */
  selectedDevicesInLocation(locationId: string,): boolean {
    const devices = this.getDevicesFromGroup().filter(device => device.selected && device.locationId === locationId);
    return devices && devices.length !== 0;
  }


  highlightDeviceTypeOption(deviceTypeOption: DeviceTypeOption): void {
    const devices = this.getDevicesFromGroup().filter(device => device.selected);
    devices.forEach(device => device.highlight = false);

    this.availableDeviceTypeOptions.forEach(deviceTypeOption => deviceTypeOption.highlight = false);
    deviceTypeOption.highlight = true;
  }


  highlightDeviceOption(deviceOption: DeviceOption): void {
    const devices = this.getDevicesFromGroup().filter(device => device.selected);
    devices.forEach(device => device.highlight = false);

    this.availableDeviceTypeOptions.forEach(deviceTypeOption => deviceTypeOption.highlight = false);
    deviceOption.highlight = true;
  }


  // Create a Map of current activations.
  // Loop through given alarmRouteDevices and check if alarmRouteDevice's activation matches available activations for that deviceType.
  // Results : {key: ActivationX, values: ActivationState}
  getDeviceActivationOptions(alarmRouteDevices: DeviceOption[]): Map<ActivationOption, ActivationState> {
    const selectedDevicesActivations: Map<Activation, ActivationState> = new Map();

    const activationsMap: Map<Activation, boolean[]> = new Map();
    this.deviceTypeAvailableActivations = this.allActivations.filter(activation =>
      activation.deviceType === alarmRouteDevices[0].deviceType);

    this.deviceTypeAvailableActivations.forEach(availableActivation => {
      alarmRouteDevices.forEach(alarmRouteDevice => {
        const activationActive: boolean = alarmRouteDevice.activations.some(activation =>
          activation.node === availableActivation.node && activation.group === availableActivation.group);


        if (activationsMap.has(availableActivation)) {
          activationsMap.get(availableActivation).push(activationActive)
        } else {

          availableActivation.bindDeviceIds = alarmRouteDevices.map(device => {
            return device.deviceId;
          });
          activationsMap.set(availableActivation, [activationActive]);
        }
      });
    });


    for (let entry of Array.from(activationsMap.entries())) {
      const key = entry[0];
      const value = entry[1];

      let activationState: ActivationState;

      if (value.every(active => active)) {
        activationState = ActivationState.On;
      } else {
        if (value.some(active => active)) {
          activationState = ActivationState.Intermediate;
        } else {
          if (value.every(active => !active)) {
            activationState = ActivationState.Off;

          }
        }
      }

      selectedDevicesActivations.set(key, activationState)
    }


    return selectedDevicesActivations;


  }


  addActivationToDevice(deviceId: string, addActivation: Activation): void {
    const device = this.getDevicesFromGroup().find(device => device.deviceId === deviceId);

    if (device) {
      if (!device.activations.some(activation => activation.group === addActivation.group && activation.node == addActivation.node)) {
        device.activations.push({group: addActivation.group, node: addActivation.node});
      }

    }

  }


  addActivationToDevices(deviceIds: string[], addActivation: Activation): void {
    deviceIds.forEach(deviceId => {
      this.addActivationToDevice(deviceId, addActivation);
    });

    this.saveDevicesToTempRoute();

  }


  removeActivationFromDevice(deviceId: string, removeActivation: Activation): void {
    const device = this.getDevicesFromGroup().find(device => device.deviceId === deviceId);

    if (device) {
      const removeActivationIndex: number = device.activations.findIndex(activation => {
        return activation.group === removeActivation.group && activation.node == removeActivation.node;
      });

      if (removeActivationIndex !== -1) {
        device.activations.splice(removeActivationIndex, 1);
      }
    }

  }


  removeActivationFromDevices(deviceIds: string[], removeActivation: Activation): void {
    deviceIds.forEach(deviceId => {
      this.removeActivationFromDevice(deviceId, removeActivation);
    });

    this.saveDevicesToTempRoute();

  }


  addInitialDevices(): void {
    const devices = this.getDevicesFromGroup();
    this.tempRoute.devices.forEach(initialDevice => {
      let device = devices.find(deviceOption => deviceOption.deviceId === initialDevice.deviceId);

      if (device) {
        this.addDevice(device);

        initialDevice.activations.forEach(activation => {
          this.addActivationToDevice(device.deviceId, activation);
        });

      }
    });


  }

  saveDevicesToTempRoute(): void {
    this.tempRoute.devices = this.getDevicesFromGroup().filter(device => device.selected);

  }

  ngOnInit() {


    //Deep copy rawDeviceList so that we won't keep the reference
    this.deviceGroups = this.getDeviceGroups(JSON.parse(JSON.stringify(this.rawDeviceList)));

    this.addInitialDevices();

    this.availableDeviceTypeOptions = this.getAvailableDeviceTypes();

    this.allActivations = JSON.parse(localStorage.getItem('act'));

  }

  //Re-parse devices on location change
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.tempRoute.previousValue) {
      const tempDeviceList = this.getDevicesFromGroup().filter(device => device.selected);

      this.deviceGroups = this.getDeviceGroups(JSON.parse(JSON.stringify(this.rawDeviceList)));

      this.availableDeviceTypeOptions = this.getAvailableDeviceTypes();

      this.tempRoute.devices = tempDeviceList.filter(device => this.availableDeviceByDeviceId(device.deviceId));

      this.addInitialDevices();

    }

  }

}


interface DeviceOption extends AlarmRouteDevice {
  pairedDeviceOptions?: DeviceOption[];
  activations: ActivationOption[];
  highlight?: boolean;
  selected?: boolean;
}


interface DeviceGroup {
  locationName: string;
  locationId: string;
  deviceOptions: DeviceOption[];
}


interface DeviceTypeOption {
  deviceType: string;
  highlight?: boolean;

}


export interface ActivationOption extends Activation {
  bindDeviceIds?: string[];
  deviceType?: string;
}


export enum ActivationState {
  Off,
  On,
  Intermediate
}



<ng-container *ngIf="showNav()">
    <!-- desktop layout -->
    <div *ngIf="!onMobileScreen">
        <div class="border-bottom border-mute border-2 d-flex align-middle"
            [ngClass]="{ 'justify-content-center': !showTitles, 'imgContainer': showTitles, 'imgContainerMinimized': !showTitles }"
            [routerLink]="getLogoUrl()"
            style="cursor: pointer;">
            <img *ngIf="showTitles" src="assets/img/logo.svg" alt="Everon" style="height: 58px;">
            <img *ngIf="!showTitles" src="assets/img/logo_monogram.svg" style="height: 40px;">
        </div>
        <button (click)="onShowOrHideTitles()" aria-label="Show and hide names of navigation links"
            class="badge rounded-circle text-dark bg-light btn-sm chevronButton"
            *ngIf="!showCrClocks()">
            <mat-icon *ngIf="!showTitles">chevron_right</mat-icon>
            <mat-icon *ngIf="showTitles">chevron_left</mat-icon>
        </button>
    </div>

    <!-- mobile layout -->
    <div *ngIf="onMobileScreen" [ngStyle]="{'margin-bottom': '15px'}" class="d-flex">
        <div class="flex-grow-1">

            <app-logout-options
                [language]="language"
                [user]="user"
                [loggedCustomer]="loggedCustomer"
                [loginButtonStyle]="positionLeft"
                [logoutBtnForMobileSidenav]=true>
            </app-logout-options>
        </div>
        <button (click)="onSideNavClose()" mat-mini-fab color="primary" class="closeBtn"
            aria-label="Close navigation bar">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</ng-container>

<div class="col-lg-12">
    <h5 class="cursorPointer" (click)="signalCollapseState = !signalCollapseState">
        {{ "SIGNAL_LEVELS" | translate }} <span class="fal"
            [ngClass]="signalCollapseState ? 'fa-chevron-up' : 'fa-chevron-down'"></span>
    </h5>

    <div *ngIf="signalCollapseState">
        <table class="table">
            <thead>
                <tr>
                    <th>{{ "DATE" | translate }}</th>
                    <th>{{ "LEVEL" | translate }}</th>
                    <th>{{ "OPERATOR" | translate }}</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let row of signalLevels | slice : 0 : 3">
                    <td>{{ row.logTime | moment : "l LTS" }}</td>
                    <td>
                        <span [ngClass]="row.signalLevel | signalQualityClass">{{ row.signalLevel
                            }}</span>
                    </td>
                    <td>
                        {{ row.operator }} / {{ row.networkMode }}
                    </td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="networkSurveyResults && networkSurveyResults.length > 0">
            <h6>{{ "NETWORK_SURVEY" | translate }}</h6>

            <table class="table">
                <thead>
                    <tr>
                        <th>{{ "DATE" | translate }}</th>
                        <th>{{ "OPERATOR" | translate }}</th>
                        <th>{{ "LEVEL" | translate }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let row of networkSurveyResults">
                        <td>
                            {{ row.logTime | moment : "l LTS" }}
                        </td>
                        <td>
                            <span *ngIf="row.operator">{{ row.operator }} ({{ row.mccMnc }})</span>
                            <span *ngIf="!row.operator">{{ row.mccMnc }}</span>
                        </td>
                        <td>
                            <span [ngClass]="row.signals['2G'] | signalQualityClass">
                                2G: {{ row.signals["2G"] !== 0 ? row.signals["2G"] : "N/A" }}
                            </span>
                            <br />

                            <div *ngIf="deviceType === '31-14'">
                                <span [ngClass]="row.signals['3G'] | signalQualityClass">
                                    3G: {{ row.signals["3G"] !== 0 ? row.signals["3G"] : "N/A"}}
                                </span>
                                <br />
                            </div>

                            <span [ngClass]="row.signals['4G'] | signalQualityClass">
                                4G: {{ row.signals["4G"] !== 0 ? row.signals["4G"] : "N/A" }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
import { NotificationService } from './../../../services/notification.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ControlRoomComponent } from '../control-room.component';
import * as moment from 'moment-timezone';
import { TranslateModule } from '@ngx-translate/core';
import { IncomingAlertCardComponent } from './incoming-alert-card/incoming-alert-card.component';
import { NgFor, NgIf } from '@angular/common';
import { CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { NotificationBadgeComponent } from 'app/components/notifications/notification-badge/notification-badge.component';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ControlRoomService } from 'app/services/control-room.service';

@Component({
  selector: 'control-room-alert-panel-incoming',
  templateUrl: './control-room-alert-panel-incoming.component.html',
  styleUrls: ['./control-room-alert-panel-incoming.css'],
  standalone: true,
  providers: [NotificationService],
  imports: [MatTabsModule, CdkDropList, NgFor, CdkDrag, IncomingAlertCardComponent, NgIf, TranslateModule, NotificationBadgeComponent]
})
export class ControlRoomAlertPanelIncomingComponent implements OnInit, OnDestroy {

  constructor(
    private notification: NotificationService,
    public crService: ControlRoomService,
  ) {
    const subscription1 = this.subscribeToAlertNotifications();
    const subscription2 = this.subscribeToExpiringVisitsNotification();

    if (subscription1) this.subscriptions.push(subscription1);
    if (subscription2) this.subscriptions.push(subscription2);
  }

  // @Input('alertsArray') alerts: any;
  // @Input('visits') visits: any;
  get alerts(){
    return this.crService.incomingAlerts
  }
  get visits(){
    return this.crService.incomingVisits
  }
  showTeamMembers: boolean = false;

  alertWarningStatus$ = new BehaviorSubject<boolean>(false);
  visitWarningStatus: boolean = false;
  notifyVisits$ = new Subject<any[]>();

  subscriptions: Subscription[] = [];

  ngOnInit() {
    setTimeout(() => this.updateElapsedTime(), 1000);
    this.updateRemainingVisitTime();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  subscribeToAlertNotifications(): Subscription {
    // Emits only when the value changes from 'false' to 'true'
    const submitAlertNotification$ = this.alertWarningStatus$.pipe(
      distinctUntilChanged(),
      filter((value) => value === true),
    );
    return submitAlertNotification$.subscribe(_ => this.notification.notifyUnansweredAlert());
  }

  subscribeToExpiringVisitsNotification(): Subscription {
    return this.notifyVisits$.subscribe(visits => {
      if (visits.length > 0) {
        this.notification.notifyRemainingTimeForVisits(visits.length);
        visits.forEach(visit => visit.notified = true);
      }
    })
  }

  alertStartTime(alarmTimeObj: any) {
    return alarmTimeObj["_i"];
  }

  updateRemainingVisitTime() {
    const visitsToNotify = [];
    let warningStatus = false;

    this.visits.forEach((v: any) => {
      v.timeRemaining = moment(v.end).diff(moment(), 'seconds');
      if (v.timeRemaining < 60 * 5 && !v.notified) visitsToNotify.push(v);
      if (v.notified || visitsToNotify.length > 0) warningStatus = true;
    });
    this.notifyVisits$.next(visitsToNotify);
    this.visitWarningStatus = warningStatus;

    setTimeout(() => this.updateRemainingVisitTime(), 1000);
  }

  updateElapsedTime() {
    let warningThresholdExceeded = false;

    this.alerts.forEach((alert: any) => {
      alert.showTime = new Date().getTime() - new Date(this.alertStartTime(alert.time)).getTime();
      alert.styleOutOfTime = alert.showTime / 1000 > 30 ? true : false;
      alert.styleBadlyOutOfTime = alert.showTime / 1000 > 120 ? true : false;
      if (alert.styleBadlyOutOfTime) warningThresholdExceeded = true;
    });
    this.alertWarningStatus$.next(warningThresholdExceeded);

    setTimeout(() => this.updateElapsedTime(), 1000);
  }
}

<form class="container">
  <p>{{ "EDIT_BINDING_TIME" | translate }}</p>

  <div class="d-flex">
    <input id="bindingTime" type="number" class="form-control mr-3" [formControl]="bindingTimeControl">
    <label class="mb-0 mr-auto align-self-center" for="bindingTime">{{ "DAYS" | translate }}</label>
  </div>

  <div *ngIf="bindingTimeControl.invalid">
    <small class="text-danger">
      {{ "INVALID_VALUE" | translate}}
    </small>
    <br/>
  </div>

  <div *ngIf="errorMsg" class="alert alert-danger alert-trim mt-3 mb-0" role="alert">
    {{ errorMsg | translate }}
  </div>

  <div class="mt-3">
    <button
      (click)="submit()"
      [disabled]="bindingTimeControl.invalid || bindingTimeControl.pristine"
      class="btn btn-primary mr-3"
      type="button"
    >
      <span *ngIf="updating" class="spinner-border spinner-border-sm"></span>
      {{ (updating ? "SAVING" : "SAVE") | translate }}
    </button>

    <button (click)="cancel()" class="secondaryBtnWhiteBg" type="button">
      {{ "CANCEL" | translate }}
    </button>
  </div>

</form>

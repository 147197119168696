import { WEEKDAYS } from './../../../models/unloc.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { VideoServerService } from 'app/services/videoserver.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as uuid from 'uuid';
import { concat, forkJoin } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { ApiService } from 'app/services/api.service';

export const WEEKDAY_OPTIONS = [
  { id: 1, name: 'DAYOFWEEK_1', isChecked: false },
  { id: 2, name: 'DAYOFWEEK_2', isChecked: false },
  { id: 3, name: 'DAYOFWEEK_3', isChecked: false },
  { id: 4, name: 'DAYOFWEEK_4', isChecked: false },
  { id: 5, name: 'DAYOFWEEK_5', isChecked: false },
  { id: 6, name: 'DAYOFWEEK_6', isChecked: false },
  { id: 7, name: 'DAYOFWEEK_7', isChecked: false }
];

@Component({
  selector: 'create-new-visit',
  templateUrl: './create-new-visit.component.html',
  styleUrls: ['./create-new-visit.component.css'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, MatIconModule, NgFor, NgClass, NgIf, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatInputModule, MatTooltipModule, TranslateModule]
})
export class CreateNewVisitComponent implements OnInit, OnDestroy {
  constructor(
    private videoApi: VideoServerService,
    private api: ApiService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private cookies: CookieService,
  ) { }

  isChecked: boolean = false;
  weekdays = WEEKDAY_OPTIONS;
  selectedType = [
    { id: 1, name: 'SCHEDULED_VISIT' },
    { id: 2, name: 'ALERT_TRIGGERED_VISIT' }
  ]

  cameraGroups: any[];
  visibleCameraGroups: any[];
  selectedCameraGroups: any[] = [];
  newVisitForm: UntypedFormGroup;
  savingInProgess: boolean;
  savingDone: boolean;

  formSubmitSubject = new Subject();

  initCreateNewVisitForm(): void {
    this.newVisitForm = this.fb.group({
      visitWeekDay: ["", Validators.required],
      visitStartTime: ["08:00", Validators.required],
      visitDuration: [120, Validators.required],
      visitExtensionTime: [15, Validators.required]
    })
  }

  selectCameraGroup(group: any): void {
    this.initCreateNewVisitForm();
    group.selected = !group.selected;
    if (group.selected) {
      this.selectedCameraGroups.push(group)
    } else {
      this.selectedCameraGroups.splice(this.selectedCameraGroups.indexOf(group), 1);
    }
  }

  cancelVisitCreation(): void {
    this.selectedCameraGroups = [];
    for (let i = 0; i < this.visibleCameraGroups.length; i++) {
      const element = this.visibleCameraGroups[i];
      element.selected = false;
    }
  }

  submitNewVisitForm(): void {
    this.savingInProgess = true;
    this.savingDone = false;
    let observableBatch = [];
    // Save each weekday with own request since video server API does not currently support other way
    let weekdays: number[] = this.newVisitForm.controls.visitWeekDay.value;
    weekdays.forEach(x => {
      let visitData = {
        id: uuid.v4(),
        time: {
          day: x,
          hour: parseInt(this.newVisitForm.controls.visitStartTime.value.split(':')[0]),
          minute: parseInt(this.newVisitForm.controls.visitStartTime.value.split(':')[1])
        },
        duration: this.newVisitForm.controls.visitDuration.value,
        gracePeriod: this.newVisitForm.controls.visitExtensionTime.value
      }
      // Save each camera group with separate requests to array of requests and trigger them all same time later
      this.selectedCameraGroups.forEach(x => {
        observableBatch.push(
          this.videoApi.saveNewVisit(x.id, visitData));
      })
    })
    // Trigger all saved requests to be sent at the same time to server
    forkJoin(observableBatch).subscribe(() => {
      observableBatch = [];

      this.selectedCameraGroups.forEach(x => {
        observableBatch.push(
          this.api.logScheduledVisitActivity({
            id: x.id,
            groupName: x.displayName,
            weekdayTranslationCodes: this.weekdays.filter(x => weekdays.indexOf(x.id) != -1).map(x => x.name),
            startTime: this.newVisitForm.controls.visitStartTime.value,
            endTime: this.addMinutesToTime(this.newVisitForm.controls.visitStartTime.value, this.newVisitForm.controls.visitDuration.value),
            extendedVisitTimeMinutes: this.newVisitForm.controls.visitExtensionTime.value,
            action: 'Create',
          })
        )
      });

      concat(...observableBatch).subscribe(() => {
        // Set saving done and reset form
        this.savingDone = true;
        this.savingInProgess = false;
        this.newVisitForm.reset({
          visitWeekDay: "",
          visitStartTime: "08:00",
          visitDuration: 120,
          visitExtensionTime: 15
        })
      });
    })
  }

  addMinutesToTime(time: string, minutesToAdd: number): string {
    // Split the time into hours and minutes
    const [hoursStr, minutesStr] = time.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    // Calculate total minutes from hours and minutes
    let totalMinutes = hours * 60 + minutes;

    // Add the specified minutes
    totalMinutes += minutesToAdd;

    // Calculate new hours and minutes
    const newHours = Math.floor(totalMinutes / 60) % 24; // Use % 24 for 24-hour format
    const newMinutes = totalMinutes % 60;

    // Format hours and minutes to ensure two digits
    const formattedHours = String(newHours).padStart(2, '0');
    const formattedMinutes = String(newMinutes).padStart(2, '0');

    // Return the formatted time string
    return `${formattedHours}:${formattedMinutes}`;
  }

  ngOnInit(): void {
    // Make login first to video server if token not found
    if (!this.cookies.get("videoserver-session-token")) {
      let language = localStorage.getItem('language');
      this.videoApi.login().subscribe(res => {
        // Set Video server API token into cookies
        this.cookies.set("videoserver-session-token", "Bearer " + res.token, undefined, "/", undefined, false, "Lax");
        this.videoApi.getAllCameraGroups().subscribe(res => {
          this.cameraGroups = res;
          this.visibleCameraGroups = this.cameraGroups.filter(x => x.accessMode === 'cameraVisit')
        })
      })
    } else {
      this.videoApi.getAllCameraGroups().subscribe(res => {
        this.cameraGroups = res;
        this.visibleCameraGroups = this.cameraGroups.filter(x => x.accessMode === 'cameraVisit')
      })
    }

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject.pipe(throttleTime(3000))
      .subscribe(() => {
        this.submitNewVisitForm();
      });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MatButtonModule } from '@angular/material/button';
import { LogoutOptionsComponent } from '../logout-options/logout-options.component';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgClass, NgStyle } from '@angular/common';

@Component({
    selector: 'everon-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.css'],
    standalone: true,
    imports: [NgIf, RouterLink, NgClass, NgStyle, MatIconModule, LogoutOptionsComponent, MatButtonModule]
})
export class LogoComponent {

  constructor(private router: Router,
    private cookies: CookieService) { }


  @Input('user') user: any;
  @Input() loggedCustomer;
  @Input() onMobileScreen: boolean;
  @Input() language;
  @Input() showTitles;

  @Output() titlesShownOrHidden = new EventEmitter<boolean>();
  @Output() closeSideNavBtn = new EventEmitter<void>();

  positionLeft = {
    position: 'absolute',
    left: '10px',
    'margin-top': '10px'
  };

  onShowOrHideTitles() {
    this.showTitles = !this.showTitles;
    this.titlesShownOrHidden.emit(this.showTitles);
  }

  onSideNavClose() {
    this.onMobileScreen ? this.closeSideNavBtn.emit() : null;
  }

  showNav(): boolean {
    let url = this.router.url;
    if (!url.includes('login') && !url.startsWith('/ap/')) {
      return true;
    }
    return false;
  }

  //Show normal navigation bar
  normalNav(): boolean {
    //Check that user exists and has role level 100 or lower (MainUser or lower) or has session-token-admin cookie which exists when admin changes to another customer
    if (this.user && this.user.roleLevel <= 100 || this.cookies.get('session-token-admin')) {
      return true;
    }
  }


  //Display admin navigation only when logged in as admin
  adminNav(): boolean {
    //Check that user exists and that user role level is 200 (SystemAdmin) but does not have session-token-admin cookie. That cookie exists if admin user switches to other customer.
    if (this.user && (!this.cookies.get('session-token-admin') && this.user.roleLevel === 200)) {
      return true;
    }
    return false;
  }

  getLogoUrl(): string {
    if (this.normalNav()) {
      return "dashboard"
    }
    if (this.adminNav()) {
      return "dashboard/admin"
    }
    if (this.user && this.user.roleLevel === 120) {
      return "cr"
    }
  }

  showCrClocks(): boolean {
    if (this.user && this.user.roleLevel === 120) {
      return true;
    }
    return false;
  }

}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { AnimationCollapse } from "../../../animations";
import { CookieService } from "ngx-cookie-service";
import { TranslateModule } from "@ngx-translate/core";
import { ControlRoomClockComponent } from "../../control-room/control-room-clock/control-room-clock.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatListModule } from "@angular/material/list";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { ALL_NAV_TABS_CUSTOMER_ADMIN, ALL_TABS_NORMAL_USER, INavTabItem, NAV_TABS_ADMIN, NAV_TABS_LANDING_PAGE } from "./nav-elements-data"

@Component({
  selector: "global-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.css"],
  animations: [AnimationCollapse],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    MatListModule,
    RouterLink,
    RouterLinkActive,
    MatTooltipModule,
    ControlRoomClockComponent,
    TranslateModule,
  ],
})
export class GlobalNavComponent {
  constructor(private router: Router, private cookies: CookieService) {}

  @Input("user") user: any;
  @Input("landingPageUser") landingPageUser: any;
  @Input() onMobileScreen: boolean;
  @Input() showTitles: boolean;
  @Input() features: any;
  @Output() closeSideNav = new EventEmitter<void>();

  normalNav = false;
  custAdminNav = false; // Customer admin navigation
  adminNav = false;
  showNav = false;
  showCrClocks = false;

  navTabsNormalUser: INavTabItem[];
  navTabsCustomerAdmin = NAV_TABS_ADMIN;
  navTabsAdmin = NAV_TABS_ADMIN;
  navTabsLandingPage = NAV_TABS_LANDING_PAGE;

  onSideNavClose() {
    this.onMobileScreen ? this.closeSideNav.emit() : null;
  }

  showNavigation(): boolean {
    const url = this.router.url;
    if (url !== "/login" && !url.startsWith("/ap/")) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.showNav = this.showNavigation();
  }

  ngOnChanges() {
    this.normalNav = this.normalNavigation();
    this.custAdminNav = this.customerAdminNavigation();
    this.adminNav = this.adminNavigation();
    this.showNav = this.showNavigation();
    this.showCrClocks = this.showControlRoomClocks();

    this.navTabsNormalUser = this.chooseNavbarElements(ALL_TABS_NORMAL_USER, this.features);
    this.navTabsCustomerAdmin = this.chooseNavbarElements(ALL_NAV_TABS_CUSTOMER_ADMIN, this.features);
  }

  chooseNavbarElements(allElements: INavTabItem[], features: string): INavTabItem[]{
    let navElements: INavTabItem[];

    if (features) {
      navElements = allElements
      .map((tab) => {
        if (this.features.includes(tab.deviceCondition.toString())) {
          return {...tab, deviceCondition: true};
        } else {
          return tab
        }
      })
      .map((tab) => {
        if (
          this.features.includes(tab.userCondition.toString()) &&
          this.user?.roleLevel >= 100
        ) {
          return {...tab, userCondition: true};
        } else {
          return tab
        }
      });
    } else {
      navElements = ALL_TABS_NORMAL_USER
    }
    return navElements
  }

  // Show normal navigation bar
  normalNavigation(): boolean {
    // Check that user exists and has role level 100 or lower (MainUser or lower)
    if (this.user?.roleLevel <= 100) {
      return true;
    }
  }

  customerAdminNavigation(): boolean {
    // Check that user exists and has role level 101 (CustomerAdmin) or has session-token-admin cookie which exists when admin changes to another customer
    if (this.user?.roleLevel === 101 || this.cookies.get("session-token-admin")) {
      return true;
    }
    return false;
  }

  // Display admin navigation only when logged in as admin
  adminNavigation(): boolean {
    // Check that user exists and that user role level is 200 (SystemAdmin) but does not have session-token-admin cookie. That cookie exists if admin user switches to other customer.
    if (
      this.user &&
      !this.cookies.get("session-token-admin") &&
      this.user.roleLevel === 200
    ) {
      return true;
    }
    return false;
  }

  showControlRoomClocks(): boolean {
    if (this.user && this.user.roleLevel === 120) {
      return true;
    }
    return false;
  }
}

import { map } from 'rxjs/operators';
import { CommonModule } from "@angular/common";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/services/api.service";
import { Observable, Subscription, forkJoin } from "rxjs";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  OnChanges,
} from "@angular/core";
import { ICustomerInfo } from '../new-staff.component';

interface IAddedCompany {
  company: string;
  role: string;
  staffId: string;
}

@Component({
  selector: "app-new-staff-edit",
  templateUrl: "./new-staff-edit.component.html",
  styleUrls: ["./new-staff-edit.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDividerModule,
    TranslateModule,
  ],
})
export class NewStaffEditComponent implements OnChanges {
  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) { }
  @Input() staff: any;
  @Input() customers: ICustomerInfo[];

  @Output() onAddCompany = new EventEmitter<void>();
  @Output() onRemoveCompany = new EventEmitter<number>();
  @Output() onMovingSelected = new EventEmitter<any>();
  @Output() onDeleteFailed = new EventEmitter<void>();
  @Output() onCloseEditCard = new EventEmitter<void>();

  dataLoaded: boolean = false;

  roles = [
    { value: 100, name: this.translate.instant("STAFF_MAIN_USER") },
    { value: 50, name: this.translate.instant("STAFF_USER") },
    { value: 10, name: this.translate.instant("EveronMobileApp") },
    { value: 0, name: this.translate.instant("STAFF_READONLY") },
  ];

  isCompanyAdded(companyId: string) {
    return this.staff.addedCompanies.some((item: IAddedCompany) => item.company === companyId);
  }

  startAddingCompany() {
    this.staff.locationAmount += 1;
    this.onAddCompany.emit();
  }

  startRemovingCompany(r_id: number) {
    this.staff.locationAmount -= 1;
    this.onRemoveCompany.emit(r_id);
  }

  startMovingSelected() {
    this.onMovingSelected.emit(this.staff);
  }

  initClosingEditCard() {
    this.onCloseEditCard.emit();
  }

  parseAddedCompanies(staffLocations?: any[]): any[] {
    const addedCompanies = [];
    for (const location of staffLocations) {
      addedCompanies.push({
        company: location.companyId,
        role: location.staffRole.toString(),
        staffId: location.staffId,
      });
    }
    return addedCompanies;
  }

  ngOnChanges(): void {
    const identityId = this.staff.identityId;

    this.api.getStaffCompanyLocations(identityId).subscribe(
      (locations: any) => {
        this.staff.staffLocations = locations;
        this.staff["addedCompanies"] = this.parseAddedCompanies(locations);

        this.dataLoaded = true;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  openDialog(user: any, companyId: any): void {
    // Get locations staff id and company information
    const staffId = user.staffLocations.find(
      (location: any) => location.companyId === companyId
    ).staffId;
    const company = this.customers.find(
      (customer) => customer.companyId === companyId
    );

    const dialogRef = this.dialog.open(RemoveStaffFromCompanyDialog, {
      width: "450px",
      // Data for dialog object to use
      data: {
        personnel: user.firstName + " " + user.familyName,
        company: company.company + " (" + company.companyId + ")",
        staffId: staffId,
      },
    });

    dialogRef.afterClosed().subscribe(
      (removeCompanyResult: Observable<any>) => {
        if (removeCompanyResult) {
          removeCompanyResult.subscribe((result) => {
            if (result) {
              // Remove deleted company from the list...
              const index = user.addedCompanies.findIndex(
                (c: any) => c.company === companyId
              );
              const removedLocation = user.addedCompanies.splice(index, 1)[0];

              // ... and from location list to display current location status in the table
              this.staff.locationList = this.staff.locationList.filter(
                (x: string) => x !== this.getLocationName(removedLocation.company)
              );
            }
          });
        }
      },
      (err) => {
        this.onDeleteFailed.emit();
      }
    );
  }

  getLocationName(companyId: string): string {
    return this.customers?.find(c => c.companyId === companyId).company ?? "";
  }

  updateStaffRole(templateRef: MatSelect, staff: any) {
    const identityId = staff.identityId;
    const currentRole = staff.staffRole;
    const selectedRole = templateRef.value;

    this.api.updateNewStaffRole(selectedRole, identityId).subscribe(
      (success: boolean) => {
        if (success) {
          staff.staffRole = selectedRole;
          staff["updateFailed"] = false;
        } else {
          // If updating staff role failed, set dropdown's selected value back to staff's current role
          templateRef.value = currentRole;
          staff["updateFailed"] = true;
        }
      },
      (error) => {
        templateRef.value = currentRole;
        staff["updateFailed"] = true;
      }
    );
  }
}

@Component({
  selector: "remove-company-dialog",
  templateUrl: ".././remove-company-dialog.html",
  styles: [
    `
      h3,
      p {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
          "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
      }
      .warning {
        padding: 20px 24px 20px;
        color: #e8413b;
      }
    `,
  ],
  standalone: true,
  imports: [TranslateModule, MatDialogModule],
})
export class RemoveStaffFromCompanyDialog {
  constructor(
    private api: ApiService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  deleteCompany(id: any): Observable<any> {
    return this.api.deleteStaffCompanyLocation(id);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

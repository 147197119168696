import { Component, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HighlightSearch } from 'app/pipes/pipes';
import { ExpandCollapseAnimation } from 'app/animations';
import { CaretRotationAnimation } from './category-multiselect-button-animations';
import { OptionGroup, Option } from './category-multiselect-button-models';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-category-multiselect-button',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, MatCheckboxModule, HighlightSearch],
  templateUrl: './category-multiselect-button.component.html',
  styleUrls: ['./category-multiselect-button.component.css'],
  animations: [ExpandCollapseAnimation, CaretRotationAnimation],
})
export class CategoryMultiselectButtonComponent implements OnInit {
  @ViewChild('dropdownMenu') dropdownMenu: ElementRef;
  @ViewChild('button') button: ElementRef;

  @Input() options: OptionGroup[];
  @Input() selected: any[] = [];
  @Input() name: string = 'Select';

  @Output() selectedChange = new EventEmitter<any[]>(); // Output for two-way binding

  searchText: string = '';
  showDropdownMenu: boolean = false;
  originalOptions: OptionGroup[]; // Used to reset options after search

  checkGroup(group: OptionGroup): void {
    group.options.forEach(option => option.checked = true);
    this.setSelected();
  }

  uncheckGroup(group: OptionGroup): void {
    group.options.forEach(option => option.checked = false);
    this.setSelected();
  }

  toggle(group: OptionGroup): void {
    this.setSelected();
    group.allChecked = this.allChecked(group);
  }

  toggleAll(group: OptionGroup): void {
    if (group.allChecked) {
      this.checkGroup(group);
    } else {
      this.uncheckGroup(group);
    }
  }

  uncheckAll(): void {
    for (const group of this.options) {
      group.options.forEach(option => option.checked = false);
      group.allChecked = false;
    }
    this.setSelected();
  }

  // Collapse or expand a single group
  toggleCollapse(group: OptionGroup): void {
    group.collapseState = group.collapseState === "expanded" ? "collapsed" : "expanded";
  }

  // Collapse all groups
  collapseGroups(groups: OptionGroup[]): void {
    groups.forEach(group => group.collapseState = "collapsed");
  }

  // Expand all groups
  expandGroups(groups: OptionGroup[]): void {
    groups.forEach(group => group.collapseState = "expanded");
  }

  search(event: any): void {
    this.searchText = event.target.value;
    this.handleSearch();
  }

  clearSearch(): void {
    this.searchText = '';
    this.resetOptions();
  }

  someChecked(group: OptionGroup): boolean {
    return group.options.some(option => option.checked) && !group.allChecked;
  }

  allChecked(group: OptionGroup): boolean {
    return group.options.every(option => option.checked);
  }

  openDropdown(): void {
    this.showDropdownMenu = true;
    this.clearSearch();
  }

  @HostListener('document:click', ['$event'])
  handleHideDropdown(event: any): void {
    if (!this.button || !this.dropdownMenu) {
      return;
    }

    const clickedDropdown = this.dropdownMenu.nativeElement.contains(event.target);
    const clickedButton = this.button.nativeElement.contains(event.target);

    if (!clickedDropdown && !clickedButton) {
      this.showDropdownMenu = false;
    }
  }

  ngOnInit(): void {
    // Save original options during initialization
    this.originalOptions = cloneDeep(this.options);
  }

  private setSelected(): void {
    const checked: string[] = [];

    for (const group of this.options) {
      group.options.forEach(option => {
        if (option.checked) {
          checked.push(option.value);
        }
      });
    }
    // Set new selected array to trigger change detection
    this.selected = [...checked];
    this.selectedChange.emit(this.selected);
  }

  private resetOptions(): void {
    this.options = this.originalOptions;
    this.collapseGroups(this.options);
  }

  private handleSearch(): void {
    if (this.searchText) {
      this.options = this.originalOptions.filter(group => this.matchesSearch(group));
      this.expandGroups(this.options);
    } else {
      this.resetOptions();
    }
  }

  private matchesGroupName(group: OptionGroup): boolean {
    return group.name.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase());
  }

  private matchesGroupOptionName(option: Option): boolean {
    return option.name.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase());
  }

  private matchesSearch(group: OptionGroup): boolean {
    return this.matchesGroupName(group) || group.options.some(option => this.matchesGroupOptionName(option));
  }
}

<div class="card stickyCard">
    <div class="card-header-white header-underline d-flex justify-content-between">
        <h6 class="white-header-text">{{location.name}} ({{location.id}})</h6>
        <span class="float-right cursorPointer icon-container" (click)="closeLocationCard()" >
            <span
                class="fa-light fa-xmark white-header-xmark-icon">
            </span>
        </span>
    </div>
    <div class="card-body-white">
        <div class="row" *ngIf="location">
            <div class="col-lg-12" *ngIf="!deleteConfirmation">
                <div class="mb-2">
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <span class="editPageTab" [ngClass]="{'editPageTab-active' : activeTab === 1}"
                                (click)="activeTab = 1">{{'INFORMATION' |translate}}</span>
                        </li>
                        <li class="list-inline-item">
                            <span class="editPageTab" [ngClass]="{'editPageTab-active' : activeTab === 3}"
                                (click)="activeTab = 3">{{'ACCESS_RIGHTS' |translate}}</span>
                        </li>
                        <li class="list-inline-item">
                            <span class="editPageTab" *ngIf="showIfFeatureEnabled('EveronMobileApp')"
                                [ngClass]="{'editPageTab-active' : activeTab === 2}"
                                (click)="activeTab = 2">{{'EMA_SETTINGS' |translate}}</span>
                        </li>
                        <li class="list-inline-item">
                            <span class="editPageTab" *ngIf="showIfFeatureEnabled('IotComms') && user.roleLevel === 200"
                                [ngClass]="{'editPageTab-active' : activeTab === 4}"
                                (click)="activeTab = 4">{{'IOTCOMMS_SETTINGS' |translate}}</span>
                        </li>
                    </ul>
                </div>
                <div *ngIf="activeTab === 1">
                    <div *ngIf="updateSuccess" class="alert alert-success">
                        {{'LOCATION_UPDATE_SUCCESS' |translate}}
                    </div>
                    <div *ngIf="updateError" class="alert alert-danger">
                        {{'ERROR' |translate}}
                    </div>
                    <!-- View location information-->
                    <div *ngIf="!editMode">
                        <div>
                            <strong>{{'NAME' |translate}}:</strong> {{location.name}}
                        </div>
                        <div *ngIf="showIfFeatureEnabled('Joliv');">
                            <strong>{{'QUALIFIER' |translate}}:</strong> {{location.qualifier}}
                        </div>
                        <div *ngIf="location.contactPhone">
                            <strong>{{'CONTACT_PHONE' |translate}}</strong> {{location.contactPhone}}
                        </div>
                        <div>
                            <strong>{{'ADDRESS' |translate}}</strong> {{location.address}}
                        </div>
                        <div>
                            <strong>{{'POSTCODE' |translate}}</strong> {{location.postCode}}
                        </div>
                        <div>
                            <strong>{{'POSTOFFICE' |translate}}</strong> {{location.postOffice}}
                        </div>
                        <div *ngIf="user.roleLevel >= 100" class="mt-3">
                            <button class="btn btn-outline-primary gray-bg" (click)="editMode = !editMode"><span
                                    class="fa-light fa-fw fa-pen-to-square"></span> {{'MODIFY' |translate}}</button>
                        </div>
                    </div>
                    <!-- Edit location information -->
                    <div *ngIf="editMode">
                        <form [formGroup]="locationEditForm" (ngSubmit)="submitLocationEdit()">
                            <div class="form-group">
                                <label><strong>{{'NAME' |translate}}</strong></label>
                                <input class="form-control" type="text" placeholder="{{'NAME' |translate}}"
                                    formControlName="name">
                            </div>
                            <div class="form-group" *ngIf="showIfFeatureEnabled('Joliv');">
                                <label><strong>{{'QUALIFIER' |translate}}</strong></label>
                                <input class="form-control" type="text" placeholder="{{'QUALIFIER' |translate}}"
                                    formControlName="qualifier">
                            </div>
                            <div class="form-group">
                                <label><strong>{{'CONTACT_PHONE' |translate}}</strong></label>
                                <input class="form-control" type="text" placeholder="{{'CONTACT_PHONE' |translate}}"
                                    formControlName="contactPhone">
                            </div>
                            <div class="form-group">
                                <label><strong>{{'ADDRESS' |translate}}</strong></label>
                                <input class="form-control" type="text" placeholder="{{'ADDRESS' |translate}}"
                                    formControlName="address">
                            </div>
                            <div class="form-group">
                                <label><strong>{{'POSTCODE' |translate}}</strong></label>
                                <input class="form-control" type="text" placeholder="{{'POSTCODE' |translate}}"
                                    formControlName="postCode">
                            </div>
                            <div class="form-group">
                                <label><strong>{{'POSTOFFICE' |translate}}</strong></label>
                                <input class="form-control" type="text" placeholder="{{'POSTOFFICE' |translate}}"
                                    formControlName="postOffice">
                            </div>
                            <div class="form-group">
                                <label>{{'EMA_TAG_ONLY' |translate}}</label>
                                <section>
                                    <mat-slide-toggle formControlName="emaTagOnly">
                                    </mat-slide-toggle>
                                </section>
                            </div>
                            <div class="form-group">
                                <button [disabled]="(locationEditForm.invalid || locationEditForm.pristine)"
                                    type="submit" class="btn btn-outline-primary gray-bg mr-1"><span
                                        class="fa-light fa-floppy-disk"></span> {{'SAVE' |translate}}</button>
                                <button type="reset" class="btn btn-outline-danger mr-1"
                                    (click)="resetLocationEditForm()"><span class="fa-light fa-xmark"></span>
                                    {{'CANCEL' |translate}}</button>
                                <span class="btn btn-outline-danger float-right" *ngIf="user.roleLevel === 200"
                                    (click)="confirmLocationDelete()"><span class="fa-light fa-octagon-minus"></span>
                                    {{'DELETE' |translate}}</span>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- Content for location access rights -->
                <div *ngIf="activeTab === 3">
                    <location-access-rights [location]="location"></location-access-rights>
                </div>
                <!-- Content for EMA settings -->
                <div *ngIf="activeTab === 2">
                    <location-ema-settings [location]="location"></location-ema-settings>
                </div>
                <!-- Content for IotComms settings -->
                <div *ngIf="activeTab === 4">
                    <location-iotcomms-settings [location]="location"></location-iotcomms-settings>
                </div>
            </div>
            <!--Information and confirmation for location deletion-->
            <div class="col-lg-12" *ngIf="deleteConfirmation">
                <div class="card-body-container">
                    <div *ngIf="deleteError">
                        <div class="alert alert-danger">{{'ERROR' |translate}}</div>
                    </div>
                    <div>
                        <strong>{{'LOCATION_DELETE_CONFIRM' |translate: {location: location.name + ' - ' + location.id}
                            }}</strong>
                    </div>
                    <div
                        *ngIf="locationConfirmData?.devices.length > 0 || locationConfirmData?.personnels.length > 0 || locationConfirmData?.alarmRoutes.length > 0">
                        <strong>{{'LOCATION_DELETE_CONFIRM_DEVICES' |translate}}</strong>
                        <div *ngIf="locationConfirmData?.devices.length > 0">
                            {{'DEVICES' |translate}}
                            <ul>
                                <li *ngFor="let dev of locationConfirmData.devices"><span *ngIf="dev.name">{{dev.name}}
                                        - </span> {{dev.id}}</li>
                            </ul>
                        </div>
                        <div *ngIf="locationConfirmData?.personnels.length > 0">
                            {{'STAFF_MEMBERS' |translate}}
                            <ul>
                                <li *ngFor="let personnel of locationConfirmData.personnels"><span
                                        *ngIf="personnel.firstName">{{personnel.firstName}} </span><span
                                        *ngIf="personnel.familyName">{{personnel.familyName}} -</span>
                                    {{personnel.username}}, {{personnel.id}}</li>
                            </ul>
                        </div>
                        <div *ngIf="locationConfirmData?.alarmRoutes.length > 0">
                            {{'ALARM_ROUTES' |translate}}
                            <ul>
                                <li *ngFor="let route of locationConfirmData.alarmRoutes"><span
                                        *ngIf="route.name">{{route.name}} - </span> {{route.id}}</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-outline-danger mr-1" (click)="confirmDelete()"><span
                                class="fa-light fa-octagon-minus"></span> {{'DELETE' |translate}}</button>
                        <button class="btn btn-outline-primary gray-bg" (click)="cancelDelete()"><span
                                class="fa-light fa-xmark"></span> {{'CANCEL' |translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12" style="text-align: center;">
            <div *ngIf="!errorMsg">
                <img class="img-fluid" [src]="'data:image/gif;base64,' + alert?.imageData" alt=""/>
            </div>
            <div class="alert alert-danger" *ngIf="errorMsg">
                {{errorMsg}}
            </div>
        </div>
    </div>
</div>

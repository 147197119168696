<div [ngSwitch]="elementType">
    <ng-container *ngSwitchCase="'batteryIcon'">
        <i *ngIf="value" class="fa-solid fa-fw fa-lg" [ngClass]="value | batteryIcon"></i>
        <span *ngIf="!value">{{ "NONE" | translate }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="'deviceStatus'">
        <app-feature-badge
            *ngIf="value"
            [feature]="value | deviceStatusSelector"></app-feature-badge>

        <span *ngIf="!value">{{ "NONE" | translate }}</span>
    </ng-container>

    <span *ngSwitchDefault>{{ value || ("NONE" | translate) }}</span>
</div>
<h3 class="warning">{{"WARNING" | translate}}!</h3>
<div mat-dialog-content>
    <p>{{"DELETE_STAFF_FROM_COMPANY" | translate}}?</p>
    <p>
        {{"ARE_YOU_SURE_YOU_WANT_TO_REMOVE_PERSONNEL" | translate}}
        <b>{{data.personnel}}</b>
        {{"FROM_COMPANY" |translate}}
        <b>{{data.company}}</b>?
    </p>
    <p style="color: #1D83E9">{{"YOU_CANT_UNDO_THIS_ACTION" |translate}}.</p>

</div>
<div mat-dialog-actions class="d-flex justify-content-between m-3">
    <button
        class="btn btn-outline-danger"
        [mat-dialog-close]="deleteCompany(data.staffId)"
    >
        <span class="fa fa-trash-can"></span> {{"DELETE" | translate}}
    </button>
    <button class="btn btn-primary" (click)="onNoClick()" [mat-dialog-close]="false">
        {{"CANCEL" | translate}}
    </button>
</div>

<form class="form mx-5 mt-4" #updateCompanyGroup="ngForm" role="form">
    <div class="form-group m-3">
        <label for="groupName"
            ><b>{{ "NAME" | translate }}</b></label
        >
        <input
            type="text"
            name="groupName"
            id="groupName"
            [(ngModel)]="editCompanyGroupForm.name"
            class="form-control form-control-sm"
            [ngClass]="{
                'is-invalid': !groupName.value,
                'is-valid':
                    groupName.value && groupName.value !== companyGroup.name
            }"
            #groupName="ngModel"
            required
        />
    </div>

    <div class="form-group m-3">
        <label for="address"
            ><b>{{ "ADDRESS" | translate }}</b></label
        >
        <input
            type="text"
            [(ngModel)]="editCompanyGroupForm.address"
            name="address"
            class="form-control form-control-sm"
            [ngClass]="{
                'is-invalid': !address.value,
                'is-valid':
                    address.value && address.value !== companyGroup.address
            }"
            id="address"
            #address="ngModel"
            required
        />
    </div>

    <div class="m-3">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="postOffice">
                        <b>{{ "POSTOFFICE" | translate }}</b>
                    </label>
                    <input
                        type="text"
                        [(ngModel)]="editCompanyGroupForm.postOffice"
                        name="postOffice"
                        class="form-control form-control-sm"
                        id="postOffice"
                        [ngClass]="{
                            'is-invalid': !postOffice.value,
                            'is-valid':
                                postOffice.value &&
                                postOffice.value !== companyGroup.postOffice
                        }"
                        id="postOffice"
                        #postOffice="ngModel"
                        required
                    />
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group">
                    <label for="postCode">
                        <b>{{ "POSTCODE" | translate }}</b>
                    </label>
                    <input
                        type="text"
                        [(ngModel)]="editCompanyGroupForm.postCode"
                        name="postCode"
                        class="form-control form-control-sm"
                        [ngClass]="{
                            'is-invalid': !postCode.value,
                            'is-valid':
                                postCode.value &&
                                postCode.value !== companyGroup.postCode
                        }"
                        id="postCode"
                        #postCode="ngModel"
                        required
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="m-3 pb-1">
        <label for="country">
            <b>{{ "COUNTRY" | translate }}</b>
        </label>
        <select
            [(ngModel)]="editCompanyGroupForm.country"
            name="country"
            class="form-control form-control-sm"
            [ngClass]="{
                'is-invalid bgImgPosition': !country.value,
                'is-valid bgImgPosition': country.value !== companyGroup.country
            }"
            id="country"
            #country="ngModel"
            required
        >
            <option
                *ngFor="let tz of timezones"
                [value]="tz.country.alpha3Code"
            >
                {{ tz.country.name }}
            </option>
        </select>
        <div class="mt-2">
            <p *ngIf="editCompanyGroupForm.country" class="ml-1">
                {{ "TIME_ZONE" | translate }}:
                <strong>{{ setTimezone() }}</strong>
            </p>
        </div>
    </div>

    <div class="mx-3 mt-2 pb-3">
        <label for="language">
            <b>{{ "LANGUAGE" | translate }}</b>
        </label>
        <select
            [(ngModel)]="editCompanyGroupForm.langId"
            name="language"
            class="form-control form-control-sm"
            [ngClass]="{
                'is-valid bgImgPosition': language.value !== companyGroup.langId
            }"
            #language="ngModel"
            id="language"
        >
            <option value="FIN">
                {{ "FINNISH" | translate }}
            </option>
            <option value="ENG" selected>
                {{ "ENGLISH" | translate }}
            </option>
            <option value="SWE">
                {{ "SWEDISH" | translate }}
            </option>
            <option value="DEU">
                {{ "GERMAN" | translate }}
            </option>
            <option value="FRA">
                {{ "FRENCH" | translate }}
            </option>
            <option value="EST">
                {{ "ESTONIAN" | translate }}
            </option>
        </select>
    </div>

    <hr />

    <div class="form-group m-3 pb-1">
        <label for="organizationNumber">
            <b>{{ "ORGANIZATION_NUMBER" | translate }}</b>
        </label>
        <input
            type="text"
            [(ngModel)]="editCompanyGroupForm.organizationNumber"
            name="'organizationNumber"
            class="form-control form-control-sm"
            [ngClass]="{
                'is-invalid': !organizationNumber.value,
                'is-valid':
                    organizationNumber.value &&
                    organizationNumber.value !== companyGroup.organizationNumber
            }"
            id="organizationNumber"
            #organizationNumber="ngModel"
            required
        />
        <small class="ml-1" style="color: #1241cd">
            {{ "FOR_EXAMPLE_YTUNNUS_IN_FINLAND_OR_EIN_IN_USA" | translate }}
        </small>
    </div>

    <div class="form-group m-3 pb-2">
        <label for="organizationNumber">
            <b>{{ "CONTACT_PHONE" | translate }}</b>
        </label>
        <input
            type="text"
            [(ngModel)]="editCompanyGroupForm.contactPhone"
            name="contactPhone"
            class="form-control form-control-sm"
            [ngClass]="{
                'is-invalid': !contactPhone.value,
                'is-valid':
                    contactPhone.value &&
                    contactPhone.value !== companyGroup.contactPhone
            }"
            id="contactPhone"
            #contactPhone="ngModel"
            required
        />
    </div>
</form>

<div class="mt-5">
    <div class="ml-4" style="width: fit-content">
        <div *ngIf="successMessage" class="alert alert-success" role="alert">
            {{ successMessage | translate }}
        </div>
        <div *ngIf="failureMessage" class="alert alert-danger" role="alert">
            {{ failureMessage | translate }}
        </div>
    </div>

    <div class="mr-3 mb-3 ml-4">
        <button
            type="submit"
            class="btn btn-primary mr-2"
            [disabled]="
                !updateCompanyGroup.form.valid ||
                updateCompanyGroup.form.pristine
            "
            (click)="updateCompanyGroup()"
        >
            <span class="fa-light fa-floppy-disk"></span>
            {{ "SAVE" | translate }}
        </button>

        <button class="btn btn-outline-primary gray-bg" (click)="initForm()">
            <span class="fa-light fa-rotate-left"></span>
            {{ "UNDO" | translate }}
        </button>
    </div>
</div>

import { SortOrder } from "app/services/helper.service";
import { ExpandCollapseAnimation } from "app/animations";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from "@angular/core";
import {
  IAlertsByLocation,
  ILocationAlerts,
} from "app/models/statistics.model";

@Component({
  selector: "alerts-by-location",
  templateUrl: "./alerts-by-location.component.html",
  styleUrls: ["./alerts-by-location.component.css"],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  animations: [ExpandCollapseAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsByLocationComponent implements OnInit {
  constructor() {}

  @Input() locationAlerts: ILocationAlerts;

  expandState: "collapsed" | "expanded" = "collapsed";
  expandedList: IAlertsByLocation[] = [];
  collapsedList: IAlertsByLocation[] = [];

  defaultCollapseStateRowCount: number = 10;
  sorter: string = "";
  sortOrder: SortOrder = SortOrder.Default;

  // Style objects for the alarm count columns
  alarmContainerStyles: { "grid-template-columns": string };

  toggleExpand(): void {
    this.expandState =
      this.expandState === "collapsed" ? "expanded" : "collapsed";
  }

  sort(sorter: "location" | "alerts"): void {
    if (this.sorter !== sorter) {
      this.sortOrder = SortOrder.Default;
    }
    this.sorter = sorter;
    let sorted: any;

    switch (this.sortOrder) {
      case SortOrder.Default:
        if (sorter === "location") {
          sorted = this.locationAlerts.alertsByLocations.sort(
            this.compareLocationNamesAsc
          );
        }
        if (sorter === "alerts") {
          sorted = this.locationAlerts.alertsByLocations.sort(
            this.compareAlertCountsDesc
          );
        }
        this.sortOrder = SortOrder.Desc;
        break;
      case SortOrder.Desc:
        if (sorter === "location") {
          sorted = this.locationAlerts.alertsByLocations.sort(
            this.compareLocationNamesDesc
          );
        }
        if (sorter === "alerts") {
          sorted = this.locationAlerts.alertsByLocations.sort(
            this.compareAlertCountsAsc
          );
        }
        this.sortOrder = SortOrder.Asc;
        break;
      case SortOrder.Asc:
        sorted = this.locationAlerts.alertsByLocations;
        this.sorter = "";
        this.sortOrder = SortOrder.Default;
        break;
    }
    this.parseLocationAlerts(sorted);
  }

  compareLocationNamesAsc(a: IAlertsByLocation, b: IAlertsByLocation): number {
    return a.locationName.localeCompare(b.locationName);
  }

  compareLocationNamesDesc(a: IAlertsByLocation, b: IAlertsByLocation): number {
    return b.locationName.localeCompare(a.locationName);
  }

  compareAlertCountsAsc(a: IAlertsByLocation, b: IAlertsByLocation): number {
    return a.alertCount - b.alertCount;
  }

  compareAlertCountsDesc(a: IAlertsByLocation, b: IAlertsByLocation): number {
    return b.alertCount - a.alertCount;
  }

  parseLocationAlerts(alertsByLocations: IAlertsByLocation[]): void {
    const collapsedAlerts: IAlertsByLocation[] = [];
    const expandedAlerts: IAlertsByLocation[] = [];

    for (let i = 0; i < alertsByLocations.length; i++) {
      this.defaultCollapseStateRowCount > i
        ? collapsedAlerts.push(alertsByLocations[i])
        : expandedAlerts.push(alertsByLocations[i]);
    }

    this.expandedList = expandedAlerts;
    this.collapsedList = collapsedAlerts;
  }

  // Set width of the alert count columns based on number digits of the biggest alert count
  setAlarmColumnWidths(maxAlarms: number): void {
    let alertCountGridColumnWidth = "auto";
    // 1-digit
    if (maxAlarms < 10) {
      alertCountGridColumnWidth = "6px";
    }
    // 2-digits
    else if (maxAlarms < 100) {
      alertCountGridColumnWidth = "14px";
    }
    // 3-digits
    else if (maxAlarms < 1000) {
      alertCountGridColumnWidth = "22px";
    }
    // 4-digits
    else if (maxAlarms < 10000) {
      alertCountGridColumnWidth = "30px";
    }
    // 5-digits
    else if (maxAlarms < 100000) {
      alertCountGridColumnWidth = "38px";
    }
    // 6-digits
    else if (maxAlarms < 1000000) {
      alertCountGridColumnWidth = "46px";
    }
    // Set styles
    this.alarmContainerStyles = {
      "grid-template-columns": `${alertCountGridColumnWidth} auto`,
    };
  }

  ngOnInit(): void {
    const maxAlarmCount = Math.max(
      ...this.locationAlerts.alertsByLocations.map(
        (location) => location.alertCount
      )
    );
    this.setAlarmColumnWidths(maxAlarmCount);
    this.parseLocationAlerts(this.locationAlerts.alertsByLocations);
  }
}

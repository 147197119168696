<div class="row">
    <div class="col-xl-12">
        <h3>{{'AnnaPerenna' |translate}}</h3>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <span class="fa-light fa-rotate fa-lg float-right cursorPointer" (click)="resetMonitors()"></span>
    </div>
</div>
<ng-container *ngFor="let monitor of monitors; let i = index">
    <div class="row mb-4" *ngIf="i % 6 === 0">
        <div class="col-xl-2" *ngIf="monitors[i]">
            <ap-monitor-card (monitorInEdit)="monitorInEdit($event)" (monitorRemovedEmit)="monitorRemovedEmit($event)"
                [monitor]="monitors[i]" [profiles]="profiles"></ap-monitor-card>
        </div>
        <div class="col-xl-2" *ngIf="monitors[i+1]">
            <ap-monitor-card (monitorInEdit)="monitorInEdit($event)" (monitorRemovedEmit)="monitorRemovedEmit($event)"
                [monitor]="monitors[i+1]" [profiles]="profiles"></ap-monitor-card>
        </div>
        <div class="col-xl-2" *ngIf="monitors[i+2]">
            <ap-monitor-card (monitorInEdit)="monitorInEdit($event)" (monitorRemovedEmit)="monitorRemovedEmit($event)"
                [monitor]="monitors[i+2]" [profiles]="profiles"></ap-monitor-card>
        </div>
        <div class="col-xl-2" *ngIf="monitors[i+3]">
            <ap-monitor-card (monitorInEdit)="monitorInEdit($event)" (monitorRemovedEmit)="monitorRemovedEmit($event)"
                [monitor]="monitors[i+3]" [profiles]="profiles"></ap-monitor-card>
        </div>
        <div class="col-xl-2" *ngIf="monitors[i+4]">
            <ap-monitor-card (monitorInEdit)="monitorInEdit($event)" (monitorRemovedEmit)="monitorRemovedEmit($event)"
                [monitor]="monitors[i+4]" [profiles]="profiles"></ap-monitor-card>
        </div>
        <div class="col-xl-2" *ngIf="monitors[i+5]">
            <ap-monitor-card (monitorInEdit)="monitorInEdit($event)" (monitorRemovedEmit)="monitorRemovedEmit($event)"
                [monitor]="monitors[i+5]" [profiles]="profiles"></ap-monitor-card>
        </div>
    </div>
    <div *ngIf="monitorsNotFound">
        {{'NO_DEVICES' |translate}}
    </div>
</ng-container>

<div class="row">
    <div class="col-lg-12">
        <div *ngIf="!monitors && !monitorsNotFound">
            <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg" />
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <a routerLink="/anna-perenna">
            <button class="btn btn-outline-primary mb-4"><span class="fa-light fa-angle-left fa-fw"
                    aria-hidden="true"></span>{{'BACK' |translate}}</button>
        </a>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="mb-5">
            <h5>{{monitor?.monitorName}} ({{monitor?.aspaId}})</h5>
        </div>
    </div>
</div>
<div class="row">

    <div class="col-xl-8">
        <ap-monitor-zone *ngIf="monitorId" [monitorAspaId]="monitorId"></ap-monitor-zone>
    </div>

    <div class="col-xl-4 profileManagement">
        <h6>{{'PROFILE_MANAGEMENT' |translate}}</h6>

        <div class="input-group mb-2 profileSelection">

            <mat-form-field class="selector-full-width">
                <mat-label>{{'SELECT_PROFILE' |translate}}</mat-label>
                <mat-select [(ngModel)]="selectedProfile" (ngModelChange)="profileChanged($event)">
                    <mat-option value=null disabled>{{'SELECT_PROFILE' |translate}}</mat-option>
                    <mat-option *ngFor="let profile of profiles" [value]="profile">
                        {{profile.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="input-group-prepend create-button">
                <button type="button" class="btn btn-outline-primary ml-1"><span
                        class="fa-light fa-plus fa-lg cursorPointer" (click)="createNewProfile()"
                        title="{{'CREATE_NEW_PROFILE' |translate}}"></span></button>
            </div>
        </div>

        <form [formGroup]="profileManagementForm" *ngIf="selectedProfile" (ngSubmit)="formSubmitSubject.next()">
            <div class="form-group profileSelection">
                <label>{{'NAME' |translate}}</label>
                <input placeholder="{{'NAME' |translate}}" type="text" class="form-control"
                    formControlName="profileName">
            </div>
            <div class="form-group">
                <label>{{'MONITORING_ENABLED' |translate}}</label>
                <section>
                    <mat-slide-toggle formControlName="profileMonitoringEnabled">
                    </mat-slide-toggle>
                </section>

            </div>
            <div class="row">
                <div class="col-xl-12" *ngIf="profileManagementForm.value.profileMonitoringEnabled">
                    <div class="form-group">
                        <label>{{'BED_OUT'|translate}}</label><span matTooltip="{{'AP_HELP_BED_OUT' |translate}}"
                            class="fa fa-info-circle ml-2"></span>
                        <div class="form-row align-items-center">
                            <section>
                                <mat-slide-toggle formControlName="profileBedOutEnabled">
                                </mat-slide-toggle>
                            </section>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{'BED_OUT_CONTROL'|translate}} ({{'MISC_MINUTES_UP_FROM_BED'|translate}})</label><span
                            matTooltip="{{'AP_HELP_UP_FROM_BED' |translate}}" class="fa fa-info-circle ml-2"></span>
                        <div class="form-row align-items-center">
                            <div class="col-0">
                                <section>
                                    <mat-slide-toggle formControlName="profileBedOutControlEnabled">
                                    </mat-slide-toggle>
                                </section>
                            </div>
                            <div class="col-xl-7 col-2">
                                <fieldset [disabled]="!profileManagementForm.value.profileBedOutControlEnabled">
                                    <input type="text" class="form-control" formControlName="profileBedOutControl"
                                        placeholder="{{'BED_OUT_CONTROL' |translate}}">
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{'ROOM_OUT'|translate}} ({{'MISC_MINUTES'|translate}})</label><span
                            matTooltip="{{'AP_HELP_ROOM_OUT' |translate}}" class="fa fa-info-circle ml-2"></span>
                        <div class="form-row align-items-center">
                            <div class="col-0">
                                <section>
                                    <mat-slide-toggle formControlName="profileRoomOutEnabled">
                                    </mat-slide-toggle>
                                </section>

                            </div>

                            <div class="col-xl-7 col-2">
                                <fieldset [disabled]="!profileManagementForm.value.profileRoomOutEnabled">
                                    <input type="text" class="form-control" formControlName="profileRoomOut"
                                        placeholder="{{'ROOM_OUT' |translate}}">
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{'MAX_TOILET_TIME'|translate}} ({{'MISC_MINUTES'|translate}})</label><span
                            matTooltip="{{'AP_HELP_WC' |translate}}" class="fa fa-info-circle ml-2"></span>
                        <div class="form-row align-items-center">
                            <div class="col-0">
                                <section>
                                    <mat-slide-toggle formControlName="profileMaxToiletTimeEnabled">
                                    </mat-slide-toggle>
                                </section>

                            </div>
                            <div class="col-xl-7 col-2">
                                <fieldset [disabled]="!profileManagementForm.value.profileMaxToiletTimeEnabled">
                                    <input type="text" class="form-control" formControlName="profileMaxToiletTime"
                                        placeholder="{{'MAX_TOILET_TIME' |translate}}">
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{'MAX_FLOOR_TIME'|translate}} ({{'MISC_MINUTES'|translate}})</label><span
                            matTooltip="{{'AP_HELP_FLOOR' |translate}}" class="fa fa-info-circle ml-2"></span>
                        <div class="form-row align-items-center">
                            <div class="col-0">
                                <section>
                                    <mat-slide-toggle formControlName="profileMaxFloorTimeEnabled">
                                    </mat-slide-toggle>
                                </section>
                            </div>
                            <div class="col-xl-7 col-2">
                                <fieldset [disabled]="!profileManagementForm.value.profileMaxFloorTimeEnabled">
                                    <input type="text" class="form-control" formControlName="profileMaxFloorTime"
                                        placeholder="{{'MAX_FLOOR_TIME' |translate}}">
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{'INACTIVE_TIME'|translate}}</label><span matTooltip="{{'AP_HELP_INACTIVE' |translate}}"
                            class="fa fa-info-circle ml-2"></span>
                        <div class="input-group align-items-center">

                            <section class="mr-2">
                                <mat-slide-toggle formControlName="profileInactiveTimeEnabled">
                                </mat-slide-toggle>
                            </section>
                            <fieldset [disabled]="!profileManagementForm.value.profileInactiveTimeEnabled">
                                <input type="text" class="form-control profileTimeInput"
                                    formControlName="profileInactiveTimeStart" placeholder="{{'START' |translate}}">
                            </fieldset>
                            <div class="input-group-prepend">
                                <span class="input-group-text">-</span>
                            </div>
                            <fieldset [disabled]="!profileManagementForm.value.profileInactiveTimeEnabled">
                                <input type="text" class="form-control profileTimeInput"
                                    formControlName="profileInactiveTimeEnd" placeholder="{{'END' |translate}}">
                            </fieldset>
                        </div>
                    </div>


                    <div class="form-group">
                        <label>{{'SUPERACTIVE_TIME'|translate}}</label><span
                            matTooltip="{{'AP_HELP_ONLY_OUT_OF_BED' |translate}}" class="fa fa-info-circle ml-2"></span>
                        <div class="input-group align-items-center">
                            <section class="mr-2">
                                <mat-slide-toggle formControlName="profileSuperActiveTimeEnabled">
                                </mat-slide-toggle>
                            </section>
                            <fieldset [disabled]="!profileManagementForm.value.profileSuperActiveTimeEnabled">
                                <input type="text" class="form-control profileTimeInput"
                                    formControlName="profileSuperActiveTimeStart" placeholder="{{'START' |translate}}">
                            </fieldset>
                            <div class="input-group-prepend">
                                <span class="input-group-text">-</span>
                            </div>
                            <fieldset [disabled]="!profileManagementForm.value.profileSuperActiveTimeEnabled">
                                <input type="text" class="form-control profileTimeInput"
                                    formControlName="profileSuperActiveTimeEnd" placeholder="{{'END' |translate}}">
                            </fieldset>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{'TWO_PERSONS_IN_ROOM' |translate}}</label><span
                            matTooltip="{{'AP_HELP_TWO_PERSONS' |translate}}" class="fa fa-info-circle ml-2"></span>
                        <section>
                            <mat-slide-toggle formControlName="profileTwoPersonsInRoom">
                            </mat-slide-toggle>
                        </section>
                    </div>
                    <!--<div class="form-group">
                        <label>{{'FALL_RECOGNITION' |translate}}</label>

                        <section>
                            <mat-slide-toggle formControlName="profileFallRecognition">
                            </mat-slide-toggle>
                        </section>

                    </div>
                    <div class="form-group">
                        <label>{{'EXIT_NIGHT_TIME' |translate}}</label>
                        <section>
                            <mat-slide-toggle formControlName="profileExitNightTime">
                            </mat-slide-toggle>
                        </section>

                    </div>-->
                    <div class="form-group">
                        <label>{{'SEND_IMAGE' |translate}}</label><span matTooltip="{{'AP_HELP_SEND_IMAGE' |translate}}"
                            class="fa fa-info-circle ml-2"></span>
                        <section>
                            <mat-slide-toggle formControlName="profileSendImage">
                            </mat-slide-toggle>
                        </section>

                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="!deleteConfirm">
                <button type="submit" [disabled]="!profileManagementForm.valid || profileManagementForm.pristine"
                    class="btn btn-outline-primary mr-2"><span class="fa-light fa-floppy-disk"></span> {{'SAVE' |
                    translate}}</button>
                <span class="btn btn-outline-danger mr-2" *ngIf="selectedProfile.id"
                    (click)="deleteConfirm = true"><span class="fa-light fa-trash-alt"></span> {{'DELETE' |
                    translate}}</span>
            </div>
            <div class="form-group" *ngIf="deleteConfirm">
                {{'CONFIRM_PROFILE_DELETE' |translate: {profile: selectedProfile.name} }}<br>
                <span class="btn btn-outline-primary mt-2" *ngIf="selectedProfile.id"
                    (click)="deleteConfirm = false"><span class="fa-light fa-xmark"></span> {{'CANCEL' |
                    translate}}</span><br>
                <span class="btn btn-outline-danger mt-2" *ngIf="selectedProfile.id" (click)="deleteProfile()"><span
                        class="fa-light fa-trash-alt"></span> {{'DELETE' | translate}}</span>
            </div>
        </form>
        <div class="alert alert-success mt-3" *ngIf="saveSuccess">
            {{'CONF_SAVED' |translate}}
        </div>
        <div class="alert alert-danger mt-3" *ngIf="saveError">
            {{'ERROR' |translate}}
        </div>
    </div>
</div>

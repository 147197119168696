import { CommonModule, SlicePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IDeviceHistoryEvent } from 'app/models/device-history.model';
import { MomentPipe } from 'app/pipes/pipes';
import { DeviceHistoryStatusSelectorComponent } from './device-history-status-selector/device-history-status-selector.component';
import { ExpandCollapseAnimation } from 'app/animations';

@Component({
  selector: 'app-device-history-logs',
  templateUrl: './device-history-logs.component.html',
  styleUrls: ['./device-history-logs.component.css'],
  standalone: true,
  animations: [ExpandCollapseAnimation],
  imports: [CommonModule, TranslateModule, MomentPipe, SlicePipe, DeviceHistoryStatusSelectorComponent],
})
export class DeviceHistoryLogsComponent {
  @Input() historyEvents: IDeviceHistoryEvent[];

  expandState: "expanded" | "collapsed" = "collapsed";
  readonly collapseItemCount = 5;

  toggleExpand(): void {
    this.expandState
      = this.expandState === "collapsed" ? "expanded" : "collapsed";
  }
}

const fakeDeviceHistoryEvents: IDeviceHistoryEvent[] = [
  { timestamp: new Date(), statusKey: 'statusKey1', oldValue: 'oldValue1', newValue: 'newValue1', userId: 'userId1', userName: 'userName1' },
  { timestamp: new Date(), statusKey: 'statusKey2', oldValue: 'oldValue2', newValue: 'newValue2', userId: 'userId2', userName: 'userName2' },
  { timestamp: new Date(), statusKey: 'statusKey3', oldValue: 'oldValue3', newValue: 'newValue3', userId: 'userId3', userName: 'userName3' },
  { timestamp: new Date(), statusKey: 'statusKey4', oldValue: 'oldValue4', newValue: 'newValue4', userId: 'userId4', userName: 'userName4' },
  { timestamp: new Date(), statusKey: 'statusKey5', oldValue: 'oldValue5', newValue: 'newValue5', userId: 'userId5', userName: 'userName5' },
  { timestamp: new Date(), statusKey: 'statusKey6', oldValue: 'oldValue6', newValue: 'newValue6', userId: 'userId6', userName: 'userName6' },
  { timestamp: new Date(), statusKey: 'statusKey7', oldValue: 'oldValue7', newValue: 'newValue7', userId: 'userId7', userName: 'userName7' },
  { timestamp: new Date(), statusKey: 'statusKey8', oldValue: 'oldValue8', newValue: 'newValue8', userId: 'userId8', userName: 'userName8' },
  { timestamp: new Date(), statusKey: 'statusKey9', oldValue: 'oldValue9', newValue: 'newValue9', userId: 'userId9', userName: 'userName9' },
  { timestamp: new Date(), statusKey: 'statusKey10', oldValue: 'oldValue10', newValue: 'newValue10', userId: 'userId10', userName: 'userName10' }
];

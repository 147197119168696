<h3 class="col-md-12">{{ "STATISTICS_TITLE" | translate }}</h3>
<div>
    <div *ngIf="!filterGraphType || filterGraphType.group === 1">
        <div class="row col-md-12">
            <div class="col-md-10">
                <div class="form-inline">
                    <div class="form-group location-select-form mr-2">
                        <mat-form-field
                            class="selector-full-width"
                            appearance="outline"
                        >
                            <mat-select
                                [(ngModel)]="filterGraphType"
                                name="statsType"
                                placeholder="{{
                                    'CHOOSE_GRAPH_TYPE' | translate
                                }}"
                                required
                            >
                                <mat-option [value]="null" disabled>{{
                                    "CHOOSE_GRAPH_TYPE" | translate
                                }}</mat-option>
                                <mat-option
                                    *ngFor="let type of graphTypes"
                                    [value]="type"
                                >
                                    {{ type.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="!filterGraphType"></mat-error>
                        </mat-form-field>
                    </div>
                    <mat-form-field class="mr-2" appearance="outline">
                        <mat-label>{{
                            "REPORT_START_DATE" | translate
                        }}</mat-label>
                        <input
                            matInput
                            [max]="currentDate"
                            [min]="dateLimit"
                            [matDatepicker]="filterStartDatePicker"
                            [(ngModel)]="startDate"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="filterStartDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #filterStartDatePicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="mr-3" appearance="outline">
                        <mat-label>{{
                            "REPORT_END_DATE" | translate
                        }}</mat-label>
                        <input
                            matInput
                            [min]="startDate"
                            [max]="currentDate"
                            [matDatepicker]="filterEndDayPicker"
                            [(ngModel)]="endDate"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="filterEndDayPicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #filterEndDayPicker></mat-datepicker>
                    </mat-form-field>

                    <button
                        [disabled]="!filterGraphType"
                        style="margin-top: -19px"
                        (click)="loadGraph()"
                        type="submit"
                        class="btn btn-primary"
                    >
                        <span class="fa-light fa-magnifying-glass"></span>
                        {{ "SEARCH" | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-2" style="margin-left: 15px">
                <span
                    class="advancedFilterLink"
                    (click)="advancedFilterState = !advancedFilterState"
                    ><small>{{
                        "ADDITIONAL_SETTINGS" | translate
                    }}</small></span
                >
            </div>
        </div>
        <div class="row" [hidden]="!advancedFilterState">
            <div class="col-sm-10">
                <div class="form-inline" *ngIf="advancedFiltersLoaded">
                    <ngx-bootstrap-multiselect
                        class="mr-2"
                        [options]="advancedFilter.device.filterList"
                        [settings]="advancedFilter.settings"
                        [texts]="advancedFilter.device.texts"
                        [(ngModel)]="advancedFilter.device.selectedList"
                        name="advancedFilterDevices"
                    >
                    </ngx-bootstrap-multiselect>

                    <ngx-bootstrap-multiselect
                        class="mr-2"
                        [options]="advancedFilter.location.filterList"
                        [settings]="advancedFilter.settings"
                        [texts]="advancedFilter.location.texts"
                        [(ngModel)]="advancedFilter.location.selectedList"
                        name="advancedFilterLocations"
                    >
                    </ngx-bootstrap-multiselect>

                    <ngx-bootstrap-multiselect
                        class="mr-2"
                        [options]="advancedFilter.alertRoute.filterList"
                        [settings]="advancedFilter.settings"
                        [texts]="advancedFilter.alertRoute.texts"
                        [(ngModel)]="advancedFilter.alertRoute.selectedList"
                        name="advancedFilterAlertRoutes"
                    >
                    </ngx-bootstrap-multiselect>

                    <ngx-bootstrap-multiselect
                        class="mr-2"
                        [options]="advancedFilter.basestation.filterList"
                        [settings]="advancedFilter.settings"
                        [texts]="advancedFilter.basestation.texts"
                        [(ngModel)]="advancedFilter.basestation.selectedList"
                        name="advancedFilterBasestations"
                    >
                    </ngx-bootstrap-multiselect>

                    <ngx-bootstrap-multiselect
                        class="mr-2"
                        [options]="advancedFilter.personnel.filterList"
                        [settings]="advancedFilter.settings"
                        [texts]="advancedFilter.personnel.texts"
                        [(ngModel)]="advancedFilter.personnel.selectedList"
                        name="advancedFilterPersonnel"
                    >
                    </ngx-bootstrap-multiselect>

                    <div
                        class="form-check mr-2"
                        *ngIf="
                            filterGraphType.type === 'horizontalBar' ||
                            filterGraphType.type === 'barEMA'
                        "
                    >
                        <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="advancedFilter.SumAvg"
                            name="filterSum"
                            value="sum"
                        />
                        <label class="form-check-label"
                            >{{ "FILTER_SUM" | translate }}
                        </label>
                    </div>
                    <div
                        class="form-check mr-2"
                        *ngIf="
                            filterGraphType.type === 'horizontalBar' ||
                            filterGraphType.type === 'barEMA'
                        "
                    >
                        <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="advancedFilter.SumAvg"
                            name="filterAverage"
                            value="average"
                        />
                        <label class="form-check-label">{{
                            "FILTER_AVERAGE" | translate
                        }}</label>
                    </div>
                </div>
                <div *ngIf="!advancedFiltersLoaded">
                    <img
                        class="fadeLoader logoSmall"
                        src="assets/img/loading_logo.svg"
                    />
                </div>
            </div>
        </div>
        <hr />
        <div
            class="row"
            *ngIf="
                (dataLoaded !== undefined && !graphData) ||
                (graphData && !dataLoaded)
            "
        >
            <div class="col-md-11">
                <p *ngIf="dataLoaded">{{ "SEARCH_NOT_FOUND" | translate }}</p>
                <div style="text-align: center">
                    <img
                        style="margin-top: 5px"
                        class="fadeLoader logoSmall"
                        src="assets/img/loading_logo.svg"
                        *ngIf="!dataLoaded"
                    />
                </div>
            </div>
        </div>
        <div class="row" [hidden]="!dataLoaded">
            <div class="col-md-11">
                <div class="chartContainer">
                    <canvas id="mainChart"></canvas>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="filterGraphType.group === 2">
        <div class="form-group location-select-form mr-2 col-md-10">
            <mat-form-field class="selector-full-width" appearance="outline">
                <mat-select
                    [(ngModel)]="filterGraphType"
                    name="statsType"
                    required
                >
                    <mat-option [value]="null" disabled>{{
                        "CHOOSE_GRAPH_TYPE" | translate
                    }}</mat-option>
                    <mat-option *ngFor="let type of graphTypes" [value]="type">
                        {{ type.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!filterGraphType"></mat-error>
            </mat-form-field>
        </div>
        <div>
            <div *ngIf="filterGraphType.type === 'alertSummaryData'">
                <div>
                    <app-report-summary
                        [startDate]="startDate"
                        [endDate]="endDate"
                        (startDateChanged)="startDateEventHandler($event)"
                        (endDateChanged)="endDateEventHandler($event)"
                    >
                    </app-report-summary>
                </div>
            </div>
            <div *ngIf="filterGraphType.type === 'emaacStatisticsData'">
                <div>
                    <app-emaac-statistics
                        [startDate]="startDate"
                        [endDate]="endDate"
                        (startDateChanged)="startDateEventHandler($event)"
                        (endDateChanged)="endDateEventHandler($event)"
                    >
                    </app-emaac-statistics>
                </div>
            </div>
            <div *ngIf="filterGraphType.type === 'deviceData'">
                <hr />
                <div>
                    <app-device-report></app-device-report>
                </div>
            </div>
        </div>
    </div>
</div>

<form [formGroup]="locationLockAccessForm" (ngSubmit)="formSubmitSubject.next()" *ngIf="originalLocationsLoaded">
    <div class="form-group">
        <p>{{'LOCK_ACCESS_RIGHT_INFO' |translate}}</p>
    </div>
    <div class="form-group">
        <label><strong>{{'LOCATIONS' |translate}}</strong></label>
        <mat-selection-list #locationLockAccess formControlName="selectedLocationAccess" dense="true">
            <mat-list-option *ngFor="let location of allLocations" [value]="location.id">
                {{location.name}}
            </mat-list-option>
        </mat-selection-list>
    </div>
    <div class="form-group">
        <div *ngIf="saveSuccess" class="alert alert-success mt-2"><span class="fa-light fa-check"></span>
            {{'UPDATE_SUCCESS' |translate}}</div>
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-outline-primary gray-bg mr-1"><span class="fa-light fa-floppy-disk"></span>
            {{'SAVE' |translate}}</button>
    </div>
</form>

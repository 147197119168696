import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services/api.service";
import {
  CrSyncService,
  CrSyncState,
  CrAlertState,
  CrAlertEvent,
  CrAlertEventType,
  CrAlert,
} from "../../services/crsync.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { VideoServerService } from "../../services/videoserver.service";
import { PickUpAlertIcon } from "app/pipes/pipes";
import { NgIf } from "@angular/common";
import { ControlRoomAlertPanelForwardedComponent } from "./control-room-alert-panel-forwarded/control-room-alert-panel-forwarded.component";
import { ControlRoomAlertPanelStartedComponent } from "./control-room-alert-panel-started/control-room-alert-panel-started.component";
import { ControlRoomAlertPanelIncomingComponent } from "./control-room-alert-panel-incoming/control-room-alert-panel-incoming.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ControlRoomService } from "app/services/control-room.service";
import { NotificationService } from "app/services/notification.service";

@Component({
  selector: "control-room",
  templateUrl: "./control-room.component.html",
  styleUrls: ["./control-room.component.css"],
  standalone: true,
  imports: [
    MatSlideToggleModule,
    ControlRoomAlertPanelIncomingComponent,
    ControlRoomAlertPanelStartedComponent,
    ControlRoomAlertPanelForwardedComponent,
    NgIf,
    TranslateModule,
    PickUpAlertIcon,
  ],
})
export class ControlRoomComponent implements OnInit {
  constructor(
    private crSync: CrSyncService,
    private crService: ControlRoomService,
    private api: ApiService,
    private videoServerService: VideoServerService,
    private translate: TranslateService,
    private router: Router,
    private notification: NotificationService
  ) {}

  dragStartElement: string;

  syncStatusOnline: boolean = false;
  videoServerOnline: boolean = false;

  showTeamAlerts: boolean = false;

  notifyAlert(alert: CrAlert) {
    this.notification.notifyNewAlert(alert.header);
  }

  ngOnInit() {
    if (this.crSync.state == CrSyncState.Init) {
      // initialise crsync if login not performed (e.g. development
      // server reload or user performing page refresh)
      this.crSync.connect();
    }

    //Start tracking active visits
    this.videoServerService.combinedVisits$.subscribe((data) => {
      this.crService.updateCameraVisits(data);
    });

    // subscribe and handle crsync events
    this.crSync.activeAlerts.subscribe((event: CrAlertEvent) => {
      if (event.type === CrAlertEventType.UpdateAllAlerts && event.alertList) {
        this.crService.updateAllAlerts(event.alertList);
      } else if (
        event.type === CrAlertEventType.UpdateOneAlert &&
        event.alertList &&
        event.alertList.length > 0
      ) {
        this.crService.updateOneAlert(event.alertList[0]);
      } else if (
        event.type === CrAlertEventType.DeleteOneAlert &&
        event.alertId
      ) {
        this.crService.deleteOneAlert(event.alertId);
      }
    });
    this.crSync.forwardedAlerts.subscribe((event: CrAlertEvent) => {
      if (event.type === CrAlertEventType.UpdateAllAlerts && event.alertList) {
        this.crService.updateAllForwardedAlerts(event.alertList);
      } else if (
        event.type === CrAlertEventType.UpdateOneAlert &&
        event.alertList &&
        event.alertList.length > 0
      ) {
        this.crService.updateOneForwardedAlert(event.alertList[0]);
      } else if (
        event.type === CrAlertEventType.DeleteOneAlert &&
        event.alertId
      ) {
        this.crService.deleteOneForwardedAlert(event.alertId);
      }
    });
    this.crSync.isOnline.subscribe((isOnline: boolean) => {
      this.syncStatusOnline = isOnline;
    });
    this.videoServerService.isConnected.subscribe((isConnected: boolean) => {
      this.videoServerOnline = isConnected;
    });
    this.crSync.shouldExit.subscribe(() => {
      this.crSync.disconnect();
      this.api.logout().subscribe((_response) => {
        this.router.navigate(["login"], {
          queryParams: { reason: "login_elsewhere" },
        });
      });
    });

    // if notifications have not been granted/denied yet, request permission
    if ("Notification" in window && Notification.permission === "default")
      Notification.requestPermission();
  }

  getUserId() {
    return this.crSync.userId;
  }

  getAlertState(state: CrAlertState): string {
    switch (state) {
      case CrAlertState.Available:
        return this.translate.instant("EMA_STATE_AVAILABLE");
      case CrAlertState.Reserved:
        return this.translate.instant("EMA_STATE_RESERVED");
      case CrAlertState.Started:
        return this.translate.instant("EMA_STATE_STARTED");
      default:
        break;
    }
  }
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ReportPipesModule } from "app/components/report/report-pipes";
import { CameraEvent } from 'app/models/report.model';
import { MomentPipe, TransformToLangCodePipe } from 'app/pipes/pipes';


@Component({
  selector: 'app-report-camera-event-table',
  templateUrl: './report-camera-event-table.component.html',
  styleUrls: ['../../report.component.css'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    InfiniteScrollModule,
    ReportPipesModule,
    MomentPipe,
    TransformToLangCodePipe
  ]
})
export class ReportCameraEventTableComponent {

  constructor() { }

  @Input() cameraEvents: CameraEvent[];
  @Input() infiniteScrollDisabled: boolean;

  @Output() loadNextEvents = new EventEmitter<void>();

  expandRow(cameraEvent: CameraEvent): void {
    cameraEvent.expanded = !cameraEvent.expanded ?? true;
  }
}

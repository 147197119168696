import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NewResidentsComponent } from '../new-residents/new-residents.component';
import { FlaggedResidentsComponent } from '../flagged-residents/flagged-residents.component';
import { AllResidentsComponent } from '../all-residents/all-residents.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AngularMaterialModules } from 'app/angular-material-modules';

@Component({
  selector: 'app-residents',
  templateUrl: './residents-on-landing-page.component.html',
  styleUrls: ['./residents-on-landing-page.component.css'],
  standalone: true,
  imports: [NgIf, MatTabsModule, TranslateModule, NewResidentsComponent, FlaggedResidentsComponent, AllResidentsComponent, AngularMaterialModules]

})
export class ResidentsLandingPageComponent implements OnInit {

  constructor() { }

  // selectedIndex: number = 0;
  user: any;
  customerSelectionData: any;

  ngOnInit(): void {
    this.customerSelectionData = JSON.parse(
      localStorage.getItem("landing-page")
    );
    this.user = this.customerSelectionData.user;
  }

}

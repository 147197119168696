<div>
    <div id="time-filters">
        <mat-form-field class="mr-2" appearance="outline">
            <mat-label>{{'REPORT_START_DATE' |translate}}</mat-label>
            <input placeholder="{{'REPORT_START_DATE' |translate}}" matInput [max]="currentDate" [min]="dateLimit"
                [matDatepicker]="filterStartDatePicker" [(ngModel)]="startDate"
                (dateChange)="onStartDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="filterStartDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #filterStartDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="mr-2" appearance="outline">
            <mat-label>{{'REPORT_END_DATE' |translate}}</mat-label>
            <input placeholder="{{'REPORT_END_DATE' |translate}}" matInput [min]="startDate" [max]="currentDate"
                [matDatepicker]="filterEndDayPicker" [(ngModel)]="endDate" (dateChange)="onEndDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="filterEndDayPicker"></mat-datepicker-toggle>
            <mat-datepicker #filterEndDayPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="selector-full-width" appearance="outline">
            <mat-select [formControl]="selectedAlertRoutes" placeholder="{{'ALARM_ROUTE' |translate}}" multiple>
                <mat-option *ngFor="let alertRoute of alertRoutes" [value]="alertRoute.id">
                    {{alertRoute.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="!alertRoutes"></mat-error>
        </mat-form-field>
        <button
            type="submit"
            (click)="loadStatistics()"
            style="position: relative; top: -6px; margin-left: 15px;"
            class="btn btn-primary">
            <span class="fal fa-search"></span>
            {{'SEARCH' |translate}}
        </button>
    </div>
    <hr>
    <div *ngIf="pageViewInitialized">
        <div *ngIf="dataLoading">
            <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg">
        </div>

        <div *ngIf="!dataLoading && noDataFound">
            <p>{{'SEARCH_NOT_FOUND' |translate}}</p>
        </div>

        <div id="graph-content" [hidden]="dataLoading || noDataFound" style="margin-left: 5px;">
            <div id="table" class="card" style="width: 68%; height: fit-content; float:left;">

                <div class="card-header-blue" style="padding-left: 20px; padding-top: 10px; height: 40px">
                    <h6 class="blue-text">{{'RESPONSE_TIMES' |translate}}</h6>
                </div>

                <table class="card-body-white" mat-table [dataSource]="tableData" style="width: 95%; margin: auto;" matSort>

                    <!-- Timestamp Column -->
                    <ng-container matColumnDef="timestamp">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ALARM_REGISTERED' |translate}}</th>
                        <td mat-cell *matCellDef="let e">{{e.timestamp |moment:'l LTS'}}</td>
                    </ng-container>

                    <!-- Device Name Column -->
                    <ng-container matColumnDef="deviceName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'DEVICE_NAME' |translate}}</th>
                        <td mat-cell *matCellDef="let e">{{e.deviceName}}</td>
                    </ng-container>

                    <!-- Device type Column -->
                    <ng-container matColumnDef="deviceType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'DEVICE_TYPE' |translate}}</th>
                        <td mat-cell *matCellDef="let e">{{e.deviceType}}</td>
                    </ng-container>

                    <!-- Serial number Column -->
                    <ng-container matColumnDef="deviceId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SERIAL_NUMBER' |translate}}</th>
                        <td mat-cell *matCellDef="let e">{{e.deviceId}}</td>
                    </ng-container>

                    <ng-container matColumnDef="responseTime">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'RESPONSE_TIME_TEXT' |translate}}</th>
                        <td mat-cell *matCellDef="let e">{{e.formattedResponseTime}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>

            <div id="emaac-statistics" style="width: 29%; float: right; top: 10px; margin-right: 5px;">
                <div class="card">
                    <div class="card-header-blue" style="padding-left: 20px; padding-top: 10px; height: 40px">
                        <h6 class="blue-text">{{'AVERAGE_RESPONSE_TIME' |translate}}</h6>
                    </div>
                    <div class="card-body-white" style="height: 115px;">
                        <div style="margin-top: 15px">
                            <label>
                                <input type="radio" [checked]="meanRadioValue" (click)="onRadioClick('mean')" />
                                {{'MEAN' |translate}}
                            </label>
                            <label style="margin-left: 10px;">
                                <input type="radio" [checked]="medianRadioValue" (click)="onRadioClick('median')" />
                                {{'MEDIAN' |translate}}
                            </label>
                        </div>
                        <h3 style="text-align: center; margin-top: 10px; font-size: 30px;">{{averageOrMeanResTime}}</h3>
                    </div>
                </div>

                <div class="card" style="margin-top: 15px;">
                    <div class="card-header-blue" style="padding-left: 20px; padding-top: 10px; height: 40px">
                        <h6 class="blue-text">{{'INTERVAL' |translate}}</h6>
                    </div>
                    <div class="card-body-white" style="height: 90px; padding-top: 10px;">
                        <div style="display: flex; justify-content: center; margin-top: 5px;">
                            <div style="float:left; margin-right: 65px;">
                                <h4 style="text-align: center; font-size: 23px;">{{fastestResponseTime}}</h4>
                                <p class="blue-text" style="text-align: center;">{{'SHORTEST' |translate}}</p>
                            </div>
                            <div style="float:left;">
                                <h4 style="text-align: center; font-size: 23px;">{{longestResponseTime}}</h4>
                                <p class="blue-text" style="text-align: center;">{{'LONGEST' |translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </div>

        <!--
    <div class="mat-grid-card-dark" style="width: 42%; margin: auto; margin-bottom: 9px;">
        <div class="mat-grid-card-container">
            <mat-grid-list class="mat-grid-card-header" cols="2" rowHeight="30px">
                <mat-grid-tile id="emaac">
                    <h6>EMAAC:</h6>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list class="mat-grid-card-content" *ngIf="dataLoading" cols="1" rowHeight="450px">
                <mat-grid-tile>
                    <img style="margin-top:5px;" class="fadeLoader" src="assets/img/loading_logo_small.png">
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list class="mat-grid-card-content" *ngIf="!dataLoading && missingData" cols="1" rowHeight="450px">
                <mat-grid-tile>
                    <p>{{'SEARCH_NOT_FOUND' |translate}}</p>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list class="mat-grid-card-content" cols="2" rowHeight="150px" *ngIf="!dataLoading && !missingData">
                <mat-grid-tile>
                    <mat-card-content>
                        <h6 style="color: rgb(131, 133, 134); text-align: center;">{{'CONNECTED_BASESTATIONS'
                            |translate}}</h6>
                        <h2 style="text-align: center;">{{connectedBasestations}}</h2>
                    </mat-card-content>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-card-content>
                        <h6 style="color: rgb(131, 133, 134); text-align: center;">{{'RESPONSE_TIME' |translate}}</h6>
                        <div>
                            <label>
                                <input type="radio" [checked]="meanRadioValue" (click)="onRadioClick('mean')" />
                                {{'MEAN' |translate}}
                            </label>
                            <label style="margin-left: 10px;">
                                <input type="radio" [checked]="medianRadioValue" (click)="onRadioClick('median')" />
                                {{'MEDIAN' |translate}}
                            </label>
                        </div>
                        <h2 style="text-align: center;">{{emaacStatistics.shownResponseValue}} {{'SEC' |translate}}</h2>
                    </mat-card-content>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-card-content>
                        <h6 style="color: rgb(131, 133, 134); text-align: center;">{{'FASTEST_RESPONSE_TIME'
                            |translate}}
                        </h6>
                        <h2 style="text-align: center;">{{emaacStatistics.fastestResponseTime}} {{'SEC' |translate}}
                        </h2>
                    </mat-card-content>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-card-content>
                        <h6 style="color: rgb(131, 133, 134); text-align: center;">{{'LONGEST_RESPONSE_TIME'
                            |translate}}
                        </h6>
                        <h2 style="text-align: center;">{{emaacStatistics.longestResponseTime}} {{'SEC' |translate}}
                        </h2>
                    </mat-card-content>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-card-content>
                        <h6 style="color: rgb(131, 133, 134); text-align: center;">{{'FINISHED_ALERTS' |translate}}</h6>
                        <h2 style="text-align: center;">{{emaacStatistics.finishedAlerts}}</h2>
                    </mat-card-content>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-card-content>
                        <h6 style="color: rgb(131, 133, 134); text-align: center;">{{'FORWARDED_ALERTS' |translate}}
                        </h6>
                        <h2 style="text-align: center;">{{emaacStatistics.forwardedAlerts}}</h2>
                    </mat-card-content>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
    -->
    </div>
</div>

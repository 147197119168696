export const ALL_TABS_NORMAL_USER: INavTabItem[] = [
  {
    path: "/dashboard",
    name: "NAV_DASHBOARD",
    fa_icon: "fa-duotone fa-house fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/device",
    name: "NAV_DEVICE_MANAGEMENT",
    fa_icon: "fa-duotone fa-phone-laptop fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/staff",
    name: "STAFF_MEMBERS",
    fa_icon: "fa-duotone fa-user fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/teams",
    name: "RECEIVER_TEAMS",
    fa_icon: "fa-duotone fa-users fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/mobile-whitelist",
    name: "MOBILE_WHITELIST",
    fa_icon: "fa-duotone fa-mobile-android fa-fw",
    userCondition: "EmaPhoneWhitelist",
    deviceCondition: true,
  },
  {
    path: "/route",
    name: "ALARM_ROUTES",
    fa_icon: "fa-duotone fa-bells fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/reports",
    name: "NAV_REPORT",
    fa_icon: "fa-duotone fa-list fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/reports/statistics",
    name: "NAV_STATISTICS",
    fa_icon: "fa-duotone fa-chart-bar fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/reports/activity",
    name: "NAV_ACTIVITY",
    fa_icon: "fa-duotone fa-monitor-heart-rate fa-fw",
    userCondition: true,
    deviceCondition: "DeviceMovement",
  },
  {
    path: "/anna-perenna",
    name: "AnnaPerenna",
    fa_icon: "fa-light fa-camera fa-fw",
    userCondition: true,
    deviceCondition: "AnnaPerenna",
  },
  {
    path: "/onon",
    name: "OnOn",
    fa_icon: "fa-duotone fa-webcam fa-fw",
    userCondition: true,
    deviceCondition: "OnOn",
  },
  {
    path: "/camera-supervision",
    name: "CameraSupervision",
    fa_icon: "fa-duotone fa-cctv fa-fw",
    userCondition: true,
    deviceCondition: "CameraSupervision",
  },
];

export const ALL_NAV_TABS_CUSTOMER_ADMIN: INavTabItem[] = [
  {
    path: "/dashboard",
    name: "NAV_DASHBOARD",
    fa_icon: "fa-duotone fa-house fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/device",
    name: "NAV_DEVICE_MANAGEMENT",
    fa_icon: "fa-duotone fa-phone-laptop fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/staff",
    name: "STAFF_MEMBERS",
    fa_icon: "fa-duotone fa-user fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/teams",
    name: "RECEIVER_TEAMS",
    fa_icon: "fa-duotone fa-users fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/mobile-whitelist",
    name: "MOBILE_WHITELIST",
    fa_icon: "fa-duotone fa-mobile-android fa-fw",
    userCondition: "EmaPhoneWhitelist",
    deviceCondition: true,
  },
  {
    path: "/route",
    name: "ALARM_ROUTES",
    fa_icon: "fa-duotone fa-bells fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/reports",
    name: "NAV_REPORT",
    fa_icon: "fa-duotone fa-list fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/reports/statistics",
    name: "NAV_STATISTICS",
    fa_icon: "fa-duotone fa-chart-bar fa-fw",
    userCondition: true,
    deviceCondition: true,
  },
  {
    path: "/reports/activity",
    name: "NAV_ACTIVITY",
    fa_icon: "fa-duotone fa-monitor-heart-rate fa-fw",
    userCondition: true,
    deviceCondition: "DeviceMovement",
  },
  {
    path: "/anna-perenna",
    name: "AnnaPerenna",
    fa_icon: "fa-light fa-camera fa-fw",
    userCondition: true,
    deviceCondition: "AnnaPerenna",
  },
  {
    path: "/onon",
    name: "OnOn",
    fa_icon: "fa-duotone fa-webcam fa-fw",
    userCondition: true,
    deviceCondition: "OnOn",
  },
  {
    path: "/camera-supervision",
    name: "CameraSupervision",
    fa_icon: "fa-duotone fa-cctv fa-fw",
    userCondition: true,
    deviceCondition: "CameraSupervision",
  },
/*
  {
    path: "/camera-configuration",
    name: "CAMERA_GROUPS",
    fa_icon: "", // TODO: Add icon
    userCondition: true,
    deviceCondition: "CameraSupervision"
  },
   */
  {
    path: "/activity-log",
    name: "ACTIVITY_LOG",
    fa_icon: "fa-duotone fa-user-pen fa-fw",
    userCondition: true,
    deviceCondition: true,
  }
]

export const NAV_TABS_ADMIN: INavTabItem[] = [
  {
    path: "/dashboard/admin",
    name: "NAV_DASHBOARD",
    fa_icon: "fa-duotone fa-house fa-fw",
  },
  {
    path: "/system/firmware",
    name: "FIRMWARES",
    fa_icon: "fa-duotone fa-archive fa-fw",
  },
  {
    path: "/system/users",
    name: "USERS",
    fa_icon: "fa-duotone fa-users fa-fw",
  },
  {
    path: "/system/company-management",
    name: "COMPANY_MANAGEMENT",
    fa_icon: "fa-duotone fa-building fa-fw",
  },
  {
    path: "/system/company-groups",
    name: "COMPANY_GROUPS",
    fa_icon: "fa-duotone fa-buildings fa-fw",
  },
  {
    path: "/alarm-reasons",
    name: "ALARM_REASONS",
    fa_icon: "fa-duotone fa-bell-on fa-fw",
  },
];

export const NAV_TABS_LANDING_PAGE: INavTabItem[] = [
  {
    path: "/landing-page/residents",
    name: "RESIDENTS",
    fa_icon: "fa-duotone fa-user fa-fw",
  },
  {
    path: "/landing-page/devices",
    name: "DEVICES",
    fa_icon: "fa-duotone fa-phone-laptop fa-fw",
  },
  {
    path: "/landing-page/staff",
    name: "STAFF",
    fa_icon: "fa-duotone fa-user fa-fw",
  },
];

export interface INavTabItem {
  path: string;
  name: string;
  fa_icon: string;
  userCondition?: boolean | string;
  deviceCondition?: boolean | string;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VideoServerCamera } from 'app/models/camera.model';
import { Device } from 'app/models/device.model';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IGroupedOption, MultiselectDropdownComponent } from 'app/components/multiselect-dropdown/multiselect-dropdown.component';
import { BehaviorSubject } from 'rxjs';

interface ISelectedLocation { id: string, name: string };

@Component({
  selector: 'app-select-cameras',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MultiselectDropdownComponent
  ],
  templateUrl: './select-cameras.component.html',
  styleUrls: ['./select-cameras.component.css']
})
export class SelectCamerasComponent {
  @Input() cameras: VideoServerCamera[];
  @Input() camerasOfLocation: IGroupedOption[];
  @Input() cameraList: Device[];
  @Input() selectedLocation: ISelectedLocation;
  @Input() disabled: BehaviorSubject<boolean>;

  @Output() cameraSelectChange = new EventEmitter<VideoServerCamera[]>();

  selectedCameraCheckboxes: string[] = [];

  toggleMotionDetection(event: any, camera: VideoServerCamera): void {
    const isEnabled = event.target.checked;
    camera.motionDetection.enabled = isEnabled;

    // Clear the sub-options if the main option is disabled
    if (!isEnabled) {
      camera.motionDetection.snapshot = false;
      camera.motionDetection.videoclip = false;
    }
  }

  toggleAnonymizedVisit(event: any, camera: VideoServerCamera): void {
    const isEnabled = event.target.checked;
    camera.anonymization = isEnabled;

    if (!isEnabled) {
      camera.toggleAnonymization = false;
    }
  }

  onCameraSelectedChange(cameraIds: string[]): void {
    const cameras: VideoServerCamera[] = [];

    for (const cameraId of cameraIds) {
      const cameraName = this.cameraList.find(x => x.id === cameraId)?.name || "";

      const camera = this.createVideoServerCamera(cameraId, cameraName);
      cameras.push(camera);
    }
    this.cameraSelectChange.emit(cameras);
  }

  createVideoServerCamera(cameraId: string, cameraName: string): VideoServerCamera {
    return {
      deviceId: "",
      displayName: cameraName,
      discoveryName: cameraId,
      anonymization: false,
      toggleAnonymization: false,
      motionDetection: {
        enabled: false,
        snapshot: false,
        videoclip: false,
      },
    }
  }

  ngOnChanges() {
    const selectedCameraCheckboxes = [];

    // Set checkboxes checked based on the cameras that are already included in the group
    for (const camera of this.cameras) {
      selectedCameraCheckboxes.push(camera.discoveryName);
    }

    this.selectedCameraCheckboxes = [...selectedCameraCheckboxes];
  }
}

<div class="row">
    <div class="col-md-12 eventPanel" *ngIf="displayEvents && selectedDisplay">
        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-success" *ngIf="infoText">
                    {{ infoText }}
                    <span
                        class="float-right fal fa-times"
                        (click)="closeInfoBox()"
                        style="cursor: pointer"
                    ></span>
                </div>
            </div>
        </div>
        <div
            class="row"
            *ngIf="checkDisplaySwVersion(selectedDisplay.swVersion)"
        >
            <div class="col-md-12">
                <form
                    #dispParamForm="ngForm"
                    (ngSubmit)="saveDisplayParameters()"
                >
                    <h5>{{ "QUIET_HOURS" | translate }}</h5>
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label>{{ "START" | translate }}</label>
                            <input
                                type="text"
                                class="form-control"
                                [(ngModel)]="selectedDisplay.silentHoursStart"
                                name="silentHoursStart"
                                #silentHoursStart="ngModel"
                                required
                                pattern="^[0-9]{2}:[0-9]{2}:[0-9]{2}$"
                            />
                        </div>
                        <div class="form-group col-md-3">
                            <label>{{ "END" | translate }}</label>
                            <input
                                type="text"
                                class="form-control"
                                [(ngModel)]="selectedDisplay.silentHoursEnd"
                                name="silentHoursEnd"
                                #silentHoursEnd="ngModel"
                                required
                                pattern="^[0-9]{2}:[0-9]{2}:[0-9]{2}$"
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <button
                            type="submit"
                            [disabled]="
                                !dispParamForm.dirty || !dispParamForm.valid
                            "
                            class="btn btn-outline-primary"
                        >
                            <span class="fal fa-save"></span>
                            {{ "SAVE" | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h5>{{ "ACTIVATIONS" | translate }}</h5>
                <hr />
                <p style="margin-bottom: 20px" *ngIf="eventsLoaded">
                    <button
                        class="btn btn-outline-primary"
                        (click)="addNewEvent()"
                        [disabled]="unfinishedEvent"
                    >
                        <span class="fal fa-plus"></span>
                        {{ "NEW_ACTIVATION" | translate }}
                    </button>
                    <button
                        *ngIf="massEditMade"
                        class="btn btn-outline-primary"
                        (click)="saveAllEvents()"
                    >
                        <span class="fal fa-save"></span>
                        {{ "SAVE_ALL_EVENTS" | translate }}
                    </button>
                </p>
                <div *ngIf="!eventsLoaded || massEditFinished">
                    <img
                        style="margin-top: 5px"
                        class="fadeLoader centerLoader logoSmall"
                        src="assets/img/loading_logo.svg"
                    />
                </div>
                <div *ngIf="!massEditFinished">
                    <div
                        *ngFor="let event of displayEvents; let i = index"
                        class="card"
                        [ngClass]="event.isNew ? 'unfinishedEvent' : ''"
                    >
                        <div
                            class="eventHeader card-header"
                            (click)="event.collapseState = !event.collapseState"
                            style="cursor: pointer"
                        >
                            <span *ngIf="event.addEventDevName"
                                >{{ event.addEventDevName }} ({{
                                    event.addEventDevID
                                }}), </span
                            ><span
                                *ngIf="
                                    !event.addEventDevName &&
                                    event.addEventDevID
                                "
                                >{{ event.addEventDevID }},
                            </span>
                            <strong>{{ event.activationName }}</strong>
                        </div>
                        <div
                            *ngIf="event.collapseState"
                            class="card-body"
                            [@animationCollapse]
                        >
                            <form>
                                <div *ngIf="event.isNew" class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{
                                                "ACTIVATE_DEVICE" | translate
                                            }}</label>
                                            <ngx-bootstrap-multiselect
                                                [texts]="multiselect.textDev"
                                                [options]="
                                                    multiselect.deviceList
                                                "
                                                [settings]="
                                                    multiselect.settingsDev
                                                "
                                                [(ngModel)]="
                                                    event.addEventDevIDArray
                                                "
                                                (ngModelChange)="
                                                    eventAddDevChange(
                                                        $event,
                                                        event
                                                    )
                                                "
                                                name="activationDevices"
                                            ></ngx-bootstrap-multiselect>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{
                                                "ACTIVATION" | translate
                                            }}</label>
                                            <ngx-bootstrap-multiselect
                                                [disabled]="
                                                    !event.addEventDevIDArray
                                                "
                                                [texts]="multiselect.textAct"
                                                [options]="
                                                    event.activationListAdd
                                                "
                                                [settings]="
                                                    multiselect.settingsAct
                                                "
                                                [(ngModel)]="
                                                    event.addEventActivationArray
                                                "
                                                name="activationGroupNode"
                                            ></ngx-bootstrap-multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            {{
                                                "CONFIRMATION_DEVICES"
                                                    | translate
                                            }}
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <ngx-bootstrap-multiselect
                                                        [texts]="
                                                            multiselect.textDev
                                                        "
                                                        [options]="
                                                            multiselect.deviceList
                                                        "
                                                        [settings]="
                                                            multiselect.settingsDev
                                                        "
                                                        [(ngModel)]="
                                                            event.removeEventDeviceArray
                                                        "
                                                        (ngModelChange)="
                                                            eventRemoveDevChange(
                                                                $event,
                                                                event
                                                            )
                                                        "
                                                        name="removeDevice"
                                                    ></ngx-bootstrap-multiselect>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <ngx-bootstrap-multiselect
                                                        [disabled]="
                                                            !event.removeEventDeviceArray
                                                        "
                                                        [texts]="
                                                            multiselect.textAct
                                                        "
                                                        [options]="
                                                            event.activationListRemove
                                                        "
                                                        [settings]="
                                                            multiselect.settingsAct
                                                        "
                                                        [(ngModel)]="
                                                            event.removeEventActivationArray
                                                        "
                                                        name="removeActivation"
                                                    ></ngx-bootstrap-multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <mat-slide-toggle
                                                name="confirmationCancel"
                                                [(ngModel)]="event.cancelAlarms"
                                                >{{
                                                    "CANCEL_EMA" | translate
                                                }}</mat-slide-toggle
                                            >
                                        </div>
                                        <div class="form-group">
                                            <button
                                                [disabled]="
                                                    !(
                                                        event
                                                            .removeEventActivationArray
                                                            ?.length > 0
                                                    )
                                                "
                                                class="btn btn-outline-primary fullWidth"
                                                (click)="
                                                    addNewRemoveDevice(event)
                                                "
                                            >
                                                <span
                                                    class="fal fa-plus"
                                                ></span>
                                                {{
                                                    "ADD_CONFIRMATION_DEVICE"
                                                        | translate
                                                }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    *ngIf="event.removeEventDevices.length > 0"
                                >
                                    <div class="col-md-12">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        {{
                                                            "DEVICE" | translate
                                                        }}
                                                    </th>
                                                    <th>
                                                        {{
                                                            "ACTIVATION"
                                                                | translate
                                                        }}
                                                    </th>
                                                    <th>
                                                        {{
                                                            "CANCEL_EMA"
                                                                | translate
                                                        }}
                                                    </th>
                                                    <th>
                                                        {{
                                                            "DELETE" | translate
                                                        }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="
                                                        let removeDevice of event.removeEventDevices;
                                                        let f = first;
                                                        let i = index
                                                    "
                                                >
                                                    <td>
                                                        {{
                                                            removeDevice.removeEventDevName
                                                                ? removeDevice.removeEventDevName +
                                                                  " (" +
                                                                  removeDevice.removeEventDevID +
                                                                  ")"
                                                                : removeDevice.removeEventDevID
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getActivationName(
                                                                removeDevice.removeEventGroup,
                                                                removeDevice.removeEventNode,
                                                                removeDevice.removeEventDevType
                                                            )
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            removeDevice.cancelAlarms.toString()
                                                                | translate
                                                        }}
                                                    </td>
                                                    <td>
                                                        <span
                                                            class="fal fa-trash-alt fa-lg eventRemoveDevice"
                                                            (click)="
                                                                removeEventRemoveDevice(
                                                                    removeDevice,
                                                                    event
                                                                )
                                                            "
                                                        ></span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label>{{
                                                "MESSAGE_FORMAT" | translate
                                            }}</label>
                                            <input
                                                type="text "
                                                class="form-control"
                                                [(ngModel)]="event.msgTemplate"
                                                name="msgTemplate "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>{{
                                                "CLEAR_DISPLAY_AUTO" | translate
                                            }}</label>
                                            <input
                                                type="text "
                                                class="form-control"
                                                style="width: 50px"
                                                [(ngModel)]="
                                                    event.autoRemoveTime
                                                "
                                                name="autoRemoveTime "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="
                                        checkDisplaySwVersion(
                                            selectedDisplay.swVersion
                                        )
                                    "
                                >
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <label>{{
                                                    "BACKGROUND_COLOR"
                                                        | translate
                                                }}</label>
                                                <div
                                                    class="displayEventColorSelect"
                                                    [(colorPicker)]="
                                                        event.backgroundColor
                                                    "
                                                    [cpPosition]="'bottom'"
                                                    [style.background-color]="
                                                        event.backgroundColor
                                                    "
                                                    [cpPositionOffset]="'50%'"
                                                    [cpPositionRelativeToArrow]="
                                                        true
                                                    "
                                                ></div>
                                                <input
                                                    hidden
                                                    type="text"
                                                    [(ngModel)]="
                                                        event.backgroundColor
                                                    "
                                                    name="backgroundColor"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <label>{{
                                                    "FOREGROUND_COLOR"
                                                        | translate
                                                }}</label>
                                                <div
                                                    class="displayEventColorSelect"
                                                    [(colorPicker)]="
                                                        event.foregroundColor
                                                    "
                                                    [cpPosition]="'bottom'"
                                                    [style.background-color]="
                                                        event.foregroundColor
                                                    "
                                                    [cpPositionOffset]="'50%'"
                                                    [cpPositionRelativeToArrow]="
                                                        true
                                                    "
                                                ></div>
                                                <input
                                                    hidden
                                                    type="text"
                                                    [(ngModel)]="
                                                        event.foregroundColor
                                                    "
                                                    name="foregroundColor"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <label>{{
                                                            "SOUND" | translate
                                                        }}</label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <ul
                                                            class="displaySoundList"
                                                        >
                                                            <li
                                                                *ngFor="
                                                                    let sound of soundList
                                                                "
                                                            >
                                                                <div
                                                                    class="displaySoundNo"
                                                                    *ngIf="
                                                                        sound.id ===
                                                                        0
                                                                    "
                                                                    [ngClass]="
                                                                        event.soundID ===
                                                                        sound.id
                                                                            ? 'displaySoundSelected'
                                                                            : ''
                                                                    "
                                                                    (click)="
                                                                        setSelectedSound(
                                                                            sound,
                                                                            event
                                                                        )
                                                                    "
                                                                >
                                                                    <span
                                                                        class="fal fa-lg fa-ban soundIcon"
                                                                    ></span>
                                                                    {{
                                                                        "NO_SOUND"
                                                                            | translate
                                                                    }}
                                                                </div>
                                                                <div
                                                                    class="displaySound"
                                                                    *ngIf="
                                                                        sound.id !==
                                                                        0
                                                                    "
                                                                    [ngClass]="
                                                                        event.soundID ==
                                                                        sound.id
                                                                            ? 'displaySoundSelected'
                                                                            : ''
                                                                    "
                                                                    (click)="
                                                                        setSelectedSound(
                                                                            sound,
                                                                            event
                                                                        )
                                                                    "
                                                                >
                                                                    <span
                                                                        class="fal fa-lg fa-play-circle soundIcon"
                                                                        (click)="
                                                                            playSound(
                                                                                sound,
                                                                                event
                                                                            )
                                                                        "
                                                                    ></span>
                                                                    {{
                                                                        "SOUND"
                                                                            | translate
                                                                    }}
                                                                    {{
                                                                        sound.id
                                                                    }}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <audio
                                                            id="displaySound"
                                                        ></audio>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label>{{
                                                "BLINKING_TEXT" | translate
                                            }}</label>
                                            <section>
                                                <mat-slide-toggle
                                                    [(ngModel)]="
                                                        event.blinkEnabled
                                                    "
                                                    name="blinkEnabled"
                                                >
                                                </mat-slide-toggle>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 20px">
                                    <div class="col-md-12">
                                        <button
                                            appThrottleClick
                                            (throttledClick)="saveEvent(event)"
                                            class="btn btn-outline-primary mr-2"
                                        >
                                            <span class="fal fa-save"></span>
                                            {{ "SAVE" | translate }}
                                        </button>
                                        <button
                                            class="btn btn-outline-danger"
                                            (click)="removeEvent(event)"
                                        >
                                            <span class="fal fa-times"></span>
                                            {{ "DELETE" | translate }}
                                        </button>
                                    </div>
                                </div>
                                <div class="float-right copyEvent">
                                    <div class="text-right">
                                        <span
                                            class="fal fa-fw fa-lg fa-copy cursorPointer"
                                        ></span>
                                    </div>
                                    <div class="copySelect pb-3">
                                        <div
                                            class="cursorPointer"
                                            (click)="copyDeviceType(event)"
                                        >
                                            {{ "COPY_TO_DEVICES" | translate }}
                                        </div>
                                        <div
                                            class="cursorPointer"
                                            (click)="copySettings(event)"
                                        >
                                            {{ "COPY_SETTINGS" | translate }}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

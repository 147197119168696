import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ApiService } from "app/services/api.service";
import { Subject } from "rxjs";
import { throttleTime } from "rxjs/operators";
import { ITimezone, TIMEZONES } from "../../timezones";
import { AddCompanyForm } from "app/models/company.models";
import { TranslateModule } from "@ngx-translate/core";
import { UsernameValidatorDirective } from "../../../../directives/username-validator.directive";
import { NgClass, NgIf, NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    selector: "company-add",
    templateUrl: "./company-add.component.html",
    styleUrls: ["./company-add.component.css"],
    standalone: true,
    imports: [FormsModule, NgClass, NgIf, NgFor, UsernameValidatorDirective, TranslateModule]
})
export class AddCompanyComponent implements OnInit, OnDestroy {
  constructor(private api: ApiService) {}

  @Input() showForm: BehaviorSubject<boolean>;

  @Input() successMsg: BehaviorSubject<string>;
  @Input() failedMsg: BehaviorSubject<string>;

  @Output() companyAdded: EventEmitter<boolean> = new EventEmitter();

  form: AddCompanyForm;
  formSubmitSubject = new Subject();

  companyGroupList: { name: string; companyGroupId: string; server: string }[];
  serverList: string[];
  serverSelectionDisabled: boolean = false;
  timezones: ITimezone[] = TIMEZONES;

  onCompanyGroupSelected() {
    const selectedGroupId = this.form.companyGroupId;
    if (selectedGroupId) {
      this.form.server = this.companyGroupList.find(
        (x) => x.companyGroupId === selectedGroupId
      ).server;
      this.serverSelectionDisabled = true;
    } else {
      this.form.server = "";
      this.serverSelectionDisabled = false;
    }
  }

  passwordMatch(password: string, confirmPassword: string): boolean {
    if (!password || !confirmPassword) {
      return false;
    }
    if (password !== confirmPassword) {
      return false;
    }
    return true;
  }

  // Set timezone and return the name of the timezone
  setTimezone(country: string): string {
    const data = this.timezones.find((x) => x.country.code === country);
    if (data) {
      this.form.timezone = data.timezone;
      return data.name;
    }
    return "";
  }

  onSubmit(): void {
    this.api.addCompany(this.form).subscribe((res: boolean) => {
      if (res) {
        //Send message text and refresh company list data in parent component
        this.successMsg.next("COMPANY_ADD_SUCCESS");
        this.companyAdded.emit(true);
      } else {
        this.failedMsg.next("COMPANY_ADD_FAILED");
      }
      this.showForm.next(false);
    });
  }

  ngOnInit(): void {
    this.form = new AddCompanyForm();

    this.api.getCompanyGroupAndServerLists().subscribe(
      (res: {
        companyGroups: {
          name: string;
          companyGroupId: string;
          server: string;
        }[];
        servers: string[];
      }) => {
        this.companyGroupList = res.companyGroups;
        this.serverList = res.servers;
      }
    );

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject.pipe(throttleTime(3000)).subscribe(() => {
      this.onSubmit();
    });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

import { Component, OnChanges, Input } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { TranslateModule } from '@ngx-translate/core';
import { AngularDraggableModule } from 'angular2-draggable';
import { NgIf, NgFor, NgClass, NgStyle } from '@angular/common';

@Component({
    selector: 'ap-monitor-zone',
    templateUrl: './ap-monitor-zone.component.html',
    styleUrls: ['./ap-monitor-zone.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, AngularDraggableModule, NgStyle, TranslateModule]
})
export class AnnaPerennaMonitorZoneComponent implements OnChanges {
  constructor(
    private api: ApiService
  ) { }

  @Input('monitorAspaId') monitorAspaId: any;
  zoneData: any;
  zonesOriginal: any;
  selectedZone: any;

  getZoneData(): void {
    this.api.getMonitorZoneData(this.monitorAspaId).subscribe(res => {
      this.zoneData = this.parseZoneData(res);
      //Save original zones into different variable in case of cancel
      this.zonesOriginal = JSON.parse(JSON.stringify(this.zoneData));
    })
  }

  parseZoneData(data: any): any {
    data.activeZones = [];
    data.disabledZones = [];
    for (let i = 0; i < data.zones.length; i++) {
      if (data.zones[i].type === "Exclusive" || data.zones[i].type === "Preclusive") {
        data.activeZones.push(data.zones[i])
      } else if (data.zones[i].type === "Inactive") {
        data.disabledZones.push(data.zones[i]);
      }
    }

    return data;
  }

  editZone(zone: any): void {
    if (zone === this.selectedZone) {
      this.selectedZone = null;
      return;
    }
    this.selectedZone = zone;
    this.selectedZone.xyCoords = [];
    //Zone coordinates are in single string separated by space between each. First split them into 4 different coordinates
    let coords = zone.coords.split(" ");
    for (let i = 0; i < coords.length; i++) {
      //Split coordinates with ',' so we can get x and y coordinates
      let splitted = coords[i].split(',');
      let left = splitted[0];
      let top = splitted[1];
      let xy = { 'top': top, 'left': left }
      //Push x and y coordinates into list of all xy coordinates. This list is used to show draggable corners for the zone
      this.selectedZone.xyCoords.push(xy);
    }
  }

  onMoving(event: any, index: number): void {
    this.selectedZone.modified = true;
    this.selectedZone.coordsChanged = true;
    this.updateZoneCoords(index, event.x, event.y);
  }

  updateZoneCoords(index: number, xOffset: number, yOffset: number): void {
    let top = parseInt(this.selectedZone.xyCoords[index].top) + yOffset;
    let left = parseInt(this.selectedZone.xyCoords[index].left) + xOffset;
    let originalCoords = this.selectedZone.coords.split(" ");
    originalCoords[index] = left + "," + top
    this.selectedZone.coords = originalCoords.join(" ");
  }

  saveZone(zone: any): void {
    let saveData: any = {};
    saveData.aspaId = this.monitorAspaId;
    saveData.coords = zone.coords;
    saveData.zoneId = zone.id;
    saveData.type = zone.type;
    this.api.updateZone(saveData).subscribe(res => {
      zone.modified = false;
      zone.coordsChanged = false;
      zone.activeChanged = false;
      this.selectedZone = null;
    })
  }

  cancelZoneEdit(zone: any): void {
    //Get list of original activated and disabled zones
    let origAZones = JSON.parse(JSON.stringify(this.zonesOriginal.activeZones));
    let origDZones = JSON.parse(JSON.stringify(this.zonesOriginal.disabledZones));

    //Try to search cancelled zone from the active zones list first
    let origZone = origAZones.find(obj => {
      return obj.id === zone.id
    })

    if (origZone) {
      //If zone was found and it's activation was changed revert the changes
      if (zone.activeChanged) {
        this.zoneData.disabledZones.splice(this.zoneData.disabledZones.indexOf(zone), 1);
        this.zoneData.activeZones.push(zone);
      }

    } else {
      //If zone was not found from active zone list then look from the disabled zones list
      origZone = origDZones.find(obj => {
        return obj.id === zone.id
      })
      //Revert the changes if it was activated and then cancelled
      if (zone.activeChanged) {
        this.zoneData.activeZones.splice(this.zoneData.activeZones.indexOf(zone), 1);
        this.zoneData.disabledZones.push(zone);
      }

    }
    //Revert the coordinates if coordinates were changed
    if (zone.coordsChanged) {
      zone.coords = origZone.coords;
    }

    //Reset all modification related variables
    zone.modified = false;
    zone.coordsChanged = false;
    zone.activeChanged = false;
    this.selectedZone = null;
  }

  getClassForZone(zone: any): string {
    let classStr: string = "";
    if (zone.name.startsWith('bed')) {
      classStr = "zoneBed"
    }
    if (zone.name.startsWith('floor')) {
      classStr = "zoneFloor"
    }
    if (zone.name.startsWith('exit')) {
      classStr = "zoneExit"
    }
    if (zone.name.startsWith('safe')) {
      classStr = "zoneSafe"
    }
    if (zone.name.startsWith('toilet')) {
      classStr = "zoneToilet"
    }

    if (zone.name.startsWith('filter')) {
      classStr = "zoneFilter"
    }

    if (zone === this.selectedZone) {
      classStr += " selectedZone"
    }

    return classStr;
  }

  activateZone(zone: any) {
    zone.modified = true;
    zone.activeChanged = true;
    //Get zone type from name. Filter zones are named as filter1, filter2 etc
    if (zone.name.startsWith('filter')) {
      zone.type = "Preclusive"
    } else {
      zone.type = "Exclusive";
    }
    this.zoneData.disabledZones.splice(this.zoneData.disabledZones.indexOf(zone), 1);
    this.zoneData.activeZones.push(zone);
  }

  disableZone(zone: any) {
    zone.modified = true;
    zone.activeChanged = true;
    zone.type = "Inactive";
    this.zoneData.activeZones.splice(this.zoneData.activeZones.indexOf(zone), 1);
    this.zoneData.disabledZones.push(zone);
    this.selectedZone = null;
  }

  ngOnChanges() {
    this.zoneData = null;
    this.selectedZone = null;
    this.getZoneData();
  }
}

<h3>{{'RESIDENT_MANAGEMENT' |translate}}</h3>
<div class="form-inline mb-2 justify-content-between" role="toolbar" aria-label="">
    <div></div>
    <div class="btn-group mb-2" role="group" aria-label="">
        <!-- Search -->
        <input #residentSearchInput class="form-control border border-primary" type="text " placeholder="{{ 'SEARCH' |translate}}... ">
    </div>
</div>

<div class="pt-3">
    <div class="row p-2">
        <div class="col-lg-6">
            <!--Resident listing-->
            <div class="card shadow">
                <div class="card-body">
                    <table class="table table-borderless table-hover table-responsive ">
                        <thead>
                            <tr>
                                <th style="width:1%;">{{'RESIDENT_NAME' |translate}}</th>
                                <th>{{'ADDRESS' |translate}}</th>
                                <th>{{'NO_OF_DEVICES' |translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let resident of residents; let i = index">
                                <tr class="cursorPointer" (click)="getResidentDevices(i)" [ngClass]="{'table-active' : (selectedDev == i)}">
                                    <td>{{resident.displayName}}</td>
                                    <td>{{resident.address + ", " + resident.zipCode + " " + resident.city}}</td>
                                    <td>{{devicesOfResidents[resident.residentId]?.length}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <resident-info [deviceInfo]="deviceInfo" [residentInfo]="residentInfo" [deviceCount]="deviceCount" [devices]="devices" *ngIf="showDeviceInfo"></resident-info>
        </div>
    </div>
</div>



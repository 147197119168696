import { ActivityTranslationCode, Area } from '../../../models/activity-log.model';
import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { FeatureBadgeStatus } from 'app/components/feature-badge/feature-badge.component';

type LinkRoute = { area: Area, name: "staff" | "camera-configuration" };

const linkRoutes: LinkRoute[] = [
  { area: "Staff", name: "staff" },
  { area: "Digital Supervision", name: "camera-configuration" },
];

/**
 * This pipe takes a translated string and wraps targetName and targetId into an anchor tag.
 */
@Pipe({
  name: "anchorWrap",
  standalone: true,
  pure: true,
})
export class AnchorWrapPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  /**
   * Transforms the input string by wrapping targetName and targetId into an anchor tag.
   * @param value - The input string to transform.
   * @param targetName - The name to be wrapped in the anchor tag.
   * @param targetId - The id to be wrapped in the anchor tag.
   * @param area - The area of the target.
   * @param searchText - The text to highlight in the anchor tag.
   * @param hideTargetId - Whether to hide the targetId in the anchor tag.
   * @returns - The transformed string with targetName and targetId wrapped in an anchor tag.
   */
  transform(value: string, targetName: string, targetId: string, area: Area, searchText: string = "", hideTargetId: boolean = false): SafeHtml {
    let result = "";

    if (!value || !targetName || !targetId || !area) {
      return value;
    }

    if (searchText) {
      result = this.wrapInHighlightedAnchorTag(value, targetName, targetId, area, searchText, hideTargetId);
    }
    else {
      result = this.wrapInAnchorTag(value, targetName, targetId, area, hideTargetId);
    }
    return this.sanitizer.bypassSecurityTrustHtml(result);
  }

  private resolveTargetString(targetName: string, targetId: string, area: Area, hideTargetId: boolean): string {
    // Camera groups uses GUID as targetId, so we don't want to show it in the anchor tag
    if (!hideTargetId && area !== "Digital Supervision" && targetId) {
      return `${targetName} (${targetId})`;
    }

    return targetName;
  }

  private resolveRoute(area: Area, targetId: string): string {
    const route = linkRoutes.find((r) => r.area === area);
    return route ? `/${route.name}/${targetId}` : "/dashboard";
  }

  private wrapInAnchorTag(value: string, targetName: string, targetId: string, area: Area, hideTargetId): string {
    const targetString = this.resolveTargetString(targetName, targetId, area, hideTargetId);
    const start = value.indexOf(targetString);
    const end = start + targetString.length;
    const result = `
      ${value.slice(0, start)}
        <a class="link ${area.toLocaleLowerCase()}"
          data-url="${this.resolveRoute(area, targetId)}"
          style="cursor: pointer; text-decoration: underline;">
          ${targetString}
        </a>
      ${value.slice(end)}`;
    return result;
  }

  private wrapInHighlightedAnchorTag(value: string, targetName: string, targetId: string, area: Area, searchText: string, hideTargetId: boolean): string {
    const targetString = this.resolveTargetString(targetName, targetId, area, hideTargetId).replace(/&#x2011;/g, '-');

    const start = value.toLocaleLowerCase().indexOf(targetString.toLocaleLowerCase());
    const end = start + targetString.length;

    const searchStart = targetString.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase());
    const searchEnd = searchStart + searchText.length;

    let highlightedTargetString = "";

    if (searchStart !== -1) {
      highlightedTargetString = `${targetString.slice(0, searchStart)}<span class="yellow" onclick="event.target.parentNode.click();">${targetString.slice(searchStart, searchEnd)}</span>${targetString.slice(searchEnd)}`;
    } else {
      highlightedTargetString = targetString;
    }

    return `${value.slice(0, start)}<a class="link" data-url="${this.resolveRoute(area, targetId)}" style="cursor: pointer; text-decoration: underline;">${highlightedTargetString}</a>${value.slice(end)}`;
  }
}

/**
 * This pipe takes a translated string and wraps targetName and targetId into an strong tag.
 */
@Pipe({
  name: "strongWrap",
  standalone: true,
  pure: true,
})
export class StrongWrapPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: string, targetString: string): SafeHtml {
    let result = value;
    const start = result.indexOf(targetString);
    const end = start + targetString.length;

    if (start !== -1) {
      result = `
        ${result.slice(0, start)}
          <strong>${targetString}</strong>
        ${result.slice(end)}`;
    }
    return this.sanitizer.bypassSecurityTrustHtml(result);
  }
}

/**
 * Pipe to parse target class to a class name.
 */
@Pipe({
  name: "parseTargetClass",
  standalone: true,
  pure: true,
})
export class ParseTargetClassPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return "";
    }

    const targetClass = value
      .toLowerCase()
      .trim()
      .replace(/ /g, "-");

    return targetClass;
  }
}

@Pipe({
  name: "resolveActivityBadgeStatus",
  standalone: true,
  pure: true,
})
export class ResolveActivityBadgeStatusPipe implements PipeTransform {
  transform(value: ActivityTranslationCode): FeatureBadgeStatus {
    let status: FeatureBadgeStatus;

    switch (value) {
      case "USER_LOGIN_FAILED":
        status = "warning";
        break;
      default:
        status = "default";
        break;
    }

    return status;
  }
}

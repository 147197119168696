<table
  infiniteScroll
  [infiniteScrollDistance]="0"
  [infiniteScrollThrottle]="500"
  [infiniteScrollDisabled]="infiniteScrollDisabled"
  (scrolled)="loadNextAlerts.emit()"
  class="table table-hover tableLatest"
>
  <thead>
    <tr>
      <th></th>
      <th>{{ "START_TIME" | translate }}</th>
      <th>{{ "DEVICE" | translate }}</th>
      <th>{{ "EVENT" | translate }}</th>
      <th>{{ "USER_OR_RECIPIENT" | translate }}</th>
      <th>{{ "END_TIME" | translate }}</th>
    </tr>
  </thead>

  <tbody>
    <ng-container *ngFor="let alert of alerts; let e = even; let i = index">
      <!-- Alert header row -->
      <tr
        class="trCollapse"
        [ngClass]="{ trEven: e }"
        (click)="expandRow(alert)"
      >
        <td><span class="fa-light" [ngClass]="alert.expanded ? 'fa-minus' : 'fa-plus'"></span></td>
        <td>{{ alert.receiveTime | moment : "l LTS" }}</td>
        <td>{{ alert.deviceName ? alert.deviceName : alert.deviceId }}</td>
        <td>
          {{ alert.activationGroup | getActivationName : alert.activationNode : alert.deviceType : alert.customActivationName : alert.connTimeLimit }}
        </td>
        <td>
          <ng-container *ngIf="!alert.cancelled">
            <!-- Show different recipient if alarm was completed with EMA timeout. In that case resolverName is always NO_USER and deliveryType is EMA -->
            <div *ngIf="alert.resolverName === 'NO_USER' && alert.deliveryType === 'EMA'; else alarmResolver">
              {{ "EMA_COMPLETE_TIMEOUT" | translate }} ({{ alert.emaEvents | getResolverForTimeout}})
            </div>

            <!--Show normal alarm recipient-->
            <ng-template #alarmResolver>
              <span *ngIf="alert.resolverName !== 'STAFF_ERROR'">
                <span class="fa-light fa-fw" [ngClass]="alert | getDeliveryClass"></span>
                  {{ alert.resolverName !== "NO_USER" ? alert.resolverName : (alert.deliveryType | getDeliveryStr)}}
                </span>

                <span *ngIf="alert.resolverName === 'STAFF_ERROR'">
                  <span class="fa-light fa-fw fa-exclamation-triangle text-danger"></span>
                  {{ "DELIVERY_ERROR" | translate }}
                </span>
            </ng-template>
          </ng-container>

          <!--If alarm was cancelled show special text for that-->
          <ng-container *ngIf="alert.cancelled">
            <span class="text-danger">
              <span class="fa-light fa-fw fa-ban"></span>
              {{ "CANCELLED" | translate }}
            </span>
          </ng-container>
        </td>
        <td> {{ alert.completeTime | moment : "l LTS" }}</td>
      </tr>

      <!--Alert event detail row's data-->
      <tr *ngIf="alert.expanded" [ngClass]="{ trEven: e }">
        <td colspan="6" class="tdDetails">
          <div class="rowTaskId" [ngClass]="{ rowTaskIdEven: e }">
            {{ alert.taskId }}
          </div>

          <div class="row">
            <div class="col-md-3">
              <div>
                <strong>{{ "SERIAL_NUMBER" | translate}}:</strong> {{ alert.deviceId }}
                <br />
                <span *ngIf="alert.environmentId !== '00000000'">
                  <strong> {{"BASESTATION" | translate}}:</strong> {{ alert.environmentName }} ({{ alert.environmentId}})
                  <br />
                </span>
                <strong>{{ "ALARM_ROUTE" | translate }}:</strong> {{ alert.alertRouterName }} ({{ alert.alertRouterId }})
              </div>
            </div>

            <div class="col-md-3" *ngIf="alert.emaEvents || alert.callEvents || alert.emailEvents">
              <!--EMA-->
              <div *ngIf="alert.emaEvents">
                <div *ngFor="let ema of alert.emaEvents" class="mb-2">
                  <div *ngIf="ema.eventType !== 'LOG_NEW_NOTE'">

                    <!--Routing changed event is inside EMA events. Show different icon and header for that one-->
                    <strong *ngIf="ema.eventType === 'LOG_NEW_ALERT' || ema.eventType === 'LOG_ROUTING_CHANGED'">
                      <span
                        class="fa-light fa-lg"
                        [ngClass]="{'fa-mobile': ema.eventType === 'LOG_NEW_ALERT', 'fa-cog': ema.eventType ==='LOG_ROUTING_CHANGED'}"
                      ></span>
                        {{ ema.eventType === "LOG_ROUTING_CHANGED" ? ("SYSTEM" | translate) : "Everon Mobile" }}
                    </strong>

                    <div *ngIf="ema.userId !== 'NO_USER'">
                      {{ ema.userName }} ({{ ema.userId }})
                      <span *ngIf="ema.nfcId">
                        {{ "WITH_NFC" | translate }} {{ ema.nfcName }} ({{ ema.nfcId }})
                      </span>
                    </div>

                    <div *ngIf="ema.eventType === 'LOG_NEW_STATE_COMPLETED' && ema.userId === 'NO_USER'">
                      {{ "EMA_COMPLETE_TIMEOUT" | translate}} ({{alert.emaEvents | getResolverForTimeout}})
                    </div>

                    <div>
                      {{ ema.eventType | translate}} - {{ ema.time | moment : "l LTS"}}
                    </div>

                    <div style="font-weight: bold;">
                      {{ alert.receiveTime | calculateTime : ema.time }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- CALL+SMS-->
              <div *ngIf="alert.callEvents && alert.callEvents.length > 0">
                <strong> <span class="fa-light fa-phone"></span> Call+SMS </strong>

                <div *ngFor="let call of alert.callEvents" class="mb-2">
                  <div *ngIf="call.CallCount > 0">
                    <div>
                      {{ call.UserName }}
                      <span *ngIf="call.DialNumber">
                        ({{ call.DialNumber }})
                      </span>
                    </div>

                    <div>
                      {{ "CALL_COUNT" | translate }} {{ call.CallCount }}
                    </div>

                    <div>
                      {{ "CALL_" + call.CallResult | removeSpaces | translate }}
                      <span *ngIf=" call.CallResult !== 'CALL FAILURE'">
                        - {{ call.LastCallTime | moment : "l LTS" }}
                      </span>
                    </div>

                    <div *ngIf="call.CallResult !== 'CALL FAILURE'" style="font-weight: bold;" >
                      {{ alert.receiveTime | calculateTime : call.LastCallTime }}
                    </div>
                  </div>
                </div>
              </div>

              <!--EMAIL-->
              <div *ngIf="alert.emailEvents && alert.emailEvents.length > 0">
                <strong><span class="fa-light fa-envelope"></span> Email</strong>
                <div *ngFor="let email of alert.emailEvents" class="mb-2">
                  <div>
                    {{ email.UserName }} ({{ email.UserId }})
                  </div>

                  <div *ngIf="email.EmailAddress">
                    {{ email.EmailAddress }}
                  </div>

                  <div>
                    {{ email.DeliveringTime | moment : "l LTS" }}
                  </div>
                </div>
              </div>

              <!--Delivery error info-->
              <div *ngIf="alert.resolverName === 'STAFF_ERROR'">
                <strong>
                  <span class="fa-light fa-fw fa-exclamation-triangle text-danger"></span> {{ "DELIVERY_ERROR" | translate}}
                </strong>
                <p>{{ "DELIVERY_ERROR_INFO" | translate }}</p>
              </div>
            </div>

            <!-- SMS -->
            <div *ngIf="alert.deliveryType.includes('SMS')" class="col-md-2">
              <strong>
                <span class="fa-light fa-comment"></span> SMS
              </strong>

              <div *ngFor="let sms of alert?.smsEvents" class="mb-2">
                <div> {{ sms.UserName }} ({{ sms.UserId }}) </div>
                <div> {{ sms.PhoneNumber }} </div>
                <div> {{ "MESSAGE_SENT" | translate}}: {{ sms.SendingTime | moment : "l LTS"}}</div>

                <div *ngIf="(sms.DeliveringTime | moment: 'l LTS') !== '-'; else smsNotDelivered">
                  {{ "DELIVERED" | translate }}: {{ sms.DeliveringTime | moment : "l LTS"}}
                </div>

                <ng-template #smsNotDelivered>
                  <span class="text-danger ml-2"><span class="fa-light fa-fw fa-exclamation-triangle mr-1"></span>{{ "UNABLE_TO_DELIVER" | translate }}</span>
                </ng-template>
              </div>

              <div *ngIf="alert?.smsEvents.length == 0" class="pt-1">
                <span class="text-danger ml-2"><span class="fa-light fa-exclamation-triangle mr-1"></span> {{ "SMS_DELIVERY_ERROR" | translate }}</span>
              </div>

            </div>

            <!--Alarm Reasons-->
            <div
              *ngIf="alert?.alarmReasons.length > 0"
              class="col-md-3"
            >
              <div>
                <strong> {{ "ALARM_REASONS" | translate}}:</strong>
                <ul style="margin-left: -22px">
                  <li *ngFor="let item of alert.alarmReasons | removeAlarmReaonDuplicates" style="right: 50px">
                    {{ (item.alarmReasonCategory | alarmCategory : alarmReasonTranslations) + " - " + (item.alarmReason | alarmReason : alarmReasonTranslations)}}
                  </li>
                </ul>
              </div>
            </div>

            <!-- NOTES -->
            <div class="col-md-2">
              <strong>
                <span class="fa-light fa-note-sticky"></span> {{ "NOTES" | translate }}
              </strong>

              <div class="mt-2 mb-2">
                <span class="btn btn-outline-primary btn-sm" (click)="alert.showNoteForm = true">
                  <span class="fa-light fa-fw fa-plus"></span> {{ "ADD_NOTE" | translate }}
                </span>
              </div>

              <div *ngIf="alert.showNoteForm" class="mt-2 mb-2">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    [(ngModel)]="alert.noteText"
                  ></textarea>
                </div>

                <div class="form-group">
                  <span class="btn btn-outline-primary" (click)="submitAlertNoteHandler(alert)">
                    {{ "SAVE" | translate }}
                  </span>

                  <span class="btn btn-outline-danger ml-2" (click)="alert.noteText = ''; alert.showNoteForm = false">
                    {{ "CANCEL" | translate }}
                  </span>
                </div>
              </div>

              <div *ngFor="let comment of alert.commentEvents" class="mb-2">
                  <div>{{ comment.UserName }} ({{comment.UserId}})</div>
                  <div> {{ comment.CommentTime | moment : "l LTS" }}</div>
                  <app-text-format [text]="comment.Comment"></app-text-format>
              </div>
            </div>

            <!--Anna Perenna animated gif container-->
            <div *ngIf="alert.deviceType === '34-2' || alert.deviceType === '34-3'" class="col-md-3">
              <div *ngIf="alert.APImageData">
                <div>
                  <button (click)="alert.showAPImage = !alert.showAPImage" class="btn btn-outline-primary" >
                    {{ (!alert.showAPImage ? "AP_SHOW" : "AP_HIDE") | translate }}
                  </button>
                </div>
                <div>
                  <img *ngIf="alert.showAPImage" class="img-fluid mt-2" [src]="'data:image/gif;base64,' + alert.APImageData" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Mobile } from 'app/models/lyraregistry.model';
import { ApiService } from 'app/services/api.service';
import * as moment from 'moment-timezone';
import { MomentPipe, FilterPipe } from '../../pipes/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'mobile-whitelist',
    templateUrl: './mobile-whitelist.component.html',
    styleUrls: ['./mobile-whitelist.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, FormsModule, TranslateModule, MomentPipe, FilterPipe]
})
export class MobileWhitelistComponent implements OnInit {

  constructor(
    private api: ApiService,
    private route: ActivatedRoute
  ) {
  }

  mobileWhitelist: Mobile[] = [];


  approveMobile(mobile: Mobile): void {
    this.api.approveWhitelistRequest(mobile.mobileId).subscribe(res => {
      mobile.modified = moment().utc().toDate();
      mobile.approved = true;
    })
  }

  denyMobile(mobile: Mobile): void {
    this.api.denyWhitelistRequest(mobile.mobileId).subscribe(res => {
      this.mobileWhitelist.splice(this.mobileWhitelist.indexOf(mobile), 1);
    })
  }

  editMobile(mobile: Mobile): void {
    mobile.newGsmNumber = mobile.gsmNumber;
    mobile.newName = mobile.name;
    mobile.edit = true;
  }

  saveMobile(mobile: Mobile): void {
    let data = {
      name: mobile.newName,
      gsmNumber: mobile.newGsmNumber
    }
    this.api.updateMobile(mobile.mobileId, data).subscribe(res => {
      mobile.name = mobile.newName;
      mobile.gsmNumber = mobile.newGsmNumber;
      mobile.edit = false;
    })
  }

  ngOnInit(): void {
    this.api.getMobileWhitelist().subscribe(res => {
      this.mobileWhitelist = res;
    })
  }

}

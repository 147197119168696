import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureBadgeComponent } from 'app/components/feature-badge/feature-badge.component';
import { DeviceStatusSelectorPipe } from './device-status-selector.pipe';
import { BatteryIconPipe } from 'app/pipes/pipes';

type StatusElementType = "batteryIcon" | "deviceStatus" | "text";

@Component({
  selector: 'app-device-history-status-selector',
  templateUrl: './device-history-status-selector.component.html',
  styleUrls: ['./device-history-status-selector.component.css'],
  standalone: true,
  imports: [CommonModule, TranslateModule, FeatureBadgeComponent, BatteryIconPipe, DeviceStatusSelectorPipe],
})
export class DeviceHistoryStatusSelectorComponent implements OnChanges {
  @Input() statusKey: string;
  @Input() value: any;

  elementType: StatusElementType;

  setStatusElementType(statusKey: string): StatusElementType {
    const key = statusKey.toUpperCase();

    if (key.includes("BATTERY")) {
      return "batteryIcon";
    }
    if (key.includes("STATUS")) {
      return "deviceStatus";
    }
    return "text";
  }

  ngOnChanges(): void {
    this.elementType = this.setStatusElementType(this.statusKey);
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'training-videos',
    templateUrl: './training-videos.component.html',
    styleUrls: ['./training-videos.component.css'],
    standalone: true,
    imports: [MatExpansionModule, TranslateModule]
})
export class TrainingVideosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

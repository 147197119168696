<h5 id="title">{{ "ACTIVITY_LOG" | translate }}</h5>

<div class="row pb-5">
    <div class="col-10 filters">

        <!-- Hide other than user options until they are implemented
        <app-multiselect-dropdown class="mr-2"
            [label]="'REPORT_TYPE' | translate"
            [placeholder]="'ALL_REPORT_TYPES' | translate"
            [options]="options.reportType">
        </app-multiselect-dropdown> -->

        <app-multiselect-dropdown class="mr-3" data-testid="user-dropdown"
            id="user-select-dropdown"
            [label]="'ACTIVITY_FILTER_USER' | translate"
            [placeholder]="'ALL_USERS' | translate"
            [groupedOptions]="options.users"
            [(selected)]="activityLogService.filters.users"
            (selectedChange)="updateFilters()"
            [enableSearch]="true">
        </app-multiselect-dropdown>

        <app-multiselect-dropdown class="mr-3" data-testid="area-dropdown"
            [label]="'AREA' | translate"
            [placeholder]="'ALL_AREAS' | translate"
            [options]="options.areas"
            [(selected)]="activityLogService.filters.areas"
            (selectedChange)="updateFilters()">
        </app-multiselect-dropdown>

        <app-multiselect-dropdown class="mr-3" data-testid="action-dropdown"
            [label]="'ACTION' | translate"
            [placeholder]="'ALL_ACTIONS' | translate"
            [options]="options.actions"
            [(selected)]="activityLogService.filters.actions"
            (selectedChange)="updateFilters()">
        </app-multiselect-dropdown>

        <button id="clear-filters-btn" (click)="clearFilters()">{{ "CLEAR_FILTER" | translate | capitilize }}</button>
    </div>

    <div class="col input-group search">
        <input type="text" class="form-control search-input"
            [placeholder]="('SEARCH' | translate) + '...'"
            (keyup.enter)="updateFilters()"
            [(ngModel)]="activityLogService.filters.search">

        <div class="input-group-append">
            <button class="btn btn-primary" type="button" (click)="updateFilters()">
                <i class="fa-light fa-magnifying-glass"></i>
            </button>
        </div>
    </div>
</div>

<div>
    <app-activity-log-table></app-activity-log-table>
</div>

<div class="row mb-2">
    <div class="col-lg-2">
        <mat-slide-toggle (change)="showTeamAlerts = !showTeamAlerts">{{'SHOW_ALERTS_TO_OTHERS' |translate}}
        </mat-slide-toggle>
    </div>
</div>
<div class="row">
    <div class="col-lg-4">
        <control-room-alert-panel-incoming></control-room-alert-panel-incoming>
    </div>
    <div class="col-lg-4">
        <control-room-alert-panel-started [showTeamAlerts]="showTeamAlerts"></control-room-alert-panel-started>
    </div>
    <div class="col-lg-4">
        <control-room-alert-panel-forwarded [showTeamAlerts]="showTeamAlerts"></control-room-alert-panel-forwarded>
    </div>

</div>
<div *ngIf="!syncStatusOnline" class="syncStatus alert alert-danger">
    <span class="fa-light fa-fw fa-lg fa-signal-slash"></span> {{'ALERT_SERVER_CONNECTION_LOST' |translate}}
</div>
<div *ngIf="!videoServerOnline" class="syncStatus alert alert-danger">
    <span class="fa-light fa-fw fa-lg fa-signal-slash"></span> {{'VIDEO_SERVER_CONNECTION_LOST' |translate}}
</div>
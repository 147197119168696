export class OptionGroup {

  constructor(name: string, options: Option[]) {
    this.name = name;
    this.options = options;
  }
  name: string;
  allChecked: boolean = false;
  collapseState: "expanded" | "collapsed" = "collapsed";
  options: Option[];
}

export class Option {
  constructor(value: any, name: string) {
    this.value = value;
    this.name = name;
  }
  value: any;
  name: string;
  checked: boolean = false;
}

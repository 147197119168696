<div class="card stickyCard">
    <div class="card-header-white header-underline">
        <h6 class="white-header-text">
            {{'CREATE_NEW_GROUP' |translate}}
        </h6>
        <span class="float-right cursorPointer white-header-xmark-icon" (click)="closeAddForm()"><span class="fa-light fa-xmark"></span></span>
    </div>
    <div class="card-body-white">

        <div *ngIf="locationList">
            <form [formGroup]="teamAddForm">
                <div class="form-group">
                    <label><strong> {{'GROUP_NAME' |translate}}</strong></label>
                    <input class="form-control" type="text" placeholder="{{'GROUP_NAME' |translate}}"
                        formControlName="groupName">
                </div>
                <div class="form-group">
                    <mat-slide-toggle formControlName="teamLocked">{{'LOCKED' |translate}} <span
                            matTooltip="{{'TOOLTIP_TEAM_LOCKED' |translate}}"
                            class="fa fa-fw fa-info-circle"></span></mat-slide-toggle>
                </div>
                <div class="form-group">
                    <label><strong>{{'LOCATION' |translate}}</strong></label>
                    <mat-form-field class="selector-full-width">
                        <mat-select formControlName="locationName" placeholder="{{'OPTIONAL' |translate}}"
                            (selectionChange)="parseStaffList(allStaff)">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let location of locationList" [value]="location">{{location.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <label class="d-flex justify-content-between rightMargin"><strong>{{'STAFF_MEMBERS' |translate}}</strong>
                        <mat-checkbox
                            (click)="toggleAllSelection()"
                            [labelPosition]="'before'">
                            <strong>{{'SELECT_ALL' | translate}}</strong>
                        </mat-checkbox>
                    </label>
                    <mat-selection-list #teamMembers formControlName="selectedTeamMembers" dense="true"
                        class="staff-container">
                        <mat-list-option *ngFor="let staff of availableStaffForCompany" [value]="staff.id">
                            {{staff.fullName}}
                        </mat-list-option>
                        <span *ngIf="availableStaffForLocation && availableStaffForLocation.length > 0"
                            matSubHeader>{{'LOCATION_MEMBERS' |translate}}</span>
                        <mat-list-option *ngFor="let staff of availableStaffForLocation" [value]="staff.id">
                            {{staff.fullName}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
                <div class="form-group">
                    <button appThrottleClick (throttledClick)="addTeam()" [disabled]="teamAddForm.invalid" type="submit" class="btn btn-outline-primary gray-bg mr-1">
                        <span class="fa-light fa-floppy-disk"></span>
                        {{'SAVE' |translate}}
                    </button>
                    <button type="reset" class="btn btn-outline-danger" (click)="closeAddForm()"><span
                            class="fa-light fa-xmark"></span> {{'CANCEL' |translate}}</button>
                    <div class="alert alert-danger mt-2" *ngIf="addTeamFormError">
                        {{addTeamFormError}}
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="!locationList">
            <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg">
        </div>
    </div>
</div>

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ResidentDevice, ResidentInfo } from '../../models/resident.model';
import { Device } from '../../models/device.model';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { HelperService } from '../../services/helper.service';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule } from '@angular/forms';
import { HighlightSearch } from '../../pipes/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceRemoveCompanyComponent } from './device-remove-company/device-remove-company.component';
import { DeviceAddComponent } from '../devices/device-add/device-add.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-device-database',
    templateUrl: './device-database.component.html',
    styleUrls: ['./device-database.component.css'],
    standalone: true,
    imports: [NgIf, DeviceAddComponent, DeviceRemoveCompanyComponent, FormsModule, NgFor, TranslateModule, HighlightSearch]
})
export class DeviceDatabaseComponent implements OnInit {

  constructor(
    private api: ApiService,
    private helper: HelperService,
  ) {
    
  }

  residentDevices: ResidentDevice;
  residentInfo: ResidentInfo;

  devices: any = [];


  @ViewChild('deviceSearchInput', { static: true }) deviceSearchInput: ElementRef;

  @ViewChild('devTypeSelect', { static: true }) devTypeSelect: ElementRef;

  _deviceFilter: string;
  filteredDevices = [];

  dataSource = null;

  // for filters
  devTypes = [];
  locations = [];

  selectedDevType = null;
  selectedLocation = null;

  showAddProductDialog = false;
  showRemoveProductDialog = false;

  getDeviceType(type: string): string {
    return this.helper.getDeviceType(type);
  }

  get deviceFilter(): string {
    return this._deviceFilter;
  }

  set deviceFilter(value: string) {
    this._deviceFilter = value;
    this.filteredDevices = this.transform(this.devices.filter(word => this.filterMatchForSelected(word)), value);
    this.dataSource = this.filteredDevices;
  }

  selectDevType(value: string) {
    this.selectedDevType = value;
    this.dataSource = this.devices.filter(word => this.filterMatchForSelected(word));
  }

  selectLocation(value: string) {
    this.selectedLocation = value;
    this.dataSource = this.devices.filter(word => this.filterMatchForSelected(word));
  }

  filterMatchForSelected(word) {
    if (this.selectedDevType && this.selectedLocation) {
      return word.devType == this.selectedDevType && word.location == this.selectedLocation;
    } else if(this.selectedDevType) {
      return word.devType == this.selectedDevType;
    } else if (this.selectedLocation) {
      return word.location == this.selectedLocation;
    }
    else {
      return true;
    }
  }

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      return (
        (typeof it.devId !== 'undefined' ? it.devId.toLocaleLowerCase().includes(searchText) : false) ||
        (typeof it.devType !== 'undefined' ? it.devType.toLocaleLowerCase().includes(searchText) : false) ||
        (typeof it.leasingTime !== 'undefined' ? it.leasingTime.toLocaleLowerCase().includes(searchText) : false) ||
        (typeof it.location !== 'undefined' ? it.location.toLocaleLowerCase().includes(searchText) : false) ||
        (typeof it.deviceName !== 'undefined' ? it.deviceName.toLocaleLowerCase().includes(searchText) : false) ||
        (typeof it.resident !== 'undefined' ? it.resident.toLocaleLowerCase().includes(searchText) : false) ||
        (typeof it.sSnumber !== 'undefined' ? it.sSnumber.toLocaleLowerCase().includes(searchText) : false)
      )
    });
  }

  openAddProduct() {
    this.showAddProductDialog = true;
  }

  closeAddBaseForm(): void {
    this.showAddProductDialog = false;
  }

  openRemoveProduct() {
    this.showRemoveProductDialog = true;
  }

  closeRemoveBaseForm(): void {
    this.showRemoveProductDialog = false;
  }


  preventClick() {
    window.event.cancelBubble = true;
  }


  ngOnInit(): void {
/*
    let data = { fullInfo: true }
    this.api.getDevices(data).subscribe(res => {
      this.devices = res;
    });
    */

    let customerId = JSON.parse(localStorage.getItem("user"))["customerId"];

    this.api.getCompanyInventory(customerId).subscribe(res => {
      this.devices = res;
      //      this.devices.leasingTime = moment(this.companyGroupDevices[d_id++].leasingTime).format('l LTS')
      let i = 0;
      let devTypeArray = [];
      let locationArray = [];
      for (let i = 0; i < this.devices.length; i++) {
        this.devices[i].devType = this.getDeviceType(this.devices[i].devType);
        this.devices[i].leasingTime = moment(this.devices[i].leasingTime).format('L LTS');

        devTypeArray[i] = this.devices[i].devType;
        locationArray[i] = this.devices[i].location;

      }

      // gather product types and locations in uniquely into one list
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      this.devTypes = devTypeArray.filter(onlyUnique);
      this.locations = locationArray.filter(onlyUnique);

      this.filteredDevices = this.devices;
      this.dataSource = this.filteredDevices;

    });

    fromEvent(this.deviceSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => event.target.value)
      // Time in milliseconds between key events
      , debounceTime(50)
      // If previous query is different from current
      , distinctUntilChanged()
      // subscription for response
    ).subscribe((query: string) => this.deviceFilter = query.toLowerCase());

  }
  sortingBy = [];
  setSort(sorter: string) {

    if (typeof this.sortingBy[sorter] === 'undefined') {
      this.sortingBy[sorter] = true;
    } else {
      this.sortingBy[sorter] = !this.sortingBy[sorter];
    }
    this.dataSource = this.filteredDevices.filter(word => this.filterMatchForSelected(word)).sort((a, b) => {
            return this.compare(a[sorter], b[sorter], this.sortingBy[sorter]);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}

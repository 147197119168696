<p id="label" *ngIf="label">{{ label }}</p>
<button id="dropdown-btn" #button [disabled]="disabled" (click)="openDropdown()">
    <span class="pr-3">{{ selectedText }}</span>
    <i class="fa-solid fa-caret-down"></i>
</button>

<div id="dropdown-menu" #dropdownMenu *ngIf="showDropdownMenu" data-testid="dropdown-menu">
    <ng-container *ngIf="optionsCopy?.length > 0 || groupOptionsCopy?.length > 0; else elseBlock">
        <div id="search-box" *ngIf="enableSearch">
            <input
              type="text"
              class="form-control form-control-sm"
              [placeholder]="'SEARCH' | translate"
              (input)="search($event)"
            />
        </div>

        <span id="untick-all" (click)="untickAll()"><i class="fas fa-xmark fa-lg mr-1"></i>{{ "UNTICK_ALL" | translate }}</span>

        <div class="option-list mt-2">
            <div
              *ngFor="let option of options"
              (click)="toggleCheckbox(checkbox, option.value, $event.target)"
              [ngClass]="['option-item', !option.disabled ? 'item-hover-enabled' : 'item-hover-disabled']"
              data-testid="option-list"
            >
                <span class="option-text" [innerHTML]="option.name | translate | highlight : searchText"></span>
                <mat-checkbox
                    #checkbox
                    [disabled]="option.disabled"
                    [checked]="isSelected(option.value)">
                </mat-checkbox>
            </div>

            <div *ngFor="let group of groupedOptions; let i = index" class="option-group">
                <span [ngClass]="group.disabled ? 'group-title-disabled' : 'group-title-enabled'">
                    {{ group.label | translate }}
                    <span class="ml-1" [ngClass]="group.iconClass"></span>
                </span>

                <div
                  *ngFor="let option of group.options"
                  (click)="toggleCheckbox(checkbox, option.value, $event.target)"
                  [ngClass]="['option-item', !group.disabled && !option.disabled ? 'item-hover-enabled' : 'item-hover-disabled']"
                  data-testid="option-group-list"
                >
                    <span class="option-text" [innerHTML]="option.name | translate | highlight : searchText"></span>
                    <mat-checkbox
                        #checkbox
                        [disabled]="option.disabled || group.disabled"
                        [checked]="isSelected(option.value)">
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #elseBlock>
        <span id="option-warning-text">{{ "NO_OPTIONS" | translate }}</span>
    </ng-template>
</div>

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable} from 'rxjs';
import { AlarmReasonsChecklist } from './alarm-reason-checklist/alarm-reason-checklist.component';

import { AlarmService } from 'app/services/alarm.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { DisplayAlarmReasonsComponent } from '../../display-alarm-reasons/display-alarm-reasons.component';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { now } from 'moment';

@Component({
    selector: 'app-mark-alarm-reason',
    templateUrl: './mark-alarm-reason.component.html',
    styleUrls: ['../../control-room.component.css'],
    standalone: true,
    imports: [MatCardModule, NgClass, NgIf, DisplayAlarmReasonsComponent, MatButtonModule, AlarmReasonsChecklist, AsyncPipe, TranslateModule]
})
export class MarkAlarmReasonComponent implements OnInit{

  constructor( private alarmService: AlarmService) { }

  checkboxListIsOpen: boolean = false;
  @Input() alert;
  isCheckedAlarms$: Observable<boolean>;

  openCheckboxList() {

    if (this.checkboxListIsOpen === false) {
      this.checkboxListIsOpen = true;
    }
  }

  ngOnInit(): void {

    this.isCheckedAlarms$ = this.alarmService.doAlertHaveCheckedAlarmReasons(this.alert?.alertId)

  }


}

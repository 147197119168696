<div class="card stickyCard">
    <div class="card-header-white header-underline">
        <h6 class="white-header-text" style="margin-left: -8px;">{{'ADD_NEW_LOCATION' |translate}} </h6>
        <span class="float-right cursorPointer" (click)="closeLocationCard()" style="margin-right: 15px; position: relative; top: -12px">
            <span class="fa-light fa-xmark"></span>
        </span>
    </div>
    <div class="card-body-white">
        <div class="row" *ngIf="location">
            <div class="col-lg-12">
                <form [formGroup]="locationAddForm" (ngSubmit)="formSubmitSubject.next()">
                    <div class="form-group">
                        <label><strong>{{'NAME' |translate}}</strong></label>
                        <input class="form-control" type="text" placeholder="{{'NAME' |translate}}"
                            formControlName="name">
                    </div>
                    <div class="form-group cursorPointerHover">
                        <span class="fa-light fa-fw" [ngClass]="allFields ? 'fa-minus' : 'fa-plus'"></span> <span
                            (click)="allFields = !allFields">{{'SHOW_ALL_FIELDS' |translate}}</span>
                    </div>
                    <div *ngIf="allFields">
                        <div class="form-group">
                            <label><strong>{{'CONTACT_PHONE' |translate}}</strong></label>
                            <input class="form-control" type="text" placeholder="{{'CONTACT_PHONE' |translate}}"
                                formControlName="contactPhone">
                        </div>
                        <div class="form-group">
                            <label><strong>{{'ADDRESS' |translate}}</strong></label>
                            <input class="form-control" type="text" placeholder="{{'ADDRESS' |translate}}"
                                formControlName="address">
                        </div>
                        <div class="form-group">
                            <label><strong>{{'POSTCODE' |translate}}</strong></label>
                            <input class="form-control" type="text" placeholder="{{'POSTCODE' |translate}}"
                                formControlName="postCode">
                        </div>
                        <div class="form-group">
                            <label><strong>{{'POSTOFFICE' |translate}}</strong></label>
                            <input class="form-control" type="text" placeholder="{{'POSTOFFICE' |translate}}"
                                formControlName="postOffice">
                        </div>
                    </div>
                    <div class="form-group">
                        <button [disabled]="(locationAddForm.invalid || locationAddForm.pristine)" type="submit"
                            class="btn btn-outline-primary gray-bg mr-1"><span class="fa-light fa-floppy-disk"></span>
                            {{'SAVE' |translate}}</button>
                        <button type="reset" class="btn btn-outline-danger" (click)="closeLocationCard()"><span
                                class="fal fa-times"></span> {{'CANCEL' |translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

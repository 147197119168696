import { Component, OnChanges, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Location, NgIf } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AnimationCollapse } from '../../../animations';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Device } from '../../../models/device.model';
import { HelperService } from '../../../services/helper.service'
import { Subject } from "rxjs";
import { throttleTime } from "rxjs/operators";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
    selector: 'device-key-info',
    templateUrl: './device-key-info.component.html',
    styleUrls: ['./device-key-info.component.css'],
    animations: [
        AnimationCollapse
    ],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatSlideToggleModule, TranslateModule]
})
export class DeviceKeyInfoComponent implements OnChanges, OnInit, OnDestroy {

  //Init global variables and services
  constructor(
    private api: ApiService,
    private helper: HelperService,
    private route: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private fb: UntypedFormBuilder
  ) { };

  @Input('device') device: Device;
  @Output() updateKey = new EventEmitter<any[]>();
  @Output() updateError = new EventEmitter<boolean>();

  editMode: boolean = false;
  user: any;
  editFormMore:boolean = false;
  keyEditForm: UntypedFormGroup;

  formSubmitSubject = new Subject();

  createKeyEditForm(): void {
    this.keyEditForm = this.fb.group({
      name: this.device.name,
      enabled: this.device.keyEnabled
    })
  }

  resetKeyEditForm(): void {
    this.keyEditForm.reset({
      name: this.device.name,
      enabled: this.device.keyEnabled
    })
    this.editMode = !this.editMode;
  }


  submitKeyEdit(): void {
    //Create new object from the original device
    const keyToBeSaved = JSON.parse(JSON.stringify(this.device))
    //Change the name and locationId of the new object to same as in form
    keyToBeSaved.name = this.keyEditForm.value.name;
    keyToBeSaved.keyEnabled = this.keyEditForm.value.enabled;
    //Check that modified data is different than original
    if (JSON.stringify(this.device) !== JSON.stringify(keyToBeSaved)) {
      //Send save request to server
      this.api.updateDevice(keyToBeSaved)
        .subscribe(res => {
          //If save was successfull replace original device with saved one and close edit mode
          this.device = keyToBeSaved;
          this.editMode = false;
          //Emit the new name to parent object so name is changed in the device list also
          this.updateKey.emit([this.device.name, this.device.keyEnabled]);
        }, err => {
          //Show error if save failed for some reason
          this.editMode = false;
          this.updateError.emit(true);
        })
    }
  }

  ngOnInit(): void {
    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe(() => {
        this.submitKeyEdit()
      });
  }

  ngOnChanges() {
    this.createKeyEditForm()
    this.user = JSON.parse(localStorage.getItem("user"));
    this.editMode = false;
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

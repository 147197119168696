<div class="card stickyCard shadow border col-lg-12">
    <div class="card-header border-bottom mb-0 pb-2">
        <div class="float-left">
            <h4>{{ residentName }}</h4>
            {{ personalID }}
            <span class="small align-top"
                ><i class="fa-solid fa-circle fa-xs p-2"></i
            ></span>
            {{ company }}
        </div>
        <div class="float-right">
            <span
                ><i class="fa-light fa-xmark fa-lg p-1 ml-2 cursorPointer"></i
            ></span>
        </div>
    </div>
    <div class="card-body ml-4 mr-4">
        <div class="row">
            <h5>{{ "CONTACT_INFORMATION" | translate }}</h5>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-2 text-right">
                            <span
                                ><i class="fa-solid fa-location-dot"></i
                            ></span>
                        </div>
                        <div class="col-10">
                            <strong>{{ "ADDRESS" | translate }}</strong>
                            <p>
                                {{ residentAddress }}
                                <br />
                                {{ residentPostalCode }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2 text-right">
                            <span><i class="fa-light fa-door-closed"></i></span>
                        </div>
                        <div class="col-10">
                            <strong>{{ "DOOR_CODE" | translate }}</strong>
                            <p>
                                {{ doorCode }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2 text-right">
                            <span><i class="fa-solid fa-phone"></i></span>
                        </div>
                        <div class="col-10">
                            <strong>{{
                                "TELEPHONE_NUMBER" | translate
                            }}</strong>
                            <p>
                                {{ telNumber }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-2 text-right">
                            <span><i class="fa-light fa-map"></i></span>
                        </div>
                        <div class="col-10">
                            <strong>{{
                                "DRIVING_DIRECTIONS" | translate
                            }}</strong>
                            <p class="max-height">
                                {{ drivingDirections }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2 text-right">
                            <span><i class="fa-light fa-file-lines"></i></span>
                        </div>
                        <div class="col-10">
                            <strong>{{ "NOTES" | translate }}</strong>
                            <p class="max-height">
                                {{ notes }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider mt-4 mb-4"></div>
        </div>

        <div class="row">
            <h5>{{ "IMPORTANT_INFORMATION" | translate }}</h5>
            <p>
                {{ importantInfo }}
            </p>
            <div class="divider mt-4 mb-4"></div>
        </div>

        <div class="row">
            <h5>{{ "CONNECTED_DEVICES" | translate }}</h5>
        </div>
        <div class="row">
            <div class="col-8">
                <form [formGroup]="residentEditForm">
                    <div class="form-group">
                        <ngx-bootstrap-multiselect
                            [options]="deviceSelect.deviceList"
                            [settings]="deviceSelect.settings"
                            [texts]="deviceSelect.texts"
                            formControlName="deviceId"
                            #selectedDevice
                        >
                        </ngx-bootstrap-multiselect>
                    </div>
                </form>
            </div>
            <div class="col-4">
                <button
                    appThrottleClick
                    (throttledClick)="addDevice()"
                    class="right btn btn-outline-primary shadow-sm mr-1"
                >
                    <span class="fa-light fa-plus"></span>
                    {{ "ADD_DEVICE" | translate }}
                </button>
            </div>
        </div>

        <!-- Empty state - no devices connected -->
        <div *ngIf="deviceCount == 0" class="row">
            <p>{{ "NO_DEVICES" | translate }}</p>
        </div>
        <!-- Else if connected devices > 0 -->
        <div *ngIf="deviceCount > 0" class="row">
            <table class="table table-borderless table-hover table-responsive">
                <thead class="border-bottom">
                    <tr>
                        <th style="width: 1%"></th>
                        <th>{{ "SERIAL_NUMBER" | translate }}</th>
                        <th>{{ "DEVICE_NAME" | translate }}</th>
                        <th>{{ "DEVICE_TYPE" | translate }}</th>
                        <th>{{ "DEVICE_LAST_CONTACT" | translate }}</th>
                        <th>{{ "DEVICE_STATUS" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <ng-container *ngFor="let dev of resident.devices; let i = index"> -->
                    <tr
                        class="cursorPointer"
                        *ngFor="let residentDevice of devicesInfo"
                    >
                        <td></td>
                        <td>{{ residentDevice.devId }}</td>
                        <td>{{ residentDevice.devName }}</td>
                        <td>{{ residentDevice.devType }}</td>
                        <td>
                            {{
                                residentDevice.lastConnection | moment : "l LTS"
                            }}
                        </td>
                        <td>{{ residentDevice.status }}</td>
                    </tr>
                    <!-- </ng-container> -->
                </tbody>
            </table>
        </div>
    </div>
</div>

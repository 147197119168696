<div class="stickyCard">
  <div class="d-flex flex-column">
    <div class="staffNameHeader">
      <div class="font-weight-bold">
        {{ staff.firstName}} {{ staff.familyName }}
      </div>

      <button (click)="initClosingEditCard()" class="unsetStyles">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>

    <div class="form-content ml-4 mb-4" [ngClass]="staff.updateFailed ? 'mt-5' : 'mt-4'">
      <mat-label class="mr-4 font-weight-bold">{{ "PLATFORM_ROLE" | translate }}:</mat-label>

      <mat-form-field appearance="outline">
        <mat-select
         #roleSelect
         [value]="staff.staffRole"
         (selectionChange)="updateStaffRole(roleSelect, staff)"
        >
          <mat-option [value]="0" class="hideMatOptionCheckbox">User</mat-option>
          <mat-option [value]="95" class="hideMatOptionCheckbox">Landing page user</mat-option>
        </mat-select>
      </mat-form-field>

      <p *ngIf="staff.updateFailed" class="text-danger role-select-error">
        {{ "ROLE_UPDATE_ERROR" | translate }}
      </p>
    </div>

    <mat-divider class="mx-3 wd-50"></mat-divider>

    <div class="mx-4">
      <p class="font-weight-bold mt-4 mb-3">{{ "LOCATION_ACCESS" | translate }}:</p>

      <div *ngFor="let added of staff.addedCompanies; let r_id = index" class="d-flex align-items-center mb-4">
        <mat-form-field appearance="outline" class="ml-4">
          <!-- <mat-label>{{ "COMPANY" | translate }}</mat-label> -->
          <mat-select [(value)]="added.company">
            <mat-option
             *ngFor="let customer of customers"
             class="hideMatOptionCheckbox"
             value="{{ customer.companyId }}"
             [disabled]="isCompanyAdded(customer.companyId) && customer.companyId !== added.company"
            >
              {{ customer.company }} ({{ customer.companyId }})
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="ml-4">
          <!-- <mat-label>{{ "ROLE" | translate }}</mat-label> -->
          <mat-select [(value)]="added.role" [disabled]="!added.company">
            <mat-option
             *ngFor="let role of roles; let i = index"
             class="hideMatOptionCheckbox"
             value="{{ role.value }}"
            >
              {{ role.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button
         *ngIf="staff.addedCompanies.length > 1 && added.new"
         type="button"
         class="ml-4 unsetStyles"
         data-dismiss="modal"
         (click)="startRemovingCompany(r_id)"
        >
          <i class="fa-duotone fa-xmark fa-lg"></i>
        </button>

        <button
         *ngIf="staff.addedCompanies.length >= 1 && !added.new"
         class="ml-4 unsetStyles"
         (click)="openDialog(staff, added.company)"
        >
          <i class="fa-duotone fa-trash-can fa-lg"></i>
        </button>
      </div>

      <button class="btn btn-light wd-50" (click)="startAddingCompany()">
        + {{ "ADD_COMPANY" | translate }}
      </button>
    </div>
  </div>
  <button class="btn btn-primary ml-4 mt-5 mr-2 mb-3" (click)="startMovingSelected()">
    {{ "SAVE" | translate }}
  </button>
  <button class="btn mt-5 mb-3" (click)="initClosingEditCard()">
    {{"CANCEL" | translate}}
  </button>
</div>

<div class="col-sm-11 mx-auto">
  <div class="row">
    <h4 class="col">{{ "CameraSupervision" | translate }}</h4>

    <div class="col">
      <button type="button" *ngIf="user.roleLevel > 50" routerLink="/camera-supervision/new-visit" class="btn btn-primary float-right">
        {{ "SCHEDULE" | translate }}
      </button>

      <button
          *ngIf="user.roleLevel === 101 || user.roleLevel === 200"
          type="button"
          class="btn secondaryBtnGrayBg float-right mr-3"
          data-testid="camera-configuration-button"
          routerLink="/camera-configuration"
        >
          <span class="fa-solid fa-gear" style="color: #4f4f4f"></span>
          {{ "CAMERA_CONFIGURATION_LINK" | translate }}
      </button>
    </div>
  </div>

  <hr />

  <div class="row" *ngIf="allVisits" style="margin-bottom: -10px">
    <div class="col-sm-12">
      <div class="form-inline mb-2 mt-3 filterContent" role="toolbar" aria-label="">
        <div class="row ml-1 mr-2">
          <div class="form-container">
            <label class="date-label">
                {{ "REPORT_START_DATE" | translate}}
            </label>

            <mat-form-field appearance="outline" class="dateFilterSelect">
              <input
                placeholder="{{'REPORT_START_DATE' | translate}}"
                matInput [(ngModel)]="filterStartDay"
                [min]="filterMinDate"
                [max]="filterEndDay"
                [matDatepicker]="filterStartDayPicker"
                (dateChange)="onDateChanged()" />
              <mat-datepicker-toggle matSuffix [for]="filterStartDayPicker"></mat-datepicker-toggle>
              <mat-datepicker #filterStartDayPicker></mat-datepicker>
            </mat-form-field>
          </div>

          <p class="mx-2" style="margin-top: 8px">-</p>

          <div class="form-container">
            <label class="date-label">
                {{"REPORT_END_DATE" | translate}}
            </label>

            <mat-form-field appearance="outline" class="dateFilterSelect">
              <input
                placeholder="{{'REPORT_END_DATE' | translate}}"
                matInput [(ngModel)]="filterEndDay"
                [min]="filterStartDay"
                [max]="filterMaxDate"
                [matDatepicker]="filterEndDayPicker"
                (dateChange)="onDateChanged()" />
              <mat-datepicker-toggle matSuffix [for]="filterEndDayPicker"></mat-datepicker-toggle>
              <mat-datepicker #filterEndDayPicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="vertical-line ml-2"></div>

        <div class="ml-3 time-select-group">
          <div class="form-group time-select">
            <label class="time-label">
                {{ "REPORT_START_TIME" | translate}}
            </label>
            <input type="time" class="form-control clock-icon" [(ngModel)]="filterStartTime" (change)="applyFilters()" />
          </div>

          <p class="mx-2" style="margin-top: 30px">-</p>

          <div class="form-group time-select">
            <label class="time-label">
                {{"REPORT_END_TIME" | translate}}
            </label>
            <input type="time" class="form-control clock-icon" [(ngModel)]="filterEndTime" (change)="applyFilters()" />
          </div>
        </div>

        <div class="vertical-line ml-4 mr-3"></div>

        <ng-container *ngFor="let checkbox of weekDayFilterList">
          <mat-checkbox
            class="mb-3"
            [value]="checkbox"
            [checked]="checkbox.isChecked"
            [(ngModel)]="checkbox.isChecked"
            (change)="applyFilters()"> {{ checkbox.title | translate }}
          </mat-checkbox>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="col-sm-12 mb-4">
    <div class="row">
      <p class="mt-2 ml-2" style="font-weight: 500; color: #797979">
        {{ "FILTER_BY" | translate }}:
      </p>

      <div class="form-group ml-3" *ngIf="advancedFilter.location.filterList">
        <ngx-bootstrap-multiselect
            [options]="advancedFilter.location.filterList"
            [settings]="advancedFilter.settings"
            [texts]="advancedFilter.location.texts"
            [(ngModel)]="advancedFilter.location.selectedList"
            (ngModelChange)="applyFilters()"
            name="advancedFilterLocations">
        </ngx-bootstrap-multiselect>
      </div>

      <div class="form-group ml-3" *ngIf="advancedFilter.cameraGroup.filterList">
        <ngx-bootstrap-multiselect
            [options]="advancedFilter.cameraGroup.filterList"
            [settings]="advancedFilter.settings"
            [texts]="advancedFilter.cameraGroup.texts"
            [(ngModel)]="advancedFilter.cameraGroup.selectedList"
            (ngModelChange)="applyFilters()"
            name="advancedFilterCameraGroupName">
        </ngx-bootstrap-multiselect>
      </div>

      <div class="form-group ml-3" *ngIf="advancedFilter.receiverGroup.filterList">
        <ngx-bootstrap-multiselect
            [options]="advancedFilter.receiverGroup.filterList"
            [settings]="advancedFilter.settings"
            [texts]="advancedFilter.receiverGroup.texts"
            [(ngModel)]="advancedFilter.receiverGroup.selectedList"
            (ngModelChange)="applyFilters()"
            name="advancedFilterReceiverGroup">
        </ngx-bootstrap-multiselect>
      </div>

      <!--
      <div class="form-group ml-3" *ngIf="advancedFilter.alertRoute.filterList">
        <ngx-bootstrap-multiselect
            [options]="advancedFilter.alertRoute.filterList"
            [settings]="advancedFilter.settings"
            [texts]="advancedFilter.alertRoute.texts"
            [(ngModel)]="advancedFilter.alertRoute.selectedList"
            (ngModelChange)="applyFilters()"
            name="advancedFilterAlertRoute">
        </ngx-bootstrap-multiselect>
      </div>
      -->

      <div class="form-group ml-3">
        <button class="btn btn-outline-primary btn-sm" (click)="clearFilters()">
          <span class="fa-light fa-xmark"></span>
          {{ "CLEAR_FILTERS" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="allVisits">
    <div>
      <h6>{{ "UPCOMING_VISITS" | translate }}</h6>
      <table *ngIf="allVisits.length > 0" class="table table-hover mt-2" style="background-color: #ffffff">
        <thead>
          <tr>
            <th>
              {{ "VISIT_TYPE" | translate }}
            </th>
            <th>{{ "DAY" | translate }}</th>
            <th>{{ "TIME" | translate }}</th>
            <th>{{ "DATE" | translate }}</th>
            <th>
              {{ "CAMERAGROUP_NAME" | translate }}
            </th>
            <th>
              {{ "RECEIVER_GROUPS" | translate }}
            </th>
            <th>
              {{ "LOCATION" | translate }}
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let visit of allVisits">
            <td>
              <span *ngIf="visit.visitType">
                <span class="fa-light fa-fw fa-lg" [ngClass]="visit.visitType | cameraVisitIcon"></span>
                {{ visit.visitType | translate }}
              </span>
            </td>
            <td class="capitalize">
              {{ visit.startDate | moment : "dddd" }}
            </td>
            <td>{{ visit | function : getVisitDurationTimes }}</td>
            <td>{{ visit.startDate | moment : "l" }}</td>
            <td>
                {{ visit.cameraGroupName }}
                <li *ngFor="let id of visit.discoveryNames" class="ml-2">
                    {{ id }}
                </li>
            </td>
            <td>
                <ng-container *ngIf="!visit.userGroups || visit.userGroups.length === 0">
                    <p class="m-0">-</p>
                </ng-container>

                <ng-container *ngIf="visit.userGroups && visit.userGroups.length === 1;else elseBlock">
                    <p class="m-0">{{ visit.userGroups[0] | function : getUserGroupName : advancedFilter.receiverGroup.filterList }}</p>
                </ng-container>

                <ng-template  #elseBlock>
                  <li *ngFor="let groupId of visit.userGroups">
                    {{ groupId | function : getUserGroupName : advancedFilter.receiverGroup.filterList }}
                  </li>
                </ng-template>
            </td>
            <td>{{ visit.locationId | visitLocation : advancedFilter.location.filterList }}</td>
            <td>
              <div *ngIf="!visit.toBeDeleted && user.roleLevel > 50">
                <span class="cursorPointer fa-light fa-lg fa-fw fa-trash-alt" (click)="visit.toBeDeleted = true"></span>
              </div>
              <div *ngIf="visit.toBeDeleted">
                {{ "CONFIRM_VISIT_DELETE" | translate }}
                <span class="cursorPointer fa-light fa-fw fa-xmark fa-lg text-danger" (click)="visit.toBeDeleted = false"></span>
                <span class="cursorPointer fal fa-fw fa-lg fa-check text-success" (click)="deleteCameraVisit(visit)"></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p *ngIf="allVisits.length === 0" class="ml-3 mt-2">
        {{ "NO_VISITS" | translate }}
      </p>
    </div>
  </div>
  <div *ngIf="!allVisits">
    <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg" />
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { DeviceStatus } from 'app/models/devicestatus.model';

type DeviceStatusCode = "ONLINE" | "OFFLINE_UNDER_24H" | "OFFLINE" | "LOW_BATTERY" | "SERVICE" | "LOST" | "EVERON_INVENTORY" | "CUSTOMER_INVENTORY";

@Pipe({
  name: "deviceStatusSelector",
  pure: true,
  standalone: true,
})
export class DeviceStatusSelectorPipe implements PipeTransform {
  transform(value: DeviceStatusCode): DeviceStatus {
    let status: DeviceStatus = null;

    switch (value) {
      case "ONLINE":
        status = "online";
        break;
      case "OFFLINE_UNDER_24H":
        status = "offlineUnder24h";
        break;
      case "OFFLINE":
        status = "offline";
        break;
      case "LOW_BATTERY":
        status = "lowBattery";
        break;
      case "SERVICE":
        status = "service";
        break;
      case "LOST":
        status = "lost";
        break;
      case "EVERON_INVENTORY":
        status = "everonInventory";
        break;
      case "CUSTOMER_INVENTORY":
        status = "inventory";
        break;
    }

    return status;
  }
}

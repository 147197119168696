import { Component, Input, EventEmitter, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { TextFormatComponent } from "app/components/text-format/text-format.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ApiService } from "app/services/api.service";
import { ReportPipesModule } from "app/components/report/report-pipes";
import { FormsModule } from "@angular/forms";
import {
  MomentPipe,
  TransformToLangCodePipe,
  TranslateAlarmCategoryPipe,
  TranslateAlarmReasonPipe,
} from "app/pipes/pipes";
import { IAlarmReasonTranslations } from "app/models/alarm-reasons.model";

@Component({
  selector: 'app-report-alert-table',
  templateUrl: './report-alert-table.component.html',
  styleUrls: ['../../report.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatTooltipModule,
    InfiniteScrollModule,
    TextFormatComponent,
    TranslateModule,
    MomentPipe,
    TranslateAlarmReasonPipe,
    TranslateAlarmCategoryPipe,
    TransformToLangCodePipe,
    ReportPipesModule,
  ],
})
export class ReportAlertTableComponent {

  constructor(private api: ApiService) { }

  @Input() alerts: any[];
  @Input() infiniteScrollDisabled: boolean;
  @Input() alarmReasonTranslations: IAlarmReasonTranslations;

  @Output() submitAlertNote = new EventEmitter<any>();
  @Output() loadNextAlerts = new EventEmitter<void>();

  submitAlertNoteHandler(alert: any): void {
    this.submitAlertNote.emit(alert);
  }

  expandRow(alert: any): void {
    alert.expanded = !alert.expanded ?? true;

    if ((alert.deviceType == "34-2" || alert.deviceType == "34-3") && alert.expanded) {
      this.loadAnnaPerennaImage(alert);
    }
  }

  loadAnnaPerennaImage(alert: any): void {
    alert.showAPImage = false;
    let user = JSON.parse(localStorage.getItem("user"));

    // Load data for the alert
    this.api.getAnnaPerennaAlert(user.serverId + alert.taskId).subscribe(
      (res) => {
        if (res) {
          alert.APImageData = res.imageData;
        }
      }
    );
  }
}

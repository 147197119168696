<div *ngIf="lockBeaconConfig">
    <div *ngIf="user && user.roleLevel === 200" class="mb-3">
        <strong>DoorID: {{ lockBeaconConfig.doorId }}</strong>
    </div>
    <form
        [formGroup]="lockBeaconForm"
        (ngSubmit)="submitLockBeaconSettings()"
        [hidden]="isOldDevice()"
    >
        <div class="form-group">
            <label>{{ "CLOSE_DOOR_FOR_UNKNOWN" | translate }}</label>
            <section>
                <mat-slide-toggle
                    formControlName="closeDoorForUnknownButton"
                    (change)="closeDoorForUnknownButtonChange($event)"
                >
                </mat-slide-toggle>
            </section>
        </div>
        <div class="form-group">
            <label>{{ "OPEN_DOOR_FOR_UNKNOWN" | translate }}</label>
            <section>
                <mat-slide-toggle
                    formControlName="openDoorForUnknownButton"
                    (change)="openDoorForUnknownButtonChange($event)"
                >
                </mat-slide-toggle>
            </section>
        </div>
        <div class="form-group">
            <label>{{ "ALERT_WHEN_BUTTON_IN_LOCK_AREA" | translate }}</label>
            <section>
                <mat-slide-toggle formControlName="alertWhenButtonInLockArea">
                </mat-slide-toggle>
            </section>
        </div>
    </form>
    <form #allowedDeniedForm="ngForm">
        <div>
            <strong>{{ "ALLOWED_DEVICES" | translate }}</strong>
            <div class="mt-3 mb-3">
                <ngx-bootstrap-multiselect
                    class="d-inline"
                    [texts]="multiselect.textDev"
                    [options]="multiselect.allowedDeviceList"
                    [settings]="multiselect.settingsDev"
                    [(ngModel)]="addNewAllowedDeviceArray"
                    name="addNewAllowedDeviceDrp"
                ></ngx-bootstrap-multiselect>
                <button
                    type="button"
                    class="btn btn-outline-primary d-inline ml-3"
                    (click)="addNewAllowedDevice()"
                >
                    <span class="fal fa-fw fa-plus"></span>
                    {{ "ADD_NEW" | translate }}
                </button>
            </div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th style="width: 25%">{{ "NAME" | translate }}</th>
                        <th style="width: 25%">{{ "WEEKDAYS" | translate }}</th>
                        <th style="width: 25%">
                            {{ "SATURDAYS" | translate }}
                        </th>
                        <th style="width: 25%">{{ "SUNDAYS" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="
                            let allowedDevice of lockBeaconConfig.allowedDevices
                        "
                        [ngClass]="{ lostDevice: allowedDevice.lostDevice }"
                    >
                        <td>
                            <div>
                                {{
                                    allowedDevice.name
                                        ? allowedDevice.name +
                                          " (" +
                                          allowedDevice.id +
                                          ")"
                                        : allowedDevice.id
                                }}
                            </div>
                            <div class="mt-1">
                                <span
                                    class="fal fa-fw fa-trash-alt cursorPointerHover mr-3"
                                    (click)="
                                        removeAllowedDeviceFromList(
                                            allowedDevice
                                        )
                                    "
                                    matTooltip="{{
                                        'REMOVE_DEVICE' | translate
                                    }}"
                                    [matTooltipPosition]="'above'"
                                ></span>
                                <span
                                    *ngIf="!isOldDevice()"
                                    class="fal fa-fw cursorPointerHover mr-3"
                                    (click)="
                                        allowedDevice.lostDevice =
                                            !allowedDevice.lostDevice
                                    "
                                    [ngClass]="
                                        allowedDevice.lostDevice
                                            ? 'fa-check'
                                            : 'fa-ban'
                                    "
                                    matTooltip="{{
                                        allowedDevice.lostDevice
                                            ? ('MARK_AS_FOUND' | translate)
                                            : ('MARK_AS_LOST' | translate)
                                    }}"
                                    [matTooltipPosition]="'above'"
                                ></span>
                                <span
                                    class="fal fa-fw fa-edit cursorPointerHover mr-1"
                                    (click)="
                                        allowedDevice.editMode =
                                            !allowedDevice.editMode
                                    "
                                    matTooltip="{{ 'EDIT_TIMES' | translate }}"
                                    [matTooltipPosition]="'above'"
                                ></span>
                                <span
                                    *ngIf="
                                        allowedDevice.validityTimes.length <
                                            2 && !isOldDevice()
                                    "
                                    (click)="addNewTimeToDevice(allowedDevice)"
                                    class="fal fa-fw fa-plus cursorPointerHover"
                                    matTooltip="{{
                                        'ADD_NEW_TIME' | translate
                                    }}"
                                    [matTooltipPosition]="'above'"
                                ></span>
                                <span
                                    *ngIf="
                                        allowedDevice.validityTimes.length ===
                                            2 && !isOldDevice()
                                    "
                                    class="fal fa-fw fa-minus-circle cursorPointerHover"
                                    (click)="
                                        allowedDevice.validityTimes.splice(1, 1)
                                    "
                                    matTooltip="{{ 'REMOVE_TIME' | translate }}"
                                    [matTooltipPosition]="'above'"
                                ></span>
                            </div>
                        </td>
                        <td>
                            <div
                                *ngFor="
                                    let times of allowedDevice.validityTimes;
                                    let i = index
                                "
                                class="mb-2"
                            >
                                <div *ngIf="!allowedDevice.editMode">
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.weekDaysFrom,
                                                times.weekDaysTo
                                            ) === '0'
                                        "
                                        >{{ "ALWAYS" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.weekDaysFrom,
                                                times.weekDaysTo
                                            ) === '1'
                                        "
                                        >{{ "NEVER" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.weekDaysFrom,
                                                times.weekDaysTo
                                            ) === '2'
                                        "
                                        >{{ times.weekDaysFrom }} -
                                        {{ times.weekDaysTo }}</span
                                    >
                                </div>
                                <div
                                    *ngIf="allowedDevice.editMode"
                                    class="form-inline timeEditInputs"
                                >
                                    <div>
                                        <mat-form-field>
                                            <mat-label>{{
                                                "SELECT_ACTIVE_TIME" | translate
                                            }}</mat-label>
                                            <mat-select
                                                [value]="
                                                    convertTimesToType(
                                                        times.weekDaysFrom,
                                                        times.weekDaysTo
                                                    )
                                                "
                                                (selectionChange)="
                                                    changeValidityTime(
                                                        $event,
                                                        times,
                                                        'weekDays'
                                                    )
                                                "
                                            >
                                                <mat-option value="0">{{
                                                    "ALWAYS" | translate
                                                }}</mat-option>
                                                <mat-option value="1">{{
                                                    "NEVER" | translate
                                                }}</mat-option>
                                                <mat-option value="2">{{
                                                    "DURING_TIMES" | translate
                                                }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        *ngIf="
                                            convertTimesToType(
                                                times.weekDaysFrom,
                                                times.weekDaysTo
                                            ) === '2'
                                        "
                                        class="mb-3"
                                    >
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.weekDaysFrom" [name]="'allowedWeekDaysFrom' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="
                                                allowedWeekDaysFrom
                                            "
                                            [format]="24"
                                            [(ngModel)]="times.weekDaysFrom"
                                            [name]="
                                                'allowedWeekDaysFrom' +
                                                i +
                                                allowedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #allowedWeekDaysFrom
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                        <span class="mr-2 ml-2">-</span>
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.weekDaysTo" [name]="'allowedWeekDaysTo' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="allowedWeekDaysTo"
                                            [format]="24"
                                            [(ngModel)]="times.weekDaysTo"
                                            [name]="
                                                'allowedWeekDaysTo' +
                                                i +
                                                allowedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #allowedWeekDaysTo
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div
                                *ngFor="
                                    let times of allowedDevice.validityTimes;
                                    let i = index
                                "
                                class="mb-2"
                            >
                                <div *ngIf="!allowedDevice.editMode">
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.saturdaysFrom,
                                                times.saturdaysTo
                                            ) === '0'
                                        "
                                        >{{ "ALWAYS" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.saturdaysFrom,
                                                times.saturdaysTo
                                            ) === '1'
                                        "
                                        >{{ "NEVER" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.saturdaysFrom,
                                                times.saturdaysTo
                                            ) === '2'
                                        "
                                        >{{ times.saturdaysFrom }} -
                                        {{ times.saturdaysTo }}</span
                                    >
                                </div>
                                <div
                                    *ngIf="allowedDevice.editMode"
                                    class="form-inline timeEditInputs"
                                >
                                    <div>
                                        <mat-form-field>
                                            <mat-label>{{
                                                "SELECT_ACTIVE_TIME" | translate
                                            }}</mat-label>
                                            <mat-select
                                                [value]="
                                                    convertTimesToType(
                                                        times.saturdaysFrom,
                                                        times.saturdaysTo
                                                    )
                                                "
                                                (selectionChange)="
                                                    changeValidityTime(
                                                        $event,
                                                        times,
                                                        'saturdays'
                                                    )
                                                "
                                            >
                                                <mat-option value="0">{{
                                                    "ALWAYS" | translate
                                                }}</mat-option>
                                                <mat-option value="1">{{
                                                    "NEVER" | translate
                                                }}</mat-option>
                                                <mat-option value="2">{{
                                                    "DURING_TIMES" | translate
                                                }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        *ngIf="
                                            convertTimesToType(
                                                times.saturdaysFrom,
                                                times.saturdaysTo
                                            ) === '2'
                                        "
                                        class="mb-3"
                                    >
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.saturdaysFrom" [name]="'allowedSaturdaysfrom' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="
                                                allowedSaturdaysfrom
                                            "
                                            [format]="24"
                                            [(ngModel)]="times.saturdaysFrom"
                                            [name]="
                                                'allowedSaturdaysfrom' +
                                                i +
                                                allowedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #allowedSaturdaysfrom
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                        <span class="mr-2 ml-2">-</span>
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.saturdaysTo" [name]="'allowedSaturdaysTo' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="allowedSaturdaysTo"
                                            [format]="24"
                                            [(ngModel)]="times.saturdaysTo"
                                            [name]="
                                                'allowedSaturdaysTo' +
                                                i +
                                                allowedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #allowedSaturdaysTo
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div
                                *ngFor="
                                    let times of allowedDevice.validityTimes;
                                    let i = index
                                "
                                class="mb-2"
                            >
                                <div *ngIf="!allowedDevice.editMode">
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.sundaysFrom,
                                                times.sundaysTo
                                            ) === '0'
                                        "
                                        >{{ "ALWAYS" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.sundaysFrom,
                                                times.sundaysTo
                                            ) === '1'
                                        "
                                        >{{ "NEVER" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.sundaysFrom,
                                                times.sundaysTo
                                            ) === '2'
                                        "
                                        >{{ times.sundaysFrom }} -
                                        {{ times.sundaysTo }}</span
                                    >
                                </div>
                                <div
                                    *ngIf="allowedDevice.editMode"
                                    class="form-inline timeEditInputs"
                                >
                                    <div>
                                        <mat-form-field>
                                            <mat-label>{{
                                                "SELECT_ACTIVE_TIME" | translate
                                            }}</mat-label>
                                            <mat-select
                                                [value]="
                                                    convertTimesToType(
                                                        times.sundaysFrom,
                                                        times.sundaysTo
                                                    )
                                                "
                                                (selectionChange)="
                                                    changeValidityTime(
                                                        $event,
                                                        times,
                                                        'sundays'
                                                    )
                                                "
                                            >
                                                <mat-option value="0">{{
                                                    "ALWAYS" | translate
                                                }}</mat-option>
                                                <mat-option value="1">{{
                                                    "NEVER" | translate
                                                }}</mat-option>
                                                <mat-option value="2">{{
                                                    "DURING_TIMES" | translate
                                                }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        *ngIf="
                                            convertTimesToType(
                                                times.sundaysFrom,
                                                times.sundaysTo
                                            ) === '2'
                                        "
                                        class="mb-3"
                                    >
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.sundaysFrom" [name]="'allowedSundaysFrom' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="allowedSundaysFrom"
                                            [format]="24"
                                            [(ngModel)]="times.sundaysFrom"
                                            [name]="
                                                'allowedSundaysFrom' +
                                                i +
                                                allowedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #allowedSundaysFrom
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                        <span class="mr-2 ml-2">-</span>
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.sundaysTo" [name]="'allowedSundaysTo' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="allowedSundaysTo"
                                            [format]="24"
                                            [(ngModel)]="times.sundaysTo"
                                            [name]="
                                                'allowedSundaysTo' +
                                                i +
                                                allowedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #allowedSundaysTo
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-3">
            <strong>{{ "DENIED_DEVICES" | translate }}</strong>
            <div class="mt-3 mb-3">
                <ngx-bootstrap-multiselect
                    class="d-inline"
                    [texts]="multiselect.textDev"
                    [options]="multiselect.deniedDeviceList"
                    [settings]="multiselect.settingsDev"
                    [(ngModel)]="addNewDeniedDeviceArray"
                    [ngModelOptions]="{ standalone: true }"
                    name="addNewDeniedDeviceDrp"
                ></ngx-bootstrap-multiselect>
                <button
                    type="button"
                    class="btn btn-outline-primary d-inline ml-3"
                    (click)="addNewDeniedDevice()"
                >
                    <span class="fal fa-fw fa-plus"></span>
                    {{ "ADD_NEW" | translate }}
                </button>
            </div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th style="width: 25%">{{ "NAME" | translate }}</th>
                        <th style="width: 25%">{{ "WEEKDAYS" | translate }}</th>
                        <th style="width: 25%">
                            {{ "SATURDAYS" | translate }}
                        </th>
                        <th style="width: 25%">{{ "SUNDAYS" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="
                            let deniedDevice of lockBeaconConfig.deniedDevices
                        "
                        [ngClass]="{ lostDevice: deniedDevice.lostDevice }"
                    >
                        <td>
                            <div>
                                {{
                                    deniedDevice.name
                                        ? deniedDevice.name +
                                          " (" +
                                          deniedDevice.id +
                                          ")"
                                        : deniedDevice.id
                                }}
                            </div>
                            <div class="mt-1">
                                <span
                                    class="fal fa-fw fa-trash-alt cursorPointerHover mr-3"
                                    (click)="
                                        removeDeniedDeviceFromList(deniedDevice)
                                    "
                                    matTooltip="{{
                                        'REMOVE_DEVICE' | translate
                                    }}"
                                    [matTooltipPosition]="'above'"
                                ></span>
                                <span
                                    *ngIf="!isOldDevice()"
                                    class="fal fa-fw cursorPointerHover mr-3"
                                    (click)="
                                        deniedDevice.lostDevice =
                                            !deniedDevice.lostDevice
                                    "
                                    [ngClass]="
                                        deniedDevice.lostDevice
                                            ? 'fa-check'
                                            : 'fa-ban'
                                    "
                                    matTooltip="{{
                                        deniedDevice.lostDevice
                                            ? ('MARK_AS_FOUND' | translate)
                                            : ('MARK_AS_LOST' | translate)
                                    }}"
                                    [matTooltipPosition]="'above'"
                                ></span>
                                <span
                                    class="fal fa-fw fa-edit cursorPointerHover mr-1"
                                    (click)="
                                        deniedDevice.editMode =
                                            !deniedDevice.editMode
                                    "
                                    matTooltip="{{ 'EDIT_TIMES' | translate }}"
                                    [matTooltipPosition]="'above'"
                                ></span>
                                <span
                                    *ngIf="
                                        deniedDevice.validityTimes.length < 2 &&
                                        !isOldDevice()
                                    "
                                    (click)="addNewTimeToDevice(deniedDevice)"
                                    class="fal fa-fw fa-plus cursorPointerHover mr-1"
                                    matTooltip="{{
                                        'ADD_NEW_TIME' | translate
                                    }}"
                                    [matTooltipPosition]="'above'"
                                ></span>
                                <span
                                    *ngIf="
                                        deniedDevice.validityTimes.length ===
                                            2 && !isOldDevice()
                                    "
                                    class="fal fa-fw fa-minus-circle cursorPointerHover"
                                    (click)="
                                        deniedDevice.validityTimes.splice(1, 1)
                                    "
                                    matTooltip="{{ 'REMOVE_TIME' | translate }}"
                                    [matTooltipPosition]="'above'"
                                ></span>
                            </div>
                        </td>
                        <td>
                            <div
                                *ngFor="
                                    let times of deniedDevice.validityTimes;
                                    let i = index
                                "
                                class="mb-2"
                            >
                                <div *ngIf="!deniedDevice.editMode">
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.weekDaysFrom,
                                                times.weekDaysTo
                                            ) === '0'
                                        "
                                        >{{ "ALWAYS" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.weekDaysFrom,
                                                times.weekDaysTo
                                            ) === '1'
                                        "
                                        >{{ "NEVER" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.weekDaysFrom,
                                                times.weekDaysTo
                                            ) === '2'
                                        "
                                        >{{ times.weekDaysFrom }} -
                                        {{ times.weekDaysTo }}</span
                                    >
                                </div>
                                <div
                                    *ngIf="deniedDevice.editMode"
                                    class="form-inline timeEditInputs"
                                >
                                    <div>
                                        <mat-form-field>
                                            <mat-label>{{
                                                "SELECT_ACTIVE_TIME" | translate
                                            }}</mat-label>
                                            <mat-select
                                                [value]="
                                                    convertTimesToType(
                                                        times.weekDaysFrom,
                                                        times.weekDaysTo
                                                    )
                                                "
                                                (selectionChange)="
                                                    changeValidityTime(
                                                        $event,
                                                        times,
                                                        'weekDays'
                                                    )
                                                "
                                            >
                                                <mat-option value="0">{{
                                                    "ALWAYS" | translate
                                                }}</mat-option>
                                                <mat-option value="1">{{
                                                    "NEVER" | translate
                                                }}</mat-option>
                                                <mat-option value="2">{{
                                                    "DURING_TIMES" | translate
                                                }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        *ngIf="
                                            convertTimesToType(
                                                times.weekDaysFrom,
                                                times.weekDaysTo
                                            ) === '2'
                                        "
                                        class="mb-3"
                                    >
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.weekDaysFrom" [name]="'deniedWeekDaysFrom' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="deniedWeekDaysFrom"
                                            [format]="24"
                                            [(ngModel)]="times.weekDaysFrom"
                                            [name]="
                                                'deniedWeekDaysFrom' +
                                                i +
                                                deniedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #deniedWeekDaysFrom
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                        <span class="mr-2 ml-2">-</span>
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.weekDaysTo" [name]="'deniedWeekDaysTo' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="deniedWeekDaysTo"
                                            [format]="24"
                                            [(ngModel)]="times.weekDaysTo"
                                            [name]="
                                                'deniedWeekDaysTo' +
                                                i +
                                                deniedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #deniedWeekDaysTo
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div
                                *ngFor="
                                    let times of deniedDevice.validityTimes;
                                    let i = index
                                "
                                class="mb-2"
                            >
                                <div *ngIf="!deniedDevice.editMode">
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.saturdaysFrom,
                                                times.saturdaysTo
                                            ) === '0'
                                        "
                                        >{{ "ALWAYS" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.saturdaysFrom,
                                                times.saturdaysTo
                                            ) === '1'
                                        "
                                        >{{ "NEVER" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.saturdaysFrom,
                                                times.saturdaysTo
                                            ) === '2'
                                        "
                                        >{{ times.saturdaysFrom }} -
                                        {{ times.saturdaysTo }}</span
                                    >
                                </div>
                                <div
                                    *ngIf="deniedDevice.editMode"
                                    class="form-inline timeEditInputs"
                                >
                                    <div>
                                        <mat-form-field>
                                            <mat-label>{{
                                                "SELECT_ACTIVE_TIME" | translate
                                            }}</mat-label>
                                            <mat-select
                                                [value]="
                                                    convertTimesToType(
                                                        times.saturdaysFrom,
                                                        times.saturdaysTo
                                                    )
                                                "
                                                (selectionChange)="
                                                    changeValidityTime(
                                                        $event,
                                                        times,
                                                        'saturdays'
                                                    )
                                                "
                                            >
                                                <mat-option value="0">{{
                                                    "ALWAYS" | translate
                                                }}</mat-option>
                                                <mat-option value="1">{{
                                                    "NEVER" | translate
                                                }}</mat-option>
                                                <mat-option value="2">{{
                                                    "DURING_TIMES" | translate
                                                }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        *ngIf="
                                            convertTimesToType(
                                                times.saturdaysFrom,
                                                times.saturdaysTo
                                            ) === '2'
                                        "
                                        class="mb-3"
                                    >
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.saturdaysFrom" [name]="'deniedSaturdaysfrom' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="
                                                deniedSaturdaysfrom
                                            "
                                            [format]="24"
                                            [(ngModel)]="times.saturdaysFrom"
                                            [name]="
                                                'deniedSaturdaysfrom' +
                                                i +
                                                deniedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #deniedSaturdaysfrom
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                        <span class="mr-2 ml-2">-</span>
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.saturdaysTo" [name]="'deniedSaturdaysTo' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="deniedSaturdaysTo"
                                            [format]="24"
                                            [(ngModel)]="times.saturdaysTo"
                                            [name]="
                                                'deniedSaturdaysTo' +
                                                i +
                                                deniedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #deniedSaturdaysTo
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div
                                *ngFor="
                                    let times of deniedDevice.validityTimes;
                                    let i = index
                                "
                                class="mb-2"
                            >
                                <div *ngIf="!deniedDevice.editMode">
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.sundaysFrom,
                                                times.sundaysTo
                                            ) === '0'
                                        "
                                        >{{ "ALWAYS" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.sundaysFrom,
                                                times.sundaysTo
                                            ) === '1'
                                        "
                                        >{{ "NEVER" | translate }}</span
                                    >
                                    <span
                                        *ngIf="
                                            convertTimesToType(
                                                times.sundaysFrom,
                                                times.sundaysTo
                                            ) === '2'
                                        "
                                        >{{ times.sundaysFrom }} -
                                        {{ times.sundaysTo }}</span
                                    >
                                </div>
                                <div
                                    *ngIf="deniedDevice.editMode"
                                    class="form-inline timeEditInputs"
                                >
                                    <div>
                                        <mat-form-field>
                                            <mat-label>{{
                                                "SELECT_ACTIVE_TIME" | translate
                                            }}</mat-label>
                                            <mat-select
                                                [value]="
                                                    convertTimesToType(
                                                        times.sundaysFrom,
                                                        times.sundaysTo
                                                    )
                                                "
                                                (selectionChange)="
                                                    changeValidityTime(
                                                        $event,
                                                        times,
                                                        'sundays'
                                                    )
                                                "
                                            >
                                                <mat-option value="0">{{
                                                    "ALWAYS" | translate
                                                }}</mat-option>
                                                <mat-option value="1">{{
                                                    "NEVER" | translate
                                                }}</mat-option>
                                                <mat-option value="2">{{
                                                    "DURING_TIMES" | translate
                                                }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        *ngIf="
                                            convertTimesToType(
                                                times.sundaysFrom,
                                                times.sundaysTo
                                            ) === '2'
                                        "
                                        class="mb-3"
                                    >
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.sundaysFrom" [name]="'deniedSundaysFrom' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="deniedSundaysFrom"
                                            [format]="24"
                                            [(ngModel)]="times.sundaysFrom"
                                            [name]="
                                                'deniedSundaysFrom' +
                                                i +
                                                deniedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #deniedSundaysFrom
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                        <span class="mr-2 ml-2">-</span>
                                        <!--<input pattern="^([0-9]{2}):([0-9]{2})$" type="text" class="form-control form-control-sm" [(ngModel)]="times.sundaysTo" [name]="'deniedSundaysTo' + i">-->
                                        <input
                                            class="form-control form-control-sm"
                                            [ngxTimepicker]="deniedSundaysTo"
                                            [format]="24"
                                            [(ngModel)]="times.sundaysTo"
                                            [name]="
                                                'deniedSundaysTo' +
                                                i +
                                                deniedDevice.id
                                            "
                                        />
                                        <ngx-material-timepicker
                                            #deniedSundaysTo
                                            [minutesGap]="10"
                                        ></ngx-material-timepicker>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>
    <div class="form-group mt-3">
        <div class="alert alert-success" *ngIf="saveSuccess">
            {{ "DEVICE_INFORMATION_UPDATED" | translate }}
        </div>
        <div class="alert alert-danger" *ngIf="saveError">
            {{ "ERROR" | translate }}
        </div>
        <button
            [disabled]="allowedDeniedForm.form.invalid"
            type="button"
            class="btn btn-primary"
            (click)="submitLockBeaconSettings()"
        >
            {{ "SAVE" | translate }}
        </button>
    </div>
</div>

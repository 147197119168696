import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ApiService } from '../../../services/api.service';
import { HelperService } from 'app/services/helper.service';
import { DateAdapter } from '@angular/material/core';
import { Device } from '../../../models/device.model';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';

interface ITile {
  deviceType: string,
  count: number,
  cols: number,
  rows: number,
}

@Component({
    selector: 'app-device-report',
    templateUrl: './device-report.component.html',
    styleUrls: ['./device-report.component.css'],
    standalone: true,
    imports: [MatGridListModule, NgIf, NgFor, MatCardModule, MatButtonModule, MatIconModule, TranslateModule]
})
export class DeviceReportComponent implements OnInit {

  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private helper: HelperService
  ) { }

  //Declare variables
  longDeviceList: ITile[] = [];
  shortDeviceList: ITile[] = [];
  total: number = 0;
  show: boolean = false;
  dataLoaded: boolean;

  parseDevices(devices: Device[]): void {

    //get device types
    let deviceTypes = devices.map(item => item.deviceType).filter((value, index, self) =>
      self.indexOf(value) === index);

    for (let i = 0; i < deviceTypes.length; i++) {
      this.longDeviceList.push(
        {
          deviceType: this.helper.getDeviceType(deviceTypes[i]),
          count: devices.filter(x => x.deviceType === deviceTypes[i]).length, //Count occurence of each device type
          cols: 1,
          rows: 1,
        })
    }
    this.longDeviceList.sort((a, b) => (b.count - a.count));

    //Sets last element's column span to two if number of device types is uneven. Styling purpose.
    deviceTypes.length % 2 !== 0 ? this.longDeviceList[this.longDeviceList.length - 1].cols = 2 : void (0);

    this.shortDeviceList = this.longDeviceList.slice(0, 6);
    this.total = devices.length;
  }

  showMoreOrLess(): void {
    this.show = !this.show;
  }

  ngOnInit(): void {
    this.dataLoaded = false;

    this.api.getDevices({ fullInfo: false }).subscribe((res) => {
      if (res != null) {
        this.parseDevices(res);
        this.dataLoaded = true;
      }
      else {
        this.dataLoaded = true;
      }
    })
  }
}

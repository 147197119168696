<h3>{{ "COMPANY_GROUPS" | translate }}</h3>

<div class="row pb-3">
    <div class="col-sm-5">
        <div class="input-group-sm mt-3">
            <input
                type="text"
                class="form-control"
                style="width: 75%"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="{{ 'SEARCH' | translate }}"
                (keyup)="search($event)"
            />
        </div>
    </div>
    <div class="col-sm-7 justify-content-center px-5">
        <div
            *ngIf="successMessage"
            class="alert alert-success"
            role="alert"
            [@fadeOut]
        >
            <!--Success! The new company group have been added-->
            {{ successMessage | translate }}
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-5">
        <div>
            <button
                class="btn btn-primary mb-3"
                (click)="
                    showAddCompanyGroupForm.next(true);
                    showEditCompanyGroupForm.next(false)
                "
            >
                <span class="fa-light fa-fw fa-plus"></span>
                {{ "ADD_GROUP" | translate }}
            </button>
        </div>
        <div class="card mb-3">
            <div
                class="card-header-white"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important"
            >
                <h6 class="white-header-text">
                    {{ "COMPANY_GROUPS" | translate }}
                </h6>
            </div>

            <mat-accordion displayMode="flat" multi class="mat-table">
                <mat-expansion-panel
                    *ngFor="
                        let listItem of filteredCompanyGroupList;
                        let i = index
                    "
                    [expanded]="listItem.expanded"
                    [ngStyle]="{
                        'border-top':
                            i > 0 ? '1px solid rgba(0, 0, 0, .1)' : 'none'
                    }"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title
                            [innerHTML]="
                                listItem.companyGroup.name
                                    | highlight : searchFilter
                            "
                        ></mat-panel-title>
                    </mat-expansion-panel-header>

                    <h6 class="mt-3 ml-2">
                        {{ "INFORMATION" | translate }}

                        <small
                            *ngIf="!listItem.companyGroup.populateStaffEnabled"
                            class="fa-solid fa-user-xmark float-right mr-3 mt-2"
                            ngbTooltip="{{
                                'POPULATE_STAFF_DISABLED' | translate
                            }}"
                            placement="top"
                            [container]="'body'"
                        ></small>

                        <hr class="my-2" />
                    </h6>

                    <div class="mt-3">
                        <div class="float-right">
                            <button
                                class="btn secondaryBtnWhiteBg"
                                (click)="
                                    editCompanyGroupClickEvent(
                                        listItem.companyGroup
                                    )
                                "
                            >
                                <span class="fa fa-edit"></span
                                >{{ "MODIFY" | translate }}
                            </button>
                        </div>

                        <div class="ml-3">
                            <div *ngIf="listItem.companyGroup.address">
                                <strong>{{ "ADDRESS" | translate }}</strong
                                >:
                                <p
                                    [innerHTML]="
                                        listItem.companyGroup.address
                                            | highlight : searchFilter
                                    "
                                ></p>
                            </div>
                            <div
                                *ngIf="listItem.companyGroup.postOffice"
                                class="mt-1"
                            >
                                <strong>{{ "POSTOFFICE" | translate }}</strong
                                >:
                                <p
                                    [innerHTML]="
                                        listItem.companyGroup.postOffice
                                            | highlight : searchFilter
                                    "
                                ></p>
                            </div>
                            <div
                                *ngIf="listItem.companyGroup.postCode"
                                class="mt-1"
                            >
                                <strong>{{ "POSTCODE" | translate }}</strong
                                >:
                                <p
                                    [innerHTML]="
                                        listItem.companyGroup.postCode
                                            | highlight : searchFilter
                                    "
                                ></p>
                            </div>
                            <div
                                *ngIf="listItem.companyGroup.country"
                                class="mt-1"
                            >
                                <strong>{{ "COUNTRY" | translate }}</strong
                                >:
                                <p
                                    [innerHTML]="
                                        getCountry(
                                            listItem.companyGroup.country
                                        ) | highlight : searchFilter
                                    "
                                ></p>
                            </div>
                            <div
                                *ngIf="listItem.companyGroup.organizationNumber"
                                class="mt-1"
                            >
                                <strong>{{
                                    "ORGANIZATION_NUMBER" | translate
                                }}</strong
                                >:
                                <p
                                    [innerHTML]="
                                        listItem.companyGroup.organizationNumber
                                            | highlight : searchFilter
                                    "
                                ></p>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <h6 class="mt-3 ml-2">
                            {{ "COMPANIES" | translate }}
                            <hr class="my-2" />
                        </h6>

                        <div class="mt-3">
                            <ul
                                *ngIf="
                                    listItem.companyGroup.companies.length === 0
                                "
                            >
                                {{
                                    "NO_COMPANIES" | translate
                                }}
                            </ul>
                            <ul
                                *ngFor="
                                    let company of listItem.companyGroup
                                        .companies
                                "
                                [innerHTML]="
                                    company.company | highlight : searchFilter
                                "
                            ></ul>
                        </div>
                    </div>
                </mat-expansion-panel>

                <!--Loading icon-->
                <mat-expansion-panel
                    *ngIf="!companyGroupList"
                    [expanded]="true"
                    class="pt-3 pb-5"
                >
                    <img
                        class="fadeLoader logoSmall mx-auto d-block"
                        src="assets/img/loading_logo.svg"
                    />
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

    <div
        class="col-lg-6 stickyCard"
        style="margin-right: auto; margin-left: auto; overflow: hidden"
    >
        <div>
            <company-group-add
                *ngIf="showAddCompanyGroupForm.value"
                [showForm]="showAddCompanyGroupForm"
                (companyGroupAdded)="refreshCompanyGroupList($event)"
            ></company-group-add>
            <company-group-edit
                *ngIf="showEditCompanyGroupForm.value"
                [companyGroup]="companyGroupToEdit"
                [showForm]="showEditCompanyGroupForm"
            >
            </company-group-edit>
        </div>
    </div>
</div>

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IActivityLog, ICameraGroupChangeRecord, CameraGroupChangeRecordField, VISIBLE_DETAILS_FIELDS_COUNT } from 'app/models/activity-log.model';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { IdentityFormatPipe, IncludesPipe, JoinPipe, MomentPipe, SomePipe } from 'app/pipes/pipes';
import { FormatCameraDetailsPipe } from './activity-camera-group-details-pipes';

@Component({
  selector: 'app-activity-camera-group-details',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, IdentityFormatPipe, MomentPipe, IncludesPipe, SomePipe, JoinPipe, FormatCameraDetailsPipe],
  templateUrl: './activity-camera-group-details.component.html',
  styleUrls: ['../activity-details-dialog/activity-details-content-styles.css']
})
export class ActivityCameraGroupDetailsComponent implements OnChanges {
  @Output() closeDialog = new EventEmitter<void>();
  @Input() log: IActivityLog;

  readonly visibleFieldCount = VISIBLE_DETAILS_FIELDS_COUNT;

  cameraGroupChanges: ICameraGroupChangeRecord;
  visibleFields: CameraGroupChangeRecordField[] = [];
  allFields: CameraGroupChangeRecordField[] = [];

  ngOnChanges(): void {
    // Copy the group changes to a local variable to just make the template little more cleaner.
    this.cameraGroupChanges = this.log?.details?.cameraGroupChanges;
    const result = this.getVisibleFields(this.cameraGroupChanges);
    this.visibleFields = [...result.visibleFields]; // Change the visible fields to a new array to trigger change detection.
    this.allFields = [...result.allFields];
  }

  private getVisibleFields(cameraGroupChanges: ICameraGroupChangeRecord): { visibleFields: CameraGroupChangeRecordField[], allFields: CameraGroupChangeRecordField[] } {
    // Return empty array if the object is falsy.
    if (!cameraGroupChanges) {
      return { visibleFields: [], allFields: [] };
    }

    const visibleFields: CameraGroupChangeRecordField[] = [];
    const allFields = Object.keys(cameraGroupChanges).filter(obj => obj) as CameraGroupChangeRecordField[]; // Get the all fields that are not falsy.

    // Camera changes have higher priority, so they are always visible if they exists.
    if (allFields.includes("addedCameras")) {
      visibleFields.push("addedCameras");
    }

    if (allFields.includes("modifiedCameras")) {
      visibleFields.push("modifiedCameras");
    }

    if (allFields.includes("removedCameras")) {
      visibleFields.push("removedCameras");
    }

    for (const field of allFields) {
      this.addVisibleField(field, allFields, visibleFields);
    }

    return { visibleFields, allFields };
  }

  private addVisibleField(field: CameraGroupChangeRecordField, allFields: CameraGroupChangeRecordField[], visibleFields: CameraGroupChangeRecordField[]): void {
    // Do nothing if the field is already visible or the limit is reached.
    if (this.isFieldAddable(field, visibleFields) === false) {
      return;
    }

    switch (field) {
      case "addedCameras":
      case "modifiedCameras":
      case "removedCameras":
        const cameraChanges = this.filterFields(allFields, "addedCameras", "modifiedCameras", "removedCameras");
        this.addIfSpace(visibleFields, cameraChanges);
        break;
      case "addedAlertDevices":
      case "removedAlertDevices":
        const receiverTeamsChanges = this.filterFields(allFields, "addedAlertDevices", "removedAlertDevices");
        this.addIfSpace(visibleFields, receiverTeamsChanges);
        break;
      case "addedReceiverGroups":
      case "removedReceiverGroups":
        const alarmRoutesChanges = this.filterFields(allFields, "addedReceiverGroups", "removedReceiverGroups");
        this.addIfSpace(visibleFields, alarmRoutesChanges);
        break;
      default:
        visibleFields.push(field);
        break;
    }
  }

  private isFieldAddable(field: CameraGroupChangeRecordField, visibleFields: CameraGroupChangeRecordField[]): boolean {
    if (visibleFields.length >= this.visibleFieldCount || visibleFields.includes(field)) {
      return false;
    }
    return true;
  }

  private filterFields(allFields: CameraGroupChangeRecordField[], ...fields: CameraGroupChangeRecordField[]): CameraGroupChangeRecordField[] {
    return allFields.filter(f => {
      return fields.some(field => f === field);
    })
  }

  private addIfSpace(visibleFields: CameraGroupChangeRecordField[], fieldsToAdd: CameraGroupChangeRecordField[]): void {
    // Add the fields if there is enough space.
    if (visibleFields.length >= this.visibleFieldCount - fieldsToAdd.length) {
      return;
    }
    fieldsToAdd.forEach(f => visibleFields.push(f));
  }
}

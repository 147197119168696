import { CommonModule } from '@angular/common';
import { Component, Inject, HostListener, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceCompanyGroup } from 'app/models/device.model';
import { DeviceInformationComponent } from '../device-information.component';
import { DeviceBindTimeManagerComponent } from '../device-bind-time-manager/device-bind-time-manager.component';
import { FadeOutAnimation } from 'app/animations';

type ContentType = "deviceInfo" | "bindTimeManager";

export interface IDeviceInformationDialogData {
  device: DeviceCompanyGroup;
  bindingTimeVisible: boolean;
}

@Component({
  selector: 'app-device-information-dialog',
  templateUrl: './device-information-dialog.component.html',
  styleUrls: ['./device-information-dialog.component.css'],
  standalone: true,
  imports: [CommonModule, TranslateModule, DeviceInformationComponent, DeviceBindTimeManagerComponent],
  animations: [FadeOutAnimation]
})
export class DeviceInformationDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<DeviceInformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IDeviceInformationDialogData
  ) {
    this.device = data.device;
    this.bindingTimeVisible = data.bindingTimeVisible;
  }

  @ViewChild("dropdown") dropdown: ElementRef;
  @ViewChild("showDropdownBtn") showDropdownBtn: ElementRef;

  device: DeviceCompanyGroup;
  bindingTimeVisible: boolean;

  showMenuItems: boolean = false;
  displayedContent: ContentType = "deviceInfo";

  successMsg: string = "";

  openDefaultTab(successMsg: string): void {
    this.displayedContent = "deviceInfo";
    this.showSuccessMsg(successMsg);
  }

  toggle(contentType: ContentType): void {
    this.displayedContent = contentType;
    this.showMenuItems = false;
  }

  close(): void {
    this.dialogRef.close();
  }

  showSuccessMsg(message: string): void {
    const time = 5000; // 5 seconds
    this.successMsg = message;

    if (this.successMsg) {
      setTimeout(() => {
        this.successMsg = "";
      }, time);
    }
  }

  @HostListener("document:click", ["$event"])
  handleCloseDropdown(event: Event): void {
    if (this.dropdown && this.showDropdownBtn) {
      const isOption = this.dropdown.nativeElement.contains(event.target);
      const isButton = this.showDropdownBtn.nativeElement.contains(event.target);

      if (!isOption && !isButton) {
        this.showMenuItems = false;
      }
    }
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "residentDevice",
  pure: true,
  standalone: true,
})
export class TranslateResidentDevicePipe implements PipeTransform {
  transform(deviceType: string, deviceTranslations: any[]): string {
    let deviceTypeName = "";

    if (deviceTranslations.length > 0) {
      const translation = deviceTranslations.find(
        (x: any) => x.deviceType === deviceType
      );

      deviceTypeName = `${translation.deviceTypeName} (${translation.deviceTypeCode})`;
    }

    return deviceTypeName;
  }
}

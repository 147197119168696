<h4>{{'MOBILE_WHITELIST' |translate}}</h4>
<div class="row" *ngIf="mobileWhitelist.length > 0">
    <div class="col-lg-12">
        <div class="mb-2">
            <strong>{{'WAITING_APPROVAL' |translate}}
            </strong>
        </div>
        <ng-container *ngIf="mobileWhitelist |filter:'approved':false as whitelist">
            <table class="table table-sm table-striped" *ngIf="whitelist.length">
                <thead>
                    <tr>
                        <th style="width:18.75%">{{'PHONE_NAME' |translate}}</th>
                        <th style="width:18.75%">{{'GSM_NUMBER' |translate}}</th>
                        <th style="width:18.75%">{{'REQUESTED_BY' |translate}}</th>
                        <th style="width:18.75%">{{'REQUESTED_AT' |translate}}</th>
                        <th style="width:25%">{{'APPROVE_DENY' |translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let mobile of whitelist">
                        <ng-container *ngIf="!mobile.toBeDeleted">
                            <td>{{mobile.name}}</td>
                            <td>{{mobile.gsmNumber ? mobile.gsmNumber : '-'}}</td>
                            <td><a class="underline" *ngIf="mobile.modifiedStaff"
                                    [routerLink]="'../staff/'+mobile.modifiedStaff">{{mobile.modifiedStaff}}</a></td>
                            <td>{{mobile.modified |moment:'l LTS'}}</td>
                            <td>
                                <span class="text-success cursorPointer" (click)="approveMobile(mobile)"><span
                                        class="fa-light fa-fw fa-check"></span> {{'APPROVE' |translate}}</span>
                                <span class="ml-3 text-danger cursorPointer" (click)="mobile.toBeDeleted = true"><span
                                        class="fa-light fa-fw fa-ban"></span> {{'DENY' |translate}}</span>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="mobile.toBeDeleted">
                            <td colspan="5">
                                {{'CONFIRM_MOBILE_DELETION' |translate: {mobile: mobile.name} }}
                                <span class="ml-3 text-danger cursorPointer" (click)="denyMobile(mobile)"><span
                                        class="fa-light fa-fw fa-xmark"></span> {{'BLOCK_MOBILE' |translate}}</span>
                                <span class="ml-3 text-primary cursorPointer" (click)="mobile.toBeDeleted = false"><span
                                        class="fa-light fa-fw fa-xmark"></span> {{'CANCEL' |translate}}</span>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="!whitelist.length">{{'NO_APPROVE_REQUESTS' |translate}}</div>
        </ng-container>
    </div>
</div>
<hr>
<div class="row mt-3" *ngIf="mobileWhitelist.length > 0">
    <div class="col-lg-12">
        <div>
            <strong>{{'APPROVED_MOBILES' |translate}}</strong>
        </div>
        <ng-container *ngIf="mobileWhitelist |filter:'approved':true as whitelist">
            <table class="table table-sm table-striped" *ngIf="whitelist.length">
                <thead>
                    <tr>
                        <th style="width:4%">{{'MODIFY' |translate}}</th>
                        <th style="width:24%">{{'PHONE_NAME' |translate}}</th>
                        <th style="width:24%">{{'GSM_NUMBER' |translate}}</th>
                        <th style="width:24%">{{'APPROVED_AT' |translate}}</th>
                        <th style="width:24%">{{'BLOCK_MOBILE' |translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let mobile of whitelist">
                        <ng-container *ngIf="!mobile.toBeDeleted">
                            <td>
                                <div *ngIf="!mobile.edit">
                                    <span class="fa-light fa-fw fa-pen-to-square cursorPointer text-primary"
                                        (click)="editMobile(mobile)"></span>
                                </div>
                                <div *ngIf="mobile.edit">
                                    <span class="fa-light fa-fw fa-xmark cursorPointer text-danger"
                                        (click)="mobile.edit = false"></span>
                                    <span class="fa-light fa-fw fa-floppy-disk cursorPointer text-primary ml-2"
                                        (click)="saveMobile(mobile)"></span>
                                </div>
                            </td>
                            <td>
                                <input *ngIf="mobile.edit" class="form-control form-control-sm" type="text"
                                    [(ngModel)]="mobile.newName">
                                <span *ngIf="!mobile.edit">{{mobile.name}}</span>
                            </td>
                            <td>
                                <input *ngIf="mobile.edit" class="form-control form-control-sm" type="text"
                                    [(ngModel)]="mobile.newGsmNumber">
                                <span *ngIf="!mobile.edit">{{mobile.gsmNumber ? mobile.gsmNumber : '-'}}</span>
                            </td>
                            <td>{{mobile.modified |moment: 'l LTS'}}</td>
                            <td>
                                <span class="text-danger cursorPointer" (click)="mobile.toBeDeleted = true"><span
                                        class="fa-light fa-fw fa-ban"></span> {{'BLOCK_MOBILE' |translate}}</span>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="mobile.toBeDeleted">
                            <td colspan="5">
                                {{'CONFIRM_MOBILE_DELETION' |translate: {mobile: mobile.name} }}
                                <span class="ml-3 text-danger cursorPointer" (click)="denyMobile(mobile)"><span
                                        class="fa-light fa-fw fa-ban"></span> {{'BLOCK_MOBILE' |translate}}</span>
                                <span class="ml-3 text-primary cursorPointer" (click)="mobile.toBeDeleted = false"><span
                                        class="fa-light fa-fw fa-xmark"></span> {{'CANCEL' |translate}}</span>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="!whitelist.length">{{'NO_APPROVED_MOBILES' |translate}}</div>
        </ng-container>
    </div>
</div>
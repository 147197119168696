import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { AnimationCollapse, AnimationCollapseLeave } from "../../../animations";
import { Sort, MatSortModule } from "@angular/material/sort";
import { IDeviceStatus } from "../../../models/devicestatus.model";
import { HelperService } from "../../../services/helper.service";
import { Angular2Csv } from "angular2-csv";
import * as moment from "moment-timezone";
import { MomentPipe } from "../../../pipes/pipes";
import { DeviceDatabaseComponent } from "../../device-database/device-database.component";
import { NgIf, NgClass, NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { DeviceService } from "app/services/device.service";

@Component({
  selector: "devicestatus",
  templateUrl: "./devicestatus.component.html",
  styleUrls: ["./devicestatus.component.css"],
  animations: [AnimationCollapse, AnimationCollapseLeave],
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgClass,
    DeviceDatabaseComponent,
    MatSortModule,
    NgFor,
    TranslateModule,
    MomentPipe,
  ],
})
export class DeviceStatusComponent implements OnChanges, OnInit {
  @Input("type") statusType: string;
  @Input("class") statusClass: string;
  @Input("data") countData: number;
  @Input("title") statusTitle: string;
  @Input("showBaseDevicesOnly") showBaseDevicesOnly: boolean;
  @Input("description") description: string;
  baseDeviceList: IDeviceStatus[];

  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService,
    private helper: HelperService,
    private service: DeviceService
  ) {}

  onlineStatus: OnlineStatus = {
    state: false,
    deviceList: null,
  };

  originalDevList: IDeviceStatus[];
  user: any;

  getBaseDevices(): IDeviceStatus[] {
    return this.originalDevList.filter((deviceStatus) =>
      ["31", "40", "36"].some((prefix) => deviceStatus.id.startsWith(prefix))
    );
  }

  selectDevicesToShow(): void {
    this.onlineStatus.deviceList = this.showBaseDevicesOnly
      ? this.baseDeviceList
      : this.originalDevList;
  }

  showProductStatus: boolean = false;

  displayOnlineStatusDevices(): void {
    if (this.statusType == "productStatus") {
      /*
      let customerId = JSON.parse(localStorage.getItem("user"))["customerId"];
      console.log(customerId);
      this.api.getCompanyInventory(customerId).subscribe((response: any) => {

      });
      */
      this.showProductStatus = true;
    } else if (!this.onlineStatus.state) {
      this.api
        .getOnlineStatusDevices(this.statusType)
        .subscribe((response: any) => {
          this.onlineStatus.deviceList = response;
          this.originalDevList = response.slice();
          this.baseDeviceList = this.getBaseDevices();
          this.selectDevicesToShow();
        });
    } else {
      this.onlineStatus.deviceList = null;
    }
    this.onlineStatus.state = !this.onlineStatus.state;
  }

  openDevice(dev: any): void {
    this.router.navigate(["device/" + dev.id]);
  }

  getDeviceType(type: string): string {
    return this.helper.getDeviceType(type);
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction == "") {
      // this.onlineStatus.deviceList = this.originalDevList;
      return;
    }

    this.onlineStatus.deviceList.sort((a, b) => {
      let isAsc = sort.direction == "asc";
      switch (sort.active) {
        case "id":
          return this.compare(a.id, b.id, isAsc);
        case "deviceType":
          return this.compare(a.deviceType, b.deviceType, isAsc);
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "lastcon":
          return this.compare(a.lastConnection, b.lastConnection, isAsc);
        case "location":
          return this.compare(a.locationName, b.locationName, isAsc);
        case "softwareVersion":
          return this.compare(a.softwareVersion, b.softwareVersion, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  exportAsCSV(): void {
    let csvHeaders = [
      "ID",
      "Name",
      "Location",
      "Address",
      "Postal code",
      "City",
      "Type",
      "Software version",
    ];

    if (this.user.roleLevel >= 50) {
      csvHeaders.push(...["Additional information", "Device information"]);
    }

    // Push rest of the headers separately to mantain correct order
    csvHeaders.push(...["Latest contact registered", "Battery status"]);

    var csvData = [];
    let filename = "deviceStatus-" + moment().format("YYYY-MM-DD");
    let options = {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalseparator: ".",
      showTitle: false,
      headers: csvHeaders,
    };
    //Define what information is included in the file
    this.onlineStatus.deviceList.forEach((row) => {
      var csvRow: any = {};
      csvRow.DeviceId = `="${row.id}"`;
      csvRow.Name = row.name || "ID-" + row.id;
      csvRow.Location = row.locationName;
      csvRow.Address = row.address;
      csvRow.PostCode = row.postCode;
      csvRow.City = row.postOffice;
      csvRow.DeviceType = this.getDeviceType(row.deviceType);
      csvRow.Firmware = row.softwareVersion;
      if (this.user.roleLevel >= 50) {
        csvRow.AdditionalInfo = row.additionalInfo;
        csvRow.DeviceInformation = row.additionalAlarmInfo;
      }
      csvRow.lastContact = moment(row.lastConnection).format("l LTS");
      csvRow.batteryState = row.batteryState;
      csvData.push(csvRow);
    });
    //Generate the file and send it to the browser
    new Angular2Csv(csvData, filename, options);
  }

  async getDeviceTranslations(): Promise<void> {
    let devTypeList = localStorage.getItem("devType");
    //If list is not in storage download it from the server and set it to local storage
    if (!devTypeList) {
      await new Promise<void>((resolve) =>
        this.api
          .getDeviceTranslations(localStorage.getItem("language"))
          .subscribe((res) => {
            localStorage.setItem("devType", JSON.stringify(res));
            devTypeList = res;
            resolve();
          })
      );
    } else {
      await Promise.resolve();
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getDeviceTranslations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectDevicesToShow();
  }
}

class OnlineStatus {
  state: boolean;
  deviceList: IDeviceStatus[];
}

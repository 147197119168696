import { Pipe, PipeTransform } from '@angular/core';
import { IActivityLog } from 'app/models/activity-log.model';

@Pipe({
  name: 'boxLayoutStyle',
  standalone: true,
  pure: true
})
export class BoxLayoutStylePipe implements PipeTransform {
  transform(log: IActivityLog): object {
    const changeRecord = log.details.accountChanges;
    const rowHeight = "minmax(40px, max-content)";
    let rows = "";

    if (changeRecord?.roleLevel?.newValue) {
      rows += rowHeight + " ";
    }
    if (changeRecord?.lockRights?.newValue) {
      rows += rowHeight + " ";
    }

    return { "grid-template-rows": rows };
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragStart } from '@angular/cdk/drag-drop';
import { HelperService } from 'app/services/helper.service';
import * as moment from 'moment-timezone';
import { CrSyncService, CrAlert, CrAlertState, CrNote } from '../../../services/crsync.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'control-room-clock',
    templateUrl: './control-room-clock.component.html',
    styleUrls: ['./control-room-clock.component.css'],
    standalone: true
})
export class ControlRoomClockComponent implements OnInit {

  constructor(
    public helper: HelperService,
    private crSync: CrSyncService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder
  ) { }

  @Input('timeZone') tz: string;
  @Input('img') img: string;
  @ViewChild('clockCanvas', { static: true }) _canvas: ElementRef;


  ngOnInit() {
    this.initClock();
  }

  initClock(): void {
    let canvas: HTMLCanvasElement = this._canvas.nativeElement;
    let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    let radius: number = canvas.height / 2;
    ctx.translate(radius, radius);
    radius = radius * 0.90
    this.drawClock(ctx, radius);
  }

  drawClock(ctx: CanvasRenderingContext2D, radius: number): void {
    this.drawFace(ctx, radius);
    this.drawNumbers(ctx, radius);
    this.drawTime(ctx, radius);
    setTimeout(() => {
      this.drawClock(ctx, radius);
    }, 1000);
  }

  drawFace(ctx: CanvasRenderingContext2D, radius: number): void {
    var grad;
    ctx.beginPath();
    ctx.arc(0, 0, radius, 0, 2 * Math.PI);
    ctx.fillStyle = '#25335F';
    ctx.fill();
    grad = ctx.createRadialGradient(0, 0, radius * 0.95, 0, 0, radius * 1.05);
    grad.addColorStop(0, 'white');
    grad.addColorStop(0.5, 'white');
    grad.addColorStop(1, 'white');
    ctx.strokeStyle = grad;
    ctx.lineWidth = radius * 0.05;
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(0, 0, radius * 0.1, 0, 2 * Math.PI);
    ctx.fillStyle = '#fff';
    ctx.fill();
  }

  drawNumbers(ctx: CanvasRenderingContext2D, radius: number): void {
    var ang;
    var num;
    ctx.font = radius * 0.25 + "px arial";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    for (num = 1; num < 13; num++) {
      ang = num * Math.PI / 6;
      ctx.rotate(ang);
      ctx.translate(0, -radius * 0.85);
      ctx.rotate(-ang);
      ctx.fillStyle = '#fff';
      ctx.fillText('•', 0, 0);
      ctx.rotate(ang);
      ctx.translate(0, radius * 0.85);
      ctx.rotate(-ang);
    }
  }

  drawTime(ctx: CanvasRenderingContext2D, radius: number): void {
    var now = moment().tz(this.tz);
    var hour = now.hours();
    var minute = now.minutes();
    var second = now.seconds();
    //hour
    hour = hour % 12;
    hour = (hour * Math.PI / 6) +
      (minute * Math.PI / (6 * 60)) +
      (second * Math.PI / (360 * 60));
    this.drawHand(ctx, hour, radius * 0.5, radius * 0.07);
    //minute
    minute = (minute * Math.PI / 30) + (second * Math.PI / (30 * 60));
    this.drawHand(ctx, minute, radius * 0.8, radius * 0.07);
    // second
    second = (second * Math.PI / 30);
    this.drawHand(ctx, second, radius * 0.9, radius * 0.02);
  }

  drawHand(ctx, pos, length, width): void {
    ctx.beginPath();
    ctx.lineWidth = width;
    ctx.lineCap = "round";
    ctx.moveTo(0, 0);
    ctx.rotate(pos);
    ctx.lineTo(0, -length);
    ctx.stroke();
    ctx.rotate(-pos);
  }

}

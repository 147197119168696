<div class="col-md-12">
    <div class="card">
        <div class="card-header-white header-underline">
            <h6 class="white-header-text">{{'TRAINING_VIDEOS' |translate}}</h6>
        </div>
        <div class="card-body-white" style="padding-bottom: 15px;">
            <mat-accordion multi="true">
                <mat-expansion-panel (opened)="pairingVideo.play()"
                    (closed)="pairingVideo.pause();pairingVideo.currentTime=0;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'PAIRING_VIDEO_HEADER' |translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <video class="trainingVideos" #pairingVideo controls>
                        <source src="https://everonstorage.blob.core.windows.net/everon-portal-files/pairing_fi.mp4" type="video/mp4">
                    </video>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="alarmRouteVideo.play()"
                    (closed)="alarmRouteVideo.pause();alarmRouteVideo.currentTime=0;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'ALARM_ROUTE_VIDEO_HEADER' |translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <video #alarmRouteVideo class="trainingVideos" controls>
                        <source src="https://everonstorage.blob.core.windows.net/everon-portal-files/alarm_route_fi.mp4" type="video/mp4">
                    </video>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="reportsVideo.play()"
                    (closed)="reportsVideo.pause();reportsVideo.currentTime=0;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'REPORTS_VIDEO_HEADER' |translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <video #reportsVideo class="trainingVideos" controls>
                        <source src="https://everonstorage.blob.core.windows.net/everon-portal-files/reports_fi.mp4" type="video/mp4">
                    </video>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="replaceVideo.play()"
                    (closed)="replaceVideo.pause();replaceVideo.currentTime=0;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'REPLACE_VIDEO_HEADER' |translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <video #replaceVideo class="trainingVideos" controls>
                        <source src="https://everonstorage.blob.core.windows.net/everon-portal-files/replace_fi.mp4" type="video/mp4">
                    </video>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="emaSettings.play()"
                    (closed)="emaSettings.pause();emaSettings.currentTime=0;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'EMA_SETTINGS_VIDEO_HEADER' |translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <video #emaSettings class="trainingVideos" controls>
                        <source src="https://everonstorage.blob.core.windows.net/everon-portal-files/ema_settings_fi.mp4" type="video/mp4">
                    </video>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>

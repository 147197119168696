import { ICompanyGroup } from "app/models/company.models";
import { Component, OnInit } from "@angular/core";
import { trigger, transition, style, animate } from "@angular/animations";
import { ApiService } from "app/services/api.service";
import { BehaviorSubject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Location, NgIf, NgFor, NgStyle } from "@angular/common";
import { TIMEZONES } from "../timezones";
import { HighlightSearch } from "../../../pipes/pipes";
import { TranslateModule } from "@ngx-translate/core";
import { CompanyGroupEditComponent } from "./company-group-edit/company-group-edit.component";
import { CompanyGroupAddComponent } from "./company-group-add/company-group-add.component";
import { MatExpansionModule } from "@angular/material/expansion";

@Component({
    selector: "app-company-group-management",
    templateUrl: "./company-group-management.component.html",
    styleUrls: ["./company-group-management.component.css"],
    animations: [
        trigger("fadeOut", [
            transition(":leave", [animate("0.5s ease-out", style({ opacity: 0 }))]),
        ]),
    ],
    standalone: true,
    imports: [NgIf, MatExpansionModule, NgFor, NgStyle, CompanyGroupAddComponent, CompanyGroupEditComponent, TranslateModule, HighlightSearch]
})
export class CompanyGroupManagementComponent implements OnInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  companyGroups: ICompanyGroup[];

  companyGroupList: IMatExpansionPanelItem[];
  filteredCompanyGroupList: IMatExpansionPanelItem[];

  searchFilter: string;
  companyGroupsLoaded: boolean = false;
  successMessage: string;
  msgFadeOutTime: number = 3000;

  companyGroupToEditId: string;
  companyGroupToEdit: ICompanyGroup;

  // Variables for controlling child components initialization
  showAddCompanyGroupForm = new BehaviorSubject(false);
  showEditCompanyGroupForm = new BehaviorSubject(false);

  setSuccessMessage(message: string, time: number) {
    this.successMessage = message;
    setTimeout(() => {
      this.successMessage = "";
    }, time);
  }

  refreshCompanyGroupList(companyGroup: ICompanyGroup) {
    this.setSuccessMessage("NEW_GROUP_ADDED_SUCCESS", this.msgFadeOutTime);
    this.companyGroups.push(companyGroup);
    this.initCompanyGroupList(this.companyGroups);
  }

  getCountry(alpha3Code: string): string {
    if (alpha3Code.length === 3) {
      return TIMEZONES.find((tz) => tz.country.alpha3Code === alpha3Code)
        .country.name;
    }
    return alpha3Code;
  }

  editCompanyGroupClickEvent(companyGroup: ICompanyGroup): void {
    // Close inited components
    this.showEditCompanyGroupForm.next(false);
    this.showAddCompanyGroupForm.next(false);

    this.companyGroupToEdit = companyGroup;
    this.companyGroupToEditId = companyGroup.companyGroupId;

    this.showEditCompanyGroupForm.next(true);
  }

  search(event: any): void {
    const text: string = event.target.value;

    if (!text) {
      this.companyGroupList.forEach((x) => (x.expanded = false));
      this.filteredCompanyGroupList = this.companyGroupList;
      this.searchFilter = "";
      return;
    }

    // Check if Group name, company name or information data either fully or partial matches from the beginning of the string
    this.filteredCompanyGroupList = this.companyGroupList.filter(
      (item) =>
        item.companyGroup.name.substring(0, text.length).toLocaleLowerCase() ===
          text.toLocaleLowerCase() ||
        item.companyGroup.companies
          .map((x) => x.company)
          .find(
            (x) =>
              x.substring(0, text.length).toLocaleLowerCase() ===
              text.toLocaleLowerCase()
          ) ||
        item.companyGroup.address
          .substring(0, text.length)
          .toLocaleLowerCase() === text.toLocaleLowerCase() ||
        item.companyGroup.postOffice
          .substring(0, text.length)
          .toLocaleLowerCase() === text.toLocaleLowerCase() ||
        item.companyGroup.postCode
          .substring(0, text.length)
          .toLocaleLowerCase() === text.toLocaleLowerCase() ||
        this.getCountry(item.companyGroup.country)
          .substring(0, text.length)
          .toLocaleLowerCase() === text.toLocaleLowerCase() ||
        item.companyGroup.organizationNumber
          .substring(0, text.length)
          .toLocaleLowerCase() === text.toLocaleLowerCase()
    );

    // Open expansion panel if search matches to company name or group information data
    this.filteredCompanyGroupList.forEach((item) => {
      const address = item.companyGroup.address;
      const postOffice = item.companyGroup.postOffice;
      const postCode = item.companyGroup.postCode;
      const country = item.companyGroup.country;
      const organizationNumber = item.companyGroup.organizationNumber;

      item.expanded =
        address.substring(0, text.length).toLocaleLowerCase() ===
        text.toLocaleLowerCase()
          ? true
          : postOffice.substring(0, text.length).toLocaleLowerCase() ===
            text.toLocaleLowerCase()
          ? true
          : postCode.substring(0, text.length).toLocaleLowerCase() ===
            text.toLocaleLowerCase()
          ? true
          : this.getCountry(country)
              .substring(0, text.length)
              .toLocaleLowerCase() === text.toLocaleLowerCase()
          ? true
          : organizationNumber.substring(0, text.length).toLocaleLowerCase() ===
            text.toLocaleLowerCase()
          ? true
          : item.companyGroup.companies
              .map((x) => x.company)
              .findIndex(
                (x) =>
                  x.substring(0, text.length).toLocaleLowerCase() ===
                  text.toLocaleLowerCase()
              ) > -1
          ? true
          : false;
    });

    this.searchFilter = text;
  }

  parseCompanyGroupList(
    companyGroups: ICompanyGroup[]
  ): IMatExpansionPanelItem[] {
    let array = [];
    companyGroups.forEach((group) => {
      array.push({
        expanded: false,
        companyGroup: group,
      });
    });
    return array;
  }

  initCompanyGroupList(companyGroups: ICompanyGroup[]) {
    this.companyGroupList = this.parseCompanyGroupList(companyGroups);
    this.filteredCompanyGroupList = this.companyGroupList.sort((a, b) =>
      a.companyGroup.name > b.companyGroup.name ? 1 : -1
    );
  }

  ngOnInit(): void {
    this.api.getCompaniesByCompanyGroup().subscribe((res: ICompanyGroup[]) => {
      this.companyGroups = res;
      this.initCompanyGroupList(this.companyGroups);
      this.companyGroupsLoaded = true;

      this.route.params.subscribe(
        (params) => (this.companyGroupToEditId = params["id"])
      );
    });

    this.showEditCompanyGroupForm.subscribe((show) => {
      if (show) {
        this.location.go("system/company-groups/" + this.companyGroupToEditId);
      } else {
        this.location.go("system/company-groups");
      }
    });
  }
}

interface IMatExpansionPanelItem {
  expanded: boolean;
  companyGroup: ICompanyGroup;
}

<h3 style="margin-bottom: 20px">{{ "NAV_DASHBOARD" | translate }}</h3>

<div class="row">
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header-white header-underline">
                        <h6 class="white-header-text">
                            {{ "CUSTOMER_INFO" | translate }}
                        </h6>
                        <span
                          *ngIf="customerInformation && !customerEdit && user.roleLevel >= 100"
                          class="fa-light fa-lg fa-pen-to-square float-right customerEdit"
                          (click)="startCustomerEdit()">
                        </span>
                    </div>
                    <div class="card-body-white">
                        <div class="customerPanel">
                            <!--Customer information display page-->
                            <div *ngIf="!customerEdit && customerInformation">
                                <div>
                                    {{ customerInformation?.name }} ({{ customerInformation?.customerId }})<br />
                                    {{ customerInformation?.address }}<br />
                                    {{ customerInformation?.postCode }}
                                    {{ customerInformation?.postOffice }}<br />
                                </div>
                                <div class="float-right">
                                    <small>
                                        {{ "CUST_ADDED_TO_SYSTEM" | translate }}
                                        {{ customerInformation?.addedToSystem | moment : "l" }}
                                    </small>
                                </div>
                                <div *ngIf="enabledFeatures" class="pt-2">
                                    <hr />
                                    <strong>{{ "ENABLED_FEATURES" | translate }}</strong>
                                    <ul class="companyFeatures">
                                        <li *ngFor="let feature of enabledFeatures">
                                            <span class="fa-light fa-fw" [ngClass]="getFeatureClass(feature)"></span>
                                            {{ feature | translate }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!--Customer information editing form-->
                            <div *ngIf="customerEdit">
                                <form (ngSubmit)="formSubmitSubject.next()" #customerEditForm="ngForm">
                                    <div class="form-group">
                                        <label>{{ "NAME" | translate }}</label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          [(ngModel)]="customerInformation.name"
                                          name="customerName"
                                          #customerName="ngModel"
                                          required
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label>{{ "ADDRESS" | translate }}</label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          [(ngModel)]="customerInformation.address"
                                          name="customerAddress"
                                          #customerAddress="ngModel"
                                          required
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label>{{ "POSTCODE" | translate }}</label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          [(ngModel)]="customerInformation.postCode"
                                          name="customerPostCode"
                                          #customerPostCode="ngModel"
                                          required
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label>{{ "POSTOFFICE" | translate }}</label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          [(ngModel)]="customerInformation.postOffice"
                                          name="customerPostOffice"
                                          #customerPostOffice="ngModel"
                                          required
                                        />
                                    </div>
                                    <div class="form-group">
                                        <hr />
                                        <strong>{{ "ENABLED_FEATURES" | translate }}</strong>
                                        <ul class="companyFeatures allFeatures" *ngIf="user.roleLevel > 100; else elseFeature">
                                            <li
                                                *ngFor="let feature of allFeatures"
                                                [ngClass]="{ enabledFeature: isFeatureEnabled(feature), disabledFeature: !canEnableFeature(feature) }"
                                                (click)="enableFeature(feature)"
                                            >
                                                <span  class="fa-light fa-fw" [ngClass]="getFeatureClass(feature)"></span>
                                                {{ feature | translate }}
                                            </li>
                                        </ul>
                                        <!--Show only enabled features if user role is not high enough for feature modification-->
                                        <ng-template #elseFeature>
                                            <ul class="companyFeatures">
                                                <li *ngFor="let feature of enabledFeatures">
                                                    <span class="fa-light fa-fw" [ngClass]="getFeatureClass(feature)"></span>
                                                    {{ feature | translate }}
                                                </li>
                                            </ul>
                                            <span *ngIf="!enabledFeatures">{{ "NO_FEATURES_ENABLED" | translate }}</span>
                                        </ng-template>
                                    </div>
                                    <!--Components for main users and admins-->
                                    <div *ngIf="user.roleLevel >= 100">
                                        <div class="form-group">
                                            <label>{{ "PASSWORD_EXPIRATION" | translate }}</label>
                                            <input
                                              type="text"
                                              class="form-control"
                                              [(ngModel)]="customerInformation.passwordExpirationDays"
                                              name="passwordExpirationDays"
                                              #passwordExpirationDays="ngModel"
                                            />
                                        </div>
                                    </div>
                                    <!-- Components for only admins -->
                                    <div *ngIf="user.roleLevel === 200">
                                        <div class="form-group">
                                            <label>DefContLim (e.g M5 = 5 minutes)</label>
                                            <input
                                              type="text"
                                              class="form-control"
                                              [(ngModel)]="customerInformation.defContLim"
                                              name="customerDefContLim"
                                              #customerDefContLim="ngModel"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label>{{ "EMA_TAG_ONLY" | translate }}</label>
                                            <section>
                                                <mat-slide-toggle [(ngModel)]="customerInformation.emaTagOnly" name="customerEmaTagOnly"></mat-slide-toggle>
                                            </section>
                                        </div>
                                        <div class="form-group">
                                            <div class="form-group">
                                                <label>RoamingDelay (seconds)</label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  [(ngModel)]="customerInformation.roamingDelay"
                                                  name="customerRoamingDelay"
                                                  #customerRoamingDelay="ngModel"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button
                                          [disabled]="!featuresChanged && (customerEditForm.invalid || customerEditForm.pristine)"
                                          type="submit"
                                          class="btn btn-outline-primary mr-2"
                                        >
                                            <span class="fa-light fa-floppy-disk"></span>
                                            {{ "SAVE" | translate }}
                                        </button>
                                        <button class="btn btn-outline-danger" (click)="cancelCustomerEdit()">
                                            <span class="fa-light fa-xmark"></span>
                                            {{ "CANCEL" | translate }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <!--Two factor authentication configuration-->
                            <div
                                *ngIf="user.roleLevel >= 100 && isFeatureEnabled('EnhancedSecurity', false)">
                                <hr />
                                <two-factor-authentication *ngIf="customerInformation"></two-factor-authentication>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="notifications" style="margin-top: 20px">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header-white header-underline">
                        <h6 class="white-header-text">
                            {{ "NEWS" | translate }}
                        </h6>
                    </div>
                    <div class="card-body-white" style="padding-top: 15px">
                        <div *ngFor="let notification of notifications">
                            <div [ngClass]="notification.highlight ? 'notificationHighlight' : ''">
                                <strong>{{ notification.subject }}</strong><br />
                                <small>{{ notification.timestamp | moment : "l LTS" }}</small>
                                <p [innerHTML]="notification.message"></p>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="deviceOnlineStatus">
                    <div class="card">
                        <div class="card-header-white header-underline">
                            <h6 class="white-header-text">
                                {{ "DEVICES_STATUS" | translate }}
                            </h6>

                            <div style="position: relative; top: -31%; margin-right: 15px;">
                                <div class="float-right">
                                    <mat-slide-toggle (change)="showBaseDevicesOnly = !showBaseDevicesOnly; getOnlineStatus(showBaseDevicesOnly)"></mat-slide-toggle>
                                </div>

                                <label class="float-right mr-2">{{ "SHOW_BASE_STATIONS_ONLY" | translate }}</label>
                            </div>
                        </div>
                        <div class="card-body-white">
                            <devicestatus
                              [showBaseDevicesOnly]="showBaseDevicesOnly"
                              [type]="'Online'"
                              [class]="'statusOnline'"
                              [data]="onlineStatus?.online"
                              [title]="'Online' | translate"
                              [description]="'DEVICE_STATUS_ONLINE_DESCRIPTION' | translate"
                            ></devicestatus>

                            <devicestatus
                              [showBaseDevicesOnly]="showBaseDevicesOnly"
                              [type]="'OfflineUnder24h'"
                              [class]="'statusOfflineUnder'"
                              [data]="onlineStatus?.offlineUnder24h"
                              [title]="'OfflineUnder24h' | translate"
                              [description]="'DEVICE_STATUS_OFFLINE_UNDER_24H_DESCRIPTION' | translate"
                            ></devicestatus>

                            <devicestatus
                              [showBaseDevicesOnly]="showBaseDevicesOnly"
                              [type]="'OfflineOver24h'"
                              [class]="'statusOfflineOver'"
                              [data]="onlineStatus?.offlineOver24h"
                              [title]="'OfflineOver24h' | translate"
                              [description]="'DEVICE_STATUS_OFFLINE_OVER_24H_DESCRIPTION' | translate">
                            </devicestatus>

                            <devicestatus
                              [showBaseDevicesOnly]="showBaseDevicesOnly"
                              [type]="'LowBattery'"
                              [class]="'statusLowBattery'"
                              [data]="onlineStatus?.lowBattery"
                              [title]="'BATTERY_STATUS_WARNING' | translate"
                              [description]="'DEVICE_STATUS_LOW_BATTERY_DESCRIPTION' | translate"
                            ></devicestatus>
                            <!--<devicestatus [showBaseDevicesOnly]="showBaseDevicesOnly" [type]="'productStatus'" [class]="'statusDeviceRegistry'" [data]="onlineStatus?.productStatus" [title]="'PRODUCT_STATUS' |translate"></devicestatus>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Active alerts-->
        <div class="row" style="margin-top: 10px">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header-white rounded-corners activeAlertsHeader" (click)="loadActiveAlerts()">
                        <h6 style="position: relative; top: 25%">
                            {{ "ACTIVE_ALARMS" | translate }}
                        </h6>
                        <span
                          class="fa-light float-right"
                          [ngClass]="activeAlertsState ? 'fa-chevron-up' : 'fa-chevron-down'"
                          class="fa-solid fa-xl"
                          style="
                            float: right;
                            margin-right: 10px;
                            color: #707070;
                            position: relative;
                            top: -3px;
                        "></span>
                    </div>
                    <div class="card-body-white" *ngIf="activeAlertsState" [@animationCollapseLeave]>
                        <div *ngIf="!activeAlerts || !activeAlertsLoaded">
                            <img
                              *ngIf="!activeAlertsLoaded"
                              style="margin-top: 5px"
                              class="fadeLoader centerLoader logoSmall"
                              src="assets/img/loading_logo.svg"
                            />
                            <span *ngIf="activeAlertsLoaded">{{ "NO_ALARMS" | translate }}</span>
                        </div>

                        <div class="activeAlertsTable" *ngIf="activeAlerts" [@animationCollapse]>
                            <table
                                class="table table-hover"
                                style="border-top: none !important">
                                <thead>
                                    <tr>
                                        <th style="width: 1%"></th>
                                        <th>
                                            {{ "ALARM_REGISTERED" | translate }}
                                        </th>
                                        <th>{{ "DEVICE" | translate }}</th>
                                        <th>{{ "ALARM_TYPE" | translate }}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let alert of activeAlerts">
                                        <td>
                                          <span class="fa-light fa-fw" [ngClass]="getDeliveryClass(alert)"></span>
                                        </td>
                                        <td>{{ alert.receiveTime | moment : "l LTS" }}</td>
                                        <td>{{ alert.deviceName ? alert.deviceName : alert.deviceId }}</td>
                                        <td>
                                          {{
                                            getActivationName(
                                              alert.activationGroup,
                                              alert.activationNode,
                                              alert.deviceType,
                                              alert.customActivationName,
                                              alert.connTimeLimit
                                            )
                                          }}
                                        </td>
                                        <td>
                                            <button *ngIf="!alert.cancelled && !alert.error" class="btn btn-outline-dark danger-text fullWidth" (click)="cancelAlert(alert)">
                                              <span class="fa-light fa-circle-stop"></span>
                                              {{ "CANCEL_DELIVERY" | translate}}
                                            </button>

                                            <span *ngIf="alert.cancelled" class="danger-text">{{ "DELIVERY_CANCELLED" | translate }}</span>
                                            <span *ngIf="alert.error"class="danger-text">{{ "ERROR" | translate }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Training videos-->
        <div class="row" *ngIf="lang === 'fi'" style="margin-top: 10px">
            <training-videos></training-videos>
        </div>
    </div>
</div>

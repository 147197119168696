import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { NewStaff } from "../../../models/newstaff.model";
import { ApiService } from "app/services/api.service";
import { fromEvent, forkJoin, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from "@angular/material/dialog";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { MatSelect, MatSelectModule } from "@angular/material/select";
import {
  MomentPipe,
  HighlightSearch,
  StringToSlashAndColonDate,
} from "../../../pipes/pipes";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ThrottleButtonClickDirective } from "../../../directives/throttle-button-click.directive";
import { MatOptionModule } from "@angular/material/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CommonModule } from "@angular/common";
import { NewStaffEditComponent } from "./new-staff-edit/new-staff-edit.component";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
  PageEvent,
} from "@angular/material/paginator";
import { i18nPaginator } from "./i18nPaginator";
import { IExtendedJobholder, INewStaff } from "./new-staff-interfaces";
import { ICompanyGroup } from "app/models/company.models";

export interface ICustomerInfo {
  addToAllReceiverGroups: boolean;
  address: string;
  company: string;
  companyId: string;
  populateStaff: boolean;
}

@Component({
  selector: "new-staff",
  templateUrl: "./new-staff.component.html",
  styleUrls: ["./new-staff.component.css"],
  standalone: true,
  providers: [{ provide: MatPaginatorIntl, useClass: i18nPaginator }],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatOptionModule,
    ThrottleButtonClickDirective,
    MatCheckboxModule,
    TranslateModule,
    HighlightSearch,
    NewStaffEditComponent,
    StringToSlashAndColonDate,
    MomentPipe
  ]
})
export class NewStaffComponent implements OnInit, OnDestroy {
  constructor(private api: ApiService) { }

  _staffFilter: string;

  @ViewChild("staffSearchInput", { static: true }) staffSearchInput: ElementRef;

  staffList: any[] = [];
  filteredStaffs: any[] = [];
  jobholder: any;

  customers: ICustomerInfo[] = [];

  staffPageData: any = {
    resultCount: 0,
    pageSize: 0,
  };

  currentPageIndex: number = 0;
  pageSize: number = 50;
  totalItems: number = 0;

  dataLoaded: boolean = false;
  staffsLoaded: boolean = false;
  deleteFailed: boolean = false;

  editNewStaff = false;
  index: number; // Purpose of this?

  subscriptions: Subscription[] = [];

  get staffFilter(): string {
    return this._staffFilter;
  }

  set staffFilter(value: string) {
    this._staffFilter = value;
    this.filteredStaffs = this.transform(this.staffList, value);
    if (value == "" && !this.staffsLoaded) {
      this.staffsLoaded = true;
      this.searchStaff(this.currentPageIndex);
    } else if (value != "") {
      this.staffsLoaded = false;
    }
  }

  closeEditCard() {
    this.jobholder.checked = false;
    this.jobholder = null;
    this.editNewStaff = false;
  }

  rowClicked(staff: IExtendedJobholder) {
    // when click second time on the same row
    if (this.jobholder === staff) {
      this.closeEditCard();
      return;
    }

    // if previous row was still open when clicked on another row
    this.jobholder ? (this.jobholder.checked = false) : null;

    // open edit card with staff data from clicked row
    this.jobholder = staff;
    this.jobholder.checked = true;
    this.index = this.staffList.findIndex((el) => el === staff);
    this.editNewStaff = true;
  }

  finishAddingCompany() {
    this.AddCompany(this.index, true);
  }

  completeRemovingCompany(value: number) {
    this.RemoveCompany(this.index, value);
  }

  notifyOnDeleteFailed() {
    this.makeTrueForAWhile(this.deleteFailedNotice, 3000);
  }

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter((it) => {
      return (
        (typeof it.firstName !== "undefined"
          ? it.firstName.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.identityId !== "undefined"
          ? it.identityId.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.createTime !== "undefined"
          ? it.createTime.toLocaleLowerCase().includes(searchText)
          : false)
      );
    });
  }

  allSelected: boolean = false;

  selectedCount: number = 0;

  selectedCompany: number = 0;

  roleSelection: number = 0;

  companySelection: number = 0;

  moveSelectedDisabled: boolean = true;

  warningsCompany = false;
  warningsRole = false;

  nextStaffPage: number;
  prevStaffPage: number;

  // roles = [
  //   { value: 100, name: this.translate.instant("STAFF_MAIN_USER") },
  //   { value: 50, name: this.translate.instant("STAFF_USER") },
  //   { value: 10, name: this.translate.instant("EveronMobileApp") },
  //   { value: 0, name: this.translate.instant("STAFF_READONLY") },
  // ];

  moveSuccess = false;

  moveFailed: boolean = false;

  pages = new Array();

  pageCount = 0;
  searchStaff(page = 0) {
    let search = this.staffSearchInput.nativeElement.value;
    this.currentPageIndex = page;
    this.api
      .getCompanyGroupNewStaff(
        this.currentPageIndex,
        this.pageSize,
        search,
        this.selectedSort
      )
      .subscribe(
        (res) => {
          this.filteredStaffs = this.staffList = res.result;
          this.pageCount = Math.ceil(res.resultCount / res.pageSize);

          this.staffFilter = this.staffSearchInput.nativeElement.value;

          this.pages = new Array(Math.ceil(res.resultCount / res.pageSize));

          // Update paginator settings
          this.totalItems = res.resultCount;
          this.pageSize = res.pageSize;
          this.currentPageIndex = 0;
        },
        (error) => {
          console.log(error);
        }
      );

    return false;
  }

  selectedSort: number = 0;
  sorting: boolean;

  sortStaff(): void {
    this.sorting = true;
    this.getSortedNewStaff(this.selectedSort);
  }

  getSortedNewStaff(sortOrder: any): void {
    const page = this.currentPageIndex;
    let search = this.staffSearchInput.nativeElement.value;
    this.api
      .getCompanyGroupNewStaff(page, this.pageSize, search, sortOrder)
      .subscribe(
        (res: any) => {
          this.staffList = res.result;
          this.pageCount = Math.ceil(res.resultCount / res.pageSize);

          this.pages = new Array(Math.ceil(res.resultCount / res.pageSize));

          // Update paginator settings
          this.totalItems = res.resultCount;
          this.pageSize = res.pageSize;
          this.currentPageIndex = page;

          this.sorting = false;
        },
        (error) => {
          this.sorting = false;
        }
      );
  }

  removeMessages() {
    this.moveSuccess = false;
    this.moveFailed = false;
    this.warningsCompany = false;
    this.warningsRole = false;
  }

  AddCompany(index: number, isNew: boolean) {
    this.staffList[index]["addedCompanies"].push({ new: isNew });
  }

  isCompanyAdded(staff: any, customer: any): boolean {
    return staff.addedCompanies.some(
      (x: any) => x.company === customer.companyId
    );
  }

  RemoveCompany(index: number, r_id) {
    this.staffList[index]["addedCompanies"].splice(r_id, 1);
  }

  makeTrueForAWhile(callback: (arg) => void, expositionTime: number): void {
    callback(true);
    setTimeout(() => {
      callback(false);
    }, expositionTime);
  }

  moveSuccessNotice = (
    () => (arg: boolean) =>
      (this.moveSuccess = arg)
  )();
  moveFailedNotice = (
    () => (arg: boolean) =>
      (this.moveFailed = arg)
  )();
  warningsCompanyNotice = (
    () => (arg: boolean) =>
      (this.warningsCompany = arg)
  )();
  warningsRoleNotice = (
    () => (arg: boolean) =>
      (this.warningsRole = arg)
  )();
  deleteFailedNotice = (
    () => (arg: boolean) =>
      (this.deleteFailed = arg)
  )();

  moveSelected(staff: any) {
    this.editNewStaff = false;
    this.removeMessages();
    const data: NewStaff[] = [];
    const newLocations: string[] = [];
    let moveSuccessUnderWork = true;

    // this.makeTrueForAWhile(this.moveSuccessNotice, 3000)

    for (const companyAndRole of staff["addedCompanies"]) {
      data.push({
        staffId: staff.identityId,
        companyId: companyAndRole.company,
        firstName: staff.firstName,
        familyName: staff.familyName,
        email: staff.email,
        staffRole: companyAndRole.role,
        IdentityId: staff.identityId,
        locationStaffId: companyAndRole.staffId,
      });

      if (typeof companyAndRole.company === "undefined") {
        this.makeTrueForAWhile(this.warningsCompanyNotice, 10000);
        // this.moveSuccess = false;
        moveSuccessUnderWork = false;
      }

      if (typeof companyAndRole.role === "undefined") {
        this.makeTrueForAWhile(this.warningsRoleNotice, 10000);
        // this.moveSuccess = false;
        moveSuccessUnderWork = false;
      }

      if (companyAndRole.new) {
        newLocations.push(this.getLocationName(companyAndRole.company));
      }
    }

    if (moveSuccessUnderWork) {
      this.api.setStaffCompanyLocation(data).subscribe(
        (res) => {
          this.makeTrueForAWhile(this.moveSuccessNotice, 10000);
          staff.locationList.push(...newLocations);
          this.moveFailed = false;
        },
        (err) => {
          this.makeTrueForAWhile(this.moveFailedNotice, 3000);
          this.moveSuccess = false;
        }
      );
    }
    this.closeEditCard();
  }

  getLocationName(companyId: string): string {
    return this.customers?.find(c => c.companyId === companyId).company ?? "";
  }

  /**
   *
   * @param data // jotain data
   */
  initNewStaffList(data: any): void {
    this.staffList = data.result;
    this.totalItems = data.resultCount;

    this.staffPageData.resultCount = data.resultCount;
    this.staffPageData.pageSize = data.pageSize;

    this.pages = new Array(
      Math.ceil(this.staffPageData.resultCount / this.staffPageData.pageSize)
    );

    this.pageCount = Math.ceil(
      this.staffPageData.resultCount / this.staffPageData.pageSize
    );

    this.filteredStaffs = this.staffList;

    fromEvent(this.staffSearchInput.nativeElement, "keyup")
      .pipe(
        // get value
        map((event: any) => event.target.value),
        // Time in milliseconds between key events
        debounceTime(50),
        // If previous query is different from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((query: string) => (this.staffFilter = query.toLowerCase()));
  }

  /**
   *
   * @param pageEvent
   */
  handlePageEvent(pageEvent: PageEvent) {
    if (this.staffFilter !== undefined && this.staffFilter !== "") {
      this.searchStaff(pageEvent.pageIndex);
    } else {
      this.pageSize = pageEvent.pageSize;
      this.currentPageIndex = pageEvent.pageIndex;

      this.api
        .getCompanyGroupNewStaff(
          pageEvent.pageIndex,
          this.pageSize,
          null,
          this.selectedSort
        )
        .subscribe((res) => {
          this.totalItems = res.resultCount;
          this.initNewStaffList(res);

          this.dataLoaded = true;
        });
    }
  }

  ngOnInit(): void {
    const getCompanyGroups = this.api.getCompanyGroups();
    const getNewStaff$ = this.api.getCompanyGroupNewStaff(
      this.currentPageIndex,
      this.pageSize
    );

    const subscription = forkJoin([getNewStaff$, getCompanyGroups]).subscribe(
      ([getNewStaffResult, companyGroups]) => {
        this.customers = companyGroups;
        this.initNewStaffList(getNewStaffResult);

        this.dataLoaded = true;
      },
      (error) => {
        console.log(error);
      }
    );

    if (subscription) {
      this.subscriptions.push(subscription);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  Renderer2,
  OnDestroy,
} from "@angular/core";
import { fromEvent, Subject, Observable, Subscription } from "rxjs";
import {
  switchMap,
  debounceTime,
  distinctUntilChanged,
  map,
  throttleTime,
} from "rxjs/operators";
import * as moment from "moment-timezone";
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  DeviceCompanyGroup,
  IDeviceInventoryFilters,
  IDeviceTypeSummary,
  DeviceCSV,
  DeviceSorter,
  IDeviceInventory,
  Device,
  DeviceSortKey,
} from "../../../models/device.model";
import { CompanyGroupLocation } from "app/models/location.model";
import { MatExpansionPanel, MatExpansionModule } from "@angular/material/expansion";
import { ApiService } from "../../../services/api.service";
import { CookieService } from "ngx-cookie-service";
import { HelperService, SortOrder } from "../../../services/helper.service";
import { MatSelect } from "@angular/material/select";
import { DeviceBindingTimePipe, DeviceTypeCodePipe, DeviceTypePipe, HighlightSearch } from "../../../pipes/pipes";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from "@angular/material/divider";
import { DeviceAddInventoryComponent } from "./device-add-inventory/device-add-inventory.component";
import { NgFor, CommonModule } from "@angular/common";
import { MatPaginatorIntl, MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { i18nPaginator } from "../new-staff/i18nPaginator";
import { Angular2Csv } from "angular2-csv";
import { FeatureBadgeComponent } from "app/components/feature-badge/feature-badge.component";
import { DeviceStatusResolverPipe } from "./device-status-resolver.pipe";
import { LocalStorageService } from "app/services/local-storage.service";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DeviceInformationDialogComponent, IDeviceInformationDialogData } from "./device-information/device-information-dialog/device-information-dialog.component";
import { error } from "console";

type GetDeviceInventoryResponse = { pageCount: number; deviceList: any[]; numberOfDevices: number };

@Component({
  selector: "companygroup-devices",
  templateUrl: "./devices.component.html",
  styleUrls: ["./devices.component.css"],
  standalone: true,
  providers: [{ provide: MatPaginatorIntl, useClass: i18nPaginator }],
  imports: [
    CommonModule,
    MatPaginatorModule,
    DeviceAddInventoryComponent,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatDividerModule,
    MatCheckboxModule,
    TranslateModule,
    HighlightSearch,
    FeatureBadgeComponent,
    DeviceStatusResolverPipe,
    DeviceTypePipe,
    DeviceTypeCodePipe,
    DeviceBindingTimePipe,
    MatProgressSpinnerModule
  ]
})
export class CompanygroupDevicesComponent implements OnInit, OnDestroy {
  constructor(
    public dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private api: ApiService,
    private helper: HelperService,
    private renderer: Renderer2,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private scrollStrategyOptions: ScrollStrategyOptions
  ) {
    // Check if clicked outside filter dialog to close it
    this.renderer.listen("window", "click", (e) => {
      if (this.showFilterDialog) {
        if (
          !this.toggleFilterDialog.nativeElement.contains(e.target) &&
          !this.filterMenu.nativeElement.contains(e.target) &&
          e.target.id != "menuicon"
        ) {
          this.showFilterDialog = false;

          let element = document.getElementById("tab-group");
          if (element) {
            element.style.overflow = "hidden";
          }
        }
      }
    });

    // Cancel previous http request if a new device filter or sort order is selected
    this.output = this.getDeviceInventorySubject$.pipe(
      switchMap(() => {
        return this.api.getCompanyGroupDeviceInventory(this.selectedFilters);
      })
    );
    this.output.subscribe(
      (res: GetDeviceInventoryResponse) => {
        this.parsePageData(res);
        this.pageLoaded = true;
      },
      (error) => {
        console.log(error);
        this.pageLoaded = true;
      }
    );
  }

  private getDeviceInventorySubject = new Subject<any>();
  getDeviceInventorySubject$ = this.getDeviceInventorySubject.asObservable();
  output: Observable<any>;

  locationForm: UntypedFormGroup;

  @Input()
  customerSelectionData: any;

  companyGroupLocations: CompanyGroupLocation[] = [];
  companyGroupDevices: DeviceCompanyGroup[] = [];
  companyGroupDevicesAll: DeviceCompanyGroup[] = [];

  @ViewChild("toggleFilterDialog") toggleFilterDialog: ElementRef;
  @ViewChild("filterMenu") filterMenu: ElementRef;

  @ViewChild(MatExpansionPanel, { static: true })
  matExpansionPanel: MatExpansionPanel;
  @ViewChild("deviceSearchInput", { static: true })
  deviceSearchInput: ElementRef;

  _deviceFilter: string;
  filteredDevices: DeviceCompanyGroup[] = [];
  devTypeSummary: IDeviceTypeSummary[];

  showFilterDialog: boolean = false;
  showFilterDialogAllowed: boolean = true;

  bindingTimeVisible: boolean = false; // Default value for binding time visibility is hidden

  formSubmitSubject = new Subject();
  private languageSubscription: Subscription;

  filterDialog() {
    this.showFilterDialog = !this.showFilterDialog;

    const element = document.getElementById("tab-group");
    if (element) {
      element.style.overflow = this.showFilterDialog ? "visible" : "hidden";
    }
  }

  searchTimeout = null;

  get deviceFilter(): string {
    return this._deviceFilter;
  }

  set deviceFilter(value: string) {
    this._deviceFilter = value;
    if (value.length === 0) {
      this.dataSource = this.companyGroupDevicesAll.length ? this.companyGroupDevicesAll : this.companyGroupDevices;
      if (this.pageCountMax > 0) this.pageCount = this.pageCountMax;

    } else {
      if (!this.companyGroupDevicesAll.length) {
        this.companyGroupDevicesAll = this.companyGroupDevices;
        this.pageCountMax = this.pageCount;
      }

      this.filteredDevices = this.transform(this.companyGroupDevices, value);
      this.dataSource = this.filteredDevices;
    }
  }

  oldSearchText = "";

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    searchText = searchText.toLocaleLowerCase();

    if (searchText.length != this.oldSearchText.length) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => this.search(), 1000);
    }

    this.oldSearchText = searchText;

    return items.filter((it) => {
      return (
        (typeof it.devTypeName !== "undefined"
          ? it.devTypeName.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.deviceName !== "undefined"
          ? it.deviceName.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.location !== "undefined"
          ? it.location.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.locationAddress !== "undefined"
          ? it.locationAddress.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.resident !== "undefined"
          ? it.resident.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.sSnumber !== "undefined"
          ? it.sSnumber.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.devId !== "undefined"
          ? it.devId.toLocaleLowerCase().includes(searchText)
          : false) ||
        (typeof it.leasingTime !== "undefined"
          ? it.leasingTime.includes(searchText)
          : false)
      );
    });
  }

  registryUpdated: boolean = false;
  registryUpdatedMany: boolean = false;
  registryUpdatedError: boolean = false;
  registryUpdatedManyError: boolean = false;

  deviceIdAdded: string = "";

  pageCount: number;
  pageCountMax: number;
  selectedPageIndex = 0;

  pageInited: boolean = false;
  pageLoaded: boolean = true;
  dataSource = null;
  originalDevices = null;

  // initial settings for the paginator
  currentPageIndex = 0;
  pageSize = 100;
  numberOfDevices = 0;

  handlePageEvent(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize
    this.currentPageIndex = pageEvent.pageIndex
    this.selectedFilters.search = this.deviceSearchInput.nativeElement.value;
    this.selectPage(pageEvent.pageIndex)
  }

  selectPage(page: number) {
    this.pageLoaded = false;
    this.selectedFilters.pageIndex = page;
    this.selectedFilters.pageSize = this.pageSize;

    this.api.getCompanyGroupDeviceInventory(this.selectedFilters).subscribe(
      (res: GetDeviceInventoryResponse) => {
        this.parsePageData(res);

        // Apply sorting by device status if the sorting is selected
        if (this.selectedFilters.sortKey === DeviceSorter.Status && this.selectedFilters.sortBy !== SortOrder.Default) {
          this.dataSource = this.sortByDeviceStatus(this.dataSource);
        }

        this.deviceFilter = this.deviceSearchInput.nativeElement.value.toLowerCase();
        this.pageLoaded = true;
      },
      (error) => {
        console.log();
        this.pageLoaded = true;
      }
    );
    this.selectedPageIndex = page;
  }

  filterDevices(): void {
    this.pageLoaded = false;
    this.selectedFilters.pageIndex = 0;

    this.getDeviceInventorySubject.next(this.selectedFilters);
  }

  search(): void {
    this.pageLoaded = false;
    this.selectedFilters.pageIndex = 0;
    this.selectedPageIndex = 0;

    const search = this.deviceSearchInput.nativeElement.value;
    this.selectedFilters.search = search;

    this.api.getCompanyGroupDeviceInventory(this.selectedFilters).subscribe(
      (res: GetDeviceInventoryResponse) => {
        this.parsePageData(res);
        this.pageLoaded = true;
      },
      (error) => {
        console.log(error);
        this.pageLoaded = true;
      }
    );
  }

  showAddProductDialog = false;

  onSelected(
    templateRef: MatSelect,
    device: DeviceCompanyGroup,
    event: Event
  ): void {
    event.stopPropagation();

    // Get templates value
    const selectedLocationId = templateRef.value;

    // Create deep copy from selected device
    let deviceCopy: DeviceCompanyGroup = JSON.parse(JSON.stringify(device));
    deviceCopy.leasingTime = typeof deviceCopy.leasingTime === 'undefined' ? moment(new Date()).format() : deviceCopy.leasingTime;

    if (selectedLocationId == "null") {
      this.api.addDeviceToCustomerInventory(deviceCopy).subscribe((res) => {

      });
    } else {

      // Check if the selected location's company ID is different to device's current
      const selectedCompanyId = this.getCompanyData(selectedLocationId).companyID;
      //const companyLocationChanged = selectedCompanyId !== device.companyId ? true : false;

      // Temp
      const companyLocationChanged = false;

      // Set selected values
      deviceCopy.locationId = selectedLocationId;
      deviceCopy.companyId = selectedCompanyId;
      deviceCopy.devType = device.devType;

      this.api
        .updateBaseDeviceLocation(deviceCopy, companyLocationChanged)
        .subscribe(
          (updatedDevice: DeviceCompanyGroup) => {
            // If location update was success, update location information in table row
            if (updatedDevice) {
              const index = this.filteredDevices.findIndex(
                (x) => x.devId === updatedDevice.devId
              );
              updatedDevice.devTypeName = device.devTypeName;
              this.filteredDevices[index] = updatedDevice;
              this.dataSource = this.filteredDevices;
            } else {
              alert("Failed to update device location");
              templateRef.value = device.locationId;
            }
          },
          (error) => {
            console.log(error);
            alert("Failed to update device location");
            templateRef.value = device.locationId;
          }
        );
    }

  }

  getCompanyData(locationId: string): CompanyGroupLocation {
    return this.companyGroupLocations.find((e) => e.locationID === locationId);
  }

  removeDuplicateLocation(locationsList: any[], deviceLocation: string): any {
    return locationsList?.filter((e) => e.locationName !== deviceLocation);
  }

  isDeviceBasestation(device: any): boolean {
    // Get device type from valid property key
    const devType = device.devTypeCode ?? device.devType;
    return devType.substring(0, 2) === "31" ? true : false;
  }

  openAddProductDialog() {
    this.showAddProductDialog = true;
  }

  closeAddBaseForm(): void {
    this.showAddProductDialog = false;
  }

  headerInfo = [
    {
      headName: "DEVICE_TYPE",
      sorter: DeviceSorter.DeviceType,
      class: "DEVICE_TYPE", // class name for the style
    },
    {
      headName: "DEVICE_NAME",
      sorter: DeviceSorter.DeviceName,
      class: "DEVICE_NAME",
    },
    {
      headName: "SERIAL_NUMBER",
      sorter: DeviceSorter.DeviceId,
      // Urgent and bad solution to fix table width issue when binding time is hidden
      class: this.bindingTimeVisible ? "SERIAL_NUMBER" : "SERIAL_NUMBER_NOBINDING",
    },
    {
      headName: "LOCATION",
      sorter: DeviceSorter.Location,
      class: "LOCATION",
    },
    {
      headName: "ADDRESS",
      sorter: DeviceSorter.Address,
      class: "ADDRESS",
    },
    {
      headName: "RESIDENT",
      sorter: DeviceSorter.Resident,
      class: "RESIDENT",
    },
    {
      headName: "SSNUMBER",
      sorter: DeviceSorter.SSnumber,
      class: "SSNUMBER",
    },
    {
      headName: "BINDING_TIME",
      sorter: DeviceSorter.BindingTime,
      class: "BINDING_TIME",
    },
    {
      headName: "STATUS",
      sorter: DeviceSorter.Status,
      class: "STATUS",
    }
  ];

  getDeviceType(type: string): string {
    return this.helper.getDeviceType(type);
  }

  getLocationName(id: string): string {
    return (
      this.companyGroupLocations.find((x) => x.locationID === id)
        .locationName ?? id
    );
  }

  isLocationFilterChecked(id: string, $event: any): void {
    const checked = $event.checked;
    if (checked) {
      this.selectedFilters.locations.push(id);
    } else {
      this.selectedFilters.locations = this.selectedFilters.locations.filter(
        (str) => str !== id
      );
    }
    this.filterDevices();
  }

  isDeviceFilterChecked(id: string, $event: any): void {
    const checked = $event.checked;
    if (checked) {
      this.selectedFilters.deviceTypes.push(id);
    } else {
      this.selectedFilters.deviceTypes =
        this.selectedFilters.deviceTypes.filter((str) => str !== id);
    }
    this.filterDevices();
  }

  formData: FormData = new FormData();
  uploadDeviceInventoryCsvFile: UntypedFormGroup;

  createUploadInventoryCsvFile(): void {
    this.uploadDeviceInventoryCsvFile = this._formBuilder.group({
      file: [null, Validators.required],
    });
  }

  CSVToUpload: File | null = null;

  handleCSVInput(e) {
    this.CSVToUpload = e.target.files[0];
  }

  uploadCSVFile(e: Event) {
    e.preventDefault();
    const formData = new FormData();
    // console.log(this.CSVToUpload);
    formData.append("file", this.CSVToUpload);
    // console.log(formData);
    this.api.uploadDeviceInventoryCsv(formData).subscribe((res) => {
      this.devicesAdded(res);
    });
  }

  async loadDevices(): Promise<void> {
    this.pageLoaded = false;
    this.api.getCompanyGroupLocations().subscribe((res) => {
      this.companyGroupLocations = res;
      // Collect locations for the filtering
      this.locationFilters = this.companyGroupLocations?.map(
        (x) => x.locationID
      );
    });
    await this.initCompanyGroupDeviceInventory();
    this.pageInited = true;
  }

  async initCompanyGroupDeviceInventory(): Promise<void> {
    await new Promise<void>((r) => {
      this.selectedFilters.language = localStorage.getItem("language");
      this.api.getCompanyGroupDeviceInventory(this.selectedFilters).subscribe(
        (res: GetDeviceInventoryResponse) => {
          this.parsePageData(res);
          r();
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  parsePageData(data: GetDeviceInventoryResponse): void {
    this.numberOfDevices = data.numberOfDevices;
    this.companyGroupDevices = data.deviceList
    this.pageCount = data.pageCount || 1;
    this.filteredDevices = this.companyGroupDevices;
    this.dataSource = this.filteredDevices;
    this.originalDevices = [...this.dataSource];
    this.pageLoaded = true;
  }

  deviceTypeFilters: string[];
  locationFilters: string[];

  selectedFilters: IDeviceInventoryFilters = {
    pageSize: 100,
    pageIndex: 0,
    search: "",
    language: localStorage.getItem("language"),
    deviceTypes: [],
    locations: [],
    statusOnline: false,
    statusOfflineUnder24h: false,
    statusOfflineOver24h: false,
    statusLowBattery: false,
    statusInCustomerStock: false,
    statusInEveronStock: false,
    statusInService: false,
    statusLost: false,
    sortKey: DeviceSorter.DeviceType,
    sortBy: SortOrder.Default,
  };

  downloadCsv() {
    this.selectedFilters.pageSize = this.numberOfDevices;
    this.api.getCompanyGroupDeviceInventory(this.selectedFilters).subscribe((res: { pageCount: number; deviceList: IDeviceInventory[]; numberOfDevices: number }) => {

      var deviceCSV: DeviceCSV[] = [];

      res.deviceList.forEach((r) => {

        var statusText = "";

        this.translate.get(["STOCK_FILTER", "Online", "OfflineUnder24h", "OfflineOver24h", "BATTERY_STATUS_WARNING", "SERVICE_OR_REPAIR"]).subscribe((t) => {
          if (r.statusInEveronStock) statusText = t.STOCK_FILTER;
          if (r.statusOnline) statusText = t.Online;
          if (r.statusOfflineUnder24h) statusText = t.OfflineUnder24h;
          if (r.statusOfflineOver24h) statusText = t.OfflineOver24h;
          if (r.statusLowBattery) statusText = t.BATTERY_STATUS_WARNING;
          if (r.statusInService) statusText = t.SERVICE_OR_REPAIR;
        });

        var adds = r.locationAddress.split(' ');
        var city = adds[adds.length - 1];
        var postalCode = adds[adds.length - 2];

        city = (typeof city === 'undefined') ? "" : city;
        postalCode = (typeof postalCode === 'undefined') ? "" : postalCode;
        var leasingTime = r.leasingTime;
        var dateStr = typeof r.leasingTime === 'undefined' ? "undefined" : (leasingTime.replace('T', ' ')).substring(0, leasingTime.length - 1);

        deviceCSV.push({
          id: `="${r.devId}"`,
          name: r.deviceName,
          company: r.company,
          location: r.location,
          address: r.locationAddress,
          postalCode: `="${postalCode}"`,
          city: city,
          gsm: `="${r.gsmNumber}"`,
          productType: this.helper.getDeviceType(r.devType),
          softwareVersion: r.swrel,
          dateAdded: `="${dateStr}"`,
          additionalInfo: /^[0-9]*$/.test(r.additionalInfo) ? `="${r.additionalInfo}"` : `${r.additionalInfo}`,
          deviceInfo: /^[0-9]*$/.test(r.deviceInfo) ? `="${r.deviceInfo}"` : `${r.deviceInfo}`,
          status: statusText,
        });
      })
      let filename = "devices-" + moment().format("YYYY-MM-DD");

      var headers = [];

      this.translate.get(["CUSTOM_DEVICE_ID", "NAME", "COMPANY", "LOCATION", "ADDRESS", "POSTCODE", "POSTOFFICE", "GSM_NUMBER", "DEVICE_TYPE", "DEVICE_SOFTWARE_VERSION", "DATEADDED", "DEVICE_ADDITIONAL_INFO_SHORT", "DEVICE_INFORMATION", "STATUS"]).subscribe((t) => {
        var translatedHeaders = Object.entries(t);
        for (const [key, value] of translatedHeaders)
          headers.push(value);
      });

      let options = {
        fieldSeparator: ";",
        quoteStrings: '"',
        decimalseparator: ".",
        showLabels: true,
        showTitle: false,
        headers: headers
      };

      new Angular2Csv(deviceCSV, filename, options);

    });

  }

  checkBindingTimeVisibility(): void {
    // Check if binding time is visibility to determine if the binding time column should be shown
    this.api.checkBindingTimeVisibility().subscribe((res) => {
      this.bindingTimeVisible = res;

      // Remove binding table header if it is not visible
      if (!this.bindingTimeVisible) {
        this.headerInfo = this.headerInfo.filter((x) => x.headName !== "BINDING_TIME");
      }
    },
      error => {
        console.log(error);
        this.bindingTimeVisible = false;

        if (!this.bindingTimeVisible) {
          this.headerInfo = this.headerInfo.filter((x) => x.headName !== "BINDING_TIME");
        }
      });
  }

  ngOnInit(): void {

    this.checkBindingTimeVisibility();

    this.loadDevices();

    this.languageSubscription = this.localStorageService.currentLanguage.subscribe((language) => {
      this.loadDevices();
    });

    this.api.getCompanyGroupInventorySummary().subscribe(
      (res: IDeviceTypeSummary[]) => {
        this.devTypeSummary = [];
        this.devTypeSummary = res;
        // Collect device types for filtering
        this.deviceTypeFilters = this.devTypeSummary.map((x) => x.deviceType);
      },
      (error) => {
        this.devTypeSummary = [];
      }
    );

    this.createUploadInventoryCsvFile();

    // Check typing for the search bar
    fromEvent(this.deviceSearchInput.nativeElement, "keyup")
      .pipe(
        // get value
        map((event: any) => event.target.value),
        // Time in milliseconds between key events
        debounceTime(50),
        // If previous query is different from current
        distinctUntilChanged()
        // subscription for response
      )
      .subscribe((query: string) => (this.deviceFilter = query.toLowerCase()));

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe((event: Event) => {
        this.uploadCSVFile(event);
      });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
    this.getDeviceInventorySubject.unsubscribe();
    this.languageSubscription.unsubscribe();
  }

  openDialog(device: DeviceCompanyGroup): void {
    const data: IDeviceInformationDialogData = {
      device: device,
      bindingTimeVisible: this.bindingTimeVisible,
    }

    this.dialog.open(DeviceInformationDialogComponent, {
      width: "900px",
      disableClose: true,
      autoFocus: false,
      data: data,
      scrollStrategy: this.scrollStrategyOptions.block(),
      position: { top: '5%' },
    });
  }

  sort(sorter: DeviceSorter) {

    if (typeof sorter === "undefined") {
      return
    }

    // Reset the sort order cycle if the selected key is changed
    if (sorter !== this.selectedFilters.sortKey) { // i e if the user clicks on a different column
      this.selectedFilters.sortBy = SortOrder.Default;
    }
    this.selectedFilters.sortKey = sorter;

    // make one step forward through the sort order cycle
    this.updateSortOrder();

    if (this.selectedFilters.sortKey === DeviceSorter.Status) {
      // If sort order is default, return the original unsorted devices
      if (this.selectedFilters.sortBy === SortOrder.Default) {
        this.dataSource = [...this.originalDevices];
      } else {
        this.dataSource = this.sortByDeviceStatus(this.dataSource);
      }
      return;
    }

    this.pageLoaded = false;
    this.getDeviceInventorySubject.next(this.selectedFilters);
  }

  sortByDeviceStatus(unsortedDevices: DeviceCompanyGroup[]): DeviceCompanyGroup[] {

    const statusResolver = new DeviceStatusResolverPipe();

    const sortedDevices = unsortedDevices.sort((a, b) => {
      const statusA = DeviceSortKey[statusResolver.transform(a)];
      const statusB = DeviceSortKey[statusResolver.transform(b)];

      if (statusA < statusB) {
        return this.selectedFilters.sortBy === SortOrder.Asc ? -1 : 1;
      } else if (statusA > statusB) {
        return this.selectedFilters.sortBy === SortOrder.Asc ? 1 : -1;
      } else {
        return;
      }
    });
    return sortedDevices;
  }

  private updateSortOrder() {
    switch (this.selectedFilters.sortBy) {
      case SortOrder.Default:
        this.selectedFilters.sortBy = SortOrder.Asc;  // was 0 becomes 1
        break;
      case SortOrder.Asc:
        this.selectedFilters.sortBy = SortOrder.Desc; // was 1 becomes -1
        break;
      case SortOrder.Desc:
        this.selectedFilters.sortBy = SortOrder.Default; // was -1 becomes 0
        break;
    }
  }

  deviceAdded(id: string) {
    this.registryUpdated = true;
    this.deviceIdAdded = id;
    console.log(id);
    setTimeout(() => (this.registryUpdated = false), 3000);
  }

  devsAddedToReg = 0;

  devicesAdded(count: number) {
    if (!isNaN(count)) {
      this.registryUpdatedMany = true;
    }
    this.devsAddedToReg = count;
    setTimeout(() => (this.registryUpdatedMany = false), 3000);
  }

  deviceAddedError() {
    this.registryUpdatedError = true;
  }

  devicesAddedError() {
    this.registryUpdatedManyError = true;
  }
}

@Component({
  selector: "moreinformation",
  templateUrl: "./moreinformation.component.html",
  standalone: true,
  imports: [NgFor, TranslateModule]
})
export class MoreDeviceInformationDialog implements OnInit {
  constructor(
    private api: ApiService,
    private cookies: CookieService,
    public dialogRef: MatDialogRef<MoreDeviceInformationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DeviceCompanyGroup
  ) { }

  histories: [];

  ngOnInit(): void {
    this.api.getDeviceInventoryMovementHistory(this.data.devId).subscribe(
      (res) => {
        this.histories = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

import { Injectable, OnDestroy } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { Subject, noop } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

@Injectable()
export class i18nPaginator implements MatPaginatorIntl {
  unsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private translate: TranslateService
  ) {
    this.getTranslations()
  }
  changes = new Subject<void>();

  firstLabelTranslated;
  // this.translate.get("FIRST_PAGE").subscribe(data => this.firstLabelTranslated = data)

  firstPageLabel = `First page!`;
  itemsPerPageLabel = `Items per page:::`;
  lastPageLabel = `Last page!`;

  // You can set labels to an arbitrary string too, or dynamically compute
  // it through other third-party internationalization libraries.
  nextPageLabel = 'Next page!';
  previousPageLabel = 'Previous page!';
  page = "Page";
  translateSub: any;

  ngOnDestroy() {
    this.translateSub.unsubscribe()
  }

  getTranslations() {
    this.translateSub = this.translate.get([
      'ITEMS_PER_PAGE',
      'FIRST_PAGE_LABEL',
      'PREVIOUS_PAGE',
      'NEXT_PAGE',
      'LAST_PAGE_LABEL',
      'PAGE'
    ])
      .subscribe(translation => {
        this.itemsPerPageLabel = translation['ITEMS_PER_PAGE'];
        this.firstPageLabel = translation['FIRST_PAGE_LABEL'];
        this.previousPageLabel = translation['PREVIOUS_PAGE'];
        this.nextPageLabel = translation['NEXT_PAGE'];
        this.lastPageLabel = translation['LAST_PAGE_LABEL'];
        this.page = translation['PAGE'];
        this.changes.next();
      });
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {

    const amountPages = Math.ceil(length / pageSize);
    return `${this.page} ${page + 1} / ${amountPages}`;
  }
}

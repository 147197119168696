<div mat-dialog-content class="p-3">
    <div>
        <p>
            1. {{'TWO_FACTOR_INFO_STEP1' |translate}}
        </p>
        <p>
            2. {{'TWO_FACTOR_INFO_STEP2' |translate}}
        </p>
        <p>
            <img [src]="data.qrCode" /><br> {{'MANUAL_CODE' |translate}}: {{data.manualCode}}
        </p>
        <p>
            3. {{'TWO_FACTOR_INFO_STEP3' |translate}}
        </p>
        <div>
            <input type="text" class="form-control" name="confirmCodeElement" #confirmCodeElement="ngModel" [(ngModel)]="confirmCode">
        </div>
    </div>
    <div class="mt-3">
        <button class="btn btn-primary" (click)="confirmTwoFactor()">{{'CONFIRM' |translate}}</button>
        <button class="btn btn-outline-danger ml-2" (click)="cancelTwoFactor()">{{'CANCEL' |translate}}</button>
    </div>
</div>
<h3 class="ml-3">{{ "COMPANY_MANAGEMENT" | translate }}</h3>

<div style="margin: 25px 10px 0 20px">
    <div class="row">
        <div class="col-md-4">
            <button class="btn btn-primary" (click)="showAddCompanyForm.next(true); showCompanyInfo.next(false); clearMessages();">
                <span class="fa-light fa-fw fa-plus"></span>
                {{ "ADD_NEW_COMPANY" | translate }}
            </button>
             <!--
            <button class="btn btn-primary" style="margin-left: 10px">
                <span class="fa-light fa-fw fa-plus"></span>
                {{ "ADD_NEW_COMPANY_WITH_CSV" | translate }}
            </button>
            -->
        </div>

        <div style="margin: auto; width: 900px;">
            <div *ngIf="successMsg.value" class="alert alert-success" role="alert">
                {{successMsg.value | translate }}
            </div>

            <div *ngIf="failedMsg.value" class="alert alert-danger" role="alert">
                {{failedMsg.value | translate }}
            </div>
        </div>

    </div>

    <div class="row" style="margin-top: 20px">
        <div class="col-sm-4">
            <div class="w-100 card" style="height: 740px">
                <div class="card-header-white header-underline">
                    <h6 class="white-header-text">
                        {{ "COMPANIES" | translate }}
                    </h6>
                </div>

                <img *ngIf="!listedCompanies" class="fadeLoader centerLoader verticalCenterLoader logoSmall" src="assets/img/loading_logo.svg">

                <div *ngIf="listedCompanies" class="mr-2 ml-2">

                    <div class="input-group input-group-sm mt-2 mb-4"
                        style="width: 95%; margin-right: auto; margin-left: auto;"
                    >
                        <input
                            matInput
                            class="form-control"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            placeholder="{{ 'SEARCH' | translate }}"
                            [formControl]="companySearchFilter"
                            (keyup)="filterCompanies()"
                        />
                    </div>

                    <div style="height: 590px; overflow: auto">
                        <mat-list>
                            <mat-list-item
                                *ngFor="let company of listedCompanies"
                                (click)="showAddCompanyForm.next(false); companySelectEvent(company.companyId, company.server); clearMessages();"
                                >{{ company.companyName }}
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-8">
            <div class="w-90">
                <company-add
                    *ngIf="showAddCompanyForm.value"
                    [showForm]="showAddCompanyForm"
                    [successMsg]="successMsg"
                    [failedMsg]="failedMsg"
                    (companyAdded)="refreshCompanies($event);"
                ></company-add>
                <company-info
                    *ngIf="showCompanyInfo.value"
                    [showInfoForm]="showCompanyInfo"
                    [company]="selectedCompany"
                    [successMsg]="successMsg"
                    [failedMsg]="failedMsg"
                    (companyUpdated)="refreshCompanies($event);"
                ></company-info>
            </div>
        </div>
    </div>
</div>

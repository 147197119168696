<div class="card" style="width: 80%; margin-left: auto; margin-right: auto">
    <div class="card-header-white header-underline">
        <h6 class="white-header-text">
            {{ "ADD_NEW_COMPANY" | translate }}
        </h6>
        <span class="float-right cursorPointer" (click)="showForm.next(false)">
            <span class="fa-light fa-xmark white-header-xmark-icon"></span>
        </span>
    </div>

    <form
        role="form"
        #registerCompany="ngForm"
        (ngSubmit)="registerCompany.form.valid && formSubmitSubject.next()"
        style="height: 710px"
        novalidate
    >
        <div style="height: 100%; overflow-x: hidden; overflow-y: auto">
            <div class="ml-4 mr-4">
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="company-name">
                        {{ "COMPANY_NAME" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="
                                name.errors?.required || name.errors?.pattern
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="company-name"
                            name="company-name"
                            #name="ngModel"
                            maxlength="35"
                            pattern="^[a-zA-Z0-9à-üÀ-Ü/\//().,'`-]+[a-zA-Z0-9à-üÀ-Ü\s/\//().,'`-]*$"
                            [(ngModel)]="form.companyName"
                            required
                        />
                        <span
                            class="text-danger"
                            *ngIf="!name.value && name.errors?.required"
                        >
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>
                        <span class="text-danger" *ngIf="name.errors?.pattern">
                            {{ "INVALID_CHARACTERS" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" for="company-group">
                        {{ "COMPANY_GROUP" | translate }}:
                    </label>

                    <div class="col-sm-8">
                        <select
                            class="form-control form-control-sm is-valid-checkmark"
                            [ngClass]="{
                                'is-valid': companyGroup.value
                            }"
                            id="company-group"
                            name="company-group"
                            #companyGroup="ngModel"
                            [(ngModel)]="form.companyGroupId"
                            (ngModelChange)="onCompanyGroupSelected()"
                        >
                            <option value="">-</option>
                            <option
                                [value]="group.companyGroupId"
                                *ngFor="let group of companyGroupList"
                            >
                                {{ group.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="address">
                        {{ "ADDRESS" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="
                                address.errors?.required ||
                                address.errors?.pattern
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="address"
                            name="address"
                            #address="ngModel"
                            maxlength="50"
                            pattern="^[a-zA-Z0-9à-üÀ-Ü/\//().,'`-]+[a-zA-Z0-9à-üÀ-Ü\s/\//().,'`-]*$"
                            [(ngModel)]="form.address"
                            required
                        />
                        <span
                            class="text-danger"
                            *ngIf="!address.value && address.errors?.required"
                        >
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>
                        <span
                            class="text-danger"
                            *ngIf="address.errors?.pattern"
                        >
                            {{ "INVALID_CHARACTERS" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="postal-code">
                        {{ "POSTCODE" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="
                                postalCode.errors?.required ||
                                postalCode.errors?.pattern
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="postal-code"
                            name="postal-code"
                            #postalCode="ngModel"
                            maxlength="10"
                            pattern="^[a-zA-Z0-9à-üÀ-Ü]+[a-zA-Z0-9à-üÀ-Ü\s-]*$"
                            [(ngModel)]="form.postCode"
                            required
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                !postalCode.value && postalCode.errors?.required
                            "
                        >
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>
                        <span
                            class="text-danger"
                            *ngIf="postalCode.errors?.pattern"
                        >
                            {{ "INVALID_CHARACTERS" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="city">
                        {{ "POSTOFFICE" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="
                                city.errors?.required
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="city"
                            name="city"
                            [(ngModel)]="form.postOffice"
                            #city="ngModel"
                            pattern="^[a-zA-Z0-9à-üÀ-Ü/\//().,'`-]+[a-zA-Z0-9à-üÀ-Ü\s/\//().,'`-]*$"
                            maxlength="35"
                            required
                        />
                        <span
                            class="text-danger"
                            *ngIf="!city.value && city.errors?.required"
                        >
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>
                        <span
                            class="text-danger"
                            *ngIf="postalCode.errors?.pattern"
                        >
                            {{ "INVALID_CHARACTERS" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" for="country">
                        {{ "COUNTRY" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <select
                            class="form-control form-control-sm is-valid-checkmark"
                            [ngClass]="
                                country.errors?.required
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="country"
                            name="country"
                            #country="ngModel"
                            [(ngModel)]="form.country"
                            required
                        >
                            <option value="">-</option>
                            <option
                                *ngFor="let tz of timezones"
                                [value]="tz.country.code"
                            >
                                {{ tz.country.name }}
                            </option>
                        </select>
                        <span
                            class="text-danger"
                            *ngIf="!country.value && country.errors?.required"
                        >
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>
                        <p *ngIf="form.country" class="mt-2 ml-1">
                            {{ "TIMEZONE" | translate }}:
                            <b>{{ setTimezone(form.country) }}</b>
                        </p>
                    </div>
                </div>

                <hr />

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="phone">
                        {{ "CONTACT_PHONE" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="
                                contactPhone.errors?.required ||
                                contactPhone.errors?.pattern
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="phone"
                            name="phone"
                            #contactPhone="ngModel"
                            pattern="[0-9+]{1}[0-9]{6,14}"
                            [(ngModel)]="form.contactPhone"
                            required
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                !contactPhone.value &&
                                contactPhone.errors?.required
                            "
                        >
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>
                        <span
                            class="text-danger"
                            *ngIf="contactPhone.errors?.pattern"
                        >
                            {{ "INVALID_CONTACT_NUMBER" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="username">
                        {{ "MAIN_USER_ACCOUNT_NAME" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="
                                username.errors?.required ||
                                username.errors?.invalidUsername
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="username"
                            name="username"
                            #username="ngModel"
                            autocomplete="new-user"
                            [(ngModel)]="form.mainUserAccount"
                            ngModel
                            appUsernameValidatorAsync
                            required
                        />
                        <span
                            class="text-danger"
                            *ngIf="!username.value && username.errors?.required"
                        >
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>

                        <span
                            class="text-danger"
                            *ngIf="
                                username.value &&
                                username.errors?.invalidUsername
                            "
                        >
                            {{ "USERNAME_IN_USE" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="user-password">
                        {{ "PASSWORD" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="password"
                            class="form-control form-control-sm"
                            [ngClass]="
                                pwd.errors?.minlength ||
                                !pwd.value ||
                                pwd.errors?.required
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="user-password"
                            name="password"
                            #pwd="ngModel"
                            minlength="6"
                            maxlength="32"
                            autocomplete="new-password"
                            [(ngModel)]="form.password"
                            required
                        />

                        <span
                            class="text-danger"
                            *ngIf="
                                pwd.errors?.minlength ||
                                !pwd.value ||
                                pwd.errors?.required
                            "
                        >
                            {{ "PASSWORD_INFO" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="verify-pwd">
                        {{ "VERIFY_PASSWORD" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="password"
                            class="form-control form-control-sm"
                            [ngClass]="
                                confirmPwd.errors?.required ||
                                !passwordMatch(
                                    form.password,
                                    form.passwordVerify
                                )
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="verify-pwd"
                            name="verify"
                            #confirmPwd="ngModel"
                            [(ngModel)]="form.passwordVerify"
                            required
                        />
                        <span
                            class="text-danger"
                            *ngIf="
                                !passwordMatch(
                                    form.password,
                                    form.passwordVerify
                                )
                            "
                        >
                            {{ "PASSWORDS_DONT_MATCH" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="user-gsm">
                        {{ "MAIN_USER_GSM" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="
                                muGSM.errors?.required || muGSM.errors?.pattern
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="user-gsm"
                            name="gsm"
                            #muGSM="ngModel"
                            pattern="[0-9+]{1}[0-9]{6,14}"
                            [(ngModel)]="form.mainUserGsm"
                            required
                        />
                        <span
                            class="text-danger"
                            *ngIf="!muGSM.value && muGSM.errors?.required"
                        >
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>
                        <span class="text-danger" *ngIf="muGSM.errors?.pattern">
                            {{ "INVALID_CONTACT_NUMBER" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="email">
                        {{ "EMAIL" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="email"
                            class="form-control form-control-sm"
                            [ngClass]="
                                email.errors?.required || email.errors?.pattern
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="email"
                            name="email"
                            #email="ngModel"
                            pattern="[a-zA-Zà-üÀ-Ü0-9._%+-]+@[a-zA-Zà-üÀ-Ü0-9.-]+\.[a-z]{2,4}$"
                            [(ngModel)]="form.email"
                            required
                        />
                        <span
                            class="text-danger"
                            *ngIf="!email.value && email.errors?.required"
                        >
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>
                        <span class="text-danger" *ngIf="email.errors?.pattern">
                            {{ "INVALID_EMAIL" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="user-fname">
                        {{ "MAIN_USER_FIRST_NAME" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="
                                fname.errors?.required || fname.errors?.pattern
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="user-fname"
                            name="fname"
                            #fname="ngModel"
                            pattern="^[a-zA-Z0-9à-üÀ-Ü/\//().,'`-]+[a-zA-Z0-9à-üÀ-Ü\s/\//().,'`-]*$"
                            [(ngModel)]="form.mainUserFirstName"
                            required
                        />
                        <span
                            class="text-danger"
                            *ngIf="!fname.value && fname.errors?.required"
                        >
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>

                        <span class="text-danger" *ngIf="fname.errors?.pattern">
                            {{ "INVALID_CHARACTERS" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="user-lname">
                        {{ "MAIN_USER_LAST_NAME" | translate }}:
                    </label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="
                                lname.errors?.required || lname.errors?.pattern
                                    ? 'is-invalid'
                                    : 'is-valid'
                            "
                            id="user-lname"
                            name="lname"
                            #lname="ngModel"
                            pattern="^[a-zA-Z0-9à-üÀ-Ü/\//().,'`-]+[a-zA-Z0-9à-üÀ-Ü\s/\//().,'`-]*$"
                            [(ngModel)]="form.mainUserLastName"
                            required
                        />
                        <span
                            class="text-danger"
                            *ngIf="!lname.value && lname.errors?.required"
                        >
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>

                        <span class="text-danger" *ngIf="lname.errors?.pattern">
                            {{ "INVALID_CHARACTERS" | translate }}
                        </span>
                    </div>
                </div>

                <hr />

                <div class="form-group row">
                    <label class="col-sm-6" for="server">
                        {{ "SERVER" | translate }}:
                    </label>
                    <div class="col-sm-6">
                        <select
                            class="form-control form-control-sm is-valid-checkmark"
                            [ngClass]="server.value ? 'is-valid' : 'is-invalid'"
                            id="server"
                            name="server"
                            #server="ngModel"
                            [(ngModel)]="form.server"
                            [disabled]="serverSelectionDisabled"
                        >
                            <option value="" selected>-</option>
                            <option
                                *ngFor="let server of serverList"
                                [value]="server"
                            >
                                {{ server }}
                            </option>
                        </select>
                        <span class="text-danger" *ngIf="!server.value">
                            {{ "REQUIRED_FIELD" | translate }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-6" for="sms-message-language">
                        {{ "SMS_MESSAGE_LANGUAGE" | translate }}:
                    </label>
                    <div class="col-sm-6">
                        <select
                            class="form-control form-control-sm is-valid-checkmark"
                            [ngClass]="{ 'is-valid': !smsLang.pristine }"
                            id="sms-message-language"
                            name="sms-message-language"
                            #smsLang="ngModel"
                            [(ngModel)]="form.gsmLang"
                        >
                            <option value="FI">
                                {{ "FINNISH" | translate }}
                            </option>
                            <option value="EN" selected>
                                {{ "ENGLISH" | translate }}
                            </option>
                            <option value="SE">
                                {{ "SWEDISH" | translate }}
                            </option>
                            <option value="DE">
                                {{ "GERMAN" | translate }}
                            </option>
                            <option value="FR">
                                {{ "FRENCH" | translate }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group row pb-4">
                    <label class="col-sm-6" for="delivery-method">
                        {{
                            "DELIVERY_METHOD_FOR_ADMINISTRATIVE_MESSAGES"
                                | translate
                        }}:
                    </label>
                    <div class="col-sm-6">
                        <select
                            class="form-control form-control-sm is-valid-checkmark"
                            [ngClass]="{ 'is-valid': !deliveryMethod.pristine }"
                            id="delivery-method"
                            name="delivery"
                            #deliveryMethod="ngModel"
                            [(ngModel)]="form.deliveryMethod"
                        >
                            <option value="EMAIL" selected>
                                {{ "EMAIL" | translate }}
                            </option>
                            <option value="SMS">SMS</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="m-3">
                <button
                    type="submit"
                    class="btn btn-primary float-left mr-2"
                    [disabled]="
                        !registerCompany.form.valid ||
                        !passwordMatch(form.password, form.passwordVerify)
                    "
                >
                    <span class="fa-light fa-floppy-disk"></span>
                    {{ "SAVE" | translate }}
                </button>
                <button
                    class="btn btn-outline-primary gray-bg"
                    (click)="showForm.next(false)"
                >
                    <span class="fa-light fa-times"></span>
                    {{ "CANCEL" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>

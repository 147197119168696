<mat-sidenav-container class="nav-container"
    [ngStyle]="{ 'position' : sidenav.opened && onMobileScreen ? 'fixed' : 'relative'}"
    autosize>
    <mat-sidenav #sidenav
        class="sidenav"
        [mode]="onMobileScreen ? 'over' : 'side'"
        fixedInViewport="true"
        [(opened)]="sideNavOpened"
        [disableClose]="true">


        <everon-logo [user]="user"
            [loggedCustomer]="loggedCustomer"
            [language]="language"
            [showTitles]="showTitles"
            [onMobileScreen]="onMobileScreen"
            (titlesShownOrHidden)="toggleTitles($event)"
            (closeSideNavBtn)="sidenav.toggle();">
        </everon-logo>
        <global-nav class="mb-auto styled-scrollbars" [features]="features" [user]="user"
            [landingPageUser]="landingPageUser" [onMobileScreen]="onMobileScreen"
            (closeSideNav)="sidenav.toggle();" [showTitles]="showTitles"></global-nav>

        <app-logout-options
            class="border-top border-mute border-2"
            [language]="language"
            [user]="user"
            [loggedCustomer]="loggedCustomer"
            [logoutBtnForMobileSidenav]=false
            [showTitles]="showTitles"
            [onMobileScreen]="onMobileScreen">
        </app-logout-options>

    </mat-sidenav>

    <mat-sidenav-content class="d-flex align-items-start flex-column">
        <app-toolbar [user]="user" [language]="language"
            [loggedCustomer]="loggedCustomer"
            [onMobileScreen]="onMobileScreen" (openSideNav)="sidenav.toggle();">
        </app-toolbar>
        <div class="container-fluid mt-3 mb-auto">
            <div class="row" *ngIf="user && userPasswordWillExpire && !user.authenticated">
                <div class="col-xl-12">
                    <div class="alert alert-info passwordExpiryInfo cursorPointer"
                        (click)="showPasswordChangeDialog()">
                        {{'PASSWORD_WILL_EXPIRE_AT' |translate}} {{getUserPasswordExpirationDay()}} -
                        {{'CHANGE_PASSWORD' |translate}}
                    </div>
                </div>
            </div>
            <div class="row globalContent">
                <div class="col-xl-12">
                    <router-outlet (activate)="routeChanged()"></router-outlet>
                </div>
            </div>
        </div>

        <footer>
            <div class="col-xl-12" style="text-align:center;">
                &copy; {{copyYear}} <a class="underlineLink" href="https://everon.fi">Oy Everon Ab</a> | <span>UI:
                    {{appVersion}}</span><span *ngIf="user?.apiVersion"> - API:
                    {{user?.apiVersion}}</span><br><small>{{user?.timezone}}</small>
            </div>
        </footer>
    </mat-sidenav-content>
</mat-sidenav-container>
<div class="card">
    <div class="card-header-white header-underline">
        <h6 class="white-header-text">
            {{ "EDIT_CAMERA_GROUP" | translate }}
            <strong>{{ originalCameraGroup.displayName }}</strong>
        </h6>
        <span class="float-right cursorPointer" (click)="showForm.next(false)">
            <span class="fa-light fa-xmark white-header-xmark-icon"></span>
        </span>
    </div>

    <form class="form" #modifyCameraGroup="ngForm" style="min-height: 700px">
        <div class="form-group row mt-2">
            <label class="col-sm-2 mt-1" for="displayName"><strong>{{ "GROUP_NAME" | translate }}:</strong></label>
            <input
                type="text"
                [(ngModel)]="cameraGroupEditForm.displayName"
                name="displayName"
                id="displayName"
                data-testid="displayName"
                class="form-control form-control-sm col-sm-4"
                [ngClass]="{ 'is-invalid': !displayName.value, 'is-valid': displayName.value }"
                #displayName="ngModel"
                required />
            <div class="col">
                <p class="float-right">
                    <strong>{{ "TIME_ZONE" | translate }}</strong>: {{ cameraGroupEditForm.timeZone }}
                </p>
            </div>
        </div>

        <div class="form-group row mt-2">
            <label class="col-sm-2 mt-1" for="address"><strong>{{ "ADDRESS" | translate }}:</strong></label>
            <input
                type="text"
                [(ngModel)]="cameraGroupEditForm.address"
                name="address"
                id="address"
                class="form-control form-control-sm col-sm-4"
                [ngClass]="{ 'is-valid': cameraGroupEditForm.address }" />
        </div>

        <div class="form-group row mt-2">
            <label class="col-sm-2 mt-1" for="postCode"><strong>{{ "POSTCODE" | translate }}:</strong></label>
            <input
                type="text"
                [(ngModel)]="cameraGroupEditForm.postCode"
                name="postCode"
                id="postCode"
                class="form-control form-control-sm col-sm-4"
                [ngClass]="{ 'is-valid': cameraGroupEditForm.postCode }" />
        </div>

        <div class="form-group row mt-2">
            <label class="col-sm-2 mt-1" for="city"><strong>{{ "POSTOFFICE" | translate }}:</strong></label>
            <input
                type="text"
                [(ngModel)]="cameraGroupEditForm.postOffice"
                name="city"
                id="city"
                class="form-control form-control-sm col-sm-4"
                [ngClass]="{ 'is-valid': cameraGroupEditForm.postOffice }" />
        </div>

        <div class="form-group row mt-2">
            <label class="col-sm-2 mt-1" for="location"><strong>{{ "LOCATION" | translate }}:</strong></label>

            <select
              name="location"
              id="location"
              class="form-control form-control-sm col-sm-4 selectBgImage"
              [ngClass]="{ 'is-invalid': cameraGroupEditForm?.locationId?.length === 0, 'is-valid': cameraGroupEditForm?.locationId?.length > 0 }"
              (change)="onLocationSelectChange()"
              [(ngModel)]="selectedLocation"
            >
                <option *ngFor="let location of locationSelect" [ngValue]="location">
                    {{ location.name }}
                </option>
            </select>
        </div>

        <div class="form-group row pb-3">
            <label class="col-sm-2 mt-1" for="accessMode"><strong>{{ "ACCESS_MODE" | translate }}:</strong></label>

            <select
                name="accessMode"
                id="accessMode"
                class="form-control form-control-sm col-sm-4"
                [(ngModel)]="cameraGroupEditForm.accessMode"
                (change)="onAccessModeChange()">
                <option value="always">
                    {{ "ACCESS_MODE_ALWAYS" | translate }}
                </option>
                <option value="visitOnAlert">
                    {{ "VISIT_ON_ALERT" | translate }}
                </option>
                <option [value]="undefined">
                    {{ "SCHEDULED_VISIT" | translate }}
                </option>
            </select>
        </div>

        <br />
        <br />

        <div class="section">
            <div class="row mb-5">
                <div class="col-5">
                    <p class="mb-1"><strong>{{ "RECEIVER_TEAMS" | translate }}:</strong></p>
                    <app-multiselect-dropdown
                        class="multiselect-dropdown"
                        [placeholder]="'RECEIVER_TEAM' | translate"
                        [groupedOptions]="receiverTeamSelect"
                        [(selected)]="cameraGroupEditForm.userGroups"
                        [enableSearch]="true"
                        [disabled]="!cameraGroupEditForm.locationId"
                    ></app-multiselect-dropdown>
                </div>

                <div class="col">
                    <ng-container *ngIf="cameraGroupEditForm.userGroups.length > 0">
                        <strong>{{ "SELECTED_RECEIVER_TEAMS" | translate }}:</strong>

                        <div class="bordered-box mt-1">
                            <ul class="mb-0">
                                <li *ngFor="let userGroupId of cameraGroupEditForm.userGroups">
                                    {{ userGroupId | receiverTeamName : receiverTeamSelect }}
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="section pt-2">
            <app-select-cameras
                [cameras]="cameraGroupEditForm.cameras"
                [camerasOfLocation]="camerasOfLocation"
                [cameraList]="cameraList"
                [selectedLocation]="selectedLocation"
                (cameraSelectChange)="onCameraSelectChange($event)">
            </app-select-cameras>
            <br />
        </div>

        <ng-container *ngIf=" visitConfigEditForm && cameraGroupEditForm.accessMode === 'visitOnAlert'">
            <div class="section pb-2">
                <div class="row">
                    <div class="col-5">
                        <p class="mb-1"><strong>{{ "DEVICES_TO_TRIGGER_ALERT" | translate }}:</strong></p>
                        <app-multiselect-dropdown
                            class="multiselect-dropdown"
                            [placeholder]="'SELECT_DEVICES' | translate"
                            [options]="alertDeviceSelect"
                            [enableSearch]="true"
                            (selectedChange)="parseSelectedDevices($event)"
                            [selected]="selectedAlertDevices"
                        ></app-multiselect-dropdown>
                    </div>

                    <div class="col">
                        <ng-container *ngIf="visitConfigEditForm.alertSources.length > 0">
                                <strong>{{ "SELECTED_DEVICES" | translate }}:</strong>

                                <div class="bordered-box mt-1">
                                    <ul class="mb-0">
                                        <li *ngFor="let obj of visitConfigEditForm.alertSources">
                                            {{ obj.deviceID | deviceName : alertDeviceSelect }}
                                        </li>
                                    </ul>
                                </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="section">
                <strong>{{ "LENGTH_OF_VISIT" | translate }}:</strong>

                <div class="row mx-0 mt-4 pb-4">
                    <div class="form-group">
                        <label for="visitTime">
                            <strong>{{ "VISIT_TIME" | translate }}</strong>
                        </label>

                        <div class="d-flex">
                            <input
                                name="visitTime"
                                type="number"
                                [(ngModel)]="visitConfigEditForm.options.visitTimeMinutes"
                                (change)="validateVisitTimeInput($event.target.value, 0)"
                                class="form-control form-control-sm"
                                min="0"
                                id="visitTime"
                                style="width: 100px" />

                            <div class="mt-1 mr-2">
                                <div class="row">
                                    <i
                                        class="fa-solid fa-caret-up"
                                        style="margin-bottom: -3px"
                                        (click)="setCount(visitConfigEditForm.options.visitTimeMinutes + 1, 0)">
                                    </i>
                                </div>

                                <div class="row">
                                    <i
                                        class="fa-solid fa-caret-down"
                                        (click)="setCount(visitConfigEditForm.options.visitTimeMinutes - 1, 0)">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group ml-5">
                        <label for="visitGraceTime"><strong>{{ "GRACE_TIME" | translate }}</strong></label>
                        <div class="d-flex">
                            <input
                                type="number"
                                [(ngModel)]="visitConfigEditForm.options.visitGraceTimeMinutes"
                                (change)="validateVisitTimeInput($event.target.value, 1)"
                                class="form-control form-control-sm"
                                min="0"
                                id="visitGraceTime"
                                name="visitGraceTime"
                                style="width: 100px" />

                            <div class="mt-1 mr-2">
                                <div class="row">
                                    <i
                                        class="fa-solid fa-caret-up"
                                        style="margin-bottom: -3px"
                                        (click)="setCount(visitConfigEditForm.options.visitGraceTimeMinutes + 1, 1)">
                                    </i>
                                </div>
                                <div class="row">
                                    <i
                                        class="fa-solid fa-caret-down"
                                        (click)="setCount(visitConfigEditForm.options.visitGraceTimeMinutes - 1, 1)">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <hr />
        <div
            *ngIf="showSubmitErrorMsg"
            class="alert alert-danger"
            style="width: fit-content"
            role="alert"
            [@fadeOut]>
            {{ "UPDATE_CAMERA_GROUP_FAILED_ERROR" | translate }}
        </div>
        <div class="row pb-3" style="width: 103%">
            <div class="col">
                <button
                    type="submit"
                    class="btn btn-primary mr-3"
                    [disabled]="!cameraGroupEditForm.isValid()"
                    (click)="submit()">
                    <span class="fa-light fa-floppy-disk"></span>{{ "SAVE" | translate }}
                </button>

                <button
                    type="button"
                    class="btn btn-outline-dark"
                    (click)="showForm.next(false)">
                    <span class="fa-light fa-times"></span>
                    {{ "CANCEL" | translate }}
                </button>
            </div>

            <div class="col">
                <button
                    class="warningBtnLessAttention float-right"
                    (click)="openDeleteDialog(confirmDeleteDialog)">
                    <span class="fa-light fa-trash-alt"></span>
                    {{ "DELETE" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>

<!--Delete dialog-->
<ng-template #confirmDeleteDialog let-modal>
    <div class="modal-header">
        <h2 style="color: #e8413b">{{ "WARNING" | translate }}!</h2>
        <span class="float-right cursorPointer" (click)="modal.dismiss()">
            <span class="fa-sharp fa-solid fa-x"></span>
        </span>
    </div>

    <section class="deleteDescription">
        <p>
            {{ "CAMERA_GROUP_DELETE_CONFIRM" | translate }}

            <strong>{{ originalCameraGroup.displayName }}</strong>

            {{ "CAMERA_GROUP_DATA_LOSS_TO_LOWERCASE" | translate }}

            <strong>{{ "PERMANENTLY_LOWERCASE" | translate }}</strong>.
        </p>

        <h5 *ngIf="!deleteDialogErrorMsg">
            <strong style="color: #1d83e9">{{ "ACTION_CANT_BE_UNDONE" | translate }}</strong>
        </h5>
    </section>

    <ng-container *ngIf="deleteDialogErrorMsg">
        <div class="alert alert-danger" id="dialogErrorMsg" role="alert">
            {{ deleteDialogErrorMsg | translate }}
        </div>
    </ng-container>

    <div class="btnRow mb-3">
        <ng-container *ngIf="confirmDelete">
            <button
                type="button"
                class="btn btn-danger deleteCardBtn float-left"
                (click)="deleteCameraGroup()">
                <span [ngClass]="deletingCameraGroup ? 'fad fa-spinner-third fa-spin' : 'fa-light fa-trash-alt'"></span>
                {{ (deletingCameraGroup ? "DELETING" : "CONFIRM_DELETE") | translate }}
            </button>

            <button
                type="button"
                class="btn btn-secondary cancelDeleteBtn float-right"
                (click)="modal.dismiss()">
                {{ "CANCEL" | translate }}
            </button>
        </ng-container>
    </div>
</ng-template>

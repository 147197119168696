import { Component, Input, OnInit, OnDestroy, EventEmitter, Output, } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, AbstractControl, ValidationErrors, FormArray } from '@angular/forms';
import { IAlarmReason, IAlarmReasonCategory, IAlarmReasonCheckList, IAlarmTranslatedName, ICategoryAndAlarmTranslatedNames, ICategoryAndReasonTranslated, ICategoryCheckList, ICategoryPlusAlarm, IJustCheckedAlarmsGroup, IAlarmReasonsRequest } from 'app/models/alarm-reasons.model';
import { AlarmService } from 'app/services/alarm.service';
import { CrAlert } from 'app/services/crsync.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { tap, map } from 'rxjs/operators';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf, NgFor, NgStyle, CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';


@Component({
    selector: 'alarm-reason-checklist',
    templateUrl: './alarm-reason-checklist.component.html',
    styleUrls: ['./alarm-reason-checklist.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatExpansionModule, NgFor, NgStyle, MatCheckboxModule, TranslateModule, CommonModule]
})
export class AlarmReasonsChecklist implements OnInit, OnDestroy {

  constructor(
    private fb: UntypedFormBuilder,
    private alarmService: AlarmService
  ) { }


  listOfAlerts: UntypedFormGroup;
  @Input('alert') alert: CrAlert;
  language: string;


  alarmSub = new Subscription();
  categoryNameTranslated: string;
  alarmNameTranslated: string;
  allNames: ICategoryAndAlarmTranslatedNames[];
  @Output() onClosingForm = new EventEmitter<any>();

  // the only job of this @Input is to run onChange
  // onChange will update form value each time the form get opened
  // this form value is needed to return form to its initial state if submission canceled
  @Input() checkboxListIsOpen
  formValueOnOpening;


  ngOnChanges(){
    this.formValueOnOpening = this.listOfAlerts?.value;
  }

  ngOnInit() {

    this.language = localStorage.getItem('language')
    this.alarmService.getAlarmReasons();
    [this.categoryNameTranslated, this.alarmNameTranslated] = this.alarmService.getAlarmsTranslated(this.language);

    this.alarmSub = this.alarmService.alarmReasonsSource$.pipe(
      map(alarmsArr => this.processAlarms(alarmsArr)),
      tap(sortedAlarms => this.updateNames(sortedAlarms)),
      map(alarmsArr => this.createFormObject(alarmsArr) as UntypedFormGroup[])
    ).subscribe(
      formObject => this.createForm(formObject)
    )
  }

  private processAlarms(alarmsArr: IAlarmReasonCategory[]): IAlarmReasonCategory[] {
    let sortedAlarms = this.alarmService.sortAlphabetically(alarmsArr, this.categoryNameTranslated, this.alarmNameTranslated);
    return sortedAlarms;
  }

  private updateNames(sortedAlarms: IAlarmReasonCategory[]): void {
    this.allNames = this.categoryWithAlarmsNames(sortedAlarms, this.categoryNameTranslated, this.alarmNameTranslated);
  }

  alarmReasonNames(alarmReasons: IAlarmReason[], alarmNameTranslated: string): IAlarmTranslatedName[] {

    let names: IAlarmTranslatedName[] = []
    alarmReasons.forEach(alarm => {
      let alarmName: IAlarmTranslatedName = {
        'name': alarm[alarmNameTranslated]
      };
      names.push(alarmName)

    })
    return names
  }

  categoryWithAlarmsNames(alarmsArr: IAlarmReasonCategory[], categoryNameTranslated: string, alarmNameTranslated: string): ICategoryAndAlarmTranslatedNames[] {

    let arrOfNames = alarmsArr?.map(category => {
      let translatedNames: ICategoryAndAlarmTranslatedNames = {};
      translatedNames['categoryName'] = category[categoryNameTranslated]
      translatedNames['alarms'] = this.alarmReasonNames(category.alarmReasons, alarmNameTranslated)
      return translatedNames
    })

    return arrOfNames
  }

  isChecked(alarmReason: IAlarmReason, category: IAlarmReasonCategory): boolean{

    const result = this.alert?.alarmReasons.find(item => {
      return item.category === category.dictionaryEntryKey && item.reason === alarmReason.dictionaryEntryKey
    })

    return !!result
  }

atLeastOneCheckboxSelected: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (control instanceof FormArray) {
    const isChecked = control.controls.some((category: AbstractControl) => {
      const alarms = (category as UntypedFormGroup).get('alarms') as FormArray;
      return alarms.controls.some(
        (alarm: AbstractControl) => alarm.get('isChecked')?.value === true
      );
    });

    return isChecked ? null : { atLeastOneCheckboxSelected: true };
  }

  return null;
};

  // transform data from the alarm service
  // into structure for building a reactive form
  createFormObject(alarmsArr: IAlarmReasonCategory[]): UntypedFormGroup[] {
    let formBuildingData: UntypedFormGroup[] = [];

    alarmsArr?.forEach(category => {
      // map alarm reasons of the given category
      // into array of from groups
      let alarmReasonsFormGroupObj = category.alarmReasons.map(
        alarmReason => {
          // create a form group which corresponds to an ALARM REASON
          return this.fb.group({
            'translatedAlarmName': alarmReason[this.alarmNameTranslated],
            'alarmName': alarmReason.dictionaryEntryKey,
            'isChecked': this.fb.control(this.isChecked(alarmReason, category)) // object for breaking test {value: this.isChecked(alarmReason, category), disabled: true}
          })
        }
      )
      // create a form group which corresponds to a CATEGORY
      let categoriesFormGroupObj = this.fb.group({
        'alertID': this.alert?.alertId,
        'translatedCategoryName': category[this.categoryNameTranslated],
        'categoryName': category.dictionaryEntryKey,
        'alarms': this.fb.array(alarmReasonsFormGroupObj) // this.atLeastOneCheckboxSelected
      })
      // create an array of form groups
      formBuildingData.push(categoriesFormGroupObj)
    })
    return formBuildingData
  }

  createForm(formObject: UntypedFormGroup[]): void {
    this.listOfAlerts = this.fb.group({
      'alarmReasonsByCategories': this.fb.array([
        ...formObject
      ], this.atLeastOneCheckboxSelected)
    })

    this.listOfAlerts.valueChanges.subscribe((value) => {

      // Check if the form is currently valid
      if (!this.listOfAlerts.valid) {
        this.processCheckboxValues()
      }
    });
  }

  getCategoryArray(): UntypedFormArray {
    return <UntypedFormArray>this.listOfAlerts.get('alarmReasonsByCategories')
  }

  categoryIsChecked(category: UntypedFormGroup): boolean {
    let result = category.value.alarms.some(alarm => alarm.isChecked)
    return result
  }

  getAlarmReasonsArray(categoryIndex: number): UntypedFormArray {
    return <UntypedFormArray>this.getCategoryArray().controls[categoryIndex]?.get('alarms');
  }


  /**
   * Collects and returns a list of CHECKED alarm reasons and their corresponding categories.
   *
   * This function iterates through the list of alert categories, checks for selected alarms
   * within each category, and compiles them into an array of objects, where each object
   * represents a pair of {category, alarm reason}. If no alarms are selected in a category,
   * it will be excluded from the final list.
   *
   * @returns {ICategoryPlusAlarm[]} An array of objects containing category and alarm reason pairs.
   */
  collectCheckedAlarmReasonsAndCategories(): ICategoryPlusAlarm[] {
    let checkedAlarmReasons: ICategoryPlusAlarm[] = [];
    const results = this.listOfAlerts.get('alarmReasonsByCategories').value.forEach((category: ICategoryCheckList) => {

      //get checked alarms in the category
      let chosenAlarms = this.filterCheckedAlarmReasons(category.alarms)

      // create obj {category-reason} for every reason
      // and push it into an array
      if (chosenAlarms.length > 0) {
        let alarmWithCategory: ICategoryPlusAlarm;
        chosenAlarms.forEach(alarm => {
          alarmWithCategory = {
            categoryDictEntryKey: category.categoryName,
            reasonDictEntryKey: alarm.alarmName
          };
          checkedAlarmReasons = [...checkedAlarmReasons, alarmWithCategory]
        })
      }
    })
    return checkedAlarmReasons
  }

  /**
 * Filters and returns a list of selected alarm reasons from the given list.
 *
 * @param alarmReasons {IAlarmReasonCheckList[]} - The list of alarm reasons to filter.
 * @returns {IAlarmReasonCheckList[]} - An array containing only the selected alarm reasons.
 */
  filterCheckedAlarmReasons(alarmReasons: IAlarmReasonCheckList[]) {
    return alarmReasons.filter(
      (alarm: IAlarmReasonCheckList) => alarm.isChecked === true
      );
  }

  /**
   * Retrieves the entire checklist of just checked alarms and their associated categories.
   *
   * This function iterates through the list of alerts and their categories, compiles a list
   * of just checked alarms for each category, and returns an array of `IJustCheckedAlarmsGroup`.
   *
   * @returns {IJustCheckedAlarmsGroup[]} - An array of just checked alarms grouped by categories.
   */
  getWholeCheckList(): IJustCheckedAlarmsGroup[]{
    let updatedAlarmReasonsCheck: IJustCheckedAlarmsGroup[] = [];

    const results = this.listOfAlerts.get('alarmReasonsByCategories').value.forEach((category: ICategoryCheckList) => {

      let chosenCategory = {
        alertId: category.alertID,
        categoryName: category.translatedCategoryName,
        alarmNames: []
      }

      let checkedAlarmReasons = this.filterCheckedAlarmReasons(category.alarms)

      if (checkedAlarmReasons.length > 0) {

        checkedAlarmReasons.forEach((alarm: IAlarmReasonCheckList) => {
          chosenCategory.alarmNames.push(alarm.translatedAlarmName)
        })
      }

      updatedAlarmReasonsCheck.push(chosenCategory)
    })
    return updatedAlarmReasonsCheck
  }

  processCheckboxValues() {

    this.atLeastOneReasonChecked() ?
    this.alarmService.addToEnabledAlarms(this.alert.alertId)
    : this.alarmService.removeFromEnabledAlarms(this.alert.alertId)

    //-------------------------this is done to update UI----------------------------------------------
    let updatedAlarmReasonsCheck = this.getWholeCheckList()
    this.alarmService.storeCheckedAlarms(updatedAlarmReasonsCheck)

    //---------------------------to handle alarm reasons for API--------------------------------------
    // on each checklist submission chose only those alarm reasons which are not previously added to the alert
    // i.e. which are not coming from API with alert
    const categoryReasonPairs = this.collectCheckedAlarmReasonsAndCategories();
    const newAlarmReasonsToAdd = this.filterNewAlarmReasons(categoryReasonPairs);

    this.alarmService.storeAlarmReasons(this.alert.alertId, newAlarmReasonsToAdd)
  }


  /**
 * Filters out new alarm reasons that are already attached to the alert (which came from API).
 *
 * @param listOfPairs {ICategoryPlusAlarm[]} - The array of new alarm reasons to filter.
 * @returns {ICategoryPlusAlarm[]} - The filtered array containing only new alarm reasons.
 */
  filterNewAlarmReasons(listOfPairs: ICategoryPlusAlarm[]): ICategoryPlusAlarm[] {
    return listOfPairs.filter(pair => !this.findMatchingCategoryAndReason(pair, this.alert.alarmReasons))
  }

  /**
   * Finds and returns a matching category and reason among existing entries.
   *
   * @param alarmReasonToAdd {ICategoryPlusAlarm} - The alarm reason to search for.
   * @param existingAlarmReasons {ICategoryAndReasonTranslated[]} - The array of existing alarm reasons to search within.
   * @returns {ICategoryAndReasonTranslated | undefined} - The matching category and reason, or undefined if not found.
   */
  findMatchingCategoryAndReason(alarmReasonToAdd: ICategoryPlusAlarm, existingAlarmReasons: ICategoryAndReasonTranslated[]): ICategoryAndReasonTranslated | undefined {
    return existingAlarmReasons.find(existing =>
      alarmReasonToAdd.categoryDictEntryKey === existing.category &&
      alarmReasonToAdd.reasonDictEntryKey === existing.reason
    );
  }

  onCameraVisitAlarmSubmit(){
    if (this.listOfAlerts.valid) {
      this.listOfAlerts.reset()
    }
  }

  ngOnDestroy(): void {
    this.alarmSub.unsubscribe()
  }

  cancelWorkingWithForm() {
    this.listOfAlerts.setValue(this.formValueOnOpening)

    this.onClosingForm.emit()
  }

  onSubmit() {

    this.processCheckboxValues()

// WHAT IS THE PURPOSE OF this.onCameraVisitAlarmSubmit()?
    // if(this.alert){
    //   this.processCheckboxValues()
    //   this.atLeastOneReasonChecked() ? this.alarmService.addToEnabledAlarms(alertID) : this.alarmService.removeFromEnabledAlarms(alertID)
    // } else {
    //   this.onCameraVisitAlarmSubmit()
    // }
    this.onClosingForm.emit()
  }

  atLeastOneReasonChecked(): boolean{
    return this.listOfAlerts.get('alarmReasonsByCategories').value
    .find(category => category.alarms.some(alarm => alarm.isChecked)) !== undefined;
  }
}

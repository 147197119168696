<div *ngIf="alert">
    <div class="clearfix">
        <div class="float-left ml-2 mt-2" *ngIf="alert.notes && alert.notes.length > 0">
            <strong><span class="fa-light fa-fw fa-copy"></span>&nbsp;&nbsp;{{'NOTES' |translate}}
                ({{alert.notes.length}})</strong>
        </div>
        <div class="float-left ml-2 mt-2" *ngIf="alert.notes && alert.notes.length > 1">
            <span *ngIf="noteShowCount === 1" class="alertCardClickTarget"
                (click)="noteShowCount = alert.notes.length">{{'SHOW_ALL' |translate}}</span>
            <span *ngIf="noteShowCount > 1" class="alertCardClickTarget" (click)="noteShowCount = 1">{{'SHOW_NEWEST'
                |translate}}</span>
        </div>
    </div>
    <div class="mt-2 ml-5 clearfix" *ngIf="alert.notes && alert.notes.length > 0">
        <div *ngFor="let note of alert.notes |slice:0:noteShowCount">
            <small>{{note.writtenBy}} - {{note.time |moment: 'l LTS'}}</small>
            <p>{{note.text}}</p>
        </div>
    </div>
</div>

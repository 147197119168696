<span>
    {{ ("STAFF_ACCOUNT" | translate) + " " }}

    <a [routerLink]="['/staff', log?.details?.targetId]" (click)="closeDialog.emit()">
        {{ log?.details?.targetName | identityFormat : log?.details?.targetId }}
    </a>

    {{ " " + ("WAS_MODIFIED_BY" | translate) + " " }}

    <strong *ngIf="!log?.staffId && log?.staffName === 'Everon Admin';else elseBlock">
        Everon Admin
    </strong>

    <ng-template #elseBlock>
        <a [routerLink]="['/staff', log?.staffId]" (click)="closeDialog.emit()">{{ log?.staffName | identityFormat : log?.staffId }}</a>
    </ng-template>
</span>
<p class="mt-1">{{ log?.timestamp | moment : "l LTS" }}</p>

<hr />

<div *ngIf="!(accountChanges | propsFalsy : ['roleLevel', 'lockRights'])" class="pb-3">
    <span class="subtitle"><i class="fa-solid fa-shield-halved fa-lg pr-2"></i>{{ "MODIFIED_PERMISSIONS" | translate | uppercase }}</span>
    <div class="container modified-permissions-box" [ngStyle]="log | boxLayoutStyle">
        <ng-container *ngIf="accountChanges?.roleLevel">
            <span class="item-title">{{ "ACCESS_RIGHTS" | translate }}:</span>
            <div class="item-pair-value">
                <span class="negative-highlight line-through">{{ accountChanges?.roleLevel?.oldValue | userRole }}</span>
                <i class="fa-light fa-arrow-right-long fa-lg"></i>
                <span class="positive-highlight">{{ accountChanges?.roleLevel?.newValue | userRole }}</span>
            </div>
        </ng-container>

        <ng-container *ngIf="accountChanges?.lockRights">
            <span class="item-title">{{ "LOCK_RIGHTS" | translate }}:</span>
            <div class="item-pair-value">
                <span class="negative-highlight line-through">{{ accountChanges?.lockRights?.oldValue | lockRights }}</span>
                <i class="fa-light fa-arrow-right-long fa-lg"></i>
                <span class="positive-highlight">{{ accountChanges?.lockRights?.newValue | lockRights }}</span>
            </div>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="!(accountChanges | allPropsFalsy : ['roleLevel', 'lockRights'])">
    <span class="subtitle">
        {{ !(accountChanges | propsFalsy : ['roleLevel', 'lockRights']) ? "OTHER_MODIFICATIONS" : "MODIFICATIONS" | translate | uppercase }}
    </span>

    <div class="container">
        <!-- First name -->
        <ng-container *ngIf="visibleFields | includes : 'firstName'">
            <span class="item-title">{{ "FIRST_NAME" | translate }}:</span>
            <div class="item-pair-value">
                <span class="negative-highlight line-through">
                    {{ accountChanges?.firstName?.oldValue || "NONE" | translate }}
                </span>
                <i class="fa-light fa-arrow-right-long fa-lg"></i>
                <span class="positive-highlight">
                    {{ accountChanges?.firstName?.newValue || "NONE" | translate }}
                </span>
            </div>
        </ng-container>

        <!-- Family name -->
        <ng-container *ngIf="visibleFields | includes : 'familyName'">
            <span class="item-title">{{ "LAST_NAME" | translate }}:</span>
            <div class="item-pair-value">
                <span class="negative-highlight line-through">
                    {{ accountChanges?.familyName?.oldValue || "NONE" | translate }}
                </span>
                <i class="fa-light fa-arrow-right-long fa-lg"></i>
                <span class="positive-highlight">
                    {{ accountChanges?.familyName?.newValue || "NONE" | translate }}
                </span>
            </div>
        </ng-container>

        <!-- Username -->
         <ng-container *ngIf="visibleFields | includes : 'username'">
            <span class="item-title">{{ "USERNAME" | translate }}:</span>
            <div class="item-pair-value">
                <span class="negative-highlight line-through">
                    {{ accountChanges?.username?.oldValue || "NONE" | translate }}
                </span>
                <i class="fa-light fa-arrow-right-long fa-lg"></i>
                <span class="positive-highlight">
                    {{ accountChanges?.username?.newValue || "NONE" | translate }}
                </span>
            </div>
        </ng-container>

        <!-- Location -->
        <ng-container *ngIf="visibleFields | includes : 'locationId'">
            <span class="item-title">{{ "LOCATION" | translate }}:</span>
            <div class="item-pair-value">
                <span class="negative-highlight line-through">
                    {{ accountChanges?.locationId?.oldValue || "NONE" | translate }}
                </span>
                <i class="fa-light fa-arrow-right-long fa-lg"></i>
                <span class="positive-highlight">
                    {{ accountChanges?.locationId?.newValue || "NONE" | translate }}
                </span>
            </div>
        </ng-container>

        <!-- Location access -->
        <ng-container *ngIf="visibleFields | some : 'addedLocationRights' : 'removedLocationRights'">
            <span class="item-title">{{ "LOCATION_ACCESS" | translate }}:</span>
            <ng-container *ngIf="visibleFields | includes : 'addedLocationRights'">
                <span id="added-location-rights" class="item-value d-flex align-items-center">
                    {{ "ADDED_TO" | translate }}
                    <span class="positive-highlight ml-2">
                        {{ accountChanges?.addedLocationRights | join : ', ' || "NONE" | translate }}
                    </span>
                </span>
            </ng-container>

            <div *ngIf="visibleFields | includes : 'addedLocationRights' : 'removedLocationRights'"></div>

            <ng-container *ngIf="visibleFields | includes : 'removedLocationRights'">
                <span id="removed-location-rights" class="item-value d-flex align-items-baseline">
                    {{ "REMOVED_FROM" | translate }}
                    <span class="negative-highlight line-through ml-2">
                        {{ accountChanges?.removedLocationRights | join : ', ' || "NONE" | translate }}
                    </span>
                </span>
            </ng-container>
        </ng-container>

        <!-- Receiver teams -->
        <ng-container *ngIf="visibleFields | some : 'addedReceiverTeams' : 'removedReceiverTeams'">
            <span class="item-title">{{ "RECEIVER_GROUPS" | translate }}:</span>
            <ng-container *ngIf="visibleFields | includes : 'addedReceiverTeams'">
                <span id="added-user-groups" class="item-value d-flex align-items-center">
                    {{ "ADDED_TO" | translate }}
                    <span class="positive-highlight ml-2">
                        {{ accountChanges?.addedReceiverTeams | join : ', ' || "NONE" | translate }}
                    </span>
                </span>
            </ng-container>

            <div *ngIf="visibleFields | includes : 'addedReceiverTeams' : 'removedReceiverTeams'"></div>

            <ng-container *ngIf="visibleFields | includes : 'removedReceiverTeams'">
                <span id="removed-user-groups" class="item-value d-flex align-items-baseline">
                    {{ "REMOVED_FROM" | translate }}
                    <span class="negative-highlight line-through ml-2">
                        {{ accountChanges?.removedReceiverTeams | join : ', ' || "NONE" | translate }}
                    </span>
                </span>
            </ng-container>
        </ng-container>

        <!-- Alarm routes -->
        <ng-container *ngIf="visibleFields | some : 'addedAlarmRoutes' : 'removedAlarmRoutes'">
            <span class="item-title">{{ "ALARM_ROUTES" | translate }}:</span>
            <ng-container *ngIf="visibleFields | includes : 'addedAlarmRoutes'">
                <span id="added-alarm-routes" class="item-value d-flex align-items-center">
                    {{ "ADDED_TO" | translate }}
                    <span class="positive-highlight ml-2">
                        {{ accountChanges?.addedAlarmRoutes | join : ', ' || "NONE" | translate }}
                    </span>
                </span>
            </ng-container>

            <div *ngIf="visibleFields | includes : 'addedAlarmRoutes' : 'removedAlarmRoutes'"></div>

            <ng-container *ngIf="visibleFields | includes : 'removedAlarmRoutes'">
                <span id="removed-alarm-routes" class="item-value d-flex align-items-baseline">
                    {{ "REMOVED_FROM" | translate }}
                    <span class="negative-highlight line-through ml-2">
                        {{ accountChanges?.removedAlarmRoutes | join : ', ' || "NONE" | translate }}
                    </span>
                </span>
            </ng-container>
        </ng-container>

        <!-- GSM number -->
        <ng-container *ngIf="visibleFields | includes : 'gsmNumber'">
            <span class="item-title">{{ "GSM_NUMBER" | translate }}:</span>
            <div class="item-pair-value">
                <span class="negative-highlight line-through">
                    {{ accountChanges?.gsmNumber?.oldValue || "NONE" | translate }}
                </span>
                <i class="fa-light fa-arrow-right-long fa-lg"></i>
                <span class="positive-highlight">
                    {{ accountChanges?.gsmNumber?.newValue || "NONE" | translate }}
                </span>
            </div>
        </ng-container>

        <!-- Email -->
        <ng-container *ngIf="visibleFields | includes : 'email'">
            <span class="item-title">{{ "EMAIL" | translate }}:</span>
            <div class="item-pair-value">
                <span class="negative-highlight line-through">
                    {{ accountChanges?.email?.oldValue || "NONE" | translate }}
                </span>
                <i class="fa-light fa-arrow-right-long fa-lg"></i>
                <span class="positive-highlight">
                    {{ accountChanges?.email?.newValue || "NONE" | translate }}
                </span>
            </div>
        </ng-container>

        <!-- Language -->
        <ng-container *ngIf="visibleFields | includes : 'language'">
            <span class="item-title">{{ "LANGUAGE" | translate }}:</span>
            <div class="item-pair-value">
                <span class="negative-highlight line-through">
                    {{ accountChanges?.language?.oldValue || "NONE" | translate }}
                </span>
                <i class="fa-light fa-arrow-right-long fa-lg"></i>
                <span class="positive-highlight">
                    {{ accountChanges?.language?.newValue || "NONE" | translate }}
                </span>
            </div>
        </ng-container>

        <!-- Identity user -->
        <ng-container *ngIf="visibleFields | includes : 'identityUser'">
            <span class="item-title">{{ "STRONG_AUTHENTICATION_USER" | translate }}:</span>
            <div class="item-pair-value">
                <span class="negative-highlight line-through">
                    {{ accountChanges?.identityUser?.oldValue || "NONE" | translate }}
                </span>
                <i class="fa-light fa-arrow-right-long fa-lg"></i>
                <span class="positive-highlight">
                    {{ accountChanges?.identityUser?.newValue || "NONE" | translate }}
                </span>
            </div>
        </ng-container>

        <!-- Account status -->
        <ng-container *ngIf="visibleFields | some : 'accountUnlocked' : 'accountLocked'">
            <span class="item-title">{{ "LOCKED_ACCOUNT" | translate }}:</span>
            <div class="item-value">
                <span *ngIf="visibleFields | includes : 'accountUnlocked'" id="account-unlocked-text" class="positive-highlight">
                    {{ "ACCOUNT_UNLOCKED" | translate }}
                </span>
                <span *ngIf="visibleFields | includes : 'accountLocked'" id="account-locked-text" class="negative-highlight">
                    {{ "ACCOUNT_LOCKED" | translate }}
                </span>
            </div>
        </ng-container>

        <!-- Password -->
        <ng-container *ngIf="visibleFields | includes : 'passwordChanged'">
            <span class="item-title">{{ "PASSWORD" | translate }}:</span>
            <div class="item-value">
                <span class="positive-highlight">{{ "PASSWORD_CHANGED" | translate }}</span>
            </div>
        </ng-container>
    </div>

    <div class="mt-4" *ngIf="allFields.length > visibleFieldCount">
        <span id="shown-details-info">{{ ("SHOWN_ACTIVITY_DETAILS_INFO" | translate : visibleFieldCount) | uppercase }}.</span>
    </div>
</ng-container>







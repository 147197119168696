<p *ngIf="!dataLoaded">{{ "LOADING" | translate }}...</p>

<div class="alert alert-success positionAbs placeTopAndFade" *ngIf="moveSuccess">
    {{ "NEW_STAFFS_MOVED" | translate }}
</div>

<div class="alert alert-danger positionAbs placeTopAndFade" *ngIf="moveFailed">
    {{ "NEW_STAFFS_MOVED_FAILED" | translate }}
</div>

<div class="alert alert-danger positionAbs placeTopAndFade" *ngIf="warningsCompany">
    {{ "NOT_ALL_STAFF_HAS_COMPANY" | translate }}
</div>

<div class="alert alert-danger positionAbs placeBelowAndFade" *ngIf="warningsRole">
    {{ "NOT_ALL_STAFF_HAS_ROLE" | translate }}
</div>

<div class="alert alert-danger positionAbs placeTopAndFade" *ngIf="deleteFailed">
    {{ "COMPANY_DELETE_ERROR" | translate }}
</div>

<div class="mt-3" [hidden]="!dataLoaded">
    <div class="d-flex justify-content-between align-items-baseline mb-3">
        <h3>{{ "STAFF" | translate }}</h3>

        <div class="d-flex align-items-start justify-content-end">
            <div *ngIf="sorting" class="showSpinner">
                <i class="fad fa-spinner-third fa-spin"></i>
            </div>

            <mat-form-field appearance="outline" class="mx-2">
                <mat-label>{{ "SORT_BY" | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedSort">
                    <mat-option [value]="0" class="hideMatOptionCheckbox" (click)="sortStaff()">
                        {{ "ANY" | translate }}
                    </mat-option>

                    <mat-option [value]="-1" class="hideMatOptionCheckbox" (click)="sortStaff()">
                        {{ "LATEST_FIRST" | translate }}
                    </mat-option>

                    <mat-option [value]="1" class="hideMatOptionCheckbox" (click)="sortStaff()">
                        {{ "OLDEST_FIRST" | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <form (ngSubmit)="searchStaff()">
                <div class="input-group search-element">
                    <input
                        #staffSearchInput
                        class="form-control border border-primary"
                        type="text"
                        (keydown.enter)="searchStaff()"
                        placeholder="{{ 'SEARCH' | translate }}... " />

                    <div class="input-group-append">
                        <button class="btn btn-primary" type="submit">
                            {{ "SEARCH" | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="d-flex align-items-baseline">
        <div class="m-2" data-testid="totalItems_Top">{{ "ITEMS" | translate }}: {{ totalItems }}</div>

        <mat-paginator
            #paginatorTop
            class="ml-2 mb-1"
            [showFirstLastButtons]="true"
            [length]="totalItems"
            [pageSize]="pageSize"
            [pageSizeOptions]="staffFilter ? null : [25, 50, 75, 100]"
            (page)="paginatorBottom.pageIndex = $event.pageIndex; handlePageEvent($event)"
            aria-label="Select page">
        </mat-paginator>
    </div>

    <div class="container-fluid m-0 p-0 main">
        <div class="row">
            <div [ngClass]="editNewStaff ? 'col-xl-7' : 'col-9'" style="overflow-x: auto">
                <table
                    *ngIf="staffList.length > 0; else elseblock"
                    class="mb-3"
                    data-testid="table">
                    <thead class="border-bottom">
                        <tr>
                            <th>{{ "STAFF_NAME" | translate }}</th>
                            <th>{{ "STAFF_ID" | translate }}</th>
                            <th>{{ "ROLE" | translate }}</th>
                            <th>{{ "DATE_ADDED" | translate }}</th>
                            <th>{{ "LOCATIONS" | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let staff of staffList; let i = index; let roleUpdated = false"
                            (click)="rowClicked(staff)"
                            [ngStyle]="{ 'background-color': staff.identityId === jobholder?.identityId && jobholder?.checked ? '#ECEDF0' : null }"
                            data-testid="table_row">
                            <td innerHTML="{{ (staff.firstName + ' ' + staff.familyName) | highlight : staffFilter }}">
                            </td>
                            <td innerHTML="{{ staff.identityId.split('%')[1] | highlight : staffFilter }}"></td>
                            <td>{{ staff.staffRole === 0 ? "User" : staff.staffRole === 95 ? "Landing page user" : null
                                }}</td>
                            <td innerHTML="{{ staff.createTime | moment:'L LTS' | highlight : staffFilter }}"></td>
                            <td>
                                <span *ngIf="staff.locationList.length <= 1">{{ staff.locationList.length === 1 ?
                                    staff.locationList[0] : "-" }}</span>
                                <span *ngIf="staff.locationList.length > 1">
                                    <span class="locationBadge">{{ staff.locationList.length }}</span>&nbsp;{{
                                    "LOCATIONS_FINNISH_ALT" | translate }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="editNewStaff" class="col-12 col-xl-5">
                <app-new-staff-edit
                    [staff]="jobholder"
                    [customers]="customers"
                    (onRemoveCompany)="completeRemovingCompany($event)"
                    (onAddCompany)="finishAddingCompany()"
                    (onMovingSelected)="moveSelected($event)"
                    (onDeleteFailed)="notifyOnDeleteFailed()"
                    (onCloseEditCard)="closeEditCard()"></app-new-staff-edit>
            </div>
        </div>

        <ng-template #elseblock>
            <div class="row ml-5" data-testid="no_staff_found">
                {{ "STAFF_NOT_FOUND" | translate }}
            </div>
        </ng-template>
    </div>

    <div class="d-flex align-items-baseline" [hidden]="staffList.length < 5">
        <div class="m-2" data-testid="totalItems_Bottom">{{ "ITEMS" | translate }}: {{ totalItems }}</div>
        <mat-paginator
            #paginatorBottom
            class="ml-2 mb-3"
            [showFirstLastButtons]="true"
            [length]="totalItems"
            [pageSize]="pageSize"
            [pageSizeOptions]="staffFilter ? null : [25, 50, 75, 100]"
            (page)="paginatorTop.pageIndex = $event.pageIndex; handlePageEvent($event)"
            aria-label="Select page">
        </mat-paginator>
    </div>
</div>
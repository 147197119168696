export const TIMEZONES: ITimezone[] = [
  {
    timezone: "Europe/Helsinki",
    name: "(UTC+02:00) Helsinki",
    country: { name: "Finland", code: "FI", alpha3Code: "FIN" },
  },
  {
    timezone: "Europe/London",
    name: "(UTC+00:00) London",
    country: { name: "England", code: "GB", alpha3Code: "GBR" },
  },
  {
    timezone: "Europe/Tallinn",
    name: "(UTC+02:00) Tallinn",
    country: { name: "Estonia", code: "EE", alpha3Code: "EST" },
  },
  {
    timezone: "Europe/Stockholm",
    name: "(UTC+01:00) Stockholm",
    country: { name: "Sweden", code: "SE", alpha3Code: "SWE" },
  },
];

export interface ITimezone {
  timezone: string;
  name: string;
  country: { name: string; code: string; alpha3Code: string };
}


<form id="container">
  <p id="header">{{ "WHY_DO_YOU_WANT_TO_REPLACE_THE_DEVICE" | translate }}?</p>

  <mat-radio-group [(ngModel)]="form.deviceStatus" class="vertical-radio-group pb-3" name="replaceStatus">
    <mat-radio-button *ngFor="let option of options" [value]="option.value">
      <span class="radio-label">
        {{ option.label | translate }}
      </span>
    </mat-radio-button>
  </mat-radio-group>

  <div *ngIf="errorMsg" class="alert alert-danger" role="alert">
    {{ errorMsg | translate }}
  </div>

  <button type="submit" class="btn btn-primary ml-2" (click)="replaceDevice()" [disabled]="replacing">
    <i *ngIf="replacing" class="fad fa-spinner-third fa-spin" style="margin-right: 7px"></i>
    {{ (replacing ? "REPLACING" : "SAVE") | translate }}
  </button>

  <button class="btn secondaryBtnWhiteBg ml-2" (click)="cancel()">
    {{ "CANCEL" | translate }}
  </button>
</form>

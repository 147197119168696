import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import {
  ICompanyGroup,
  ICompanyGroupCompany,
} from "./../../../../models/company.models";
import { BehaviorSubject } from "rxjs";
import { ITimezone, TIMEZONES } from "../../timezones";
import { ApiService } from "app/services/api.service";
import { NgxBootstrapMultiselectModule, IMultiSelectOption, IMultiSelectTexts, IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { ThrottleButtonClickDirective } from "../../../../directives/throttle-button-click.directive";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    selector: "company-group-add",
    templateUrl: "./company-group-add.component.html",
    styleUrls: ["./company-group-add.component.css"],
    standalone: true,
    imports: [FormsModule, NgClass, NgFor, NgIf, NgxBootstrapMultiselectModule, ThrottleButtonClickDirective, TranslateModule]
})
export class CompanyGroupAddComponent implements OnInit {
  constructor(private api: ApiService, private translate: TranslateService) {}

  @Input() showForm: BehaviorSubject<boolean>;
  @Output() companyGroupAdded = new EventEmitter<ICompanyGroup>();

  companyGroupForm: ICompanyGroup;
  availableCompanies: ICompanyGroupCompany[];

  timezones: ITimezone[] = TIMEZONES;
  serverList: string[];
  failureMessage: string;

  companySelect: ICompanySelect = {
    texts: {},
    settings: {
      enableSearch: true,
      showCheckAll: false,
      showUncheckAll: false,
      fixedTitle: true,
      closeOnSelect: false,
      checkedStyle: "checkboxes",
      buttonClasses: "primaryBtn btnText16px ssPlusIcon",
      selectionLimit: 0,
      autoUnselect: false,
      dynamicTitleMaxItems: 0,
      maxHeight: "350px",
      loadViewDistance: 1,
      stopScrollPropagation: true,
      selectAddedValues: true,
      focusBack: false,
    },
    companyOptions: [],
  };

  // Set timezone and return the name of the timezone
  setTimezone(): string {
    const data = this.timezones.find(
      (x) => x.country.alpha3Code === this.companyGroupForm.country
    );
    if (data) {
      this.companyGroupForm.timezone = data.timezone;
      return data.name;
    }
    return "";
  }

  removeAllAddedCompanies(): void {
    this.companyGroupForm.companies = [];
  }

  removeAddedCompany(index: number): void {
    this.companyGroupForm.companies.splice(index, 1);
  }

  saveCompanyGroup(): void {
    this.failureMessage = "";
    this.api.addCompanyGroup(this.companyGroupForm).subscribe(
      (addedCompanyGroup: ICompanyGroup) => {
        if (addedCompanyGroup) {
          this.companyGroupAdded.emit(addedCompanyGroup);
          this.showForm.next(false);
        } else {
          this.failureMessage = "ADD_COMPANY_GROUP_FAILED";
        }
      },
      (error) => {
        console.log(error);
        this.failureMessage = "ADD_COMPANY_GROUP_FAILED";
      }
    );
  }

  initDropDownOptions(companies: ICompanyGroupCompany[]): void {
    this.companySelect.companyOptions = [];
    for (let company of companies) {
      this.companySelect.companyOptions.push({
        id: company,
        name: company.company,
      });
    }
  }

  initCompanyGroupForm() {
    this.companyGroupForm = {
      name: "",
      companyGroupId: "",
      parentCompanyGroupId: null,
      organizationNumber: "",
      address: "",
      postOffice: "",
      postCode: "",
      contactPhone: "",
      langId: "ENG",
      country: "",
      timezone: "",
      companies: [],
    };
  }

  sortCompanies(a: ICompanyGroupCompany, b: ICompanyGroupCompany): number {
    if (a.company < b.company) return -1;
    if (a.company > b.company) return 1;
    return 0;
  }

  onServerSelected() {
    const server = this.companyGroupForm.server;
    if (server !== 'undefined') {
      this.api
        .getAvailableCompanies(server)
        .subscribe((res: ICompanyGroupCompany[]) => {
          this.availableCompanies = res.sort(this.sortCompanies);
          this.initDropDownOptions(this.availableCompanies);
        });
    }
    else {
      this.companySelect.companyOptions = [];
    }
  }

  ngOnInit(): void {
    this.api
      .getCompanyGroupAndServerLists()
      .subscribe(
        (res: {
          companyGroups: { name: string; companyGroupId: string };
          servers: string[];
        }) => {
          this.serverList = res.servers;
        }
      );

    // Get translation for the company select dropdown button
    this.translate
      .get(["SEARCH", "SEARCH_NOT_FOUND", "ADD_COMPANY"])
      .subscribe((t) => {
        this.companySelect.texts = {
          defaultTitle: t.ADD_COMPANY,
          searchPlaceholder: t.SEARCH,
          searchEmptyResult: t.SEARCH_NOT_FOUND,
        };
      });

    this.initCompanyGroupForm();
  }
}

interface ICompanySelect {
  companyOptions: IMultiSelectOption[];
  settings: IMultiSelectSettings;
  texts: IMultiSelectTexts;
}

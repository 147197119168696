<mat-toolbar>
    <button *ngIf="onMobileScreen && user" (click)="onSideNavOpen()" mat-button
        class="openSideNavButton"
        aria-label="Open navigation bar">
        <mat-icon>menu</mat-icon>
    </button>

    <button *ngIf="!onMobileScreen && (language === 'fi' || language === 'sv') && user"
        class="helpBtn button-rounded"
        [matMenuTriggerFor]="userManual">
        <i class="fa-solid fa-question deepBlue"></i>
    </button>

    <mat-menu #userManual="matMenu">
        <div class="menuWithPaddings">
            <a target="_blank" [href]="'https://everonstorage.blob.core.windows.net/manuals/portal_'+language+'.pdf'">
                {{'PORTAL_MANUAL' |translate}}
            </a>
            <a *ngIf="language === 'fi' || language === 'sv'|| language === 'en'" target="_blank"
                [href]="'https://everonstorage.blob.core.windows.net/manuals/ema_'+language+'.pdf'">
                {{'EMA_MANUAL' |translate}}
            </a>
        </div>
    </mat-menu>
</mat-toolbar>

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICameraChangeInfo } from 'app/models/activity-log.model';

@Pipe({
  name: 'formatCameraDetails',
  standalone: true,
  pure: true
})
export class FormatCameraDetailsPipe implements PipeTransform {
  constructor(private translate: TranslateService) {
    const translations = this.translate.instant(["ENABLED", "DISABLED", "MOTION_DETECTION", "ANONYMIZED_VISIT", "VIEW_CLEAR_IMAGE"]);

    this.ENABLED = translations.ENABLED.toLowerCase();
    this.DISABLED = translations.DISABLED.toLowerCase();
    this.MOTION_DETECTION = translations.MOTION_DETECTION;
    this.ANONYMIZED_VISIT = translations.ANONYMIZED_VISIT;
    this.VIEW_CLEAR_IMAGE = translations.VIEW_CLEAR_IMAGE;
  }

  readonly ENABLED: string;
  readonly DISABLED: string;
  readonly MOTION_DETECTION: string;
  readonly ANONYMIZED_VISIT: string;
  readonly VIEW_CLEAR_IMAGE: string;

  transform(camera: ICameraChangeInfo): string {
    let string = ""

    string += `${this.MOTION_DETECTION} ${camera.motionDetection ? this.ENABLED : this.DISABLED}; `
    string += `${this.ANONYMIZED_VISIT} ${camera.anonymizedVisit ? this.ENABLED : this.DISABLED}; `
    string += `${this.VIEW_CLEAR_IMAGE} ${camera.clearImage ? this.ENABLED : this.DISABLED}`;

    return string;
  }
}

<div class="row">
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header-white header-underline">
                        <h6 class="white-header-text">
                            {{ "CHANGE_CUSTOMER" | translate }}
                        </h6>
                    </div>
                    <div class="card-body-white">
                        <form
                            #custSelect="ngForm"
                            (ngSubmit)="changeCustomer()"
                        >
                            <div class="form-group">
                                <ngx-bootstrap-multiselect
                                    [options]="customerSelect.customerList"
                                    [settings]="customerSelect.settings"
                                    [texts]="customerSelect.texts"
                                    [(ngModel)]="
                                        customerSelect.selectedCustomer
                                    "
                                    name="customerSelectDropdown"
                                >
                                </ngx-bootstrap-multiselect>
                            </div>
                            <div class="form-group">
                                <button
                                    [disabled]="
                                        !(
                                            customerSelect.selectedCustomer
                                                ?.length > 0
                                        )
                                    "
                                    class="btn btn-outline-primary gray-bg fullWidth"
                                    type="submit"
                                >
                                    {{ "CHANGE" | translate }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header-white header-underline">
                        <h6 class="white-header-text">
                            {{ "NEWS" | translate }}
                        </h6>
                    </div>
                    <div class="card-body-white">
                        <div>
                            <div>
                                <ul class="languageSelectList">
                                    <li *ngFor="let language of languages">
                                        <img
                                            [ngClass]="
                                                activeLanguage == language.name
                                                    ? 'languageActive'
                                                    : ''
                                            "
                                            [src]="language.imgUrl"
                                            (click)="
                                                setActiveLanguage(language)
                                            "
                                        />
                                    </li>
                                </ul>
                            </div>
                            <form
                                (ngSubmit)="formSubmitSubject.next()"
                                #insertNotificationForm="ngForm"
                            >
                                <div class="form-group">
                                    <input
                                        type="text"
                                        required
                                        class="form-control"
                                        placeholder="{{
                                            'SUBJECT' | translate
                                        }}"
                                        [(ngModel)]="addNotification.subject"
                                        name="subject"
                                        #subject="ngModel"
                                    />
                                </div>
                                <div class="form-group">
                                    <textarea
                                        required
                                        class="form-control"
                                        placeholder="{{
                                            'MESSAGE' | translate
                                        }}"
                                        [(ngModel)]="addNotification.message"
                                        name="message"
                                        #message="ngModel"
                                    >
                                    </textarea>
                                </div>
                                <div class="form-check mb-2">
                                    <input
                                        id="notificationHilight"
                                        class="form-check-input"
                                        type="checkbox"
                                        [(ngModel)]="addNotification.highlight"
                                        name="highlight"
                                    />
                                    <label
                                        for="notificationHilight"
                                        class="form-check-label"
                                        >{{ "HIGHLIGHT" | translate }}</label
                                    >
                                </div>
                                <div class="form-group">
                                    <button
                                        type="submit"
                                        class="btn btn-outline-primary gray-bg"
                                        [disabled]="
                                            !insertNotificationForm.valid
                                        "
                                    >
                                        {{ "SAVE" | translate }}
                                    </button>
                                    <button
                                        class="btn btn-outline-danger"
                                        *ngIf="insertNotificationFormEdit"
                                        (click)="undoFormEdit()"
                                    >
                                        {{ "CANCEL" | translate }}
                                    </button>
                                </div>
                            </form>
                            <hr />
                        </div>
                        <div *ngFor="let notification of notifications">
                            <div
                                class="notificationBlock"
                                [ngClass]="
                                    notification.highlight
                                        ? 'notificationHighlight'
                                        : ''
                                "
                            >
                                <strong>{{ notification.subject }}</strong
                                ><br />
                                <span
                                    class="float-right notificationEdit fa-light fa-trash-alt fa-lg fa-fw faHover"
                                    (click)="deleteNotification(notification)"
                                ></span>
                                <span
                                    class="float-right notificationEdit fa-light fa-pen-to-square fa-lg fa-fw faHover"
                                    (click)="editNotification(notification)"
                                ></span>
                                <small>{{
                                    notification.timestamp | moment : "l LTS"
                                }}</small>
                                <p [innerHTML]="notification.message"></p>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header-white header-underline">
                        <h6 class="white-header-text">
                            {{ "SEARCH" | translate }}
                        </h6>
                    </div>
                    <div class="card-body-white">
                        <form #searchForm="ngForm" (ngSubmit)="search()">
                            <div class="form-check form-check-inline mb-2">
                                <input
                                    id="searchName"
                                    class="form-check-input"
                                    type="radio"
                                    name="searchType"
                                    [(ngModel)]="searchModel.type"
                                    value="2"
                                />
                                <label
                                    for="searchName"
                                    class="form-check-label"
                                    >{{ "NAME" | translate }}</label
                                >
                            </div>
                            <div class="form-check form-check-inline mb-2">
                                <input
                                    id="searchId"
                                    class="form-check-input"
                                    type="radio"
                                    name="searchType"
                                    [(ngModel)]="searchModel.type"
                                    value="1"
                                />
                                <label for="searchId" class="form-check-label"
                                    >ID</label
                                >
                            </div>
                            <div class="form-check form-check-inline mb-2">
                                <input
                                    id="searchGSM"
                                    class="form-check-input"
                                    type="radio"
                                    name="searchType"
                                    [(ngModel)]="searchModel.type"
                                    value="0"
                                />
                                <label for="searchGSM" class="form-check-label"
                                    >GSM</label
                                >
                            </div>
                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="searchModel.keyword"
                                    name="searchKeyword"
                                    required
                                    minlength="4"
                                />
                            </div>
                            <div class="form-group">
                                <button
                                    [disabled]="!searchForm.valid"
                                    type="submit"
                                    class="btn btn-outline-primary gray-bg"
                                >
                                    <span class="fa-light fa-search"></span>
                                    {{ "SEARCH" | translate }}
                                </button>
                            </div>
                        </form>
                        <div
                            *ngIf="
                                searchModel.results && !searchModel.emptyResult
                            "
                            class="searchResults"
                        >
                            <!-- Device search results-->
                            <div
                                *ngIf="searchModel.results.devices?.length > 0"
                            >
                                <h5>{{ "DEVICES" | translate }}</h5>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>ID</th>
                                            <th>
                                                {{ "DEVICE_TYPE" | translate }}
                                            </th>
                                            <th>
                                                {{
                                                    "DEVICE_SOFTWARE_VERSION"
                                                        | translate
                                                }}
                                            </th>
                                            <th>{{ "NAME" | translate }}</th>
                                            <th>
                                                {{ "CUSTOMER" | translate }}
                                            </th>
                                            <th>
                                                {{ "LOCATION" | translate }}
                                            </th>
                                            <th>GSM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let result of searchModel
                                                    .results.devices
                                            "
                                        >
                                            <td>
                                                <span
                                                    title="{{
                                                        result.lastConnection
                                                            | moment : 'l LTS'
                                                    }}"
                                                    class="fa-light"
                                                    [ngClass]="
                                                        result.onlineStatus ===
                                                        'Offline'
                                                            ? 'fa-exclamation-triangle text-danger'
                                                            : 'fa-check-circle text-success'
                                                    "
                                                ></span>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="
                                                        result.customerId;
                                                        else devElseBlock
                                                    "
                                                    class="searchChangeCustomer"
                                                    (click)="
                                                        changeCustomer(
                                                            result.customerId,
                                                            result.customerName,
                                                            result.serverName,
                                                            result.id
                                                        )
                                                    "
                                                    >{{ result.id }}</span
                                                >
                                                <ng-template #devElseBlock>{{
                                                    result.id
                                                }}</ng-template>
                                            </td>
                                            <td>
                                                {{
                                                    getDevType(
                                                        result.deviceType
                                                    )
                                                }}
                                            </td>
                                            <td>
                                                {{ result.softwareVersion }}
                                            </td>
                                            <td>{{ result.name }}</td>
                                            <td>
                                                <span
                                                    *ngIf="
                                                        result.customerId;
                                                        else custElseBlock
                                                    "
                                                    class="searchChangeCustomer"
                                                    (click)="
                                                        changeCustomer(
                                                            result.customerId,
                                                            result.customerName,
                                                            result.serverName
                                                        )
                                                    "
                                                    >{{
                                                        result.customerName
                                                    }}
                                                    ({{
                                                        result.serverName
                                                    }})</span
                                                >
                                                <ng-template #custElseBlock>{{
                                                    result.serverName
                                                }}</ng-template>
                                            </td>
                                            <td>{{ result.locationName }}</td>
                                            <td>{{ result.gsm }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- Keys search results-->
                            <div *ngIf="searchModel.results.keys?.length > 0">
                                <h5>{{ "KEYS" | translate }}</h5>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>{{ "NAME" | translate }}</th>
                                            <th>
                                                {{ "CUSTOMER" | translate }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let result of searchModel
                                                    .results.keys
                                            "
                                        >
                                            <td>{{ result.id }}</td>
                                            <td>{{ result.name }}</td>
                                            <td>
                                                <span
                                                    *ngIf="
                                                        result.customerId;
                                                        else devElseBlock
                                                    "
                                                    class="searchChangeCustomer"
                                                    (click)="
                                                        changeCustomer(
                                                            result.customerId,
                                                            result.customerName,
                                                            result.serverName
                                                        )
                                                    "
                                                    >{{
                                                        result.customerName
                                                    }}
                                                    ({{
                                                        result.serverName
                                                    }})</span
                                                >
                                                <ng-template #devElseBlock>{{
                                                    result.serverName
                                                }}</ng-template>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- Customer search results-->
                            <div
                                *ngIf="
                                    searchModel.results.customers?.length > 0
                                "
                            >
                                <h5>{{ "CUSTOMERS" | translate }}</h5>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>
                                                {{ "CUSTOMER" | translate }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let result of searchModel
                                                    .results.customers
                                            "
                                        >
                                            <td>{{ result.id }}</td>
                                            <td>
                                                <span
                                                    class="searchChangeCustomer"
                                                    (click)="
                                                        changeCustomer(
                                                            result.id,
                                                            result.name,
                                                            result.serverName
                                                        )
                                                    "
                                                    >{{ result.name }} ({{
                                                        result.serverName
                                                    }})</span
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- Personnel search results-->
                            <div
                                *ngIf="
                                    searchModel.results.personnel?.length > 0
                                "
                            >
                                <h5>{{ "STAFF_MEMBERS" | translate }}</h5>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>
                                                {{ "CUSTOMER" | translate }}
                                            </th>
                                            <th>{{ "NAME" | translate }}</th>
                                            <th>GSM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let result of searchModel
                                                    .results.personnel
                                            "
                                        >
                                            <td>{{ result.id }}</td>
                                            <td>
                                                <span
                                                    *ngIf="
                                                        result.customerId;
                                                        else perElseBlock
                                                    "
                                                    class="searchChangeCustomer"
                                                    (click)="
                                                        changeCustomer(
                                                            result.customerId,
                                                            result.customerName,
                                                            result.serverName
                                                        )
                                                    "
                                                    >{{
                                                        result.customerName
                                                    }}
                                                    ({{
                                                        result.serverName
                                                    }})</span
                                                >
                                                <ng-template #perElseBlock>{{
                                                    result.serverName
                                                }}</ng-template>
                                            </td>
                                            <td>
                                                {{ result.firstName }}
                                                {{ result.familyName }} ({{
                                                    result.name
                                                }})
                                            </td>
                                            <td>{{ result.gsm }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- Locations search results-->
                            <div
                                *ngIf="
                                    searchModel.results.locations?.length > 0
                                "
                            >
                                <h5>{{ "LOCATIONS" | translate }}</h5>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>
                                                {{ "CUSTOMER" | translate }}
                                            </th>
                                            <th>{{ "NAME" | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let result of searchModel
                                                    .results.locations
                                            "
                                        >
                                            <td>{{ result.id }}</td>
                                            <td>
                                                <span
                                                    *ngIf="
                                                        result.customerId;
                                                        else locElseBlock
                                                    "
                                                    class="searchChangeCustomer"
                                                    (click)="
                                                        changeCustomer(
                                                            result.customerId,
                                                            result.customerName,
                                                            result.serverName
                                                        )
                                                    "
                                                    >{{
                                                        result.customerName
                                                    }}
                                                    ({{
                                                        result.serverName
                                                    }})</span
                                                >
                                                <ng-template #locElseBlock>{{
                                                    result.serverName
                                                }}</ng-template>
                                            </td>
                                            <td>{{ result.name }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- Alarm routes search results-->
                            <div
                                *ngIf="
                                    searchModel.results.alarmRoutes?.length > 0
                                "
                            >
                                <h5>{{ "ALARM_ROUTINGS" | translate }}</h5>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>
                                                {{ "CUSTOMER" | translate }}
                                            </th>
                                            <th>{{ "NAME" | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let result of searchModel
                                                    .results.alarmRoutes
                                            "
                                        >
                                            <td>{{ result.id }}</td>
                                            <td>
                                                <span
                                                    *ngIf="
                                                        result.customerId;
                                                        else routesElseBlock
                                                    "
                                                    class="searchChangeCustomer"
                                                    (click)="
                                                        changeCustomer(
                                                            result.customerId,
                                                            result.customerName,
                                                            result.serverName
                                                        )
                                                    "
                                                    >{{
                                                        result.customerName
                                                    }}
                                                    ({{
                                                        result.serverName
                                                    }})</span
                                                >
                                                <ng-template #routesElseBlock>{{
                                                    result.serverName
                                                }}</ng-template>
                                            </td>
                                            <td>{{ result.name }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                !searchModel.results &&
                                !searchModel.searchComplete
                            "
                        >
                            <img
                                class="fadeLoader centerLoader logoSmall"
                                src="assets/img/loading_logo.svg"
                            />
                        </div>
                        <div
                            *ngIf="
                                searchModel.emptyResult && searchModel.results
                            "
                        >
                            {{ "SEARCH_NOT_FOUND" | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="deviceOnlineStatus">
                    <div class="card">
                        <div class="card-header-white header-underline">
                            <h6 class="white-header-text">
                                {{ "SYSTEM_STATUS" | translate }}
                            </h6>
                        </div>
                        <div class="card-body-white">
                            <div>
                                <h5>{{ "DEVICES" | translate }}</h5>
                                <div class="table-responsive">
                                    <table
                                        *ngIf="onlineStatus"
                                        class="table onlineStatusTable"
                                    >
                                        <thead>
                                            <tr>
                                                <th>
                                                    {{ "SERVER" | translate }}
                                                </th>
                                                <th class="table-success">
                                                    {{ "Online" | translate }}
                                                </th>
                                                <th class="table-warning">
                                                    {{
                                                        "OfflineUnder24h"
                                                            | translate
                                                    }}
                                                </th>
                                                <th class="table-danger">
                                                    {{
                                                        "OfflineOver24h"
                                                            | translate
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="
                                                    let server of onlineStatus
                                                "
                                            >
                                                <td style="text-align: left">
                                                    {{ server?.serverName }}
                                                </td>
                                                <td class="table-success">
                                                    {{ server?.online }}
                                                </td>
                                                <td class="table-warning">
                                                    {{
                                                        server?.offlineUnder24h
                                                    }}
                                                </td>
                                                <td class="table-danger">
                                                    {{ server?.offlineOver24h }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h5>{{ "NETWORK_FAILURES" | translate }}</h5>
                                <div *ngIf="networkFailures">
                                    <div
                                        *ngFor="let failure of networkFailures"
                                        class="mb-1"
                                    >
                                        <span
                                            class="networkFailureRow"
                                            (click)="
                                                changeCustomer(
                                                    failure.customerId,
                                                    failure.customerName,
                                                    failure.serverName
                                                )
                                            "
                                            >{{ failure.customerName }} ({{
                                                failure.serverName
                                            }}) - {{ failure.failPercentage }} %
                                            -
                                            {{ failure.failLocationCount }}
                                            /
                                            {{
                                                failure.totalLocationCount
                                            }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h5
                                    style="cursor: pointer"
                                    (click)="openOldAlerts()"
                                >
                                    {{ "OLD_ACTIVE_ALERTS" | translate }}
                                    <span *ngIf="oldAlertState && oldAlerts"
                                        >({{ oldAlerts?.length }})</span
                                    ><span
                                        class="fa-light fa-fw float-left"
                                        [ngClass]="
                                            oldAlertState
                                                ? 'fa-chevron-down'
                                                : 'fa-chevron-up'
                                        "
                                    ></span>
                                </h5>
                                <div *ngIf="oldAlertState">
                                    <div *ngIf="!oldAlerts || oldAlertsLoaded">
                                        <span
                                            *ngIf="
                                                oldAlertsLoaded && !oldAlerts
                                            "
                                            >{{ "NO_ALARMS" | translate }}</span
                                        >
                                        <img
                                            *ngIf="!oldAlertsLoaded"
                                            class="fadeLoader centerLoader logoSmall"
                                            src="assets/img/loading_logo.svg"
                                        />
                                    </div>
                                    <div
                                        *ngFor="let alert of oldAlerts"
                                        class="activeAlert"
                                    >
                                        <div class="row activeAlertRow">
                                            <div class="col-sm-3">
                                                <div>
                                                    <strong>{{
                                                        "ALARM_REGISTERED"
                                                            | translate
                                                    }}</strong>
                                                </div>
                                                <div>
                                                    {{
                                                        alert.receiveTime
                                                            | moment : "l LTS"
                                                    }}
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div>
                                                    <strong>{{
                                                        "SERVER" | translate
                                                    }}</strong>
                                                </div>
                                                <div>
                                                    {{ alert.server }}
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div>
                                                    <strong>TaskId</strong>
                                                </div>
                                                <div>
                                                    {{ alert.taskId }}
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div>
                                                    <strong>{{
                                                        "ALARM_TYPE" | translate
                                                    }}</strong>
                                                </div>
                                                <div>
                                                    {{
                                                        getActivationName(
                                                            alert.activationGroup,
                                                            alert.activationNode,
                                                            alert.deviceType,
                                                            alert.customActivationName,
                                                            alert.connTimeLimit
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row activeAlertRow">
                                            <div class="col-sm-6">
                                                <div>
                                                    <strong>{{
                                                        "CUSTOMER" | translate
                                                    }}</strong>
                                                </div>
                                                <div>
                                                    {{ alert.customerName }} ({{
                                                        alert.customerId
                                                    }})
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div>
                                                    <strong>{{
                                                        "DEVICE" | translate
                                                    }}</strong>
                                                </div>
                                                <div>
                                                    {{ alert.deviceName }} ({{
                                                        alert.deviceId
                                                    }})
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row activeAlertRow">
                                            <div class="col-sm-6">
                                                <div>
                                                    <strong>{{
                                                        "DELIVERY_TYPE"
                                                            | translate
                                                    }}</strong>
                                                </div>
                                                <div>
                                                    {{ alert.deliveryType }}
                                                </div>
                                            </div>
                                            <div
                                                class="col-sm-6"
                                                *ngIf="alert.callCount > 0"
                                            >
                                                <div>
                                                    <strong>{{
                                                        "CALL_COUNT" | translate
                                                    }}</strong>
                                                </div>
                                                <div>
                                                    {{ alert.callCount }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row activeAlertRow">
                                            <div class="col-sm-12">
                                                <button
                                                    *ngIf="
                                                        !alert.cancelled &&
                                                        !alert.error
                                                    "
                                                    class="btn btn-outline-dark danger-text fullWidth"
                                                    (click)="cancelAlert(alert)"
                                                >
                                                    <span
                                                        class="fa-solid fa-stop-circle"
                                                    ></span>
                                                    {{
                                                        "CANCEL_DELIVERY"
                                                            | translate
                                                    }}
                                                </button>
                                                <span
                                                    *ngIf="alert.cancelled"
                                                    class="danger-text"
                                                    >{{
                                                        "DELIVERY_CANCELLED"
                                                            | translate
                                                    }}</span
                                                >
                                                <span
                                                    *ngIf="alert.error"
                                                    class="danger-text"
                                                    >{{
                                                        "ERROR" | translate
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

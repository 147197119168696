<div class="card monitor">
    <div class="card-header">
        <strong>{{monitor.monitorName}} ({{monitor.aspaId}})</strong>
        <span class="fa-light fa-pen-to-square fa-lg cursorPointer ml-3" (click)="editZones()"
            *ngIf="user.roleLevel >= 100 || user.roleLevel === 40"></span>
        <span class="float-right fa fa-xmark cursorPointer" (click)="removeFromList()"></span>
        <div class="mt-1 mb-1">
            <div *ngIf="!profileEdit">
                <span class="cursorPointer" (click)="editProfile()"
                    *ngIf="monitor.hasOwnProperty('profile')">{{'PROFILE' |translate}}: {{monitor.profileName}}</span>
                <span class="cursorPointer" (click)="editProfile()"
                    *ngIf="!monitor.hasOwnProperty('profile')">{{'SELECT_PROFILE' |translate}}</span>
            </div>
            <div *ngIf="profileEdit" class="form">
                <div class="form-group">
                    <mat-form-field class="selector-full-width">
                        <mat-select [(ngModel)]="selectedProfile">
                            <mat-option *ngIf="!selectedProfile" value=undefined disabled>{{'SELECT_PROFILE'
                                |translate}}</mat-option>
                            <mat-option *ngFor="let profile of profiles" [value]="profile">
                                {{profile.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <button (click)="saveProfile()" class="btn btn-outline-primary mr-2">{{'SAVE' |translate}}</button>
                    <button (click)="editProfile()" class="btn btn-outline-danger">{{'CANCEL' |translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div *ngIf="monitor.monitoringEnabled">
            <div class="row mb-1">
                <div class="float-left ml-3 mt-3" *ngIf="monitor.alert">
                    <span class="fa-solid fa-exclamation fa-3x text-danger"></span>
                </div>
                <div class="float-left ml-3">
                    <img [src]="getIconPath(monitor.icon)" class="apStateIcon" alt="" *ngIf="monitor.icon">
                </div>
                <div class="float-left ml-3">
                    <strong>{{getIconText()}}</strong><br>
                    <span *ngIf="monitor.icon != 'unknown'"><span
                            *ngIf="this.monitor.eventTime && this.monitor.eventTime !== '0001-01-01T00:00:00Z'">{{'STARTED'|translate}}:
                            {{monitor.eventTime |moment: 'LT'}} <br>({{calculateStateDuration()}})</span></span>
                </div>
            </div>
            <!-- Show notification if monitor has an active alert -->
            <div class="alert alert-danger alertActive" *ngIf="monitor.alert">
                <span class="fa-light fa-fw fa-bell"></span> {{'AP_ALERT_' + monitor.alertType |translate}}
            </div>
            <!-- Info if superactive times are configured but not active yet -->
            <div class="superActive"
                *ngIf="(monitor.superActiveStart && monitor.superActiveEnd) && !checkSuperActiveTime()">
                <small><span class="fa-light fa-fw fa-info-circle"></span> {{'AP_SUPERACTIVE_INFO' |translate}}
                    {{monitor.superActiveStart}} - {{monitor.superActiveEnd}}</small>
            </div>
            <!-- Info if superactive times are configured and active -->
            <div class="superActive"
                *ngIf="(monitor.superActiveStart && monitor.superActiveEnd) && checkSuperActiveTime()">
                <small><span class="fa-light fa-fw fa-info-circle"></span> {{'SUPERACTIVE_TIME' |translate}}
                    {{monitor.superActiveStart}} - {{monitor.superActiveEnd}}</small>
            </div>
        </div>
    </div>
</div>
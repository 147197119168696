import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { OnOnCamera, OnOnCameraGroup, OnOnZone } from 'app/models/onon.model';
import { ApiService } from 'app/services/api.service';
import { OnOnService } from 'app/services/onon.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AngularDraggableModule } from 'angular2-draggable';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgFor, NgClass, NgIf, NgStyle } from '@angular/common';


@Component({
    selector: 'onon-monitor-zone',
    templateUrl: './onon-monitor-zone.component.html',
    styleUrls: ['./onon-monitor-zone.component.css'],
    standalone: true,
    imports: [RouterLink, NgFor, NgClass, NgIf, MatTooltipModule, AngularDraggableModule, NgStyle, MatFormFieldModule, MatSelectModule, FormsModule, MatOptionModule, TranslateModule]
})
export class OnOnMonitorZoneComponent implements OnInit {
  constructor(
    private ononApi: OnOnService,
    private route: ActivatedRoute
  ) { }


  cameraGroupId: string;
  cameraGroup: OnOnCameraGroup;
  cameras: OnOnCamera[] = [];
  selectedCameraId: string;
  allZones: OnOnZone[] = [];
  selectedZone: OnOnZone;
  snapShotLoaded: boolean = false;
  snapshotBase64: any;
  alertBackgroundImageBase64: any;
  alertBackgroundImageBaseLoaded: boolean = false;
  snapshotData: any = {
    width: 0,
    height: 0
  }
  newZone: OnOnZone = new OnOnZone();
  zoneTypeList: string[] = [
    'exit',
    'floorarea',
    'floorarea-ext',
    'bedarea',
    'wc',
    'safe',
    'ghost',
    'exclude-poses'
  ]
  zoneDrawingActive: boolean = false;

  parseZoneData(zones: any): void {
    //Convert xy coordinates into format which works with SVG and Polygons
    for (let i = 0; i < zones.length; i++) {
      const zone = zones[i];
      zone.polygonPoints = "";
      zone.options.points.forEach(p => {
        zone.polygonPoints += `${p.x},${p.y} `
      });
      zone.polygonPoints = zone.polygonPoints.trim();
      this.allZones.push(zone)
    }
  }

  editZone(zone: any): void {
    //Do nothing if zone drawing is active
    if (this.zoneDrawingActive) {
      return;
    }
    if (zone === this.selectedZone) {
      this.selectedZone = null;
      return;
    }
    this.selectedZone = zone;
    //Convert polygon points into xy coordinates for movable corners
    let coords = zone.polygonPoints.split(" ");
    this.selectedZone.cornerPoints = [];
    for (let i = 0; i < coords.length; i++) {
      //Split coordinates with ',' so we can get x and y coordinates
      let splitted = coords[i].split(',');
      let x = splitted[0];
      let y = splitted[1];
      //Check that both x and y coordinates were found
      if (x && y) {
        let xy = { 'x': x, 'y': y }
        //Push x and y coordinates into list of all xy coordinates. This list is used to show draggable corners for the zone
        this.selectedZone.cornerPoints.push(xy);
      }
    }
  }

  onMoving(event: any, index: number): void {
    this.selectedZone.modified = true;
    this.updateZoneCoords(index, event.x, event.y);
  }

  getZoneIcon(type: string): string {
    switch (type) {
      case "exit":
        return "fa-door-open";
      case "wc":
        return "fa-restroom";
      case "bedarea":
        return "fa-bed";
      case "safe":
        return "fa-couch";
      case "floorarea":
        return "fa-street-view";
      case "floorarea-ext":
        return "fa-street-view";
      case "ghost":
        return "fa-ghost";
      case "exclude-poses":
        return "fa-walking";
      default:
        break;
    }
  }

  updateZoneCoords(index: number, xOffset: number, yOffset: number): void {
    //Update offset from moved corner into original coordinates to get final coordinate of the corner
    let top = this.selectedZone.options.points[index].y + yOffset;
    let left = this.selectedZone.options.points[index].x + xOffset;
    //Get original polygon points from the zone and update the moved corner with new value
    let originalCoords = this.selectedZone.polygonPoints.split(" ");
    originalCoords[index] = left + "," + top
    this.selectedZone.polygonPoints = originalCoords.join(" ");
  }

  svgClick(event: any): void {
    //Do nothing if zone drawing is not active
    if (!this.zoneDrawingActive) {
      return;
    }
    if (!this.newZone.polygonPoints) {
      this.newZone.options = {
        direction: null,
        directionalVertex: null,
        points: []
      }
      this.newZone.polygonPoints = ""
      this.allZones.push(this.newZone);
    }
    let points = {
      x: event.layerX,
      y: event.layerY
    }
    this.newZone.options.points.push(points);
    this.newZone.polygonPoints += `${points.x},${points.y} `;
  }

  undoLastNewZoneCorner(): void {
    //Remove latest corner and populate polygon points again
    this.newZone.options.points.pop();
    this.newZone.polygonPoints = "";
    this.newZone.options.points.forEach(p => {
      this.newZone.polygonPoints += `${p.x},${p.y} `
    });
  }

  cancelNewZoneDrawing(): void {
    this.zoneDrawingActive = false;
    //Remove new zone from list of all zones only if drawing has been started
    if (this.newZone.polygonPoints) {
      this.allZones.splice(this.allZones.indexOf(this.newZone));
    }
    this.newZone = new OnOnZone();
  }

  saveNewZone(): void {
    delete this.newZone['polygonPoints']
    this.ononApi.addZoneForCamera(this.selectedCameraId, this.newZone).subscribe(res => {
      this.zoneDrawingActive = false;
      this.newZone = new OnOnZone();
      this.ononApi.getZonesForCamera(this.selectedCameraId).subscribe(res => {
        this.allZones = [];
        this.parseZoneData(res);
      })
    })
  }

  deleteZone(zone: any): void {
    this.ononApi.deleteZoneForCamera(this.selectedCameraId, zone.id).subscribe(res => {
      this.selectedZone = null;
      this.ononApi.getZonesForCamera(this.selectedCameraId).subscribe(res => {
        this.allZones = [];
        this.parseZoneData(res);
      })
    })
  }

  saveZone(zone: any): void {
    let coords = zone.polygonPoints.split(" ");
    let points = [];
    for (let i = 0; i < coords.length; i++) {
      //Split coordinates with ',' so we can get x and y coordinates
      let splitted = coords[i].split(',');
      let x = splitted[0];
      let y = splitted[1];
      //Check that both x and y coordinates were found
      if (x && y) {
        let xy = { 'x': parseFloat(x), 'y': parseFloat(y) }
        //Push x and y coordinates into list of all xy coordinates. This list is used to show draggable corners for the zone
        points.push(xy);
      }
    }
    zone.options.points = points;
    this.ononApi.updateZoneForCamera(this.selectedCameraId, zone).subscribe(res => {
      zone.modified = false;
    })
  }

  cancelZoneEdit(zone: any): void {
    //Revert polygon points to their original state
    zone.polygonPoints = "";
    zone.options.points.forEach(p => {
      zone.polygonPoints += `${p.x},${p.y} `
    });
    zone.polygonPoints.trim();
    //Reset all modification related variables
    zone.modified = false;
    this.selectedZone = null;
  }

  changeCamera(id: string): void {
    //Reset alert background data
    this.alertBackgroundImageBase64 = null;
    this.alertBackgroundImageBaseLoaded = false;
    this.selectedCameraId = id;
    this.initZoneData();
    this.getBackgroundImage();
  }

  initZoneData(): void {
    this.snapShotLoaded = false;
    //Load image from OnOn API and convert it into Base64 string to be displayed
    this.ononApi.getCameraSnapshot(this.selectedCameraId).subscribe(res => {
      this.snapShotLoaded = true;
      var reader = new FileReader();
      reader.readAsDataURL(res);
      reader.addEventListener("load", () => {
        this.snapshotBase64 = reader.result;
      }, false);
    })

    this.ononApi.getZonesForCamera(this.selectedCameraId).subscribe(res => {
      this.allZones = [];
      this.parseZoneData(res);
    })
  }

  getBackgroundImage(): void {
    this.ononApi.getAlertBackgroundImage(this.selectedCameraId).subscribe(res => {
      this.alertBackgroundImageBaseLoaded = true;
      if (res) {
        var reader = new FileReader();
        reader.readAsDataURL(res);
        reader.addEventListener("load", () => {
          this.alertBackgroundImageBase64 = reader.result;
        }, false);
      }
    }, err => {
      this.alertBackgroundImageBaseLoaded = true;
      this.alertBackgroundImageBase64 = null;
    })
  }

  setBackgroundImage(): void {
    this.ononApi.setAlertBackgroundImage(this.selectedCameraId).subscribe(res => {
      this.getBackgroundImage();
    })
  }

  deleteBackgroundImage(): void {
    this.ononApi.deleteAlertBackgroundImage(this.selectedCameraId).subscribe(res => {
      this.getBackgroundImage();
    })
  }

  ngOnInit() {
    this.route.params.subscribe(params => this.cameraGroupId = params['id']);
    //Get information about cameras for group
    this.ononApi.getCameras().subscribe(res => {
      this.cameras = res.filter(c => c.cameraGroupId === this.cameraGroupId);

      //Set first camera in array for selected as default
      this.selectedCameraId = this.cameras[0].id;
      this.getBackgroundImage();
      this.initZoneData();
    })
  }
}

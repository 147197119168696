<div class="container">

    <div class="card product-control-dialog" *ngIf="showAddProductDialog">
        <device-add (closeAddFormEvent)="closeAddBaseForm()"></device-add>
    </div>

    <div class="card product-control-dialog" *ngIf="showRemoveProductDialog">
        <device-remove-company></device-remove-company>
    </div>

    <h3>{{'PRODUCT_DATABASE' |translate}}</h3>

    <div class="row justify-content-between form pt-3">
        <div class="col-6">
            <button (click)="openAddProduct()" class="btn btn-primary"><i class="fa-solid fa-plus"></i> {{'ADD_PRODUCT'|translate}}</button>
        <button (click)="openRemoveProduct()" class="btn btn-outline-primary">{{'REMOVE_PRODUCT' |translate}}</button>
        <button class="btn btn-outline-primary">{{'REPLACE_PRODCT' |translate}}</button>
           
            
        </div>
        <div class="searchbox">
            <input #deviceSearchInput type="text" placeholder="{{'SEARCH' |translate}}"
                class="form-control border border-primary" />
        </div>
    </div>
    <div class="card card-body-container list-content">
        <div class="row dev-list-header">
            <div class="col" (click)="setSort('devId')">
                <div class="row">
                    <div class="col-8">
                        {{'SERIAL_NUMBER' |translate}}
                    </div>
                    <div class="col-4 symbol">
                        <i class="fa-solid fa-chevron-down"></i><i class="fa-solid fa-chevron-up"></i>
                    </div>
                </div>
            </div>
            <div class="col" (click)="setSort('devType')">
                <div class="row">
                    <div class="col-8">
                        {{selectedDevType?.length ? selectedDevType : ('DEVICE_TYPE' |translate)}}
                    </div>
                    <div class="col symbol">
                        <div class="selectWrapper" (click)="preventClick()">
                            <i class="fa-solid fa-caret-down caret"></i>
                            <select name="filter" id="filter" #devTypeSelect
                                (change)="selectDevType(devTypeSelect.value)">
                                <option value=""></option>
                                <option *ngFor="let devType of devTypes" value={{devType}}>{{devType}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col" (click)="setSort('leasingTime')">
                <div class="row">
                    <div class="col-8">
                        {{'DATEADDED' |translate}}
                    </div>
                    <div class="col-4 symbol">
                        <i class="fa-solid fa-chevron-down"></i><i class="fa-solid fa-chevron-up"></i>
                    </div>
                </div>
            </div>
            <div class="col" (click)="setSort('location')">
                <div class="row">
                    <div class="col-8">
                        {{selectedLocation?.length ? selectedLocation : ('LOCATION' |translate)}}
                    </div>
                    <div class="col symbol">
                        <div class="selectWrapper" (click)="preventClick()">
                            <i class="fa-solid fa-caret-down caret"></i>
                            <select name="filter" id="filter" #locationSelect
                                (change)="selectLocation(locationSelect.value)">
                                <option value=""></option>
                                <option *ngFor="let location of locations" value={{location}}>{{location}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col" (click)="setSort('deviceName')">
                <div class="row">
                    <div class="col-8">
                        {{'DEVICE_NAME' |translate}}
                    </div>
                    <div class="col-4 symbol">
                        <i class="fa-solid fa-chevron-down"></i><i class="fa-solid fa-chevron-up"></i>
                    </div>
                </div>


            </div>
            <div class="col" (click)="setSort('resident')">
                <div class="row">
                    <div class="col-8">
                        {{'RESIDENT' |translate}}
                    </div>
                    <div class="col-4 symbol">
                        <i class="fa-solid fa-chevron-down"></i><i class="fa-solid fa-chevron-up"></i>
                    </div>
                </div>
            </div>
            <div class="col" (click)="setSort('sSnumber')">
                <div class="row">
                    <div class="col-8">
                        {{'SSNUMBER' |translate}}
                    </div>
                    <div class="col-4 symbol">
                        <i class="fa-solid fa-chevron-down"></i><i class="fa-solid fa-chevron-up"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="row device-list" *ngFor="let device of dataSource">
            <div class="col" innerHTML="{{(device.devId | highlight : deviceFilter) || '-'}}">
            </div>
            <div class="col" innerHTML="{{(device.devType | highlight : deviceFilter) || '-'}}">
            </div>
            <div class="col" innerHTML="{{(device.leasingTime | highlight : deviceFilter) || '-'}}">
            </div>
            <div class="col" innerHTML="{{(device.location | highlight : deviceFilter) || '-'}}">
            </div>
            <div class="col" innerHTML="{{(device.deviceName | highlight : deviceFilter) || '-'}}">
            </div>
            <div class="col" innerHTML="{{(device.resident | highlight : deviceFilter) || '-'}}">
            </div>
            <div class="col" innerHTML="{{(device.sSnumber | highlight : deviceFilter) || '-'}}">
            </div>
        </div>
    </div>
</div>

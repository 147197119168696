<div class="px-5 pb-5">
    <div class="mt-4">
        <span class="collapsible cursorPointer" (click)="toggleDeviceInfo()">
            {{ ("DEVICE" | translate | uppercase) + " " + ("INFORMATION" | translate | uppercase) }}
            <i class="fa-regular ml-2"
                [ngClass]="deviceInfoCollapseState === 'expanded' ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </span>

        <div class="deviceInfoGrid expandContainer" [@expandCollapseDetails]="deviceInfoCollapseState">
            <div class="d-flex">
                <p>{{ "STATUS" | translate}}:</p>
                <app-feature-badge [feature]="device | resolveDeviceStatus"></app-feature-badge>
            </div>

            <p>{{ "NAME" | translate}}: <span>{{ device.deviceName || "-" }}</span></p>
            <p>{{ "LOCATION" | translate}}: <span>{{ device.company || "-" }}</span></p>
            <p>{{ "SERIAL_NUMBER" | translate}}: <span>{{ device.devId || "-" }}</span></p>

            <div *ngIf="batteryStatus" class="d-flex">
                <p>{{ "BATTERY_STATUS" | translate}}:</p>
                <span class="fa-solid fa-fw fa-lg align-self-center ml-1"
                    [ngClass]="batteryStatus | batteryIcon"></span>
            </div>

            <p>{{ "DEVICE_SOFTWARE_VERSION" | translate}}: <span>{{ device.swrel || "-" }}</span></p>
            <p *ngIf="batteryStatus"></p>
            <p>GSM: <span>{{ device.gsmNumber || "-" }}</span></p>
        </div>
    </div>

    <hr class="mt-1" />

    <div>
        <span class="collapsible cursorPointer" (click)="toggleCareRecipient()">
            {{ "CARE_RECIPIENT" | translate | uppercase }}
            <i class="fa-regular ml-2"
                [ngClass]="careRecipientCollapseState === 'expanded' ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </span>

        <div class="expandContainer careRecipientInfo" [@expandCollapseDetails]="careRecipientCollapseState">
            <p>{{ "NAME" | translate}}: <span>{{ device.resident || "-" }}</span></p>
            <p>{{ "SSNUMBER" | translate}}: <span>{{ device.sSnumber || "-" }}</span></p>
            <p>{{ "ADDRESS" | translate}}: <span>{{ device.locationAddress || "-" }}</span></p>
        </div>
    </div>

    <hr />

    <div>
        <span class="collapsible cursorPointer" (click)="toggleDeviceHistory()">
            {{ "HISTORY" | translate | uppercase }}
            <i class="fa-regular ml-2"
                [ngClass]="deviceHistoryCollapseState === 'expanded' ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </span>

        <div class="deviceHistoryExpandContainer" [@expandCollapseDetails]="deviceHistoryCollapseState">
            <app-device-history-logs
                *ngIf="!deviceHistoryLoading && deviceHistory?.events.length > 0; else noDeviceHistory"
                [historyEvents]="deviceHistory.events"></app-device-history-logs>

            <ng-template #noDeviceHistory>
                <div class="alert alert-info" style="margin-bottom: 0;" role="alert">
                    {{ "NO_HISTORY" | translate }}
                </div>
            </ng-template>
        </div>
    </div>
</div>
import { IAlertResponseTime } from "app/models/statistics.model";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { TranslateModule } from "@ngx-translate/core";
import { DeviceTypePipe, MomentPipe, SecondsToReadableFormatPipe } from "app/pipes/pipes";
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  ChangeDetectionStrategy,
} from "@angular/core";

@Component({
  selector: "alert-response-times",
  templateUrl: "./alert-response-times.component.html",
  styleUrls: ["./alert-response-times.component.css"],
  standalone: true,
  imports: [
    TranslateModule,
    SecondsToReadableFormatPipe,
    MatTableModule,
    MatSortModule,
    MomentPipe,
    DeviceTypePipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertResponseTimesComponent implements OnInit, AfterViewInit {
  constructor() {}

  @Input() responseTimes: IAlertResponseTime[];

  @ViewChild(MatSort) sort: MatSort;

  tableData: MatTableDataSource<IAlertResponseTime>;

  displayedColumns: string[] = [
    "timestamp",
    "deviceName",
    "deviceType",
    "deviceId",
    "responseTime",
  ];

  ngAfterViewInit(): void {
    this.tableData.sort = this.sort;
  }

  ngOnInit(): void {
    this.tableData = new MatTableDataSource(this.responseTimes);
  }
}

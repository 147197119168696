<span [ngSwitch]="log?.action">
    <span *ngSwitchCase="'Create'">{{ ('NEW_VISIT_WAS_SCHEDULED_FOR' | translate) + ' ' }}</span>
    <span *ngSwitchCase="'Delete'">{{ ('VISIT_WAS_DELETED_FOR' | translate) + ' ' }}</span>

    <a [routerLink]="['/camera-configuration', log?.details?.targetId ]" (click)="closeDialog.emit()">
        {{ log?.details?.targetName }}
    </a>

    <span *ngIf="!log?.staffId && log?.staffName === 'Everon Admin';else elseBlock"
        [innerHTML]="(('BY_USER' | translate : { targetString : 'Everon Admin' }) | strongWrap : 'Everon Admin')">
    </span>

    <ng-template #elseBlock>
        <span [innerHTML]="(('BY_USER' | translate :
            {
                targetString : log?.staffName | identityFormat : log?.staffId
            }) | anchorWrap : (log?.staffName | identityFormat : log?.staffId) : log?.staffId : 'Staff' : '' : true)">
        </span>
    </ng-template>
</span>
<p class="mt-1">{{ log?.timestamp | moment : "l LTS" }}</p>

<hr />

<div class="container">
    <!-- Weekdays -->
    <span class="item-title">{{ "WEEKDAY" | translate }}:</span>
    <div class="item-pair-value">
        <span [ngClass]="{'positive-highlight': log?.action === 'Create', 'negative-highlight line-through': log?.action === 'Delete' }">
            {{ (visitChanges?.weekdayTranslationCodes | translateMultiple) | join : ', ' || "NONE" | translate }}
        </span>
    </div>

    <!-- Time -->
    <span class="item-title">{{ "TIME_TEXT" | translate }}:</span>
    <div class="item-pair-value">
        <span [ngClass]="{'positive-highlight': log?.action === 'Create', 'negative-highlight line-through': log?.action === 'Delete' }">
            {{ (visitChanges?.startTime + " - " +  visitChanges?.endTime )|| "NONE" | translate }}
        </span>
    </div>

    <!-- Extended visit time -->
    <span class="item-title">{{ "EXTENDED_VISIT_TIME" | translate }}:</span>
    <div class="item-pair-value">
        <span [ngClass]="{'positive-highlight': log?.action === 'Create', 'negative-highlight line-through': log?.action === 'Delete' }">
            {{ ( visitChanges?.extendedVisitTimeMinutes + " " + ("MINUTES" | translate))|| "NONE" | translate }}
        </span>
    </div>

</div>

import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'device-remove-company',
    templateUrl: './device-remove-company.component.html',
    styleUrls: ['./device-remove-company.component.css'],
    standalone: true,
    imports: [TranslateModule]
})
export class DeviceRemoveCompanyComponent implements OnInit {

  constructor() { }

  closeAddForm()
  {

  }

  ngOnInit(): void {
  }

}

import { Component, OnChanges, Input, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Location, NgIf, NgFor, NgClass } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AnimationCollapse } from '../../../animations';
import { ThrottleButtonClickDirective } from '../../../directives/throttle-button-click.directive';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'displayconfig',
    templateUrl: './displayconfig.component.html',
    styleUrls: ['./displayconfig.component.css'],
    animations: [
        AnimationCollapse
    ],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, NgClass, NgxBootstrapMultiselectModule, MatSlideToggleModule, ColorPickerModule, ThrottleButtonClickDirective, TranslateModule]
})
export class DisplayconfigComponent implements OnChanges {

  //Init global variables and services
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {
  };

  @Input('id') selectedId: number;

  displays: Display[];
  selectedDisplay: Display;
  displayEvents: DisplayEvent[] = [];
  eventsLoaded: boolean = false;
  panelShowAll: boolean = false;
  unfinishedEvent = false;
  soundList: any;
  infoText: string;
  devArray: Array<any>;
  massEditMade: boolean = false;
  massEditFinished: boolean = false;

  multiselect: any = {
    settingsDev: {
      enableSearch: true,
      dynamicTitleMaxItems: 1,
      checkedStyle: 'fontawesome',
      containerClasses: 'fullWidth',
      buttonClasses: 'btn btn-outline-dark fullWidth',
      selectionLimit: 1,
      autoUnselect: true,
      closeOnSelect: true
    },
    settingsAct: {
      dynamicTitleMaxItems: 1,
      checkedStyle: 'fontawesome',
      containerClasses: 'fullWidth',
      buttonClasses: 'btn btn-outline-dark fullWidth',
      selectionLimit: 1,
      autoUnselect: true,
      closeOnSelect: true
    },
    deviceList: null,
    textDev: {
      defaultTitle: ''
    },
    textAct: {
      defaultTitle: ''
    }
  }

  //Load all events from single display
  getSingleDisplay(id: number): void {
    //Clear all unfinished events if there is any
    this.unfinishedEvent = false;
    //Load all events for the display
    this.displayEvents = [];
    this.eventsLoaded = false;
    this.api.getDisplayEvents(id)
      .subscribe((response: any) => {
        if (this.selectedId === id) {
          this.eventsLoaded = true;
          if (response) {
            // this.displayEvents = response;
            this.displayEvents = this.sortDisplayEvents(response);
          }
        }
      })
  }


  sortDisplayEvents(displayEvents: DisplayEvent[]): DisplayEvent[] {

    return displayEvents.sort((eventA, eventB) => {
      eventA.activationName = this.getActivationName(eventA.addEventGroup, eventA.addEventNode, eventA.addEventDevType);
      eventB.activationName = this.getActivationName(eventB.addEventGroup, eventB.addEventNode, eventB.addEventDevType);
      return eventA.addEventDevName.localeCompare(eventB.addEventDevName);
    });


  }


  collapseEvents(): void {
    for (var i = 0; i < this.displayEvents.length; i++) {
      this.displayEvents[i].collapseState = !this.panelShowAll;
    }
    this.panelShowAll = !this.panelShowAll;
  }

  //Add new event to display
  addNewEvent(): void {
    //Set unfinished variable to true. This shows red borders for the event and disables the add new event button until unfinished is cancelled or saved.
    this.unfinishedEvent = true;
    //Initialize new event with default settings
    let event = new DisplayEvent();
    event.isNew = true;
    event.collapseState = true;
    event.autoRemoveTime = 15;
    event.msgTemplate = '{tname} {tplace}';
    event.removeEventDevices = [];
    //Insert the new event to the beginning of the event array
    this.displayEvents.unshift(event);
  }

  //Add new remove device to event
  addNewRemoveDevice(event: DisplayEvent): void {
    try {
      let removeDev = new DisplayRemoveEventDevice();
      removeDev.removeEventDevID = event.removeEventDeviceArray[0].id;
      removeDev.removeEventDevName = event.removeEventDeviceArray[0].name;
      removeDev.removeEventGroup = event.removeEventActivationArray[0].group;
      removeDev.removeEventNode = event.removeEventActivationArray[0].node;
      removeDev.removeEventDevType = event.removeEventDeviceArray[0].deviceType;
      removeDev.cancelAlarms = event.cancelAlarms ? event.cancelAlarms : false;
      event.removeEventDevices.push(removeDev);
      event.removeEventActivationArray = null;
      event.removeEventDeviceArray = null;
      event.cancelAlarms = false;
    } catch (error) {
    }
  }

  //Delete remove device from the event
  removeEventRemoveDevice(device: DisplayRemoveEventDevice, event: DisplayEvent) {
    let index = event.removeEventDevices.indexOf(device);
    if (index !== -1) {
      event.removeEventDevices.splice(index, 1);
    }
  }

  //Delete event from the display
  removeEvent(event: DisplayEvent): void {
    let index = this.displayEvents.indexOf(event);
    if (index !== -1) {
      if (event.rowID) {
        this.translate.get(['ACTIVATION_DELETE', 'ACTIVATION_DELETED', 'ERROR']).subscribe(t => {
          if (confirm(t.ACTIVATION_DELETE + ' ' + event.addEventDevName + '(' + event.addEventDevID + ')?')) {
            this.api.deleteDisplayEvent(event.rowID)
              .subscribe(res => {
                this.infoText = res.ACTIVATION_DELETED
                this.displayEvents.splice(index, 1);
              }, err => {
                this.infoText = t.DELIVERY_ERROR
              })
          }
        })
      } else {
        this.displayEvents.splice(index, 1);
        this.unfinishedEvent = false;
      }
    }
  }

  //Save new event
  saveEvent(event: DisplayEvent): void {
    let saveData;
    //If event is new modify the save data to different format in order to sent it to server
    if (event.isNew) {
      //Exit function if same device with same activations already exists
      if (this.displayEvents.some(x => x.addEventDevID === event.addEventDevIDArray[0].id && x.addEventGroup === event.addEventActivationArray[0].group && x.addEventNode == event.addEventActivationArray[0].node)) {
        this.getSingleDisplay(this.selectedId);
        return;
      }
      //Initialize emptry arrays called displayList and confList
      saveData = {
        'displayList': [],
        'confList': []
      }
      //Set current display ID into previously initialized displayList array
      saveData.displayList.push(this.selectedId)
      //Activation multiselect uses arrays so get the first value from the selected array and set it to addEventDevID and Group/Node
      event.addEventDevID = event.addEventDevIDArray[0].id;
      event.addEventGroup = event.addEventActivationArray[0].group
      event.addEventNode = event.addEventActivationArray[0].node
      //Remove UI only elements from the event before sending
      this.removeUIElements(event);
      saveData.confList.push(event);
    } else {
      //Remove UI only elements from the event before sending
      this.removeUIElements(event);
      saveData = event;
    }

    this.translate.get(['CONF_SAVED', 'ERROR']).subscribe(t => {
      //Send modified data to server
      this.api.saveDisplayEvent(saveData, event.isNew)
        .subscribe(res => {
          this.infoText = t.CONF_SAVED
          //Remove unfinished flag so red borders are cleared
          if (event.isNew) {
            this.unfinishedEvent = false;
          }
          this.getSingleDisplay(this.selectedId);
        }, err => {
          this.infoText = t.DELIVERY_ERROR
        })
    })

  }

  saveAllEvents(): void {
    this.massEditFinished = true;
    let newEvents = {
      'displayList': [],
      'confList': []
    }
    newEvents.displayList.push(this.selectedId);
    this.displayEvents.forEach(event => {
      if (event.isNew) {
        newEvents.confList.push(event);
      } else if (event.massEditMade) {
        this.api.saveDisplayEvent(event, false)
          .subscribe(res => {
            event.massEditMade = false;
          }, err => {

          })
      }
    });
    if (newEvents.confList.length > 0) {
      this.api.saveDisplayEvent(newEvents, true)
        .subscribe(res => {
          this.massEditFinished = false;
          this.getSingleDisplay(this.selectedId);
        })
    }
    this.massEditMade = false;
  }

  removeUIElements(event: DisplayEvent): void {
    delete event.addEventDevIDArray
    delete event.addEventActivationArray
    delete event.removeEventDeviceArray
    delete event.removeEventActivationArray
    delete event.activationListAdd
    delete event.activationListRemove
    delete event.massEditMade
  }

  saveDisplayParameters(): void {
    let data = this.selectedDisplay;
    this.api.saveDisplayParameters(data)
      .subscribe(res => {
        this.translate.get(['CONF_SAVED']).subscribe(t => {
          this.infoText = t.CONF_SAVED
        })
      })
  }

  setSelectedSound(sound: any, event: DisplayEvent): void {
    this.soundList.forEach(e => {
      if (e.selected) {
        e.selected = false;
      }
    });
    event.soundID = sound.id;
    sound.selected = !sound.selected;
  }

  playSound(sound: any, event: DisplayEvent): void {
    let audioEl = <HTMLAudioElement>document.getElementById('displaySound')
    sound.playing = true;
    audioEl.src = sound.src;
    audioEl.load();
    audioEl.play();
  }

  checkDisplaySwVersion(version: string): boolean {
    let verInt: number = parseInt(version.replace(/\./g, ''))
    if (verInt >= 140) {
      return true;
    }
    return false;
  }

  refreshActivations(dev: any, type: string, event: DisplayEvent): void {
    //Get all activations
    let activations = JSON.parse(localStorage.getItem('act'));
    if (activations) {
      //Filter activations based on selected device type
      let actFilter = activations.filter(obj => {
        return dev[0].deviceType === obj.deviceType;
      })
      //Make new array which will work with dropdown selection module
      let list = actFilter.map(obj => {
        let a: any = {};
        a.id = obj;
        a.name = obj.name
        return a;
      })
      //Set activations to right dropdown
      switch (type) {
        case 'add':
          event.activationListAdd = list;
          break;
        case 'remove':
          event.activationListRemove = list;
          break;
        default:
          break;
      }
      this.cdr.detectChanges();
    }
  }

  eventAddDevChange(selectedDev, event: DisplayEvent) {
    if (selectedDev.length < 1) {
      event.addEventDevIDArray = null;
      return;
    }
    this.refreshActivations(selectedDev, 'add', event);
  }

  eventRemoveDevChange(selectedDev, event: DisplayEvent) {
    if (selectedDev.length < 1) {
      event.removeEventDeviceArray = null;
      return;
    }
    this.refreshActivations(selectedDev, 'remove', event);
  }

  getActivationName(group: number, node: number, type: string): string {
    let array: any = JSON.parse(localStorage.getItem('act'));
    if (array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].group === group && array[i].node === node && array[i].deviceType === type) {
          return array[i].name;
        }
      }
    }
    return '';
  }

  closeInfoBox(): void {
    this.infoText = null;
  }

  copySettings(event: DisplayEvent): void {
    event.collapseState = false;
    this.massEditMade = true;
    this.displayEvents.forEach(e => {
      if (e !== event && e.addEventDevType === event.addEventDevType) {
        e.msgTemplate = event.msgTemplate;
        e.autoRemoveTime = event.autoRemoveTime;
        e.removeEventDevices = event.removeEventDevices;
        e.foregroundColor = event.foregroundColor;
        e.backgroundColor = event.backgroundColor;
        e.soundID = event.soundID;
        e.massEditMade = true;
      }
    });
  }

  copyDeviceType(event: DisplayEvent): void {
    event.collapseState = false;
    this.massEditMade = true;
    let type = event.addEventDevType;
    this.devArray.forEach(dev => {
      if (dev.deviceType === type) {
        let eventCopy: DisplayEvent = JSON.parse(JSON.stringify(event));
        delete eventCopy.addEventID;
        delete eventCopy.rowID;
        delete eventCopy.removeEventID;
        delete eventCopy.massEditMade;
        eventCopy.addEventDevName = dev.name;
        eventCopy.addEventDevID = dev.id;
        eventCopy.isNew = true;
        eventCopy.collapseState = false;
        if (!this.displayEvents.some(x => x.addEventDevID === dev.id && x.addEventGroup === event.addEventGroup && x.addEventNode == event.addEventNode)) {
          this.displayEvents.push(eventCopy);
        }
      }
    });
  }


  ngOnChanges() {
    this.massEditMade = false;
    this.soundList = [
      {
        'id': 0,
        'src': ''
      },
      {
        'id': 1,
        'src': '/assets/sounds/1.mp3'
      },
      {
        'id': 2,
        'src': '/assets/sounds/2.mp3'
      },
      {
        'id': 3,
        'src': '/assets/sounds/3.mp3'
      },
      {
        'id': 4,
        'src': '/assets/sounds/4.mp3'
      },
      {
        'id': 5,
        'src': '/assets/sounds/5.mp3'
      }
    ]

    //Set translations on page load
    this.translate.get(['FILTER_DEVICE', 'FILTER_ACTIVATION']).subscribe(t => {
      this.multiselect.textAct.defaultTitle = t.FILTER_ACTIVATION;
      this.multiselect.textDev.defaultTitle = t.FILTER_DEVICE;
    })

    //Load all customer displays
    this.api.getSingleDisplayParameters(this.selectedId)
      .subscribe((response: any) => {
        this.selectedDisplay = response;
        if (this.selectedId) {
          this.getSingleDisplay(this.selectedId);
        }
      });

    let deviceData = {
      fullInfo: false
    }

    this.api.getDevices(deviceData)
      .subscribe(res => {
        this.devArray = res;
        this.multiselect.deviceList = res.map(obj => {
          let a: any = {};
          a.id = obj;
          a.name = obj.name ? obj.name + ' (' + obj.id + ')' : obj.id;
          return a;
        })
      });
  }
}

class DisplayEvent {
  rowID: number;
  msgTemplate: string;
  autoRemoveTime: number;
  addEventID: string;
  addEventDevID: number;
  addEventDevName: string;
  addEventDevType: string;
  addEventGroup: number;
  addEventNode: number;
  removeEventID: number;
  removeEventDevices: DisplayRemoveEventDevice[];
  foregroundColor: string = '#FFFFFF';
  backgroundColor: string = '#000000';
  soundID: number = 1;
  addEventDevIDArray: any[]; //Used only in UI
  addEventActivationArray: any[] //Used only in UI
  collapseState: boolean; //Used only in UI
  isNew: boolean = false; //Used only in UI, defaults to false
  massEditMade: boolean = false; //Used only in UI, defaults to false
  removeEventDeviceArray: any[]; //Used only in UI
  removeEventActivationArray: any[]; //Used only in UI
  activationListAdd: any; //Used only in UI
  activationListRemove: any; //Used only in UI
  activationName: string; //Used only in UI
  cancelAlarms: boolean = false; //Used only in UI
}

class DisplayRemoveEventDevice {
  removeEventDevID: number;
  removeEventDevName: string;
  removeEventGroup: number;
  removeEventNode: number;
  removeEventDevType: string;
  cancelAlarms: boolean;
}

class Display {
  displayId: number;
  displayName: string;
  silentHoursStart: string;
  silentHoursEnd: string;
  swVersion: string;
}

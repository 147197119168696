<form [formGroup]="informationForm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h6 class="text-center routeEditGroupTitle mb-2">{{'BASIC_INFORMATION' | translate}}</h6>
            </div>
        </div>
        <div class="row p-3 card card-borders">
            <div>
                <mat-form-field class="full-width">
                    <input matInput placeholder="{{'ALARM_ROUTE_NAME' | translate}}" formControlName="alarmRoutingName"
                        required autofocus>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <mat-label>{{'LOCATION' |translate}}</mat-label>
                    <mat-select formControlName="locationId">
                        <mat-option *ngFor="let location of alarmRouteLocations" [value]="location.locationId">
                            {{location.locationName}} ({{location.locationId}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <mat-label>{{'PRIORITY' |translate}}</mat-label>
                    <mat-select formControlName="priority">
                        <mat-option *ngFor="let priority of priorityList" [value]="priority">
                            {{'PRIORITY_' + priority | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <mat-label>{{'DELIVERY_TYPE' |translate}}</mat-label>
                    <mat-select formControlName="deliveryMethod">
                        <mat-optgroup *ngFor="let group of deliveryMethodGroups">
                            {{group.name | translate}}
                            <mat-option *ngFor="let deliveryType of group.deliveryTypes" [value]="deliveryType">
                                {{deliveryType | translate}}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="additionalInformationBox">
                <mat-form-field appearance="fill">
                    <mat-label>{{'ADDITIONAL_INFORMATION' | translate}}</mat-label>
                    <textarea matInput formControlName="additionalInformation"></textarea>
                </mat-form-field>
            </div>
            <h5 *ngIf="!tempRoute.defaultRoute" (click)="showAdvancedSettings = !showAdvancedSettings"
                class="cursorPointer advancedSettings mb-5">{{'ADDITIONAL_SETTINGS' |translate}}
                <span class="fa-light advancedSettings"
                    [ngClass]="showAdvancedSettings ? 'fa-chevron-down' : 'fa-chevron-up'"></span>
            </h5>
            <ng-container *ngIf="showAdvancedSettings">
                <div class="mb-4 mt-2" *ngIf="tempRoute.deliveryType.includes('CALL')">
                    <p *ngIf="tempRoute.callLoopFailType !== 'NONE'">
                        <span class="fa-solid fa-fw fa-triangle-exclamation"></span> {{'CALL_LOOP_FAIL_TYPE_INFO'
                        |translate}} <span class="fa-solid fa-fw fa-triangle-exclamation"></span>
                    </p>
                    <mat-form-field class="full-width">
                        <mat-label>{{'CALL_LOOP_FAIL_TYPE' |translate}}</mat-label>
                        <mat-select formControlName="callLoopFailType">
                            <mat-option [value]="'NONE'">--</mat-option>
                            <mat-option [value]="'LOG'">{{'LOG' |translate}}</mat-option>
                            <mat-option [value]="'EMAIL'">{{'EMAIL' |translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="emaFeatureEnabled()" class="mb-4 mt-2">
                    <mat-slide-toggle formControlName="skipInactiveTeamsForAssistance">
                        {{'SKIP_INACTIVE_TEAMS_FOR_ASSISTANCE' |translate}}
                    </mat-slide-toggle>
                    <span matTooltip="{{'KEEP_ASSISTANCE_ALARMS_WITHIN_ACTIVE_TEAM' |translate}}"
                        class="fa-solid fa-circle-info ml-2"></span>
                </div>
                <div *ngIf="!tempRoute.defaultRoute">
                    <mat-form-field class="full-width">
                        <mat-label>{{'CALL_CENTER' |translate}}</mat-label>
                        <mat-select formControlName="callCenterId">
                            <mat-option [value]="''">--</mat-option>
                            <mat-option *ngFor="let callCenter of callCenters" [value]="callCenter.id">
                                {{callCenter.name}} ({{callCenter.id}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="tempRoute.callCenterId !== ''">
                    <mat-form-field class="full-width">
                        <mat-label>{{'CALL_CENTER_DELIVERYTYPE' |translate}}</mat-label>
                        <mat-select formControlName="callCenterDeliverytype">
                            <mat-option *ngFor="let deliveryType of callCenterDeliveryTypeTranslationsMap | keyvalue"
                                [value]="deliveryType.key">
                                {{deliveryType.value | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <ng-container
                    *ngIf="tempRoute.deliveryType !== 'EMAIL' && tempRoute.deliveryType !== 'LOG' && emaFeatureEnabled() ">
                    <div *ngIf="!tempRoute.defaultRoute">
                        <mat-form-field class="full-width">
                            <mat-label>{{'FALLBACK_DELIVERY_TYPE' |translate}}</mat-label>
                            <mat-select formControlName="fallbackDeliveryMethod">
                                <mat-option [value]="'NONE'">--</mat-option>
                                <mat-optgroup *ngFor="let group of deliveryMethodGroups">
                                    {{group.name | translate}}
                                    <mat-option *ngFor="let deliveryType of group.deliveryTypes" [value]="deliveryType">
                                        {{deliveryType | translate}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div
                        *ngIf="!tempRoute.defaultRoute && tempRoute.fallBackType !== 'LOG' && tempRoute.fallBackType !== 'EMAIL' && tempRoute.fallBackType !== 'NONE'">
                        <mat-form-field class="full-width">
                            <mat-label>{{'FALLBACK_CALL_CENTER' |translate}}</mat-label>
                            <mat-select formControlName="fallbackCallCenterId">
                                <mat-option [value]="'NONE'">--</mat-option>
                                <mat-option [value]="'VERIFI'">EMAAC</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <div *ngIf="!tempRoute.defaultRoute" class="mb-4 mt-2">
                    <mat-slide-toggle formControlName="alarmIndicationGroupEnabled">
                        {{'ALARM_INDICATION_GROUP' |translate}}
                    </mat-slide-toggle>
                </div>
                <div *ngIf="this.tempRoute.deliveryType.toString().toLowerCase().includes('sms')" class="mb-4">
                    <mat-slide-toggle formControlName="waitForSmsAcknowledgement">
                        {{'WAIT_FOR_SMS_ACKNOWLEDGEMENT' |translate}}
                    </mat-slide-toggle>
                </div>
            </ng-container>
        </div>
    </div>
</form>

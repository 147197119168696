<div class="row justify-content-center">
    <div class="col-md-3">
        <h1>{{'SIGN_IN_TITLE' |translate}}</h1>
        <div>
            <ul class="languageSelectList">
                <li *ngFor="let language of languages"><img
                        [ngClass]="activeLanguage == language.name ? 'languageActive' : ''" [src]="language.imgUrl"
                        (click)="setActiveLanguage(language)"></li>
            </ul>
        </div>
        <div class="alert alert-danger" *ngIf="loginError">
            {{loginError}}
        </div>
        <div class="alert alert-danger" *ngIf="loginForm.submitted && !loginForm.valid">
            {{'LOGIN_ERROR_REQUIRED' | translate}}
        </div>
        <!-- Normal login form -->
        <form (ngSubmit)="onLogin(loginForm.valid)" #loginForm="ngForm" [hidden]="twoFactorRequired || passwordExpired">
            <div class="form-group">
                <input id="loginUsername" class="form-control" type="text" placeholder="{{'USERNAME' |translate}}"
                    [(ngModel)]="loginUsername" name="username" #username="ngModel">
            </div>
            <div class="form-group">
                <input id="loginPassword" class="form-control" type="password" [(ngModel)]="loginPassword"
                    placeholder="{{'PASSWORD' |translate}}" name="password" #password="ngModel">
            </div>
            <div class="form-group">
                <button id="loginSubmit" class="btn btn-outline-primary" [ngClass]="loginStatus ? 'active' : ''"
                    type="submit" (click)="loginStatus = true"><span
                        [ngClass]="loginStatus ? 'fa-light fa-circle-notch fa-spin' : 'fa-light fa-right-to-bracket'"
                        aria-hidden="true"></span>&nbsp; {{'SIGN_IN' |translate}}</button>
            </div>
        </form>
        <!-- Form for 2FA login -->
        <form (ngSubmit)="onTwoFactorLogin()" #twoFactorLogin="ngForm" [hidden]="!twoFactorRequired">
            <div class="form-group">
                <p class="alert alert-info">{{'PLEASE_ENTER_TWO_FACTOR_CODE' |translate}}</p>
            </div>
            <div class="form-group">
                <input id="twoFactorCode" class="form-control" type="text" [(ngModel)]="twoFactorCode"
                    name="twoFactorCodeElement" #twoFactorCodeElement="ngModel" required>
            </div>
            <div class="form-group">
                <button id="twoFactorSubmit" class="btn btn-outline-primary" type="submit">{{'CONTINUE' |translate}}
                    <span class="fa-light fa-fw fa-arrow-right"></span></button>
            </div>
        </form>
        <!-- Login if password has expired -->
        <form (ngSubmit)="onPasswordExpire()" #passwordExpiredLogin="ngForm" [hidden]="!passwordExpired">
            <div class="form-group">
                <p class="alert alert-info">{{'PASSWORD_EXPIRED_INFO' |translate}}</p>
            </div>
            <div class="form-group">
                {{'ENHANCED_PASSWORD_REQUIREMENTS' |translate}}:
                <ul>
                    <li>{{'ENHANCED_PASSWORD_REQUIREMENTS1' |translate}}</li>
                    <li>{{'ENHANCED_PASSWORD_REQUIREMENTS2' |translate}}</li>
                    <li>{{'ENHANCED_PASSWORD_REQUIREMENTS3' |translate}}</li>
                </ul>
                <p class="text-danger" *ngIf="passwordChangeError">
                    {{passwordChangeError |translate}}
                </p>
            </div>
            <div class="form-group">
                <input class="form-control" type="password" placeholder="{{'OLD_PASSWORD' |translate}}"
                    [(ngModel)]="expiredOldPassword" name="expiredOldPasswordElement"
                    #expiredOldPasswordElement="ngModel" required>
            </div>
            <div class="form-group">
                <input class="form-control"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{8,}$" type="password"
                    placeholder="{{'NEW_PASSWORD' |translate}}" [(ngModel)]="expiredNewPassword"
                    name="expiredNewPasswordElement" #expiredNewPasswordElement="ngModel" required>
            </div>
            <div class="form-group">
                <input class="form-control"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{8,}$" type="password"
                    placeholder="{{'CONFIRM_NEW_PASSWORD' |translate}}" [(ngModel)]="expiredNewPasswordConfirm"
                    name="expiredNewPasswordConfirmElement" #expiredNewPasswordConfirmElement="ngModel" required>
            </div>
            <div class="form-group">
                <button [disabled]="passwordExpiredLogin.invalid" class="btn btn-outline-primary"
                    type="submit">{{'CHANGE_PASSWORD_AND_LOGIN' |translate}} <span
                        class="fa-light fa-fw fa-arrow-right"></span></button>
            </div>
        </form>
    </div>
</div>
<div class="row justify-content-center" *ngIf="notification">
    <div class="col-md-5">
        <div>
            <hr>
            <h4>{{notification.subject}}</h4>
            <small>{{notification.timestamp |moment:'l LTS'}}</small><br>
            <p [innerHTML]="notification.message"></p>
        </div>
    </div>
</div>
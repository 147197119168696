import { CommonModule } from '@angular/common';
import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Action } from 'app/models/activity-log.model';
import { DeviceStatus } from 'app/models/devicestatus.model';

// TODO: Add more statuses when needed
export type FeatureBadgeStatus = "warning" | "default";

@Pipe({
  name: "rewriteFeature",
  pure: true,
  standalone: true,
})
export class RewriteFeaturePipe implements PipeTransform {
  deviceStatuses = {
    "online": "Online",
    "offline": "OfflineOver24h",
    "offlineUnder24h": "OfflineUnder24h",
    "lowBattery": "BATTERY_LOW",
    "inventory": "INVENTORY",
    "everonInventory": "EVERON_INVENTORY",
    "service": "SERVICE",
    "lost": "LOST",
  }

  actions = {
    "delete": "ACTIVITY_DELETE",
    "modify": "ACTIVITY_MODIFY",
    "create": "ACTIVITY_CREATE",
    "authentication": "ACTIVITY_AUTHENTICATION",
  }

  features = {
    ...this.deviceStatuses,
    ...this.actions
  }

  transform(key: string): string {
    return this.features[key];
  }
}

@Component({
  selector: 'app-feature-badge',
  templateUrl: './feature-badge.component.html',
  styleUrls: ['./feature-badge.component.css'],
  imports: [CommonModule, TranslateModule, RewriteFeaturePipe],
  standalone: true,
})
export class FeatureBadgeComponent {
  @Input() feature: DeviceStatus | Action;
  @Input() status: FeatureBadgeStatus = "default"
}



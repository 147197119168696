<div class="card">
    <div class="card-header card-header-dark d-flex align-items-center justify-content-between">
        <span><i class="fa-duotone fa-light-emergency-on"></i> {{'ALARM_REASONS' |translate}}</span>
        <button
            class="btn btn-outline-primary mr-5"
            *ngIf="!showEditForm"
            (click)="showEditForm = true">
            {{ 'MODIFY' | translate}}
        </button>
        <div *ngIf="showEditForm" class="mr-5 d-flex-column">
            <div class="d-flex justify-content-end">
                <button type="reset" class="btn btn-outline-primary" (click)="onExitForm()"><span
                        class="fal fa-times"></span> {{'CANCEL' |translate}}</button>
                <button [disabled]="!editAlarms.valid"
                    type="submit"
                    class="btn btn-primary"
                    appThrottleClick
                    (throttledClick)="onSubmit()">
                    <span class="fa-light fa-fw fa-floppy-disk"></span>
                    {{'SAVE' |translate}}
                </button>
            </div>
            <span *ngIf="!editAlarms.valid" class="validationNotice">{{'ALARM_REASON_NAMES_VALIDATION' |translate}}</span>
        </div>
    </div>

    <!-- LIST of alarm reasons inside of each category -->
    <div *ngIf="!showEditForm" class="card-body card-body-dark" cdkDropListGroup>
        <mat-expansion-panel *ngFor="let item of arrayOfTableData; let i = index"
            [expanded]="true"
            class="mb-5">
            <mat-expansion-panel-header class="indicator">
                <mat-panel-title>
                    <div class="categoryNames"
                        [ngStyle]="sortingIndex === j ? {'background-color': '#DDE3ED'}:null"
                        *ngFor="let categoryName of item.categoryNames; let j = index">{{categoryName}}
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <table
                class="drag-and-drop-list table table-striped">
                <thead>
                    <tr class="noBorder pointer" *ngIf="!item.alarms.length == 0">
                        <td></td>
                        <!-- <td class='positionIndex'></td> -->
                        <td *ngFor="let language of columnLanguages; let k = index"
                            (click)="resetSortingIndex(k)">{{language}}
                            <i class="fa-solid fa-sort text-secondary"></i>
                        </td>
                    </tr>
                </thead>
                <tbody cdkDropList
                    id="{{item.categoryID}}"
                    [cdkDropListData]="item.alarms"
                    (cdkDropListDropped)="drop($event)"
                    class="drag-and-drop-list">
                    <tr *ngIf="item.alarms.length == 0" class="preventCollapseDropArea">
                        <td></td>
                        <!-- <td class='positionIndex'></td> -->
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let translatedNames of item.alarms"
                        cdkDrag
                        class="drag-and-drop-box">
                        <td cdkDragHandle>
                            <i class="fa-solid fa-grip-dots-vertical my-auto"></i>
                        </td>
                        <!-- <td class='positionIndex'>{{ translatedNames.positionIndex }}</td> -->
                        <td class="alarmNameCell"
                            [ngStyle]="sortingIndex === j ? {'background-color': '#DDE3ED','border': '1px solid #DADADA'}:null"
                            *ngFor="let alarmName of translatedNames.names; let j = index">{{ alarmName }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-expansion-panel>
    </div>

    <!-- FORM for editing of alarm reasons and their categories -->
    <div *ngIf="showEditForm" class="card-body card-body-dark">
        <button class="btn btn-outline-primary m-2" type='button' (click)="addCategory(); $event.preventDefault();  $event.stopPropagation()">
            {{ 'ADD_CATEGORY' | translate}}
        </button>
        <!-- *ngIf="editAlarms" -->
        <form [formGroup]="editAlarms">
            <div formArrayName="categories">
                <div *ngFor="let category of getCategoryArray().controls; let i=index">
                    <div [formGroupName]="i">
                        <div class="d-flex justify-content-around">
                            <span *ngFor="let column of columnNames">
                                {{column}} <i class="fa-solid fa-sort"></i>
                            </span>
                        </div>
                        <div class="input-group">
                            <input class="form-control text-center fromCategoryNames"
                                formControlName="alarmReasonCategory_EN"
                                type="text"
                                id="{{'categoryName' + i}}"
                                [ngClass]="{invalidInput: (category.get('alarmReasonCategory_EN').touched ||
                                                            category.get('alarmReasonCategory_EN').dirty) &&
                                                            !category.get('alarmReasonCategory_EN').valid}">
                            <input class="form-control text-center fromCategoryNames"
                                formControlName="alarmReasonCategory_FI"
                                type="text"
                                id="{{'categoryName' + i}}"
                                [ngClass]="{invalidInput: (category.get('alarmReasonCategory_FI').touched ||
                                                            category.get('alarmReasonCategory_FI').dirty) &&
                                                            !category.get('alarmReasonCategory_FI').valid}">
                            <input class="form-control text-center fromCategoryNames"
                                formControlName="alarmReasonCategory_SE"
                                type="text"
                                id="{{'categoryName' + i}}"
                                [ngClass]="{invalidInput: (category.get('alarmReasonCategory_SE').touched ||
                                                            category.get('alarmReasonCategory_SE').dirty) &&
                                                            !category.get('alarmReasonCategory_SE').valid}">
                            <input class="form-control text-center fromCategoryNames"
                                formControlName="alarmReasonCategory_EE"
                                type="text"
                                id="{{'categoryName' + i}}"
                                [ngClass]="{invalidInput: (category.get('alarmReasonCategory_EE').touched ||
                                                            category.get('alarmReasonCategory_EE').dirty) &&
                                                            !category.get('alarmReasonCategory_EE').valid}">
                            <button (click)="removeCategory(i); $event.preventDefault();  $event.stopPropagation()"
                                    type='button'
                                    mat-button
                                    matTooltip="{{'REMOVE_CATEGORY' |translate}}"
                                    matTooltipPosition="above"
                                    matTooltipClass="customTooltipStyle"
                                    matTooltipShowDelay="50"
                                    matTooltipHideDelay="150">
                                <i class="fa-duotone fa-trash-can redTrashCan"></i>
                            </button>
                        </div>
                        <div formArrayName="alarmReasons"
                            class="drag-and-drop-list"
                            id="{{i}}">
                            <div *ngFor="let alarm of getAlarmReasonsArray(i).controls; let j=index"
                                class="alarmReasonRow">
                                <div [formGroupName]="j" class="input-group drag-and-drop-box">
                                    <input class="form-control"
                                        formControlName="alarmReason_EN"
                                        type="text"
                                        id="{{'alarmReason' + j + i}}">
                                    <input class="form-control"
                                        formControlName="alarmReason_FI"
                                        type="text"
                                        id="{{'alarmReason' + j + i}}">
                                    <input class="form-control"
                                        formControlName="alarmReason_SE"
                                        type="text"
                                        id="{{'alarmReason' + j + i}}">
                                    <input class="form-control"
                                        formControlName="alarmReason_EE"
                                        type="text"
                                        id="{{'alarmReason' + j + i}}">
                                    <button (click)="removeAlarm(i, j); $event.preventDefault();  $event.stopPropagation()"
                                        type='button'
                                        mat-button
                                        matTooltip="{{'REMOVE_ALARM_REASON' |translate}}"
                                        matTooltipPosition="above"
                                        matTooltipClass="customTooltipStyle"
                                        matTooltipShowDelay="50"
                                        matTooltipHideDelay="150">
                                        <i class="fa-duotone fa-trash-can redTrashCan"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-outline-primary mt-2 mb-4" type='button' (click)="addAlarm(i); $event.preventDefault();  $event.stopPropagation()">
                            {{'ADD_ALARM_REASON'| translate}}
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <!-- <p>{{ editAlarms.value | json }}</p> -->
    </div>
</div>

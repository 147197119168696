<div class="card" style="width: 40%; top: 10px; margin: auto;">
    <mat-grid-list class="mat-grid-card-header-blue" cols="2" rowHeight="35px">
        <mat-grid-tile id="devices">
            <h6 class="blue-text">{{'DEVICES' |translate}}</h6>
        </mat-grid-tile>
        <mat-grid-tile id="total-amount">
            <h6 class="blue-text">
                {{'TOTAL_AMOUNT' |translate}}: {{total}}
            </h6>
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list *ngIf="!dataLoaded && longDeviceList.length === 0" cols="1"
        rowHeight="450px">
        <mat-grid-tile>
            <img style="margin-top:5px;" class="fadeLoader logoSmall" src="assets/img/loading_logo.svg">
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list *ngIf="dataLoaded && longDeviceList.length === 0" cols="1"
        rowHeight="450px">
        <mat-grid-tile>
            <p>{{'SEARCH_NOT_FOUND' |translate}}</p>
        </mat-grid-tile>
    </mat-grid-list>
    <div *ngIf="longDeviceList.length > 0 && !show">
        <mat-grid-list cols="2" rowHeight="130px">
            <mat-grid-tile *ngFor="let tile of shortDeviceList" [colspan]="tile.cols" [rowspan]="tile.rows">
                <mat-card-content>
                    <p class="device-type" style="text-align: center;">{{tile.deviceType}}</p>
                    <h2 style="text-align: center;">{{tile.count}}</h2>
                </mat-card-content>
            </mat-grid-tile>
        </mat-grid-list>
        <div style="height: 38px;" id="card-footer">
            <button mat-icon-button (click)="showMoreOrLess()" style="float:right;outline: none; margin-right: 7px;">
                <mat-icon *ngIf="!show">expand_more</mat-icon>
                <mat-icon *ngIf="show">expand_less</mat-icon>
            </button>
        </div>
    </div>
    <div *ngIf="longDeviceList.length > 0 && show">
        <mat-grid-list cols="2" rowHeight="130px">
            <mat-grid-tile *ngFor="let tile of longDeviceList" [colspan]="tile.cols" [rowspan]="tile.rows">
                <mat-card-content>
                    <p class="device-type" style="text-align: center;">{{tile.deviceType}}</p>
                    <h2 style="text-align: center;">{{tile.count}}</h2>
                </mat-card-content>
            </mat-grid-tile>
        </mat-grid-list>
        <div style="height: 38px;" id="card-footer">
            <button mat-icon-button (click)="showMoreOrLess()" style="float:right;outline: none; margin-right: 7px;">
                <mat-icon style="color: rgb(131, 133, 134);" *ngIf="!show">expand_more</mat-icon>
                <mat-icon style="color: rgb(131, 133, 134);" *ngIf="show">expand_less</mat-icon>
            </button>
        </div>
    </div>
</div>
<br>

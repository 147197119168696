import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";

export const AnimationCollapse = trigger("animationCollapse", [
  transition(":enter", [
    style({
      height: "0px",
      overflow: "hidden",
    }),
    animate(
      300,
      style({
        height: "*",
        overflow: "hidden",
      })
    ),
  ]),
  transition(":leave", [
    style({
      height: "*",
      overflow: "hidden",
    }),
    animate(
      300,
      style({
        height: "0px",
        overflow: "hidden",
      })
    ),
  ]),
]);

export const AnimationCollapseLeave = trigger("animationCollapseLeave", [
  transition(":leave", [
    style({
      height: "*",
      overflow: "hidden",
    }),
    animate(
      300,
      style({
        height: "0px",
        overflow: "hidden",
      })
    ),
  ]),
]);

export const AddDeviceAnimation = trigger("addDeviceAnimation", [
  transition("false => true", [
    animate(1000, style({ backgroundColor: "rgba(86, 151, 171, 0.3)" })),
    animate(1000, style({ backgroundColor: "rgba(86, 151, 171, 0)" })),
  ]),
]);

export const AddRecipientAnimation = trigger("addRecipientAnimation", [
  transition("void => true", [
    animate(1000, style({ backgroundColor: "rgba(86, 151, 171, 0.3)" })),
    animate(1000, style({ backgroundColor: "rgba(86, 151, 171, 0)" })),
  ]),
]);

export const ExpandCollapseAnimation = trigger("expandCollapseDetails", [
  state("collapsed", style({ height: 0, overflow: "hidden" })),
  state("expanded", style({ height: "*", overflow: "hidden" })),
  transition("collapsed <=> expanded", animate("500ms ease")),
]);

export const FadeOutAnimation = trigger("fadeOut", [
  transition(":leave", [animate("0.5s ease-out", style({ opacity: 0 }))]),
]);

<div class="col-lg-12 d-flex pl-0">
    <!--*ngIf="customers.length > 0"-->
    <mat-form-field appearance="outline">
        <mat-select [(value)]="selectedCustomer" placeholder="&#9830; {{ 'SELECT_LOCATION' | translate }}">
            <mat-option
                *ngFor="let customer of customerSelectionData?.customers"
                [value]="customer"
                class="hideMatOptionCheckbox"
            >
                <div>
                    <strong>{{ customer.customerName }}</strong> ({{
                        customer.customerId
                    }})
                </div>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button
        class="btn btn-primary shadow-sm ml-2"
        style="margin-bottom: 11px"
        (click)="loginIntoCustomer()"
    >
        {{ "CHANGE_LOCATION" | translate }}
        <span class="ml-1"
            ><i class="fa-light fa-arrow-right-to-bracket"></i
        ></span>
    </button>
</div>
<router-outlet></router-outlet>

<p *ngIf="!pageInited">{{ "LOADING" | translate }}...</p>
<div *ngIf="pageInited">
  <div class="d-flex align-items-baseline">
    <div class="m-2">
      {{ "ITEMS" | translate }}: {{totalNumberOfResidents}}
    </div>

    <mat-paginator #paginatorTop
      class="ml-2 mb-1"
      [showFirstLastButtons]="true"
      [length]="totalNumberOfResidents"
      [pageSize]="pageSize"
      [pageSizeOptions]="[25, 50, 75, 100]"
      (page)="paginatorBottom.pageIndex = $event.pageIndex; handlePageEvent($event)"
      aria-label="Select page">
    </mat-paginator>

    <button
      class="btn btn-primary shadow-sm mb-2"
      (click)="markAsDone()"
      [disabled]="markButtonDisabled"
    >
      {{ "MARK_AS_DONE" | translate }}
    </button>
  </div>
  <!--
  <div class="card-header">
    <div class="">
      <h3>{{'CHANGED_RESIDENTS' |translate}}</h3>
      <p>{{'FLAGGED_RESIDENTS_INFO' |translate}}</p>
    </div>
  </div>
  -->

  <div class="alert alert-success mt-3" *ngIf="changesSuccess">
    {{ "CHANGES_SUCCESS" | translate }}
  </div>

  <div class="alert alert-danger mt-3" *ngIf="changesFailed">
    {{ "ERROR" | translate }}
  </div>

  <table>
    <thead class="border-bottom">
      <tr>
        <th style="width: 60px">
          <mat-checkbox (click)="selectAll()" [(ngModel)]="allSelected"></mat-checkbox>
        </th>

        <!--
        <div class="h">
            {{'NEW_RESIDENT_DISPLAYNAME' |translate}}
        </div>
        &nbsp;
        <div class="{{(sorter != 'displayName' ? 'carets' : 'onecaret')}}">
            <i *ngIf="!sortingBy[sorter] || sorter != 'displayName'" class="fas fa-caret-up caret-up"></i>
            <i *ngIf="sortingBy[sorter] || sorter != 'displayName'" class="fas fa-caret-down caret-down"></i>
        </div>
        -->

        <th (click)="sort(0)">
          <span>{{ "NAME" | translate }}</span>
          &nbsp;
          <span>
            <i *ngIf="sortKey !== 0 || (sortKey === 0 && sortBy === 0)" class="fa-solid fa-sort" style="color: #a9a9a9"></i>
            <i *ngIf="sortKey === 0 && sortBy === -1" class="fa-solid fa-sort-up"></i>
            <i *ngIf="sortKey === 0 && sortBy === 1" class="fa-solid fa-sort-down"></i>
          </span>
        </th>

        <th (click)="sort(1)">
          <span>{{ "ADDRESS" | translate }}</span>
          &nbsp;
          <span>
            <i *ngIf="sortKey !== 1 || (sortKey === 1 && sortBy === 0)" class="fa-solid fa-sort" style="color: #a9a9a9"></i>
            <i *ngIf="sortKey === 1 && sortBy === -1" class="fa-solid fa-sort-up"></i>
            <i *ngIf="sortKey === 1 && sortBy === 1" class="fa-solid fa-sort-down"></i>
          </span>
        </th>

        <!--
        <th (click)="sort(2)">
          <span>{{ "FLAGGED_RESIDENT_COMPANY" | translate }}</span>
          &nbsp;
          <span>
            <i *ngIf="sortKey !== 2 || (sortKey === 2 && sortBy === 0)" class="fa-solid fa-sort" style="color: #a9a9a9"></i>
            <i *ngIf="sortKey === 2 && sortBy === -1" class="fa-solid fa-sort-up"></i>
            <i *ngIf="sortKey === 2 && sortBy === 1" class="fa-solid fa-sort-down"></i>
          </span>
        </th>
        -->

        <th (click)="sort(3)">
          <span>{{ "PHONE_HOME" | translate }}</span>
          &nbsp;
          <span>
            <i *ngIf="sortKey !== 3 || (sortKey === 3 && sortBy === 0)" class="fa-solid fa-sort" style="color: #a9a9a9"></i>
            <i *ngIf="sortKey === 3 && sortBy === -1" class="fa-solid fa-sort-up"></i>
            <i *ngIf="sortKey === 3 && sortBy === 1" class="fa-solid fa-sort-down"></i>
          </span>
        </th>

        <th (click)="sort(4)">
          <span>{{ "PHONE_CELL" | translate }}</span>
          &nbsp;
          <span>
            <i *ngIf="sortKey !== 4 || (sortKey === 4 && sortBy === 0)" class="fa-solid fa-sort" style="color: #a9a9a9"></i>
            <i *ngIf="sortKey === 4 && sortBy === -1" class="fa-solid fa-sort-up"></i>
            <i *ngIf="sortKey === 4 && sortBy === 1" class="fa-solid fa-sort-down"></i>
          </span>
        </th>

        <th (click)="sort(5)">
          <span>{{ "DATEADDED" | translate }}</span>
          &nbsp;
          <span>
            <i *ngIf="sortKey !== 5 || (sortKey === 5 && sortBy === 0)" class="fa-solid fa-sort" style="color: #a9a9a9"></i>
            <i *ngIf="sortKey === 5 && sortBy === -1" class="fa-solid fa-sort-up"></i>
            <i *ngIf="sortKey === 5 && sortBy === 1" class="fa-solid fa-sort-down"></i>
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let resident of flaggedResidents; let i = index" class="{{ resident.inactive }}">
        <td>
          <div class="d-flex align-items-center">
            <mat-checkbox
              (change)="select(resident.checked)"
              [(ngModel)]="resident.checked"
              [id]="i"
              [checked]="resident.checked">
            </mat-checkbox>

            <div class="ml-1">
              <i class="{{ resident.icon }}"></i>
              <span class="badge badge-secondary expl">
                {{ resident.flagStatus | translate }}
              </span>
            </div>
          </div>
        </td>

        <td>
          <div class="{{ resident.residentNameChanged }}">
            {{ resident.residentName }}
          </div>
        </td>

        <td>
          <div class="{{ resident.addressChanged }}">
            {{ resident.address }}
          </div>
        </td>

        <!--
        <td>
          <div class="{{ resident.companyChanged }}">
            {{ resident.company }}
          </div>
        </td>
        -->

        <td>
          <div class="{{ resident.phoneHomeChanged }}">
            {{ resident.phoneHome }}
          </div>
        </td>

        <td>
          <div class="{{ resident.phoneCellChanged }}">
            {{ resident.phoneCell }}
          </div>
        </td>

        <td>
          <div>{{ resident.dateFlagged | moment : "L LTS" }}</div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex align-items-baseline paginatorBox">
    <div class="m-2">
      {{ "ITEMS" | translate }}: {{totalNumberOfResidents}}
    </div>

    <mat-paginator #paginatorBottom
      class="ml-2 mb-1"
      [showFirstLastButtons]="true"
      [length]="totalNumberOfResidents"
      [pageSize]="pageSize"
      [pageSizeOptions]="[25, 50, 75, 100]"
      (page)="paginatorTop.pageIndex = $event.pageIndex; handlePageEvent($event)"
      aria-label="Select page">
    </mat-paginator>
  </div>
</div>

import { Component, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Device } from 'app/models/device.model';
import { UntypedFormGroup, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from 'app/services/api.service';
import { HelperService } from 'app/services/helper.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { LockBeaconModel, LockBeaconDevice, LockBeaconValidityTimes } from 'app/models/lock-beacon.model';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'lock-beacon-settings',
    templateUrl: './lock-beacon-settings.component.html',
    styleUrls: ['./lock-beacon-settings.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatSlideToggleModule, NgxBootstrapMultiselectModule, NgFor, NgClass, MatTooltipModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgxMaterialTimepickerModule, TranslateModule]
})
export class LockBeaconSettingsComponent implements OnChanges {

  constructor(
    private api: ApiService,
    private helper: HelperService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) { };

  @Input('device') device: Device;

    user: any;

  lockBeaconConfig: LockBeaconModel;
  lockBeaconConfigOriginal: LockBeaconModel;

  lockBeaconForm: UntypedFormGroup;

  addNewAllowedDeviceArray: any[];
  addNewDeniedDeviceArray: any[];

  saveSuccess: boolean;
  saveError: boolean;

  multiselect: any = {
    settingsDev: {
      enableSearch: true,
      dynamicTitleMaxItems: 1,
      checkedStyle: 'fontawesome',
      buttonClasses: "btn btn-outline-dark fullWidth",
      selectionLimit: 1,
      autoUnselect: true,
      closeOnSelect: true
    },
    allowedDeviceList: null,
    deniedDeviceList: null,
    textDev: {
      defaultTitle: ""
    },
    textAct: {
      defaultTitle: ""
    }
  }

  createDevBitParaForm(): void {
    this.lockBeaconForm = this.fb.group({
      closeDoorForUnknownButton: this.lockBeaconConfig.closeDoorForUnknownButton,
      openDoorForUnknownButton: this.lockBeaconConfig.openDoorForUnknownButton,
      alertWhenButtonInLockArea: this.lockBeaconConfig.alertWhenButtonInLockArea
    })
  }

  submitLockBeaconSettings(): void {
    this.saveError = false;
    this.saveSuccess = false;
    this.lockBeaconConfig.closeDoorForUnknownButton = this.lockBeaconForm.value.closeDoorForUnknownButton;
    this.lockBeaconConfig.openDoorForUnknownButton = this.lockBeaconForm.value.openDoorForUnknownButton;
    this.lockBeaconConfig.alertWhenButtonInLockArea = this.lockBeaconForm.value.alertWhenButtonInLockArea;

    this.lockBeaconConfig.lostDevicesUpdated = this.checkForLostDevicesUpdate();
    this.api.saveLockAndBeaconConfig(this.lockBeaconConfig).subscribe(res => {
      this.saveSuccess = true;
    }, err => {
      this.saveError = true;
    })
  }

  checkForLostDevicesUpdate(): boolean {
    for (let i = 0; i < this.lockBeaconConfig.allowedDevices.length; i++) {
      for (let j = 0; j < this.lockBeaconConfigOriginal.allowedDevices.length; j++) {
        if (this.lockBeaconConfigOriginal.allowedDevices[j].id === this.lockBeaconConfig.allowedDevices[i].id) {
          if (this.lockBeaconConfigOriginal.allowedDevices[j].lostDevice !== this.lockBeaconConfig.allowedDevices[i].lostDevice) {
            return true;
          }
        }
      }
    }

    for (let i = 0; i < this.lockBeaconConfig.deniedDevices.length; i++) {
      for (let j = 0; j < this.lockBeaconConfigOriginal.deniedDevices.length; j++) {
        if (this.lockBeaconConfigOriginal.deniedDevices[j].id === this.lockBeaconConfig.deniedDevices[i].id) {
          if (this.lockBeaconConfigOriginal.deniedDevices[j].lostDevice !== this.lockBeaconConfig.deniedDevices[i].lostDevice) {
            return true;
          }
        }
      }
    }

    return false;
  }

  addNewAllowedDevice(): void {
    //Init new lock beacon device and set parameters
    let dev: LockBeaconDevice = new LockBeaconDevice();
    dev.id = this.addNewAllowedDeviceArray[0].id;
    dev.name = this.addNewAllowedDeviceArray[0].name;
    dev.deviceType = this.addNewAllowedDeviceArray[0].deviceType;
    dev.lostDevice = false;
    dev.editMode = true;
    dev.validityTimes = [];
    //Set validity times for always
    let validityTimes: LockBeaconValidityTimes = new LockBeaconValidityTimes();
    //Push validity times into device
    dev.validityTimes.push(validityTimes);
    //Push device into model
    this.lockBeaconConfig.allowedDevices.unshift(dev);
    //Remove device from list after it has been added
    this.multiselect.allowedDeviceList = this.multiselect.allowedDeviceList.filter(e => {
      return e.id.id !== dev.id;
    })
    //Clear array after push
    this.addNewAllowedDeviceArray = [];
  }


  addNewDeniedDevice(): void {
    //Init new lock beacon device and set parameters
    let dev: LockBeaconDevice = new LockBeaconDevice();
    dev.id = this.addNewDeniedDeviceArray[0].id;
    dev.name = this.addNewDeniedDeviceArray[0].name;
    dev.deviceType = this.addNewDeniedDeviceArray[0].deviceType;
    dev.lostDevice = false;
    dev.editMode = true;
    dev.validityTimes = [];
    //Set validity times for always
    let validityTimes: LockBeaconValidityTimes = new LockBeaconValidityTimes();
    //Push validity times into device
    dev.validityTimes.push(validityTimes);
    //Push device into model
    this.lockBeaconConfig.deniedDevices.unshift(dev);
    //Remove device from list after it has been added
    this.multiselect.deniedDeviceList = this.multiselect.deniedDeviceList.filter(e => {
      return e.id.id !== dev.id;
    })
    //Clear array after push
    this.addNewDeniedDeviceArray = [];
  }

  addNewTimeToDevice(dev: LockBeaconDevice): void {
    let validityTimes: LockBeaconValidityTimes = new LockBeaconValidityTimes();
    dev.validityTimes.push(validityTimes);
    dev.editMode = true;
  }

  closeDoorForUnknownButtonChange(event: any): void {
    if (event.checked) {
      this.lockBeaconForm.patchValue({
        openDoorForUnknownButton: false
      })
    }
  }

  openDoorForUnknownButtonChange(event: any): void {
    if (event.checked) {
      this.lockBeaconForm.patchValue({
        closeDoorForUnknownButton: false
      })
    }
  }

  removeAllowedDeviceFromList(dev: LockBeaconDevice): void {
    this.lockBeaconConfig.allowedDevices.splice(this.lockBeaconConfig.allowedDevices.findIndex(x => x.id === dev.id), 1);
    //Add the removed device back to device listing
    let a: any = {};
    a.id = dev;
    a.name = dev.name ? dev.name + " (" + dev.id + ")" : dev.id;
    //Make hard copy of the current list and push the new object there. Otherwise change detection is not triggered and list is not updated
    let newList = JSON.parse(JSON.stringify(this.multiselect.allowedDeviceList));
    newList.push(a);
    this.multiselect.allowedDeviceList = newList;
  }

  removeDeniedDeviceFromList(dev: LockBeaconDevice): void {
    this.lockBeaconConfig.deniedDevices.splice(this.lockBeaconConfig.deniedDevices.findIndex(x => x.id === dev.id), 1);
    //Add the removed device back to device listing
    let a: any = {};
    a.id = dev;
    a.name = dev.name ? dev.name + " (" + dev.id + ")" : dev.id;
    //Make hard copy of the current list and push the new object there. Otherwise change detection is not triggered and list is not updated
    let newList = JSON.parse(JSON.stringify(this.multiselect.deniedDeviceList));
    newList.push(a);
    this.multiselect.deniedDeviceList = newList;
  }


  parseAllowedDeviceList(data: any): any {
    this.lockBeaconConfig.allowedDevices.forEach(e => {
      data = data.filter(obj => {
        //ID field in list array contains whole device objects which have also id field.
        return obj.id.id !== e.id;
      })
    });
    return data;
  }

  parseDeniedDeviceList(data: any): any {
    this.lockBeaconConfig.deniedDevices.forEach(e => {
      data = data.filter(obj => {
        //ID field in list array contains whole device objects which have also id field.
        return obj.id.id !== e.id;
      })
    });
    return data;
  }

  changeValidityTime(event: any, times: LockBeaconValidityTimes, type: string): void {
    switch (event.value) {
      case "0":
        times[type + 'From'] = "00:00";
        times[type + 'To'] = "23:59";
        break;
      case "1":
        times[type + 'From'] = "00:00";
        times[type + 'To'] = "00:00";
        break;
      case "2":
        times[type + 'From'] = "08:00";
        times[type + 'To'] = "16:00";
        break;
      default:
        break;
    }
  }

  //Convert given from and to times to time type; 0 = always, 1 = never, 2 = custom times
  convertTimesToType(from: string, to: string): string {
    if (from === "00:00" && to === "23:59") {
      return "0"
    }
    if (from === "00:00" && to === "00:00") {
      return "1"
    }
    return "2"
  }

  isOldDevice(): boolean {
    if (this.device.deviceType === '32-1' || this.device.deviceType === '32-6') {
      return true;
    }
    return false;
  }

  ngOnChanges() {
    this.user = JSON.parse(localStorage.getItem('user'));
    //Load lock or beacon configurations
    this.lockBeaconConfig = null
    this.api.getLockAndBeaconConfig(this.device.id).subscribe(res => {
      this.lockBeaconConfig = res;
      //Store original model as deep copy
      this.lockBeaconConfigOriginal = JSON.parse(JSON.stringify(res));
      this.createDevBitParaForm();


      //Set translations on page load
      this.translate.get(['FILTER_DEVICE', 'FILTER_ACTIVATION']).subscribe(t => {
        this.multiselect.textAct.defaultTitle = t.FILTER_ACTIVATION;
        this.multiselect.textDev.defaultTitle = t.FILTER_DEVICE;
      })

      //Set what devices are requested from API. Get G-series buttons for new lock and beacon and old buttons for old lock and beacons
      let deviceRequestData = {};
      if (!this.isOldDevice()) {
        deviceRequestData = {
          deviceTypeList: [
            {
              prefix: "05",
              devClass: "4"
            },
            {
              prefix: "05",
              devClass: "5"
            },
            {
              prefix: "05",
              devClass: "7"
            },
            {
              prefix: "05",
              devClass: "8"
            }
          ]
        }
      } else {
        deviceRequestData = {
          deviceTypeList: [
            {
              prefix: "04",
              devClass: "1"
            },
            {
              prefix: "04",
              devClass: "2"
            },
            {
              prefix: "F0"
            }
          ]
        }
      }

      //Load device list and parse results into name and id
      this.api.getDevices(deviceRequestData)
        .subscribe(res => {
          let data: any = res.map(obj => {
            let a: any = {};
            a.id = obj;
            a.name = obj.name ? obj.name + " (" + obj.id + ")" : obj.id;
            return a;
          })
          this.multiselect.allowedDeviceList = this.parseAllowedDeviceList(data);
          this.multiselect.deniedDeviceList = this.parseDeniedDeviceList(data);
          this.cd.detectChanges();
        });
    }, err => {

    })
  }

}

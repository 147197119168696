<div class="card">
    <mat-tab-group class="card-body card-body-dark">
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="d-flex">
                  <span class="mr-2">{{'INCOMING_ALERTS' |translate}}</span>
                  <app-notification-badge class="matNotificationBadge" [count]="alerts.length" [warningStatus]="alertWarningStatus$.value"></app-notification-badge>
                </div>
            </ng-template>
            <div cdkDropList id="incomingAlertList" [cdkDropListData]="alerts" [cdkDropListConnectedTo]="['startedAlertList']" (cdkDropListDropped)="crService.drop($event)">
                <div *ngFor="let alert of alerts" cdkDrag [cdkDragDisabled]="!alert.allowTransition">
                    <!--Alert card-->
                    <app-incoming-alert-card [alert]="alert" [disabled]="alert.transitional"></app-incoming-alert-card>
                </div>
                <!-- show an empty box if where is no incoming alerts -->
                <app-incoming-alert-card *ngIf="alerts.length < 1"></app-incoming-alert-card>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="d-flex">
                  <span class="mr-2">{{'TYPE_SCHEDULED_VISIT' | translate}}</span>
                  <app-notification-badge class="matNotificationBadge" [count]="visits.length" [warningStatus]="visitWarningStatus"></app-notification-badge>
                </div>
            </ng-template>
        <!--Visit card-->
        <app-incoming-alert-card *ngIf="visits.length < 1"></app-incoming-alert-card>
            <div *ngFor="let visit of visits">
                <div class="alertCard" [class.alertBadlyOutOfTime]="visit.timeRemaining < 0" [class.alertOutOfTime]="visit.timeRemaining > 0 && visit.timeRemaining < 60">
                    <div class="alertCardHeader noBorder cursorPointer" (click)="crService.startVisit(visit)">
                        <div class="d-flex flex-wrap justify-content-between">
                            <div class="d-flex flex-column">
                                <div class="d-flex flex-row align-items-center">
                                    <i class="fa-solid faIconSize fa-camera-cctv text-primary"></i>
                                    <div class="ml-2 customerName" *ngIf="visit.companyName">
                                        {{visit.companyName}}
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <p class="alertHeader"><strong>{{visit.name}}</strong></p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center border-left">
                                <div class="mr-sm-4 my-2 pl-3">
                                    <p><small>{{'TIME_REMAINING' |translate}}</small></p>
                                    <p><strong>{{crService.getFormattedRemainingTime(visit.timeRemaining)}}</strong></p>
                                </div>
                                <div class="constantWidth">
                                    <i class="fa-duotone fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

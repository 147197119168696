<div *ngIf="route" class="card stickyCard">
    <div class="card-header-white header-underline">
        <ng-container *ngIf="route.defaultRoute">
            <h6 class="white-header-text">{{route.locationName}} ({{'DEFAULT_ROUTE' | translate}})</h6>
        </ng-container>
        <ng-container *ngIf="!route.defaultRoute">
            <h6 class="white-header-text"> {{route.name}} ({{route.id}})</h6>
        </ng-container>
        <span class="float-right cursorPointer" (click)="closeInfoForm()"><span class="fa-light fa-xmark white-header-xmark-icon"></span>
        </span>
    </div>
    <div class="card-body-white">

        <div class="row">
            <div class="col-12">
                <div class="mb-2">
                    <ul class="list-inline">
                        <li class="list-inline-item" (click)="activeTabIndex = 0; emitActiveTabIndex();">
                            <span [ngClass]="{'tab-active' : activeTabIndex === 0}" class="tab">{{'BASIC_INFORMATION'
                                |translate}}</span>
                        </li>
                        <li *ngIf="!route.defaultRoute" [ngClass]="{'tab-active' : activeTabIndex === 1}"
                            class="list-inline-item" (click)="activeTabIndex = 1; emitActiveTabIndex();">
                            <span class="tab">{{'DEVICES' |translate}}</span>
                        </li>
                        <li [ngClass]="{'tab-active' : activeTabIndex === 2}" class="list-inline-item"
                            (click)="activeTabIndex = 2; emitActiveTabIndex();">
                            <span class="tab">{{'RECIPIENTS' |translate}}</span>
                        </li>
                        <ng-container *ngIf="!route.defaultRoute">
                            <li class="list-inline-item" (click)="activeTabIndex = 3; emitActiveTabIndex();">
                                <span [ngClass]="{'tab-active' : activeTabIndex === 3}" class="tab">{{'SCHEDULE'
                                    |translate}}</span>
                            </li>
                            <li *ngIf="route.fallBackRecipients && route.fallBackRecipients.length !== 0"
                                [ngClass]="{'tab-active' : activeTabIndex === 4}" class="list-inline-item"
                                (click)="activeTabIndex = 4; emitActiveTabIndex();">
                                <span class="tab">{{'FALLBACK_RECIPIENTS' |translate}} </span>
                            </li>
                            <li *ngIf="route.alarmIndicationGroupEnabled && route.alarmIndicationGroups?.length !== 0"
                                [ngClass]="{'tab-active' : activeTabIndex === 5}" class="list-inline-item"
                                (click)="activeTabIndex = 5; emitActiveTabIndex();">
                                <span class="tab">{{'ALARM_INDICATION_GROUP' |translate}}</span>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <div class="card card-borders" *ngIf="activeTabIndex === 0">
                    <table class="table table-borderless table-sm m-2 informationTable">
                        <tbody>
                            <tr>
                                <td>
                                    <strong>{{'NAME' |translate}}:</strong>
                                </td>
                                <td>
                                    {{route.name}} ({{route.id}})
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>{{'LOCATION' |translate}}:</strong>
                                </td>
                                <td>
                                    {{route.locationName}} ({{route.locationId}})
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>{{'PRIORITY' |translate}}:</strong>
                                </td>
                                <td>
                                    {{'PRIORITY_' + route.priority | translate}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>{{'DELIVERY_TYPE' |translate}}:</strong>
                                </td>
                                <td>
                                    {{route.deliveryType | translate}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>{{'ADDITIONAL_INFORMATION' |translate}}:</strong>
                                </td>
                                <td>
                                    <div *ngIf="route.comment" class="card card-borders p-1" style="max-width: 90%">
                                        <pre>{{route.comment}}</pre>
                                    </div>
                                </td>
                            </tr>
                            <ng-container *ngIf="!route.defaultRoute">
                                <tr>
                                    <td>
                                        <h5 (click)="showAdvancedSettings = !showAdvancedSettings"
                                            class="cursorPointer advancedSettings mb-2 mt-2">{{'ADDITIONAL_SETTINGS'
                                            |translate}}
                                            <span class="fa-light advancedSettings"
                                                [ngClass]="showAdvancedSettings ? 'fa-chevron-down' : 'fa-chevron-up'"></span>
                                        </h5>
                                    </td>
                                </tr>
                                <ng-container *ngIf="showAdvancedSettings">
                                    <ng-container *ngIf="route.callLoopFailType !== 'NONE'">
                                        <tr>
                                            <td>
                                                <strong>{{'CALL_LOOP_FAIL_TYPE' |translate}}:</strong>
                                            </td>
                                            <td>
                                                {{route.callLoopFailType | translate}}
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="route.callCenterId !== ''">
                                        <tr>
                                            <td>
                                                <strong>{{'CALL_CENTER' |translate}}:</strong>
                                            </td>
                                            <td>
                                                {{getCallCenterInformation(route.callCenterId)?.name}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>{{'CALL_CENTER_DELIVERYTYPE' |translate}}:</strong>
                                            </td>
                                            <td>
                                                {{callCenterDeliveryTypeTranslationsMap.get(getCallCenterDeliveryType(route.callCenterFirst,
                                                route.keepAlarmOpenAfterCallCenter, route.emaBeforeCallCenter)) |
                                                translate}}
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="route.fallBackType !== 'NONE'">
                                        <tr>
                                            <td>
                                                <strong>{{'FALLBACK_DELIVERY_TYPE' |translate}}:</strong>
                                            </td>
                                            <td>
                                                {{route.fallBackType === 'NONE' ? '' : route.fallBackType |
                                                translate}}
                                            </td>
                                        </tr>
                                        <tr *ngIf="route.fallBackCallCenter !== 'NONE'">
                                            <td>
                                                <strong>{{'FALLBACK_CALL_CENTER' |translate}}:</strong>
                                            </td>
                                            <td>
                                                {{route.fallBackCallCenter === 'NONE' ? '' : 'CALL_CENTER_' +
                                                route.fallBackCallCenter | translate}}
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr *ngIf="emaFeatureEnabled()">
                                        <td>
                                            <strong>{{'SKIP_INACTIVE_TEAMS_FOR_ASSISTANCE' |translate}}:</strong>
                                        </td>
                                        <td>
                                            <span class="fa-solid fa-xs fa-circle"
                                                [ngClass]="route.skipInactiveTeamsForAssistance ? 'text-success' : 'text-danger'"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>{{'ALARM_INDICATION_GROUP' |translate}}:</strong>
                                        </td>
                                        <td>
                                            <span class="fa-solid fa-xs fa-circle"
                                                [ngClass]="route.alarmIndicationGroups ? 'text-success' : 'text-danger'"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>{{'WAIT_FOR_SMS_ACKNOWLEDGEMENT' |translate}}:</strong>
                                        </td>
                                        <td>
                                            <span class="fa-solid fa-xs fa-circle"
                                                [ngClass]="route.waitForSmsAcknowledgement ? 'text-success' : 'text-danger'"></span>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="card card-borders" *ngIf="activeTabIndex === 1">
                    <p *ngIf="route.devices.length <= 0" class="text-center">{{'NO_DEVICES' |translate}}</p>
                    <table *ngIf="route.devices.length > 0"
                        class="table table-borderless table-sm table-responsive table-hover centerTableContent">
                        <thead>
                            <tr class="border-bottom">
                                <th style="width:1%;"></th>
                                <th>{{'NAME' |translate}}</th>
                                <th>{{'ID' |translate}}</th>
                                <th>{{'DEVICE_TYPE' |translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let device of route.devices">
                                <tr class="border-top" (click)="openDevicePage(device.deviceId)">
                                    <td>
                                        <span [ngClass]="getDeviceIconClass(device.deviceType)"></span>
                                    </td>
                                    <td class="boldAndBluePointer">{{device.deviceName}}</td>
                                    <td class="boldAndBluePointer">{{device.deviceId}}</td>
                                    <td class="boldAndBluePointer">{{getDeviceType(device.deviceType)}}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan="3">
                                        <div *ngFor="let activation of device.activations">
                                            - {{getActivationName(activation.group, activation.node,
                                            device.deviceType, '', device.connectionTimeLimit)}}
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="card card-borders" *ngIf="activeTabIndex === 2">
                    <p *ngIf="!route.recipients.length" class="text-center">{{'NO_USER' |translate}}</p>
                    <table *ngIf="route.recipients.length"
                        class="table table-borderless table-sm table-responsive table-hover centerTableContent">
                        <thead>
                            <tr class="border-bottom">
                                <th style="width:1%;"></th>
                                <th>{{'NAME' |translate}}</th>
                                <th>{{'CONTACT_PHONE' |translate}}</th>
                                <th>{{'ID' |translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let recipient of route.recipients">
                                <tr
                                    (click)="recipient.type === 'USER' ? openPersonnelPage(recipient.id) : openTeamPage(recipient.id) ">
                                    <td><span [ngClass]="recipientType(recipient.type)"></span></td>
                                    <td class="boldAndBluePointer">{{recipient.name}}</td>
                                    <td class="boldAndBluePointer">{{recipient.gsmNumber}}</td>
                                    <td class="boldAndBluePointer">{{recipient.id}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="card card-borders" *ngIf="activeTabIndex === 3">
                    <p *ngIf="route.serviceTimeValidity === 0" class="text-center">{{'NEVER' |translate}}</p>
                    <p *ngIf="route.serviceTimeValidity === 10" class="text-center">{{'ALWAYS' |translate}}</p>
                    <ng-container *ngIf="route.serviceTimeValidity === 1">
                        <table class="table table-borderless table-responsive table-hover centerTableContent">
                            <thead>
                                <tr class="border-bottom">
                                    <th style="width:1%;"></th>
                                    <th>{{'DAY' |translate}}</th>
                                    <th>{{'FILTER_TIME_TITLE' |translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let serviceTime of route.serviceTimes">
                                    <tr>
                                        <td>
                                            <span [ngClass]="serviceTimeClass(serviceTime)"></span>
                                        </td>
                                        <td>{{daysTranslationMap.get(serviceTime.day) | translate}}</td>

                                        <td>{{parseServiceTime(serviceTime) | translate}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </ng-container>
                </div>
                <ng-container *ngIf="activeTabIndex === 4">
                    <div class="card">
                        <table *ngIf="route.fallBackRecipients"
                            class="table table-borderless table-responsive table-hover centerTableContent">
                            <thead>
                                <tr class="border-bottom">
                                    <th style="width:1%;"></th>
                                    <th>{{'NAME' |translate}}</th>
                                    <th>{{'CONTACT_PHONE' |translate}}</th>
                                    <th>{{'ID' |translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let fallBackRecipient of route.fallBackRecipients">
                                    <tr
                                        (click)="fallBackRecipient.type === 'USER' ? openPersonnelPage(fallBackRecipient.id) : openTeamPage(fallBackRecipient.id)">
                                        <td><span [ngClass]="recipientType(fallBackRecipient.type)"></span></td>
                                        <td class="boldAndBluePointer">{{fallBackRecipient.name}}</td>
                                        <td class="boldAndBluePointer">{{fallBackRecipient.gsmNumber}}</td>
                                        <td class="boldAndBluePointer">{{fallBackRecipient.id}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
                <ng-container *ngIf="activeTabIndex === 5">
                    <div class="card">
                        <table *ngIf="route.alarmIndicationGroupEnabled && route.alarmIndicationGroups?.length !== 0"
                            class="table table-borderless table-responsive table-hover centerTableContent">
                            <thead>
                                <tr class="border-bottom">
                                    <th style="width:1%;"></th>
                                    <th>{{'NAME' |translate}}</th>
                                    <th>{{'ID' |translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let alarmIndicationGroup of route.alarmIndicationGroups">
                                    <tr class="border-top" (click)="openDevicePage(alarmIndicationGroup.baseStationId)">
                                        <td>
                                            <span
                                                [ngClass]="getDeviceIconClass(alarmIndicationGroup.deviceType)"></span>
                                        </td>
                                        <td class="boldAndBluePointer">{{alarmIndicationGroup.baseStationName}}</td>
                                        <td class="boldAndBluePointer">{{alarmIndicationGroup.baseStationId}}</td>
                                    </tr>
                                    <tr>
                                        <td>-</td>
                                        <td colspan="3">
                                            <strong>{{'ALERTING_STATIONS' |translate}}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span
                                                [ngClass]="enabledIndicator(alarmIndicationGroup.alarmOnHomeBase)"></span>
                                        </td>
                                        <td colspan="3">{{'ALARM_ON_HOMEBASE' |translate}}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span
                                                [ngClass]="enabledIndicator(alarmIndicationGroup.alarmOnRoamingBase)"></span>
                                        </td>
                                        <td colspan="3">{{'ALARM_ON_ROAMINGBASE' |translate}}</td>
                                    </tr>
                                    <tr>
                                        <td>-</td>
                                        <td colspan="3">
                                            <strong>{{'ALARM_TYPE' |translate}}</strong>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!alarmIndicationGroup.buzzerOnly">
                                        <td>
                                            <span
                                                [ngClass]="enabledIndicator(alarmIndicationGroup.buzzerAndLed)"></span>
                                        </td>
                                        <td colspan="3">{{'BUZZER_AND_LED' |translate}}</td>
                                    </tr>
                                    <tr *ngIf="!alarmIndicationGroup.buzzerAndLed">
                                        <td>
                                            <span [ngClass]="enabledIndicator(alarmIndicationGroup.buzzerOnly)"></span>
                                        </td>
                                        <td colspan="3">{{'BUZZER_ONLY' |translate}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="user.roleLevel >= 100">
                <div class="ml-3">
                    <div class="form-group" *ngIf="!deleteConfirmation">
                        <span *ngIf="!route.defaultRoute" class="btn btn-outline-danger float-right cursorPointer ml-2"
                            (click)="deleteConfirmation = true"><span class="fa-light fa-trash-alt"></span>
                            {{'DELETE'
                            |translate}}</span>
                        <span class="btn btn-outline-primary gray-bg float-right cursorPointer"
                            (click)="showEditingForm();"><span class="fa-light fa-fw fa-pen-to-square"></span>
                            {{'MODIFY' |translate}}</span>
                    </div>
                    <div class="form-group" *ngIf="deleteConfirmation">
                        <p>{{'CONFIRM_ROUTE_DELETE' |translate: {routename: route.name} }}</p>
                        <span class="btn btn-outline-danger cursorPointer" (click)="deleteRoute()"><span
                                class="fa-light fa-trash-alt"></span> {{'DELETE' |translate}}</span>
                        <span class="btn btn-outline-primary gray-bg ml-2 cursorPointer"
                            (click)="deleteConfirmation = false;"><span class="fa-light fa-xmark"></span> {{'CANCEL'
                            |translate}}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="row" *ngIf="!route">
    <div class="col-lg-12">
        <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg">
    </div>
</div>

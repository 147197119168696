<div class="form-group row">

    <div class="col-5">
        <p class="mb-1"><strong>{{ "CAMERAS" | translate }}:</strong></p>
        <app-multiselect-dropdown
            class="camera-select-dropdown"
            [placeholder]="'SELECT_CAMERAS' | translate"
            [groupedOptions]="camerasOfLocation"
            [disabled]="disabled?.value"
            [enableSearch]="true"
            (selectedChange)="onCameraSelectedChange($event)"
            [selected]="selectedCameraCheckboxes">
        </app-multiselect-dropdown>
    </div>

    <div class="col">
        <ng-container *ngIf="cameras?.length > 0; else noCamerasAdded">
            <div class="pb-1">
                <strong>{{ "SELECTED_CAMERAS" | translate }}:</strong>
            </div>
            <div *ngFor="let camera of cameras" class="bordered-box py-2">
                <p class="cameraName mb-1">
                    {{ camera.displayName || "-" }} <span *ngIf="camera.discoveryName">({{ camera.discoveryName }})</span>
                </p>

                <label class="custom-checkbox">
                    <input
                        type="checkbox"
                        name="motion-detection"
                        (change)="toggleMotionDetection($event, camera)"
                        [value]="camera.motionDetection.enabled"
                        [checked]="camera.motionDetection.enabled"
                    />
                    {{ "MOTION_DETECTION_IN_CAMERA" | translate }}
                </label>

                <label class="custom-checkbox pl-3">
                    <input
                        type="checkbox"
                        name="snapshot"
                        [disabled]="!camera.motionDetection.enabled"
                        [(ngModel)]="camera.motionDetection.snapshot"
                    >
                    {{ "SNAPSHOT_WITH_ALERT" | translate }}
                </label>

                <label class="custom-checkbox pl-3">
                    <input
                        type="checkbox"
                        name="videoclip"
                        [disabled]="!camera.motionDetection.enabled"
                        [(ngModel)]="camera.motionDetection.videoclip"
                    />
                    {{ "VIDEOCLIP_WITH_ALERT" | translate }}
                </label>

                <label class="custom-checkbox">
                    <input
                        type="checkbox"
                        name="anonymized-visit"
                        (change)="toggleAnonymizedVisit($event, camera)"
                        [value]="camera.anonymization"
                        [checked]="camera.anonymization"
                    />
                    <span>
                        <i class="fa-solid fa-eye-slash"></i>
                        {{ "ANONYMIZED_VISIT" | translate }}
                    </span>
                </label>

                <label class="custom-checkbox pl-3">
                    <input
                        type="checkbox"
                        name="clear-img-toggle"
                        [disabled]="!camera.anonymization"
                        [(ngModel)]="camera.toggleAnonymization"
                    />
                    <span>
                        <i class="fa-solid fa-eye"></i>
                        {{ "TOGGLE_CLEAR_IMAGE" | translate }}
                    </span>
                </label>
            </div>
        </ng-container>
        <ng-template #noCamerasAdded>
            <div class="font-italic margin-top-2rem">
                {{'NO_CAMERAS_ADDED' | translate}}
            </div>
        </ng-template>
    </div>
</div>

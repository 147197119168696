<div>
<h6>{{'MORE_INFORMATION' |translate}}</h6>
<p><b>{{'SERIAL_NUMBER' |translate}}:</b> {{data.devId}}</p>
<p><b>{{'NAME' |translate}}:</b> {{data.deviceName}}</p>
<p><b>{{'LOCATION' |translate}}:</b> {{data.location}}</p>
<p><b>{{'RESIDENT' |translate}}:</b> {{data.resident}}</p>
<p><b>SSN#</b> {{data.sSnumber}}</p>
<p><b>{{'LEASING_TIME' |translate}}</b>: {{data.leasingTime}}</p>
</div>
    <h6>
	{{'HISTORY' |translate}}
    </h6>
<div class="row">
	<div class="col">
		<b>{{'TIMESTAMP'| translate}}</b>
	</div>
	<div class="col">
		<b>{{'EVENT'| translate}}</b>
	</div>
	<div class="col">
		<b>{{'LOCATION'| translate}}</b>
	</div>
</div>

<div class="row" *ngFor="let history of histories">
	<div class="col">
        {{history.timestamp}}
    </div>
	<div class="col">
        {{history.event}}
    </div>
    <div class="col">
        {{history.location}}
    </div>

</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { NgIf } from '@angular/common';


@Component({
    selector: 'ap-alert-view',
    templateUrl: './ap-alert-view.component.html',
    styleUrls: ['./ap-alert-view.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class AnnaPerennaAlertViewComponent implements OnInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  alarmId: string;
  alert:AnnaPerennaAlert;
  errorMsg: string;

  getAnnaPerennaAlert(id: string): void {
    this.api.getAnnaPerennaAlert(id).subscribe(res => {
      if (res) {
        this.alert = res;
      } else {
        this.translate.get(['SEARCH_NOT_FOUND']).subscribe(t => {
          this.errorMsg = t.SEARCH_NOT_FOUND;
        })
      }
    }, err => {
      this.translate.get(['ERROR']).subscribe(t => {
        this.errorMsg = t.ERROR;
      })
    })
  }
  
  ngOnInit(): void {
    //If user comes with direct URL get the ID from the URL
    this.alarmId = this.route.snapshot.paramMap.get('id');
    if (this.alarmId) {
      this.getAnnaPerennaAlert(this.alarmId);
    }
  }
}

class AnnaPerennaAlert {
  alertId: string;
  imageData: string;
  timeStamp: Date;
}
import { Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IActivityLog, IScheduledVisitChangeRecord } from 'app/models/activity-log.model';
import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterModule } from '@angular/router';
import { IdentityFormatPipe, JoinPipe, MomentPipe, TranslateMultiplePipe } from 'app/pipes/pipes';
import { AnchorWrapPipe, StrongWrapPipe } from '../activity-log-table/activity-log-table-pipes';

@Component({
  selector: 'app-activity-camera-visit-details',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, MomentPipe, JoinPipe, TranslateMultiplePipe, IdentityFormatPipe, AnchorWrapPipe, StrongWrapPipe],
  templateUrl: './activity-camera-visit-details.component.html',
  styleUrls: ['../activity-details-dialog/activity-details-content-styles.css']
})
export class ActivityCameraVisitDetailsComponent implements OnChanges {
  constructor(private router: Router) { }

  @Output() closeDialog = new EventEmitter<void>();
  @Input() log: IActivityLog;

  visitChanges: IScheduledVisitChangeRecord;

  // Handle the staff link navigation programmatically,
  // since clicking on the staff anchor tag will not trigger the router navigation for some reason.
  @HostListener("document:click", ["$event"])
  handleStaffLinkNavigation(event: Event): void {
    const element = event.target as HTMLElement;

    // Check if the clicken element is a anchor tag
    if (element.tagName.toLocaleLowerCase() === "a") {
      const classList = element.classList;

      // Check if the anchor tag has a class of staff
      if (classList.contains("staff")) {
        event.preventDefault();
        const dataUrl = element.getAttribute("data-url");
        this.router.navigateByUrl(dataUrl);
        this.closeDialog.emit();
      }
    }
  }

  ngOnChanges(): void {
    // Copy the group changes to a local variable to just make the template little more cleaner.
    this.visitChanges = this.log?.details?.scheduledVisitChanges;
  }
}

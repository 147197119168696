import { Component, EventEmitter, Input, Output, HostListener, ElementRef, ViewChild, OnChanges, SimpleChanges, Query, QueryList, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HighlightSearch } from 'app/pipes/pipes';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';

export interface IOption {
  value: any,
  name: string,
  disabled?: boolean,
}

export interface IGroupedOption {
  label: string,
  id?: string,
  iconClass?: string,
  disabled?: boolean,
  options: IOption[],
}

@Component({
  selector: 'app-multiselect-dropdown',
  standalone: true,
  imports: [CommonModule, TranslateModule, HighlightSearch, MatCheckboxModule],
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.css']
})
export class MultiselectDropdownComponent implements OnChanges {

  constructor(private translate: TranslateService) { }

  @ViewChild('dropdownMenu') dropdownMenu: ElementRef;
  @ViewChild('button') button: ElementRef;

  @ViewChildren(MatCheckbox) checkboxes: QueryList<MatCheckbox>;

  @Input() label: string = "";
  @Input() placeholder: string = this.translate.instant("SELECT");
  @Input() options: IOption[] = [];
  @Input() groupedOptions: IGroupedOption[] = [];
  @Input() enableSearch: boolean = false;
  @Input() selected: any[] = [];
  @Input() disabled: boolean = false;

  @Output() selectedChange = new EventEmitter<any[]>(); // Output for two-way binding

  showDropdownMenu: boolean = false;
  searchText: string = "";
  optionsCopy: IOption[] = []; // Used to reset options after search
  groupOptionsCopy: IGroupedOption[] = [];
  combinedOptions: IOption[] = []; // Used to find the name of the selected option

  get selectedText(): string {
    if (this.selected.length === 0) {
      return this.placeholder;
    } else if (this.selected.length === 1) {
      const text = this.combinedOptions.find(option => option.value === this.selected[0])?.name;
      return text ? this.translate.instant(text) : `1 ${this.translate.instant("SELECTED")}`;
    } else {
      return this.selected.length + " " + this.translate.instant("SELECTED");
    }
  }

  toggleCheckbox(checkbox: MatCheckbox, value: any, clickedEl: HTMLElement): void {
    if (checkbox.disabled) {
      return;
    }

    // If the click was not on the checkbox itself, toggle the checkbox to change its state
    if (clickedEl.tagName.toLowerCase() !== 'input') {
      checkbox.toggle();
    }

    if (checkbox) {
      this.setSelected(value, checkbox.checked);
    }
  }

  isSelected(value: any): boolean {
    return this.selected.includes(value);
  }

  setSelected(value: any, checked: boolean): void {
    if (checked) {
      this.selected = [...this.selected, value];
    } else {
      this.selected = [...this.selected.filter(selected => selected !== value)];
    }
    this.selectedChange.emit(this.selected);
  }

  openDropdown(): void {
    this.showDropdownMenu = true;
  }

  untickAll(): void {
    this.selected = [];
    this.checkboxes.forEach(checkbox => checkbox.checked = false);
    this.selectedChange.emit(this.selected);
  }

  @HostListener('document:click', ['$event'])
  handleHideDropdown(event: any): void {
    if (!this.button || !this.dropdownMenu) {
      return;
    }

    const clickedDropdown = this.dropdownMenu.nativeElement.contains(event.target);
    const clickedButton = this.button.nativeElement.contains(event.target);

    if (!clickedDropdown && !clickedButton) {
      this.showDropdownMenu = false;
      this._resetOptions();
      this._clearSearchText();
    }
  }

  search(event: any): void {
    this.searchText = event.target.value;

    if (!this.searchText || this.searchText === "") {
      this._resetOptions();
      return;
    }

    this._handleOptionSearch();
    this._handleGroupSearch();
  }

  /**
   * Performs necessary actions when input properties change.
   * @param changes Contains changes to the input properties
   */
  ngOnChanges(changes: SimpleChanges): void {

    /**
     * Deep clones options and groupedOptions only once when they are first set to avoid mutating the original arrays later,
     * since ngOnChanges is being called whenever user selects an option and it's known to mutate the original arrays while the search is active.
     */
    if ((changes?.groupedOptions?.previousValue ?? []).length === 0 && changes?.groupedOptions?.currentValue?.length > 0) {
      this._copyGroupedOptions();
    }

    if ((changes?.options?.previousValue ?? []).length === 0 && changes?.options?.currentValue?.length > 0) {
      this._copyOptions();
    }

    /**
     * Update disabled states of options and groupedOptions copy objects whenever input for those properties changes.
     * Fixes the known issue of disabled states disappearing when search is reset or dropdown is closed and opened again.
     */
    if (changes.groupedOptions) {
      this._updateGroupOptionsCopyDisabledStates();
    }

    if (changes.options) {
      this._updateOptionsCopyDisabledStates();
    }
  }

  private _handleOptionSearch(): void {
    this.options = this.optionsCopy
      .filter(option => option.name
        .toLocaleLowerCase()
        .includes(this.searchText.toLocaleLowerCase())
      );
  }

  private _handleGroupSearch(): void {
    this.groupedOptions = this.groupOptionsCopy.map(group => {
      return {
        label: group.label,
        iconClass: group.iconClass,
        options: group.options
          .filter(option => option.name
            .toLocaleLowerCase()
            .includes(this.searchText.toLocaleLowerCase())
          )
      }
    }).filter(group => group.options.length > 0);
  }

  private _resetOptions(): void {
    // Deep clone options to avoid changing the original array
    this.options = structuredClone(this.optionsCopy);
    this.groupedOptions = structuredClone(this.groupOptionsCopy);
  }

  private _clearSearchText(): void {
    this.searchText = "";
  }

  private _updateGroupOptionsCopyDisabledStates(): void {
    this.groupOptionsCopy = this.groupOptionsCopy.map(group => {
      const inputtedGroup = this.groupedOptions.find(inputtedGroup => inputtedGroup.label === group.label);
      if (inputtedGroup) {
        return {
          ...group,
          disabled: inputtedGroup.disabled,
          options: group.options?.map(option => {
            const inputtedOption = inputtedGroup.options.find(inputtedOption => inputtedOption.value === option.value);
            if (inputtedOption) {
              return {
                ...option,
                disabled: inputtedOption.disabled
              }
            }
            return option;
          })
        }
      }
      return group;
    });
  }

  private _updateOptionsCopyDisabledStates(): void {
    this.optionsCopy = this.optionsCopy.map(option => {
      const inputtedOption = this.options.find(inputtedOption => inputtedOption.value === option.value);
      if (inputtedOption) {
        return {
          ...option,
          disabled: inputtedOption.disabled
        }
      }
      return option;
    });
  }

  private _copyGroupedOptions() {
    this.groupOptionsCopy = structuredClone(this.groupedOptions);
    this.combinedOptions = structuredClone([...this.options, ...this.groupedOptions.flatMap(group => group.options)]);
  }

  private _copyOptions() {
    this.optionsCopy = structuredClone(this.options);
    this.combinedOptions = structuredClone([...this.options, ...this.groupedOptions.flatMap(group => group.options)]);
  }
}

<table appScrollNearEnd (nearEnd)="onNearBottomScroll()" mat-table [dataSource]="activityLogs$ | async" multiTemplateDataRows class="mat-elevation-z8">
    <!-- Timestamp Column -->
    <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef> {{'TIMESTAMP' | translate}} </th>
        <td mat-cell *matCellDef="let element">{{element.timestamp| moment : "l LTS"}} </td>
    </ng-container>

    <!-- Area Column -->
    <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef> {{'AREA' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{'ACTIVITY_' + element.area.toUpperCase() |translate}} </td>
    </ng-container>

    <!-- User Column -->
    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef> {{'USER' | translate }} </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="!element.staffId && element.staffName === 'Everon Admin'; else elseBlock" [innerHTML]="element.staffName | highlight : searchText"></span>
            <ng-template #elseBlock>
                <a [routerLink]="['/staff', element.staffId]" data-testid="staff-link" [innerHTML]="(element.staffName + ' (' + element.staffId + ')' ) | highlightLink : searchText"></a>
            </ng-template>
        </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> {{'ACTION' | translate}} </th>
        <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center">
                <app-feature-badge
                  [feature]="element.action | lowercase"
                  [status]="element?.details?.translationCode | resolveActivityBadgeStatus">
                </app-feature-badge>
                <i
                  *ngIf="element.action === 'Modify' && !(element?.details?.accountChanges | propsFalsy : ['roleLevel', 'lockRights'])"
                  class="fa-solid fa-shield-halved fa-lg ml-2"
                  style="color: #07184D"
                  data-testid="shield-icon"
                  [matTooltip]="'PERMISSIONS_MODIFIED' | translate">
                </i>
            </div>
        </td>
    </ng-container>

    <!-- Details Column -->
    <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef> {{'DETAILS' | translate}} </th>
        <td mat-cell *matCellDef="let element"
            [innerHTML]="element.area !== 'Digital Supervision' && element.action === 'Delete' || element.action === 'Authentication' ?

            (element.details.translationCode | translate:
                {
                    targetName: element.details.targetName,
                    targetId: element.details.targetId
                }) :

            ((element.details.translationCode | translate :
                {
                    targetName : element.details.targetName,
                    targetId: element.details.targetId
                })
                | anchorWrap : element.details.targetName : element.details.targetId : element.area : searchText)"
            (click)="onCellClick($event)">
        </td>
        <!-- <td mat-cell *matCellDef="let element">{{element.details.targetId}}</td> -->
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
        mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row"
        [ngClass]="[element.action.toLowerCase(), element.details?.targetClass | parseTargetClass]"
        (click)="onRowClicked(element, $event)">
    </tr>
</table>

import { Component, OnChanges, OnInit, Inject } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { HelperService } from 'app/services/helper.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
    selector: 'two-factor-authentication',
    templateUrl: './two-factor-authentication.component.html',
    styleUrls: ['./two-factor-authentication.component.css'],
    standalone: true,
    imports: [NgIf, TranslateModule]
})
export class TwoFactorAuthenticationComponent implements OnChanges, OnInit {

  constructor(
    private api: ApiService,
    private helper: HelperService,
    private translate: TranslateService,
    public dialog: MatDialog
  ) { }
  customer: any;
  twoFactorAuthDetails: any;
  twoFactorDisableConfirm: boolean = false;
  user: any;


  enableTwoFactorAuthentication(): void {
    this.api.enableTwoFactorAuthentication().subscribe(res => {
      const dialogRef = this.dialog.open(TwoFactorAuthenticationDialog, {
        disableClose: true,
        data: { qrCode: res.qrCode, manualCode: res.manualCode }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.customer.twoFactorAuthEnabled = result;
        localStorage.setItem("customerInfo", JSON.stringify(this.customer))
      })
    }, err => {

    })
  }

  showTwoFactorAuthDetails(): void {
    if (this.twoFactorAuthDetails) {
      this.twoFactorAuthDetails = null;
      return;
    }
    this.api.enableTwoFactorAuthentication().subscribe(res => {
      this.twoFactorAuthDetails = res;
    }, err => {

    })
  }

  disableTwoFactorAuthentication(): void {
    this.twoFactorAuthDetails = null;
    this.api.disableTwoFactorAuthentication().subscribe(res => {
      this.customer.twoFactorAuthEnabled = false;
      localStorage.setItem("customerInfo", JSON.stringify(this.customer))
    }, err => {

    })
  }

  ngOnInit() {
    this.customer = JSON.parse(localStorage.getItem('customerInfo'));
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnChanges() {
    this.customer = JSON.parse(localStorage.getItem('customerInfo'));
    this.user = JSON.parse(localStorage.getItem('user'));
  }
}

//Dialog component for configuring the 2FA

@Component({
    selector: 'two-factor-authentication-dialog',
    templateUrl: 'two-factor-authentication-dialog.html',
    standalone: true,
    imports: [MatDialogModule, FormsModule, TranslateModule]
})
export class TwoFactorAuthenticationDialog {

  constructor(
    public dialogRef: MatDialogRef<TwoFactorAuthenticationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService
    ) { }

    confirmCode: number;

    confirmTwoFactor(): void {
      this.api.confirmTwoFactorCode(this.confirmCode).subscribe(res => {
        this.dialogRef.close(true);
      }, err => {

      })
    }

    cancelTwoFactor(): void {
      this.api.disableTwoFactorAuthentication().subscribe(res => {
        this.dialogRef.close(false);
      }, err => {
      })
    }
}

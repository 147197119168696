import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AnimationCollapse } from '../../../animations';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationModel } from 'app/models/location.model';
import { LocationIotcommsSettingsComponent } from '../location-iotcomms-settings/location-iotcomms-settings.component';
import { LocationEmaSettingsComponent } from '../location-ema-settings/location-ema-settings.component';
import { LocationAccessRightsComponent } from '../location-access-rights/location-access-rights.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'location-info',
    templateUrl: './location-info.component.html',
    styleUrls: ['./location-info.component.css'],
    animations: [
        AnimationCollapse
    ],
    standalone: true,
    imports: [NgIf, NgClass, FormsModule, ReactiveFormsModule, MatSlideToggleModule, LocationAccessRightsComponent, LocationEmaSettingsComponent, LocationIotcommsSettingsComponent, NgFor, TranslateModule]
})
export class LocationInfoComponent implements OnChanges {

  //Init global variables and services
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private fb: UntypedFormBuilder
  ) {
  };

  @Input('location') location: LocationModel;
  @Output() closeLocationCardEvent = new EventEmitter<LocationModel>();
  @Output() locationUpdateEvent = new EventEmitter<LocationModel>();
  @Output() locationDeletedEvent = new EventEmitter<LocationModel>();

  user: any;
  editMode: boolean;
  locationEditForm: UntypedFormGroup;
  updateSuccess: boolean;
  updateError: boolean;
  deleteConfirmation: boolean;
  locationConfirmData: any;
  deleteError: boolean;
  activeTab: number = 1;

  createLocationEditForm(): void {
    this.locationEditForm = this.fb.group({
      name: [this.location.name, Validators.required],
      qualifier: this.location.qualifier,
      contactPhone: this.location.contactPhone,
      address: this.location.address,
      postCode: this.location.postCode,
      postOffice: this.location.postOffice,
      emaTagOnly: this.location.emaTagOnly
    })
  }

  resetLocationEditForm(): void {
    this.updateError = false;
    this.updateSuccess = false;
    this.locationEditForm.reset({
      name: this.location.name,
      qualifier: this.location.qualifier,
      contactPhone: this.location.contactPhone,
      address: this.location.address,
      postCode: this.location.postCode,
      postOffice: this.location.postOffice,
      emaTagOnly: this.location.emaTagOnly
    })
    this.editMode = !this.editMode;
  }

  submitLocationEdit(): void {
    let editedLocation = new LocationModel();
    editedLocation.id = this.location.id;
    editedLocation.name = this.locationEditForm.value.name;
    editedLocation.qualifier = this.locationEditForm.value.qualifier;
    editedLocation.contactPhone = this.locationEditForm.value.contactPhone;
    editedLocation.address = this.locationEditForm.value.address;
    editedLocation.postCode = this.locationEditForm.value.postCode;
    editedLocation.postOffice = this.locationEditForm.value.postOffice;
    editedLocation.mainUserGsmNumber = this.location.mainUserGsmNumber;
    editedLocation.emaTagOnly = this.locationEditForm.value.emaTagOnly;
    this.api.updateLocation(editedLocation).subscribe(res => {
      this.updateSuccess = true;
      this.editMode = false;
      this.location = editedLocation;
      this.locationUpdateEvent.emit(this.location);
    }, err => {
      this.updateError = true;
      this.editMode = false;
    })
  }

  closeLocationCard(): void {
    this.closeLocationCardEvent.emit(this.location);
  }

  confirmLocationDelete(): void {
    this.api.confirmLocationDelete(this.location.id).subscribe(res => {
      this.deleteConfirmation = true;
      this.locationConfirmData = res;
    }, err => {

    })
  }

  confirmDelete(): void {
    this.deleteError = false;
    this.api.deleteLocation(this.location.id).subscribe(res => {
      this.locationDeletedEvent.emit(this.location);
    }, err => {
      this.deleteError = true;
    })
  }

  cancelDelete(): void {
    this.deleteConfirmation = false;
    this.editMode = false;
  }


  showIfFeatureEnabled(feature: string): boolean {
    let features: [String] = JSON.parse(localStorage.getItem('features'));
    if (features) {
      return features.includes(feature);
    }
    return false;
  }

  ngOnChanges() {
    this.editMode = false;
    this.deleteConfirmation = false;
    this.updateError = false;
    this.updateSuccess = false;
    this.activeTab = 1;
    this.createLocationEditForm();
    this.user = JSON.parse(localStorage.getItem('user'));
  }
}

import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { ApiService } from 'app/services/api.service';
import { AnnaPerennaMonitorProfile } from 'app/models/annaperenna.model';
import { MomentPipe } from '../../../../pipes/pipes';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';


@Component({
    selector: 'ap-monitor-card',
    templateUrl: './ap-monitor-card.component.html',
    styleUrls: ['./ap-monitor-card.component.css'],
    standalone: true,
    imports: [NgIf, MatFormFieldModule, MatSelectModule, FormsModule, MatOptionModule, NgFor, TranslateModule, MomentPipe]
})
export class AnnaPerennaMonitorCardComponent implements OnChanges {
  constructor(
    private translate: TranslateService,
    private api: ApiService,
    private router: Router
  ) { }

  @Input('monitor') monitor: any;
  @Input('profiles') profiles: AnnaPerennaMonitorProfile[];

  @Output() monitorInEdit = new EventEmitter<boolean>();
  @Output() monitorRemovedEmit = new EventEmitter<number>();

  profileEdit: boolean = false;
  selectedProfile: AnnaPerennaMonitorProfile;
  user: any;

  getIconPath(type: string): string {
    //Return unknown icon if type is not set
    if (!type) {
      return 'assets/img/ap_icons/unknown.png'
    }
    let iconPath: string = type;

    if (type.startsWith('safe')) {
      iconPath = 'safe';
    }
    if (type.startsWith('floor')) {
      iconPath = 'floor';
    }
    return `assets/img/ap_icons/${iconPath}.png`
  }

  getIconText(): string {
    if (!this.monitor.icon) {
      return "";
    }
    let type = this.monitor.icon;
    if (type.startsWith('safe')) {
      type = 'safe';
    }
    if (type.startsWith('floor')) {
      type = 'floor';
    }
    type = type.toUpperCase();
    let translateString = `AP_ICON_TEXT_${type}`
    return this.translate.instant(translateString)
  }

  editProfile(): void {
    if (this.user.roleLevel == 40 || this.user.roleLevel >= 100) {
      this.profileEdit = !this.profileEdit;
      this.monitorInEdit.emit(this.profileEdit);
    }
  }

  removeFromList(): void {
    this.monitorRemovedEmit.emit(this.monitor.monitorId);
  }

  saveProfile(): void {
    let data: any = {};
    data.aspaId = this.monitor.aspaId;
    data.profile = this.selectedProfile.id;
    data.monitoringEnabled = this.selectedProfile.monitoringEnabled;
    this.api.updateMonitorProfile(data).subscribe(res => {
      this.profileEdit = false;
      this.monitorInEdit.emit(this.profileEdit);
    })
  }

  checkSuperActiveTime(): boolean {
    let currentTime = moment();
    let startTime = moment(this.monitor.superActiveStart, "HH:mm");
    let endTime = moment(this.monitor.superActiveEnd, "HH:mm");
    if (endTime < startTime) {
      endTime.add(1, 'day')
    }
    return currentTime.isBetween(startTime, endTime);
  }

  calculateStateDuration(): string {
    //Return empty string if event timestamp is null or zero value
    if (!this.monitor.eventTime || this.monitor.eventTime === "0001-01-01T00:00:00Z") return "";
    let momentDate = moment(this.monitor.eventTime);
    return momentDate.fromNow();
  }

  parseMonitorData(): void {
    //Change superActiveTimes from ie. 2000 - 0700 to 20:00 - 07:00
    if (this.monitor.superActiveStart) {
      let splittedStart = this.monitor.superActiveStart.match(/.{1,2}/g)
      this.monitor.superActiveStart = `${splittedStart[0]}:${splittedStart[1]}`;
    }
    if (this.monitor.superActiveEnd) {
      let splittedEnd = this.monitor.superActiveEnd.match(/.{1,2}/g)
      this.monitor.superActiveEnd = `${splittedEnd[0]}:${splittedEnd[1]}`;
    }
  }

  editZones(): void {
    this.router.navigate([`anna-perenna/edit/${this.monitor.aspaId}`]);
  }

  ngOnChanges() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.parseMonitorData();
    this.selectedProfile = this.profiles.find(profile => {
      return this.monitor.profile === profile.id;
    })
  }
}
<div *ngIf="showNav">
    <mat-nav-list
        class="mb-4"
        [class.topPadding]="onMobileScreen"
        (click)="onSideNavClose()">
        <!--Normal user navigation-->
        <ng-container *ngIf="normalNav && (router.url !== '/landing-page')">
            <a *ngFor="let t of navTabsNormalUser"
                routerLink={{t.path}}
                [hidden]="!t.userCondition && !t.deviceCondition">
                <mat-list-item
                    routerLinkActive="active"
                    [matTooltipDisabled]="showTitles"
                    matTooltip="{{ t.name | translate }}"
                    matTooltipPosition="after"
                    matTooltipClass="navTooltipStyle"
                    matTooltipShowDelay="50"
                    matTooltipHideDelay="150"
                    class="text-light"
                    *ngIf="
                    (t.userCondition === true ? true : false) &&
                    t.deviceCondition === true ? true : false"

                    [routerLinkActiveOptions]="{ exact: true }">
                    <div [ngClass]="{'centerItem' : !showTitles}">
                        <i class={{t.fa_icon}} aria-hidden="true"></i>
                        <span *ngIf="showTitles">
                            {{ t.name | translate }}
                        </span>
                    </div>
                </mat-list-item>
            </a>
        </ng-container>

        <!--Customer admin user navigation-->
        <ng-container *ngIf="custAdminNav && (router.url !== '/landing-page')">
            <a *ngFor="let t of navTabsCustomerAdmin"
                routerLink={{t.path}}
                [hidden]="!t.userCondition && !t.deviceCondition">
                <mat-list-item
                    routerLinkActive="active"
                    [matTooltipDisabled]="showTitles"
                    matTooltip="{{ t.name | translate }}"
                    matTooltipPosition="after"
                    matTooltipClass="navTooltipStyle"
                    matTooltipShowDelay="50"
                    matTooltipHideDelay="150"
                    class="text-light"
                    *ngIf="
                    (t.userCondition === true ? true : false) &&
                    t.deviceCondition === true ? true : false"

                    [routerLinkActiveOptions]="{ exact: true }">
                    <div [ngClass]="{'centerItem' : !showTitles}">
                        <i class={{t.fa_icon}} aria-hidden="true"></i>
                        <span *ngIf="showTitles">
                            {{ t.name | translate }}
                        </span>
                    </div>
                </mat-list-item>
            </a>
        </ng-container>

        <!-- Admin user navigation-->
        <ng-container
            *ngIf="adminNav &&
            (router.url !== '/landing-page/residents' &&
            router.url !== '/landing-page/devices' &&
            router.url !== '/landing-page/staff')"
            (click)="onSideNavClose()">

            <a *ngFor="let t of navTabsAdmin"
                routerLink={{t.path}}>
                <mat-list-item
                    routerLinkActive="active"
                    class="text-light"
                    matTooltip="{{ t.name | translate }}"
                    matTooltipPosition="after"
                    [matTooltipDisabled]="showTitles"
                    matTooltipClass="navTooltipStyle"
                    matTooltipShowDelay="50"
                    matTooltipHideDelay="150"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <div [ngClass]="{'centerItem' : !showTitles}">
                        <i class={{t.fa_icon}} aria-hidden="true"></i>
                        <span *ngIf="showTitles">
                            {{ t.name | translate }}
                        </span>
                    </div>
                </mat-list-item>
            </a>
        </ng-container>

        <!-- Clocks for control room-->
        <div *ngIf="showCrClocks && (router.url !== '/landing-page')"
            class="crClocks">
            <div mat-list-item class="mx-2 mt-4">
                <control-room-clock
                    [timeZone]="'Europe/Helsinki'"
                    [img]="'fi'"></control-room-clock>
            </div>
            <div mat-list-item class="mt-4">
                <control-room-clock
                    [timeZone]="'Europe/Stockholm'"
                    [img]="'sv'"></control-room-clock>
            </div>
            <div mat-list-item class="mx-2 mt-4">
                <control-room-clock
                    [timeZone]="'Europe/London'"
                    [img]="'uk'"></control-room-clock>
            </div>
        </div>

        <!-- Landing page navigation -->
        <ng-container *ngIf="router.url.includes('/landing-page') && landingPageUser.user.roleLevel === 95">
            <a *ngFor="let t of navTabsLandingPage"
                routerLink={{t.path}}>
                <mat-list-item
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    matTooltip="{{ t.name | translate }}"
                    matTooltipPosition="after"
                    [matTooltipDisabled]="showTitles"
                    matTooltipClass="navTooltipStyle"
                    matTooltipShowDelay="50"
                    matTooltipHideDelay="150"
                    class="text-light">
                    <i class={{t.fa_icon}} aria-hidden="true"></i>
                    <span *ngIf="showTitles">
                        {{ t.name | translate }}
                    </span>
                </mat-list-item>
            </a>
        </ng-container>
    </mat-nav-list>
</div>

import { LOCK_CATEGORY_LANG_CODES, LOCK_TYPE_LANG_CODES } from "./../../models/unloc.model";
import { Pipe, PipeTransform, NgModule } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "app/services/api.service";
import { HelperService } from "app/services/helper.service";
import * as moment from "moment-timezone";

// Pipes specifically used in the report page

/**
 * Remove alarm reason duplicates from the list
 */
@Pipe({
  name: "removeAlarmReaonDuplicates",
  pure: true,
})
export class RemoveAlarmReasonDuplicatesPipe implements PipeTransform {
  transform(
    alarmReasons: { alarmReason: string; alarmReasonCategory: string }[]
  ): any[] {
    alarmReasons = alarmReasons.filter(
      (obj, index) =>
        alarmReasons.findIndex(
          (item) =>
            item.alarmReason === obj.alarmReason &&
            item.alarmReasonCategory === obj.alarmReasonCategory
        ) === index
    );
    return alarmReasons;
  }
}

/**
 * Format camera visit duration from time strings
 */
@Pipe({
  name: "visitDuration",
  pure: true,
})
export class CameraVisitDurationPipe implements PipeTransform {
  transform(startTime: string, endTime: string): string {
    const ms = new Date(endTime).getTime() - new Date(startTime).getTime();

    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    // Format the duration string
    let durationString = "";
    if (hours > 0) {
      durationString += `${hours} h `;
    }
    if (minutes > 0 || durationString !== "") {
      durationString += `${minutes} minutes `;
    }
    durationString += `${seconds} seconds`;

    return durationString;
  }
}

/**
 * Replace spaces with underscore so translating some string is possible. Might not be needed
 */
@Pipe({
  name: "removeSpaces",
  pure: true,
})
export class RemoveSpacesPipe implements PipeTransform {
  transform(str: string): string {
    return str.replace(/ /g, "_");
  }
}

/**
 * Calculate time difference between two timestamps
 */
@Pipe({
  name: "calculateTime",
  pure: true,
})
export class CalculateTimePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  convertToMinutes(s: number): string {
    let converted: string = "";
    let minutes = Math.floor(s / 60);
    let seconds = s % 60;
    if (minutes >= 1) {
      this.translate
        .get("TIME_START_MINUTES", { seconds: seconds, minutes: minutes })
        .subscribe((t) => {
          converted = t;
        });
    } else {
      this.translate
        .get("TIME_START_SECONDS", { seconds: seconds })
        .subscribe((t) => {
          converted = t;
        });
    }
    return converted;
  }

  transform(startTime: string, endTime: string): string {
    let start = moment(startTime).millisecond(0);
    let stop = moment(endTime).millisecond(0);
    let delay = moment.duration(stop.diff(start));
    return this.convertToMinutes(delay.asSeconds());
  }
}

/**
 * Get resolver name of the timeout event
 */
@Pipe({
  name: "getResolverForTimeout",
  pure: true,
})
export class TimeoutResolverPipe implements PipeTransform {
  transform(emaEvents: any[]): string {
    let resolver = "";
    emaEvents.forEach((e) => {
      if (e.eventType.includes("LOG_NEW_STATE_STARTED")) {
        resolver = e.UserName;
      }
    });
    return resolver;
  }
}

/**
 * Return icon based on alarm delivery type
 */
@Pipe({
  name: "getDeliveryClass",
  pure: true,
})
export class DeliveryClassPipe implements PipeTransform {
  constructor(private api: ApiService) { }

  transform(row: any): string {
    if (row.confirmed) {
      return this.api.getDeviceIconClass(row.deviceType);
    }
    switch (row.deliveryType) {
      case "LOG":
        return "fa-file";
      case "CALL+SMS":
        return "fa-phone";
      case "CALL+GRSMS":
        return "fa-users";
      case "EMA":
        return "fa-mobile fa-lg";
      case "SMSONLY":
      case "SMS":
        return "fa-comment";
      case "EMAIL":
        return "fa-envelope";
      default:
        break;
    }
  }
}

/**
 * Get delivery type text
 */
@Pipe({
  name: "getDeliveryStr",
  pure: true,
})
export class DeliveryStringPipe implements PipeTransform {
  transform(type: string): string {
    switch (type) {
      case "LOG":
        return "Log";
      case "EMAIL":
        return "Email";
      case "SMSONLY":
        return "SMS";
      default:
        return type;
    }
  }
}

/**
 * Get activation name
 */
@Pipe({
  name: "getActivationName",
  pure: true,
})
export class ActivationNamePipe implements PipeTransform {
  constructor(private helper: HelperService) { }

  transform(
    group: number,
    node: number,
    type: string,
    customAct: string,
    connTimeLimit: number
  ): string {
    return this.helper.getActivationName(
      group,
      node,
      type,
      customAct,
      connTimeLimit
    );
  }
}

/**
 * Get lock type language code
 */
@Pipe({
  name: "lockType",
  pure: true,
})
export class LockTypePipe implements PipeTransform {
  transform(lockedDoorTypeId: number): string {
    const obj = LOCK_TYPE_LANG_CODES.find((x) => x.id === lockedDoorTypeId);
    return obj ? obj.code : "";
  }
}

/**
 * Get lock category language code
 */
@Pipe({
  name: "lockCategory",
  pure: true,
})
export class LockCategoryPipe implements PipeTransform {
  transform(lockedDoorTypeId: number): string {
    const obj = LOCK_CATEGORY_LANG_CODES.find(x => x.id === lockedDoorTypeId);
    return obj ? obj.code : "";
  }
}

@NgModule({
  declarations: [
    RemoveAlarmReasonDuplicatesPipe,
    CameraVisitDurationPipe,
    RemoveSpacesPipe,
    CalculateTimePipe,
    TimeoutResolverPipe,
    DeliveryClassPipe,
    DeliveryStringPipe,
    ActivationNamePipe,
    LockTypePipe,
    LockCategoryPipe
  ],
  exports: [
    RemoveAlarmReasonDuplicatesPipe,
    CameraVisitDurationPipe,
    RemoveSpacesPipe,
    CalculateTimePipe,
    TimeoutResolverPipe,
    DeliveryClassPipe,
    DeliveryStringPipe,
    ActivationNamePipe,
    LockTypePipe,
    LockCategoryPipe
  ],
})
export class ReportPipesModule { }

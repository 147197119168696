import { throttleTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, EventEmitter, OnChanges, OnInit, Output, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from 'app/services/api.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';


@Component({
    selector: 'device-import-lock-csv',
    templateUrl: './device-import-lock-csv.component.html',
    styleUrls: ['./device-import-lock-csv.component.css'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, TranslateModule]
})
export class DeviceImportLockCsvComponent implements OnInit, OnDestroy {

  constructor(
    private api: ApiService,
    private fb: UntypedFormBuilder
  ) { }


  @Output() closeLockImportCardEvent = new EventEmitter<void>();

  formData: FormData = new FormData();
  uploadLockCsvFile: UntypedFormGroup;
  saveSuccess: boolean;
  saveError: boolean;

  formSubmitSubject = new Subject();

  createUploadLockCsvFile(): void {
    this.uploadLockCsvFile = this.fb.group({
      file: [null, Validators.required]
    })
  }

  resetCsvForm(): void {
    this.uploadLockCsvFile.reset({
      file: null
    })
  }

  //Emit the close event to parent component
  closeImportForm(): void {
    this.closeLockImportCardEvent.emit();
  }

  fileChange(event) {
    let files: FileList = event.target.files;
    if (files.length > 0) {
      let file: File = files[0];
      this.formData.append('file', file, file.name);
    }
  }

  uploadCsv(): void {
    this.saveError = false;
    this.saveSuccess = false;
    this.api.uploadLockCsv(this.formData)
      .subscribe(res => {
        this.saveSuccess = true;
        this.resetCsvForm();
        this.formData = new FormData();
      }, err => {
        this.saveError = true;
      })
  }


  ngOnInit(): void {
    this.createUploadLockCsvFile();

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject
      .pipe(throttleTime(3000))
      .subscribe(() => {
        this.uploadCsv()
      });
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ControlRoomComponent } from '../../control-room.component';
import { NgClass, NgIf, DatePipe, CommonModule } from '@angular/common';
import { CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { ControlRoomService } from 'app/services/control-room.service';

@Component({
    selector: 'app-incoming-alert-card',
    templateUrl: './incoming-alert-card.component.html',
    styleUrls: ['./incoming-alert-card.component.css'],
    standalone: true,
    imports: [CommonModule, MatCardModule, CdkDragHandle]
})
export class IncomingAlertCardComponent implements OnInit {


  @Input() alert;
  constructor(
    public crService: ControlRoomService
  ) { }

  ngOnInit(): void {
  }

}

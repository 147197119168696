<div class="card">
    <h4 class="mainDarkBlueText normalFontWeight pb-2">
        {{ "RESPONSE_TIME_PER_ALERT" }}
    </h4>

    <div id="tableContainer">
        <table mat-table [dataSource]="tableData" matSort>
            <!-- Timestamp Column -->
            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "TIMESTAMP" | translate }}
                </th>
                <td mat-cell *matCellDef="let e">
                    {{ e.timestamp | moment : "l LTS" }}
                </td>
            </ng-container>

            <!-- Device Name Column -->
            <ng-container matColumnDef="deviceName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "DEVICE_NAME" | translate }}
                </th>
                <td mat-cell *matCellDef="let e">{{ e.deviceName }}</td>
            </ng-container>

            <!-- Device type Column -->
            <ng-container matColumnDef="deviceType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "DEVICE_TYPE" | translate }}
                </th>
                <td mat-cell *matCellDef="let e">
                    {{ e.deviceType | deviceType }}
                </td>
            </ng-container>

            <!-- Serial number Column -->
            <ng-container matColumnDef="deviceId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "SERIAL_NUMBER" | translate }}
                </th>
                <td mat-cell *matCellDef="let e">{{ e.deviceId }}</td>
            </ng-container>

            <ng-container matColumnDef="responseTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "RESPONSE_TIME_TEXT" | translate }}
                </th>
                <td mat-cell *matCellDef="let e">
                    {{ e.responseTime | formatSeconds }}
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>

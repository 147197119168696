<div class="row">
    <div class="col-lg-12">
        <a routerLink="/onon">
            <button class="btn btn-outline-primary mb-4"><span class="fa-light fa-angle-left fa-fw"
                    aria-hidden="true"></span>{{'BACK' |translate}}</button>
        </a>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div>
            <strong>{{'SELECT_CAMERA' |translate}}</strong>
        </div>
        <div class="btn-group mt-2" role="group">
            <button *ngFor="let camera of cameras" type="button" class="btn btn-light"
                [ngClass]="{'activeCamera' : selectedCameraId === camera.id}" (click)="changeCamera(camera.id)"><span
                    class="fa-light fa-fw fa-camera-home"></span> {{camera.name}}</button>
        </div>
    </div>
</div>
<hr>
<div class="row" *ngIf="snapshotBase64 && selectedCameraId">
    <div class="col-lg-6" *ngIf="snapShotLoaded">
        <div class="zoneList">
            <strong>{{'ACTIVE_ZONES' |translate}}</strong>
            <div *ngFor="let zone of allZones" (click)="editZone(zone)" class="mt-2">
                <div *ngIf="zone.id" class="alert cursorPointer zoneBlock" [ngClass]="zone.type">
                    <div *ngIf="!zone.toBeDeleted">
                        <span class="fa-light fa-fw" [ngClass]="getZoneIcon(zone.type)"></span> {{'ZONE_' +
                        zone.type.toUpperCase() |translate}}
                        <div class="float-right">
                            <span matTooltip="{{'SAVE_ZONE' |translate}}" *ngIf="zone.modified"
                                class="fa-light fa-lg fa-fw fa-floppy-disk mr-1" (click)="saveZone(zone)"></span>
                            <span matTooltip="{{'CANCEL_ZONE_EDIT' |translate}}" *ngIf="zone.modified"
                                class="fa-light fa-lg fa-fw fa-xmark mr-4"
                                (click)="cancelZoneEdit(zone); $event.stopPropagation()"></span>
                            <span matTooltip="{{'DELETE_ZONE' |translate}}"
                                *ngIf="selectedZone === zone && !zone.modified"
                                class="fa-light fa-fw fa-trash-alt fa-lg"
                                (click)="zone.toBeDeleted = true; $event.stopPropagation()"></span>
                        </div>
                    </div>
                    <div *ngIf="zone.toBeDeleted">
                        {{'CONFIRM_ZONE_DELETE' |translate}}
                        <span class="fa-light fa-fw fa-lg fa-check ml-2"
                            (click)="deleteZone(zone); $event.stopPropagation()"></span>
                        <span class="fa-light fa-fw fa-lg fa-xmark ml-2"
                            (click)="zone.toBeDeleted = false; $event.stopPropagation()"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="zoneContainer" #dragBounds>
            <img *ngIf="snapshotBase64" [src]="snapshotBase64" />
            <svg class="zonesSvg" (click)="svgClick($event)">
                <polygon *ngFor="let zone of allZones" class="zonePolygon" [ngClass]="zone.type"
                    [attr.points]="zone.polygonPoints" (click)="editZone(zone)" />
            </svg>
            <div class="zonePoint" ngDraggable [bounds]="dragBounds" [inBounds]="true" [preventDefaultEvent]="true"
                (movingOffset)="onMoving($event, i)" *ngFor="let xy of selectedZone?.cornerPoints; let i = index"
                [ngStyle]="{'left.px': xy.x, 'top.px': xy.y}"></div>
        </div>
        <div class="addZoneContainer">
            <strong>{{'ADD_NEW_ZONE' |translate}}</strong>
            <div class="form-group mt-3">
                <mat-form-field>
                    <mat-label>{{'ZONE_TYPE' |translate}}</mat-label>
                    <mat-select [(ngModel)]="newZone.type">
                        <mat-option *ngFor="let type of zoneTypeList" [value]="type" name="newZoneType">
                            <span class="fa-light fa-fw" [ngClass]="getZoneIcon(type)"></span> {{'ZONE_' +
                            type.toUpperCase() |translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <button [disabled]="!newZone.type" class="btn btn-primary"
                    (click)="zoneDrawingActive = true; selectedZone = null"><span
                        class="fa-light fa-fw fa-pencil"></span> {{'DRAW_ZONE' |translate}}</button>
            </div>
            <div *ngIf="zoneDrawingActive">
                <div>
                    <p>{{'ZONE_DRAWING_INFO' |translate}}</p>
                </div>
                <div class="form-group">
                    <button class="btn btn-outline-primary" (click)="undoLastNewZoneCorner()"><span
                            class="fa-light fa-fw fa-arrow-rotate-left"></span> {{'UNDO_LAST_CORNER'
                        |translate}}</button>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary" (click)="saveNewZone()"><span
                            class="fa-light fa-fw fa-floppy-disk"></span> {{'SAVE' |translate}}</button>
                    <button class="btn btn-outline-danger ml-3" (click)="cancelNewZoneDrawing()"><span
                            class="fa-light fa-fw fa-xmark"></span> {{'CANCEL' |translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 border-left pl-4" *ngIf="snapShotLoaded">
        <div>
            <strong>{{'ALERT_BACKGROUND_IMAGE' |translate }}</strong>
        </div>
        <div class="mt-3">
            <button type="button" class="btn btn-outline-primary ml-2"
                (click)="setBackgroundImage()">{{'SET_ALERT_BACKGROUND' |translate}}</button>
            <button type="button" class="btn btn-outline-danger ml-2"
                (click)="deleteBackgroundImage()">{{'DELETE_ALERT_BACKGROUND' |translate}}</button>
        </div>
        <div class="mt-3">
            <strong>{{'CURRENT_ALERT_BACKGROUND' |translate}}</strong>
            <div class="mt-2">
                <img *ngIf="alertBackgroundImageBaseLoaded && alertBackgroundImageBase64"
                    [src]="alertBackgroundImageBase64" />
                <span *ngIf="alertBackgroundImageBaseLoaded && !alertBackgroundImageBase64">{{'ALERT_BACKGROUND_NOT_SET'
                    |translate}}</span>
            </div>
        </div>
    </div>
    <!--Error if snapshot could not be loaded-->
    <div class="col-lg-4" *ngIf="!snapshotBase64 && snapShotLoaded">
        {{'SNAPSHOT_NOT_LOADED' |translate}}
    </div>
    <!--Loading icon while zone information and snapshot is being loaded-->
    <div class="col-lg-12" *ngIf="!allZones && allZones.length == 0">
        <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg">
    </div>
</div>

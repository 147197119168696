import { Pipe, PipeTransform } from "@angular/core";

// This file contains pipes that are specifically used in camera supervision

@Pipe({
  name: "visitLocation",
  pure: true,
  standalone: true
})
export class CameraVisitLocationNamePipe implements PipeTransform {
  transform(serverIdLocationId: string, locationList: { id: string, name: string }[] = []): string {
    let locationName = "-";

    if (serverIdLocationId) {
      const locationId = serverIdLocationId.slice(-8);
      const location = locationList.find(x => x.id === locationId);
      if (location) {
        locationName = location.name;
      }
      else {
        locationName = locationId;
      }
    }
    return locationName;
  }
}

import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "app/services/helper.service";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import * as moment from "moment-timezone";
import { DateAdapter, MatOptionModule } from "@angular/material/core";
import { Device } from "../../../models/device.model";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSelectModule } from "@angular/material/select";
import { NgIf } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { Angular2Csv } from "angular2-csv";
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import {
  ReportSummaryRequest,
  DeviceAlert,
  AlarmTypes,
} from "app/models/reportrequestdata.model";
import {
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
} from "@angular/core";

export interface IAlarmCategory {
  deviceType: string;
  activationGroup: number;
  activationNode: number;
}

@Component({
  selector: "app-report-summary",
  templateUrl: "./report-summary.component.html",
  styleUrls: ["./report-summary.component.css"],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    NgIf,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatTableModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    TranslateModule,
  ],
})
export class ReportSummaryComponent implements OnInit {
  //Declare services
  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private helper: HelperService
  ) {}

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("dropdownBtn") dropdownBtn: ElementRef;
  @ViewChild("dropdownMenu") dropdownMenu: ElementRef;

  @Input() startDate: Date;
  @Input() endDate: Date;

  @Output() startDateChanged: EventEmitter<Date> = new EventEmitter();
  @Output() endDateChanged: EventEmitter<Date> = new EventEmitter();

  // Declare variables
  user: any;
  dataLoaded: boolean = false;
  pageViewInitialized: boolean = false;
  showDropdownMenu: boolean = false;

  // Alert variables
  deviceAlerts: DeviceAlert[];
  alarmTypes: AlarmTypes = {
    total: 0,
    basestationButton: 0,
    alarmButton: 0,
    fallAlarm: 0,
    movement: 0,
    doorAlarm: 0,
    batteryState: 0,
  };

  // Table variables
  displayedColumns: string[] = [
    "deviceName",
    "deviceType",
    "deviceId",
    "alarms",
  ];
  tableData: any;
  selectFormControl = new UntypedFormControl("more");
  alertCountFilter = new UntypedFormControl("");

  currentDate: Date = new Date();
  dateLimit: Date = moment().subtract(4, "months").toDate();

  /**
   * Device alert custom dropdown hide handler
   * @param event Click event
   */
  @HostListener("document:click", ["$event"])
  handleHideDropdown(event: Event): void {
    if (this.dropdownBtn && this.showDropdownMenu) {
      // Check if clicked element is dropdown option, dropdown button or icon inside the button
      const isOption = this.dropdownMenu.nativeElement.contains(event.target);
      const isDropdownBtn = this.dropdownBtn.nativeElement === event.target;
      const isBtnIcon = this.dropdownBtn.nativeElement.contains(event.target);

      // Hide the dropdown if the clicked element isn't any of the checked elements
      if (!isDropdownBtn && !isBtnIcon && !isOption) {
        this.showDropdownMenu = false;
      }
    }
  }

  onStartDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.startDate = event.value;
    this.startDateChanged.emit(this.startDate);
  }

  onEndDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.endDateChanged.emit(event.value);
  }

  exportDeviceAlertsAsCSV(): void {
    const filename =
      "deviceAlerts_" +
      moment(this.startDate).format("YYYY-MM-DD") +
      "_" +
      moment(this.endDate).format("YYYY-MM-DD");

    const csvHeaders = [
      "ID",
      "Name",
      "Location",
      "Type",
      "Alerts",
      "Start date",
      "End date",
    ];
    const exportOptions = {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalseparator: ".",
      showTitle: false,
      headers: csvHeaders,
    };
    const csvData = this.parseDeviceAlertsCSV(this.deviceAlerts);

    // Generate the CSV file, send it to the browser and close the dropdown menu
    new Angular2Csv(csvData, filename, exportOptions);
    this.showDropdownMenu = false;
  }

  parseDeviceAlertsCSV(alerts: DeviceAlert[]): any {
    const csvData = [];

    for (const alert of alerts) {
      const row = {
        deviceId: `="${alert.deviceId}"`,
        deviceName: alert.deviceName,
        location: alert.locationName,
        deviceType: alert.deviceType,
        alerts: alert.alarms,
        startDate: moment(this.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.endDate).format("YYYY-MM-DD"),
      };
      csvData.push(row);
    }
    return csvData;
  }

  loadAlerts(): void {
    // Reset table data and alarm counts
    Object.keys(this.alarmTypes).forEach((k) => {
      this.alarmTypes[k] = 0;
    });
    this.tableData = [];
    this.dataLoaded = false;
    this.pageViewInitialized = true;

    let request = new ReportSummaryRequest();

    request.fromTimestamp = moment(this.startDate).startOf("day").utc();
    request.toTimestamp = moment(this.endDate).endOf("day").utc();

    this.api.getDevices({ fullInfo: false }).subscribe((res: Device[]) => {
      if (res != null) {
        // Removes unwanted devices from the request
        res = res.filter(
          (device) =>
            device.deviceType.substring(0, 2) !== "18" &&
            device.deviceType !== "F0-1"
        );
        request.devices = res;

        this.api.getReportSummary(request).subscribe((res: DeviceAlert[]) => {
          if (res) {
            res.forEach((e) => {
              this.parseAlarmTypes(
                {
                  deviceType: e.deviceType,
                  activationGroup: e.activationGroup,
                  activationNode: e.activationNode,
                },
                e.alarms
              );
              e.deviceType = this.helper.getDeviceType(e.deviceType);
            });
            this.deviceAlerts = res;
            this.dataLoaded = true;

            this.tableData = new MatTableDataSource(this.deviceAlerts);
            this.tableData.sort = this.sort;
          } else {
            this.dataLoaded = true;
            this.deviceAlerts = [];
          }
        });
      } else {
        this.dataLoaded = true;
        this.deviceAlerts = [];
      }
    });
  }

  parseAlarmTypes(alert: IAlarmCategory, alarmCount: number): void {
    // Basestation alarms
    if (
      alert.deviceType.substring(0, 2) === "31" &&
      alert.activationGroup === 1 &&
      alert.activationNode === 1
    ) {
      this.alarmTypes.basestationButton += alarmCount;
      this.alarmTypes.total += alarmCount;
    }
    // Alarm button alarms
    if (
      ["04", "05"].includes(alert.deviceType.substring(0, 2)) &&
      alert.activationGroup === 2 &&
      alert.activationNode === 1
    ) {
      this.alarmTypes.alarmButton += alarmCount;
      this.alarmTypes.total += alarmCount;
    }
    // Fall alarms
    if (
      alert.deviceType.substring(0, 2) === "05" &&
      alert.activationGroup === 2 &&
      alert.activationNode === 2
    ) {
      this.alarmTypes.fallAlarm += alarmCount;
      this.alarmTypes.total += alarmCount;
    }
    // Movement alarms
    if (alert.activationGroup === 1 && alert.activationNode === 33) {
      this.alarmTypes.movement += alarmCount;
      this.alarmTypes.total += alarmCount;
    }
    // Door alarms
    if (
      alert.deviceType.substring(0, 2) === "10" &&
      alert.activationGroup === 2 &&
      alert.activationNode === 1
    ) {
      this.alarmTypes.doorAlarm += alarmCount;
      this.alarmTypes.total += alarmCount;
    }
    // Battery state alarms
    if (alert.activationGroup === 4 && alert.activationNode === 1) {
      this.alarmTypes.batteryState += alarmCount;
      this.alarmTypes.total += alarmCount;
    }
  }

  applyFilter() {
    if (this.alertCountFilter.value === "") {
      this.tableData = new MatTableDataSource(this.deviceAlerts);
      this.tableData.sort = this.sort;
    }
    // Checks if string Value is a number
    else if (!isNaN(+this.alertCountFilter.value)) {
      const selectedAlertCount = Number(this.alertCountFilter.value);
      let filtered: DeviceAlert[];

      switch (this.selectFormControl.value) {
        case "more":
          filtered = this.deviceAlerts.filter((alert) => {
            return alert.alarms > selectedAlertCount;
          });
          break;
        case "less":
          filtered = this.deviceAlerts.filter((alert) => {
            return alert.alarms < selectedAlertCount;
          });
          break;
      }

      this.tableData = new MatTableDataSource(filtered);
      this.tableData.sort = this.sort;
    }
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.user.language = localStorage.getItem("language");
    this.dateAdapter.setLocale(this.user.language);
  }
}

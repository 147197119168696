<div class="row mt-3 ml-lg-2 mr-lg-2">
    <div class="col-12">
        <div>
            <h3 class="d-inline-block mb-2">
                <span class="fa-light fa-fw fa-users"></span>
                {{ "RECEIVER_TEAMS" | translate }}
            </h3>
            <div class="mb-2">
                <span class="fa-solid fa-user fa-fw staffSuperUser"></span>
                <small> = {{ "STAFF_CUSTOMER_ADMIN" | translate }}</small>

                <span class="fa-light fa-user fa-fw staffMainUser ml-2"></span>
                <small> = {{ "STAFF_MAIN_USER" | translate }}</small>

                <span class="fa-light fa-user fa-fw staffUser ml-2"></span>
                <small> = {{ "STAFF_USER" | translate }}</small>

                <span class="fa-light fa-user fa-fw ml-2"></span>
                <small> = {{ "STAFF_READONLY" | translate }}</small>

                <span *ngIf="featureEnabled('EveronMobileApp')" class="fa-light fa-user fa-fw ml-2 staffEMA"></span>
                <small *ngIf="featureEnabled('EveronMobileApp')">
                    = {{ "EveronMobileApp" | translate }}
                </small>

                <span *ngIf="featureEnabled('AnnaPerenna') || featureEnabled('OnOn')" class="fa-light fa-user fa-fw ml-2 staffAPMU"></span>
                <small *ngIf="featureEnabled('AnnaPerenna') || featureEnabled('OnOn')">
                    = {{ "STAFF_ONON_MAIN_USER" | translate }}
                </small>
            </div>
            <div class="mb-3 mt-3" *ngIf="user.roleLevel >= 100">
                <button
                    class="btn btn-primary"
                    (click)="showTeamEditForm = false; showTeamAddForm = true"
                >
                    <span class="fa-light fw fa-plus"></span>
                    {{ "CREATE_NEW_GROUP" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3 ml-xl-2 mr-xl-2">
    <div [ngClass]="{ 'd-none d-lg-block': isSideFormVisible(), 'col-xl-8 col-lg-7': isSideFormVisible(), 'col-xl-12': !isSideFormVisible() }">
        <ng-container *ngIf="noTeamsAvailable">
            <h6 class="noUsers">
                {{ "NO_RECEIVER_TEAMS_AVAILABLE" | translate }}
            </h6>
        </ng-container>
        <!--Customer groups-->
        <div *ngFor="let customerGroup of customerTeams" class="card-group">
            <div class="card mb-4">
                <div class="card-header cursorPointer" (click)="customerGroup.collapsed = !customerGroup.collapsed">
                    <h5>
                        <span [ngClass]="customerGroup.collapsed ? 'fa-light fa-fw fa-user' : 'fas fa-fw fa-user'"></span>
                        {{ customerGroup.customerName }}
                    </h5>
                    <small>
                        {{ "NR_OF_GROUPS" | translate }}:
                        {{ customerGroup.teams ? customerGroup.teams.length : 0 }}
                    </small>
                </div>
                <div class="body-overline" *ngIf="customerGroup.collapsed">
                    <div class="card-body">
                        <div class="row pl-2 pr-2 pt-2" *ngIf="receiverTeams">
                            <div *ngFor="let team of customerGroup.teams">
                                <div
                                    class="card group-container mx-2"
                                    [ngClass]="{ userLoggedInCard: team.currentUserLoggedToThisGroup, userLoggedOutCard: !team.currentUserLoggedToThisGroup, hiddenTeam: team.hidden }"
                                >
                                    <div
                                        class="card-header cursorPointer"
                                        [ngClass]="{ hiddenTeam: team.hidden }"
                                        (click)="team.collapsed = !team.collapsed"
                                    >
                                        <div class="d-flex justify-content-between align-items-baseline">
                                            <h6 class="d-flex flex-grow-1">
                                                <span class="fa-solid fa-fw fa-users mr-3"></span>
                                                <span>
                                                    {{ team.groupName.length > 15 ? team.groupName.slice(0, 15) + "..." : team.groupName }}
                                                </span>
                                                <span
                                                    *ngIf="team.locked"
                                                    class="fa-light fa-fw fa-lock ml-1"
                                                ></span>
                                            </h6>
                                            <!--Button for group edit-->
                                            <span
                                                matTooltip="{{ 'HIDDEN' | translate }}"
                                                matTooltipClass="receiverGroupTooltip"
                                                *ngIf="team.hidden"
                                                class="float-right fa-sharp fa-light fa-eye-slash fa-lg mr-2"
                                            ></span>
                                            <span
                                                matTooltip="{{ 'TOOLTIP_EDIT_RECEIVER_TEAM' | translate }}"
                                                matTooltipClass="receiverGroupTooltip"
                                                *ngIf="user.roleLevel >= 50"
                                                class="fa-light fa-pen-to-square fa-lg mr-2 cursorPointerHover"
                                                (click)="
                                                    showTeamInformation(team);
                                                    showLocationEditForm = false;
                                                    showTeamAddForm = false;
                                                    $event.stopPropagation()
                                                "
                                            ></span>
                                            <!--Button to mark user active or inactive-->
                                            <span
                                                *ngIf="user.roleLevel <= 100 && !team.locked && team.currentUserLoggedToThisGroup"
                                                (click)="changeUserGroupActiveStatus(team)"
                                                class="cursorPointer float-right mr-2 ml-1 fa-light fa-fw fa-lg"
                                                [matTooltip]="team.currentUserActiveInThisGroup ? ('GROUP_MARK_INACTIVE' | translate) : ('GROUP_MARK_ACTIVE' | translate)"
                                                matTooltipClass="receiverGroupTooltip"
                                                [ngClass]="team.currentUserActiveInThisGroup ? 'fa-volume-slash userLoggedIn' : 'fa-volume userLoggedOut'"
                                            ></span>
                                            <!--Button to join or leave group-->
                                            <span
                                                *ngIf="user.roleLevel <= 100 && !team.locked"
                                                class="cursorPointer float-right mr-2 ml-1"
                                                [matTooltip]="team.currentUserLoggedToThisGroup ? ('REMOVE_USER_FROM_GROUP' | translate) : ('ADD_USER_TO_GROUP' | translate)"
                                                matTooltipClass="receiverGroupTooltip"
                                                [ngClass]="team.currentUserLoggedToThisGroup ? 'fa-light fa-user-minus fa-lg userLoggedIn' : 'fa-light fa-user-plus fa-lg userLoggedOut'"
                                                (click)="
                                                    team.currentUserLoggedToThisGroup ? removeUserFromGroup(team.groupId) : addUserToGroup(team.groupId);
                                                    $event.stopPropagation()
                                                "
                                            ></span>
                                        </div>
                                        <small>{{ "NR_OF_STAFF_IN_GROUP" | translate }}: {{ team.users ? team.users.length : 0 }}</small>
                                    </div>
                                    <p *ngIf="team.users.length === 0" class="noUsers">
                                        {{ "NO_USERS_IN_RECEIVER_TEAM" | translate }}
                                    </p>
                                    <div *ngIf="team.users.length > 0">
                                        <table
                                            class="table table-borderless table-responsive-sm"
                                            *ngIf="team.collapsed"
                                        >
                                            <thead>
                                                <tr class="border-bottom">
                                                    <th>#</th>
                                                    <th>{{ "NAME" | translate }}</th>
                                                    <th *ngIf="emaFeatureEnabled()">
                                                        EMA
                                                        <span
                                                            matTooltip="{{ 'TOOLTIP_EMA' | translate}}"
                                                            matTooltipClass="receiverGroupTooltip"
                                                            class="fa fa-fw fa-info-circle"
                                                        ></span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let user of team.users">
                                                    <tr>
                                                        <td>
                                                            <span
                                                                class="fa-user fa-fw fa-lg"
                                                                [ngClass]="[getUsingLevelClass(user.usingLevel), user.usingLevel === 101 ? 'fa-solid' : 'fa-light']">
                                                            </span>
                                                        </td>
                                                        <td class="staff" [ngClass]="{inactiveMember: getInactiveState(user) }">
                                                            <span (click)="openPersonnelPage(user.id)" class="cursorPointerHover">
                                                                {{ user.name.length > 20 ? user.name.slice(0, 20) + "..." : user.name }}
                                                            </span>
                                                            {{ user.id }}
                                                        </td>
                                                        <td *ngIf="emaFeatureEnabled()">
                                                            <i *ngIf="!user.emaOnline" class="fa-regular fa-circle mr-2 emaOffline"></i>
                                                            <i *ngIf="user.emaOnline" class="fa-solid fa-circle-check mr-2 emaOnline"></i>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Location groups-->
        <div *ngFor="let locationGroup of locationTeams" class="card-group">
            <div class="card mb-4">
                <div class="card-header cursorPointer" (click)="locationGroup.collapsed = !locationGroup.collapsed">
                    <h5>
                        <span [ngClass]="locationGroup.collapsed ? 'fa-fw fa-light fa-house' : ' fa-fw fa-solid fa-house'"></span>
                        {{ locationGroup.locationName }}
                        <span
                            class="float-right fa-light fa-pen-to-square mr-2 cursorPointerHover"
                            matTooltip="{{ 'TOOLTIP_EDIT_LOCATION' | translate }}"
                            matTooltipClass="receiverGroupTooltip"
                            *ngIf="user.roleLevel >= 100"
                            (click)="
                                getLocationInformation(locationGroup.locationId);
                                showTeamAddForm = false;
                                showTeamEditForm = false;
                                showLocationEditForm = true;
                                $event.stopPropagation()
                            "
                        ></span>
                    </h5>
                    <small>
                        {{ "NR_OF_GROUPS" | translate }}: {{ locationGroup.teams ? locationGroup.teams.length : 0 }}
                    </small>
                </div>
                <div class="body-overline" *ngIf="locationGroup.collapsed">
                    <div class="card-body">
                        <div class="row pl-2 pr-2 pt-2" *ngIf="receiverTeams">
                            <!-- [ngClass]="isSideFormVisible() ? 'col-xl-4 col-lg-6 col-md-7' : 'col-xl-3 col-lg-4 col-md-6' " -->
                            <div *ngFor="let team of locationGroup.teams">
                                <div
                                    class="card group-container mx-2"
                                    [ngClass]="{ userLoggedInCard: team.currentUserLoggedToThisGroup, userLoggedOutCard: !team.currentUserLoggedToThisGroup, hiddenTeam: team.hidden }"
                                >
                                    <div
                                        class="card-header cursorPointer"
                                        [ngClass]="{ hiddenTeam: team.hidden }"
                                        (click)="team.collapsed = !team.collapsed"
                                    >
                                        <div class="d-flex justify-content-between align-items-baseline">
                                            <h6 class="d-flex flex-grow-1">
                                                <span class="fa-solid fa-fw fa-users mr-3"></span>
                                                <span>{{ team.groupName.length > 15 ? team.groupName.slice(0, 15) + "..." : team.groupName }}</span>
                                                <span *ngIf="team.locked" class="fa-light fa-fw fa-lock ml-1"></span>
                                            </h6>
                                            <!--Button for group edit-->
                                            <span
                                                *ngIf="team.hidden"
                                                matTooltip="{{ 'HIDDEN' | translate }}"
                                                matTooltipClass="receiverGroupTooltip"
                                                class="float-right fa-sharp fa-light fa-eye-slash fa-lg mr-2"
                                            ></span>
                                            <span
                                                *ngIf="user.roleLevel >= 50"
                                                matTooltip="{{ 'TOOLTIP_EDIT_RECEIVER_TEAM' | translate }}"
                                                matTooltipClass="receiverGroupTooltip"
                                                class="float-right fa-light fa-pen-to-square fa-lg mr-2 cursorPointerHover"
                                                (click)="
                                                    showTeamInformation(team);
                                                    showLocationEditForm = false;
                                                    showTeamAddForm = false;
                                                    $event.stopPropagation()
                                                "
                                            ></span>
                                            <!--Button to mark user active or inactive-->
                                            <span
                                                *ngIf="user.roleLevel <= 100 && !team.locked && team.currentUserLoggedToThisGroup"
                                                class="cursorPointer float-right mr-2 ml-1 fa-light fa-fw fa-lg"
                                                matTooltipClass="receiverGroupTooltip"
                                                [matTooltip]="team.currentUserActiveInThisGroup ? ('GROUP_MARK_INACTIVE' | translate) : ('GROUP_MARK_ACTIVE' | translate)"
                                                [ngClass]="team.currentUserActiveInThisGroup ? 'fa-volume-slash userLoggedIn' : 'fa-volume userLoggedOut'"
                                                (click)="changeUserGroupActiveStatus(team)"
                                            ></span>
                                            <!--Button to join or leave group-->
                                            <span *ngIf="user.roleLevel <= 100 && !team.locked"
                                                class="cursorPointer float-right mr-2 ml-1"
                                                [matTooltip]="team.currentUserLoggedToThisGroup ? ('REMOVE_USER_FROM_GROUP' | translate) : ('ADD_USER_TO_GROUP' | translate)"
                                                matTooltipClass="receiverGroupTooltip"
                                                [ngClass]="team.currentUserLoggedToThisGroup ? 'fa-light fa-user-minus fa-lg userLoggedIn' : 'fa-light fa-user-plus fa-lg userLoggedOut'"
                                                (click)="
                                                    team.currentUserLoggedToThisGroup ? removeUserFromGroup(team.groupId) : addUserToGroup(team.groupId);
                                                    $event.stopPropagation()
                                                "
                                            ></span>
                                        </div>
                                        <small>
                                            {{ "NR_OF_STAFF_IN_GROUP" | translate }}:
                                            {{ team.users ? team.users.length : 0 }}
                                        </small>
                                    </div>
                                    <p *ngIf="team.users.length === 0" class="noUsers">
                                        {{ "NO_USERS_IN_RECEIVER_TEAM" | translate }}
                                    </p>
                                    <div *ngIf="team.users.length > 0">
                                        <table class="table table-borderless table-responsive-sm" *ngIf="team.collapsed">
                                            <thead>
                                                <tr class="border-bottom">
                                                    <th>#</th>
                                                    <th>{{ "NAME" | translate }}</th>
                                                    <th *ngIf="emaFeatureEnabled()">
                                                        EMA
                                                        <span
                                                            matTooltip="{{ 'TOOLTIP_EMA' | translate }}"
                                                            matTooltipClass="receiverGroupTooltip"
                                                            class="fa fa-fw fa-info-circle"
                                                        ></span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let user of team.users">
                                                    <tr>
                                                        <td>
                                                            <span
                                                                class="fa-user fa-fw fa-lg"
                                                                [ngClass]="[getUsingLevelClass(user.usingLevel), user.usingLevel === 101 ? 'fa-solid' : 'fa-light']">
                                                            </span>
                                                        </td>
                                                        <td class="staff" [ngClass]="{ inactiveMember: getInactiveState(user)}">
                                                            <span (click)="openPersonnelPage(user.id)" class="cursorPointerHover">
                                                                {{ user.name.length > 20 ? user.name.slice(0, 20) + "..." : user.name }}
                                                            </span>
                                                            {{ user.id }}
                                                        </td>
                                                        <td *ngIf="emaFeatureEnabled()">
                                                            <i *ngIf="!user.emaOnline" class="fa-regular fa-circle mr-2 emaOffline"></i>
                                                            <i *ngIf="user.emaOnline" class="fa-solid fa-circle-check mr-2 emaOnline"></i>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!receiverTeams && !noTeamsAvailable">
            <img class="fadeLoader centerLoader logoSmall mt-2" src="assets/img/loading_logo.svg"/>
        </div>
    </div>
    <div *ngIf="showTeamEditForm" class="col-xl-4 col-lg-5">
        <team-edit
            (closeEditFormEvent)="closeTeamInformationForm()"
            [locations]="allLocations"
            [staff]="allStaff"
            [team]="selectedTeam"
            (teamEdited)="teamsModified()"
            (teamDeleted)="teamDeleted($event)"
        ></team-edit>
    </div>
    <div *ngIf="showTeamAddForm" class="col-xl-4 col-lg-5">
        <team-add
            (closeAddFormEvent)="showTeamAddForm = false"
            [staff]="allStaff"
            (teamAdded)="teamsModified()"
        >
        </team-add>
    </div>
    <div
        *ngIf="showLocationEditForm && selectedLocation"
        class="col-xl-4 col-lg-5"
    >
        <location-info
            [location]="selectedLocation"
            (closeLocationCardEvent)="showLocationEditForm = false; selectedLocation = null"
            (locationUpdateEvent)="locationEdited()"
        ></location-info>
    </div>
    <div *ngIf="showLocationEditForm && !selectedLocation" class="col-xl-4 col-lg-5">
        <img
            class="fadeLoader centerLoader logoSmall mt-2"
            src="assets/img/loading_logo.svg"
        />
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'login-federation',
    templateUrl: './federation.component.html',
    styleUrls: ['./federation.component.css'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class LoginFederationComponent implements OnInit {

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private cookies: CookieService
  ) { }

  sessionId: string;
  customersToSelect: any[];
  idProviderCode: string;

  startFederationLogin(): void {
    let data = {
      callbackUrl: window.location.origin + window.location.pathname,
      idProvider: this.idProviderCode,
      target: 0
    }

    this.api.startFederationLogin(data).subscribe(res => {
      if (res.redirectUrl) {
        window.location.href = res.redirectUrl;
      } else {
        this.parseLoginResponse(res);
      }
    })
  }

  parseLoginResponse(res: any): void {
    //Continue with login process if there's only one linked staff with identity
    if (!res.customers) {
      //Set the token received from the server to the cookie.
      this.cookies.set("session-token", "Bearer " + res.token, undefined, "/", undefined, false, "Lax");
      //Set logged in user data into local storage for later use.
      localStorage.setItem("user", JSON.stringify(res));
      this.router.navigate(["dashboard"]);
    } else {
      //Set the token received from the server to the cookie.
      this.cookies.set("session-token", "Bearer " + res.token, undefined, "/", undefined, false, "Lax");
      //Multiple customers where user can login. Show selection
      let landingPageData = {
        customers: res.customers,
        sessionId: this.sessionId,
        idProvider: this.idProviderCode,
        user: res.user,
      }
      localStorage.setItem("landing-page", JSON.stringify(landingPageData));
      //Go to landing page for customer selection
      this.router.navigate(["landing-page"], {
        queryParams: null
      });
    }

  }

  loginIntoCustomer(customer: any): void {
    let data = {
      sessionId: this.sessionId,
      userId: customer.userId,
      serverName: customer.serverName,
      idProvider: this.idProviderCode
    }

    this.api.loginToFederationCustomer(data).subscribe(res => {
      this.parseLoginResponse(res);
    })
  }

  ngOnInit(): void {
    //Set browser language for federated logins if there is no language in the local storage
    let language = localStorage.getItem("language");
    if (!language) {
      language = navigator.language.split('-')[0];
      localStorage.setItem("language", language)
    }
    //Get company code from URL
    this.route.params.subscribe(params => this.idProviderCode = params['code']);

    //Start demo session
    if (this.idProviderCode === "demo") {
      this.api.getFederationSessionDemo().subscribe(res => {
        console.log(res)
        this.parseLoginResponse(res);
        return;
      });
      return;
    }

    //If query params include GrandID sessionID then user is redirected from login. In that case get session details from API. Otherwise continue to federated authentication
    this.sessionId = this.route.snapshot.queryParams['grandidsession'];

    if (this.sessionId) {
      let data = {
        sessionId: this.sessionId,
        idProvider: this.idProviderCode
      }
      this.api.getFederationSession(data).subscribe(res => {
        if (res) {
          this.parseLoginResponse(res);
        } else {
          this.router.navigate(["login"]);
        }
      }, err => {
        if (err.status === 401) {
          this.startFederationLogin();
        }
      })
    } else {
      this.startFederationLogin();
    }

  }

}

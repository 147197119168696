import { throttleTime } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ElementRef,
  QueryList,
  ViewChildren,
  AfterViewInit,
} from "@angular/core";
import { ApiService } from "../../../services/api.service";
import { ActivatedRoute } from "@angular/router";
import { Location, NgIf, NgFor, NgClass } from "@angular/common";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { AnimationCollapse } from "../../../animations";
import { NgxBootstrapMultiselectModule, IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Device, DeviceReplacementStatus, ReplaceDeviceForm } from "../../../models/device.model";
import { HelperService } from "../../../services/helper.service";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialog } from "@angular/material/dialog";
import { ReplaceDeviceDialogComponent } from "./replace-device-dialog/replace-device-dialog.component";

type DeviceReplacementChart = { [devicePrefix: string]: string[] };

// TODO: add more device replacement rules when needed
const deviceReplacementChart: DeviceReplacementChart = {
  /** PL-100 button */
  "04": ["05"]
}

@Component({
  selector: "device-control",
  templateUrl: "./device-control.component.html",
  styleUrls: ["./device-control.component.css"],
  animations: [AnimationCollapse],
  standalone: true,
  imports: [NgIf, NgxBootstrapMultiselectModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgClass, NgxMaterialTimepickerModule, TranslateModule]
})
export class DeviceControlComponent
  implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  //Init global variables and services
  constructor(
    private api: ApiService,
    private helper: HelperService,
    private route: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
  ) { }

  @ViewChildren("ctrlElement") ctrlElements: QueryList<ElementRef>;

  @Input("device") device: Device;
  @Input("pairedDevices") pairedDevices: Device[];
  @Input("basestationList") basestationList: Device[];

  @Output() deviceDeleted = new EventEmitter<string>();
  @Output() cancelDeviceDelete = new EventEmitter<string>();
  @Output() deviceForceDeleted = new EventEmitter<Device>();
  @Output() deviceReplaced = new EventEmitter<string>();
  @Output() settingsTransferred = new EventEmitter<number>();
  @Output() updateError = new EventEmitter<boolean>();
  @Output() settingTransferCancelled = new EventEmitter<boolean>();
  @Output() nfcMoved = new EventEmitter<[string, string]>();
  @Output() isDeviceManagementEmpty: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  deleting: boolean = false;
  forceDeleting: boolean = false;
  replaceError: string;
  replaceDevice: string;
  isReplaced: boolean;
  user: any;
  firmwareVersions: any[];
  otaUpdateForm: UntypedFormGroup;
  otaError: boolean = false;
  otaSuccess: boolean = false;
  toBeDeleted: boolean = false;
  nfcMoveSuccess: boolean = false;
  nfcMoveError: boolean = false;
  deviceInventoryEnabled: boolean = false;

  formSubmitSubject = new Subject();

  basestationSelect: IBaseDeviceSelect = {
    options: [],
    texts: {},
    settings: {
      enableSearch: true,
      closeOnSelect: true,
      checkedStyle: "fontawesome",
      buttonClasses: "btn graySSBtn",
      selectionLimit: 1,
      autoUnselect: true,
      dynamicTitleMaxItems: 1,
      maxHeight: "510px",
    },
  };
  selectedBasestation: Device[] = [];

  moveNfcTag(): void {
    if (this.user.roleLevel >= 200 && this.device.deviceType === "08-1") {
      this.nfcMoveSuccess = false;
      this.nfcMoveError = false;

      const devId = this.device.id;
      const newBaseId = this.selectedBasestation[0].id;

      this.api.moveNfcTag(devId, newBaseId).subscribe(
        (updatedBaseId: string) => {
          this.device.basestationId = updatedBaseId;
          this.nfcMoveSuccess = true;
          this.nfcMoved.emit([devId, updatedBaseId]);
        },
        (error) => {
          this.nfcMoveError = true;
          console.log(error);
        }
      );
    }
  }

  createOtaUpdateForm(): void {
    this.otaUpdateForm = this.fb.group({
      fw: ["", Validators.required],
      fwUpdateTiming:
        this.device.swUdStartTime === "00:00" &&
          this.device.swUdEndTime === "00:00"
          ? "0"
          : "1",
      fwUpdateStartTime: this.device.swUdStartTime,
      fwUpdateEndTime: this.device.swUdEndTime,
    });
  }

  deleteDevice(): void {
    this.deleting = true;
  }

  forceDeleteDevice(): void {
    this.forceDeleting = true;
  }

  cancelDelete(): void {
    this.deleting = false;
    this.forceDeleting = false;
  }

  revertDelete(): void {
    this.api.cancelDeleteDevice(this.device.id).subscribe(
      (res) => {
        this.cancelDeviceDelete.emit(this.device.id);
      },
      (err) => {
        this.updateError.emit(true);
      }
    );
  }

  confirmDelete(): void {
    if (this.deleting) {
      this.api.deleteDevice(this.device.id).subscribe(
        (res) => {
          this.deviceDeleted.emit(this.device.id);
        },
        (err) => {
          this.updateError.emit(true);
        }
      );
    } else if (this.forceDeleting) {
      this.api.forceDeleteDevice(this.device.id).subscribe(
        (res) => {
          this.deviceForceDeleted.emit(this.device);
        },
        (err) => {
          this.updateError.emit(true);
        }
      );
    }
  }

  getDeviceType(type: string): string {
    return this.helper.getDeviceType(type);
  }

  checkDeviceType(setting: string): boolean {
    let type = this.device.deviceType;
    switch (setting) {
      case "moveNFC":
        if (type === "08-1" && this.user.roleLevel >= 200) {
          return true;
        }
        break;
      case "rts": //Retransfer settings
        if (
          (type.substring(0, 2) === "31" && type !== "31-8") ||
          type === "36-1" ||
          type === "32-1" ||
          type === "32-3" ||
          type === "32-6" ||
          type === "32-7" ||
          type === "32-8"
        ) {
          return true;
        }
        break;
      case "replace": //Replace device
        if (
          (type.substring(0, 2) < "10" &&
            type !== "08-1" &&
            this.user.roleLevel >= 50)

          // ***** ticket EP-495
          // Replace device section is hidden for Origons

          // || ((type === "31-13" || type === "31-14") &&
          //   this.device.softwareVersion >= 47 &&
          //   this.user.roleLevel === 200)
        ) {
          return true;
        }
        break;
      case "forceDelete": //Force delete device
        if (
          type.substring(0, 2) !== "31" &&
          (this.device.onlineStatus === "Offline" || this.device.toBeDeleted)
        ) {
          return true;
        }
        break;
      case "basestationDelete":
        if (type.substring(0, 2) === "31" && this.user.roleLevel < 100) {
          return false;
        }
        return true;
    }
    return false;
  }

  reTransferSettings(): void {
    this.api.retransferSettings(this.device.id).subscribe(
      (res) => {
        this.settingsTransferred.emit(1);
      },
      (err) => {
        this.updateError.emit(true);
      }
    );
  }

  cancelSettingsTransfers(): void {
    this.api.cancelSettingTransfer(this.device.id).subscribe(
      (res) => {
        this.settingTransferCancelled.emit(true);
      },
      (err) => {
        this.updateError.emit(true);
      }
    );
  }

  isReplaceIdValid(id: string): boolean {
    const replacePrefix = id.substring(0, 2);
    const devicePrefix = this.device.id.substring(0, 2);
    const samePrefix = replacePrefix === devicePrefix;

    this.replaceError = ""

    if (!id) {
      this.replaceError = this.translate.instant("NOT_EMPTY_FIELD");
      return false;
    }

    // Check that given ID is 8 digits hexadecimal and show error if not
    if (!id.match(new RegExp("^[0-9A-F]{8}$", "i"))) {
      this.replaceError = this.translate.instant("ID_VALIDATION_INFO");
      return false;
    }

    // If the device is not replaceable with the given type and the prefix is different, show error
    if (!samePrefix && !this.isDeviceReplaceableWithDifferentType(replacePrefix)) {
      this.replaceError = this.translate.instant("DEVICE_NOT_REPLACEABLE_WITH_GIVEN_TYPE");
      return false;
    }

    return true;
  }

  /**
   * @description Checks that given replacement ID prefix can be used to replace the original device with different device type
   * @param {string} replacePrefix Replacement device ID prefix to check against
   * @returns {boolean} `true` if the device can be replaced with the given prefix, `false` otherwise
   */
  isDeviceReplaceableWithDifferentType(replacePrefix: string): boolean {
    const devicePrefix = this.device.id.substring(0, 2);

    return deviceReplacementChart[devicePrefix]
      && deviceReplacementChart[devicePrefix].indexOf(replacePrefix) !== -1;
  }

  openReplaceDialog(): void {
    const replaceId = this.replaceDevice;

    if (this.isReplaceIdValid(replaceId)) {
      const dialogRef = this.dialog.open(ReplaceDeviceDialogComponent, {
        data: new ReplaceDeviceForm(this.device.id, replaceId),
        disableClose: true,
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe(
        (result: { replaced: boolean; cancelReplace?: boolean }) => {
          if (result.replaced) {
            this.deviceReplaced.emit(replaceId);
          }
          else if (result.cancelReplace) {
            this.cancelReplace();
          }
        });
    }
  }

  submitReplaceDevice(): void {
    this.replaceError = "";
    let replaceId = this.replaceDevice;

    if (!this.isReplaceIdValid(replaceId)) {
      return;
    }

    let replaceData = new ReplaceDeviceForm(this.device.id, replaceId);

    this.api.replaceDevice(replaceData).subscribe(
      () => {
        this.deviceReplaced.emit(replaceId);
      },
      (e) => {
        this.translate
          .get([
            "INVALID_DEVICE",
            "DEVICE_IN_USE",
            "ERROR",
            "REPLACEMENT_NOT_SUPPORTED",
            "REPLACED_DEVICE_NOT_OFFLINE",
            "REPLACED_DEVICE_SOFTWARE_TOO_OLD",
            "DEVICE_REPLACE_TYPE_MISMATCH",
            "DEVICE_NOT_REPLACEABLE_WITH_GIVEN_TYPE"
          ])
          .subscribe((t) => {
            switch (e.error.status as DeviceReplacementStatus) {
              case DeviceReplacementStatus.ReplaceDeviceNotValid:
                this.replaceError = t.INVALID_DEVICE;
                break;
              case DeviceReplacementStatus.ReplaceDeviceAlreadyInUse:
                this.replaceError = t.DEVICE_IN_USE;
                break;
              case DeviceReplacementStatus.GenericError:
                this.replaceError = t.ERROR;
                break;
              case DeviceReplacementStatus.DeviceNotReplaceableWithGivenType:
                this.replaceError = t.DEVICE_NOT_REPLACEABLE_WITH_GIVEN_TYPE;
                break;
              case DeviceReplacementStatus.DeviceReplacementNotSupported:
                this.replaceError = t.REPLACEMENT_NOT_SUPPORTED;
                break;
              case DeviceReplacementStatus.ReplacedDeviceNotOffline:
                this.replaceError = t.REPLACED_DEVICE_NOT_OFFLINE;
                break;
              case DeviceReplacementStatus.ReplacedDeviceSoftwareTooOld:
                this.replaceError = t.REPLACED_DEVICE_SOFTWARE_TOO_OLD;
                break;
              default:
                this.replaceError = t.ERROR;
                break;
            }
          });
      }
    );
  }

  cancelReplace(): void {
    if (this.isReplaced && this.device.deviceType !== "31-13") {
      this.api.cancelReplace(this.device.id).subscribe(
        (res) => {
          this.deviceReplaced.emit("");
          this.replaceDevice = null;
          this.isReplaced = false;
        },
        (err) => {
          this.translate.get(["ERROR"]).subscribe((t) => {
            this.replaceError = t.ERROR;
          });
        }
      );
    }
  }

  submitOta(): void {
    this.otaSuccess = false;
    this.otaError = false;

    // Convert times for 00:00 - 00:00 for immediate updates
    let updateStartTime = "00:00";
    let updateEndTime = "00:00";
    if (this.otaUpdateForm.value.fwUpdateTiming === "1") {
      updateStartTime = this.otaUpdateForm.value.fwUpdateStartTime;
      updateEndTime = this.otaUpdateForm.value.fwUpdateEndTime;
    }

    let data = {
      deviceId: this.device.id,
      firmwareId: this.otaUpdateForm.value.fw.firmwareId,
      type: this.otaUpdateForm.value.fw.swType,
      updateStartTime: updateStartTime,
      updateEndTime: updateEndTime,
    };

    this.api.startFirmwareUpdate(data).subscribe(
      (res) => {
        this.otaSuccess = true;
      },
      (err) => {
        this.otaError = true;
      }
    );
  }

  cancelOTA(): void {
    this.api.cancelFirmwareUpdate(this.device.id).subscribe(
      (res) => {
        this.device.softwareUpdateOngoing = false;
      },
      (err) => { }
    );
  }

  getBaseNameAndId(baseDev: Device) {
    return baseDev.name ? `${baseDev.name} (${baseDev.id})` : baseDev.id;
  }

  parseBaseDevOptions(basestations: Device[]): void {
    let array: IMultiSelectOption[] = [];
    for (let base of basestations) {
      array.push({ id: base, name: this.getBaseNameAndId(base) });
    }
    this.basestationSelect.options = array;
  }

  checkElementVisibility() {
    const isEmpty = this.ctrlElements.toArray().length === 0 ? true : false;
    this.isDeviceManagementEmpty.emit(isEmpty);
  }

  ngOnInit(): void {
    if (
      this.device.replaceDevice &&
      this.device.replaceDevice !== "00000000" &&
      this.device.replaceDevice !== "0"
    ) {
      this.replaceDevice = this.device.replaceDevice;
      this.isReplaced = true;
    }
    this.toBeDeleted = this.device.toBeDeleted;
    this.deviceInventoryEnabled = this.helper.isFeatureEnabled("DeviceRegistry");
  }

  ngAfterViewInit(): void {
    this.checkElementVisibility();
  }

  ngOnChanges(): void {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.firmwareVersions = null;

    if (this.user.roleLevel > 100) {
      this.api.getDeviceFirmwareVersions(this.device.id).subscribe(
        (res) => {
          res.sort(
            (a, b) => parseFloat(b.firmwareId) - parseFloat(a.firmwareId)
          );
          this.firmwareVersions = res;
          this.createOtaUpdateForm();
        },
        (err) => { }
      );
    }

    this.translate.get(["SEARCH_FOR_HUB_OR_DEVICE"]).subscribe((t) => {
      this.basestationSelect.texts = {
        defaultTitle: t.SEARCH_FOR_HUB_OR_DEVICE,
      };
    });

    // Initialize form submit subject with 3 second throttle time to prevent multiple submits
    this.formSubmitSubject.pipe(throttleTime(3000)).subscribe(() => {
      this.submitOta();
    });

    // Initialize basestation select
    this.parseBaseDevOptions(this.basestationList);
  }

  ngOnDestroy(): void {
    this.formSubmitSubject.unsubscribe();
  }
}

interface IBaseDeviceSelect {
  texts: IMultiSelectTexts;
  settings: IMultiSelectSettings;
  options: IMultiSelectOption[];
}
